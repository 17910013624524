import { Typography, IconButton, Dialog, Button } from '@mui/material'
import { FlexContainer, Spacer, Tooltip } from '../../components'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import DeleteIcon from '@mui/icons-material/Delete'
import CreateIcon from '@mui/icons-material/Create'
import ControlCameraIcon from '@mui/icons-material/ControlCamera'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { getMonthsBetweenDates } from '../../helpers/utils'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 0px 1px #E0E0E0;
  border-radius: 4px;
  padding: 16px;
`

const Attribute = styled(Typography)`
  color: #00000099;
`

const DragButton = styled.div`
  padding: 12px;
  cursor: all-scroll;
`

const DeleteButton = styled(Button)`
  background: #F44336;
  color: #FFFFFF;
  &:hover {
    background: #F44336;
    filter: brightness(1.05);
  }
`

const ViewPlan = ({ previewUnit, plan, onEdit, onDelete }) => {
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)

  const dynamicNumberOfMonths = useMemo(() => getMonthsBetweenDates(new Date(), previewUnit?.delivery_date), [previewUnit?.delivery_date])

  const toggleConfirmDeleteModal = useCallback(() => setIsConfirmDeleteOpen(!isConfirmDeleteOpen), [isConfirmDeleteOpen])

  const handleDelete = useCallback(() => {
    onDelete()
    toggleConfirmDeleteModal()
  }, [onDelete, toggleConfirmDeleteModal])

  return (
    <>
      <Dialog
        open={isConfirmDeleteOpen}
        onClose={toggleConfirmDeleteModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>¿Quieres eliminar este plan?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Dejará de mostrarse en tu showroom digital y no será posible recuperarlo después.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleConfirmDeleteModal} color="primary" variant="outlined">
            Cancelar
          </Button>
          <DeleteButton onClick={handleDelete} color="primary" variant="contained">
            Eliminar
          </DeleteButton>
        </DialogActions>
      </Dialog>
      <Container>
        <Typography variant='h5'>{plan?.name}</Typography>
        <Spacer size={2} vertical />
        {plan?.description && (
          <>
            <Typography>{plan?.description}</Typography>
            <Spacer size={2} vertical />
          </>
        )}
        <FlexContainer fullWidth justified>
          <FlexContainer vertical fullWidth>
            <Typography variant='h5'>{plan?.down_payment_percent ?? 0}%</Typography>
            <Attribute variant='body2'>Enganche</Attribute>
          </FlexContainer>
          <FlexContainer vertical fullWidth>
            <Typography variant='h5'>{(plan?.number_of_months === -1 ? dynamicNumberOfMonths : (plan?.number_of_months ?? 0))}</Typography>
            <FlexContainer>
              <Attribute variant='body2'>Mensualidades</Attribute>
              {plan?.number_of_months === -1 && (
                <>
                  <Spacer size={1} />
                  <Tooltip text='Las mensualidades se calculan de manera automática con base a la fecha de entrega' placement='top' />
                </>
              )}
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <Spacer size={3} vertical />
        <FlexContainer fullWidth justified>
          <FlexContainer vertical fullWidth>
            <Typography variant='h5'>{plan?.monthly_payment_percent ?? 0}%</Typography>
            <Attribute variant='body2'>Mensualidades</Attribute>
          </FlexContainer>
          <FlexContainer vertical fullWidth>
            <Typography variant='h5'>{plan?.discount_percent ?? 0}%</Typography>
            <Attribute variant='body2'>Descuento</Attribute>
          </FlexContainer>
        </FlexContainer>
        <Spacer size={3} vertical />
        <FlexContainer fullWidth>
          <FlexContainer fullWidth centered>
            <DragButton className='draggable'>
              <ControlCameraIcon />
            </DragButton>
          </FlexContainer>
          <FlexContainer fullWidth centered>
            <IconButton onClick={toggleConfirmDeleteModal}>
              <DeleteIcon />
            </IconButton>
          </FlexContainer>
          <FlexContainer fullWidth centered>
            <IconButton onClick={onEdit}>
              <CreateIcon />
            </IconButton>
          </FlexContainer>
        </FlexContainer>
      </Container>
    </>
  )
}

export default ViewPlan
