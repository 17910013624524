import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import React, { useCallback, useState, useMemo } from 'react';
import Image from '../../../../components/Image/Image';
import useAssetsNames from '../../../../hooks/useAssetsNames';
import useAssetsUpload from '../../../../hooks/useAssetsUpload';
import { AssetsUploadInput, CardMedia, ImageGallery } from '../../../../modules';
import Section from './Section';

const SelectionLayout = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, 333px)',
  justifyContent: 'space-between',
  gap: 32,
});

const CardContent = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 16px',
});

const TAGS = ['projects'];

const Images = ({ images = [], onDelete, onAdd }) => {
  const [openGallery, setOpenGallery] = useState(false);

  const { inputProps } = useAssetsUpload({
    type: 'image',
    tags: TAGS,
    onUploaded: ({ assets }) =>
      assets.map(({ url, assetId }) => onAdd({ url, assetId })),
  });

  const ids = useMemo(
    () =>
      images.filter((props) => props?.assetId).map(({ assetId }) => assetId),
    [images],
  );

  const { assetsNames } = useAssetsNames({ ids });

  const onSelectFromGallery = useCallback(
    (assets) => {
      for (const { assetId, url } of assets) {
        onAdd({ assetId, url });
      }
      setOpenGallery(false);
    },
    [onAdd],
  );

  return (
    <>
      <ImageGallery
        open={openGallery}
        onClose={() => setOpenGallery(false)}
        onSelected={onSelectFromGallery}
      />
      <Section title="Imágen (o render) del proyecto">
        <SelectionLayout>
          {images.map(({ url, assetId, alt }) => (
            <Card key={assetId} sx={{ maxWidth: 333, width: 1 }}>
              <CardMedia component={Image} src={url} height={194} alt={alt} />
              <CardContent>
                <Typography>{assetsNames?.[assetId]}</Typography>
                <IconButton onClick={onDelete}>
                  <DeleteIcon />
                </IconButton>
              </CardContent>
            </Card>
          ))}

          {images.length < 1 && (
            <div>
              <AssetsUploadInput
                sx={{ height: 194, cursor: 'pointer' }}
                uploadLabel="Sube una imagen"
                inputProps={{ ...inputProps, multiple: false }}
              />
              <Box
                sx={{ display: 'flex', mt: 1, justifyContent: 'space-between' }}
              >
                <Typography component="span">Imagen de la tipología</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => setOpenGallery(true)}
                >
                  Elegir de galería
                </Button>
              </Box>
            </div>
          )}
        </SelectionLayout>
        <Typography variant="body2" sx={{ color: 'text.secondary', mt: 4 }}>
          Esta imagen se mostrará como imagen del proyecto
        </Typography>
      </Section>
    </>
  );
};

export default Images;
