import React, { useMemo } from 'react';
import { getPermission, PERMISSIONS } from '../../helpers/permissions';
import useSite from '../../hooks/useSite';

const DEFAULT_PERMISSION_LEVELS = [
  PERMISSIONS.edit || PERMISSIONS.view || PERMISSIONS.limited_view,
];

/**
 * @param {{ permissionPath: string[]; acceptedPermissionLevel: string | string[] }} {props}
 */
const WithPermission = ({
  children,
  permissionPath,
  acceptedPermissionLevel = DEFAULT_PERMISSION_LEVELS,
}) => {
  const { site } = useSite();
  const permissionLevel = useMemo(() => {
    if (!permissionPath || !site) return PERMISSIONS.hidden;
    return getPermission(permissionPath, site.permissions);
  }, [permissionPath, site]);

  if (
    acceptedPermissionLevel === permissionLevel ||
    (Array.isArray(acceptedPermissionLevel) &&
      acceptedPermissionLevel.includes(permissionLevel))
  )
    return <>{children}</>;

  return null;
};

export default WithPermission;
