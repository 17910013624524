import { FlexContainer, Spacer } from "../../components"
import ErrorIcon from '@mui/icons-material/Error'
import styled from "styled-components"
import { Typography } from "@mui/material"

const StyledErrorIcon = styled(ErrorIcon)`
  font-size: 80px;
  color: rgba(0,0,0,0.54);
`

const ErrorMessage = styled(Typography)`
  width: 45%;
`

const Container = styled(FlexContainer)`
  min-height: calc(100vh - 100px);
`

const MissingAnalytics = () => {
  return (
    <Container
      verticalCentered
      fullWidth
      fullHeight
      centered
      vertical
    >
      <StyledErrorIcon />
      <Spacer size={2} vertical />
      <Typography variant="h5" color="textSecondary">OCURRIÓ UN PROBLEMA</Typography>
      <Spacer size={1} vertical />
      <ErrorMessage variant="body1" color="textSecondary" align="center">Esta analítica no ha sido correctamente configurada. Por favor contáctese a <a href={`mailto:${process.env.REACT_APP_SUPPORT_MAIL}`}>{process.env.REACT_APP_SUPPORT_MAIL}</a></ErrorMessage>
    </Container>
  )
}

export default MissingAnalytics
