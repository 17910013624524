import { Typography } from '@mui/material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import { FlexContainer, Spacer } from '../../components'
import React from 'react'
import styled from 'styled-components';
import ChangeCard from './ChangeCard'
import { INVENTORY_SCREEN } from './utils';
import { PERMISSIONS } from '../../helpers/permissions';

const Container = styled(FlexContainer)`
  padding: 24px;
`

const CardContainer = styled(FlexContainer)`
  justify-content: space-around;
`

const Description = styled(Typography)`
  font-weight: 500;
`

const BulkEditSelector = ({ onSelect, changeStatusPermission, changeDeliveryDatePermission, changePricePermission }) => (
  <Container vertical>
    <Description variant="body1">¿Qué campo te gustaría cambiar?</Description>
    <Spacer vertical size={7.5} />
    <CardContainer>
      {changePricePermission === PERMISSIONS.edit && (
        <ChangeCard
          icon={AttachMoneyIcon}
          title="Precio"
          description="Edite de forma masiva los precios por cantidad o porcentaje."
          onClick={() => onSelect(INVENTORY_SCREEN.bulkEditPrice)}
        />
      )}
      {changeDeliveryDatePermission === PERMISSIONS.edit && (
        <ChangeCard
          icon={EventAvailableIcon}
          title="Fecha de Entrega"
          description="Edite de forma masiva la fecha de entrega de los apartamentos seleccionados"
          onClick={() => onSelect(INVENTORY_SCREEN.bulkEditDeliveryDate)}
        />
      )}
      {changeStatusPermission === PERMISSIONS.edit && (
        <ChangeCard
          icon={DomainDisabledIcon}
          title="Estado"
          description="Edición masiva del estado de los apartamentos seleccionados"
          onClick={() => onSelect(INVENTORY_SCREEN.bulkEditStatus)}
        />
      )}
    </CardContainer>
  </Container>
)

export default BulkEditSelector
