import { Button, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { FlexContainer, Spacer } from '../../components';
import ProjectImage from '../../modules/ProjectImage/ProjectImage';

const Container = styled(FlexContainer)`
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  width: 645px;
`;

const InfoContainer = styled(FlexContainer)`
  padding: 16px;
`;

const Domain = styled.a`
  text-decoration: underline;
  cursor: pointer;
  color: var(--color-text-secondary);
`;

const ProjectCard = ({
  name,
  image,
  domain,
  onEditShowroom,
  onSiteDetails,
  showEditButton,
}) => (
  <Container>
    <ProjectImage src={image} alt={name} />
    <InfoContainer vertical fullWidth>
      <FlexContainer fullWidth justified>
        <Typography variant="h5">{name}</Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={onSiteDetails}
        >
          VER DETALLES
        </Button>
      </FlexContainer>
      <Spacer vertical size={1} />
      <FlexContainer fullWidth justified verticalCentered>
        <Domain href={`https://${domain}`} target="_blank">
          {domain}
        </Domain>
        {showEditButton && (
          <Button color="primary" onClick={onEditShowroom}>
            EDITAR SHOWROOM
          </Button>
        )}
      </FlexContainer>
    </InfoContainer>
  </Container>
);

export default ProjectCard;
