import React, { useMemo } from 'react';
import { styled } from '@mui/material';
import Image from '../../components/Image/Image';

const StyledComponent = styled('div')(({ isMedia, isImage }) =>
  !isMedia
    ? {}
    : {
        display: 'block',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        width: '100%',
        objectFit: isImage ? 'cover' : undefined,
      },
);

function componentIsMedia(component) {
  return ['video', 'audio', 'picture', 'iframe', 'img', Image].includes(
    component,
  );
}

function componentIsImage(component) {
  return ['picture', 'img', Image].includes(component);
}

const CardMedia = React.forwardRef(({ component, ...props }, ref) => {
  const isMedia = useMemo(() => componentIsMedia(component), [component]);
  const isImage = useMemo(() => componentIsImage(component), [component]);
  return (
    <StyledComponent
      {...props}
      as={component}
      isMedia={isMedia}
      isImage={isImage}
      ref={ref}
    />
  );
});

export default CardMedia;
