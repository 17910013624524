import LuxuryImg from "../assets/images/themes/luxury.png";
import NatureImg from "../assets/images/themes/nature.png";
import UrbanImg from "../assets/images/themes/urban.png";

export const convertThemeInCss = (theme, colorOption) => {
  return `
    :root {
      --color-primary: ${theme.colors[colorOption].primary};
      --color-primary-80: ${theme.colors[colorOption].primary80};
      --color-primary-60: ${theme.colors[colorOption].primary60};
      --color-secondary: ${theme.colors[colorOption].secondary};
      --color-secondary-80: ${theme.colors[colorOption].secondary80};
      --color-secondary-60: ${theme.colors[colorOption].secondary60};
      --color-neutral-80: ${theme.colors[colorOption].neutral80};
      --color-neutral-60: ${theme.colors[colorOption].neutral60};
      --color-neutral-40: ${theme.colors[colorOption].neutral40};
      --color-neutral-20: ${theme.colors[colorOption].neutral20};
      --font-family-heading: "${theme.fonts.primary}", sans-serif;
      --font-family-text: "${theme.fonts.secondary}", sans-serif;
      --border-radius-nav-buttons: ${theme.bordersRadius.navButtons};
      --border-radius-containers: ${theme.bordersRadius.containers};
      --margin-nav-buttons: ${theme.margins.navButtons};
      --color-range-slider: ${theme.colors[colorOption].secondary};
    }
  `
}

export const convertCustomColorsInCss = (customColors) => `
  :root {
    --color-primary: ${customColors.primary};
    --color-primary-80: ${customColors.primary80};
    --color-primary-60: ${customColors.primary60};
    --color-secondary: ${customColors.secondary};
    --color-neutral-80: ${customColors.neutral80};
    --color-neutral-60: ${customColors.neutral60};
    --color-neutral-20: ${customColors.neutral20};
  }
`

export const themes = {
  urban: {
    colors: {
      option1: {
        primary: '#090A0E',
        primary80: '#2E3243',
        primary60: '#575B6F',
        secondary: '#FFD310',
        secondary80: '#FFDD43',
        secondary60: '#FFE882',
        neutral80: '#212329',
        neutral60: '#636670',
        neutral40: '#AEB1BA',
        neutral20: '#EBEDF3',
      },
      option2: {
        primary: '#0C3244',
        primary80: '#1C556F',
        primary60: '#3B82A3',
        secondary: '#B1D6C6',
        secondary80: '#BCDFD0',
        secondary60: '#D8EFE5',
        neutral80: '#131925',
        neutral60: '#3D485E',
        neutral40: '#AFB5C1',
        neutral20: '#E2E5E9',
      }
    },
    fonts: {
      primary: 'League Gothic',
      secondary: 'Fira Sans',
    },
    bordersRadius: {
      navButtons: '0px',
      containers: '0px',
    },
    margins: {
      navButtons: '0px',
    }
  },
  nature: {
    colors: {
      option1: {
        primary: '#162620',
        primary80: '#335346',
        primary60: '#46705E',
        secondary: '#C5DF95',
        secondary80: '#D6ECAE',
        secondary60: '#E7F8C7',
        neutral80: '#1E352F',
        neutral60: '#878578',
        neutral40: '#C2C0B5',
        neutral20: '#F1EEDF',
      },
      option2: {
        primary: '#262F0F',
        primary80: '#455324',
        primary60: '#8FA45C',
        secondary: '#D3AF93',
        secondary80: '#E0C1A9',
        secondary60: '#EFD3BD',
        neutral80: '#2E2520',
        neutral60: '#878578',
        neutral40: '#C2C0B5',
        neutral20: '#F1EEDF',
      }
    },
    fonts: {
      primary: 'Libre Bodoni',
      secondary: 'Montserrat',
    },
    bordersRadius: {
      navButtons: '50%',
      containers: '8px',
    },
    margins: {
      navButtons: '8px',
    }
  },
  luxury: {
    colors: {
      option1: {
        primary: '#052539',
        primary80: '#123E59',
        primary60: '#2A5F80',
        secondary: '#DAAD70',
        secondary80: '#F5D1A1',
        secondary60: '#E6BE89',
        neutral80: '#03121B',
        neutral60: '#868D92',
        neutral40: '#C4C9CC',
        neutral20: '#F5F7F8',
      },
      option2: {
        primary: '#171516',
        primary80: '#474245',
        primary60: '#766F73',
        secondary: '#BF915E',
        secondary80: '#D2A675',
        secondary60: '#EEC79B',
        neutral80: '#453D38',
        neutral60: '#696059',
        neutral40: '#D0C8C5',
        neutral20: '#F8F6F5',
      }
    },
    fonts: {
      primary: 'Cormorant Garamond',
      secondary: 'Raleway',
    },
    bordersRadius: {
      navButtons: '0px',
      containers: '0px',
    },
    margins: {
      navButtons: '0px',
    }
  },
}

export const THEME_TYPES = [
  {
    value: "URBAN",
    label: "Urban",
    imageSrc: UrbanImg,
    colors: [
      {
        value: 'option1',
        primary: themes.urban.colors.option1.primary,
        secondary: themes.urban.colors.option1.secondary,
        neutral: themes.urban.colors.option1.neutral80,
      },
      {
        value: 'option2',
        primary: themes.urban.colors.option2.primary,
        secondary: themes.urban.colors.option2.secondary,
        neutral: themes.urban.colors.option2.neutral80,
      },
    ]
  },
  {
    value: "NATURE",
    label: "Nature",
    imageSrc: NatureImg,
    colors: [
      {
        value: 'option1',
        primary: themes.nature.colors.option1.primary,
        secondary: themes.nature.colors.option1.secondary,
        neutral: themes.nature.colors.option1.neutral80,
      },
      {
        value: 'option2',
        primary: themes.nature.colors.option2.primary,
        secondary: themes.nature.colors.option2.secondary,
        neutral: themes.nature.colors.option2.neutral80,
      },
    ]
  },
  {
    value: "LUXURY",
    label: "Luxury",
    imageSrc: LuxuryImg,
    colors: [
      {
        value: 'option1',
        primary: themes.luxury.colors.option1.primary,
        secondary: themes.luxury.colors.option1.secondary,
        neutral: themes.luxury.colors.option1.neutral80,
      },
      {
        value: 'option2',
        primary: themes.luxury.colors.option2.primary,
        secondary: themes.luxury.colors.option2.secondary,
        neutral: themes.luxury.colors.option2.neutral80,
      },
    ]
  }
]

export default themes
