import { Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"
import React, { Fragment, useCallback, useState } from "react"
import { FlexContainer, Spacer } from "../../components"
import AddIcon from '@mui/icons-material/Add'
import styled, { css } from "styled-components"
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import DeleteIcon from '@mui/icons-material/Delete'
import { DatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import NewMediaButton from "./NewMediaButton"
import MediaGallery from "../../modules/AssetsManagment/MediaGallery"
import MediaCard from "./MediaCard"
import { ReactSortable } from "react-sortablejs"
import { SimpleModal } from "../../modules"
import { useMemo } from "react"

const MODAL_TYPE = {
  "period": 1,
  "image": 2,
  "video": 3,
}

const StyledTableContainer = styled(TableContainer)`
  overflow-x: hidden;
`

const BodyRow = styled(TableRow)`
  opacity: ${p => p.$isDisabled ? "0.5" : "1"};
`

const Cell = styled(TableCell)`
  padding: 8px;
  ${p => p.$width && css`
    width: ${p.$width}px;
    max-width: ${p.$width}px;
    min-width: ${p.$width}px;
  `}
`

const MediaContainer = styled(FlexContainer)`
  overflow-x: auto;
  width: 100%;
  padding: 16px 1px;
  max-width: calc(100vw - 840px);

  &::-webkit-scrollbar-track {
    border-radius: 16px;
    opacity: 0.2;
    background-color: rgba(0, 0, 0, .05);
  }
  &::-webkit-scrollbar {
    width: 12px;
    background-color: rgba(0, 0, 0, .05);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: var(--color-primary);
  }
`

const PeriodsTable = ({
  periods,
  onAdd,
  onChange,
  onDelete,
  onDateChange,
  onMediaAdd,
  onMediaDelete,
  onMediaChange,
  onMediaReorder,
}) => {
  const [periodToAddMedia, setPeriodToAddMedia] = useState(null)
  const [modalData, setModalData] = useState(null)

  const closeGallery = useCallback(() => setPeriodToAddMedia(null), [])
  const closeModal = useCallback(() => setModalData(null), [])

  const newMediaClick = useCallback((periodId) => setPeriodToAddMedia(periodId),[])

  const showModal = useCallback((type, data) => {
    setModalData({
      type,
      data,
    })
  }, [])

  const handleMediaAdd = useCallback((assets) => {
    onMediaAdd(periodToAddMedia, assets)
    setPeriodToAddMedia(null)
  }, [onMediaAdd, periodToAddMedia])

  const handleModalDelete = useCallback(() => {
    if (modalData) {
      switch (modalData.type) {
        case MODAL_TYPE.period:
          onDelete(modalData?.data.periodId)
          break
        case MODAL_TYPE.image:
        case MODAL_TYPE.video:
          onMediaDelete(modalData.data.periodId, modalData.data.mediaIndex)
          break
        default:
      }
      setModalData(null)
    }
  }, [modalData, onDelete, onMediaDelete])

  const deleteModalTitle = useMemo(() => {
    switch (modalData?.type) {
      case MODAL_TYPE.period:
        return "¿Quieres eliminar este periodo?"
      case MODAL_TYPE.image:
        return `¿Quieres eliminar la imagen${modalData?.data?.imageTitle ?? ""}?`
      case MODAL_TYPE.video:
        return "¿Quieres eliminar el video?"
      default:
        return ""
    }
  }, [modalData?.data?.imageTitle, modalData?.type])

  const deleteModalMessage = useMemo(() => {
    switch (modalData?.type) {
      case MODAL_TYPE.period:
        return "En caso de querer mostrar las imágenes o videos en otro periodo, estarán disponibles en la galería del proyecto."
      case MODAL_TYPE.image:
      case MODAL_TYPE.video:
        return "En caso de querer mostrarla nuevamente, estará disponible en la galería del proyecto."
      default:
        return ""
    }
  }, [modalData?.type])

  return (
    <>
      <SimpleModal
        open={modalData != null}
        onClose={closeModal}
        title={deleteModalTitle}
        message={deleteModalMessage}
        primaryText="Eliminar"
        onPrimaryClick={handleModalDelete}
        secondaryText="Cancelar"
        onSecondaryClick={closeModal}
      />
      <MediaGallery open={periodToAddMedia != null} onClose={closeGallery} onSelected={handleMediaAdd} />
      <FlexContainer vertical>
        {periods.length > 0 && (
          <StyledTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <Cell></Cell>
                  <Cell></Cell>
                  <Cell align="left">PERIODO</Cell>
                  <Cell align="left">IMÁGENES</Cell>
                </TableRow>
              </TableHead>
              <TableBody>
                {periods?.map((period, i) => (
                  <BodyRow key={period.id} $isDisabled={!period.enabled}>
                    <Cell align="center" $width={50}>
                      <IconButton onClick={() => onChange(period.id, !period.enabled, "enabled")}>
                        {period.enabled ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </Cell>
                    <Cell align="center" $width={100}>
                      <IconButton onClick={() => showModal(MODAL_TYPE.period, { periodId: period.id })}>
                        <DeleteIcon />
                      </IconButton>
                    </Cell>
                    <Cell $width={250}>
                      <DatePicker
                        views={['year', 'month']}
                        label="Periodo de construcción"
                        maxDate={dayjs(new Date())}
                        value={period.date}
                        onChange={(newValue) => onDateChange(period.id, newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            helperText={period.repeatedDate ? "Ya tienes un periodo con esta fecha. Te recomendamos elegir otra." : ""}
                            error={params.error || period.repeatedDate}
                            sx={{ marginBottom: 2 }}
                          />
                        )}
                      />
                    </Cell>
                    <Cell>
                      <MediaContainer>
                        <NewMediaButton onClick={() => newMediaClick(period.id)} />
                        <ReactSortable list={period.media} setList={(value) => onMediaReorder(period.id, value)} handle='.drag-handler' animation={300} direction="horizontal" tag={FlexContainer}>
                          {period.media.map((m, mediaIndex) => (
                            <FlexContainer key={m?.id}>
                              <Spacer size={2} />
                              <MediaCard
                                media={m}
                                onDelete={() => showModal(MODAL_TYPE[m.type], { periodId: period.id, mediaIndex, imageTitle: m.title })}
                                onTitleChange={(value) => onMediaChange(period.id, m.id, value, "title")}
                              />
                            </FlexContainer>
                          ))}
                        </ReactSortable>
                      </MediaContainer>
                    </Cell>
                  </BodyRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}
        <Spacer size={2} vertical />
        <FlexContainer>
          <Button
            size="small"
            color="primary"
            variant="contained"
            endIcon={<AddIcon />}
            onClick={onAdd}
          >
            Agregar Periodo
          </Button>
        </FlexContainer>
      </FlexContainer>
    </>
  )
}

export default PeriodsTable
