import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import FlexContainer from '../FlexContainer/FlexContainer'
import InformationTooltip from '../InformationTooltip/InformationTooltip'

const WidgetTitle = ({ title, tooltip }) => {
  return (
    <FlexContainer
      verticalCentered
    >
      <Typography
        align="left"
        variant="overline">
        {
          title
        }
      </Typography>
      {tooltip && <InformationTooltip tooltip={tooltip} />}
    </FlexContainer>
  )
}

WidgetTitle.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string
}

export default WidgetTitle
