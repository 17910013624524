import { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { useBlocker } from './useBlocker';
import useSite from './useSite';

export const useCallbackPrompt = (when) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const blockedSite = useSelector((state) => state.blockedSite, shallowEqual)
  const { setSite } = useSite();

  const cancelNavigation = useCallback(() => {
    dispatch({ type: 'block-changing-site', data: false });
    if (blockedSite) {
      setSite(blockedSite);
    }
    setShowPrompt(false);
  }, [blockedSite, dispatch, setSite]);

  const openPrompt = useCallback(() => {
    dispatch({ type: 'block-changing-site', data: true });
    setShowPrompt(true);
  }, [dispatch])

  // handle blocking when user click on another route prompt will be shown
  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      // in if condition we are checking next location and current location are equals or not
      if (
        !confirmedNavigation &&
        nextLocation.location.pathname !== location.pathname
      ) {
        openPrompt();
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation, location.pathname, openPrompt],
  );

  const confirmNavigation = useCallback(() => {
    dispatch({ type: 'block-changing-site', data: false });
    if (blockedSite) {
      setSite(blockedSite);
    }
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, [blockedSite, setSite, dispatch]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname);
      setConfirmedNavigation(false);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  useBlocker(handleBlockedNavigation, when);

  return [showPrompt, confirmNavigation, cancelNavigation, openPrompt];
}