import { FlexContainer, WidgetContainer, WidgetTitle, Spacer, CompareData, DateRange } from "../../components"
import { numberToCurrency } from "../../helpers/utils"
import { useEffect, useState } from "react"
import { get } from '../../helpers/request'
import useCurrencies from "../../hooks/useCurrencies"
import moment from 'moment'
import useScreenWidthBelowEqual from "../../hooks/useScreenWidthBelowEqual"
import useSite from "../../hooks/useSite"

const SPANISH_MONTHS = {
  January: 'Enero',
  February: 'Febrero',
  March: 'Marzo',
  April: 'Abril',
  May: 'Mayo',
  June: 'Junio',
  July: 'Julio',
  August: 'Agosto',
  September: 'Septiembre',
  October: 'Octubre',
  November: 'Noviembre',
  December: 'Diciembre',

}
const TITLE = "Comparación de Ventas"
const TOOLTIP = "Observe el progreso del mes actual comparándolo con las ventas totales generadas en el rango de fechas seleccionado"
const CURRENT_MONTH = `${SPANISH_MONTHS[moment().format('MMMM')]} ${moment().format('YYYY')}`
const RANGE = " "

const SalesComparison = () => {
  const currencies = useCurrencies()
  const [sales, setSales] = useState(null)
  const [beginDate, setBeginDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const isBelowEqual1280 = useScreenWidthBelowEqual(1280)
  const { site: selectedSite } = useSite()

  useEffect(() => {
    setBeginDate(moment().subtract(1, 'months').startOf('month').toISOString())
    setEndDate(moment().subtract(1, 'months').endOf('month').subtract(1, 'days').toISOString())
  }, [])

  useEffect(() => {
    if (beginDate && endDate && selectedSite) {
      get(`/internal/developer/v3/dashboard/salescomparison?begin=${beginDate}&end=${endDate}`, { site_id: selectedSite.site_id })
        .then(result => setSales(result))
        .catch(error => {
          console.error(error)
        })
    }
  }, [beginDate, endDate, selectedSite])

  const handleBeginDateChange = event => setBeginDate(moment(event.target.value).toISOString())

  const handleEndDateChange = event => setEndDate(moment(event.target.value).toISOString())

  return (
    <>
      {currencies && sales && (<WidgetContainer width={isBelowEqual1280 ? 5 : 3}>
        <WidgetTitle title={TITLE} tooltip={TOOLTIP} />
        <Spacer vertical size={3} />
        <FlexContainer centered vertical fullHeight verticalCentered>
          <CompareData dataSize="small"
            dataA={{
              topCaption: currencies.main.code,
              data: numberToCurrency(sales.range_sales, currencies.main.symbol),
              bottomCaption: RANGE
            }}
            dataB={{
              topCaption: currencies.main.code,
              data: numberToCurrency(sales.current_month_sales, currencies.main.symbol),
              bottomCaption: CURRENT_MONTH
            }}
            childrenA={beginDate && endDate && (
              <DateRange
                beginDate={beginDate}
                endDate={endDate}
                handleBeginDateChange={handleBeginDateChange}
                handlEndDateChange={handleEndDateChange}
              />
            )}
          />
        </FlexContainer>
        <Spacer vertical size={2} />
      </WidgetContainer>)
      }
    </>
  )
}

export default SalesComparison
