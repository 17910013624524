import React from 'react'
import OnboardingProvider from '../../contexts/OnboardingContext'
import OnboardingSteps from './OnboardingSteps'

const OnboardingView = ({ setHideSidebar }) => (
  <OnboardingProvider>
    <OnboardingSteps setHideSidebar={setHideSidebar} />
  </OnboardingProvider>
)

export default OnboardingView
