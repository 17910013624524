import { Typography } from "@mui/material"
import React from "react"
import styled from "styled-components"
import { FlexContainer } from "../../../components"

const Container = styled(FlexContainer)`
  max-width: 250px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
`

const Image = styled.img`
  border-radius: 4px 4px 0 0;
`

const Footer = styled(FlexContainer)`
  height: 48px;
  padding: 0 16px;
`

const ThemeCard = ({ img, label }) => (
  <Container vertical>
    <Image src={img} alt={label} />
    <Footer verticalCentered>
      <Typography variant="h5">{label}</Typography>
    </Footer>
  </Container>
)

export default ThemeCard
