import { Button, Typography } from "@mui/material"
import React, { forwardRef } from "react"
import styled from "styled-components"
import { FlexContainer, Spacer } from "../../components"
import useSite from "../../hooks/useSite"
import { RightSidebar } from "../../modules"

const Container = styled(FlexContainer)`
  padding: 64px 32px;
`

const Iframe = styled.iframe`
  height: 591px;
  border: 0;
`

const Preview = forwardRef(({ open, onClose }, ref) => {
  const { site } = useSite()
  return (
    <RightSidebar open={open} onClose={onClose}>
      <Container vertical>
        <Typography>Así es como los clientes verán el avance de obra en tu showroom digital</Typography>
        <Spacer size={2} vertical />
        {site?.site_id && (
          <Iframe
            title="Avance de obra preview"
            ref={ref}
            src={`https://preview-draft-${site?.site_id}.preview.alohome.io?preview=1`}
          />
        )}
        <Spacer size={2} vertical />
        <FlexContainer>
          <Button onClick={onClose} variant="contained" color="primary">SEGUIR EDITANDO</Button>
        </FlexContainer>
      </Container>
    </RightSidebar>
  )
})

export default Preview
