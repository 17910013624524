import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  styled,
  Typography
} from "@mui/material";
import React from "react";
import HoverCardMedia from "./HoverCardMedia";

const StyledCard = styled(Card)({
  margin: 8,
  maxWidth: 330,
  width: "100%",
  "& button[disabled]": {
    opacity: 0.5,
  },
});

const SelectableCard = ({
  imageSrc,
  label,
  value,
  isSelected,
  onSelect,
  isACardSelected,
}) => (
  <>
    {isACardSelected ? (
      <StyledCard>
        <CardActionArea
          disabled={!isSelected}
          onClick={() => onSelect(undefined)}
        >
          <CardMedia component="img" height={194} src={imageSrc} alt={label} />
          <Box sx={{ p: 2 }}>
            <Typography component="p" variant="h6">
              {label}
            </Typography>
          </Box>
        </CardActionArea>
      </StyledCard>
    ) : (
      <StyledCard>
        <HoverCardMedia src={imageSrc} height={194} alt={label}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSelect(value)}
          >
            Elegir
          </Button>
        </HoverCardMedia>
        <Box sx={{ p: 2 }}>
          <Typography variant="h6">{label}</Typography>
        </Box>
      </StyledCard>
    )}
  </>
);

export default SelectableCard;
