import {
  Checkbox,
  FormControlLabel,
  Input,
  List,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { FlexContainer, Spacer } from '../../components';
import LocationOption from '../../components/PlacesAutocomplete/LocationOption';

const FieldContainer = styled(TextField)``;

const CustomRowTextField = styled(TextField)`
  width: 50%;
`;

const CustomRowTextSelector = styled(TextField)`
  width: 50%;
`;

const LocationList = styled(List)`
  max-width: 28rem;
  max-height: 240px;
  overflow-y: auto;
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  background-color: #fff;
  border: 1px solid #dedede;
  border-top: none;
  border-radius: 4px;
`;

const CustomListContainer = styled(FlexContainer)`
  width: 50%;
`;

export const renderField = (
  field,
  columnIndex,
  form,
  errors,
  handleForm,
  autocomplete
) => {
  switch (field.type) {
    case 'text-field':
      return (
        <React.Fragment key={field.field_name}>
          <CustomRowTextField
            fullWidth
            label={field.label}
            name={field.field_name}
            variant="standard"
            value={form[field.field_name]}
            error={errors[field.field_name]}
            helperText={errors[field.field_name]}
            onChange={({ target }) => handleForm(target.name, target.value)}
          />
          {!columnIndex && <Spacer size={3} />}
        </React.Fragment>
      );
    case 'text-field-autocomplete':
      return (
        <React.Fragment key={field.field_name}>
          <CustomListContainer fullWidth vertical>
            <TextField
              fullWidth
              label={field.label}
              name={field.field_name}
              variant="standard"
              value={autocomplete.value}
              error={errors[field.field_name]}
              helperText={errors[field.field_name]}
              onChange={(e) => autocomplete.setValue(e.target.value)}
              disabled={!autocomplete.ready}
            />
            <LocationList hidden={!autocomplete?.suggestions?.status}>
              {autocomplete?.suggestions?.status === 'OK' &&
                autocomplete?.suggestions?.data?.map(
                  ({ place_id, description }) => (
                    <LocationOption
                      key={place_id}
                      value={description}
                      label={description}
                      onClick={(address) => {
                        getGeocode({ address })
                          .then((results) => getLatLng(results[0]))
                          .then(({ lat, lng }) => {
                            handleForm('coordinates', { lat: lat, lng: lng });
                            handleForm(field.field_name, address);
                            autocomplete.setValue(address, false);
                            autocomplete.clearSuggestions();
                          });
                      }}
                      sx={{ color: 'text.primary' }}
                    />
                  )
                )}
            </LocationList>
          </CustomListContainer>
          {!columnIndex && <Spacer size={3} />}
        </React.Fragment>
      );
    case 'full-width-text-field':
      return (
        <FieldContainer
          key={field.field_name}
          label={field.label}
          name={field.field_name}
          variant="standard"
          value={form[field.field_name]}
          error={errors[field.field_name]}
          helperText={errors[field.field_name]}
          fullWidth
          onChange={({ target }) => handleForm(target.name, target.value)}
        />
      );
    case 'outlined-multiline-text-field':
      return (
        <TextField
          key={field.field_name}
          label={field.label}
          name={field.field_name}
          variant="outlined"
          value={form[field.field_name]}
          error={errors[field.field_name]}
          helperText={errors[field.field_name]}
          fullWidth
          multiline
          onChange={({ target }) => handleForm(target.name, target.value)}
          inputProps={{
            style: {
              height: 128,
            },
          }}
        />
      );
    case 'selector':
      return (
        <React.Fragment key={field.field_name}>
          <CustomRowTextSelector
            select
            variant="standard"
            name={field.field_name}
            value={form[field.field_name] || ''}
            onChange={({ target }) => handleForm(target.name, target.value)}
            label={field.label}
          >
            {field.options
              .filter(({ condition }) => {
                return !condition
                  ? true
                  : condition[1] === '='
                  ? condition[2].includes(form[condition[0]])
                  : true;
              })
              .sort((a, b) => a.value.localeCompare(b.value))
              .map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
          </CustomRowTextSelector>
          {!columnIndex && <Spacer size={3} />}
        </React.Fragment>
      );
    case 'checkbox-multiple':
      const newValue =
        form[field.field_name] &&
        form[field.field_name].filter((value) => value !== '');

      return (
        <React.Fragment key={field.field_name}>
          <CustomRowTextSelector
            select
            SelectProps={{
              multiple: true,
              renderValue: (selected) => selected.join(', '),
            }}
            variant="standard"
            name={field.field_name}
            value={newValue || []}
            onChange={({ target }) => {
              handleForm(target.name, target.value);
            }}
            multiple
            label={field.label}
          >
            {field.options
              .filter(({ condition }) => {
                return !condition
                  ? true
                  : condition[1] === '='
                  ? condition[2].includes(form[condition[0]])
                  : true;
              })
              .sort((a, b) => a.value.localeCompare(b.value))
              .map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  <Checkbox
                    checked={form[field.field_name].indexOf(value) > -1}
                  />
                  {label}
                </MenuItem>
              ))}
          </CustomRowTextSelector>
          {!columnIndex && <Spacer size={3} />}
        </React.Fragment>
      );

    case 'radio-button':
      return (
        <FlexContainer verticalCentered key={field.field_name}>
          <Typography variant="subtitle2">{field.label}</Typography>
          <RadioGroup
            value={form[field.field_name] || ''}
            onChange={({ target }) => handleForm(target.name, target.value)}
            row
            name={field.field_name}
          >
            {field.options.map(({ value, label }) => (
              <FormControlLabel
                value={value}
                control={<Radio />}
                label={label}
                labelPlacement="start"
              />
            ))}
          </RadioGroup>
        </FlexContainer>
      );
    default:
      return null;
  }
};
