import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { FlexContainer, Spacer } from '../../components'
import PriceChangeInfo from './PriceChangeInfo'
import styled from 'styled-components'
import useCurrencies from '../../hooks/useCurrencies'
import useSettings from '../../hooks/useSettings'

const Container = styled(TableContainer)`
  padding: 0 24px;
  height: calc(100vh - 650px);
  overflow-y: auto;
`

const BulkEditPriceTable = ({ newInventory }) => {
  const currencies = useCurrencies()
  const settings = useSettings()
  const mainCurrencyCode = settings.dualCurrency.main
    return (
    <>
      {currencies && (<Container>
        <Table aria-label="bulk edit price table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell><b>Departamento ID</b></TableCell>
              <TableCell><b>Precio Anterior ({currencies.main.code})</b></TableCell>
              <TableCell><Spacer size={7} /><b>Precio Nuevo ({currencies.main.code})</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newInventory.map(unit => (
              <TableRow key={unit.unit_id}>
                <TableCell>{unit.department_number}</TableCell>
                <TableCell>{`${currencies.main.symbol}${unit[`price_${mainCurrencyCode}`].toLocaleString()}`}</TableCell>
                <TableCell>
                  <FlexContainer verticalCentered>
                    <PriceChangeInfo basePrice={unit[`price_${mainCurrencyCode}`]} newPrice={unit.new_price} />
                    <Spacer size={1} />
                    {`${currencies.main.symbol}${unit.new_price.toLocaleString()}`}
                  </FlexContainer>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Container>)
      }
    </>
  )
}

export default BulkEditPriceTable
