import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { FlexContainer, Spacer } from '../../components';
import { useState } from 'react';

const StyledDialogActions = styled(DialogActions)`
  padding: 8px 24px 20px 24px;
`

const CheckLabel = styled(FormControlLabel)`
  margin-left: 0;
`

const AdvanceFeatureModal = ({ open, onClose, onAccept, children }) => {
  const [acceptRisk, setAcceptRisk] = useState(false)

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        Esta es una opción avanzada para programadores
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FlexContainer vertical>
            {children}
            <Spacer vertical size={2} />
            <FormGroup aria-label="position" row>
              <CheckLabel
                control={<Checkbox checked={acceptRisk} onChange={(e) => setAcceptRisk(e.target.checked)} />}
                label="Comprendo el riesgo"
                labelPlacement="start"
              />
            </FormGroup>
          </FlexContainer>
        </DialogContentText>
      </DialogContent>
      <StyledDialogActions>
        {onClose && (
          <Button onClick={onClose} color="primary">Cancelar</Button>
        )}
        {onAccept && (
          <Button onClick={onAccept} autoFocus color='primary' variant="contained" disabled={!acceptRisk}>
            Activar
          </Button>
        )}
      </StyledDialogActions>
    </Dialog>
  )
}

export default AdvanceFeatureModal
