import { List, Typography } from "@mui/material"
import { styled } from "@mui/system"
import { FlexContainer, Spacer } from "../../components"
import checkFancyIcon from '../../assets/svgs/check_fancy.svg'
import CheckIcon from '@mui/icons-material/Check'
import { useSearchParams } from "react-router-dom"
import { useEffect, useMemo } from "react"
import { Buttons, Container, Item, Logo, Panel, PLANS, Title } from "./PaymentResultCommons"
import smartlookClient from 'smartlook-client'
import { trackEvent } from "../../helpers/mixpanel"

const ColumnsContainer = styled(FlexContainer)`
  @media (max-width: 828px) {
    flex-direction: column;
  }
`

const Column = styled(FlexContainer)`
  width: 50%;
  flex-direction: column;
  @media (max-width: 828px) {
    width: 100%;
    align-items: center;
  }
`

const MonthlyPaymentSummary = styled(FlexContainer)`
  width: 300px;
  height: 250px;
  flex-direction: column;
  padding: 32px;
  background: #FFFFFF;
  box-shadow: 0px 0px 0px 1px #E0E0E0;
  border-radius: 4px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  @media (max-width: 828px) {
    width: 100%;
    height: unset;
    margin-bottom: 12px;
  }
`

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams()
  const plan = useMemo(() => {
    const planName = (searchParams.get('plan') || '').toLowerCase()
    return PLANS[planName] || PLANS['entrepreneur']
  }, [searchParams])

  useEffect(() => {
    smartlookClient.track('payment-success')
    trackEvent('payment-success')
  }, [])

  return (
    <Container fullWidth>
      <Panel>
        <Logo />
        <Spacer vertical size={1.5} />
        <Title variant='h3'>Pago exitoso <img src={checkFancyIcon} alt='éxito' /></Title>
        <Spacer vertical size={1.5} />
        <Typography>Bienvenido a la familia Alohome, te informamos que tu pago se ha procesado correctamente</Typography>
        <Spacer vertical size={4} />
        <Typography variant='h6'>Información sobre tu plan:</Typography>
        <Spacer vertical size={4} />
        <ColumnsContainer>
          <Column>
            <MonthlyPaymentSummary>
              <Typography variant="h5">Plan {plan.name}</Typography>
              {plan.monthlyFee && <Typography variant="h6"><sup>USD</sup> ${plan.monthlyFee}/mes</Typography>}
              {!plan.monthlyFee && <Typography variant="h6">Cotización personalizada</Typography>}
            </MonthlyPaymentSummary>
          </Column>
          <Column>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {plan.benefits.map((benefit, index) => <Item key={index} text={benefit.text} icon={!benefit.noIcon && <CheckIcon color="primary" />} />)}
            </List>
          </Column>
        </ColumnsContainer>
        <Spacer vertical size={4} />
        <Buttons />
      </Panel>
    </Container>
  )
}

export default PaymentSuccess