import Avatar from '@mui/material/Avatar';

function stringAvatar(string) {
  return {
    children: `${string.charAt(0)}`,
  };
}

const InitialsAvatar = ({string}) => {
  return (
    <Avatar {...stringAvatar(string)} />
  )
}

export default InitialsAvatar