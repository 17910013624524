export default function validateTypologyFields(fields, currency = "MXN") {
  const errors = [];
  if (!fields.name?.es) errors.push("El nombre de la tipología es requerido.");
  if (!fields.price?.[currency]) errors.push("El precio de la tipología es requerido.");
  if (fields.characteristics.length < 3)
    errors.push("Debes incluir al menos tres características.");
  fields.characteristics.forEach((characteristic, index) => {
    if (!characteristic.label?.es)
      errors.push(`El texto de la característica ${index + 1} es requerido.`);
    if (!characteristic.icon)
      errors.push(`El icono de la característica ${index + 1} es requerido.`);
  });
  if (!fields.description?.es)
    errors.push("La descripción de la tipología es requerida.");
  if (fields.images.length < 3)
    errors.push("Debes incluir al menos tres imágenes para la tipología.");
  if (fields.images.length > 6)
    errors.push("Puedes incluir máximo 6 imágenes para la tipología.");
  return errors;
}
