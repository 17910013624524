import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import {
  findPathsByHubidSlug,
  getPathValue,
  mergePaths,
} from '../../helpers/data';
import { getSitePreviewUrl } from '../../helpers/draft';
import { post, put } from '../../helpers/request';
import { getContactMethodsSettingsFromData } from '../../helpers/settings';
import { THEME_TYPES } from '../../helpers/themes';
import { saveDraftFn } from './useSaveDraft';

export const usePublishDraft = ({
  site,
  data,
  inventory,
  themeConfig,
  general,
  options,
}) => {
  const { mutate, isLoading } = useMutation(publishDraftFn, options);

  const publishDraft = useCallback(() => {
    const previewUrl = getSitePreviewUrl(site);
    if (!previewUrl) return;
    return mutate({
      siteId: site?.site_id,
      previewUrl,
      data,
      inventory,
      themeConfig,
      general,
    });
  }, [site, mutate, data, inventory, themeConfig, general]);

  return {
    publishDraft,
    isLoading,
  };
};

async function publishDraftFn({
  siteId,
  previewUrl,
  data,
  inventory,
  themeConfig,
  general,
}) {
  const { logo, contact, location } = getSettingsFromData(data);
  await saveDraftFn({ previewUrl, inventory, data, themeConfig, siteId, general });

  if (logo) {
    await put('/internal/settings/v1/settings/showroom/logo', logo, siteId);
    // This request is made for backward compatibility. Don't wait for it to be
    // resolved.
    put(
      '/internal/developer/v1/assets',
      { asset_id: logo.assetId, tags: ['logo'] },
      siteId,
    );
  }
  if (contact)
    await put(
      '/internal/settings/v1/settings/showroom/contact',
      contact,
      siteId,
    );
  if (location)
    await put(
      '/internal/settings/v1/settings/showroom/config',
      { location },
      siteId,
    );
  if (themeConfig)
    await put(
      '/internal/settings/v1/settings/showroom/config',
      {
        colors: getThemeColors(themeConfig).colors[+themeConfig.version - 1],
        theme: themeConfig.theme,
        theme_version: themeConfig.version,
        theme_custom_colors: themeConfig.customColors
      },
      siteId,
    );
  await post(
    '/internal/developer/v3/publish',
    { typology: getPublishTypologies(inventory) },
    siteId,
  );
}

export function getSettingsFromData(data) {
  const [logoPath] = findPathsByHubidSlug(data, 'project-logo');
  const logo = logoPath
    ? getPathValue(data, mergePaths(logoPath, 'logo'))
    : null;

  const contact = getContactMethodsSettingsFromData(data);

  let location = null;
  const [smallMapSectionPath] = findPathsByHubidSlug(data, 'small-map-section');
  const smallMapSectionData = smallMapSectionPath
    ? getPathValue(data, mergePaths(smallMapSectionPath), 'location')
    : null;
  if (
    smallMapSectionData?.location?.coordinates &&
    smallMapSectionData?.location?.address
  ) {
    location = {
      lat: smallMapSectionData.location.coordinates.latitude,
      lng: smallMapSectionData.location.coordinates.longitude,
      address: smallMapSectionData.location.address,
    };
  }

  return {
    logo,
    contact,
    location,
  };
}

function getThemeColors(themeConfig) {
  return THEME_TYPES.find((item) => item.value === themeConfig.theme);
}

function getPublishTypologies(inventory) {
  return Object.entries(inventory.typology.items).map(([id, fields]) => {
    delete fields.id;
    return { id, fields };
  });
}
