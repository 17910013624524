import { Alert, AlertTitle } from '@mui/material';
import { styled } from '@mui/system';
import React, { useMemo } from 'react';
import { quoteTabs } from '../../helpers/tabsData';
import useBreakpoints from '../../hooks/useBreakpoints';
import useSite from '../../hooks/useSite';
import PageLayout from '../../layouts/PageLayout';

const QuoteBuilderFrame = styled('iframe')`
  width: 100%;
  height: calc(100% - 96px);
  border: 0;
  outline: none;
  @media only screen and (max-width: 641px) {
    height: 90vh;
  }
`

const QuoteBuilderView = () => {
  const { site } = useSite()
  const { isSmOrLower } = useBreakpoints()
  const productionSiteExists = !!(site?.site_domain && site.site_domain.length > 0)
  const builderUrl = useMemo(() => {
    if (productionSiteExists) {
      return `https://${site.site_domain}/sellers/dashboard`
    }
    return false
  }, [site, productionSiteExists])
if(isSmOrLower){
  return (
    <>
    {productionSiteExists &&
        <QuoteBuilderFrame sx={{position: "absolute"}} allow="clipboard-read; clipboard-write" src={builderUrl} title='Quote Builder' />
      }
    </>
  )
}

  return (
    <PageLayout
      links={quoteTabs}
    >
      {productionSiteExists &&
        <QuoteBuilderFrame allow="clipboard-read; clipboard-write" src={builderUrl} title='Quote Builder' />
      }
      {!productionSiteExists &&
        <Alert severity="info">
          <AlertTitle>¿Por qué no puedo realizar cotizaciones?</AlertTitle>
          Para poder realizar cotizaciones a tus clientes, necesitas primero publicar tu sitio.
        </Alert>
      }
    </PageLayout>
  )
}

export default QuoteBuilderView