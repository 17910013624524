import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { Box, Button, IconButton } from '@mui/material';
import React, { useMemo, useState } from 'react';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import ProjectCard from './ProjectCard';

const ProjectsList = ({ projects, onEdit, onAdd, onDelete, onClose }) => {
  const [deletingProjectId, setDeletingProjectId] = useState(null);

  const sortedProjects = useMemo(
    () =>
      Object.entries(projects)
        .map(([name, { location, gallery }]) => ({
          name,
          location,
          gallery,
        }))
        .sort(({ name: nameA }, { name: nameB }) => {
          if (nameA > nameB) {
            return 1;
          }
          if (nameA < nameB) {
            return -1;
          }
          return 0;
        }),
    [projects],
  );

  return (
    <>
      <IconButton onClick={onClose} sx={{ ml: 'auto' }}>
        <CloseIcon />
      </IconButton>
      <Button
        variant="contained"
        color="primary"
        endIcon={<AddIcon />}
        onClick={onAdd}
        sx={{ mr: 'auto', mb: 4 }}
      >
        Agregar un proyecto
      </Button>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(333px, 1fr))',
          gap: 4,
        }}
      >
        {sortedProjects?.map(({ name, location, gallery }) => (
          <ProjectCard
            key={name}
            name={name}
            location={location}
            gallery={gallery}
            onDelete={
              sortedProjects.length > 1
                ? () => setDeletingProjectId(name)
                : undefined
            }
            onEdit={() => onEdit(name)}
          />
        ))}
      </Box>
      <DeleteConfirmationDialog
        open={!!deletingProjectId}
        onClose={() => setDeletingProjectId(null)}
        onDelete={() => {
          setDeletingProjectId((name) => {
            onDelete(name);
            return null;
          });
        }}
        name={deletingProjectId}
      />
    </>
  );
};

export default ProjectsList;
