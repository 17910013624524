import {
  alpha,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormGroup,
  FormLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FlexContainer, PendingButton, Spacer } from '../../components';
import useProfile from '../../hooks/useProfile';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import FlatLogo from '../../assets/images/flat-logo-2.png';
import {
  contactFieldsRow,
  financingFieldsRow,
  generalFieldsRows,
  locationFieldsRows,
  permissionsFieldsRow,
  visitingHoursFieldsRow,
  visualMaterialFieldsRow,
} from './ConfigFields';
import { FilterBar } from './CustomFilterBar';
import { FlatLayout } from './Layout';
import {
  filterConditions,
  initialForm,
  units_columns,
  units_header_row,
  weekDays,
} from './utils';
import { ReactGrid } from '@silevis/reactgrid';
import { BulkEditor } from '../Intelimetrica/BulkEditor';
import { get, patch, post } from '../../helpers/request';
import useSite from '../../hooks/useSite';
import useLocale from '../../hooks/useLocale';
import { renderField } from './renderFields';
import { emailRegex } from '../../helpers/regex';
import { TimePicker } from '@mui/x-date-pickers';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { useLoadScript } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import { flatTabs } from '../../helpers/tabsData';

const ReactGridContainer = styled.div`
  max-height: 70vh;
  max-width: 100%;
  overflow: auto;
  .rg-cell[data-cell-rowidx='0'] {
    background-color: var(--color-primary-10) !important;
    color: var(--color-primary) !important;
  }
  .rg-cell {
    text-align: center;
  }
  .rg-checkbox-cell > label > span {
    border: 2px solid var(--color-text-secondary) !important;
    background-color: transparent !important;
  }
  .reactgrid-content
    .rg-pane
    .rg-cell.rg-checkbox-cell
    label
    input:checked
    ~ span {
    background-color: var(--color-primary) !important;
  }
  .rg-checkbox-cell > label > span::after {
    border-width: 0 2px 2px 0 !important;
  }
`;

const FixedButtonContainer = styled(FlexContainer)`
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 20px 0;
  display: inline-block;
  width: 100%;
`;

const CustomTimePicker = styled(TimePicker)`
  width: 110px;
`;

const CustomCheckboxFormLabel = styled(FormLabel)`
  margin-top: auto;
  margin-bottom: auto;
`;

const CustomPublishButton = styled(Button)`
  margin: 20px auto;
  display: flex;
`;

const mapLibraries = ['places'];

const FlatConfig = () => {
  const [isPublishing, setIsPublishing] = useState(false);
  const navigate = useNavigate();
  const { site } = useSite();
  const [openBulkEditor, setOpenBulkEditor] = useState(false);
  const [filters, setFilters] = useState(null);
  const [openFilters, setOpenFilters] = useState(false);
  const [filterColumns, setFilterColumns] = useState([]);
  const [rowHeaders, setRowHeaders] = useState(units_header_row);
  const [unitsColumns] = useState(units_columns);
  const [errors] = useState({});
  const [formButtonDisabled, setFormButtonDisabled] = useState(false);
  const [form, setForm] = useState(initialForm);
  const [savedForm, setSavedForm] = useState(initialForm);
  const profile = useProfile();
  const [reloadLayout, setReloadLayout] = useState(false);
  const [rows, setRows] = useState([]);
  const [savedRows, setSavedRows] = useState([]);
  const [currentState, setCurrentState] = useState(null);
  const [currentCity, setCurrentCity] = useState(null);

  const autocomplete = usePlacesAutocomplete();

  const validateForm = (data, rows) => {
    let validRows = true;
    for (let row of rows) {
      for (let cell of row.cells) {
        const value = cell.type === 'number' ? cell.value : cell.text;
        if (
          (value === undefined ||
            value === null ||
            value === '' ||
            isNaN(value)) &&
          cell.type !== 'checkbox' &&
          !cell.nonEditable &&
          cell.required
        ) {
          validRows = false;
          break;
        }
      }
    }

    if (
      !data.title ||
      !data.description ||
      !data.development_name ||
      !data.project_type ||
      !data.construction_year ||
      !data.street ||
      !data.exterior_num ||
      !data.area ||
      !data.zip_code ||
      !data.state ||
      !data.city ||
      !data.municipality ||
      !data.external_mortgage_loans ||
      !data.tours_guided_by_developer_staff ||
      data?.entities.filter((e) => !!e).length === 0 ||
      !data?.email?.match(emailRegex) ||
      !data.phone ||
      !data.whatsapp ||
      !validRows ||
      !data.coordinates ||
      !data.additional_data
    ) {
      return false;
    }
    return true;
  };

  const locale = useLocale();

  const checkedRows = useMemo(() => {
    const checkRows = rows.filter((row) => row.cells[0].checked);
    return checkRows;
  }, [rows]);

  const enableSave = useMemo(() => {
    return (
      JSON.stringify(savedForm) !== JSON.stringify(form) ||
      JSON.stringify(savedRows) !== JSON.stringify(rows)
    );
  }, [savedForm, form, savedRows, rows]);

  const handleForm = (name, value) => {
    setForm((form) => ({ ...form, [name]: value }));
  };

  const filteredRows = useMemo(() => {
    let customRows = rows;
    if (filters) {
      switch (filters.condition) {
        case 'contains':
          customRows = customRows.filter((row) =>
            `${row.data.fields[filters.column]}`.includes(filters.value)
          );
          break;
        case 'equals_to':
          customRows = customRows.filter(
            (row) => `${row.data.fields[filters.column]}` === filters.value
          );
          break;
        case 'starts_with':
          customRows = customRows.filter((row) =>
            `${row.data.fields[filters.column]}`.startsWith(filters.value)
          );
          break;
        case 'ends_with':
          customRows = customRows.filter((row) =>
            `${row.data.fields[filters.column]}`.endsWith(filters.value)
          );
          break;
        case 'empty':
          customRows = customRows.filter(
            (row) => !`${row.data.fields[filters.column]}`
          );
          break;
        case 'not_empty':
          customRows = customRows.filter(
            (row) => !!`${row.data.fields[filters.column]}`
          );
          break;
        case 'any_of':
          customRows = customRows.filter((row) =>
            filters.values.includes(`${row.data.fields[filters.column]}`)
          );
          break;
        default:
          break;
      }
    }
    return customRows;
  }, [rows, filters]);

  const applyChanges = (changes, prev) => {
    let newRowHeader = rowHeaders;
    changes.forEach((change) => {
      const rowName = change.rowId;
      const fieldName = change.columnId;
      const columnIndex = unitsColumns.indexOf(
        unitsColumns.find((c) => c.columnId === fieldName)
      );
      const rowIndex = rows.indexOf(rows.find((c) => c.rowId === rowName));
      if (change.rowId === 'header' && fieldName === 'checkbox') {
        newRowHeader.cells[columnIndex] = change.newCell;
        prev = prev.map((r) => {
          r.cells[columnIndex] = change.newCell;
          return { ...r };
        });
      } else {
        prev[rowIndex].cells[columnIndex] = change.newCell;
      }
    });
    setRowHeaders(newRowHeader);
    return [...prev];
  };

  const handleGridChanges = (changes) => {
    setRows((prev) => applyChanges(changes, prev));
  };

  const onSaveBulk = (_, form, checked) => {
    const newCheckedRows = checkedRows;
    const formArray = Object.entries(form);
    newCheckedRows.forEach((_, index) => {
      for (let item of formArray) {
        const itemName = item[0];
        const newValue = item[1];
        const column = rowHeaders.cells.find((c) => c.bulk_name === itemName);
        const columnIndex = rowHeaders.cells.indexOf(column);
        if (checked[itemName]) {
          const type = column.bulkType;
          newCheckedRows[index].cells[columnIndex][
            type === 'number' ? 'value' : 'text'
          ] = type === 'number' ? Number(newValue) : newValue;
        }
      }
    });
    const newRows = [];
    for (let row of rows) {
      const newRow = newCheckedRows.find((nr) => nr.rowId === row.rowId);
      if (!!newRow) {
        newRows.push(newRow);
      } else {
        newRows.push(row);
      }
    }
    setRows(newRows);
    setOpenBulkEditor(false);
  };

  useEffect(() => {
    if (
      currentState !== '' &&
      currentState !== null &&
      currentState !== form.state
    ) {
      setForm((prev) => ({
        ...prev,
        city: '',
      }));
    }
    setCurrentState(form.state);
  }, [form.state, currentState]);

  useEffect(() => {
    if (
      currentCity !== '' &&
      currentCity !== null &&
      currentCity !== form.city
    ) {
      setForm((prev) => ({
        ...prev,
        municipality: '',
      }));
    }
    setCurrentCity(form.city);
  }, [form.city, currentCity]);

  useEffect(() => {
    const validation = validateForm(form, rows);
    setFormButtonDisabled(!validation);
  }, [form, rows]);

  useEffect(() => {
    if (site?.site_id) {
      Promise.all([
        get('/internal/alohub/inventory?type=unit', {
          site_id: site?.site_id,
        }),
        get('/internal/alohub/integrations/flat/config/config-data', {
          site_id: site?.site_id,
        }),
      ]).then(([units, configs]) => {
        if (configs) {
          if (configs.street) {
            autocomplete.setValue(configs.street, false);
          }
          setForm((prev) => ({
            ...prev,
            ...JSON.parse(JSON.stringify(configs)),
          }));
          setSavedForm((prev) => ({
            ...prev,
            ...JSON.parse(JSON.stringify(configs)),
          }));
        }
        const inventory = [...units];
        const array = [];
        const firstItem = inventory[0];
        if (firstItem) {
          setFilterColumns(
            Object.entries(firstItem.fields)
              .filter(([key, value]) => typeof value !== 'object')
              .map(([key]) => ({ label: key, value: key }))
          );
        }
        for (let item of inventory) {
          let cells = [
            { type: 'checkbox', checked: false },
            {
              type: 'text',
              text: item?.id,
              nonEditable: true,
            },
            {
              type: 'number',
              value: Number(item?.fields?.prices?.MXN || 0),
              placeholder: '-',
              nonEditable: true,
              required: true,
            },
            {
              type: 'number',
              value: Number(item?.fields?.construction_size),
              placeholder: '-',
              required: true,
            },
            {
              type: 'number',
              value: Number(item?.fields?.bedrooms),
              placeholder: '-',
              required: true,
            },
            {
              type: 'number',
              value: Number(item?.fields?.bathrooms),
              placeholder: '-',
              required: true,
            },
            {
              type: 'number',
              value: Number(item?.fields?.half_bathrooms),
              placeholder: '-',
            },
            {
              type: 'number',
              value: Number(item?.fields?.parking_lots),
              placeholder: '-',
              required: true,
            },
            {
              type: 'number',
              value: Number(item?.fields?.balcon_m2),
              placeholder: '-',
            },
            {
              type: 'number',
              value: Number(item?.fields?.roof_top_m2),
              placeholder: '-',
            },
            {
              type: 'number',
              value: Number(item?.fields?.floor),
              placeholder: '-',
            },
            {
              type: 'text',
              text:
                item?.fields?.department_number !== null &&
                item?.fields?.department_number !== undefined
                  ? `${item?.fields?.department_number}`
                  : '',
              placeholder: '-',
              nonEditable: true,
            },
            {
              type: 'number',
              value: Number(item?.fields?.initial_payment),
              placeholder: '-',
            },
          ];
          array.push({
            rowId: item.inventory_id,
            data: item,
            cells,
          });
        }
        setRows(array);
        setSavedRows(JSON.parse(JSON.stringify(array)));
      });
    }
    // eslint-disable-next-line
  }, [site?.site_id, locale, autocomplete.setValue]);

  const handlePublishOnFlat = () => {
    setIsPublishing(true);
    handleSaveData()
      .then(() =>
        get(`/internal/alohub/integrations/flat/config/completed`, {
          site_id: site.site_id,
        })
      )
      .then((completed) =>
        post(
          '/internal/alohub/integrations/flat/config/publish',
          {},
          site.site_id
        ).then(() => {
          setIsPublishing(false);
          navigate(`../${flatTabs.tabs[2].href}`, {
            replace: true,
            state: { completed },
          });
        })
      );
  };

  const handleSaveData = () => {
    const inventory = [];
    for (let row of rows) {
      const newRow = row.data;
      const cells = row.cells;
      for (let col of unitsColumns) {
        const columnId = col.columnId;
        const colIndex = unitsColumns.indexOf(col);
        const cell = cells[colIndex];
        const cellType = cell.type;
        newRow.fields[columnId] =
          cellType === 'number' ? cell.value : cell.text;
      }
      newRow.fields.prices = {
        ...newRow.fields.prices,
        MXN: newRow.fields.price,
      };

      delete newRow.fields.checkbox;
      delete newRow.fields.unit_id;
      delete newRow.fields.price;
      inventory.push(newRow);
    }
    setSavedForm(JSON.parse(JSON.stringify(form)));
    setSavedRows(JSON.parse(JSON.stringify(rows)));
    return Promise.all([
      patch(
        '/internal/alohub/integrations/flat/inventory',
        {
          items: [...inventory],
        },
        site.site_id
      ),
      post(
        '/internal/alohub/integrations/flat/config/config-data',
        {
          ...form,
        },
        site.site_id
      ),
    ]);
  };

  return isPublishing ? (
    <FlexContainer fullHeight centered verticalCentered vertical>
      <Box
        component="img"
        src={FlatLogo}
        alt="flat-logo"
        sx={{ maxWidth: 200, display: 'block' }}
      />
      <Spacer vertical size={3} />
      <Typography variant="body1">
        Estamos enviando la información de tu proyecto a Flat.mx
      </Typography>
      <Spacer vertical size={3} />
      <CircularProgress size={42} color="primary" variant="indeterminate" />
    </FlexContainer>
  ) : (
    <FlatLayout
      menu={
        <>
          <PendingButton
            text="Guardar"
            onClick={handleSaveData}
            showBullet={enableSave}
          />
        </>
      }
      reloadLayout={reloadLayout}
      setReloadLayout={setReloadLayout}
    >
      <Spacer vertical size={3} />
      <Typography variant="h5">
        Mostremos tu proyecto a las personas indicadas
      </Typography>
      <Spacer vertical size={3} />
      <Typography>{profile.name}, necesitamos un poco de tu ayuda.</Typography>
      <Spacer vertical size={3} />
      <Typography>
        Con las características que nos compartas podremos enfocar mejor tu
        proyecto y hacerlo más relevante para las personas indicadas, que buscan
        una nueva propiedad.
      </Typography>
      <Spacer vertical size={4} />
      <Box
        sx={{
          py: 1,
          px: 4,
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
          borderRadius: 4,
        }}
      >
        <Typography
          sx={{
            textTransform: 'uppercase',
            color: 'primary.main',
            fontWeight: 500,
          }}
        >
          INFORMACIÓN del proyecto
        </Typography>
      </Box>
      <Spacer vertical size={4} />
      <Typography variant="h6">General</Typography>
      <Spacer vertical size={4} />
      {generalFieldsRows.map((row, index) => (
        <React.Fragment key={index}>
          <FlexContainer fullWidth>
            {row.map((field, index) =>
              renderField(field, index, form, errors, handleForm)
            )}
          </FlexContainer>
          <Spacer vertical size={4} />
        </React.Fragment>
      ))}
      <Typography variant="h6">Ubicación</Typography>
      <Spacer vertical size={4} />
      {locationFieldsRows.map((row, index) => (
        <React.Fragment key={index}>
          <FlexContainer fullWidth>
            {row.map((field, index) =>
              renderField(field, index, form, errors, handleForm, autocomplete)
            )}
          </FlexContainer>
          <Spacer vertical size={4} />
        </React.Fragment>
      ))}
      <Typography variant="h6">Financiación</Typography>
      <Spacer vertical size={4} />
      {financingFieldsRow.map((row, index) => (
        <React.Fragment key={index}>
          <FlexContainer fullWidth>
            {row.map((field, index) =>
              renderField(field, index, form, errors, handleForm)
            )}
          </FlexContainer>
          <Spacer vertical size={4} />
        </React.Fragment>
      ))}
      <Typography variant="h6">Material visual</Typography>
      <Spacer vertical size={4} />
      {visualMaterialFieldsRow.map((row, index) => (
        <React.Fragment key={index}>
          <FlexContainer fullWidth>
            {row.map((field, index) =>
              renderField(field, index, form, errors, handleForm)
            )}
          </FlexContainer>
          <Spacer vertical size={4} />
        </React.Fragment>
      ))}
      <Typography variant="h6">Información de contacto para visitas</Typography>
      <Spacer vertical size={4} />
      {contactFieldsRow.map((row, index) => (
        <React.Fragment key={index}>
          <FlexContainer fullWidth>
            {row.map((field, index) =>
              renderField(field, index, form, errors, handleForm)
            )}
          </FlexContainer>
          <Spacer vertical size={4} />
        </React.Fragment>
      ))}
      <Typography variant="subtitle2">Horario de visitas</Typography>
      <Spacer vertical size={4} />
      {weekDays.map((day) => (
        <>
          <Box
            key={day.label}
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
              maxWidth: 'md',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Typography variant="caption">{day.label}</Typography>
            <FlexContainer verticalCentered>
              <Switch
                checked={form.tourDays[day.code].active}
                onChange={({ target }) => {
                  setForm((prev) => ({
                    ...prev,
                    tourDays: {
                      ...prev.tourDays,
                      [day.code]: {
                        ...prev.tourDays[day.code],
                        active: target.checked,
                      },
                    },
                  }));
                }}
              />
              <Typography variant="caption">abierto</Typography>
            </FlexContainer>
            {form.tourDays[day.code].active && (
              <>
                <CustomTimePicker
                  ampm
                  value={form.tourDays[day.code].from}
                  onChange={(value) => {
                    setForm((prev) => ({
                      ...prev,
                      tourDays: {
                        ...prev.tourDays,
                        [day.code]: {
                          ...prev.tourDays[day.code],
                          from: value,
                        },
                      },
                    }));
                  }}
                  label="Desde"
                  renderInput={(params) => (
                    <TextField variant="standard" {...params} />
                  )}
                />
                <CustomTimePicker
                  ampm
                  value={form.tourDays[day.code].to}
                  onChange={(value) => {
                    setForm((prev) => ({
                      ...prev,
                      tourDays: {
                        ...prev.tourDays,
                        [day.code]: {
                          ...prev.tourDays[day.code],
                          to: value,
                        },
                      },
                    }));
                  }}
                  label="Hasta"
                  renderInput={(params) => (
                    <TextField variant="standard" {...params} />
                  )}
                />
              </>
            )}
          </Box>
          <Spacer vertical size={3} />
        </>
      ))}
      <Spacer vertical size={4} />
      {visitingHoursFieldsRow.map((row, index) => (
        <React.Fragment key={index}>
          <FlexContainer fullWidth>
            {row.map((field, index) =>
              renderField(field, index, form, errors, handleForm)
            )}
          </FlexContainer>
          <Spacer vertical size={4} />
        </React.Fragment>
      ))}
      <Typography variant="h6">Permisos</Typography>
      <Spacer vertical size={4} />
      {permissionsFieldsRow.map((row, index) => (
        <React.Fragment key={index}>
          <FlexContainer fullWidth>
            {row.map((field, index) =>
              renderField(field, index, form, errors, handleForm)
            )}
          </FlexContainer>
          <Spacer vertical size={4} />
        </React.Fragment>
      ))}
      <Box
        sx={{
          py: 1,
          px: 4,
          mb: 4,
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
          borderRadius: 4,
        }}
      >
        <Typography
          sx={{
            textTransform: 'uppercase',
            color: 'primary.main',
            fontWeight: 500,
          }}
        >
          INFORMACIÓN DE LAS Unidades
        </Typography>
      </Box>
      <Button
        onClick={() => setOpenBulkEditor(true)}
        disabled={checkedRows.length <= 0}
        variant="contained"
        color="primary"
      >
        EDITAR SELECCIONADOS
      </Button>
      <Spacer size={2} vertical />
      <FilterBar
        closeModal={() => setOpenFilters(false)}
        open={openFilters}
        setFilters={setFilters}
        conditions={filterConditions}
        columns={filterColumns}
      >
        <Button onClick={() => setOpenFilters(true)} color="primary">
          <FilterIcon />
          <Spacer size={1} />
          FILTROS
        </Button>
      </FilterBar>
      <Spacer size={2} vertical />
      <ReactGridContainer>
        <ReactGrid
          rows={[rowHeaders, ...filteredRows]}
          columns={unitsColumns}
          onCellsChanged={handleGridChanges}
          stickyLeftColumns={2}
          stickyTopRows={1}
        />
      </ReactGridContainer>
      <Spacer size={4} vertical />
      <BulkEditor
        columns={rowHeaders?.cells || []}
        isOpen={openBulkEditor}
        onClose={() => setOpenBulkEditor(false)}
        onSave={onSaveBulk}
      />
      <Spacer size={4} vertical />
      <FixedButtonContainer centered>
        <FormGroup aria-label="position" row>
          <Checkbox
            value={form.additional_data}
            name="additional_data"
            onChange={({ target }) => handleForm(target.name, target.checked)}
            id="additional-data"
          />
          <CustomCheckboxFormLabel htmlFor="additional-data">
            Acepto compartir información del proyecto como: País, latitud,
            longitud, amenidades, divisa principal, planes de pago, imágenes y
            recorridos 360°
          </CustomCheckboxFormLabel>
        </FormGroup>
        <CustomPublishButton
          variant="contained"
          color="primary"
          disabled={formButtonDisabled}
          onClick={handlePublishOnFlat}
        >
          PUBLICAR EN FLAT
        </CustomPublishButton>
      </FixedButtonContainer>
    </FlatLayout>
  );
};

const FlatConfigMiddleware = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: mapLibraries,
  });
  return isLoaded && !loadError ? <FlatConfig {...props} /> : null;
};

export default FlatConfigMiddleware;
