import { Box, List, ListItem, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef } from 'react';
import Lottie from "react-lottie";
import styled from 'styled-components';
import desktopHubAnimation from "../../assets/animations/desktopHub.json";
import quotationIcon from '../../assets/svgs/cotizacion.svg';
import creditCardIcon from '../../assets/svgs/credit_card.svg';
import mailIcon from '../../assets/svgs/mail.svg';
import { FlexContainer, Spacer } from '../../components';
import { post } from '../../helpers/request';
import themes from "../../helpers/themes";
import { filterByKeyValue, formatAsAssetIdKeyValue, formatAsGallerySlide, getGalleryItems } from '../../helpers/utils';
import useOnboardingContext from "../../hooks/useOnboardingContext";
import PublicLayout from '../../layouts/PublicLayout';

const Container = styled(FlexContainer)`
  padding: 30px 142px;
`

const Row = styled(Stack)`
  justify-content: center;

  @media only screen and (min-width: 833px) {
    flex-direction: row;
    align-items: flex-start;
    column-gap: 2rem;
  }
`

const BulletList = styled(List)`
  width: 100%;

  li:before {
    content: "";
    width: .75rem;
    height: .75rem;
    display: block;
    background: #000;
    flex-shrink: 0;
    margin-inline-end: 1rem;
    border-radius: 50%;
  }
`

const BulletListItem = styled(ListItem)`
  align-items: baseline;
`

const IconList = styled(List)`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 720px) {
    flex-direction: row;
  }
`

const IconListItem = styled(ListItem)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  flex-basis: auto;
  row-gap: .5rem;

  svg {
    width: 3rem;
    height: auto;
  }
`

const ImageIcon = styled.img`
  margin-block-end: .5rem;
`

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: desktopHubAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const loadingPreviewText = {
  title: "¡Estamos haciendo magia!",
  text: "A continuación verás una vista previa de tu showroom digital. Podrás:",
  previewBenefits: [
    "Terminar el <b>diseño por tu cuenta</b> y publicarlo ",
    "O solicitar <b>ayuda de diseñadores y programadores</b> para concluirlo y lanzarlo",
  ],
  subtitle: "Administra el 100% de tu proyecto con nuestras herramientas",
  toolsBenefits: [
    {
      icon: quotationIcon,
      label: "Emails automatizados"
    },
    {
      icon: creditCardIcon,
      label: "Crea cotizaciones interactivas"
    },
    {
      icon: mailIcon,
      label: "Recibe pagos online"
    },
  ]
}

const TEMP_SITE_URL = `/internal/login/v1/onboarding/temporary-site`

const PREVIEW_URL = {
  subdomainBase: "https://preview-temporal",
  mainDomain: "preview.alohome.io"
}

const ASSETS_BASE_URL = "https://assets.alohome.io"

const LoadingScreen = () => {
  const { onboardingData, onNextStep, setOnboardingData } = useOnboardingContext()
  const { title, text, previewBenefits, subtitle, toolsBenefits } = loadingPreviewText
  const theme = themes[onboardingData.theme.toLowerCase()]
  const timeOut = useRef();

  const addTempSiteId = useCallback((id) => {
    setOnboardingData((prev) => ({
    ...prev,
    tempSiteId: id
  }))},[setOnboardingData])

  const delayScreenChange = useCallback(() => new Promise((resolve) => {
    clearTimeout(timeOut.current);
    timeOut.current = setTimeout(resolve, 3000);
  }),[]);

  const createTempSite = useCallback(async () => {
    // Generate temp site ID
    const { id } = await post(TEMP_SITE_URL, {
      theme: onboardingData.theme,
      config: onboardingData.propertyType,
    })

    // Save temp site ID in onboardingData
    addTempSiteId(id)

    return id
  }, [addTempSiteId, onboardingData.propertyType, onboardingData.theme])

  const updateTemplate = useCallback(async (id) => {
    const tempSite = `${PREVIEW_URL.subdomainBase}-${id}.${PREVIEW_URL.mainDomain}`
    const GET_TEMPLATE_URL = `${tempSite}/api/data/data`
    const GET_INVENTORY_URL = onboardingData.propertyType !== 'LOT' && `${tempSite}/api/data/inventory`
    const templateData = await fetch(GET_TEMPLATE_URL)
    const inventoryData = GET_INVENTORY_URL && await fetch(GET_INVENTORY_URL)
    const templateJson = await templateData.json()
    const inventoryJson = inventoryData && await inventoryData.json()
    const landingPageSections = templateJson?.pages?.home?.sections
    const defaultTypology = inventoryJson && inventoryJson?.typology?.items['5237c9f6-18ef-11ed-861d-0242ac120002']
    const inventoryDefaultTypologyGallery = defaultTypology ? defaultTypology?.images : null
    const templateMiscGallery = filterByKeyValue(landingPageSections, "type", "masonry-gallery-section")[0]?.images

    const updatesWithHeroImage = onboardingData.projectAssets.mainImage.asset_id ? [
      {
        path: "pages.home.hero.media.images",
        value: [{ "url": `${ASSETS_BASE_URL}/images/${onboardingData.projectAssets.mainImage?.asset_id}`}]
      },
      {
        path: "meta.og_image",
        value: `${ASSETS_BASE_URL}/images/${onboardingData.projectAssets.mainImage.asset_id}`
      },
      {
        path: "meta.twitter_image",
        value: `${ASSETS_BASE_URL}/images/${onboardingData.projectAssets.mainImage.asset_id}`
      },
    ] : []
    const headerLogoUpdate = onboardingData.projectLogos.color.asset_id ? {
      path: "header.logo.url",
      value: `${ASSETS_BASE_URL}/images/${onboardingData.projectLogos.color.asset_id}`
    } : null
    const headerAssetIdLogoUpdate = onboardingData.projectLogos.color.asset_id ? {
      path: "header.logo.assetId",
      value: onboardingData.projectLogos.color.asset_id
    } : null
    const footerLogoUpdate = (onboardingData.projectLogos.white.asset_id || onboardingData.projectLogos.color.asset_id) ? {
      path: "footer.logo.url",
      value: `${ASSETS_BASE_URL}/images/${onboardingData.projectLogos.white.asset_id || onboardingData.projectLogos.color.asset_id}`
    } : null
    const footerAssetIdLogoUpdate = (onboardingData.projectLogos.white.asset_id || onboardingData.projectLogos.color.asset_id) ? {
      path: "footer.logo.assetId",
      value: onboardingData.projectLogos.white.asset_id || onboardingData.projectLogos.color.asset_id
    } : null
    const developerLogoUpdate = (onboardingData.developerLogos.white.asset_id || onboardingData.developerLogos.color.asset_id) ? {
      path: "pages.home.sections[default-carousel-projects].project.logo.url",
      value: `${ASSETS_BASE_URL}/images/${onboardingData.developerLogos.white.asset_id || onboardingData.developerLogos.color.asset_id}`
    } : null
    const bannerImageUpdate = onboardingData.projectAssets.secondaryImage.asset_id ? {
      path: "pages.home.sections[default-image-banner-section].image.url",
      value: `${ASSETS_BASE_URL}/images/${onboardingData.projectAssets.secondaryImage.asset_id}`
    } : null
    const videoUpdate = onboardingData.projectAssets.video.asset_id ? {
      path: "pages.home.sections[default-flex-media-section].video_source.url",
      value: `${ASSETS_BASE_URL}/assets/${onboardingData.projectAssets.video.asset_id}`
    } : null
    const distributionImageUpdate = (onboardingData.propertyType === 'LOT' && onboardingData.distributionAssets.asset_id) ? {
        path: "pages.home.sections[default-lots-view-section].image.url",
        value: `${ASSETS_BASE_URL}/images/${onboardingData.distributionAssets.asset_id}`
      } : null

    const miscGalleryImages = getGalleryItems(templateMiscGallery, onboardingData.miscAssets, "pages.home.sections[default-masonry-gallery-section].images", formatAsGallerySlide, ASSETS_BASE_URL)

    const nonRequiredAssets = [
      ...updatesWithHeroImage,
      headerLogoUpdate,
      headerAssetIdLogoUpdate,
      footerLogoUpdate,
      footerAssetIdLogoUpdate,
      developerLogoUpdate,
      bannerImageUpdate,
      videoUpdate,
      distributionImageUpdate,
      miscGalleryImages
    ]
    const filteredNonRequiredAssets = nonRequiredAssets.filter((item) => item?.path)
    const templateUpdates = {
      changes: [
        ...filteredNonRequiredAssets,
        {
          path: "meta.title",
          value: onboardingData.projectName
        },
        {
          path: "meta.favicon",
          value: ""
        },
        {
          path: "meta.description",
          value: `Showroom de ${onboardingData.projectName}`
        },
        {
          path: "meta.developer",
          value: onboardingData.projectName
        },
        {
          path: "meta.theme_color",
          value: theme.colors.option1.primary
        },
        {
          path: "meta.color_primary",
          value: theme.colors.option1.primary
        },
        {
          path: "meta.color_secondary",
          value: theme.colors.option1.secondary
        },
        {
          path: "pages.home.sections[default-small-map-section].location.address",
          value: onboardingData.location.address
        },
        {
          path: "pages.home.sections[default-small-map-section].card.address",
          value: onboardingData.location.address
        },
        {
          path: "pages.home.sections[default-small-map-section].location.coordinates",
          value: { latitude: onboardingData.location.lat, longitude: onboardingData.location.lng }
        },
        {
          path: "pages.home.sections[default-split-contact-section].contact_methods.address.content",
          value: onboardingData.location.address
        },
        {
          path: "pages.home.sections[default-split-contact-section].contact_methods.address.coordinates",
          value: { latitude: onboardingData.location.lat, longitude: onboardingData.location.lng }
        },
        {
          path: "footer.contact_methods.address.content",
          value: onboardingData.location.address
        },
        {
          path: "footer.contact_methods.address.coordinates",
          value: { latitude: onboardingData.location.lat, longitude: onboardingData.location.lng }
        },
      ],
    }
    const inventoryUpdates = onboardingData.propertyType !== 'LOT' && {
      changes: [
        {...getGalleryItems(inventoryDefaultTypologyGallery, onboardingData.typologyAssets, "typology.items.5237c9f6-18ef-11ed-861d-0242ac120002.images", formatAsAssetIdKeyValue, ASSETS_BASE_URL)}
      ],
    }

    await post(
      '/internal/developer/v1.1/files/temporal',
      {
        fileName: 'data',
        temporalId: id,
        ...templateUpdates,
      },
    );
    
    if(onboardingData.propertyType !== 'LOT') {
      await post(
        '/internal/developer/v1.1/files/temporal',
        {
          fileName: 'inventory',
          temporalId: id,
          ...inventoryUpdates,
        },
      );
    }
    await delayScreenChange();
  }, [delayScreenChange, onboardingData?.developerLogos?.color?.asset_id, onboardingData?.developerLogos?.white?.asset_id, onboardingData?.distributionAssets?.asset_id, onboardingData.location.address, onboardingData.location.lat, onboardingData.location.lng, onboardingData.miscAssets, onboardingData.projectAssets?.mainImage?.asset_id, onboardingData?.projectAssets?.secondaryImage?.asset_id, onboardingData?.projectAssets?.video?.asset_id, onboardingData.projectLogos.color.asset_id, onboardingData.projectLogos?.white?.asset_id, onboardingData.projectName, onboardingData.propertyType, onboardingData.typologyAssets, theme.colors.option1.primary, theme.colors.option1.secondary])

  const generatePreview = useCallback(() => {
    return createTempSite()
          .then((id) => updateTemplate(id))
  }, [createTempSite, updateTemplate])

  const handleNext = useCallback(() => {
    onNextStep()
  }, [onNextStep])

  const initializeRedirect = useCallback(() => {
    generatePreview()
      .then(() => handleNext())
      .catch((error) => console.log(error));
  }, [generatePreview, handleNext])

  useEffect(() => {
    initializeRedirect()
  }, [initializeRedirect])

  return (
    <PublicLayout>
      <Container vertical>
        <Typography variant='h2' textAlign='center'>{title}</Typography>
        <Spacer vertical size={8} />
        <Row>
          <Box>
            <Typography variant='h5'>{text}</Typography>
            <Spacer vertical size={3} />
            {previewBenefits.length > 0 &&
              <BulletList>
                {previewBenefits.map((item,index) =>
                  <BulletListItem key={index}>
                    <Typography variant='h5' dangerouslySetInnerHTML={{ __html: item }}/>
                  </BulletListItem>)}
              </BulletList>}
          </Box>
          <Box>
            <Lottie options={defaultOptions} height={184} width={265} />
          </Box>
        </Row>
        <Spacer vertical size={9} />
        <Stack alignItems="center">
          <Typography variant='h5' textAlign='center'>{subtitle}</Typography>
          {toolsBenefits.length > 0 &&
            <IconList>
              {toolsBenefits.map(({icon, label},index) =>
                <IconListItem key={index}>
                  <ImageIcon src={icon} alt='' />
                  <Typography>{label}</Typography>
                </IconListItem>)}
            </IconList>}
        </Stack>
      </Container>
    </PublicLayout>
  )
}

export default LoadingScreen
