import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  getRedirectUrl,
  redirectPrioritization,
  redirectProject,
  redirectTeam,
} from '../helpers/defaultLinks';
import { get } from '../helpers/request';
import useBreakpoints from '../hooks/useBreakpoints';
import useProfile from '../hooks/useProfile';
import useSite from '../hooks/useSite';
import { PlansModal, Sidebar } from '../modules';
import AnalyticView from './Analytics/AnalyticView';
import AppsView from './Apps/AppsView';
import CurrencyView from './Currency/CurrencyView';
import LoginMobile from './Auth/LoginMobile';
import DevelopmentDetailsView from './Developments/DevelopmentDetailsView';
import DevelopmentView from './Developments/DevelopmentsView';
import HostedZoneView from './HostedZone/HostedZoneView';
import IntegrationsView from './Integrations/IntegrationsView';
import {
  AboutIntelimetrica,
  ConfigIntelimetrica,
  GeneratingReportIntelimetrica,
  ReportIntelimetrica,
} from './Intelimetrica';
import InventoryUploadView from './Inventory/InventoryUploadView';
import InventoryView from './Inventory/InventoryView';
import NOM247View from './NOM247/NOM247View';
import NoSitesView from './NoSites/NoSitesView';
import OnboardingView from './Onboarding/OnboardingView';
import PanelView from './Panel/PanelView';
import ProfileView from './Profile/ProfileView';
import ProspectsView from './Prospects/ProspectsView';
import TypologyQuotationView from './Quotation/TypologyQuotationView';
import UnitQuotationView from './Quotation/UnitQuotationView';
import QuoteBuilderView from './QuoteBuilder/QuoteBuilderView';
import ShowroomEditorView from './ShowroomEditor/ShowroomEditorView';
import StylesheetsView from './Stylesheets/StylesheetsView';
import TeamView from './Team/TeamView';
import WelcomeView from './Welcome/WelcomeView';
import WorkProgressView from './WorkProgress/WorkProgressView';
import WelcomeToFlatView from './Flat/Welcome';
import FlatConfig from './Flat/Config';
import CompletedFlat from './Flat/Completed';
import NotificationsView from './Notifications/NotificationsView';
import PublicLayout from '../layouts/PublicLayout';
import QuoteChooseProjectView from './QuoteBuilder/QuoteChooseProjectView';

const HubView = () => {
  const profile = useProfile();
  const dispatch = useDispatch();
  const analyticsLinks = useSelector((state) => state.analyticsLinks);
  const [hideSidebar, setHideSidebar] = useState(false);
  const { site: selectedSite } = useSite({ fetchSite: true });
  const { isSmOrLower } = useBreakpoints();

  useEffect(() => {
    if (selectedSite) {
      get('/internal/inventory/v1/links', { site_id: selectedSite.site_id })
        .then((result) => {
          dispatch({ type: 'analytics-links', data: result });
        })
        .catch((error) => console.error(error));
    }
  }, [dispatch, selectedSite]);

  const toRedirect = useMemo(
    () => getRedirectUrl(selectedSite, redirectPrioritization),
    [selectedSite]
  );

  const toRedirectProject = useMemo(
    () => getRedirectUrl(selectedSite, redirectProject),
    [selectedSite]
  );

  const toRedirectTeam = useMemo(
    () => getRedirectUrl(selectedSite, redirectTeam),
    [selectedSite]
  );

  if (isSmOrLower) {
    return (
      <PublicLayout>
        <Routes>
          <Route path="/" element={<LoginMobile />} />
          <Route path="/quote/builder" element={<QuoteBuilderView />} />
          <Route path="/choose-project" element={<QuoteChooseProjectView />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>  
      </PublicLayout>
    ) 
  } 

  return (
    <>
      <PlansModal />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: hideSidebar ? '1fr' : '332px 1fr',
          gridTemplateRows: '100vh',
        }}
      >
        <Sidebar hideSidebar={hideSidebar} />
        <Box sx={{ overflowX: 'auto', width: 1 }}>
          {profile && profile.sites.length === 0 ? (
            <Routes>
              <Route path="/" element={<NoSitesView />} />
              <Route path="/profile" element={<ProfileView />} />
              <Route
                path="/new-project"
                element={<OnboardingView setHideSidebar={setHideSidebar} />}
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/project">
                <Route path="panel" element={<PanelView />} />
                <Route path="inventory" element={<InventoryView />} />
                <Route path="inventory/upload" element={<InventoryUploadView />} />
                <Route path="typology-quote" element={<TypologyQuotationView />} />
                <Route path="unit-quote" element={<UnitQuotationView />} />
                <Route path="work-progress" element={<WorkProgressView />} />
                <Route path="dual-currency" element={<CurrencyView />} />
                {toRedirectProject && (
                  <Route
                    path=""
                    element={<Navigate to={toRedirectProject} replace />}
                  />
                )}
              </Route>
              <Route path="/digital-showroom">
                <Route path="editor" element={<ShowroomEditorView />} />
                <Route path="" element={<Navigate to="editor" replace />} />
              </Route>
              <Route path="/prospects">
                <Route
                  path="funnel"
                  element={
                    <AnalyticView url={analyticsLinks?.funnel} title="Embudo" />
                  }
                />
                <Route
                  path="features"
                  element={
                    <AnalyticView
                      url={analyticsLinks?.features}
                      title="Funcionalidades"
                    />
                  }
                />
                <Route
                  path="users"
                  element={
                    <AnalyticView
                      url={analyticsLinks?.users}
                      title="Usuarios"
                    />
                  }
                />
                <Route
                  path="audience"
                  element={
                    <AnalyticView
                      url={analyticsLinks?.audience}
                      title="Audiencia"
                    />
                  }
                />
                <Route
                  path="behavior"
                  element={
                    <AnalyticView
                      url={analyticsLinks?.behavior}
                      title="Comportamiento"
                    />
                  }
                />
                <Route path="prospects" element={<ProspectsView />} />
                <Route path="" element={<Navigate to="prospects" replace />} />
              </Route>
              <Route path="/team">
                <Route path="supervisor">
                  <Route
                    index
                    element={<TeamView role="SUPERVISOR" view="list" />}
                  />
                  <Route
                    path="add"
                    element={<TeamView role="SUPERVISOR" view="add" />}
                  />
                  <Route
                    path="edit"
                    element={<TeamView role="SUPERVISOR" view="edit" />}
                  />
                </Route>
                <Route path="sales">
                  <Route
                    index
                    element={<TeamView role="SALES" view="list" />}
                    />
                  <Route
                    path="add"
                    element={<TeamView role="SALES" view="add" />}
                    />
                  <Route
                    path="edit"
                    element={<TeamView role="SALES" view="edit" />}
                    />
                </Route>
                {toRedirectTeam && (
                  <Route
                    path=""
                    element={<Navigate to={toRedirectTeam} replace />}
                  />
                )}
              </Route>
              <Route path="/marketing">
                <Route path="utm-links" element={<>utm-links</>} />
                <Route path="" element={<Navigate to="utm-links" replace />} />
              </Route>
              <Route path="/nom247" element={<NOM247View />} />
              <Route path="/config">
                <Route path="integrations" element={<IntegrationsView />} />
                <Route path="css" element={<StylesheetsView />} />
                <Route path="hosted-zone" element={<HostedZoneView />} />
                <Route path="notifications" element={<NotificationsView />} />
                <Route path="" element={<Navigate to="notifications" replace />} />
              </Route>
              <Route path="/profile" element={<ProfileView />} />
              <Route path="/projects">
                <Route path=":id" element={<DevelopmentDetailsView />} />
                <Route path="" element={<DevelopmentView />} />
              </Route>
              <Route
                path="/new-project"
                element={<OnboardingView setHideSidebar={setHideSidebar} />}
              />
              <Route path="/quote">
                <Route path="builder" element={<QuoteBuilderView />} />
                <Route path="" element={<Navigate to="builder" replace />} />
              </Route>
              <Route path="/apps">
                <Route path=":category" element={<AppsView />} />
                <Route
                  path=""
                  element={<Navigate to="data" replace />}
                />
              </Route>
              <Route path="/intelimetrica">
                <Route path="about" element={<AboutIntelimetrica />} />
                <Route path="config" element={<ConfigIntelimetrica />} />
                <Route
                  path="generating-report"
                  element={<GeneratingReportIntelimetrica />}
                />
                <Route path="report" element={<ReportIntelimetrica />} />
                <Route path="" element={<Navigate to="report" replace />} />
              </Route>
              <Route path="/flat">
                <Route path="welcome" element={<WelcomeToFlatView />} />
                <Route path="config" element={<FlatConfig />} />
                <Route path="completed" element={<CompletedFlat />} />
                <Route path="" element={<Navigate to="welcome" replace />} />
              </Route>
              <Route path="/welcome" element={<WelcomeView />} />
              {toRedirect && (
                <Route
                  path="*"
                  element={<Navigate to={toRedirect} replace />}
                />
              )}
            </Routes>
          )}
        </Box>
      </Box>
    </>
  );
};

export default HubView;
