
const setObjToPathRecursive = (obj, path, value, index = 0) => {
    const pathSplited = path.split(/\.|\[/);
    const pathPart = pathSplited[index];
    if (index === pathSplited.length - 1) {
        if (pathPart.endsWith(']')) {
            const key = pathPart.replace(']', '');
            if (Array.isArray(obj)) {
                let keyIndex = -1;
                obj.forEach((e, i) => {
                    if (e.id === key) {
                        keyIndex = i;
                    }
                });
                if (keyIndex !== -1) {
                    obj[keyIndex] = value;
                }
            }
        } else {
            obj[pathPart] = value;
        }
    } else {
        let keyName = pathPart;
        if (pathPart.endsWith(']')) {
            const key = pathPart.replace(']', '');
            let keyIndex = -1;
            if (Array.isArray(obj)) {
                obj.forEach((e, i) => {
                    if (e.id === key) {
                        keyIndex = i;
                    }
                });
                if (keyIndex !== -1) {
                    keyName = keyIndex;
                }
            }
        } else {
            if (!obj[pathPart]) {
                obj[pathPart] = {};
            }
        }
        index++;
        setObjToPathRecursive(obj[keyName], path, value, index);
    }
};

// eslint-disable-next-line
const pathRegex = /(([a-zA-Z0-9\-\_]|(\[+[a-zA-Z0-9\-\_]+\]))+(.|))+$/g;

export const changesReplacements = async (changes, dataFile) => {
    const dataClone = dataFile;
    changes.forEach(({ path, value }) => {
        if (path?.match(pathRegex)?.length === 1) {
            try {
                setObjToPathRecursive(dataClone, path, value);
            } catch { }
        }
    });
    return dataClone;
};