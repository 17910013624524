import {
  Box,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import React, { useCallback } from 'react';
import { getKeysAsPath, modifyPath } from '../../../helpers/data';

export const DAYS_KEYS = [
  { key: 'monday', label: 'Lunes' },
  { key: 'tuesday', label: 'Martes' },
  { key: 'wednesday', label: 'Miercoles' },
  { key: 'thursday', label: 'Jueves' },
  { key: 'friday', label: 'Viernes' },
  { key: 'saturday', label: 'Sábado' },
  { key: 'sunday', label: 'Domingo' },
];

const getDefaultBusinessHoursStart = () =>
  moment().hours(9).minutes(0).seconds(0).milliseconds(0).toDate();

const getDefaultBusinessHoursEnd = () =>
  moment().hours(18).minutes(0).seconds(0).milliseconds(0).toDate();

const BusinessHoursForm = ({ businessHours, setBusinessHours }) => {
  const setBusinessHoursPath = useCallback(
    (path, value) => {
      modifyPath(businessHours || {}, path, value, { recursive: true });
      setBusinessHours({ ...businessHours });
    },
    [businessHours, setBusinessHours]
  );
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 3fr 3fr',
          maxWidth: 'md',
          alignItems: 'center',
          gap: 2,
        }}
      >
        {DAYS_KEYS.map(({ key, label }) => {
          const isOpen = !!businessHours?.[key];
          const handleOpenChange = (checked) =>
            checked
              ? setBusinessHoursPath(key, {
                  from: getDefaultBusinessHoursStart(),
                  to: getDefaultBusinessHoursEnd(),
                })
              : setBusinessHoursPath(key, null);

          return (
            <React.Fragment key={key}>
              <Typography component="span">{label}</Typography>
              <FormControlLabel
                label={isOpen ? 'abierto' : 'cerrado'}
                control={
                  <Switch
                    checked={isOpen}
                    onChange={(e) => handleOpenChange(e.target.checked)}
                  />
                }
              />
              {!isOpen ? (
                <div />
              ) : (
                <TimePicker
                  label="Desde"
                  value={businessHours[key].from}
                  onChange={(v) =>
                    setBusinessHoursPath(getKeysAsPath([key, 'from']), v?.toDate())
                  }
                  renderInput={(params) => (
                    <TextField variant="standard" {...params} />
                  )}
                />
              )}
              {!isOpen ? (
                <div />
              ) : (
                <TimePicker
                  label="Hasta"
                  value={businessHours[key].to}
                  onChange={(v) =>
                    setBusinessHoursPath(getKeysAsPath([key, 'to']), v?.toDate())
                  }
                  renderInput={(params) => (
                    <TextField variant="standard" {...params} />
                  )}
                />
              )}
            </React.Fragment>
          );
        })}
      </Box>
    </LocalizationProvider>
  );
};

export default BusinessHoursForm;
