import React, { useEffect, useState, useRef, useCallback, Fragment } from 'react'
import PropTypes from 'prop-types'
import { FlexContainer, Spacer } from '../../components'
import {
  Paper,
  Divider,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  InputAdornment,
  Fab,
  Button,
  Chip,
  Typography
} from '@mui/material'
import styled from 'styled-components'
import { numberToCurrency } from '../../helpers/utils'
import Check from '@mui/icons-material/Check';
import Add from '@mui/icons-material/Add';
import { get } from '../../helpers/request'
import useCurrencies from '../../hooks/useCurrencies'
import useProfile from '../../hooks/useProfile'
import { useSelector } from 'react-redux'

const PricesWrapper = styled(FlexContainer)`
  padding: 24px;
  min-width: fit-content;
`

const FiltersWrapper = styled(FlexContainer)`
  padding: 24px;
`

const StyledPaper = styled(Paper)`
  flex: 1;
`

const PricesSeparator = styled(Divider)`
  min-width:8px;
  margin: 0 24px;
`

const StyledInput = styled(Input)`
  max-width: 140px;
 `

const StyledChip = styled(Chip)`
  margin-left: 8px;
  margin-bottom: 8px;
`

const DropdownFilters = styled.div`
  position: relative;
`

const DropdownFiltersContent = styled(Paper)`
  max-height: 350px;
  overflow-y: auto;
  position: absolute;
  min-width: 400px;
  top: 100%;
  left:0;
  z-index: 10;
  padding: 8px 24px;
  display: ${props => props.visible ? 'block' : 'none'};
`

const Filter = styled(Chip)`
  cursor: pointer;
`

const STATUS = {
  available: 'Disponible',
  sold: 'Vendido',
  reserved: 'Reservado',
  offline: 'No Publicado'
}

const CRITERIA_LABELS = {
  status: 'Estado',
  typology: 'Tipología',
  phase: 'Fase',
  tower: 'ID de Torre',
  floor: 'Piso',
  bedrooms: 'Recámaras',
  bathrooms: 'Baños',
  parking_lots: 'Estacionamientos'
}

const CRITERIA_LABELS_3_ROCAS = {
  status: 'Estado',
  typology: 'Clase',
  phase: 'Fase',
  tower: 'Manzana',
  floor: 'Piso',
  bedrooms: 'Recámaras',
  bathrooms: 'Baños',
  parking_lots: 'Estacionamientos'
}

const renderCriteria = (criteria, siteId) => {
  // return CRITERIA_LABELS[criteria] || criteria
  /** TODO: Eliminar este hack que es para el demo de 3 Rocas */
  return siteId === 41 ? CRITERIA_LABELS_3_ROCAS[criteria] : (CRITERIA_LABELS[criteria] || criteria)
}

const getFiltersURL = (criterias) => {
  const cleanCriterias = Object.fromEntries(Object.entries(criterias).filter(([_, value]) => Object.keys(value).length !== 0))
  const filters_list = Object.keys(cleanCriterias).map(criteria => (
    `${criteria}=${Object.keys(criterias[criteria]).map(key => encodeURIComponent(key)).join(',')}`
  ))
  return filters_list.join('&')
}

const renderFilterLabel = (criteria, value, currencySymbol = '$', siteID) => {
  switch (criteria) {
    case 'status':
      return STATUS[value]
    case 'typology':
      return siteID === 41 ? `Clase ${value}` : `Tipología ${value}`
    case 'floor':
      return `Piso ${value}`
    case 'bedrooms':
      return `${value} recámaras`
    case 'bathrooms':
      return `${value} baños`
    case 'parking_lots':
      return `${value} estacionamientos`
    case 'price_from':
      return `Desde ${currencySymbol} ${numberToCurrency(value)}`
    case 'price_to':
      return `Hasta ${currencySymbol} ${numberToCurrency(value)}`
    default:
      return value
  }
}

const CustomFilter = ({ criteria, filter, onClick }) => {
  /* TODO: Eliminar todo lo relacionado a profile porque es un hack para 3 Rocas */
  const profile = useProfile()
  const handleClick = useCallback(() => {
    onClick(criteria, filter)
  }, [criteria, filter, onClick])

  return (
    <>
      {profile && <Filter size="small" label={renderFilterLabel(criteria, filter, '$', profile.site_id)} onClick={handleClick} />}
    </>
  )
}

const CustomStyledChip = ({ criteria, filter, onClick }) => {
  /* TODO: Eliminar todo lo relacionado a profile porque es un hack para 3 Rocas */
  const profile = useProfile()
  const currencies = useCurrencies()
  const handleClick = useCallback(() => {
    onClick(criteria, filter)
  }, [criteria, filter, onClick])

  return (
    <>
      {currencies && profile && (<StyledChip size="small" label={renderFilterLabel(criteria, filter, currencies.main.symbol, profile.site_id)} onDelete={handleClick} />)}
    </>
  )
}

const InventoryFilter = ({ role, onFiltersChange }) => {
  const currencies = useCurrencies()
  const [prices, setPrices] = useState({
    min: "",
    max: ""
  })
  const [filters, setFilters] = useState({})
  const [criterias, setCriterias] = useState()
  const [isDropdownFiltersVisible, setIsDropdownFiltersVisible] = useState(false)
  const dropdownFiltersContentRef = useRef(null)
  const profile = useProfile()
  const selectedSite = useSelector(state => state.site)

  const handleChange = (event) => {
    const cleanStringPrice = (event.target.value || '').replace(/[A-Za-z!@#$%^&*(),]/g, '')
    setPrices({
      ...prices,
      [event.target.name]: cleanStringPrice
    })
  }

  const handleDelete = useCallback((criteria, filter) => {
    const existing_filters = filters[criteria] || {}
    const { [filter]: _, ...new_filters } = existing_filters
    setFilters({
      ...filters,
      [criteria]: new_filters
    })
  }, [filters])

  const handleFilterClick = useCallback((criteria, filter) => {
    const existing_filters = filters[criteria] || {}
    setFilters({
      ...filters,
      [criteria]: {
        ...existing_filters,
        [filter]: true
      }
    })
  }, [filters])

  const handleDropdownClick = () => {
    setIsDropdownFiltersVisible(!isDropdownFiltersVisible)
  }

  const handlePriceClick = useCallback(() => {
    const { min: minPrice, max: maxPrice } = prices
    if (minPrice !== "" && maxPrice !== "") {
      setFilters({
        ...filters,
        price_from: {
          [minPrice]: true
        },
        price_to: {
          [maxPrice]: true
        }
      })
      setPrices({
        min: "",
        max: ""
      })
    }
  }, [filters, prices])

  useEffect(() => {
    if (selectedSite) {
      get('/internal/inventory/v3/inventory/filters', { site_id: selectedSite.site_id })
        .then(criterias => setCriterias(criterias))
        .catch(error => console.error(error))
    }
  }, [selectedSite])

  useEffect(() => {
    if (onFiltersChange && Object.keys(filters).length > 0) onFiltersChange(getFiltersURL(filters))
  }, [onFiltersChange, filters])

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownFiltersContentRef.current && !dropdownFiltersContentRef.current.contains(event.target)) {
        setIsDropdownFiltersVisible(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [])

  return (
    <>
      {currencies && profile && (<FlexContainer>
        <StyledPaper variant="outlined">
          <Typography variant="overline">Filtra el Inventario</Typography>
          <Spacer size={1} vertical />
          <FlexContainer>
            <PricesWrapper verticalCentered>
              <FormControl>
                <InputLabel htmlFor="min-price">Precio min ({currencies.main.code})</InputLabel>
                <StyledInput
                  name="min"
                  id="min-price"
                  aria-describedby="min-price"
                  startAdornment={<InputAdornment position="start">{currencies.main.symbol}</InputAdornment>}
                  onChange={handleChange}
                  value={numberToCurrency(prices.min)} />
                <FormHelperText id="min-price-helper"></FormHelperText>
              </FormControl>
              <PricesSeparator variant="middle" />
              <FormControl>
                <InputLabel htmlFor="max-price">Precio max ({currencies.main.code})</InputLabel>
                <StyledInput
                  name="max"
                  id="max-price"
                  aria-describedby="max-price"
                  startAdornment={<InputAdornment position="start">{currencies.main.symbol}</InputAdornment>}
                  onChange={handleChange}
                  value={numberToCurrency(prices.max)} />
                <FormHelperText id="max-price-helper"></FormHelperText>
              </FormControl>
              <Spacer size={3} />
              <Fab color="primary" size="small" onClick={handlePriceClick}>
                <Check />
              </Fab>
            </PricesWrapper>
            <Divider orientation="vertical" flexItem />
            <FiltersWrapper vertical started>
              <Spacer size={3} vertical />
              <FlexContainer flexWrap>
                {
                  Object.keys(filters).map(criteria => (
                    <Fragment key={criteria}>
                      {
                        Object.keys(filters[criteria]).map(filter => (
                          <CustomStyledChip key={filter} criteria={criteria} filter={filter} onClick={handleDelete} />
                        ))
                      }
                    </Fragment>
                  ))
                }
              </FlexContainer>
              <Spacer size={2} vertical />
              <DropdownFilters ref={dropdownFiltersContentRef}>
                <Button variant="outlined" size="small" color="primary" startIcon={<Add />} onClick={handleDropdownClick} >AÑADIR FILTRO</Button>
                <DropdownFiltersContent elevation={2} visible={isDropdownFiltersVisible} >
                  <FlexContainer vertical>
                    {criterias &&
                      Object.keys(criterias).map((criteria, index) => (
                        <Fragment key={criteria}>
                          <Spacer size={1} vertical />
                          <Typography color="primary" variant="subtitle1">{renderCriteria(criteria, profile.site_id)}</Typography>
                          <Spacer size={1} vertical />
                          <FlexContainer flexWrap>
                            {
                              criterias[criteria].map(filter => (
                                <Fragment key={filter}>
                                  {!(filters && filters[criteria] && filters[criteria][filter]) &&
                                    <div>
                                      <div>
                                        <CustomFilter criteria={criteria} filter={filter} onClick={handleFilterClick} />
                                        <Spacer size={1} />
                                      </div>
                                      <Spacer size={1} vertical />
                                    </div>
                                  }
                                </Fragment>
                              ))
                            }
                          </FlexContainer>
                        </Fragment>
                      ))
                    }
                  </FlexContainer>
                </DropdownFiltersContent>
              </DropdownFilters>
            </FiltersWrapper>
          </FlexContainer>
        </StyledPaper>
      </FlexContainer>)}
    </>
  )
}

InventoryFilter.propTypes = {
  role: PropTypes.oneOf(["developer", "seller"]).isRequired
}

export default InventoryFilter
