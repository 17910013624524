import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'
import { Button, Checkbox, IconButton, Link, TextField, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { FlexContainer, Spacer } from '../../components'
import { post, put } from '../../helpers/request'
import useSite from '../../hooks/useSite'
import { getSettingsFromData } from './usePublishDraft'

const PUT_DEVELOPER_INFO = "/internal/settings/v1/settings/general/developer"

const ATTRIBUTES = {
  name: 'name',
  businessName: 'businessName',
  rfc: 'rfc',
  legalRepresentative: 'legalRepresentative',
  fiscalAddress: 'fiscalAddress',
}

const Input = styled(TextField)`
  width: 47%;
`

const Save = styled(Button)`
  width: fit-content;
`

const BeforePublishModal = ({ isOpen, onClose, onSave, saveDraft, data }) => {
  const { site } = useSite()
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [formData, setFormData] = useState({
    [ATTRIBUTES.name]: { value: ''},
    [ATTRIBUTES.businessName]: { value: ''},
    [ATTRIBUTES.fiscalAddress]: { value: ''},
    [ATTRIBUTES.legalRepresentative]: { value: ''},
    [ATTRIBUTES.rfc]: { value: ''},
  })

  const disableSave = useMemo(() => {
    let isValid = true
    let validatedData = {...formData}
    Object.keys(validatedData).forEach((key) => {
      if (validatedData[key].value.trim() === "") {
        validatedData[key] = {
          ...validatedData[key],
          error: true,
        }
        isValid = false
      }
    })
    if (!acceptedTerms) {
      isValid = false
    }
    return !isValid
  }, [acceptedTerms, formData])

  const handleChange = useCallback((value, attribute) => {
    setFormData(f => ({
      ...f,
      [attribute]: { value }
    }))
  }, [])

  const handleBlur = useCallback((value, attribute) => {
    if (value.trim() === "") {
      setFormData(f => ({
        ...f,
        [attribute]: {
          ...f[attribute],
          error: true,
        }
      }))
    }
  }, [])

  const updateTemplate = async(id, businessLegalName, businessName, businessEmail, businessPhone, projectDomain) => {
    const templateUpdates = {
      changes: [
        {
          path: "legal.businessLegalName",
          value: businessLegalName
        },
        {
          path: "legal.businessName",
          value: businessName
        },
        {
          path: "legal.projectDomain",
          value: projectDomain
        },
        {
          path: "legal.businessEmail",
          value: businessEmail
        },
        {
          path: "legal.businessPhone",
          value: businessPhone
        },
      ],
    }

    await post(
      '/internal/developer/v1.1/files',
      {
        stage: ['draft'],
        fileName: 'data',
        ...templateUpdates,
      },
      id
    );
  }

  const handleSave = useCallback(async() => {
    if (site?.site_id) {
      const developerAttributes = {
        [ATTRIBUTES.businessName]: formData[ATTRIBUTES.businessName].value,
        [ATTRIBUTES.fiscalAddress]: formData[ATTRIBUTES.fiscalAddress].value,
        [ATTRIBUTES.legalRepresentative]: formData[ATTRIBUTES.legalRepresentative].value,
        [ATTRIBUTES.name]: formData[ATTRIBUTES.name].value,
        [ATTRIBUTES.rfc]: formData[ATTRIBUTES.rfc].value,
      }
      const developer = {
        ...developerAttributes,
        acceptedTerms: true,
        acceptedTermsDate: new Date(),
      }
      const { contact } = getSettingsFromData(data);

      await updateTemplate(
        site?.site_id, formData[ATTRIBUTES.name].value,
        formData[ATTRIBUTES.businessName].value,
        contact?.email,
        contact?.phone,
        site?.site_domain
      )
      await saveDraft()

      put(PUT_DEVELOPER_INFO, developer, site?.site_id)

      if (onSave) onSave()
    }
  }, [data, formData, onSave, saveDraft, site?.site_domain, site?.site_id])

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        <FlexContainer fullWidth justified>
          <Typography variant='h4'>¡Espera! Un último paso...</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </FlexContainer>
      </DialogTitle>
      <DialogContent>
        <FlexContainer vertical>
          <Typography>Por favor, compártenos la siguiente información para generar automáticamente los <b>Términos y Condiciones</b> de tu showroom y las <b>facturas</b> a entregarte</Typography>
          <Spacer size={4} vertical />
          <FlexContainer fullWidth justified>
            <Input
              value={formData[ATTRIBUTES.name].value}
              onChange={(e) => handleChange(e.target.value, ATTRIBUTES.name)}
              onBlur={(e) => handleBlur(e.target.value, ATTRIBUTES.name)}
              label="Nombre comercial de tu empresa"
              variant="standard"
              error={formData[ATTRIBUTES.name].error}
              helperText={formData[ATTRIBUTES.name].error ? "Campo obligatorio" : " "}
            />
            <Input
              value={formData[ATTRIBUTES.businessName].value}
              onChange={(e) => handleChange(e.target.value, ATTRIBUTES.businessName)}
              onBlur={(e) => handleBlur(e.target.value, ATTRIBUTES.businessName)}
              label="Razón social"
              variant="standard"
              error={formData[ATTRIBUTES.businessName].error}
              helperText={formData[ATTRIBUTES.businessName].error ? "Campo obligatorio" : " "}
            />
          </FlexContainer>
          <Spacer size={3} vertical />
          <FlexContainer fullWidth justified>
            <Input
              value={formData[ATTRIBUTES.rfc].value}
              onChange={(e) => handleChange(e.target.value, ATTRIBUTES.rfc)}
              onBlur={(e) => handleBlur(e.target.value, ATTRIBUTES.rfc)}
              label="RFC"
              variant="standard"
              error={formData[ATTRIBUTES.rfc].error}
              helperText={formData[ATTRIBUTES.rfc].error ? "Campo obligatorio" : " "}
            />
            <Input
              value={formData[ATTRIBUTES.legalRepresentative].value}
              onChange={(e) => handleChange(e.target.value, ATTRIBUTES.legalRepresentative)}
              onBlur={(e) => handleBlur(e.target.value, ATTRIBUTES.legalRepresentative)}
              label="Nombre completo del representante legal"
              variant="standard"
              error={formData[ATTRIBUTES.legalRepresentative].error}
              helperText={formData[ATTRIBUTES.legalRepresentative].error ? "Campo obligatorio" : " "}
            />
          </FlexContainer>
          <Spacer size={3} vertical />
          <FlexContainer fullWidth justified>
            <Input
              value={formData[ATTRIBUTES.fiscalAddress].value}
              onChange={(e) => handleChange(e.target.value, ATTRIBUTES.fiscalAddress)}
              onBlur={(e) => handleBlur(e.target.value, ATTRIBUTES.fiscalAddress)}
              label="Dirección física de la empresa"
              variant="standard"
              error={formData[ATTRIBUTES.fiscalAddress].error}
              helperText={formData[ATTRIBUTES.fiscalAddress].error ? "Campo obligatorio" : " "}
            />
          </FlexContainer>
          <Spacer size={4} vertical />
          <FlexContainer verticalCentered>
            <Checkbox value={acceptedTerms} onChange={e => setAcceptedTerms(e.target.checked)} />
            <Typography variant="caption">Al dar clic en "Continuar" declaro haber leído y aceptado <br/> los <Link href={`${process.env.REACT_APP_ALOHOME_URL}/terminos-y-condiciones`} target="_blank">Términos y Condiciones</Link> de uso de Alohome</Typography>
          </FlexContainer>
          <Spacer size={1} vertical />
          <Save startIcon={<SaveIcon />} variant="contained" color="primary" disabled={disableSave} onClick={handleSave}>Continuar</Save>
        </FlexContainer>
      </DialogContent>
    </Dialog>
  );
}

export default BeforePublishModal
