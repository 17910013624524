import { FormControl, InputLabel, List, TextField } from '@mui/material';
import { useEffect } from 'react';
import styled from 'styled-components';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from "use-places-autocomplete";
import LocationOption from './LocationOption';

const LocationList = styled(List)`
  max-width: 28rem;
  max-height: 240px;
  overflow-y: auto;
  display: ${({hidden}) => hidden ? 'none' : 'block'};
  background-color: #fff;
  border: 1px solid #dedede;
  border-top: none;
  border-radius: 4px;
`

const LocationLabel = styled(InputLabel)`
  position: initial;
  transform: none;
`

const LocationInput = styled(TextField)`
  max-width: 28rem;
  label + & {
    margin-block-start: .25rem;
  }
`

const PlacesAutocomplete = ({
  inputLabel,
  inputPlaceholder,
  onSelect,
  variant = "outlined",
  initialValue = '',
}) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  useEffect(() => {
    setValue(initialValue, false)
  }, [initialValue, setValue])

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    onSelect({ address, lat, lng });
  };
  
  return (
    <FormControl variant={variant}>
      <LocationLabel
        htmlFor="location-input"
        variant={variant}
        sx={{ color: "text.primary" }}
      >
        {inputLabel}
      </LocationLabel>
      <LocationInput
        placeholder={inputPlaceholder}
        id="location-input"
        value={value}
        variant={variant}
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
      />
      <LocationList hidden={!status}>
        {status === "OK" && data.map(({ place_id, description }) => (
          <LocationOption
            key={place_id}
            value={description}
            label={description}
            onClick={handleSelect}
            sx={{ color: 'text.primary' }}
          />
        ))}
      </LocationList>
    </FormControl>
  );
};

export default PlacesAutocomplete