import { useMemo } from 'react';
import { filterUniqueBy } from '../../helpers/arrays';
import useLocale from '../../hooks/useLocale';

const dateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  hour12: true,
  minute: 'numeric',
};

function getSourceTranslation(origin) {
  switch (origin?.toLowerCase()) {
    case 'chat':
    case 'speed-dial chat':
      return 'Chat';
    case 'quotation no inputs':
    case 'quotation wall':
    case 'simple quotation':
      return 'Cotizaciones showroom';
    case 'multi-step form':
    case 'form with media':
    case 'contact section':
    case 'schedule appointment':
    case 'contact form':
      return 'Secciones de contacto';
    case 'alo quote':
      return null;
    default:
      return 'Showroom';
  }
}

const useTableLeads = ({ leads }) => {
  const locale = useLocale();
  return useMemo(
    () =>
      leads.map((lead) => {
        const leadSourcesOrigins = (lead.sources || [])
          .map(getSourceTranslation)
          .filter(Boolean)
          .map((sourceLabel) => ({
            type: 'leadSource',
            value: sourceLabel,
          }));
        const uniqueLeadSourcesOrigins = filterUniqueBy(
          leadSourcesOrigins,
          (v) => v.value,
        );

        const quotesOrigins = (lead.quotes || [])
          .map((quote) => {
            if (!quote.user) return undefined;
            return {
              type: 'user',
              value: quote.user,
            };
          })
          .filter((v) => v !== undefined);
        const uniqueQuotesOrigins = filterUniqueBy(
          quotesOrigins,
          (v) => v.value.userId,
        );

        const origin =
          uniqueLeadSourcesOrigins.length > 0 || uniqueQuotesOrigins.length > 0
            ? [...uniqueQuotesOrigins, ...uniqueLeadSourcesOrigins]
            : [{ type: 'leadSource', value: getSourceTranslation() }];

        return {
          name: [
            (lead.clientInfo.clientName || '').trim(),
            (lead.clientInfo.clientLastNames || '').trim(),
          ].join(' '),
          date: new Date(lead.createdAt).toLocaleDateString(
            locale,
            dateOptions,
          ),
          phone:
            (lead.clientInfo.clientPhoneCode || '') +
            (lead.clientInfo.clientPhone || ''),
          email: lead.clientInfo.clientEmail,
          country: lead.clientInfo.countryName,
          origin,
          quotes: lead.quotes,
        };
      }),
    [leads, locale],
  );
};

export default useTableLeads;
