import {
  Delete as DeleteIcon,
  Image as ImageIcon,
  Videocam as VideocamIcon
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  IconButton,
  styled,
  Typography
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import useAssetsNames from "../../../hooks/useAssetsNames";
import {
  HoverCardMedia,
  ImageGallery,
  VideoGallery
} from "../../../modules";

const CardButton = styled(CardActionArea)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  background: "rgba(0, 0, 0, 0.04)",
  border: "1px dashed rgba(0, 0, 0, 0.26)",
  borderRadius: 4,
});

const StyledImageIcon = styled(ImageIcon)({
  width: "3rem",
  height: "3rem",
});

const StyledVideocamIcon = styled(VideocamIcon)({
  width: "3rem",
  height: "3rem",
});

const SimpleVideo = ({ pathData = {}, setPathData }) => {
  const [showVideoGallery, setShowVideoGallery] = useState(false);
  const [showImageGallery, setShowImageGallery] = useState(false);

  const openVideoGallery = useCallback(() => setShowVideoGallery(true), []);
  const closeVideoGallery = useCallback(() => setShowVideoGallery(false), []);
  const openImageGallery = useCallback(() => setShowImageGallery(true), []);
  const closeImageGallery = useCallback(() => setShowImageGallery(false), []);

  const url = pathData.url;
  const assetId = pathData.assetId;
  const thumbnailAssetId = pathData.thumbnail?.assetId;
  const thumbnailUrl = pathData.thumbnail?.url;

  const assetsIds = useMemo(() => {
    const ids = [];
    if (assetId) ids.push(assetId);
    if (thumbnailAssetId) ids.push(thumbnailAssetId);
    return ids;
  }, [assetId, thumbnailAssetId]);

  const { assetsNames } = useAssetsNames({ ids: assetsIds });

  const onVideoSelected = useCallback(
    (assets) => {
      const { assetId, url } = assets[0];
      setPathData({ ...pathData, assetId, url });
      closeVideoGallery();
    },
    [pathData, setPathData, closeVideoGallery]
  );

  const onThumbnailSelected = useCallback(
    (assets) => {
      const { assetId, url } = assets[0];
      setPathData({
        ...pathData,
        thumbnail: { assetId, url },
      });
      closeImageGallery();
    },
    [pathData, setPathData, closeImageGallery]
  );

  const deleteThumbnail = useCallback(() => {
    setPathData({
      ...pathData,
      thumbnail: undefined,
    });
  }, [pathData, setPathData]);

  return (
    <>
      <VideoGallery
        open={showVideoGallery}
        onClose={closeVideoGallery}
        onSelected={onVideoSelected}
      />
      <ImageGallery
        open={showImageGallery}
        onClose={closeImageGallery}
        onSelected={onThumbnailSelected}
      />
      <Card sx={{ mb: 2 }}>
        {url ? (
          <>
            <HoverCardMedia component="video" src={url} height={140}>
              <Button
                variant="contained"
                color="primary"
                onClick={openVideoGallery}
                sx={{ textTransform: "none" }}
              >
                Cambiar
              </Button>
            </HoverCardMedia>
            {assetsNames?.[assetId] && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 1,
                  px: 2,
                }}
              >
                <Typography>{assetsNames?.[assetId]}</Typography>
              </Box>
            )}
          </>
        ) : (
          <CardButton onClick={openVideoGallery} sx={{ height: 150 }}>
            <StyledVideocamIcon color="action" />
            Selecciona o sube un vídeo
          </CardButton>
        )}
      </Card>
      <Typography variant="caption" sx={{ color: "text.secondary" }}>
        Miniatura
      </Typography>
      <Card>
        {thumbnailUrl ? (
          <>
            <HoverCardMedia
              src={thumbnailUrl}
              height={140}
              sx={{
                objectFit: "contain",
                backgroundImage:
                  "linear-gradient(180deg,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.4) 100%)",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={openImageGallery}
              >
                Cambiar
              </Button>
            </HoverCardMedia>
            {assetsNames?.[thumbnailAssetId] && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 1,
                  px: 2,
                }}
              >
                <Typography>{assetsNames?.[thumbnailAssetId]}</Typography>
                <IconButton onClick={deleteThumbnail}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
          </>
        ) : (
          <CardButton onClick={openImageGallery} sx={{ height: 150 }}>
            <StyledImageIcon color="action" />
            Selecciona o sube una imagen
          </CardButton>
        )}
      </Card>
    </>
  );
};

export default SimpleVideo;
