import { NearMe as NearMeIcon, Save as SaveIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { styled } from '@mui/material';
import React from 'react';

const PublishButton = styled(LoadingButton)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  background: theme.palette.secondary.light,
  '&:hover': {
    background: 'var(--color-secondary-gradient)',
  },
}));

const SaveActions = ({
  onSave,
  onPublish,
  loading,
  hasChanges,
  isSaving,
  isPublishing,
}) => (
  <Box sx={{ display: 'flex', gap: 3, mx: 1 }}>
    <LoadingButton
      onClick={onSave}
      startIcon={<SaveIcon />}
      disabled={loading || !hasChanges}
      loading={isSaving}
      loadingPosition="start"
    >
      Guardar
    </LoadingButton>
    <PublishButton
      variant="contained"
      onClick={onPublish}
      startIcon={<NearMeIcon />}
      disabled={loading}
      loading={isPublishing}
      loadingPosition="start"
    >
      Publicar
    </PublishButton>
  </Box>
);

export default SaveActions;
