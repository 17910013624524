import ContactForms from './Sidebar/ContactForms';
import CTAButton from './Sidebar/CTAButton';
import IconList from './Sidebar/IconList';
import Images from './Sidebar/Images';
import Link from './Sidebar/Link';
import MapInput from './Sidebar/MapInput';
import Multimedia from './Sidebar/Multimedia';
import Paragraph from './Sidebar/Paragraph';
import SimpleImage from './Sidebar/SimpleImage';
import SimpleList from './Sidebar/SimpleList';
import SimpleText from './Sidebar/SimpleText';
import SocialNetworks from './Sidebar/SocialNetworks';
import TypologiesManagement from './TypologiesManagement/TypologiesManagement';
import ProjectsManagement from './ProjectManagement/ProjectManagement';
import WorkProgress from './Sidebar/WorkProgress'

class Definition {
  constructor(control, props = {}) {
    this.control = control;
    this.props = props;
  }
}

function definition(...args) {
  return new Definition(...args);
}

// Use this symbol to catch any number-like key as a fallback (when edition
// target is an array item).
const ARRAY_ITEM = Symbol('__ah_array_item__');

const EDITION_DEFINITION = {
  'underline-header': {
    // @deprecated. [project-logo] used instead
    logo: definition(SimpleImage, { maxChars: 75 }),
    items: {
      [ARRAY_ITEM]: definition(Link, { maxChars: 30 }),
    },
  },
  'floating-banner': {
    call_to_action: definition(Link, { maxChars: 115 }),
  },
  'flex-media-hero': {
    title: definition(SimpleText, { maxChars: 60 }),
    subtitle: definition(Paragraph, { maxChars: 80 }),
    text: definition(Paragraph, { maxChars: 70 }),
    call_to_action: definition(CTAButton, { maxChars: 30 }),
    media: definition(Multimedia, {
      videoSectionPath: 'media.video',
      imagesSectionPath: 'media.images',
    }),
  },
  'image-banner-section': {
    title: definition(SimpleText, { maxChars: 45 }),
    content: definition(Paragraph, { maxChars: 140 }),
    call_to_action: definition(CTAButton, { maxChars: 30 }),
    image: definition(SimpleImage, { maxChars: 140 }),
  },
  'flex-media-section': {
    title: definition(SimpleText, { maxChars: 55 }),
    items: definition(SimpleList, { maxChars: 35 }),
    video_source: definition(Multimedia, {
      videoSectionPath: 'video_source',
      imagesSectionPath: 'gallery',
    }),
    gallery: {
      [ARRAY_ITEM]: definition(Multimedia, {
        videoSectionPath: 'video_source',
        imagesSectionPath: 'gallery',
      }),
    },
  },
  'carousel-typologies': {
    title: definition(SimpleText, { maxChars: 70 }),
    'slides-list': definition(TypologiesManagement),
  },
  'lots-view-section': {
    title: definition(SimpleText, { maxChars: 70 }),
    image: definition(SimpleImage, { maxChars: 75 }),
  },
  'masonry-gallery-section': {
    title: definition(SimpleText, { maxChars: 90 }),
    images: definition(Images, {
      maxChars: 75,
      textKey: 'caption',
      textFieldLabel: 'Texto',
      maxCount: 12,
    }),
  },
  'amenities-section': {
    title: definition(SimpleText, { maxChars: 90 }),
    items: definition(IconList, { maxChars: 20 }),
  },
  'small-map-section': {
    title: definition(SimpleText, { maxChars: 55 }),
    bullets: definition(SimpleList, { maxChars: 30, labelKey: 'text' }),
    location: definition(MapInput),
  },
  'split-contact-section': {
    contact_title: definition(SimpleText, { maxChars: 55 }),
    contact_form: {
      contact: {
        title: definition(SimpleText, { maxChars: 75 }),
      },
    },
  },
  'carousel-projects': {
    title: definition(SimpleText, { maxChars: 70 }),
    project: {
      logo: definition(SimpleImage, { maxChars: 75 }),
      description: definition(Paragraph, { maxChars: 235 }),
    },
    projects: definition(ProjectsManagement),
  },
  'default-footer': {
    logo: definition(SimpleImage, { maxChars: 75 }),
    social_links: definition(SocialNetworks),
  },
  'project-logo': {
    logo: definition(SimpleImage, { maxChars: 75 }),
  },
  'contact-methods': {
    contact_methods: definition(ContactForms),
  },
  'transparency-construction-progress-section': {
    "work-progress-data": definition(WorkProgress),
  }
};

export const shouldSyncHubId = (hubid) => {
  return ['project-logo', 'contact-methods'].includes(hubid);
}

function getEditionDefinition(hubid, relativePath) {
  // Get all editionIds present in hubdid
  const editionIds = hubid
    .match(/\[.*?\]/g)
    .map((str) => str.replace(/^\[|\]$/g, ''));

  // Find the first edition that resolves with the selected relativePath
  for (const editionId of editionIds) {
    let objCursor = EDITION_DEFINITION[editionId];
    const definitionPath = [];
    for (const key of relativePath) {
      if (typeof objCursor !== 'object') break;
      // If the key is number-like and isn't explicitly specified try using the
      // array item property
      objCursor = /^\d+$/.test(key)
        ? objCursor[key] || objCursor[ARRAY_ITEM]
        : objCursor[key];
      definitionPath.push(key);
      // Stop when a Definition is found. This will cause that nested paths can
      // also resolve to an upper one (relativePath can be equal or deeper than
      // the definitionPath)
      if (objCursor instanceof Definition)
        return {
          Component: objCursor.control,
          componentProps: objCursor.props,
          definitionPath,
          editionId,
        };
    }
  }
  return null;
}

export default getEditionDefinition;
