import { Button, Paper, Typography, Divider, Alert } from '@mui/material'
import { FlexContainer, Spacer } from '../../components'
import { post } from '../../helpers/request'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import ReactCodeInput from 'react-verification-input'
import { useLocation } from 'react-router'
import { clientIdentify, trackEvent } from '../../helpers/mixpanel'

const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
  flex-direction: column;
`

const StyledPaper = styled(Paper)`
  width: 420px;
  padding: 48px;
`

const Title = styled(Typography)`
  color: #006BAD;
  font-weight: bold;
`

const AccessButton = styled(Button)`
  background-color: #006BAD;
  &:hover {
    background-color: #4fccb3;
  }
`
const StyledAlert = styled(Alert)`
  align-self: stretch;
`
const StyledDivider = styled(Divider)`
  height: 1px;
`

const StyledLink = styled.a`
  color: #006BAD;   
`

const VerifyView = () => {
  const [code, setCode] = useState('')
  const [error, setError] = useState(false)
  const dispatch = useDispatch()
  const { state: { mfaToken, email, password } } = useLocation()

  const handleChange = useCallback(code => {
    setCode(code)
  }, [])

  const handleAccess = useCallback(() => {
    if (code === '' || code.length !== 6) {
      setError("Ingrese el código de verificación")
    } else {
      setError(false)
      post('/internal/login/v1/login', { email, password, mfaToken, mfaCode: code })
        .then(result => {
          dispatch({ type: 'SET_PROFILE', profile: result.user })
          clientIdentify(email)
          trackEvent('login')
        })
        .catch(_ => {
          setError("Código de verificación incorrecto")
        })
    }
  }, [code, dispatch, email, mfaToken, password])

  return (
    <Container>
      <img src="/images/alo_home_logo_blue.png" alt="Alo Home Logo" width={128} />
      <Spacer vertical size={3} />
      <StyledPaper variant="outlined" >
        <FlexContainer centered fullWidth vertical>
          <Title variant="h5">Código de Verificación</Title>
          <Spacer vertical size={1} />
          <Typography variant="body2" color="textSecondary">Escriba el código de 6 dígitos que le hemos enviado a su correo electrónico</Typography>
          <Spacer vertical size={5} />
          <ReactCodeInput
            autoFocus
            onChange={handleChange}
          />
          {error && (<>
            <Spacer vertical size={2} />
            <FlexContainer fullWidth centered vertical>
              <StyledAlert severity="error">{error}</StyledAlert>
            </FlexContainer>
          </>)
          }
          <Spacer vertical size={5} />
          <AccessButton
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleAccess}
            size="large"
          >
            Ingresar
          </AccessButton>
          <Spacer vertical size={4} />
          <StyledDivider flexItem variant="fullWidth" />
          <Spacer vertical size={3} />
          <Typography variant="caption">Problemas? Escríbenos a <StyledLink href={`mailto:${process.env.REACT_APP_SUPPORT_MAIL}`}>{process.env.REACT_APP_SUPPORT_MAIL}</StyledLink> </Typography>
        </FlexContainer>
      </StyledPaper>
    </Container>
  )
}

export default VerifyView