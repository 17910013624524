import ListItem from '@mui/material/ListItem';
import styled from 'styled-components';

const ListItemOption = styled(ListItem)`
  align-items: center;
  cursor: pointer;

  &.MuiListItem-root {
    padding: 0;
  }

  &.MuiListItem-root.Mui-selected {
    background-color: transparent;
    & .MuiSvgIcon-root {
      color: var(--color-primary);
    }
    & .MuiTypography-root {
      color: var(--color-primary);
    }
    a, button {
      background-color: rgba(69, 168, 230, 0.1);
      border-radius: 16px;
    }
  }
  
  a,
  button {
    align-items: center;
    display: flex;
    padding: .5rem 1rem;
    text-align: initial;
    width: 100%;

    &:hover {
      background-color: rgba(0,0,0,.04);
      border-radius: 1rem;
    }
  }

  a {
    text-decoration: none;
  }

  button {
    text-transform: none;
  }
`
export default ListItemOption