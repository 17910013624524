import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useSite from './useSite';

/**
 * Returns a callback that executes an action depending on the site's plan
 * active status.
 *
 * By default the pricing modal will be opened on not active plan scenario.
 * @param {(...args: any[]) => void} onActivePlan
 * @param {(...args: any[]) => void | undefined} onNotActivePlan
 */
const useSiteActiveStatusAction = (onActivePlan, onNotActivePlan) => {
  const dispatch = useDispatch();
  const { site } = useSite();
  const isSiteExpired = site?.remainingDays <= 0;

  const callback = useCallback(
    (...args) => {
      if (!isSiteExpired) onActivePlan(...args);
      else if (onNotActivePlan) onNotActivePlan(...args);
      else dispatch({ type: 'open-plans-modal' });
    },
    [isSiteExpired, onActivePlan, onNotActivePlan, dispatch]
  );

  return callback;
};

export default useSiteActiveStatusAction;
