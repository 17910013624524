import {
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { FlexContainer, PendingButton, Spacer } from '../../components';
import { getGeneral } from '../../helpers/draft';
import { post, put } from '../../helpers/request';
import { configTabs } from '../../helpers/tabsData';
import { useSharedDocument, convertToY } from '../../hooks/documentHooks';
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt';
import useSite from '../../hooks/useSite';
import PageLayout from '../../layouts/PageLayout';
import { AdvanceFeatureModal, DiscardChangesDialog } from '../../modules';

const PUT_CSS = '/internal/settings/v1/settings/showroom/css';

const DEFAULT_DATA = {
  css: '',
  enabled: false,
};

const CheckLabel = styled(FormControlLabel)`
  margin-left: 0;
`;

const screenText = {
  title: 'Hojas de estilo CSS: personaliza elementos de tu showroom digital',
  text: '¿Quieres cambiar el color de un botón o resaltar un texto en específico? Inserta las reglas para la apariencia de tu sitio ¡y hazlo posible!',
  enable: 'Activar hojas de estilo CSS personalizado',
  textField: {
    label: 'Hojas de estilo (CSS)',
    placeholder: `/* Ingresa tu código aquí */\n.my-custom-class {\n  colore: red;\n}`,
    note: {
      title: 'NOTA:',
      content:
        'el código CSS que insertes podría dejar de funcionar al 100% en futuras actualizaciones al diseño de nuestras plantillas (temas).',
    },
  },
};

const StylesheetsView = () => {
  const dispatch = useDispatch();
  const { site } = useSite();
  const [yDocument, , documentReady] = useSharedDocument(site?.site_id, {
    ws: process.env.REACT_APP_WEBSOCKET,
  });
  const [viewWarningModal, setViewWarningModal] = useState(false);
  const [savedData, setSavedData] = useState(DEFAULT_DATA);
  const [formData, setFormData] = useState(DEFAULT_DATA);
  const [loading, setLoading] = useState(true);
  const enableSave = useMemo(
    () => !isEqual(formData, savedData),
    [formData, savedData]
  );
  const [showPrompt, confirmNavigation] = useCallbackPrompt(enableSave);

  useEffect(() => {
    const getCss = async () => {
      if (site?.site_id) {
        const dataMap = yDocument && yDocument?.getMap('general');
        var dataJson = dataMap?.toJSON();
        if (!!dataJson && Object.keys(dataJson).length === 0 && !!dataMap) {
          dataJson = await getGeneral(site);
        }
        if (Object.keys(dataJson?.custom_theme ?? {}).length > 0) {
          const data = {
            css: dataJson?.custom_theme ?? '',
            enabled: dataJson?.custom_theme_enabled,
          };
          setFormData(data);
          setSavedData(data);
        }
        setLoading(false);
      }
    };
    if (documentReady) {
      getCss();
    }
    // eslint-disable-next-line
  }, [site?.site_id, yDocument, documentReady]);

  const openPlans = useCallback(() => {
    dispatch({ type: 'open-plans-modal' });
  }, [dispatch]);

  const resetForm = useCallback(() => setFormData(DEFAULT_DATA), []);

  const handleChange = useCallback((value, attribute) => {
    setFormData((d) => ({
      ...d,
      [attribute]: value,
    }));
  }, []);

  const handleActivationChange = useCallback(
    (e) => {
      if (e.target.checked) {
        setViewWarningModal(true);
      } else {
        resetForm();
      }
    },
    [resetForm]
  );

  const handleCloseWarningModal = useCallback(
    () => setViewWarningModal(false),
    []
  );

  const handleAcceptWarningModal = useCallback(() => {
    setViewWarningModal(false);
    handleChange(true, 'enabled');
  }, [handleChange]);

  const handlePublish = useCallback(async () => {
    if (site?.remainingDays <= 0) {
      openPlans();
    } else {
      setSavedData(formData);
      put(PUT_CSS, formData, site?.site_id);
      const changes = [
        {
          path: 'custom_theme',
          value: formData.css,
        },
      ];
      const dataMap = yDocument && yDocument?.getMap('general');

      dataMap.set('custom_theme', convertToY(formData.css));
      dataMap.set('custom_theme_enabled', convertToY(formData.enabled));

      await post(
        '/internal/developer/v1.1/files',
        {
          changes,
          stage: ['draft', 'production'],
          fileName: 'general',
        },
        site?.site_id
      );
    }
  }, [formData, openPlans, site?.remainingDays, site?.site_id, yDocument]);

  const saveAndLeave = useCallback(() => {
    handlePublish();
    confirmNavigation();
  }, [confirmNavigation, handlePublish]);

  return (
    <>
      <DiscardChangesDialog
        open={showPrompt}
        onSave={saveAndLeave}
        onDiscard={confirmNavigation}
      />
      <AdvanceFeatureModal
        open={viewWarningModal}
        onClose={handleCloseWarningModal}
        onAccept={handleAcceptWarningModal}
      >
        <Typography>
          Los estilos CSS sólo deben ser configurados por personas con
          conocimientos técnicos. Su uso incorrecto puede ocasionar fallas.
        </Typography>
        <Spacer vertical size={2} />
        <Typography>
          Si llegara a presentarse algún error, puedes desactivar esta función y
          volver al diseño original de tu showroom digital.
        </Typography>
      </AdvanceFeatureModal>
      <PageLayout
        links={configTabs}
        loading={loading}
        menu={
          <PendingButton
            text="Guardar"
            onClick={handlePublish}
            showBullet={enableSave}
          />
        }
      >
        <FlexContainer vertical>
          <Spacer vertical size={2} />
          <Typography variant="h5">{screenText.title}</Typography>
          <Spacer size={1} vertical />
          <Typography>{screenText.text}</Typography>
          <Spacer size={6} vertical />
          <FormGroup aria-label="position" row>
            <CheckLabel
              control={
                <Switch
                  color="primary"
                  checked={formData.enabled}
                  onChange={handleActivationChange}
                />
              }
              label={screenText.enable}
              labelPlacement="start"
            />
          </FormGroup>
          <Spacer size={6} vertical />
          <TextField
            label={screenText.textField.label}
            placeholder={screenText.textField.placeholder}
            multiline
            variant="outlined"
            minRows={3}
            maxRows={12}
            inputProps={{ style: { fontFamily: '"Courier New", monospace' } }}
            disabled={!formData.enabled}
            value={formData.css}
            onChange={(e) => handleChange(e.target.value, 'css')}
          />
          <FormHelperText>
            <b>{screenText.textField.note.title}</b>{' '}
            {screenText.textField.note.content}
          </FormHelperText>
        </FlexContainer>
      </PageLayout>
    </>
  );
};

export default StylesheetsView;
