import { Alert, Box, Drawer, LinearProgress, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import MUIDataTable from 'mui-datatables';
import React, { useCallback, useMemo, useState } from 'react';
import { deleteReq, get } from '../../helpers/request';
import { localization } from '../../helpers/tables';
import { configTabs } from '../../helpers/tabsData';
import useSite from '../../hooks/useSite';
import PageLayout from '../../layouts/PageLayout';
import CustomActions from './CustomActions';
import CustomSelectActions from './CustomSelectActions';
import RecordEdition from './RecordEdition';
import { formatRecordName } from './recordNameUtils';
import COLUMNS from './tableColumns';

const getHostedZoneFn = ({ queryKey }) =>
  get('/internal/alohub/config/hosted-zone', { site_id: queryKey[1] });

const getHostedZoneRecordTypesFn = () =>
  get('/internal/alohub/config/hosted-zone/record-types');

const deleteRecordsFn = ({ records, siteId }) =>
  Promise.allSettled(
    records.map(({ name, type }) =>
      deleteReq('/internal/alohub/config/hosted-zone', { name, type }, siteId)
    )
  );

const HostedZoneView = () => {
  const { site } = useSite();
  const siteId = site?.site_id;

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const {
    data: hostedZone,
    isLoading: isLoadingHostedZone,
    refetch: refetchHostedZone,
    error: hostedZoneError,
  } = useQuery(['HOSTED_ZONE', siteId], getHostedZoneFn, {
    retry: false,
    enabled: !!siteId && !openDrawer,
  });

  const { data: recordTypes, isLoading: isLoadingRecordTypes } = useQuery(
    ['RECORD_TYPES', siteId],
    getHostedZoneRecordTypesFn,
    {
      staleTime: Infinity,
    }
  );

  const onSuccess = useCallback(() => {
    refetchHostedZone();
    setOpenDrawer(false);
    setEditingRecord(null);
    setSelectedRows([]);
  }, [refetchHostedZone]);

  const { mutate: deleteRecords, isLoading: isDeleting } = useMutation(
    deleteRecordsFn,
    { onSuccess }
  );

  const onCloseDrawer = useCallback(() => {
    setOpenDrawer(false);
    setEditingRecord(null);
  }, []);

  const tableOptions = useMemo(
    () => ({
      download: false,
      print: false,
      viewColumns: false,
      textLabels: localization,
      rowsSelected: selectedRows,
      onRowSelectionChange: (_, __, v) => setSelectedRows(v),
      rowsPerPage,
      rowsPerPageOptions: [1, 10, 20, 50],
      onChangeRowsPerPage: setRowsPerPage,
      customToolbar: () => (
        <CustomActions onCreate={() => setOpenDrawer(true)} />
      ),
      customToolbarSelect: (selectedRows) => {
        const disableEdit = selectedRows.data.length !== 1;
        const onEdit = () => {
          setOpenDrawer(true);
          setEditingRecord(hostedZone.records[selectedRows.data[0].dataIndex]);
        };
        const onDelete = () => {
          const records = selectedRows.data.map(
            ({ dataIndex }) => hostedZone.records[dataIndex]
          );
          deleteRecords({ records, siteId });
        };

        return (
          <CustomSelectActions
            onDelete={onDelete}
            onEdit={onEdit}
            disableEdit={disableEdit || isDeleting}
            disableDelete={isDeleting}
          />
        );
      },
    }),
    [rowsPerPage, hostedZone, deleteRecords, siteId, selectedRows, isDeleting]
  );

  const isLoading = isLoadingHostedZone || isLoadingRecordTypes;

  return (
    <PageLayout links={configTabs} loading={isLoading}>
      {hostedZoneError && (
        <Alert severity="error" sx={{ maxWidth: 'sm', mx: 'auto' }}>
          No se pudo cargar la zona alojada. Es posible que se deba a que el
          sitio no tiene un dominio personalizado o problemas de conexión
        </Alert>
      )}
      {hostedZone && (
        <Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              gap: 2,
              mb: 2,
            }}
          >
            <Typography variant="h6">Zona alojada:</Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {formatRecordName(hostedZone.hostedZoneName)}
            </Typography>
            <Typography sx={{ mb: 0.5 }} variant="h6">
              Name servers:
            </Typography>
            <Box
              sx={{
                justifySelf: 'start',
                ml: 0.5,
                p: 1.5,
                boxShadow: 3,
                borderRadius: 2,
              }}
            >
              {hostedZone.nameServers?.map((nameServer) => (
                <Typography key={nameServer} sx={{ fontWeight: '500' }}>
                  {formatRecordName(nameServer)}
                </Typography>
              ))}
            </Box>
          </Box>
          <Box sx={{ position: 'relative', mx: 1 }}>
            <LinearProgress
              sx={{
                visibility: isDeleting || isLoading ? 'visible' : 'hidden',
              }}
            />
            <MUIDataTable
              columns={COLUMNS}
              data={hostedZone.records}
              options={tableOptions}
            />
          </Box>
        </Box>
      )}
      {recordTypes && hostedZone?.hostedZoneName && (
        <Drawer open={openDrawer} anchor="right" onClose={onCloseDrawer}>
          <Box sx={{ p: 2 }}>
            <RecordEdition
              initialValues={editingRecord}
              recordTypes={recordTypes.types}
              hostedZoneName={hostedZone.hostedZoneName}
              onCancel={onCloseDrawer}
              onSuccess={onSuccess}
            />
          </Box>
        </Drawer>
      )}
    </PageLayout>
  );
};

export default HostedZoneView;
