import { createTheme } from '@mui/material/styles';
import { esES } from '@mui/x-data-grid';
import { esES as coreEsES } from '@mui/material/locale';
import { esES as datesEs } from '@mui/x-date-pickers';

const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#006BAD',
        dark: '#003C61',
        light: '#45A8E6',
      },
      secondary: {
        main: '#6501A8',
        light: '#A647E6',
      },
      gradient: 'linear-gradient(135deg, #45A8E6 22.4%, #A647E6 78.13%)',
    },
    typography: {
      fontFamily: 'Futura Std',
      body1: {
        fontFamily: 'Futura Std'
      },
      body2: {
        fontFamily: 'Poppins'
      },
      subtitle1: {
        fontFamily: 'Futura Std'
      },
      subtitle2: {
        fontFamily: 'Futura Std'
      },
      caption: {
        fontFamily: 'Poppins'
      },
      overline: {
        fontFamily: 'Poppins'
      },
      h1: {
        fontFamily: 'Futura Std'
      },
      h2: {
        fontFamily: 'Futura Std'
      },
      h3: {
        fontFamily: 'Futura Std'
      },
      h4: {
        fontFamily: 'Futura Std'
      },
      h5: {
        fontFamily: 'Futura Std'
      },
      h6: {
        fontFamily: 'Futura Std'
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          color: 'inherit',
          size: 'large',
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            borderRadius: '16px',
            ...(ownerState.variant === 'text' && ownerState.color === 'inherit') && {
              color: '#00000099',
              '&:hover': {
                background: '#3F51B514',
              },
              '&& .MuiTouchRipple-rippleVisible': {
                background: '#0000000A',
              }
            }
          })
        },
      },
      MuiIconButton: {
        defaultProps: {
          color: 'inherit'
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            borderRadius: '16px',
            ...(ownerState.color === 'inherit') && {
              color: '#00000099',
              '&:hover': {
                background: '#3F51B514',
              },
              '&& .MuiTouchRipple-rippleVisible': {
                background: '#0000000A',
              }
            }
          })
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            background: '#003C61CC',
          }
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            background: '#003C61CC',
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.variant === 'outlined') && {
              background: 'transparent',
            }
          })
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            background: '#fff',
          }
        }
      },
      MuiCircularProgress: {
        defaultProps: {
          color: 'inherit'
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.color === 'inherit') && {
              color: '#0000008A',
            }
          })
        }
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: 0
          },
          cell: {
            border: 0
          },
          footerContainer: {
            border: 0
          },
          columnHeaders: {
            border: 0
          },
          columnHeaderTitle: {
            fontWeight: "bold"
          }
        }
      },
      MUIDataTable: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
          },
        },
      },
      MUIDataTablePagination: {
        styleOverrides: {
          tableCellContainer: {
            borderBottom: 'none',
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            fontFamily: 'Poppins',
          },
          data: {
            fontWeight: 600,
            fontFamily: 'Poppins',
          },
        },
      },
    },
  },
  coreEsES,
  esES,
  datesEs,
)

export default theme