import { ArrowBack as ArrowBackIcon } from '@mui/icons-material'
import { Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FlexContainer, PendingButton, Spacer } from '../../components'
import { salesTeamTabs } from '../../helpers/tabsData'
import PageLayout from '../../layouts/PageLayout'
import { DiscardChangesDialog, SimpleModal } from '../../modules';
import TeamList, { ALERT_TYPE } from './TeamList';
import AddUserForm from './AddUserForm';
import { emailRegex } from '../../helpers/regex';
import TeamEdit from './TeamEdit'
import { ROLES, USER_SITE_STATUS } from '../../helpers/constants'
import isEqual from 'react-fast-compare'
import { deleteReq, get, post, put } from '../../helpers/request'
import useSite from '../../hooks/useSite'
import { useMutation, useQuery } from '@tanstack/react-query'
import { LoadingButton } from '@mui/lab'
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt'

const GET_USERS = "/internal/alohub/agents?role="
const USER_ENDPOINT = "/internal/alohub/agents/"

const getUsersFn = ({ queryKey: [, role, siteId] }) => get(GET_USERS + role, { site_id: siteId });

const addUserFn = ({ user, siteId }) => post(USER_ENDPOINT, user, siteId);

const updateUserFn = ({ user, siteId }) =>
  put(USER_ENDPOINT + user.userId, user, siteId);

const deleteUserFn = ({ user, siteId }) =>
  deleteReq(USER_ENDPOINT + user.userId, {}, siteId);

const getRoleLabel = (role) =>
  ROLES.find((r) => r.id === role)?.label?.toLowerCase();

const TEAM_VIEWS = {
  list: "list",
  add: "add",
  edit: "edit"
}

const INPUT_VALIDATION = {
  required: "El campo es obligatorio",
  invalidEmail: "El correo electrónico debe ser válido",
}

const EMPTY_USER_DATA = {
  email: { value: '' },
  role: { value: '' },
  type: { value: '' },
  validUntil: { value: null },
}

const getFormattedValidUntil = (validUntil) => {
  if (validUntil == null) return null
  if (typeof validUntil === "string") return validUntil
  return validUntil.$d?.toISOString()
}

const TeamView = ({ role, view }) => {
  const navigate = useNavigate();
  const { site } = useSite()
  const siteId = site?.site_id;
  const [userToAdd, setUserToAdd] = useState(EMPTY_USER_DATA)
  const [userToDelete, setUserToDelete] = useState(null)
  const [savedUserToEdit, setSavedUserToEdit] = useState(null)
  const [userToEdit, setUserToEdit] = useState(null)
  const [alertData, setAlertData] = useState(null)
  const [showExistingUserMessage, setShowExistingUserMessage] = useState(false)

  const toggleExistingUserMessage = useCallback(() => setShowExistingUserMessage(m => !m), [])

  const resetUserToAdd = useCallback(() => setUserToAdd(EMPTY_USER_DATA), []);
  const resetUserToEdit = useCallback(() => {
    setUserToEdit(null);
    setSavedUserToEdit(null);
  }, []);

  const {
    data: users,
    isLoading: isLoadingUsers,
    isFetching: isFetchingUsers,
    refetch: refetchUsers,
  } = useQuery(['GET_USERS', role, site?.site_id], getUsersFn, {
    enabled: !!siteId,
  });

  const onAddSuccess = useCallback(
    (_, { user }) => {
      setAlertData({
        type: ALERT_TYPE.add,
        email: user.email,
        role: getRoleLabel(user.role),
      });
      resetUserToAdd();
      refetchUsers();
      navigate('..');
    },
    [resetUserToAdd, refetchUsers, navigate]
  );

  const onAddError = useCallback(
    (e) => {
      if (e.code === 'VALIDATION_FAILURE') toggleExistingUserMessage();
    },
    [toggleExistingUserMessage]
  );

  const { mutate: addUser, isLoading: isAddingUser } = useMutation(addUserFn, {
    onSuccess: onAddSuccess,
    onError: onAddError,
  });

  const onUpdateSuccess = useCallback(() => {
    resetUserToEdit();
    refetchUsers();
    navigate('..');
  }, [resetUserToEdit, refetchUsers, navigate]);

  const { mutate: updateUser, isLoading: isUpdatingUser } = useMutation(
    updateUserFn,
    { onSuccess: onUpdateSuccess }
  );

  const onDeleteSuccess = useCallback(
    (_, { user }) => {
      setAlertData({
        type: ALERT_TYPE.delete,
        email: user.email,
        role: getRoleLabel(user.role),
      });
      setUserToDelete(null);
      resetUserToEdit();
      refetchUsers();
      if (view === TEAM_VIEWS.edit)
        navigate('..');
    },
    [resetUserToEdit, refetchUsers, view, navigate]
  );

  const { mutate: deleteUser } = useMutation(deleteUserFn, {
    onSuccess: onDeleteSuccess,
  });

  const enableEditSave = useMemo(() => {
    const hasChanges = !isEqual(savedUserToEdit, userToEdit)
    const validatedValidUntil = typeof userToEdit?.validUntil === "string" || !isNaN(userToEdit?.validUntil?.$D)
    return hasChanges && validatedValidUntil
  }, [savedUserToEdit, userToEdit])

  useEffect(() => {
    setAlertData(null);
    resetUserToEdit();
    resetUserToAdd();
  }, [resetUserToAdd, resetUserToEdit, siteId]);

  useEffect(() => {
    if (view === TEAM_VIEWS.edit && !userToEdit) navigate('..');
  }, [view, userToEdit, navigate]);

  const handleEdit = useCallback(
    (row) => {
      setUserToEdit(row);
      setSavedUserToEdit(row);
      navigate('./edit');
    },
    [navigate]
  );

  const handleDelete = useCallback((row) => {
    setUserToDelete(row)
  }, [])

  const handleDeleteConfirm = useCallback(() => {
    if (userToDelete && siteId) {
      deleteUser({ user: userToDelete, siteId });
    }
  }, [userToDelete, siteId, deleteUser]);

  const handleDeleteCancel = useCallback(() => {
    setUserToDelete(null)
  }, [])

  const handleAdd = useCallback(() => {
    navigate('./add');
  }, [navigate]);

  const handleNewUserDataChange = useCallback((value, attribute) => {
    setUserToAdd(d => ({
      ...d,
      [attribute]: { value }
    }))
  }, [])

  const validateNewUser = useCallback(() => {
    let isValid = true
    let validatedData = {...userToAdd}
    Object.keys(validatedData).forEach((key) => {
      if ((typeof validatedData[key].value === "string" && validatedData[key].value.trim() === "") || validatedData[key].value == null) {
        validatedData[key] = {
          ...validatedData[key],
          error: true,
          helperText: INPUT_VALIDATION.required,
        }
        isValid = false
      } else if (key === "email" && !emailRegex.test(validatedData.email.value)) {
        validatedData[key] = {
          ...validatedData[key],
          error: true,
          helperText: INPUT_VALIDATION.invalidEmail,
        }
        isValid = false
      } else if (key === "validUntil" && isNaN(validatedData.validUntil.value?.$D)) {
        isValid = false
      }
    })
    if (!isValid) {
      setUserToAdd(validatedData)
    }
    return isValid
  }, [userToAdd])

  const handleAddUser = useCallback(() => {
    if (validateNewUser()) {
      const newUser = {
        email: userToAdd.email.value,
        role: userToAdd.role.value,
        type: userToAdd.type.value,
        validUntil: getFormattedValidUntil(userToAdd.validUntil.value),
      };
      addUser({ user: newUser, siteId });
    }
  }, [
    validateNewUser,
    userToAdd.email.value,
    userToAdd.role.value,
    userToAdd.type.value,
    userToAdd.validUntil.value,
    siteId,
    addUser,
  ]);

  const handleAlertClose = useCallback(() => setAlertData(null), [])

  const handleEditUserDataChange = useCallback((value, attribute) => {
    setUserToEdit(d => ({
      ...d,
      [attribute]: value
    }))
  }, [])

  const handleSaveEdit = useCallback(() => {
    const userToSave = {
      ...userToEdit,
      validUntil: getFormattedValidUntil(userToEdit.validUntil),
      status:
        userToEdit.status === USER_SITE_STATUS.pending
          ? USER_SITE_STATUS.active
          : userToEdit.status,
    };
    updateUser({ user: userToSave, siteId });
  }, [siteId, userToEdit, updateUser]);

  const [showEditPrompt, confirmEditNavigation] = useCallbackPrompt(
    view === TEAM_VIEWS.edit && enableEditSave && !isUpdatingUser
  );

  const onSaveEdit = useCallback(() => {
    handleSaveEdit();
    confirmEditNavigation();
  }, [handleSaveEdit, confirmEditNavigation]);

  const [showAddPrompt, confirmAddNavigation, cancelAddNavigation] =
    useCallbackPrompt(
      view === TEAM_VIEWS.add && EMPTY_USER_DATA !== userToAdd && !isAddingUser
    );

  const onDiscardAdd = useCallback(() => {
    resetUserToAdd();
    confirmAddNavigation();
  }, [resetUserToAdd, confirmAddNavigation]);

  return (
    <>
      <DiscardChangesDialog
        open={showEditPrompt}
        onSave={onSaveEdit}
        onDiscard={confirmEditNavigation}
      />
      <DiscardChangesDialog
        open={showAddPrompt}
        onSave={cancelAddNavigation}
        onDiscard={onDiscardAdd}
        text="¡Espera! Aún no agregaste al usuario, ¿deseas salir de esta página?"
        discardText='Sí, salir'
        saveText="Terminar de agregar"
        saveIcon={<></>}
      />
      <SimpleModal
        open={userToDelete != null}
        message="¿Quieres eliminar de tu equipo a este usuario?"
        primaryText="eliminar"
        onPrimaryClick={handleDeleteConfirm}
        secondaryText="cancelar"
        onSecondaryClick={handleDeleteCancel}
      />
      <SimpleModal
        open={showExistingUserMessage}
        message="Este usuario ya está dado de alta"
        primaryText="cerrar"
        onPrimaryClick={toggleExistingUserMessage}
      />
      <PageLayout
        loading={isLoadingUsers}
        links={view === TEAM_VIEWS.list ? salesTeamTabs : undefined}
        menu={
          <>
            {view === TEAM_VIEWS.list &&
              <Button
                onClick={handleAdd}
                color="primary"
                variant='contained'
                size="small"
              >
                Agregar
              </Button>
            }
            {view === TEAM_VIEWS.add &&
              <>
                <Button
                  component={Link}
                  to=".."
                  color="primary"
                  startIcon={<ArrowBackIcon />}
                  disabled={isAddingUser}
                  sx={{ mr: 'auto' }}
                >
                  Volver
                </Button>
                <LoadingButton
                  text="Agregar"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleAddUser}
                  disabled={isAddingUser}
                  loading={isAddingUser}
                >
                  Agregar
                </LoadingButton>
              </>
            }
            {view === TEAM_VIEWS.edit &&
              <>
                <Button
                  component={Link}
                  to=".."
                  color="primary"
                  startIcon={<ArrowBackIcon />}
                  disabled={isUpdatingUser}
                  sx={{ mr: 'auto' }}
                >
                  Volver
                </Button>
                <FlexContainer>
                  <Button
                    color="primary"
                    variant='outlined'
                    onClick={() => handleDelete(userToEdit)}
                    disabled={isUpdatingUser}
                  >
                    Eliminar
                  </Button>
                  <Spacer size={2} />
                  <PendingButton
                    text="Guardar"
                    onClick={handleSaveEdit}
                    showBullet={enableEditSave}
                    loading={isUpdatingUser}
                  />
                </FlexContainer>
              </>
            }
          </>
        }
      >
        {view === TEAM_VIEWS.add && (
          <AddUserForm
            newUserData={userToAdd}
            onNewUserDataChange={handleNewUserDataChange}
          />
        )}
        {view === TEAM_VIEWS.edit && userToEdit && (
          <TeamEdit
            user={userToEdit}
            onEdit={handleEditUserDataChange}
            onSave={handleSaveEdit}
          />
        )}
        {view === TEAM_VIEWS.list && (
          <TeamList
            loading={isFetchingUsers}
            users={users}
            onEdit={handleEdit}
            onDelete={handleDelete}
            alertData={alertData}
            onAlertClose={handleAlertClose}
          />
        )}
      </PageLayout>
    </>
  )
}

export default TeamView
