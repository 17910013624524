import React from 'react'
import Spacer from '../Spacer/Spacer'
import FlexContainer from '../FlexContainer/FlexContainer'
import { Slider, Typography } from '@mui/material'

const RangeSlider = ({ title, description, value, onChange, min, max, step, marks, endSymbol = '', hideSlider, disabled }) => (
  <FlexContainer vertical>
    <FlexContainer justified>
      <Typography variant='subtitle1'>{title}</Typography>
      <Typography variant='h6'>{disabled ? '-' : `${value}${endSymbol}`}</Typography>
    </FlexContainer>
    {description && (
      <Typography variant='caption'>{description}</Typography>
    )}
    {!hideSlider && (
      <>
        <Spacer vertical size={5} />
        <Slider
          disabled={disabled}
          defaultValue={min}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="on"
          step={step}
          marks={marks}
          min={min}
          max={max}
          value={value}
          onChange={(_, value) => onChange(value)}
        />
        <FlexContainer justified>
          <Typography variant='body2'>{min}</Typography>
          <Typography variant='body2'>{max}</Typography>
        </FlexContainer>
      </>
    )}
  </FlexContainer>
)

export default RangeSlider