import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get } from '../../helpers/request';
import { intelimetricaTabs } from '../../helpers/tabsData';
import PageLayout from '../../layouts/PageLayout';

export const CATEGORY = 'integrations';
export const SUBCATEGORY = 'intelimetrica';

export const IntelimetricaLayout = ({
  children,
  site,
  reloadLayout = true,
  setReloadLayout,
  onReportChange,
  avoidReportRedirect,
  layoutMenu,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState({});

  useEffect(() => {
    if (onReportChange) {
      onReportChange(report);
    }
  }, [report, onReportChange]);

  const [tabs, setTabs] = useState(intelimetricaTabs);
  useEffect(() => {
    if (site?.site_id) {
      Promise.all([
        get(`/internal/alohub/integrations/intelimetrica/config/contact-data`, {
          site_id: site.site_id,
        }),
        get('/internal/alohub/integrations/intelimetrica/reports', {
          site_id: site.site_id,
        }),
      ]).then(([result, report]) => {
        if (!!result && !result.alohome_terms) {
          tabs.tabs[0].disabled = true;
          tabs.tabs[1].disabled = false;
          tabs.tabs[2].disabled = true;
          setTabs(intelimetricaTabs);
          navigate(`../${tabs.tabs[1].href}`, { replace: true });
        } else if (report.results.length > 0) {
          const tabs = intelimetricaTabs;
          tabs.tabs[0].disabled = false;
          tabs.tabs[1].disabled = true;
          tabs.tabs[2].disabled = false;
          setTabs(tabs);
          setReport(report);
          if (!avoidReportRedirect)
            navigate(`../${tabs.tabs[0].href}`, { replace: true });
        } else if (result) {
          const tabs = intelimetricaTabs;
          tabs.tabs[0].disabled = true;
          tabs.tabs[1].disabled = true;
          tabs.tabs[2].disabled = false;
          setTabs(tabs);
          navigate(`../${tabs.tabs[2].href}`, { replace: true });
        } else {
          const tabs = intelimetricaTabs;
          tabs.tabs[0].disabled = true;
          tabs.tabs[1].disabled = true;
          tabs.tabs[2].disabled = false;
          setTabs(tabs);
          navigate(`../${tabs.tabs[2].href}`, { replace: true });
        }
        setLoading(false);
      });
      if (setReloadLayout) setReloadLayout(false);
    }
  }, [
    site?.site_id,
    tabs?.tabs,
    navigate,
    reloadLayout,
    setReloadLayout,
    avoidReportRedirect,
  ]);
  return <PageLayout links={tabs} menu={layoutMenu}>{loading ? null : children}</PageLayout>;
};
