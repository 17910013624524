import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FlexContainer, Spacer, StatusChip } from '../../components'
import { convertMMYYtoFormat, formatDate } from '../../helpers/utils'
import useCurrencies from '../../hooks/useCurrencies'
import useSettings from '../../hooks/useSettings'
import PriceChangeInfo from './PriceChangeInfo'

const Container = styled(TableContainer)`
  padding: 0 24px;
  height: calc(100vh - 400px);
  overflow-y: auto;
`
const VIEW_TEXTS = {
  title: "Departamento ID",
  status: {
    prev: "Estado Anterior",
    new: "Estado Nuevo",
  },
  reserved_date: "Fecha de Reserva",
  sold_date: "Fecha de Venta",
  delivery_date: {
    prev: "Fecha de Entrega Anterior",
    new: "Fecha de Entrega Nueva",
  },
  price: {
    prev: "Precio Anterior",
    new: "Precio Nuevo",
  },
  full_upfront_payment_price: {
    prev: "Precio al Contado Anterior",
    new: "Precio al Contado Nuevo",
  },
}

const NewChangesTable = ({ unitsToUpdate }) => {
  const [showState, setShowState] = useState(false)
  const [showReservedDate, setShowReservedDate] = useState(false)
  const [showSoldDate, setShowSoldDate] = useState(false)
  const [showDeliveryDate, setShowDeliveryDate] = useState(false)
  const [showPrice, setShowPrice] = useState(false)
  const [showTable, setShowTable] = useState(false)
  const [showFullUpfrontPaymentPrice, setShowFullUpfrontPaymentPrice] = useState(false)
  const currencies = useCurrencies()
  const settings = useSettings()
  const mainCurrencyCode = settings.dualCurrency.main

  useEffect(() => {
    unitsToUpdate.forEach(u => {
      if (u.new_status) setShowState(true)
      if (u.reserved_date) setShowReservedDate(true)
      if (u.sold_date) setShowSoldDate(true)
      if (u.new_delivery_date) setShowDeliveryDate(true)
      if (u.new_price) setShowPrice(true)
      if (u.new_full_upfront_payment_price || u.new_full_upfront_payment_price === null) setShowFullUpfrontPaymentPrice(true)
    })
    setShowTable(true)
  }, [unitsToUpdate])

  return (
    <Container>
      {showTable && currencies && (
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center"><b>{VIEW_TEXTS.title}</b></TableCell>
              {showState && (
                <>
                  <TableCell align="center"><b>{VIEW_TEXTS.status.prev}</b></TableCell>
                  <TableCell align="center"><b>{VIEW_TEXTS.status.new}</b></TableCell>
                </>
              )}
              {showReservedDate && <TableCell align="center"><b>{VIEW_TEXTS.reserved_date}</b></TableCell>}
              {showSoldDate && <TableCell align="center"><b>{VIEW_TEXTS.sold_date}</b></TableCell>}
              {showDeliveryDate && (
                <>
                  <TableCell align="center"><b>{VIEW_TEXTS.delivery_date.prev}</b></TableCell>
                  <TableCell align="center"><b>{VIEW_TEXTS.delivery_date.new}</b></TableCell>
                </>
              )}
              {showPrice && (
                <>
                  <TableCell align="center"><b>{VIEW_TEXTS.price.prev}</b></TableCell>
                  <TableCell align="center"><b>{VIEW_TEXTS.price.new}</b></TableCell>
                </>
              )}
              {showFullUpfrontPaymentPrice && (
                <>
                  <TableCell align="center"><b>{VIEW_TEXTS.full_upfront_payment_price.prev}</b></TableCell>
                  <TableCell align="center"><b>{VIEW_TEXTS.full_upfront_payment_price.new}</b></TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {unitsToUpdate.map(unit => (
              <TableRow key={unit.unit_id}>
                <TableCell align="center">{unit.department_number}</TableCell>
                {showState && (
                  <>
                    <TableCell align="center">
                      {unit.new_status
                        ? <StatusChip value={unit.status} disabled />
                        : <>-</>
                      }
                    </TableCell>
                    <TableCell align="center">
                      {unit.new_status
                        ? <StatusChip value={unit.new_status} disabled />
                        : <>-</>
                      }
                    </TableCell>
                  </>
                )}
                {showReservedDate && <TableCell align="center">{unit.reserved_date ? formatDate(unit.reserved_date) : '-'}</TableCell>}
                {showSoldDate && <TableCell align="center">{unit.sold_date ? formatDate(unit.sold_date) : '-'}</TableCell>}
                {showDeliveryDate && (
                  <>
                    <TableCell align="center">{unit.delivery_date ? convertMMYYtoFormat(unit.delivery_date, 'MMMM YYYY') : '-'}</TableCell>
                    <TableCell align="center">{unit.new_delivery_date ? convertMMYYtoFormat(unit.new_delivery_date, 'MMMM YYYY') : '-'}</TableCell>
                  </>
                )}
                {showPrice && (
                  <>
                    <TableCell align="center">
                      {unit[`price_${mainCurrencyCode}`]
                        ? <>{`${currencies.main.symbol}${unit[`price_${mainCurrencyCode}`].toLocaleString()}`}</>
                        : <>-</>
                      }
                    </TableCell>
                    <TableCell align="center">
                      {unit.new_price
                        ? (
                          <FlexContainer centered>
                            <PriceChangeInfo basePrice={unit[`price_${mainCurrencyCode}`]} newPrice={unit.new_price} />
                            <Spacer size={1} />
                            {`${currencies.main.symbol}${unit.new_price.toLocaleString()}`}
                          </FlexContainer>
                        )
                        : <>-</>
                      }
                    </TableCell>
                  </>
                )}
                {showFullUpfrontPaymentPrice && (
                  <>
                    <TableCell align="center">
                      {unit[`price_${mainCurrencyCode}_full_upfront_payment`]
                        ? <>{`${currencies.main.symbol}${unit[`price_${mainCurrencyCode}_full_upfront_payment`].toLocaleString()}`}</>
                        : <>-</>
                      }
                    </TableCell>
                    <TableCell align="center">
                      {unit.new_full_upfront_payment_price
                        ? (
                          <FlexContainer centered>
                            <PriceChangeInfo basePrice={unit[`price_${mainCurrencyCode}_full_upfront_payment`]} newPrice={unit.new_full_upfront_payment_price} />
                            <Spacer size={1} />
                            {`${currencies.main.symbol}${unit.new_full_upfront_payment_price.toLocaleString()}`}
                          </FlexContainer>
                        )
                        : <>-</>
                      }
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Container>
  )
}

export default NewChangesTable