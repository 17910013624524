import { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get } from '../helpers/request'
import { getDaysBetweenDates } from '../helpers/utils'
import useProfile from './useProfile'

const GET_SHOWROOM_CONFIG = "/internal/settings/v1/settings?category=showroom&subcategory=config"

const calculateRemainingDays = (trialEndDay) => {
  if (trialEndDay) {
    const today = new Date()

    const days = getDaysBetweenDates(today, new Date(trialEndDay))
    return days > 0 ? Math.ceil(days) : 0
  }
  return Infinity;
}

const useSite = ({ fetchSite = false } = {}) => {
  const profile = useProfile()
  const selectedSite = useSelector(state => state.site)
  const dispatch = useDispatch()

  const setSite = useCallback(async (newSite) => {
    if (newSite) {
      const config = await get(GET_SHOWROOM_CONFIG, { site_id: newSite?.site_id })
      const remainingDays = calculateRemainingDays(newSite?.planExpirationDate)
      dispatch({ type: 'set-site', data: {
        ...newSite,
        ...config,
        remainingDays,
      }})
    }
  }, [dispatch])

  const changeSiteData = useCallback((data) => dispatch({ type: 'set-site', data }), [dispatch])

  const resetSite = useCallback(async () => {
    const newProfile = await get('/internal/login/v1/profile')
    const newSite = newProfile.user.sites.find(s => s.site_id === selectedSite?.site_id)
    setSite(newSite)
  }, [selectedSite?.site_id, setSite])

  useEffect(() => {
    if (profile && !selectedSite && fetchSite) {
      setSite(profile.sites[0])
    }
  }, [fetchSite, profile, selectedSite, setSite])

  return {
    site: selectedSite,
    setSite,
    changeSiteData,
    resetSite,
  }
}

export default useSite
