export function formatRecordName(name) {
  return name.replace(/(.+)\.$/, '$1');
}

export function valueToRecordName(value) {
  return `${value}.`;
}

export function getRecordNameSubdomain(name, value) {
  const result = value.replace(name, '');
  if (!result) return result;
  return result.substring(0, result.length - 1);
}

export function getRecordFromSubdomainAndRoot(subdomain, root) {
  return subdomain ? `${subdomain}.${root}` : root;
}
