import {
  Box,
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  Typography,
} from '@mui/material';
import React, { useCallback } from 'react';

export const MUST_BE_SHOW_ON = {
  PHYSICALLY_AND_DIGITALLY: 'PHYSICALLY_AND_DIGITALLY',
  PHYSICALLY_OR_DIGITALLY: 'PHYSICALLY_OR_DIGITALLY',
  PHYSICALLY: 'PHYSICALLY',
  DIGITALLY: 'DIGITALLY',
};

const CheckItem = ({
  checked,
  value,
  setValuePath,
  title,
  NOMSection,
  mustBeShowOn,
  children,
  digitallyChildren,
}) => {
  const handleCheckChange = useCallback(
    (e) => setValuePath('value', e.target.checked),
    [setValuePath]
  );

  const handlePhysicallyChange = useCallback(
    (e) => setValuePath('physically', e.target.checked),
    [setValuePath]
  );

  const handleDigitallyChange = useCallback(
    (e) => setValuePath('digitally', e.target.checked),
    [setValuePath]
  );

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          borderRadius: 1,
          border: 'solid 1px #0000001F',
          counterIncrement: 'checklist',
        }}
      >
        <Checkbox
          color="primary"
          checked={value?.value || checked || false}
          disabled={!setValuePath}
          onChange={handleCheckChange}
        />
        <Box
          sx={{
            width: 1,
            display: 'flex',
            alignItems: 'flex-start',
            gap: 1,
            pt: '9px',
          }}
        >
          <Box
            sx={{
              '&::before': {
                content: `counter(checklist) " - "`,
              },
            }}
          />
          <Box sx={{ maxWidth: 0.7 }}>
            {typeof title !== 'string' ? (
              title
            ) : (
              <Typography component="span">{title}</Typography>
            )}
          </Box>
          <Typography
            component="span"
            variant="caption"
            sx={{ color: 'text.secondary', ml: 'auto', mr: 2 }}
          >
            {NOMSection}
          </Typography>
        </Box>
      </Box>
      {(mustBeShowOn || children) && (
        <Collapse in={value?.value || checked}>
          <Box sx={{ mt: 4 }}>
            {['PHYSICALLY', 'DIGITALLY'].includes(mustBeShowOn) && (
              <Typography component="div" sx={{ mb: 2 }}>
                <Box component="span" sx={{ mr: 1 }}>
                  Esta información se muestra:
                </Box>
                {mustBeShowOn === 'PHYSICALLY' && (
                  <Chip label="PUNTO DE VENTA" />
                )}
                {mustBeShowOn === 'DIGITALLY' && <Chip label="DIGITALMENTE" />}
              </Typography>
            )}
            {mustBeShowOn === 'PHYSICALLY_AND_DIGITALLY' && (
              <>
                <Typography component="div" sx={{ mb: 2 }}>
                  <Box component="span" sx={{ mr: 1 }}>
                    Esta información debe mostrarse en:
                  </Box>
                  <Chip label="PUNTO DE VENTA" /> Y{' '}
                  <Chip label="DIGITALMENTE" />
                </Typography>
              </>
            )}
            {mustBeShowOn === 'PHYSICALLY_OR_DIGITALLY' && (
              <>
                <Typography component="div" sx={{ mb: 2 }}>
                  <Box component="span" sx={{ mr: 1 }}>
                    Esta información puede mostrarse en:
                  </Box>
                  <Chip label="PUNTO DE VENTA" /> O{' '}
                  <Chip label="DIGITALMENTE" />
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Typography component="span" sx={{ mr: 2 }}>
                    ¿Cómo desea mostrar esta información?
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value?.physically}
                        onChange={handlePhysicallyChange}
                      />
                    }
                    label="Punto de venta"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value?.digitally}
                        onChange={handleDigitallyChange}
                      />
                    }
                    label="Digitalmente"
                  />
                </Box>
              </>
            )}
            {value?.digitally && digitallyChildren}
            {children}
          </Box>
        </Collapse>
      )}
    </div>
  );
};

export default CheckItem;
