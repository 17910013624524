import { Button } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getKeysAsPath } from '../../helpers/data';
import { getPermission } from '../../helpers/permissions';
import useSite from '../../hooks/useSite';
import AttachedFiles from './AttachedFiles';
import CheckItem, { MUST_BE_SHOW_ON } from './CheckItem';
import Section from './Section';

export const PM_AND_COSTS_URL_SLUG = 'payment-methods';

export const PM_AND_COSTS_NOM_PATH = 'paymentMethodsAndCosts';

export const PM_AND_COSTS_CHECKLIST_IDS = [
  'Art_4-Sec_I',
  'Art_6-Sec_XVIII',
  'Art_5_3',
  'Art_5-Sec_5_6',
  'Art_5-Sec_V-PaymentMethods',
  'Art_4-Sec_VI',
  'Art_4-Sec_II-PaymentMethods',
  'Art_4_2-Sec_VI',
  'Art_5-Sec_V-PaymentSimulation',
  'Art_6-Sec_XXI-BeforeContractSigning',
  'Art_6-Sec_XXI-AfterContractSigning',
  'Art_4_4',
  'Art_4-ProofOfPayment',
  'Art_4-BimestralAccountState',
];

const PaymentMethodsAndCosts = ({ getSectionData, setSectionData }) => {
  const { site } = useSite();

  const quoteConfigPath = useMemo(() => {
    if (!site) return;
    const canViewProjectQuoteEditor = getPermission(
      ['project', 'quote_editor'],
      site.permissions
    );
    if (canViewProjectQuoteEditor !== 'hidden') return '/project/unit-quote';
    const canViewTypologyQuoteEditor = getPermission(
      ['project', 'typology_quote_editor'],
      site.permissions
    );
    if (canViewTypologyQuoteEditor !== 'hidden')
      return '/project/typology-quote';
  }, [site]);

  const inventoryPath = useMemo(() => {
    if (!site) return;
    const canViewInventory = getPermission(
      ['project', 'inventory', 'view_inventory'],
      site.permissions
    );
    if (canViewInventory !== 'hidden') return '/project/inventory';
    const hasAccessToShowroom = getPermission(
      ['digital_showroom', 'editor'],
      site.permissions
    );
    if (hasAccessToShowroom !== 'hidden')
      return '/digital-showroom/editor?goto=carousel-typologies';
  }, [site]);

  const getValue = useCallback(
    (id) => getSectionData(getKeysAsPath(['checkList', id])),
    [getSectionData]
  );

  const getHandleValueChange = useCallback(
    (id) => (path, value) =>
      setSectionData(getKeysAsPath(['checkList', id, path]), value),
    [setSectionData]
  );

  return (
    <Section title="Métodos de pago y costos" id="payment-methods">
      <CheckItem
        value={getValue('Art_4-Sec_I')}
        setValuePath={getHandleValueChange('Art_4-Sec_I')}
        title="Precios totales en operaciones de contado"
        NOMSection="Art. 4 Sec. I"
        mustBeShowOn={MUST_BE_SHOW_ON.PHYSICALLY_OR_DIGITALLY}
        digitallyChildren={
          inventoryPath && (
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              to={inventoryPath}
            >
              Editar
            </Button>
          )
        }
      />
      <CheckItem
        value={getValue('Art_6-Sec_XVIII')}
        setValuePath={getHandleValueChange('Art_6-Sec_XVIII')}
        title="Gastos notariales y gastos de escrituración"
        NOMSection="Art. 6, Sec. XVIII"
        mustBeShowOn={MUST_BE_SHOW_ON.DIGITALLY}
      >
        {quoteConfigPath && (
          <Button
            variant="outlined"
            color="primary"
            component={Link}
            to={quoteConfigPath}
          >
            Editar
          </Button>
        )}
      </CheckItem>
      <CheckItem
        value={getValue('Art_5_3')}
        setValuePath={getHandleValueChange('Art_5_3')}
        title="Lista de precios de contado de unidades disponibles"
        NOMSection="Art. 5.3"
        mustBeShowOn={MUST_BE_SHOW_ON.DIGITALLY}
      >
        {inventoryPath && (
          <Button
            variant="outlined"
            color="primary"
            component={Link}
            to={inventoryPath}
          >
            Editar
          </Button>
        )}
      </CheckItem>
      <CheckItem
        value={getValue('Art_5-Sec_5_6')}
        setValuePath={getHandleValueChange('Art_5-Sec_5_6')}
        title="El precio de venta del inmueble debe ofertarse en moneda nacional. Si se hace en dólares, es necesario mostrar el tipo de cambio y monto final correspondiente al tipo de cambio del día de la firma."
        NOMSection="Art. 5 Sec. 5.6"
      />
      <CheckItem
        value={getValue('Art_5-Sec_V-PaymentMethods')}
        setValuePath={getHandleValueChange('Art_5-Sec_V-PaymentMethods')}
        title="Métodos de pago disponibles por unidad privativa a comercializar, señalando específicamente las unidades privativas que podrán ser adquiridas con crédito."
        NOMSection="Art. 5 Sec. V"
        mustBeShowOn={MUST_BE_SHOW_ON.DIGITALLY}
      >
        {inventoryPath && (
          <Button
            variant="outlined"
            color="primary"
            component={Link}
            to={inventoryPath}
          >
            Editar
          </Button>
        )}
      </CheckItem>
      <CheckItem
        value={getValue('Art_4-Sec_VI')}
        setValuePath={getHandleValueChange('Art_4-Sec_VI')}
        title="En caso de que el inmueble esté financiado a meses por el proveedor, éste debe mostrar las opciones de financiamiento y costos (porcentaje de la tasa de interés, el costo anual total, el monto de las comisiones, así como los seguros adheridos al financiamiento y sus coberturas que existieren)"
        NOMSection="Art. 4 , Sec. VI"
        mustBeShowOn={MUST_BE_SHOW_ON.DIGITALLY}
      >
        {quoteConfigPath && (
          <Button
            variant="outlined"
            color="primary"
            component={Link}
            to={quoteConfigPath}
          >
            Editar
          </Button>
        )}
      </CheckItem>
      <CheckItem
        value={getValue('Art_4-Sec_II-PaymentMethods')}
        setValuePath={getHandleValueChange('Art_4-Sec_II-PaymentMethods')}
        title="Leyenda que indique lineamientos en las operaciones de crédito. El proveedor es responsable de indicar las opciones de pago que acepta del consumidor, señalar si acepta o no créditos y de qué instituciones. Esto por cada unidad privativa que pretenda comercializar"
        NOMSection="Art. 4 Sec. II"
        mustBeShowOn={MUST_BE_SHOW_ON.DIGITALLY}
      >
        {quoteConfigPath && (
          <Button
            variant="outlined"
            color="primary"
            component={Link}
            to={quoteConfigPath}
          >
            Editar
          </Button>
        )}
      </CheckItem>
      <CheckItem
        value={getValue('Art_4_2-Sec_VI')}
        setValuePath={getHandleValueChange('Art_4_2-Sec_VI')}
        title="Seguros adheridos al financiamiento y sus coberturas que existieren"
        NOMSection="Art. 4.2, Sec. vi"
        mustBeShowOn={MUST_BE_SHOW_ON.DIGITALLY}
      >
        {quoteConfigPath && (
          <Button
            variant="outlined"
            color="primary"
            component={Link}
            to={quoteConfigPath}
          >
            Editar
          </Button>
        )}
      </CheckItem>
      <CheckItem
        value={getValue('Art_5-Sec_V-PaymentSimulation')}
        setValuePath={getHandleValueChange('Art_5-Sec_V-PaymentSimulation')}
        title="En el caso de aceptar hipotecas, dirigir a un simulador donde se indiquen los diferentes costos"
        NOMSection="Art. 5 Sec. V"
        mustBeShowOn={MUST_BE_SHOW_ON.DIGITALLY}
      >
        {quoteConfigPath && (
          <Button
            variant="outlined"
            color="primary"
            component={Link}
            to={quoteConfigPath}
          >
            Editar
          </Button>
        )}
      </CheckItem>
      <CheckItem
        value={getValue('Art_6-Sec_XXI-BeforeContractSigning')}
        setValuePath={getHandleValueChange(
          'Art_6-Sec_XXI-BeforeContractSigning'
        )}
        title="Condiciones para cancelación de la operación entre anticipo y firma de contrato de acuerdo al contrato de adhesión (devolución, plazos, penalizaciones) "
        NOMSection="Art. 6, Sec. XXI"
        mustBeShowOn={MUST_BE_SHOW_ON.DIGITALLY}
      >
        <AttachedFiles
          files={getSectionData('cancelationBeforeContractTerms.files')}
          setFiles={(v) =>
            setSectionData('cancelationBeforeContractTerms.files', v)
          }
        />
      </CheckItem>
      <CheckItem
        value={getValue('Art_6-Sec_XXI-AfterContractSigning')}
        setValuePath={getHandleValueChange(
          'Art_6-Sec_XXI-AfterContractSigning'
        )}
        title="Condiciones para cancelación de la operación después de firma de contrato de acuerdo al contrato de adhesión (devolución, plazos, penalizaciones)"
        NOMSection="Art. 6, Sec. XXI"
        mustBeShowOn={MUST_BE_SHOW_ON.PHYSICALLY}
      />
      <CheckItem
        value={getValue('Art_4_4')}
        setValuePath={getHandleValueChange('Art_4_4')}
        title="Entregar comprobante de pago de anticipo/apartado de la unidad"
        NOMSection="Art. 4.4"
      />
      <CheckItem
        value={getValue('Art_4-ProofOfPayment')}
        setValuePath={getHandleValueChange('Art_4-ProofOfPayment')}
        title="Entregar comprobante de pago de todos los pagos de la compra de la unidad"
        NOMSection="Art. 4"
      />
      <CheckItem
        value={getValue('Art_4-BimestralAccountState')}
        setValuePath={getHandleValueChange('Art_4-BimestralAccountState')}
        title="Entregar un estado de cuenta bimestral (cargos, pagos, intereses y comisiones) por cada unidad vendida"
        NOMSection="Art. 4"
      />
    </Section>
  );
};

export default PaymentMethodsAndCosts;
