import { Button, TextField, Typography } from "@mui/material"
import React from "react"
import styled from "styled-components"
import { FlexContainer, Spacer } from "../../components"
import { numberToCurrency } from "../../helpers/utils"
import FixedPlansQuoteSettings from "./FixedPlansQuoteSettings"
import FlexibleQuoteSettings from "./FlexibleQuoteSettings"
import { TYPE } from "./utils"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Container = styled(FlexContainer)`
  gap: 84px;
  padding: 32px;
  display: ${p => p.open ? 'flex' : 'none'};
`

const Column = styled(FlexContainer)`
  width: 50%;
  flex-direction: column;
`

const Preview = styled.iframe`
  width: calc(100% - 34px);
  height: 700px;
  border: 1px solid;
  border-radius: 8px;
`

const QuoteConfig = ({
  open,
  siteName,
  activePayments,
  type,
  fixedOnlinePayment,
  expirationDays,
  onFixedOnlinePaymentChange,
  onFixedOnlinePaymentBlur,
  onExpirationDaysChange,
  minDownPaymentPercent,
  onMinDownPaymentPercentChange,
  maxDownPaymentPercent,
  onMaxDownPaymentPercentChange,
  minPreSalePercent,
  onMinPreSalePercentChange,
  hasDiscounts,
  discounts,
  onHasDiscountsChange,
  onAddNewDiscount,
  onDeleteDiscount,
  onChangeDiscount,
  onBlurDiscount,
  onKeyUpDiscount,
  fixedNumberOfMonths,
  onFixedNumberOfMonthsChange,
  inventoryType,
  plans,
  onAddNewPlan,
  onDeletePlan,
  onConfirmPlan,
  onReorderPlan,
  previewUnit,
  previewUrl,
  previewRef,
  onGoBack,
  hasPaymentSchemes,
  hasExternalLoan,
  fixedMinPreSalePercent,
  onFixedMinPreSalePercentChange,
  planName,
  onPlanNameChange,
}) => {
  return (
    <Container fullWidth open={open}>
      <Column>
        <FlexContainer>
          <Button startIcon={<ArrowBackIcon />} color="primary" onClick={onGoBack}>REGRESAR A PREGUNTAS INICIALES</Button>
        </FlexContainer>
        <Spacer size={2} vertical />
        <Typography variant='h5'>Cotizador del proyecto {siteName}</Typography>
        <Spacer vertical size={1} />
        <FlexContainer vertical>
          <Typography variant='caption'>Establece las cifras con las que los clientes podrán personalizar su plan de pagos: <b>esto agilizará las cotizaciones y cierres de venta</b></Typography>
          {activePayments && (
            <>
              <Spacer size={3} vertical />
              <Typography variant='subtitle1'>Apartado Online</Typography>
              <Typography variant='caption'>Cifra que los clientes deben pagar (con tarjeta de crédito o débito) para apartar una unidad</Typography>
              <Spacer size={1} vertical />
              <TextField
                variant='standard'
                size="small"
                placeholder='$5,000'
                value={numberToCurrency(fixedOnlinePayment.value, '$')}
                onChange={e => onFixedOnlinePaymentChange(e.target.value)}
                onBlur={e => onFixedOnlinePaymentBlur(e.target.value)}
                error={fixedOnlinePayment.error}
                helperText={fixedOnlinePayment.helperText}
              />
              <Spacer size={3} vertical />
            </>
          )}
          <Spacer size={3} vertical />
          <Typography variant='subtitle1'>Vigencia</Typography>
          <Spacer size={1} vertical />
          <Typography>Define los días de vigencia que tendrán las cotizaciones a partir de que un cliente hace un cálculo en tu showroom</Typography>
          <Spacer size={2} vertical />
          <TextField
            variant='standard'
            size="small"
            placeholder='7'
            label='Número de días'
            value={expirationDays.value}
            onChange={e => onExpirationDaysChange(e.target.value)}
            error={expirationDays.error}
            helperText={expirationDays.error && 'La vigencia debe de ser mayor a 0'}
          />
          <Spacer size={6} vertical />
          {type === TYPE.flexible && (
            <FlexibleQuoteSettings
              minDownPaymentPercent={minDownPaymentPercent}
              onMinDownPaymentPercentChange={onMinDownPaymentPercentChange}
              maxDownPaymentPercent={maxDownPaymentPercent}
              onMaxDownPaymentPercentChange={onMaxDownPaymentPercentChange}
              minPreSalePercent={minPreSalePercent}
              onMinPreSalePercentChange={onMinPreSalePercentChange}
              hasDiscounts={hasDiscounts}
              discounts={discounts}
              onHasDiscountsChange={onHasDiscountsChange}
              onAddNewDiscount={onAddNewDiscount}
              onDeleteDiscount={onDeleteDiscount}
              onChangeDiscount={onChangeDiscount}
              onBlurDiscount={onBlurDiscount}
              onKeyUpDiscount={onKeyUpDiscount}
              fixedNumberOfMonths={fixedNumberOfMonths}
              onFixedNumberOfMonthsChange={onFixedNumberOfMonthsChange}
              inventoryType={inventoryType}
              hasPaymentSchemes={hasPaymentSchemes}
              hasExternalLoan={hasExternalLoan}
            />
          )}
          {type === TYPE.fixed && (
            <FixedPlansQuoteSettings
              plans={plans}
              previewUnit={previewUnit}
              onAddNewPlan={onAddNewPlan}
              onDeletePlan={onDeletePlan}
              onConfirmPlan={onConfirmPlan}
              onReorderPlan={onReorderPlan}
              hasPaymentSchemes={hasPaymentSchemes}
              hasExternalLoan={hasExternalLoan}
              minPreSalePercent={fixedMinPreSalePercent}
              onMinPreSalePercentChange={onFixedMinPreSalePercentChange}
              planName={planName}
              onPlanNameChange={onPlanNameChange}
            />
          )}
        </FlexContainer>
      </Column>
      <Column>
        <Spacer size={2} vertical />
        <Typography>Permite a los clientes explorar y personalizar su plan de pagos</Typography>
        <Spacer size={3} vertical />
        <Preview src={previewUrl} title='preview' ref={previewRef} />
        <Spacer size={3} vertical />
        <Typography variant='caption'>Vista previa: así se verá en tu showroom después de dar clic en el botón “GUARDAR”.</Typography>
      </Column>
    </Container>
  )
}

export default QuoteConfig
