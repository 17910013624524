import {
  FormControl,
  Typography,
  Alert,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { FlexContainer, Spacer } from "../../components";
import { post, put } from "../../helpers/request";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import PublicLayout from "../../layouts/PublicLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";

const FormContent = styled.div`
  width: 700px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  field-content {
    display: flex;
    width: 100%;
  }
  a {
    color: var(--color-text-primary);
  }
`;
const StyledAlert = styled(Alert)`
  align-self: stretch;
`;
const LabelField = styled(Typography)`
  padding: 8px 0;
`;

const CustomTitle = styled(Typography)`
  text-align: center;
  max-width: 900px;
`;

const AcceptInvitationView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [successMessage, setSuccessMessage] = useState(null);
  const [error, setError] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState({
    password: false,
    repeatPassword: false,
  });

  const [form, setForm] = useState({
    password: "",
    repeatPassword: "",
    name: "",
    lastname: "",
  });

  const handleFormChange = useCallback(
    (event) => {
      setForm({ ...form, [event.target.name]: event.target.value });
    },
    [form]
  );

  const handleShowPassword = useCallback(
    (name) => {
      setShowPasswordForm({
        ...showPasswordForm,
        [name]: !showPasswordForm[name],
      });
    },
    [showPasswordForm]
  );

  const validateForm = useCallback(() => {
    if (form.password !== form.repeatPassword) {
      setSuccessMessage(null);
      setError("Las contraseñas no coinciden");
      return false;
    } else if (!form.password || !form.repeatPassword || !form.name || !form.lastname) {
      setSuccessMessage(null);
      setError("Todos los campos son obligatorios");
      return false;
    } else if (form.password.length < 8) {
      setSuccessMessage(null);
      setError("La contraseña debe tener al menos 8 caracteres");
      return false;
    }
    return true
  }, [form.lastname, form.name, form.password, form.repeatPassword])

  const handleResetPassword = useCallback(async () => {
    if (!validateForm()) return

    try {
      await post("/internal/alohub/password-reset/set", {
        password: form.password,
        token,
      })
      setError(null);
      const userData = JSON.parse(atob(token.split('.')[1]))
      const userRes = await post("/internal/login/v1/login", { email: userData.email, password: form.password })
      

      put("/internal/alohub/profile", {
        name: form.name,
        lastname: form.lastname,
      })

      dispatch({ type: "set-profile", data: {
        ...userRes.user,
        name: form.name,
        lastname: form.lastname,
      }})
      navigate('/welcome')
    } catch {
      setError("El link de invitación expiró.");
      setSuccessMessage(null);
    }
  }, [dispatch, form.lastname, form.name, form.password, navigate, token, validateForm]);

  return (
    <PublicLayout
      onPrimaryClick={handleResetPassword}
      primaryLabel="Iniciar Sesión"
    >
      <FlexContainer centered fullWidth vertical>
        <Spacer vertical size={5} />
        <CustomTitle variant="h2">Crea una contraseña</CustomTitle>
        <Spacer vertical size={8} />
        <FormContent>
          <field-content>
            <FormControl variant="filled" fullWidth>
              <LabelField>Nombre</LabelField>
              <OutlinedInput
                id="login-name"
                value={form.name}
                onChange={handleFormChange}
                name="name"
                fullWidth
              />
            </FormControl>
            <Spacer horizontal size={2} />
            <FormControl fullWidth>
              <LabelField>Apellido</LabelField>
              <OutlinedInput
                id="login-lastname"
                onChange={handleFormChange}
                name="lastname"
                value={form.lastname}
                fullWidth
              />
            </FormControl>
          </field-content>
          <Spacer size={6} vertical />
          <field-content>
            <FormControl variant="filled" fullWidth>
              <LabelField>Contraseña</LabelField>
              <OutlinedInput
                id="login-password"
                type={showPasswordForm.password ? "text" : "password"}
                value={form.password}
                onChange={handleFormChange}
                name="password"
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleShowPassword("password")}
                      edge="end"
                    >
                      {showPasswordForm.password ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Spacer horizontal size={2} />
            <FormControl fullWidth>
              <LabelField>Confirmar contraseña</LabelField>
              <OutlinedInput
                id="login-repeat-password"
                type={showPasswordForm.repeatPassword ? "text" : "password"}
                onChange={handleFormChange}
                name="repeatPassword"
                value={form.repeatPassword}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleShowPassword("repeatPassword")}
                      edge="end"
                    >
                      {showPasswordForm.repeatPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </field-content>
          {(error || successMessage) && (
            <>
              <Spacer vertical size={2} />
              <FlexContainer fullWidth centered vertical>
                <StyledAlert severity={successMessage ? "success" : "error"}>
                  {successMessage ? successMessage : error}
                </StyledAlert>
              </FlexContainer>
            </>
          )}
          <Spacer vertical size={4} />
        </FormContent>
      </FlexContainer>
    </PublicLayout>
  );
};

export default AcceptInvitationView
