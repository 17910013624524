import { FormControlLabel, Switch, Typography } from "@mui/material"
import React from "react"
import styled from "styled-components"
import { FlexContainer, Spacer } from "../../components"
import PeriodsTable from "./PeriodsTable"

const HeaderContainer = styled(FlexContainer)`
  width: 400px;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    color: ${p => p.$enabled
      ? p.theme.palette.primary.main
      : p.theme.palette.text.disabled};
  }
`

const WorkProgressGallery = ({
  enabled,
  onEnabledChange,
  periods,
  onPeriodsAdd,
  onPeriodsChange,
  onPeriodsDelete,
  onPeriodsDateChange,
  onPeriodsMediaAdd,
  onPeriodsMediaDelete,
  onPeriodsMediaChange,
  onPeriodsMediaReorder,
}) => (
  <FlexContainer vertical>
    <HeaderContainer vertical>
      <FlexContainer fullWidth justified>
        <Typography variant="subtitle1">Galería de imágenes</Typography>
        <StyledFormControlLabel
          $enabled={enabled}
          control={<Switch
            color="primary"
            checked={enabled}
            onChange={e => onEnabledChange(e.target.checked)}
          />}
          label={enabled ? "Activo" : "Inactivo"}
          labelPlacement="start"
        />
      </FlexContainer>
      <Spacer vertical size={1} />
      <Typography variant="caption">Indica el periodo que quieres mostrar y agrega las imágenes o videos correspondientes </Typography>
    </HeaderContainer>
    {enabled && (
      <>
        <Spacer vertical size={2} />
        <PeriodsTable
          periods={periods}
          onAdd={onPeriodsAdd}
          onChange={onPeriodsChange}
          onDelete={onPeriodsDelete}
          onDateChange={onPeriodsDateChange}
          onMediaAdd={onPeriodsMediaAdd}
          onMediaDelete={onPeriodsMediaDelete}
          onMediaChange={onPeriodsMediaChange}
          onMediaReorder={onPeriodsMediaReorder}
        />
      </>
    )}
  </FlexContainer>
)

export default WorkProgressGallery
