import useSite from '../../hooks/useSite';
import { IntelimetricaLayout } from './Layout';
import IntelimetricaImage from '../../assets/images/intelimetrica_logo.png';
import IntelimetricaImage2 from '../../assets/images/intelimetrica_logo_2.png';
import styled from 'styled-components';
import { FlexContainer, Spacer } from '../../components';
import { Button, Link, List, ListItem, Typography } from '@mui/material';
import { useState } from 'react';
import { useMemo } from 'react';
import useLocale from '../../hooks/useLocale';
import { bullets, buttonsSx } from './util';
import {
  ArrowDropUpTwoTone,
  ArrowDropDownTwoTone,
  Lock,
} from '@mui/icons-material';
import { ReportRow } from './ReportRow';

const Logo = styled.img``;

const ToggleWrapper = styled.div`
  cursor: pointer;
  user-select: none;
`;

export const ReportIntelimetrica = () => {
  const [openMoreInfo, setOpenMoreInfo] = useState(false);
  const { site } = useSite();
  const [report, setReport] = useState({});
  const [projectType, setProjectType] = useState('');
  const locale = useLocale();

  const onReportChange = (report) => {
    const projectType = report?.results
      ? report?.results?.filter(({ type }) => type === 'typology').length > 0
        ? 'typology'
        : 'unit'
      : '';
    setProjectType(projectType);
    setReport(report);
  };

  const last_report_created_at = useMemo(() => {
    const date = new Date(report.last_report_created_at);
    const day = date.toLocaleDateString(locale, { day: 'numeric' });
    const month = date.toLocaleDateString(locale, { month: 'long' });
    const year = date.toLocaleDateString(locale, { month: 'numeric' });
    return [day, month, year].join(' / ');
  }, [report.last_report_created_at, locale]);

  const toogleMoreInfo = () => setOpenMoreInfo(!openMoreInfo);

  const customResults = useMemo(() => {
    if (report?.results) {
      if (projectType === 'typology') {
        return report?.results;
      } else if (projectType === 'unit') {
        const typologies = {};
        for (let result of report?.results) {
          if (typologies[result.fields.typology]) {
            typologies[result.fields.typology].push(result);
          } else {
            typologies[result.fields.typology] = [result];
          }
        }
        return Object.values(typologies).map((values) => ({ units: values }));
      }
      return [];
    }
  }, [report?.results, projectType]);

  return (
    <IntelimetricaLayout onReportChange={onReportChange} site={site}>
      <Spacer size={4} vertical />
      <Logo src={IntelimetricaImage} />
      <Spacer size={4} vertical />
      <Typography>
        Comparamos la información de tu proyecto con millones de propiedades con
        características similares y disponibles en el mercado inmobiliario.
      </Typography>
      <Spacer size={4} vertical />
      <Typography>
        Te presentamos los valores (precios) de inmuebles que se encuentran
        alrededor de tu desarrollo.
      </Typography>
      <Spacer size={4} vertical />
      <Typography>
        <b>REPORTE GENERADO EL</b> {last_report_created_at}
      </Typography>
      <Spacer size={4} vertical />
      {customResults?.map((data) => (
        <ReportRow
          key={data.id}
          data={data}
          projectType={projectType}
          locale={locale}
        />
      ))}
      <FlexContainer justified>
        <Logo src={IntelimetricaImage2} />
        <Link
          href={'https://intelimetrica.com/tell-us-more'}
          target="_blank"
          underline="always"
          color="primary"
        >
          <Button
            startIcon={<Lock />}
            sx={buttonsSx}
            variant="contained"
            color="primary"
          >
            OBTENER REPORTE
          </Button>
        </Link>
      </FlexContainer>
      <ToggleWrapper onClick={toogleMoreInfo}>
        <FlexContainer centered>
          <Typography variant="h6">
            ¿Te gustaría obtener un reporte aún más completo?
          </Typography>
          {openMoreInfo ? <ArrowDropUpTwoTone /> : <ArrowDropDownTwoTone />}
        </FlexContainer>
      </ToggleWrapper>
      <Spacer size={2} vertical />
      <List
        sx={{
          opacity: openMoreInfo ? '100' : '0',
          listStyleType: 'circle',
          pl: 2,
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          '& .MuiListItem-root': {
            display: 'list-item',
          },
        }}
      >
        {bullets.map(({ label }, index) => (
          <ListItem key={index}>{label}</ListItem>
        ))}
      </List>
      <Spacer size={5} vertical />
    </IntelimetricaLayout>
  );
};
