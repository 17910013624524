import { CheckCircleOutline } from '@mui/icons-material';
import { Box, Chip, CircularProgress, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel/CircularProgressWithLabel';

const ProgressStatus = ({ progress, getNOMPathData, sx }) => {
  const StatusChip = useCallback(
    (props) => {
      if (progress === 100)
        return <Chip label="Óptimo" color="success" {...props} />;
      if (progress >= 80) return <Chip label="Medio" color="info" {...props} />;
      if (progress >= 60)
        return <Chip label="Bajo" color="warning" {...props} />;
      return <Chip label="Insuficiente" color="error" {...props} />;
    },
    [progress]
  );

  const updatedAt = useMemo(() => {
    const value = getNOMPathData('updatedAt');
    if (!value) return null;
    const date = new Date(value);
    if (Number.isNaN(date.getTime())) return null;
    return date;
  }, [getNOMPathData]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        bgcolor: 'background.default',
        ...sx,
      }}
    >
      {progress === 100 ? (
        <CheckCircleOutline color="success" sx={{ fontSize: 75 }} />
      ) : (
        <CircularProgressWithLabel
          color="primary"
          size={75}
          value={progress}
          labelProps={{
            variant: 'h6',
            color: 'text.secondary',
            component: 'span',
          }}
        >
          <CircularProgress
            variant="determinate"
            size={75}
            value={100}
            sx={{
              position: 'absolute',
              left: 0,
              color: 'primary.light',
              opacity: 0.1,
            }}
          />
        </CircularProgressWithLabel>
      )}
      <Box sx={{ ml: 1 }}>
        <Typography component="div" variant="h5">
          Nivel de cumplimiento:
          <StatusChip
            variant="contained"
            size="small"
            sx={{ verticalAlign: 'middle', ml: 1, px: 1 }}
          />
        </Typography>
        {updatedAt && (
          <Typography variant="caption" color="text.secondary">
            Última actualización,{' '}
            {updatedAt.toLocaleString(undefined, {
              dateStyle: 'long',
              timeStyle: 'short',
            })}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ProgressStatus;
