import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  FormControl,
  FormGroup, IconButton,
  InputAdornment,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import calendarIcon from "../../assets/svgs/calendar.svg";
import creditCardIcon from "../../assets/svgs/credit_card.svg";
import trendingUpIcon from "../../assets/svgs/trendingUp.svg";
import { FlexContainer, Spacer } from "../../components";
import { SITE_STATUS, TRIAL_DAYS } from "../../helpers/constants";
import { trackEvent } from "../../helpers/mixpanel";
import { emailRegex } from "../../helpers/regex";
import { post, put } from "../../helpers/request";
import { THEME_TYPES } from "../../helpers/themes";
import { addDaysToDate } from "../../helpers/utils";
import useOnboardingContext from "../../hooks/useOnboardingContext";
import useProfile from "../../hooks/useProfile";
import PublicLayout from "../../layouts/PublicLayout";
import { PUT_QUOTATION } from "./helpers";

const FormContent = styled(FormGroup)`
  width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    color: var(--color-text-primary);
  }
  field-content {
    padding-inline: 24px;
  }
`;
const LabelField = styled(Typography)`
  padding: 8px 0;
`;
const RegistrationContainer = styled(FlexContainer)`
  display: flex;
  flex-direction: row;
  width: 985px;
  align-items: flex-start;
  & > div:first-child {
    padding-inline-start: 36px;
  }
  .MuiButton-root {
    width: fit-content;
  }
  bottom-form {
    padding-inline-start: 36px;
  }
  style-bottom-form {
    padding-inline-start: 36px;
  }
`;
const Icon = styled.img`
  width: 48px;
`;
const StyledAlert = styled(Alert)`
  align-self: stretch;
`;
const REFERRED_OPTIONS = [
  "Google",
  "Web alohome",
  "Instagram",
  "Facebook",
  "E-mail",
  "Podcast",
  "Por teléfono",
  "Asociación",
  "Referido por alguien más",
  "Otro",
]
const onboardingRegistrationText = {
  title: "Regístrate y sigue editando tu showroom",
  subtitle: "(Te llevará menos de 1 minuto)",
  bullets: {
    demo: "<b>Disfruta gratis por 21 días la más avanzada tecnología</b> para digitalizar todo tu proceso de ventas",
    onlinePayments: "<b>Envía cotizaciones interactivas, recibe pagos online</b> y acelera tus ventas automatizando procesos",
    inventory: "<b>Analiza, evalúa y edita 24/7</b> cualquier dato de tu inventario"
  },
  form: {
    name: {
      label: "¿Cómo te llamas?*",
      placeholder: "Nombre y apellido",
    },
    email: {
      label: "¿Cuál es tu correo electrónico?*",
      placeholder: "nombre@midominio.com",
    },
    password: {
      label: "Crea una contraseña*",
      placeholder: "",
    },
    repeatPassword: {
      label: "Confirma la contraseña*",
      placeholder: "",
    },
    referred: {
      label: "¿Cómo te enteraste de alohome?*",
      placeholder: "Elige una opción",
    },
  }
}
const actionLabels = {
  primaryLabel: "Seguir editando mi showroom",
}
const INPUT_VALIDATION = {
  required: 'Este campo es obligatorio',
  emailTaken: "Este correo electrónico ya está tomado",
  emailNotValid: "El correo electrónico debe ser válido",
  passwordLength: "La contraseña debe tener mínimo 8 caracteres",
  passwordsDidNotMatch: "Las contraseñas no son iguales",
  domainTaken: "El dominio ya está tomado",
}

const mapErrorText = (value) => {
  switch (value) {
    case "\"email\" is already taken":
      return INPUT_VALIDATION.emailTaken;

    case "\"email\" must be a valid email":
      return INPUT_VALIDATION.emailNotValid;

    case "\"password\" length must be at least 8 characters long":
      return INPUT_VALIDATION.passwordLength;

    case "Domain already exist":
      return INPUT_VALIDATION.domainTaken;

    default:
      break;
  }
}

const OnboardingRegistration = () => {
  useProfile();
  const [disabled, setDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [missing, setMissing] = useState([]);
  const [registrationError, setRegistrationError] = useState(false);
  const [registrationData, setRegistrationData] = useState({
    email: { value: '' },
    name: { value: '' },
    password: { value: '' },
    repeatPassword: { value: '' },
    referred: { value: '' },
  });
  const getInitialMissing = useMemo(() => {
    if(!registrationData) return
    let missingArray = []
    Object.entries(registrationData).forEach(([key, value]) => {
      if (value.value.trim() === "") {
        if(!missingArray.includes(key)) missingArray = [...missingArray, key]
      }
    })
    return missingArray
  }, [registrationData])
  const { onboardingData } = useOnboardingContext();
  const dispatch = useDispatch();

  const updateErrors = useCallback((name, rest) => {
    setErrors({
      ...rest,
    })
    if(missing.includes(name)) {
      const filteredMissing = missing.filter(item => item !== name)
      setMissing(filteredMissing)
    }
  },[missing])

  const handleShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleInputChange = useCallback((e) => {
    setRegistrationData((d) => ({
      ...d,
      [e.target.name]: { value: e.target.value },
    }))
  }, [])

  const validatePasswords = useCallback((otherErrors) => {
    const { error, label, ...passwordOtherInfo } = registrationData.password
    const { error: repeatPasswordError, label: repeatPasswordLabel, ...repeatPasswordOtherInfo } = registrationData.repeatPassword

    if (registrationData.password.value !== registrationData.repeatPassword.value) {
      setErrors({
        ...otherErrors,
        repeatPassword: INPUT_VALIDATION.passwordsDidNotMatch,
      })
      setRegistrationData({
        ...registrationData,
        password: {
          ...passwordOtherInfo,
          error: true
        },
        repeatPassword: {
          ...repeatPasswordOtherInfo,
          error: true,
          label: INPUT_VALIDATION.passwordsDidNotMatch
        }
      })
      return { passwordsAreValid: false }
    }

    setRegistrationData({
      ...registrationData,
      password: { ...passwordOtherInfo },
      repeatPassword: { ...repeatPasswordOtherInfo },
    })
    return { passwordsAreValid: true }
  }, [registrationData])

  const addToMissing = useCallback((name) => {
    if(!missing.includes(name)) {
      setMissing([
        ...missing,
        name
      ]);
      return
    }
  },[missing, setMissing]);

  const Validate = useCallback((name, value, required, minLength, rest) =>
    {
      let isValid = true
      if (value.trim() === "" && required) {
        setRegistrationData((d) => ({
          ...d,
          [name]: {
            ...d[name],
            error: true,
            label: INPUT_VALIDATION.required,
          },
        }))
        addToMissing(name)
        setErrors({
          ...rest,
          [name]: INPUT_VALIDATION.required,
        })
        isValid = false
      }
      if((name) === "email" && !emailRegex.test(value)) {
        setRegistrationData((d) => ({
          ...d,
          [name]: {
            ...d[name],
            error: true,
            label: INPUT_VALIDATION.emailNotValid,
          },
        }))
        setErrors({
          ...rest,
          [name]: INPUT_VALIDATION.emailNotValid,
        })
        isValid = false
      }
      if((name) === "password" || (name) === "repeatPassword") {
        if (minLength && value.trim() !== '' && value.trim().length < minLength) {
          setErrors({
            ...rest,
            [name]: INPUT_VALIDATION.passwordLength,
          })
          isValid = false
          return setRegistrationData((d) => ({
            ...d,
            [name]: {
              ...d[name],
              error: true,
              label: INPUT_VALIDATION.passwordLength,
            },
          }))
        }
        if(registrationData.password.value && registrationData.repeatPassword.value) {
          const { passwordsAreValid } = validatePasswords(rest)
          isValid = passwordsAreValid
        }
      }
      return isValid
    }, [addToMissing, registrationData.password.value, registrationData.repeatPassword.value, validatePasswords]
  )

  const handleBlur = useCallback((e, required) => {
    const {[e.target.name]: removedError, ...rest} = errors;
    const isValidResult = Validate(e.target.name, e.target.value, e.target.required || required, e.target.minLength, rest)
    if(isValidResult) {
      updateErrors(e.target.name, rest)
    }
  }, [Validate, errors, updateErrors])

  const handleSendData = useCallback(() => {
    const themeColors = THEME_TYPES.find((item) => item.value === onboardingData.theme)

    if (!disabled) {
      setDisabled(true)
      post('/internal/login/v1/onboarding/register',
        {
          email: registrationData.email.value,
          password: registrationData.password.value,
          repeat_password: registrationData.repeatPassword.value,
          name: registrationData.name.value,
          project_name: onboardingData.projectName,
          project_domain: onboardingData.subdomain + ".alohome.io",
          assets: onboardingData.assets,
          temporal_site_id: onboardingData.tempSiteId,
          property_type: onboardingData.propertyType,
        }).then( async (result) => {
          const siteId = result.user.sites[0].site_id
          const today = new Date()
          await put('/internal/settings/v1/settings/showroom/config', {
            project_type: onboardingData.propertyType,
            theme: onboardingData.theme,
            theme_version: "1",
            draft_theme: onboardingData.theme,
            draft_theme_version: "1",
            units_qty: onboardingData.unitsQty,
            location: onboardingData.location,
            site_status: SITE_STATUS.trial,
            trial_end_date: addDaysToDate(today, TRIAL_DAYS),
            colors: themeColors.colors[0],
          }, siteId)
          await put(PUT_QUOTATION, {
            activePayments: false,
            has_online_payments: false,
            quotation_type: "flexible",
          }, siteId)
          dispatch({ type: 'reload-profile', data: true })
        })
        .then(() => {
          trackEvent('project-created', { project_name: registrationData.name.value })
        })
        .catch(error => {
          if (error.data) {
            setRegistrationError(mapErrorText(error.data))
            setDisabled(false)
          }
        })
    }
  }, [disabled, dispatch, onboardingData.assets, onboardingData.location, onboardingData.projectName, onboardingData.propertyType, onboardingData.subdomain, onboardingData.tempSiteId, onboardingData.theme, onboardingData.unitsQty, registrationData.email.value, registrationData.name.value, registrationData.password.value, registrationData.repeatPassword.value]);

  const { bullets, form, subtitle, title } = onboardingRegistrationText

  useEffect(() => {
    setMissing(getInitialMissing)
  }, [getInitialMissing])

  useEffect(() => {
    if(missing.length === 0)
    Object.keys(errors).forEach((key) => {
      const {[key]: removedError, ...rest} = errors;
      updateErrors(key, rest)
    })
  }, [errors, missing.length, updateErrors])

  useEffect(() => {
    setDisabled(Object.keys(errors).length > 0 || missing.length > 0)
  }, [errors, missing])
  
  return (
    <PublicLayout
      primaryLabel={actionLabels.primaryLabel}
      onPrimaryClick={handleSendData}
      disableNextStep={disabled}
    >
      <FlexContainer centered fullWidth vertical>
        <Spacer vertical size={1} />
        <Typography variant="h2">
          {title}
        </Typography>
        <Typography variant="h5">{subtitle}</Typography>
        <Spacer vertical size={2} />
        <RegistrationContainer centered fullWidth vertical>
          <FlexContainer vertical justified>
            <Icon src={calendarIcon} alt="calendar" />
            <Typography dangerouslySetInnerHTML={{ __html: bullets.demo }} />
            <Spacer vertical size={2} />
            <Icon src={creditCardIcon} alt="credit card" />
            <Typography dangerouslySetInnerHTML={{ __html: bullets.onlinePayments }} />
            <Spacer vertical size={2} />
            <Icon src={trendingUpIcon} alt="trending up" />
            <Typography dangerouslySetInnerHTML={{ __html: bullets.inventory }} />
          </FlexContainer>
          <FlexContainer vertical justified>
            <FormContent>
              <field-content>
                <LabelField>{form.name.label}</LabelField>
                <TextField
                  id="name-user"
                  variant="outlined"
                  placeholder={form.name.placeholder}
                  fullWidth
                  name="name"
                  autoComplete="on"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  error={registrationData.name.error}
                  label={registrationData.name.error ? INPUT_VALIDATION.required : undefined}
                  required
                />
                <LabelField>{form.email.label}</LabelField>
                <TextField
                  id="email-user"
                  variant="outlined"
                  placeholder={form.email.placeholder}
                  fullWidth
                  name="email"
                  autoComplete="on"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  error={registrationData.email.error}
                  label={registrationData.email.error ? registrationData.email.label : undefined}
                  required
                />
                <Spacer horizontal size={2} />
                <LabelField>{form.password.label}</LabelField>
                <OutlinedInput
                  id="password-user"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  inputProps={{minLength: 8}}
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  error={registrationData.password.error}
                  required
                />
                {registrationData.password.error && registrationData.password.label && (
                  <FlexContainer fullWidth centered vertical>
                    <StyledAlert severity="error">{registrationData.password.label}</StyledAlert>
                  </FlexContainer>
                )}
                <LabelField>{form.repeatPassword.label}</LabelField>
                <OutlinedInput
                  id="confirm-password-user"
                  type={showPassword ? "text" : "password"}
                  name="repeatPassword"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  inputProps={{minLength: 8}}
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  error={registrationData.repeatPassword.error}
                  required
                />
                {registrationData.repeatPassword.error && (
                  <FlexContainer fullWidth centered vertical>
                    <StyledAlert severity="error">{registrationData.repeatPassword.label}</StyledAlert>
                  </FlexContainer>
                )}
                <FormControl sx={{ minWidth: 500 }} error={registrationData.referred.error} required >
                  <LabelField>{form.referred.label}</LabelField>
                  <Select
                    value={registrationData.referred.value}
                    name="referred"
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e, {required: true})}
                    displayEmpty
                  >
                    <MenuItem value="">{form.referred.placeholder}</MenuItem>
                    {REFERRED_OPTIONS.map((item) => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                  {registrationData.referred.error && (
                    <FlexContainer fullWidth centered vertical>
                      <StyledAlert severity="error">{registrationData.referred.label}</StyledAlert>
                    </FlexContainer>
                  )}
                </FormControl>
                <style-bottom-form>
                  {registrationError && (<>
                    <FlexContainer fullWidth centered vertical>
                      <StyledAlert severity="error">{registrationError}</StyledAlert>
                    </FlexContainer>
                  </>)}
                  <Spacer vertical size={2} />
                  <Typography>
                    Al dar clic en "Seguir editando mi showroom" aceptas los{" "}
                    <Link href={`${process.env.REACT_APP_ALOHOME_URL}/terminos-y-condiciones`} target="_blank">
                      Términos y Condiciones
                    </Link>{" "}
                    y la{" "}
                    <Link href={`${process.env.REACT_APP_ALOHOME_URL}/politica-de-privacidad`} target="_blank">Política de Privacidad</Link>{" "}
                    de <b>alohome.</b> Es posible que recibas notificaciones
                    por email sobre las novedades del servicio.
                  </Typography>
                  <Spacer vertical size={2} />
                </style-bottom-form>
              </field-content>
            </FormContent>
          </FlexContainer>
        </RegistrationContainer>
      </FlexContainer>
    </PublicLayout>
  );
};

export default OnboardingRegistration;
