import { Button, Typography } from "@mui/material";
import { FlexContainer, Spacer } from "../../components";
import React from "react";
import styled from "styled-components";
import Lottie from "react-lottie";
import desktopHubAnimation from "../../assets/animations/desktopHub.json";
import { Link } from "react-router-dom";

const ConteinerMobile = styled(FlexContainer)`
  padding: 0 20px;
`;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: desktopHubAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const LoginMobile = () => {
  return (
    <ConteinerMobile vertical>
      <Typography variant="h4">
        Por el momento, solo es posible realizar cotizaciones desde tu celular
      </Typography>
      <Spacer vertical size={3} />
      <Typography>
        <b>¡Hola, qué gusto verte por aquí!</b>
      </Typography>
      <Spacer vertical size={2} />
      <Typography>
        Si quieres realizar una cotización, da clic aquí:
      </Typography>
      <Spacer vertical size={2} />
      <Button
        component={Link}
        to={"/choose-project"}
        color="primary"
        variant='contained'
        size="small"
        sx={{maxWidth: 'fit-content', padding: '.5rem 1rem', alignSelf: "center"}}
      >
        Realizar cotización
      </Button>
      <Spacer vertical size={4} />
      <Typography>
        Si deseas usar otra de nuestras herramientas, para ofrecerte la mejor experiencia <b>inicia sesión desde una laptop o computadora de escritorio:</b> nuestras herramientas están optimizadas para esos dispositivos.
      </Typography>
      <Lottie options={defaultOptions} height={184} width={265} />
      <Typography>
        Pronto tendremos más detalles de las funciones que podrás tener desde tu celular.
      </Typography>
    </ConteinerMobile>
  );
};

export default LoginMobile;
