import {
  Button,
  Checkbox,
  Dialog,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import { FlexContainer, Spacer } from '../../components';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { buttonsSx } from './util';

const CustomContainer = styled(FlexContainer)`
  padding: 20px;
`;

const NumberInput = ({ ...props }) => {
  return <TextField type="number" {...props} />;
};

const TextInput = ({ ...props }) => {
  return <TextField {...props} />;
};

const inputTypes = {
  number: NumberInput,
  text: TextInput,
};

export const BulkEditor = ({ isOpen, onClose, columns = [], onSave }) => {
  const [form, setForm] = useState({});
  const [checked, setChecked] = useState({});

  const handleChange = ({ target }) => {
    setForm((form) => ({ ...form, [target.name]: target.value }));
  };

  const handleCheck = ({ target }) => {
    setChecked((form) => ({ ...form, [target.name]: target.checked }));
  };

  useEffect(() => {
    if (!isOpen) {
      setForm({});
      setChecked({});
    }
  }, [isOpen]);

  const requiredColumns = useMemo(
    () =>
      columns.filter(
        (column) => column.required && inputTypes[column.bulkType]
      ),
    [columns]
  );

  const optionalColumns = useMemo(
    () =>
      columns.filter(
        (column) => !column.required && inputTypes[column.bulkType]
      ),
    [columns]
  );

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={onClose}>
      <CustomContainer verticalCentered justified vertical>
        <FlexContainer justified>
          <FlexContainer vertical>
            <Typography variant="h6">EDITAR SELECCIONADOS</Typography>
            {requiredColumns.length > 0 && (
              <Typography variant="caption">*Campos obligatorios</Typography>
            )}
          </FlexContainer>
          <CloseIcon onClick={onClose} />
        </FlexContainer>
        <Grid container spacing={2}>
          {requiredColumns.map((column) => {
            const Input = inputTypes[column.bulkType];
            return !Input ? null : (
              <>
                <Grid item xs={6}>
                  <FlexContainer ended>
                    <FlexContainer alignSelfEnd>
                      <Checkbox
                        onChange={handleCheck}
                        name={column.bulk_name}
                      />
                    </FlexContainer>
                    <Input
                      fullWidth
                      label={column.text}
                      name={column.bulk_name}
                      onChange={handleChange}
                      variant="standard"
                    />
                  </FlexContainer>
                </Grid>
              </>
            );
          })}
        </Grid>
        <Spacer size={4} vertical />
        {optionalColumns.length > 0 && (
          <Typography variant="caption">Campos opcionales</Typography>
        )}
        <Grid container spacing={2}>
          {optionalColumns.map((column) => {
            const Input = inputTypes[column.bulkType];
            return !Input ? null : (
              <>
                <Grid item xs={6}>
                  <FlexContainer ended>
                    <FlexContainer alignSelfEnd>
                      <Checkbox
                        onChange={handleCheck}
                        name={column.bulk_name}
                      />
                    </FlexContainer>
                    <Input
                      fullWidth
                      label={column.text}
                      name={column.bulk_name}
                      onChange={handleChange}
                      variant="standard"
                    />
                  </FlexContainer>
                </Grid>
              </>
            );
          })}
        </Grid>
        <Spacer size={4} vertical />
        <FlexContainer ended>
          <Button
            onClick={onClose}
            sx={buttonsSx}
            variant="outlined"
            color="primary"
          >
            CANCELAR
          </Button>
          <Spacer size={2} />
          <Button
            sx={buttonsSx}
            variant="contained"
            color="primary"
            onClick={(e) => onSave(e, form, checked)}
          >
            GUARDAR
          </Button>
        </FlexContainer>
      </CustomContainer>
    </Dialog>
  );
};
