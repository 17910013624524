import React, { useCallback, useRef } from "react";
import { FlexContainer, MaxCharsInput } from "../../../components";

const Paragraph = ({ pathData, setPathData, maxChars }) => {
  const initialValue = useRef(pathData);

  const handleBlur = useCallback(
    (newValue) => {
      if (newValue.trim().length === 0) {
        setPathData(initialValue.current);
      }
    },
    [initialValue, setPathData]
  );

  return (
    <FlexContainer>
      <MaxCharsInput
        label="Párrafo"
        multiline
        value={pathData ?? ""}
        onBlur={handleBlur}
        onChange={setPathData}
        maxChars={maxChars}
      />
    </FlexContainer>
  );
};

export default Paragraph;
