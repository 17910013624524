import { CheckCircle } from '@mui/icons-material';
import React, { useMemo } from 'react';
import { ADVERTISING_URL_SLUG } from './Advertising';
import { CUSTOMER_SUPPORT_URL_SLUG } from './CustomerSupport/CustomerSupport';
import { LEGAL_URL_SLUG } from './Legal';
import { PM_AND_COSTS_URL_SLUG } from './PaymentMethodsAndCosts';
import { PLANS_URL_SLUG } from './Plans';
import { PROJECT_URL_SLUG } from './Project';

const useTabs = ({ sectionsProgress }) =>
  useMemo(
    () => ({
      urlLevel: 2,
      tabs: [
        {
          label: 'Legal',
          href: LEGAL_URL_SLUG,
          icon: (
            <CheckCircle
              color={
                sectionsProgress?.[LEGAL_URL_SLUG]?.progress === 100
                  ? 'success'
                  : 'disabled'
              }
            />
          ),
        },
        {
          label: 'Métodos de pago y costos',
          href: PM_AND_COSTS_URL_SLUG,
          icon: (
            <CheckCircle
              color={
                sectionsProgress?.[PM_AND_COSTS_URL_SLUG]?.progress === 100
                  ? 'success'
                  : 'disabled'
              }
            />
          ),
        },
        {
          label: 'Atención',
          href: CUSTOMER_SUPPORT_URL_SLUG,
          icon: (
            <CheckCircle
              color={
                sectionsProgress?.[CUSTOMER_SUPPORT_URL_SLUG]?.progress === 100
                  ? 'success'
                  : 'disabled'
              }
            />
          ),
        },
        {
          label: 'Información del proyecto',
          href: PROJECT_URL_SLUG,
          icon: (
            <CheckCircle
              color={
                sectionsProgress?.[PROJECT_URL_SLUG]?.progress === 100
                  ? 'success'
                  : 'disabled'
              }
            />
          ),
        },
        {
          label: 'Proyecto arquitectónico',
          href: PLANS_URL_SLUG,
          icon: (
            <CheckCircle
              color={
                sectionsProgress?.[PLANS_URL_SLUG]?.progress === 100
                  ? 'success'
                  : 'disabled'
              }
            />
          ),
        },
        {
          label: 'Publicidad',
          href: ADVERTISING_URL_SLUG,
          icon: (
            <CheckCircle
              color={
                sectionsProgress?.[ADVERTISING_URL_SLUG]?.progress === 100
                  ? 'success'
                  : 'disabled'
              }
            />
          ),
        },
      ],
    }),
    [sectionsProgress]
  );

export default useTabs;
