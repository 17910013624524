import { Typography } from "@mui/material"
import React from "react"
import { FlexContainer, Spacer } from "../../components"

const SectionDescription = () => (
  <FlexContainer vertical>
    <Typography>Genera aún más confianza en tus clientes mostrando que tu proyecto va por buen camino.</Typography>
    <Spacer vertical size={2} />
    <Typography>Aquí podrás editar el progreso en cada una de las fases de la construcción, incluyendo porcentajes, periodos e imágenes.</Typography>
    <Spacer vertical size={2} />
    <Typography sx={{ fontWeight: 500 }}>Activa la sección y muéstrala en tu showroom digital.</Typography>
  </FlexContainer>
)

export default SectionDescription
