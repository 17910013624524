import dayjs from "dayjs";

export const filterConditions = [
  {
    label: 'Contiene',
    value: 'contains',
  },
  {
    label: 'Igual a',
    value: 'equals_to',
  },
  {
    label: 'Comienza con',
    value: 'starts_with',
  },
  {
    label: 'Termina con',
    value: 'ends_with',
  },
  {
    label: 'Está vacío',
    value: 'empty',
  },
  {
    label: 'No está vacío',
    value: 'not_empty',
  },
  {
    label: 'Cualquiera de',
    value: 'any_of',
  },
];

export const units_header_row = {
  rowId: 'header',
  cells: [
    { type: 'checkbox', checked: false },
    { type: 'header', text: '*Unidad' },
    {
      type: 'header',
      text: '*Precio',
      bulkType: 'number',
      bulk_name: 'price',
      required: true,
    },
    {
      type: 'header',
      text: '*Área',
      bulkType: 'number',
      bulk_name: 'construction_size',
      required: true,
    },
    {
      type: 'header',
      text: '*Recamaras',
      bulkType: 'number',
      bulk_name: 'bedrooms',
      required: true,
    },
    {
      type: 'header',
      text: '*Baños',
      bulkType: 'number',
      bulk_name: 'bathrooms',
      required: true,
    },
    {
      type: 'header',
      text: 'Medios baños',
      bulkType: 'number',
      bulk_name: 'half_bathrooms',
    },
    {
      type: 'header',
      text: '*Estacionamientos',
      bulkType: 'number',
      bulk_name: 'parking_lots',
      required: true,
    },
    {
      type: 'header',
      text: 'Área de balcón (m2)',
      bulkType: 'number',
      bulk_name: 'balcon_m2',
    },
    {
      type: 'header',
      text: 'Área de azotea (m2)',
      bulkType: 'number',
      bulk_name: 'roof_top_m2',
    },
    {
      type: 'header',
      text: 'Piso de la unidad',
      bulkType: 'number',
      bulk_name: 'floor',
    },
    {
      type: 'header',
      text: 'Número de la unidad',
      bulk_name: 'department_number',
    },
    {
      type: 'header',
      text: 'Monto para apartado',
      bulkType: 'number',
      bulk_name: 'initial_payment',
    },
  ],
};

export const units_columns = [
  { columnId: 'checkbox', width: 150, resizable: true },
  { columnId: 'unit_id', width: 150, resizable: true },
  { columnId: 'price', width: 150, resizable: true },
  { columnId: 'construction_size', width: 150, resizable: true },
  { columnId: 'bedrooms', width: 150, resizable: true },
  { columnId: 'bathrooms', width: 150, resizable: true },
  { columnId: 'half_bathrooms', width: 150, resizable: true },
  { columnId: 'parking_lots', width: 150, resizable: true },
  { columnId: 'balcon_m2', width: 150, resizable: true },
  { columnId: 'roof_top_m2', width: 150, resizable: true },
  { columnId: 'floor', width: 150, resizable: true },
  { columnId: 'department_number', width: 150, resizable: true },
  { columnId: 'initial_payment', width: 150, resizable: true },
];

export const weekDays = [
  {
    code: 'monday',
    label: 'Lunes',
  },
  {
    code: 'tuesday',
    label: 'Martes',
  },
  {
    code: 'wednesday',
    label: 'Miércoles',
  },
  {
    code: 'thursday',
    label: 'Jueves',
  },
  {
    code: 'friday',
    label: 'Viernes',
  },
  {
    code: 'saturday',
    label: 'Sábado',
  },
  {
    code: 'sunday',
    label: 'Domingo',
  },
];

export const initialForm = {
  entities: [''],
  title: '',
  description: '',
  development_name: '',
  project_type: '',
  construction_year: '',
  floors: '',
  street: '',
  exterior_num: '',
  area: '',
  zip_code: '',
  state: '',
  city: '',
  municipality: '',
  external_mortgage_loans: 'no',
  tours_3d_url: '',
  project_brochure_url: '',
  address: '',
  email: '',
  phone: '',
  whatsapp: '',
  tours_guided_by_developer_staff: 'no',
  publish_on_external_portals: 'no',
  tourDays: {
    monday: {
      active: true,
      from: dayjs().hour(9).minute(0),
      to: dayjs().hour(21).minute(0),
    },
    tuesday: {
      active: true,
      from: dayjs().hour(9).minute(0),
      to: dayjs().hour(21).minute(0),
    },
    wednesday: {
      active: true,
      from: dayjs().hour(9).minute(0),
      to: dayjs().hour(21).minute(0),
    },
    thursday: {
      active: true,
      from: dayjs().hour(9).minute(0),
      to: dayjs().hour(21).minute(0),
    },
    friday: {
      active: true,
      from: dayjs().hour(9).minute(0),
      to: dayjs().hour(21).minute(0),
    },
    saturday: {
      active: false,
      from: dayjs().hour(9).minute(0),
      to: dayjs().hour(21).minute(0),
    },
    sunday: {
      active: false,
      from: dayjs().hour(9).minute(0),
      to: dayjs().hour(21).minute(0),
    },
  },
};