import { Typography } from "@mui/material"
import React from "react"
import styled from "styled-components"
import { FlexContainer, Spacer } from "../../components"
import PercentEditor from "./PercentEditor"
import StagesTable from "./StagesTable"
import WorkProgressGallery from "./WorkProgressGallery"

const ConfigContainer = styled(FlexContainer)`
  gap: 84px;
  width: 100%;
`

const Column = styled(FlexContainer)`
  width: 50%;
  flex-direction: column;
`

const WorkProgressSettings = ({
  stages,
  onStagesAdd,
  onStagesDelete,
  onStageChange,
  onStageReorder,
  percent,
  onPercentChange,
  onPercentBlur,
  onPercentKeyDown,
  onPercentSliderChange,
  enabledGallery,
  onEnabledGalleryChange,
  periods,
  onPeriodsAdd,
  onPeriodsChange,
  onPeriodsDelete,
  onPeriodsDateChange,
  onPeriodsMediaAdd,
  onPeriodsMediaDelete,
  onPeriodsMediaChange,
  onPeriodsMediaReorder,
}) => (
  <FlexContainer vertical fullWidth>
    <Typography variant="subtitle1">Etapas de construcción</Typography>
    <Spacer vertical size={1} />
    <ConfigContainer>
      <Column>
        <Typography variant="caption">Marca las etapas iniciadas o completadas. Puedes editar el orden, los nombres y el número de fases para ajustarlas al 100% a tu proyecto.</Typography>
        <Spacer vertical size={2} />
        <StagesTable
          stages={stages}
          onStagesAdd={onStagesAdd}
          onStagesDelete={onStagesDelete}
          onStageChange={onStageChange}
          onStageReorder={onStageReorder}
        />
      </Column>
      <Column>
        <Typography variant="caption">¿Cuál es el porcentaje actual de avance de obra de tu proyecto?</Typography>
        <Spacer vertical size={2} />
        <PercentEditor
          value={percent}
          onChange={onPercentChange}
          onBlur={onPercentBlur}
          onKeyDown={onPercentKeyDown}
          onSliderChange={onPercentSliderChange}
        />
      </Column>
    </ConfigContainer>
    <Spacer vertical size={4} />
    <WorkProgressGallery
      enabled={enabledGallery}
      onEnabledChange={onEnabledGalleryChange}
      periods={periods}
      onPeriodsAdd={onPeriodsAdd}
      onPeriodsChange={onPeriodsChange}
      onPeriodsDelete={onPeriodsDelete}
      onPeriodsDateChange={onPeriodsDateChange}
      onPeriodsMediaAdd={onPeriodsMediaAdd}
      onPeriodsMediaDelete={onPeriodsMediaDelete}
      onPeriodsMediaChange={onPeriodsMediaChange}
      onPeriodsMediaReorder={onPeriodsMediaReorder}
    />
  </FlexContainer>
)

export default WorkProgressSettings
