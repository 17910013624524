import { alpha, Button, styled, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import HouseIcon from '../../assets/svgs/house.svg';
import Image from '../../components/Image/Image';
import { getKeysAsPath } from '../../helpers/data';
import AttachedFiles from './AttachedFiles';
import CheckItem, { MUST_BE_SHOW_ON } from './CheckItem';
import Section from './Section';

export const PLANS_URL_SLUG = 'plans';

export const PLANS_NOM_PATH = 'plans';

export const PLANS_CHECKLIST_IDS = [
  'Art_5_3-Sec_i-FinishesAndDimensions',
  'Art_5_3',
  'Art_5_3-Sec_a',
  'Art_5_3-Sec_b',
  'Art_5_3-Sec_c',
  'Art_5_3-Sec_d',
  'Art_5_3-Sec_e',
  'Art_5_3-Sec_f',
  'Art_5_3-Sec_g',
  'Art_5_3-Sec_h',
  'Art_5_6_10',
];

const BannerContainer = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(4),
  borderRadius: 4,
  borderStyle: 'dashed',
  borderColor: alpha(theme.palette.primary.light, 0.6),
  borderWidth: 1,
  backgroundColor: alpha(theme.palette.primary.light, 0.1),
}));

const Plans = ({ getSectionData, setSectionData }) => {
  const getValue = useCallback(
    (id) => getSectionData(getKeysAsPath(['checkList', id])),
    [getSectionData]
  );

  const getHandleValueChange = useCallback(
    (id) => (path, value) =>
      setSectionData(getKeysAsPath(['checkList', id, path]), value),
    [setSectionData]
  );

  return (
    <Section title="Proyecto arquitectónico" id="plans">
      <CheckItem
        value={getValue('Art_5_3-Sec_i-FinishesAndDimensions')}
        setValuePath={getHandleValueChange(
          'Art_5_3-Sec_i-FinishesAndDimensions'
        )}
        title="Señalar los acabados y dimensiones con los que se entrega la vivienda. En su caso, dimensiones de muebles y equipos con los que cuente"
        NOMSection="Art. 5.3 Sec. i"
        mustBeShowOn={MUST_BE_SHOW_ON.DIGITALLY}
      >
        <AttachedFiles
          files={getSectionData('finishesAndDimensions.files')}
          setFiles={(v) => setSectionData('finishesAndDimensions.files', v)}
        />
      </CheckItem>
      <CheckItem
        value={getValue('Art_5_3')}
        setValuePath={getHandleValueChange('Art_5_3')}
        title="En caso de preventa, el proveedor debe exhibir la maqueta del proyecto, física o virtual, desde la promoción del inmueble y hasta que entregue el mismo al consumidor, de conformidad con el artículo 34 del RLFPC"
        NOMSection="Art. 5.3"
        mustBeShowOn={MUST_BE_SHOW_ON.PHYSICALLY_OR_DIGITALLY}
      >
        <BannerContainer>
          <Image src={HouseIcon} />
          <Typography
            variant="h6"
            sx={{ whiteSpace: 'break-spaces', mr: 'auto' }}
          >
            ¿Te gustaría una maqueta interactiva de tu proyecto?
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'right' }}>
            Nostros lo hacemos por ti
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ textAlign: 'center' }}
            component="a"
            target="_blank"
            href={process.env.REACT_APP_CALENDLY_URL}
          >
            Agenda una demo
          </Button>
        </BannerContainer>
      </CheckItem>
      <CheckItem
        value={getValue('Art_5_3-Sec_a')}
        setValuePath={getHandleValueChange('Art_5_3-Sec_a')}
        title="Plano del terreno (terracerías o topografías)"
        NOMSection="Art. 5.3, Sec. a"
        mustBeShowOn={MUST_BE_SHOW_ON.PHYSICALLY_OR_DIGITALLY}
        digitallyChildren={
          <AttachedFiles
            files={getSectionData('terrain.files')}
            setFiles={(v) => setSectionData('terrain.files', v)}
          />
        }
      />
      <CheckItem
        value={getValue('Art_5_3-Sec_b')}
        setValuePath={getHandleValueChange('Art_5_3-Sec_b')}
        title="Planos de ubicación y localización"
        NOMSection="Art. 5.3, Sec. b"
        mustBeShowOn={MUST_BE_SHOW_ON.PHYSICALLY_OR_DIGITALLY}
        digitallyChildren={
          <AttachedFiles
            files={getSectionData('location.files')}
            setFiles={(v) => setSectionData('location.files', v)}
          />
        }
      />
      <CheckItem
        value={getValue('Art_5_3-Sec_c')}
        setValuePath={getHandleValueChange('Art_5_3-Sec_c')}
        title="Planos de cortes, fachadas y alzados"
        NOMSection="Art. 5.3, Sec. c"
        mustBeShowOn={MUST_BE_SHOW_ON.PHYSICALLY_OR_DIGITALLY}
        digitallyChildren={
          <AttachedFiles
            files={getSectionData('sectionsAndFacades.files')}
            setFiles={(v) => setSectionData('sectionsAndFacades.files', v)}
          />
        }
      />
      <CheckItem
        value={getValue('Art_5_3-Sec_d')}
        setValuePath={getHandleValueChange('Art_5_3-Sec_d')}
        title="Planos de detalles arquitectónicos"
        NOMSection="Art. 5.3, Sec. d"
        mustBeShowOn={MUST_BE_SHOW_ON.PHYSICALLY_OR_DIGITALLY}
        digitallyChildren={
          <AttachedFiles
            files={getSectionData('details.files')}
            setFiles={(v) => setSectionData('details.files', v)}
          />
        }
      />
      <CheckItem
        value={getValue('Art_5_3-Sec_e')}
        setValuePath={getHandleValueChange('Art_5_3-Sec_e')}
        title="Planos estructurales (cimentación, columnas, trabes y losas)"
        NOMSection="Art. 5.3, Sec. e"
        mustBeShowOn={MUST_BE_SHOW_ON.PHYSICALLY_OR_DIGITALLY}
        digitallyChildren={
          <AttachedFiles
            files={getSectionData('structural.files')}
            setFiles={(v) => setSectionData('structural.files', v)}
          />
        }
      />
      <CheckItem
        value={getValue('Art_5_3-Sec_f')}
        setValuePath={getHandleValueChange('Art_5_3-Sec_f')}
        title="Planos instalaciones (hidrosanitaria, eléctricas, contra incendio, mecánicas, especiales, voz y datos, etc.)"
        NOMSection="Art. 5.3, Sec. f"
        mustBeShowOn={MUST_BE_SHOW_ON.PHYSICALLY_OR_DIGITALLY}
        digitallyChildren={
          <AttachedFiles
            files={getSectionData('installations.files')}
            setFiles={(v) => setSectionData('installations.files', v)}
          />
        }
      />
      <CheckItem
        value={getValue('Art_5_3-Sec_g')}
        setValuePath={getHandleValueChange('Art_5_3-Sec_g')}
        title="Planos de acabados (pisos, muros, techos, entre otros)"
        NOMSection="Art. 5.3, Sec. g"
        mustBeShowOn={MUST_BE_SHOW_ON.PHYSICALLY_OR_DIGITALLY}
        digitallyChildren={
          <AttachedFiles
            files={getSectionData('finishes.files')}
            setFiles={(v) => setSectionData('finishes.files', v)}
          />
        }
      />
      <CheckItem
        value={getValue('Art_5_3-Sec_h')}
        setValuePath={getHandleValueChange('Art_5_3-Sec_h')}
        title="Planos de urbanización (planta de conjunto: zonas exteriores: aceras, jardínes, instalaciones)"
        NOMSection="Art. 5.3, Sec. h"
        mustBeShowOn={MUST_BE_SHOW_ON.PHYSICALLY_OR_DIGITALLY}
        digitallyChildren={
          <AttachedFiles
            files={getSectionData('urbanization.files')}
            setFiles={(v) => setSectionData('urbanization.files', v)}
          />
        }
      />
      <CheckItem
        value={getValue('Art_5_6_10')}
        setValuePath={getHandleValueChange('Art_5_6_10')}
        title="El proveedor debe informar al consumidor acerca del equipamiento urbano existente en la localidad donde éste se encuentre y los sistemas de transporte necesarios."
        NOMSection="Art. 5.6.10"
      />
    </Section>
  );
};

export default Plans;
