import React from 'react'
import PropTypes from 'prop-types'
import { Alert, AlertTitle } from '@mui/material';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 530px;
`

const AlertMessage = ({ severity, title, message, onClose }) => (
  <Container>
    <Alert severity="warning" onClose={onClose}>
      <AlertTitle>Tienes cambios sin guardar</AlertTitle>
      Has clic en el botón de "Guardar" para guardar tus cambios
    </Alert>
  </Container>
);

AlertMessage.propTypes = {
  severity: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  onClose: PropTypes.func,
}

AlertMessage.defaultProps = {
  severity: 'success',
}

export default AlertMessage
