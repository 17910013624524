import React from 'react'
import styled from 'styled-components'
import MaterialTooltip from '@mui/material/Tooltip'

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.87);
`

const Tooltip = ({ text, placement }) => (
  <MaterialTooltip title={text} arrow placement={placement}>
    <Circle>i</Circle>
  </MaterialTooltip>
)

export default Tooltip
