import { WidgetContainer, WidgetTitle, FlexContainer, Spacer } from "../../components"
import { Select, MenuItem } from "@mui/material"
import { BarChart, XAxis, YAxis, Tooltip, Legend, Bar, ResponsiveContainer } from "recharts"
import { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import { numberToCurrency } from "../../helpers/utils"
import { get } from "../../helpers/request"
import useCurrencies from '../../hooks/useCurrencies'
import useProfile from "../../hooks/useProfile" // TODO: Eliminar este hack de 3 Rocas
import { useSelector } from "react-redux"

const TITLE = "Ventas por Categorías"
const TOOLTIP = "Observe el progreso de sus ventas segmentado por categorías como: Torre, Fase o Tipología"

const SalesProgressChart = ({ data, type, category }) => {
  const currencies = useCurrencies()
  const isIncomeType = type === "income"
  const isUnitsType = type === "units"

  return (
    <>
      {currencies && (<ResponsiveContainer
        width="100%"
        height={350}>
        <BarChart
          layout="vertical"
          data={data[type][category]}
        >
          <XAxis type="number" tickFormatter={isIncomeType && (tick => `${numberToCurrency(tick, currencies.main.symbol)}`)} />
          <YAxis dataKey="name" type="category" />
          <Tooltip formatter={isIncomeType && (value => `${numberToCurrency(value, currencies.main.symbol)}`)} />
          <Legend />
          <Bar dataKey="sold" name="Vendido" stackId="a" fill="#2196f3" unit={isUnitsType && " unidades"} />
          <Bar dataKey="reserved" name="Reservado" stackId="a" fill="#1769aa" unit={isUnitsType && " unidades"} />
          <Bar dataKey="available" name="Disponible" stackId="a" fill="#757575" unit={isUnitsType && " unidades"} />
          <Bar dataKey="unset" name="No Publicado" stackId="a" fill="#474747" unit={isUnitsType && " unidades"} />
        </BarChart>
      </ResponsiveContainer>)
      }
    </>
  )
}

SalesProgressChart.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.oneOf(["units", "income"]).isRequired,
  category: PropTypes.oneOf(["phases", "towers", "typologies"]).isRequired
}


const SalesProgress = () => {
  const profile = useProfile()
  const currencies = useCurrencies()
  const selectedSite = useSelector(state => state.site)
  const [state, setState] = useState({
    type: "units",
    category: "typologies"
  })
  const [data, setData] = useState()

  const handleChange = (event) => setState({
    ...state,
    [event.target.name]: event.target.value
  })

  useEffect(() => {
    if (selectedSite) {
      get('/internal/developer/v3/dashboard/salesprogress', { site_id: selectedSite.site_id })
        .then(result => setData(result))
        .catch(error => {
          console.error(error)
        })
    }
  }, [selectedSite])

  return (
    <>
      {currencies && data && (<WidgetContainer width={5}>
        <FlexContainer justified>
          <WidgetTitle title={TITLE} tooltip={TOOLTIP} />
          <FlexContainer>
            <Select value={state.type} name="type" onChange={handleChange} autoWidth>
              <MenuItem value="units">Unidades</MenuItem>
              <MenuItem value="income">Ingresos ({currencies.main.code} {currencies.main.symbol})</MenuItem>
            </Select>
            <Spacer size={3} />
            <Select value={state.category} name="category" onChange={handleChange} autoWidth>
              {data && data[state.type].phases && (<MenuItem value="phases">Fases</MenuItem>)}
              {profile && data && data[state.type].towers && (profile.site_id === 41 ? <MenuItem value="towers">Manzana</MenuItem> : <MenuItem value="towers">Torres</MenuItem>)}
              {profile && profile.site_id === 41 ? <MenuItem value="typologies">Clases</MenuItem> : <MenuItem value="typologies">Tipologías</MenuItem>}
            </Select>
          </FlexContainer>
        </FlexContainer>
        <Spacer vertical size={3} />
        <SalesProgressChart data={data} type={state.type} category={state.category} />
      </WidgetContainer>)
      }
    </>
  )
}

export default SalesProgress
