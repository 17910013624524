import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';

const CustomSelectActions = ({
  disableEdit,
  disableDelete,
  onDelete,
  onEdit,
}) => {
  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Tooltip title="Editar">
        <IconButton disabled={disableEdit} onClick={onEdit}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Eliminar">
        <IconButton disabled={disableDelete} onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default CustomSelectActions;
