import { MenuItem, Select, Typography } from '@mui/material';
import styled from 'styled-components';
import { FlexContainer, Spacer } from '../../components';
import { numberToCurrency } from '../../helpers/utils';
import { ReactComponent as BedroomIcon } from '../../assets/icons/bed.svg';
import { ReactComponent as BathroomIcon } from '../../assets/icons/shower.svg';
import { ReactComponent as ParkinglotsIcon } from '../../assets/icons/parking.svg';
import { ReactComponent as ConstructionsizeIcon } from '../../assets/icons/plane.svg';
import { getAssetUrlFromId } from '../../helpers/assets';
import { useMemo } from 'react';

const CardContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 333px;
  height: fit-content;
`;

const CardBody = styled.div`
  padding: 16px;
`;

const CardImage = styled.img`
  width: 100%;
  max-height: 194px;
`;

const CustomIconContainer = styled(FlexContainer)`
  > svg {
    width: 24px;
    height: 24px;
  }
  color: --color-text-secondary;
`;

export const TypologyCard = ({
  data,
  locale,
  projectType,
  units,
  selectedUnit,
  handleSelectUnit,
}) => {
  const sortedUnits = useMemo(
    () => units?.sort((a, b) => a.id?.localeCompare(b.id)),
    [units]
  );
  return (
    <CardContainer>
      <CardImage
        src={
          data?.fields?.images
            ? data?.fields?.images[0]?.url
            : data?.assets && getAssetUrlFromId(data?.assets[0]?.assetId)
        }
      />
      <CardBody>
        <Typography variant="h6">
          {projectType === 'typology'
            ? data.fields.name[locale?.substring(0, 2) || 'es']
            : data.fields.typology}
        </Typography>
        {projectType === 'unit' && (
          <Select
            disableUnderline
            fullWidth
            onChange={handleSelectUnit}
            value={selectedUnit}
            variant="standard"
          >
            {sortedUnits?.map((unit) => (
              <MenuItem value={unit}>{unit.id}</MenuItem>
            ))}
          </Select>
        )}
        <Spacer size={4} vertical />
        <Typography variant="body2" color={'var(--color-text-disabled)'}>
          $
          {projectType === 'typology'
            ? numberToCurrency(data.fields.price.MXN)
            : numberToCurrency(data.fields.prices.MXN)}
        </Typography>
        <Spacer size={4} vertical />
        <FlexContainer>
          <CustomIconContainer>
            <BedroomIcon />
            {data?.fields?.bedrooms}
          </CustomIconContainer>
          <Spacer size={2} />
          <CustomIconContainer>
            <BathroomIcon />
            {data?.fields?.bathrooms}
          </CustomIconContainer>
          <Spacer size={2} />
          <CustomIconContainer>
            <ParkinglotsIcon />
            {data?.fields?.parking_lots}
          </CustomIconContainer>
          <Spacer size={2} />
          <CustomIconContainer>
            <ConstructionsizeIcon />
            {data?.fields?.construction_size}
          </CustomIconContainer>
        </FlexContainer>
      </CardBody>
    </CardContainer>
  );
};
