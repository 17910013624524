import { FlexContainer, Select, Spacer } from '../../components'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BulkEditContainer from './BulkEditContainer'
import { convertDateToMMYY, MONTHS } from '../../helpers/utils'
import BulkEditDeliveryDateTable from './BulkEditDeliveryDateTable'
import { Typography } from '@mui/material'

const StyledTypography = styled(Typography)`
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
`

const months = Object.entries(MONTHS).map(([key, value]) => ({
  label: value,
  value: key,
}))

const currentYear = new Date().getFullYear()
const years = []
for (let i = -1; i <= 5; i += 1) {
  const year = currentYear + i
  years.push({
    label: year,
    value: year,
  })
}

const BulkEditDeliveryDate = ({ inventoryBulk, onUpdate }) => {
  const [newInventory, setNewInventory] = useState(inventoryBulk)
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')

  useEffect(() => {
    setNewInventory((inventory) => {
      const updatedInventory = inventory.map(i => ({
        ...i,
        new_delivery_date: convertDateToMMYY(new Date(Number(year), Number(month)))
      }))
      return updatedInventory
    })
  }, [month, year])

  const handleUpdateClick = () => onUpdate(newInventory)

  return (
    <BulkEditContainer
      title="Edición masiva de la fecha de entrega"
      onUpdate={handleUpdateClick}
      showUpdateButton={month !== '' && year !== ''}
    >
      <FlexContainer>
        <StyledTypography>Cambiar fecha de entrega a</StyledTypography>
        <Spacer size={2} />
        <Select options={months} value={month} onChange={setMonth} placeholder="Mes" />
        <Spacer size={2} />
        <Select options={years} value={year} onChange={setYear} placeholder="Año" />
      </FlexContainer>
      {month && year && (
        <>
          <Spacer vertical size={4} />
          <BulkEditDeliveryDateTable newInventory={newInventory} />
        </>
      )}
    </BulkEditContainer>
  )
}

export default BulkEditDeliveryDate
