import { useState } from "react"
import { useCallback } from "react"

export const OUTSIDE_SECTIONS = {
  floatingBanner: "floatingBanner"
}

const useSections = (data, setData) => {
  const [viewSectionsManager, setViewSectionsManager] = useState(false)
  const [pageToManage, setPageToManage] = useState({})

  const toggleSectionsManager = useCallback(() => setViewSectionsManager(s => !s), [])

  const onViewSectionsManager = useCallback(() => {
    setPageToManage(data?.pages?.home)
    setViewSectionsManager(true)
  }, [data?.pages?.home])

  const onSectionDelete = useCallback((index) => {
    const newSections = pageToManage?.sections.filter((_, i) => i !== index)
    setPageToManage(s => ({
      ...s,
      sections: newSections
    }))
  }, [pageToManage])

  const onSectionEnable = useCallback((value, index) => {
    if (typeof index === "string") {
      switch (index) {
        case OUTSIDE_SECTIONS.floatingBanner:
          setPageToManage(s => ({
            ...s,
            banner: {
              ...s.banner,
              disabled: !value,
            }
          }))
          break;
        default:
      }
    }
    const newSections = pageToManage?.sections.map((s, i) => {
      if (i === index) {
        return {
          ...s,
          disabled: !value,
        }
      }
      return s
    })
    setPageToManage(s => ({
      ...s,
      sections: newSections
    }))
  }, [pageToManage])

  const onSectionsReorder = useCallback((sections) => {
    setPageToManage(s => ({
      ...s,
      sections,
    }))
  }, [])

  const onSectionsSave = useCallback(() => {
    setData(d => ({
      ...d,
      pages: {
        ...d.pages,
        home: pageToManage,
      }
    }))
    setViewSectionsManager(false)
  }, [pageToManage, setData])

  return {
    viewSectionsManager,
    pageToManage,
    toggleSectionsManager,
    onSectionsReorder,
    onViewSectionsManager,
    onSectionDelete,
    onSectionEnable,
    onSectionsSave,
  }
}

export default useSections
