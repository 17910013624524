import { useLoadScript } from "@react-google-maps/api";
import React, { useCallback } from "react";
import { PlacesAutocomplete } from "../../../components";

/*
CONFIG.JS DEFINITIONS
coordinates: {
  latitude: 21.019985,
  longitude: -89.571812
},
address: "Berlí­n 16, col. Juarez, Ciudad de México",
*/

const mapLibraries = ["places"];

const MapInput = ({ pathData, setPathData }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: mapLibraries,
  });

  const onSelect = useCallback(
    ({ lat, lng, address }) =>
      setPathData({ address, coordinates: { latitude: lat, longitude: lng } }),
    [setPathData]
  );

  if (!isLoaded) return null;

  return (
    <PlacesAutocomplete
      variant="standard"
      inputLabel="Dirección"
      initialValue={pathData?.address}
      onSelect={onSelect}
    />
  );
};

export default MapInput;
