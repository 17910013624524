import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import InitialsAvatar from './InitialsAvatar'

const LabelWithInitialsAvatar = ({label}) => {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <InitialsAvatar string={label} />
      <Typography>{label}</Typography>
    </Stack>
  )
}

export default LabelWithInitialsAvatar