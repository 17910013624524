import {
  AccessTime as AccessTimeIcon,
  CalendarToday as CalendarTodayIcon,
  Web as WebIcon
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Typography
} from '@mui/material';
import React, { useMemo } from 'react';
import Image from '../../components/Image/Image';
import { getImageUrlFromId } from '../../helpers/assets';
import { getPermission } from '../../helpers/permissions';
import { formatName } from '../../helpers/utils';
import useLocale from '../../hooks/useLocale';
import useSite from '../../hooks/useSite';
import { CardMedia } from '../../modules';

function formatPrice(num) {
  return Math.round(+num).toLocaleString();
}

const HeaderPrice = ({ label, price, currency }) =>
  price &&
  +price > 0 && (
    <div>
      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
        {label}
      </Typography>
      <Typography variant="h5" sx={{ fontWeight: 500 }}>
        {currency} ${formatPrice(price)}
      </Typography>
    </div>
  );

const QuotationDetail = ({
  inventoryPublicId,
  type,
  name,
  createdAt,
  currency,
  finalPrice,
  downPayment,
  numberOfMonths,
  monthlyPayment,
  images,
  extraPayments,
  user,
}) => {
  const { site } = useSite();
  const locale = useLocale();

  const userName = useMemo(
    () => user && formatName(user.name, user.lastname),
    [user],
  );

  const dayStr = useMemo(
    () =>
      new Date(createdAt).toLocaleDateString(locale, {
        day: 'numeric',
        month: 'long',
      }),
    [locale, createdAt],
  );
  const timeStr = useMemo(
    () =>
      new Date(createdAt).toLocaleTimeString(locale, {
        timeStyle: 'short',
      }),
    [locale, createdAt],
  );

  const imgSrc = images?.[0]?.url;

  const link = useMemo(() => {
    if (type === 'unit')
      return `https://${site.site_domain}/unidades/${inventoryPublicId}`;
  }, [type, inventoryPublicId, site]);

  const extraPaymentsFormatted = useMemo(() => {
    if (!Array.isArray(extraPayments) || extraPayments.length < 1) return null;
    return extraPayments.map(({ date, amount }) => ({
      key: `${date}${amount}`,
      date: new Date(date).toLocaleDateString(locale, {
        month: 'long',
        year: 'numeric',
      }),
      amount: formatPrice(amount),
    }));
  }, [locale, extraPayments]);

  const hasFullView = useMemo(
    () =>
      site?.permissions &&
      getPermission(['prospects', 'prospects'], site?.permissions) === 'view',
    [site],
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          mb: 1,
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 500 }}>
          {name?.es || inventoryPublicId}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
          {link && (
            <Button
              target="_blank"
              href={link}
              variant="contained"
              color="primary"
              size="small"
            >
              Ver unidad
            </Button>
          )}
          {hasFullView && (
            <Typography
              component="span"
              sx={{ fontWeight: 500, display: 'flex', alignItems: 'center' }}
            >
              Origen:
              <Chip
                label={user ? `${userName}` : 'Showroom'}
                icon={<WebIcon />}
                avatar={
                  user && (
                    <Avatar
                      alt={userName}
                      src={user.avatar && getImageUrlFromId(user.avatar)}
                    >
                      {userName?.[0]}
                    </Avatar>
                  )
                }
                sx={{ ml: 1 }}
              />
            </Typography>
          )}
          <Typography
            component="span"
            sx={{ fontWeight: 500, display: 'flex' }}
          >
            <CalendarTodayIcon sx={{ mr: 1 }} /> {dayStr}
          </Typography>
          <Typography
            component="span"
            sx={{ fontWeight: 500, display: 'flex' }}
          >
            <AccessTimeIcon sx={{ mr: 1 }} /> {timeStr}
          </Typography>
        </Box>
      </Box>
      <Card variant="outlined" sx={{ display: 'flex' }}>
        {imgSrc && (
          <CardMedia
            component={Image}
            height={128}
            src={imgSrc}
            sx={{ width: 0.25, maxWidth: 320, m: 3, borderRadius: 1 }}
          />
        )}
        <CardContent sx={{ flex: 1, m: 'auto' }}>
          <Box
            sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}
          >
            <HeaderPrice
              currency={currency}
              label="Precio"
              price={finalPrice}
            />
            <HeaderPrice
              currency={currency}
              label="Pago inicial"
              price={downPayment}
            />
            <HeaderPrice
              currency={currency}
              label={`${numberOfMonths || ''} mensualidades de`}
              price={monthlyPayment}
            />
          </Box>
          {extraPaymentsFormatted && (
            <Box sx={{ mt: 1 }}>
              <Typography
                variant="caption"
                sx={{ color: 'text.secondary', display: 'block', mb: 1 }}
              >
                Pagos extras
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {extraPaymentsFormatted.map(({ date, amount }) => (
                  <Box
                    key={`${date}${amount}`}
                    sx={{ display: 'flex', mr: 1, mb: 1 }}
                  >
                    <Typography sx={{ mr: 1, fontWeight: 500 }}>
                      {date}:
                    </Typography>
                    <Chip
                      variant="contained"
                      label={
                        <Typography variant="body2" sx={{ fontWeight: 500 }}>
                          ${amount}
                        </Typography>
                      }
                      size="small"
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default QuotationDetail;
