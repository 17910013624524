import React from "react"
import CTAAction from "./CTAAction"

const CTAButton = ({ pathData, setPathData, maxChars }) => {
  return (
    <CTAAction
      value={pathData}
      onChange={setPathData}
      maxChars={maxChars}
      label="Botón (llamado a la acción)"
    />
  )
}

export default CTAButton
