import { useNavigate } from 'react-router-dom'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import useProfile from '../../hooks/useProfile';
import useSite from '../../hooks/useSite';
import SidebarOption from './SidebarOption';
import ProjectOption from './ProjectOption';
import LabelWithInitialsAvatar from './LabelWithInitialsAvatar';
import ListOptionsSubheader from './ListOptionsSubheader';
import { getRedirectUrl, redirectPrioritization } from '../../helpers/defaultLinks';

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0;
  .MuiAccordionSummary-content {
    padding-inline-start: 1rem;
  }
`

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  &.MuiAccordionDetails-root, ul {
    padding-block-start: 0;
  }
`

const ProjectSelector = () => {
  const location = useLocation()
  const profile = useProfile()
  const navigate = useNavigate()
  const projects = useMemo(() => profile && profile.sites, [profile])
  const {site, setSite} = useSite()
  const [expanded, setExpanded] = useState(false);

  const toggleOpen = useCallback((panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  }, [])

  const handleChange = useCallback((item) => {
    setSite(item);
    setExpanded(false)
    const redirectUrl = getRedirectUrl(item, redirectPrioritization)
    navigate(redirectUrl)
  }, [navigate, setSite])

  return (
    <div>
      {site && projects &&
        <Accordion expanded={expanded === 'projects'} onChange={toggleOpen('projects')} sx={{boxShadow: "none"}}>
          <StyledAccordionSummary
            expandIcon={<ArrowDropDown />}
            aria-controls="projects-content"
            id="projects-header"
          >
            <LabelWithInitialsAvatar label={site.site_name} />
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            {projects && <List>
              <ListOptionsSubheader>{'Tus proyectos'}</ListOptionsSubheader>
              {projects.map((item) => (
                <ProjectOption key={item.site_id} label={item.site_name} onClick={() => handleChange(item)}/>
              ))}
              <SidebarOption icon={MapsHomeWorkIcon} label={'Mis proyectos'} to='/projects' selected={location.pathname.includes("/projects")} />
            </List>}
          </StyledAccordionDetails>
        </Accordion>}
      {!site && !projects && <CircularProgress />}
    </div>
  )
}

export default ProjectSelector