import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';

/**
 * @param {import('@mui/material').CircularProgressProps & {
 *  labelProps?: import('@mui/material').TypographyProps;
 *  label?: string
 * }} props
 */
const CircularProgressWithLabel = ({
  labelProps,
  label,
  children,
  ...props
}) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      {children}
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          {...labelProps}
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            ...labelProps?.sx,
          }}
        >
          {typeof label === 'string' ? label : `${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
