import React from 'react'
import styled from 'styled-components'
import { Button, Typography } from '@mui/material'
import FlexContainer from '../FlexContainer/FlexContainer'

const Container = styled(FlexContainer)`
  gap: 8px;
`

const Switcher = styled(FlexContainer)`
  background: rgba(0,0,0,0.12);
  gap: 8px;
  padding: 6px;
  border-radius: 20px;
  width: fit-content;
`

const Option = styled(Button)`
  min-width: 78px;
`

const TwoOptionsSwitch = ({ isFirstOptionSelected = true, setIsFirstOptionSelected, firstOptionLabel, secondOptionLabel}) => {
  return (
    <Switcher>
      <Option
        size="small"
        variant={isFirstOptionSelected ? "contained" : "text"}
        color={isFirstOptionSelected ? "primary" : "inherit"}
        onClick={() => setIsFirstOptionSelected(true)}
      >
        {firstOptionLabel}
      </Option>
      <Option
        size="small"
        variant={isFirstOptionSelected ? "text" : "contained"}
        color={isFirstOptionSelected ? "inherit" : "primary"}
        onClick={() => setIsFirstOptionSelected(false)}
      >
        {secondOptionLabel}
      </Option>
    </Switcher>
  )
}

export default TwoOptionsSwitch