import { useCallback, useEffect, useMemo, useState } from "react"
import { currencyToNumber, generateRandomId } from "../../helpers/utils"

const DEFAULT_STAGES = [{
  id: 1,
  name: 'Por iniciar obra',
  completed: false,
  editing: false,
}, {
  id: 2,
  name: 'Excavación',
  completed: false,
  editing: false,
}, {
  id: 3,
  name: 'Estructura',
  completed: false,
  editing: false,
}, {
  id: 4,
  name: 'Instalaciones',
  completed: false,
  editing: false,
}, {
  id: 5,
  name: 'Albañilería',
  completed: false,
  editing: false,
}, {
  id: 6,
  name: 'Acabados',
  completed: false,
  editing: false,
}]

export const DEFAULT_SETTINGS = {
  enabled: false,
  stages: DEFAULT_STAGES.map(s => ({ id: s.id, name: s.name, completed: s.completed })),
  percent: 0,
  enabledGallery: false,
  periods: [],
}

export const getDate = (date) => {
  if (date == null) return null
  if (typeof date === "string") return new Date(date)
  if (date instanceof Date) return date
  return date.$d
}

const getOrderedPeriods = (periods) => {
  return [...periods].sort((a, b) => {
    if (a.enabled !== b.enabled) {
      return b.enabled - a.enabled
    }
    return getDate(b.date) - getDate(a.date)
  })
}

const checkRepeatedDate = (date, periods) => {
  if (!isNaN(date.$D)) {
    const currentMonth = date.$M
    const currentYear = date.$y

    const repeatedPeriods = periods.filter(p => {
      const date = new Date(p.date)
      const month = date.getMonth()
      const year = date.getFullYear()

      if (currentMonth === month && currentYear === year) {
        return true
      }
      return false
    })

    return repeatedPeriods.length > 0
  }
  return false
}

export const useWorkProgress = ({ currentData, enabled }) => {
  const [stages, setStages] = useState(DEFAULT_STAGES)
  const [percent, setPercent] = useState(0)
  const [enabledGallery, setEnabledGallery] = useState(false)
  const [periods, setPeriods] = useState([])

  useEffect(() => {
    if (currentData && Object.keys(currentData).length > 0) {
      const currentStates = currentData?.stages?.map(s => ({ ...s, editing: false }))
      
      setStages(currentStates)
      setPercent(currentData.percent)
      setEnabledGallery(currentData.enabledGallery)
      setPeriods(currentData.periods)
    }
  }, [currentData])

  const isValid = useMemo(() => {
    let isValid = true
    stages.forEach(s => {
      if (s.name.length === 0) {
        isValid = false
      }
    })

    periods.forEach(p => {
      if (p.media.length === 0
        || p.repeatedDate
        || (typeof p.date !== "string" && !(p.date instanceof Date) && isNaN(p.date?.$D))) {
        isValid = false
      }
    })

    return isValid
  }, [periods, stages])

  const onStagesAdd = useCallback(() => {
    setStages(s => ([
      ...s,
      {
        id: generateRandomId(),
        name: 'Nueva Etapa',
        completed: false,
        editing: false,
      }
    ]))
  }, [])

  const onStagesDelete = useCallback((id) => {
    setStages(s => {
      const newStates = s.filter(stage => stage.id !== id)
      return newStates
    })
  }, [])

  const onStageChange = useCallback((id, value, attribute) => {
    setStages(s => {
      const newStates = s.map(stage => stage.id !== id ? stage : ({
        ...stage,
        [attribute]: value,
      }))
      return newStates
    })
  }, [])

  const onPercentChange = useCallback((value) => {
    const rawValue = currencyToNumber(value)
    setPercent(rawValue)
  }, [])

  const onPercentBlur = useCallback((value) => {
    const rawValue = currencyToNumber(value)
    if (rawValue < 0) {
      setPercent(rawValue)
    } else if (rawValue > 100) {
      setPercent(100)
    } else {
      setPercent(rawValue)
    }
  }, [])

  const onPercentKeyDown = useCallback((keyCode) => {
    if (keyCode.toLowerCase() === "backspace") {
      if (percent > 0) {
        const newPercent = String(percent).slice(0, -1)
        setPercent(Number(newPercent))
      }
    }
  }, [percent])

  const onPeriodsAdd = useCallback(() => {
    setPeriods(p => ([
      ...p,
      {
        id: generateRandomId(),
        enabled: true,
        date: null,
        media: []
      }
    ]))
  }, [])

  const onPeriodsChange = useCallback((id, value, attribute) => {
    setPeriods(p => {
      const newPeriods = p.map(period => period.id !== id ? period : ({
        ...period,
        [attribute]: value,
      }))
      return newPeriods
    })
  }, [])

  const onPeriodsDelete = useCallback((id) => {
    setPeriods(p => {
      const newPeriods = p.filter(period => period.id !== id)
      return newPeriods
    })
  }, [])

  const onPeriodsReorder = useCallback(() => {
    const orderedPeriods = getOrderedPeriods(periods)
    setPeriods(orderedPeriods)
  }, [periods])

  const onPeriodsDateChange = useCallback((id, value) => {
    setPeriods(p => {
      const isRepeated = checkRepeatedDate(value, periods)

      const newPeriods = p.map(period => period.id !== id ? period : ({
        ...period,
        date: value,
        repeatedDate: isRepeated,
      }))
      return newPeriods
    })
  }, [periods])

  const onPeriodsMediaAdd = useCallback((periodId, media) => {
    const currentMedia = media.map(m => ({
      ...m,
      id: generateRandomId(),
      ...(m.type === "image" && { title: '' })
    }))
    setPeriods(p => {
      const newPeriods = p.map(period => period.id !== periodId ? period : ({
        ...period,
        media: [...period.media, ...currentMedia],
      }))
      return newPeriods
    })
  }, [])

  const onPeriodsMediaDelete = useCallback((periodId, mediaIndex) => {
    setPeriods(p => {
      const newPeriods = p.map(period => period.id !== periodId ? period : ({
        ...period,
        media: period.media.filter((_, index) => index !== mediaIndex),
      }))
      return newPeriods
    })
  }, [])

  const onPeriodsMediaChange = useCallback((periodId, mediaId, value, attribute) => {
    setPeriods(p => {
      const newPeriods = p.map(period => period.id !== periodId ? period : ({
        ...period,
        media: period.media.map(m => m.id !== mediaId ? m : ({
          ...m,
          [attribute]: value,
        }))
      }))
      return newPeriods
    })
  }, [])

  const onPeriodsMediaReorder = useCallback((periodId, value) => {
    setPeriods(p => {
      const newPeriods = p.map(period => period.id !== periodId ? period : ({
        ...period,
        media: value
      }))
      return newPeriods
    })
  }, [])

  const workProgressData = useMemo(() => {
    const formattedStages = stages.map(s => ({
      id: s.id,
      name: s.name,
      completed: s.completed,
    }))

    const formattedPeriods = periods.map(p => ({
      id: p.id,
      enabled: p.enabled,
      date: getDate(p.date),
      media: p.media.map(m => ({
        id: m.id,
        assetId: m.assetId,
        url: m.url,
        type: m.type,
        ...(m.type === "image" && { title: m.title }),
      }))
    }))
    const orderedPeriods = getOrderedPeriods(formattedPeriods)

    return {
      enabled,
      stages: formattedStages,
      percent,
      enabledGallery,
      periods: orderedPeriods,
    }
  }, [enabled, enabledGallery, percent, periods, stages])

  return {
    workProgressData,
    isValid,
    stages,
    onStagesAdd,
    onStagesDelete,
    onStageChange,
    onStageReorder: setStages,
    percent,
    onPercentChange,
    onPercentBlur,
    onPercentKeyDown,
    onPercentSliderChange: setPercent,
    enabledGallery,
    onEnabledGalleryChange: setEnabledGallery,
    periods,
    onPeriodsAdd,
    onPeriodsChange,
    onPeriodsDelete,
    onPeriodsReorder,
    onPeriodsDateChange,
    onPeriodsMediaAdd,
    onPeriodsMediaDelete,
    onPeriodsMediaChange,
    onPeriodsMediaReorder,
  }
}
