import { Save as SaveIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Snackbar, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import WithPermission from '../../components/WithPermission/WithPermission';
import { get, put } from '../../helpers/request';
import { configTabs } from '../../helpers/tabsData';
import useSite from '../../hooks/useSite';
import PageLayout from '../../layouts/PageLayout';
import NotificationChannelOption from './NotificationChannelOption';

const NOTIFICATION_CHANNEL = {
  EMAIL: 'email',
  WHATSAPP: 'whatsapp',
};

const NOTIFICATIONS = [
  {
    event: 'new_lead',
    label: 'Nuevos prospectos',
    description:
      'Recibirás notificaciones cuando un usuario deje sus datos de contacto en tu showroom.',
    options: [NOTIFICATION_CHANNEL.EMAIL, NOTIFICATION_CHANNEL.WHATSAPP],
  },
  {
    event: 'new_quote',
    label: 'Nuevas cotizaciones',
    description:
      'Recibirás notificaciones cuando un usuario realice una cotización.',
    options: [NOTIFICATION_CHANNEL.EMAIL, NOTIFICATION_CHANNEL.WHATSAPP],
  },
  {
    event: 'payments_and_reservations',
    label: 'Pagos y reservas',
    description:
      'Recibirás notificaciones cuando un usuario realice una reserva o un pago.',
    options: [NOTIFICATION_CHANNEL.EMAIL, NOTIFICATION_CHANNEL.WHATSAPP],
  },
];

const savedConfigFn = ({ queryKey }) => {
  const siteId = queryKey[1];
  if (!siteId) return;
  return get('/internal/alohub/config/notifications', { site_id: siteId });
};

const saveConfigFn = ({ config, siteId }) =>
  put('/internal/alohub/config/notifications', config, siteId);

const getSalesSupervisorsFn = ({ queryKey }) => {
  const siteId = queryKey[1];
  if (!siteId) return;
  return get('/internal/alohub/agents?role=SUPERVISOR', { site_id: siteId });
};

const NotificationsView = () => {
  const { site } = useSite();
  const siteId = site?.site_id;

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
  } = useForm({ mode: 'onSubmit' });

  const {
    isLoading: isLoadingSaved,
    isRefetching: isRefetchingSaved,
    refetch,
  } = useQuery(['NOTIFICATIONS_CONFIG', siteId], savedConfigFn, {
    refetchOnWindowFocus: false,
    onSuccess: reset,
  });

  const { data: salesSupervisors } = useQuery(
    ['SALES_SUPERVISORS', siteId],
    getSalesSupervisorsFn
  );

  const { mutate: saveConfig, isLoading: isSaving } = useMutation(
    saveConfigFn,
    {
      onSuccess: () => {
        refetch();
        setShowSuccess(true);
      },
      onError: () => setShowError(true),
    }
  );

  const onSubmit = useMemo(
    () => handleSubmit((config) => saveConfig({ config, siteId })),
    [handleSubmit, saveConfig, siteId]
  );

  const channelOptions = useMemo(
    () => ({
      [NOTIFICATION_CHANNEL.EMAIL]:
        salesSupervisors?.map(({ email }) => email) || [],
      [NOTIFICATION_CHANNEL.WHATSAPP]:
        salesSupervisors
          ?.map(({ whatsappPhone }) => whatsappPhone)
          .filter((v) => !!v) || [],
    }),
    [salesSupervisors]
  );

  const getOptionLabel = useCallback(
    (channel, value) => {
      const userChannelKey = {
        [NOTIFICATION_CHANNEL.EMAIL]: 'email',
        [NOTIFICATION_CHANNEL.WHATSAPP]: 'whatsappPhone',
      };
      const option = salesSupervisors?.find(
        (user) => user[userChannelKey[channel]] === value
      );
      if (!option) return value;
      const { name, lastname } = option;
      const fullname = [name, lastname].filter((v) => !!v).join(' ');
      return fullname ? `${value} (${fullname})` : value;
    },
    [salesSupervisors]
  );

  return (
    <>
      <Snackbar
        open={showSuccess}
        autoHideDuration={3_000}
        onClose={() => setShowSuccess(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="success" onClose={() => setShowSuccess(false)}>
          Configuración guardada
        </Alert>
      </Snackbar>
      <Snackbar
        open={showError}
        autoHideDuration={4_000}
        onClose={() => setShowError(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="error" onClose={() => setShowError(false)}>
          Ocurrió un error guardando la configuración. Por favor, inténtalo de
          nuevo.
        </Alert>
      </Snackbar>
      <PageLayout
        links={configTabs}
        loading={isLoadingSaved}
        menu={
          <LoadingButton
            startIcon={<SaveIcon />}
            disabled={!isDirty || isSaving || isRefetchingSaved}
            onClick={() => onSubmit()}
            loading={isSaving}
            loadingPosition="start"
          >
            Guardar
          </LoadingButton>
        }
      >
        {!isLoadingSaved && (
          <Box sx={{ mt: 2, maxWidth: 'md' }}>
            {NOTIFICATIONS.map(({ event, label, description, options }) => (
              <WithPermission
                key={event}
                permissionPath={['config', 'notifications', event]}
              >
                <Typography variant="h5" sx={{ mb: 1 }}>
                  {label}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  {description}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    my: 6,
                  }}
                >
                  {options.map((channel) => (
                    <NotificationChannelOption
                      key={channel}
                      channel={channel}
                      event={event}
                      label={label}
                      description={description}
                      control={control}
                      error={errors[event]?.[channel]?.value?.message}
                      autocompleteOptions={channelOptions[channel]}
                      getOptionLabel={(v) => getOptionLabel(channel, v)}
                    />
                  ))}
                </Box>
              </WithPermission>
            ))}
          </Box>
        )}
      </PageLayout>
    </>
  );
};

export default NotificationsView;
