import { Box, Divider, List, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spacer } from '../../components';
import { PLANS } from '../../helpers/constants';
import useProfile from '../../hooks/useProfile';
import useSite from '../../hooks/useSite';
import ProjectOption from '../../modules/Sidebar/ProjectOption';

const QuoteChooseProjectView = () => {

  const profile = useProfile()
  const {setSite} = useSite()
  const navigate = useNavigate()
  const projects = useMemo(() => profile && profile.sites, [profile])

  const handleChange = useCallback((item) => {
    setSite(item);
    navigate("/quote/builder")
  }, [setSite])

  return (
    <Box  sx={{ padding: "2rem" }}>
      <Typography mb={".5rem"}>
        TUS PROYECTOS
      </Typography>
      <Divider />
      <Spacer vertical size={2} />
      <Typography>
        Selecciona el proyecto en el que deseas cotizar
      </Typography>
      <Spacer vertical size={3} />
      {projects && <List> 
        {projects.map((item) => (
          item.plan !== PLANS.ENTREPRENEUR && <ProjectOption key={item.site_id} label={item.site_name} onClick={() => handleChange(item)}/>
        ))}
      </List>}
    </Box>
  );
};

export default QuoteChooseProjectView;
