import { Add as AddIcon, Close as CloseIcon } from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import React, { useMemo, useState } from "react";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import TypologyCard from "./TypologyCard";

const TypologiesList = ({ typologies, onEdit, onAdd, onDelete, onClose }) => {
  const [deletingTypologyId, setDeletingTypologyId] = useState(null);

  const sortedTypologies = useMemo(() => {
    const typologiesArr = Object.entries(typologies).map(([id, fields]) => ({
      id,
      fields,
    }));
    return typologiesArr.sort(({ fields: fieldsA }, { fields: fieldsB }) =>
      fieldsA?.name?.es?.localeCompare?.(fieldsB?.name?.es)
    );
  }, [typologies]);

  return (
    <>
      <IconButton onClick={onClose} sx={{ ml: "auto" }}>
        <CloseIcon />
      </IconButton>
      <Button
        variant="contained"
        color="primary"
        endIcon={<AddIcon />}
        onClick={onAdd}
        sx={{ mr: "auto", mb: 4 }}
      >
        Agregar una tipología
      </Button>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(333px, 1fr))",
          gap: 4,
        }}
      >
        {sortedTypologies?.map(({ id, fields }) => (
          <TypologyCard
            key={id}
            fields={fields}
            onDelete={
              sortedTypologies.length > 1
                ? () => setDeletingTypologyId(id)
                : undefined
            }
            onEdit={() => onEdit(id)}
          />
        ))}
      </Box>
      <DeleteConfirmationDialog
        open={!!deletingTypologyId}
        onClose={() => setDeletingTypologyId(null)}
        onDelete={() => {
          setDeletingTypologyId((id) => {
            onDelete(id);
            return null;
          });
        }}
        typologyName={typologies[deletingTypologyId]?.name?.es}
      />
    </>
  );
};

export default TypologiesList;
