import { useEffect, useRef } from 'react'

function useHandleOutsideClick(callback) {
  const container = useRef(null)

  useEffect(() => {
    function handleClick(event) {
      if (container.current && !container.current.contains(event.target)) {
        callback && callback()
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    };
  }, [callback])

  return container
}

export default useHandleOutsideClick
