import { Button, Typography } from "@mui/material"
import React from "react"
import { Fragment } from "react"
import styled from "styled-components"
import { FlexContainer, Spacer } from "../../../components"
import ColorCard from "./ColorCard"
import ThemeCard from "./ThemeCard"
import ThemesSidebar from "./ThemesSidebar"

const ChangeThemeButton = styled(Button)`
  width: 190px;
`

const CustomizeButton = styled(Button)`
  width: 150px;
`

const ModifyColorsButton = styled(Button)`
  width: 120px;
`

const THEME_VERSION = {
  option1: '1',
  option2: '2',
}

const ColorSelector = ({
  toggleThemesEditor,
  viewThemesEditor,
  onCustomize,
  selectedTheme,
  selectedColor,
  selectedThemeData,
  onThemeSelect,
  onColorSelect,
  customColors,
  onCustomizeColors,
  onDeleteCustomColors,
}) => (
  <>
    <ThemesSidebar
      open={viewThemesEditor}
      onClose={toggleThemesEditor}
      currentTheme={selectedTheme}
      onSelect={onThemeSelect}
    />
    <FlexContainer vertical>
      <Typography variant="h6">Modifica el diseño de tu showroom</Typography>
      <Spacer size={1} vertical />
      <Typography>Tema seleccionado</Typography>
      <Spacer size={1} vertical />
      <ThemeCard img={selectedThemeData?.imageSrc} label={selectedThemeData?.label} />
      <Spacer size={2} vertical />
      <ChangeThemeButton variant="outlined" color="primary" onClick={toggleThemesEditor}>Ver otros temas</ChangeThemeButton>
      <Spacer size={3} vertical />
      <Typography>Combinaciones de colores disponibles</Typography>
      <Spacer size={1} vertical />
      <FlexContainer>
        {selectedThemeData?.colors.map((color) => (
          <Fragment key={color.value}>
            <ColorCard
              color1={color.primary}
              color2={color.secondary}
              color3={color.neutral}
              isDisabled={!!customColors || selectedColor !== THEME_VERSION[color.value]}
              showButton={selectedColor !== THEME_VERSION[color.value] && !customColors}
              onClick={() => onColorSelect(THEME_VERSION[color.value])}
              buttonText="elegir"
            />
            <Spacer size={2} />
          </Fragment>
        ))}
      </FlexContainer>
      <Spacer size={3} vertical />
      <Typography>Personaliza los colores de tu showroom</Typography>
      <Spacer size={1} vertical />
      {customColors
        ? (
          <FlexContainer vertical>
            <ColorCard
              color1={customColors.primary}
              color2={customColors.secondary}
              color3={customColors.neutral80}
              onClick={onDeleteCustomColors}
              buttonText="eliminar"
              showButton
            />
            <Spacer vertical size={2} />
            <ModifyColorsButton color="primary" variant="outlined" onClick={onCustomizeColors}>Modificar</ModifyColorsButton>
          </FlexContainer>
        ) : (
          <CustomizeButton
            variant="outlined"
            color="primary"
            onClick={onCustomize}
          >
            Personalizar
          </CustomizeButton>
        )
      }
      
    </FlexContainer>
  </>
)

export default ColorSelector
