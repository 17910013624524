import { Button, Link, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FlexContainer, Spacer } from '../../components';
import { getPermission, PERMISSIONS } from '../../helpers/permissions';
import { get } from '../../helpers/request';
import useSite from '../../hooks/useSite';
import ProjectImage from '../../modules/ProjectImage/ProjectImage';

const GET_SHOWROOM_CONFIG =
  '/internal/settings/v1/settings?category=showroom&subcategory=config';

const PROPERTY_TYPE = {
  APARTMENT: 'Departamentos',
  LOT: 'Terrenos/Lotes',
  HOUSE: 'Casas',
};

const DevelopmentDetails = ({ site }) => {
  const navigate = useNavigate();
  const { setSite } = useSite();
  const dispatch = useDispatch();
  const [showroomData, setShowroomData] = useState({});

  const siteDomain = useMemo(
    () => `https://${site.site_domain}`,
    [site.site_domain],
  );
  const selectedSite = useSelector((state) => state.site);

  const handleGoToShowroom = useCallback(() => {
    setSite(site);
    navigate('/digital-showroom/editor');
  }, [navigate, setSite, site]);

  const handleChangePlan = useCallback(() => {
    dispatch({ type: 'open-plans-modal' });
  }, [dispatch]);

  useEffect(() => {
    const getData = async () => {
      const data = await get(GET_SHOWROOM_CONFIG, { site_id: site?.site_id });
      setShowroomData(data);
    };
    getData();
  }, [site?.site_id]);

  const showEditButton = useMemo(() => {
    const permission = getPermission(
      ['digital_showroom', 'editor'],
      selectedSite?.permissions,
    );
    if (permission !== PERMISSIONS.hidden) {
      return true;
    }
    return false;
  }, [selectedSite?.permissions]);

  return (
    <FlexContainer vertical>
      <Spacer vertical size={5} />
      <FlexContainer topped>
        <ProjectImage src={site.site_logo} alt={site.site_name} />
        <Spacer size={4} />
        <FlexContainer vertical>
          <Typography variant="h4">{site.site_name}</Typography>
          <Spacer vertical size={1} />
          {showroomData.location?.address && (
            <>
              <Typography variant="h6">
                {showroomData.location?.address}
              </Typography>
              <Spacer vertical size={1} />
            </>
          )}
          {(showroomData.project_type || showroomData.units_qty) && (
            <FlexContainer>
              {showroomData.project_type && (
                <>
                  <Typography>
                    {PROPERTY_TYPE[showroomData.project_type]}
                  </Typography>
                  <Spacer size={4} />
                </>
              )}
              {showroomData.units_qty && (
                <Typography>{showroomData.units_qty} unidades</Typography>
              )}
            </FlexContainer>
          )}
          <Spacer vertical size={1} />
          <Link href={siteDomain} target="_blank" variant="body2">
            {siteDomain}
          </Link>
          <Spacer vertical size={8} />
          <Button
            color="primary"
            variant="contained"
            onClick={handleChangePlan}
            sx={{ width: '150px' }}
          >
            CAMBIAR PLAN
          </Button>
          <Spacer vertical size={2} />
          {showEditButton && (
            <Button
              color="primary"
              onClick={handleGoToShowroom}
              sx={{ width: '164px' }}
            >
              editar showroom
            </Button>
          )}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default DevelopmentDetails;
