import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import { CONTACT_METHODS_PATH, getPathValue } from '../../helpers/data';
import { post, put } from '../../helpers/request';

export const useSaveDraft = ({
  site,
  data,
  inventory,
  themeConfig,
  options,
  general,
}) => {
  const { mutate, isLoading } = useMutation(saveDraftFn, options);

  const saveDraft = useCallback(() => {
    return mutate({
      siteId: site?.site_id,
      data,
      inventory,
      themeConfig,
      general
    });
  }, [site, mutate, data, inventory, themeConfig, general]);

  return {
    saveDraft,
    isLoading,
  };
};

export function saveDraftFn({
  siteId,
  data,
  inventory,
  themeConfig,
  general,
}) {
  return Promise.all([
    post(
      '/internal/developer/v1.1/files',
      {
        stage: ['draft'],
        fileName: 'data',
        changes: [
          { path: 'pages', value: data.pages },
          { path: 'header', value: data.header },
          { path: 'footer', value: data.footer },
          { path: 'chat', value: data.chat },
          { path: CONTACT_METHODS_PATH, value: getPathValue(data, CONTACT_METHODS_PATH) },
        ],
      },
      siteId
    ),
    post(
      '/internal/developer/v1.1/files',
      {
        stage: ['draft'],
        fileName: 'inventory',
        changes: [{ path: 'typology', value: getSaveTypologies(inventory) }],
      },
      siteId
    ),
    themeConfig
      ? put(
        `/internal/developer/v1/themes/draft/${themeConfig.theme}/${themeConfig.version}`,
        {},
        siteId,
      )
      : null,
    themeConfig
      ? put('/internal/settings/v1/settings/showroom/config',
        {
          draft_theme: themeConfig.theme,
          draft_theme_version: themeConfig.version,
          draft_theme_custom_colors: themeConfig.customColors
        },
        siteId,
      ) : null,
    general ? post(
      '/internal/developer/v1.1/files',
      {
        stage: ['draft'],
        fileName: 'general',
        changes: [{
          path: "custom_colors",
          value: general.custom_colors,
        }],
      },
      siteId
    ) : null,
  ]);
}

function getSaveTypologies(inventory) {
  const items = Object.entries(inventory.typology.items).reduce(
    (obj, [id, fields]) => {
      obj[id] = { ...fields, id };
      return obj;
    },
    {},
  );
  return { ...inventory.typology, items };
}
