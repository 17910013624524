import React from "react"
import { useCallback } from "react"
import { useRef } from "react"
import { useMemo } from "react"
import { useEffect } from "react"
import { useState } from "react"
import styled from "styled-components"
import { getHslValues } from "../../../helpers/colors"
import useHandleOutsideClick from "../../../hooks/useHandleOutsideClick"
import HslPicker from "../../../modules/HslPicker/HslPicker"

const Container = styled.div`
  position: relative;
  width: fit-content;
`

const Box = styled.button`
  background: ${p => p.color};
  border-radius: 4px;
  width: 55px;
  height: 70px;
  border: 0;
  cursor: pointer;
  border: 1px solid #eeeeee;
`

const PickerContainer = styled.div`
  position: fixed;
  left: ${p => p.left}px;
  top: ${p => p.top}px;
  z-index: 1;
`

const ColorHSLPickerBox = ({ color, onChange }) => {
  const [viewPicker, setViewPicker] = useState(false)
  const [pickerPosition, setPickerPosition] = useState({ top: 0, right: 0 })
  const pickerRef = useHandleOutsideClick(() => setViewPicker(false))
  const boxRef = useRef(null)

  const hslValues = useMemo(() => {
    const values = getHslValues(color)
    if (values) {
      return {
        h: values[0],
        s: values[1],
        l: values[2],
      }
    }
    return { h: 0, s: 0, l: 0, }
  }, [color])

  useEffect(() => {
    if (boxRef?.current) {
      const viewportOffset = boxRef.current.getBoundingClientRect();

      setPickerPosition({
        top: viewportOffset.top,
        left: viewportOffset.right + 10,
      })
    }
  }, [boxRef])

  const togglePicker = useCallback(() => setViewPicker(p => !p), [])

  const handleColorChange = useCallback((h, s, l) => {
    onChange(`hsl(${h}, ${s}%, ${l}%)`)
  }, [onChange])

  return (
    <Container>
      <Box color={color} onClick={togglePicker} ref={boxRef} />
      {viewPicker && (
        <PickerContainer ref={pickerRef} top={pickerPosition.top} left={pickerPosition.left}>
          <HslPicker h={hslValues.h} s={hslValues.s} l={hslValues.l} onChange={handleColorChange} />
        </PickerContainer>
      )}
    </Container>
  )
}

export default ColorHSLPickerBox
