import { Button, Typography } from "@mui/material"
import React, { useCallback, useState } from "react"
import { useEffect } from "react"
import styled from "styled-components"
import { FlexContainer, Spacer } from "../../../components"
import { THEME_TYPES } from "../../../helpers/themes"
import { RightSidebar, SelectableCard } from "../../../modules"

const Container = styled(FlexContainer)`
  padding: 64px 32px;
`

const SelectButton = styled(Button)`
  width: 200px;
  align-self: center;
`

const ThemesSidebar = ({ open, onClose, onSelect, currentTheme }) => {
  const [selectedTheme, setSelectedTheme] = useState(currentTheme)

  useEffect(() => setSelectedTheme(currentTheme), [currentTheme])

  const handleSelect = useCallback(() => {
    onSelect(selectedTheme)
    onClose()
  }, [onSelect, selectedTheme, onClose])

  const handleThemeSelect = useCallback((theme) => {
    setSelectedTheme(theme?.value)
  }, [])
  
  return (
    <RightSidebar open={open} onClose={onClose}>
      <Container vertical>
        <Typography variant="h5">¿Quieres cambiar el diseño de tu showroom?</Typography>
        <Spacer vertical size={2} />
        <Typography>Mira estas opciones:</Typography>
        <Spacer vertical size={2} />
        <FlexContainer fullWidth>
          {THEME_TYPES.map((theme) => (
            <SelectableCard
              key={theme.value}
              imageSrc={theme.imageSrc}
              label={theme.label}
              value={theme}
              isSelected={selectedTheme === theme.value}
              onSelect={handleThemeSelect}
              isACardSelected={selectedTheme != null}
            />
          ))}
        </FlexContainer>
        <Spacer vertical size={30} />
        <SelectButton
          color="primary"
          variant="contained"
          disabled={!selectedTheme}
          onClick={handleSelect}
        >
          Guardar Cambios
        </SelectButton>
      </Container>
    </RightSidebar>
  )
}

export default ThemesSidebar
