import { Alert, Button, List, styled, Typography } from "@mui/material"
import errorFancyIcon from '../../assets/svgs/error_fancy.svg'
import { Buttons, Container, Item, Logo, Panel, Title } from "./PaymentResultCommons"
import ClearIcon from '@mui/icons-material/Clear'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import { Spacer } from "../../components"
import { useCallback, useEffect, useState } from "react"
import smartlookClient from 'smartlook-client'
import { useProductFruits } from 'react-product-fruits'
import { trackEvent } from "../../helpers/mixpanel"

const COMMON_ERRORS = [
  { text: "Saldo insuficiente en tu tarjeta" },
  { text: "Los datos ingresados son incorrectos" },
  { text: "No es posible realizar pagos en línea con tu tarjeta" },
  { text: "La vigencia de tu tarjeta expiró" },
  { text: "Puedes comunicarte con tu banco para conocer la razón exacta", noIcon: true },
]

const StyledAlert = styled(Alert)`
  @media (max-width: 480px) {
    flex-direction: column;
  }
`

const PaymentFailure = () => {
  const [productFruits, setProductFruits] = useState()
  useProductFruits(setProductFruits, []);

  const handleContact = useCallback(() => {
    if (productFruits) productFruits.feedback.showModal()
  }, [productFruits])

  useEffect(() => {
    smartlookClient.track('payment-failure')
    trackEvent('payment-failure')
  }, [])

  return (
    <Container fullWidth>
      <Panel>
        <Logo />
        <Spacer vertical size={1.5} />
        <Title variant='h3'>Tu pago no pudo ser procesado <img src={errorFancyIcon} alt='error' /></Title>
        <Spacer vertical size={1.5} />
        <Typography>Calma, quizá solo es un pequeño contratiempo</Typography>
        <Spacer vertical size={4} />
        <StyledAlert 
          icon={<SupportAgentIcon/>} 
          severity="info"
          action={
            <Button color="inherit" size="small" onClick={handleContact}>
              Contáctanos
            </Button>
          }
        >
          ¿Necesitas ayuda? Nuestro equipo puede guiarte paso a paso
        </StyledAlert>
        <Spacer vertical size={4} />
        <Typography variant='h6'>¿Qué pudo haber ocurrido?</Typography>
        <Spacer vertical size={4} />
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {COMMON_ERRORS.map((error, index) => <Item key={index} text={error.text} icon={!error.noIcon && <ClearIcon color="error" />} />)}
        </List>
        <Spacer vertical size={4} />
        <Buttons />
      </Panel>
    </Container>
  )
}

export default PaymentFailure