import { useEffect, useState } from "react";

const useItemsActiveHref = (items, scrollListener = window) => {
  const [itemsActiveHref, setItemsActiveHref] = useState();

  useEffect(() => {
    if (!scrollListener) return;
    const scrollHandler = () => {
      // An active section can be defined as an element that:
      // 1. Its top boundary is over the middle viewport horizontal line
      // 2. Its bottom boundary is under the middle viewport horizontal line
      const middleLineDist = window.innerHeight * 0.5;
      for (const item of items) {
        const itemHrefId = item.href;
        if (!itemHrefId) continue;
        const node = document.getElementById(itemHrefId);
        if (!node) continue;
        const { top: nodeTopDist, bottom: nodeBottomDist } =
          node.getBoundingClientRect();
        if (nodeTopDist <= middleLineDist && nodeBottomDist >= middleLineDist)
          return setItemsActiveHref(item?.href);
      }
      // If no element satifies the conditions to be considered active section,
      // set the actual to undefined
      setItemsActiveHref(undefined);
    };
    scrollListener.addEventListener("scroll", scrollHandler);
    return () => scrollListener.removeEventListener("scroll", scrollHandler);
  }, [items, scrollListener]);

  return itemsActiveHref;
};

export default useItemsActiveHref;
