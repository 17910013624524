import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Chip, CircularProgress, FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, Stack, TextField, Typography } from '@mui/material';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import devices from '../../assets/images/devices.png';
import { FlexContainer, Spacer } from '../../components';
import { subdomainRegex } from '../../helpers/regex';
import { get } from '../../helpers/request';
import useOnboardingContext from '../../hooks/useOnboardingContext';
import PublicLayout from '../../layouts/PublicLayout';

const UNITS_QTY = ['2-10', '10-50', '51-100', '+100']
const VERIFY_DOMAIN_API = "/internal/login/v1/onboarding/verify-domain?domain="
const DEVELOPMENT_URL = ".alohome.io"

const INPUT_VALIDATION = {
  required: 'El campo es obligatorio',
  notAvailable: '*No disponible',
  valid: '*Disponible',
}

const SUBDOMAIN_HELPER = {
  info: 'Más adelante, al elegir un plan, podrás personalizar la dirección web',
  invalid: 'El dominio no debe contener espacios o caracteres especiales'
}

const verifyDomainAPI = (domain) => {
  if (domain !== '') {
    return get(`${VERIFY_DOMAIN_API}${domain}${DEVELOPMENT_URL}`)
  }
  return null;
}

const verifyDomainDebounced = AwesomeDebouncePromise(verifyDomainAPI, 500)

const Container = styled(FlexContainer)`
  padding: 30px 142px;
`

const NameInput = styled(TextField)`
  width: 338px;
`

const DomainInput = styled(OutlinedInput)`
  width: 338px;
  ${p => p.available && css`
    fieldset {
      border-color: ${p => p.theme.palette.primary.main};
    },
    &.Mui-focused fieldset {
      border-color: ${p => p.theme.palette.primary.main};
    }
  `}
`

const DomainHelperText = styled(FormHelperText)`
  width: 310px;
  ${p => p.available && css`
    color: ${p => p.theme.palette.primary.main};
  `}
`

const DeviceImage = styled.img`
  width: 566px;
  object-fit: contain;
  align-self: baseline;
  margin-top: 16px;
`

const DomainLabel = styled(InputLabel)`
  ${p => p.available && css`
    color: ${p => p.theme.palette.primary.main};
  `}
`

const ProjectForm = () => {
  const { onboardingData, setOnboardingData, onNextStep } = useOnboardingContext()
  const [projectData, setProjectData] = useState({
    projectName: { value: onboardingData.projectName ?? '' },
    subdomain: {
      value: onboardingData.subdomain ?? '',
      available: Boolean(onboardingData.subdomain) || undefined,
      label: Boolean(onboardingData.subdomain) ? INPUT_VALIDATION.valid : undefined,
      helperText: SUBDOMAIN_HELPER.info,
    },
    unitsQty: { value: onboardingData.unitsQty ?? '' },
  })
  const [canBeSubmitted, setCanBeSubmitted] = useState(false);

  const handleProjectNameChange = useCallback((e) => {
    setProjectData(d => ({
      ...d,
      projectName: { value: e.target.value },
    }))
  }, [setProjectData])
  
  const handleProjectNameBlur = useCallback((e) => {
    if (e.target.value.trim() === '') {
      setProjectData(d => ({
        ...d,
        projectName: { value: e.target.value, error: true },
      }))
    }
  }, [setProjectData])

  const setInvalidSubdomain = useCallback((domain) => {
    setProjectData(d => ({
      ...d,
      subdomain: {
        value: domain,
        error: true,
        loading: false,
        available: false,
        label: INPUT_VALIDATION.notAvailable,
        helperText: SUBDOMAIN_HELPER.info,
      },
    }))
  }, [])

  const handleSubdomainChange = useCallback(async (e) => {
    const domain = e.target.value.trim()
    setProjectData(d => ({
      ...d,
      subdomain: { value: domain, helperText: SUBDOMAIN_HELPER.info, },
    }))
    if (domain !== "") {
      try {
        if (!subdomainRegex.test(domain)) {
          setProjectData(d => ({
            ...d,
            subdomain: {
              value: domain,
              available: false,
              error: true,
              label: INPUT_VALIDATION.notAvailable,
              helperText: SUBDOMAIN_HELPER.invalid,
            },
          }))
          return
        }

        setProjectData(d => ({
          ...d,
          subdomain: { value: domain, loading: true, helperText: SUBDOMAIN_HELPER.info, },
        }))
        const res = await verifyDomainDebounced(domain)
        if (res.available) {
          setProjectData(d => ({
            ...d,
            subdomain: {
              value: domain,
              loading: false,
              available: true,
              label: INPUT_VALIDATION.valid,
              helperText: SUBDOMAIN_HELPER.info,
            },
          }))
        } else {
          setInvalidSubdomain(domain)
        }
      } catch (e) {
        setInvalidSubdomain(domain)
      }
    }
  }, [setInvalidSubdomain])

  const handleSubdomainBlur = useCallback(async (e) => {
    const domain = e.target.value.trim()
    if (domain === '') {
      setProjectData(d => ({
        ...d,
        subdomain: {
          value: domain,
          error: true,
          label: INPUT_VALIDATION.required,
          helperText: SUBDOMAIN_HELPER.info,
        },
      }))
    }
  }, [])

  const handleUnitsQtyClick = useCallback((unitsQty) => {
    setProjectData(d => ({
      ...d,
      unitsQty: { value: unitsQty },
    }))
  }, [setProjectData])

  const ValidateForm = useCallback(() => {
    let isValid = true
    let validatedData = {...projectData}
    Object.keys(validatedData).forEach((key) => {
      if (validatedData[key].value.trim() === "") {
        validatedData[key] = {
          ...validatedData[key],
          error: true,
          label: INPUT_VALIDATION.required,
        }
        isValid = false
      } else if (key === "subdomain" && !validatedData[key].available) {
        isValid = false
      }
    })
    if (!isValid) {
      setProjectData(validatedData)
    }
    return isValid
  }, [projectData])

  const handleNext = useCallback(() => {
    if (ValidateForm()) {
      setOnboardingData(d => ({
        ...d,
        projectName: projectData.projectName.value,
        subdomain: projectData.subdomain.value,
        unitsQty: projectData.unitsQty.value,
      }))
      onNextStep()
    }
  }, [ValidateForm, onNextStep, projectData.projectName.value, projectData.subdomain.value, projectData.unitsQty.value, setOnboardingData])
  
  useEffect(() => {
    if(projectData.projectName.value &&
      projectData.subdomain.value &&
      projectData.unitsQty.value) return setCanBeSubmitted(true)
    return setCanBeSubmitted(false)
  }, [projectData.projectName.value, projectData.subdomain.value, projectData.unitsQty.value])

  return (
    <PublicLayout
      primaryLabel="Sigamos"
      primaryIcon={<ArrowForwardIcon />}
      onPrimaryClick={handleNext}
      currentStep={1}
      numberOfSteps={5}
      disableNextStep={!canBeSubmitted}
    >
      <Container vertical>
        <Typography variant='h2'>¡Crea el showroom que tu proyecto merece!</Typography>
        <Spacer vertical size={8} />
        <Typography variant='h5'>A continuación, te haremos unas breves preguntas.</Typography>
        <Typography variant='h5'>Al finalizar, tendrás un showroom digital hecho a tu medida (y 100% editable).</Typography>
        <Spacer vertical size={3} />
        <FlexContainer justified>
          <FlexContainer vertical>
            <Typography>¿Cuál es el nombre de tu proyecto?</Typography>
            <Spacer vertical size={1} />
            <FormControl variant="filled" fullWidth>
              <NameInput
                size='medium'
                variant="outlined"
                placeholder='Nombre del proyecto'
                value={projectData.projectName.value}
                fullWidth
                onChange={handleProjectNameChange}
                onBlur={handleProjectNameBlur}
    	          error={projectData.projectName.error}
                label={projectData.projectName.error ? "Es campo es obligatorio" : undefined}
              />
            </FormControl>
            <Spacer vertical size={5} />
            <Typography>La página web de tu showroom digital será...</Typography>
            <Spacer vertical size={1} />
            <FormControl variant="filled" fullWidth>
              <FlexContainer verticalCentered>
                <DomainLabel
                  htmlFor="subdomain-input"
                  variant="outlined"
                  error={projectData.subdomain.error}
                  available={projectData.subdomain.available}
                >
                  {projectData.subdomain.label}
                </DomainLabel>
                <DomainInput
                  id="subdomain-input"
                  size='medium'
                  value={projectData.subdomain.value}
                  fullWidth
                  onChange={handleSubdomainChange}
                  onBlur={handleSubdomainBlur}
                  endAdornment={<InputAdornment position="end">.alohome.io</InputAdornment>}
                  error={projectData.subdomain.error}
                  label={projectData.subdomain.label}
                  available={projectData.subdomain.available}
                />
                {projectData.subdomain.loading && (
                  <>
                    <Spacer size={1} />
                    <CircularProgress size={20} />
                  </>
                )}
              </FlexContainer>
              <DomainHelperText error={projectData.subdomain.error} available={projectData.subdomain.available}>
                {projectData.subdomain.helperText}
              </DomainHelperText>
            </FormControl>
            <Spacer vertical size={5} />
            <Typography>¿Cuántas unidades tiene tu proyecto?</Typography>
            <Spacer vertical size={1} />
            <Stack direction="row" spacing={1}>
              {UNITS_QTY.map(u => (
                <Chip key={u} label={u} color={u === projectData.unitsQty.value ? 'primary' : 'default'} onClick={() => handleUnitsQtyClick(u)} />
              ))}
            </Stack>
            {projectData.unitsQty.error && (
              <>
                <Spacer vertical size={1} />
                <Typography color="error">Es campo es obligatorio</Typography>
              </>
            )}
          </FlexContainer>
          <DeviceImage src={devices} alt="devices" />	
        </FlexContainer>
      </Container>
    </PublicLayout>
  )
}

export default ProjectForm
