export const TRANSPARENCY_PAGE_PATH = 'pages.transparency';
export const NOM_PATH = 'custom_data.NOM247';
export const CONTACT_METHODS_PATH = 'contact_methods';

export function getPathAsKeys(path) {
  // Replace index notation (obj[0].prop) for dot (obj.0.prop)
  const dotSeparatedFullPath = path.replace(/\[(.+?)\]/g, ".$1");
  return dotSeparatedFullPath.split(".");
}

export function getKeysAsPath(keys) {
  const dotSeparatedBasePath = keys.join(".");
  // Replace dot notation (obj.0.prop) for index (obj[0].prop)
  return dotSeparatedBasePath.replace(/\.(\d+?)/g, "[$1]");
}

/**
 * Mutates the data object setting the value on the specified path
 * @param {object} data 
 * @param {Array<string | number> | string} path The path to modify. Can be a
 * string that follows the path convention (dot separated and index notation
 * for numbers) or an array of indexes (numbers and strings array).
 * @param {any} value 
 * @param {{ recursive?: boolean }} options Set recursive to `true` to create
 * path objects if not found
 */
export function modifyPath(data, path, value, options = { recursive: true }) {
  const keys = typeof path === 'string' ? getPathAsKeys(path) : path;
  let objCursor = data;
  const lastKey = keys[keys.length - 1];
  for (const key of keys.slice(0, keys.length - 1)) {
    if (typeof objCursor !== 'object' || objCursor === null)
      return { success: false };
    if (
      (typeof objCursor[key] !== 'object' || objCursor[key] === null) &&
      options.recursive !== false
    ) {
      objCursor[key] = {};
    }
    objCursor = objCursor[key];
  }
  objCursor[lastKey] = value;
  return { success: true };
}

/**
 * Returns the value of the data on the specified path
 * @param {object} data 
 * @param {Array<string | number> | string} path The path to modify. Can be a
 * string that follows the path convention (dot separated and index notation
 * for numbers) or an array of indexes (numbers and strings array).
 */
export function getPathValue(data, path) {
  const keys = typeof path === "string" ? getPathAsKeys(path) : path;
  try {
    let objCursor = data;
    const lastKey = keys[keys.length - 1];
    for (const key of keys.slice(0, keys.length - 1)) {
      if (typeof objCursor !== "object") return null;
      objCursor = objCursor[key];
    }
    return objCursor[lastKey];
  } catch {
    return undefined;
  }
}

export function mergePaths(...paths) {
  let mergedPath = [];
  for (const path of paths) {
    const pathArr = typeof path === "string" ? getPathAsKeys(path) : path;
    mergedPath.push(...pathArr);
  }
  return mergedPath;
}

/**
 * Resolves multiple string paths
 */
export function mergePathsStr(...paths) {
  let mergedPath = paths[0];
  for (const path of paths.slice(1, paths.length)) {
    if (path.startsWith("[")) mergedPath += path;
    else if (path !== "") mergedPath += `.${path}`;
  }
  return mergedPath;
}

/**
 * Returns all the paths to objects containing a hubid property with specified
 * slug (a string wrapped by `[]`)
 */
export function findPathsByHubidSlug(data, hubid, path = []) {
  if (typeof data !== "object" || data === null) return null;
  const paths = [];
  if (data.hubid?.includes(`[${hubid}]`)) paths.push([...path]);
  for (const key in data) {
    const keyPaths = findPathsByHubidSlug(data[key], hubid, [...path, key]);
    if (keyPaths) paths.push(...keyPaths);
  }
  return paths;
}

export function getRelativePath(from, to) {
  const fromKeys = typeof from === "string" ? getPathAsKeys(from) : from;
  const toKeys = typeof to === "string" ? getPathAsKeys(to) : to;
  return toKeys.slice(fromKeys.length);
}
