import React from 'react'
import styled, { css } from 'styled-components'
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';

const StyledButton = styled(LoadingButton)`
  width: fit-content;
  position: relative;
  // TODO: Enable when pending changes is available
  /* ${p => p.$showBullet && css`
    &::after {
      content: '';
      width: 8px;
      height: 8px;
      background: #F50057;
      position: absolute;
      border-radius: 50%;
      top: 5px;
      right: 5px;
    }
  `} */
`

const PendingButton = ({ text, onClick, showBullet, ...buttonProps }) => (
  <StyledButton onClick={onClick} disabled={!showBullet} $showBullet={showBullet} startIcon={<SaveIcon />} {...buttonProps}>
    {text}
  </StyledButton>
)

export default PendingButton
