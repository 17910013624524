import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  MenuItem,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useCallback, useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { post, put } from '../../helpers/request';
import useSite from '../../hooks/useSite';
import {
  formatRecordName,
  getRecordFromSubdomainAndRoot,
  getRecordNameSubdomain,
} from './recordNameUtils';

const DEFAULT_TTL = 300;
const REQUIRED_ERROR = 'Campo obligatorio';

const isRecordAutoconfigurable = ({ name, type }, hostedZoneName) => {
  if (name === hostedZoneName && type === 'A') return true;
  return false;
};

const upsertRecordFn = ({ name, type, ttl, values, siteId }) =>
  put(
    '/internal/alohub/config/hosted-zone',
    { name, type, ttl, values },
    siteId
  );

const autoconfigureRecordFn = ({ name, type, siteId }) =>
  post(
    '/internal/alohub/config/hosted-zone/autoconfigure',
    { name, type },
    siteId
  );

const RecordEdition = ({
  initialValues,
  recordTypes,
  hostedZoneName,
  onSuccess,
  onCancel,
}) => {
  const { site } = useSite();
  const siteId = site?.site_id;

  const [showError, setShowError] = useState(false);
  const onError = useCallback(() => setShowError(true), []);

  const { mutate: upsertRecord, isLoading: isLoadingUpsert } = useMutation(
    upsertRecordFn,
    { onSuccess, onError }
  );

  const { mutate: autoconfigureRecord, isLoading: isLoadingAutoconfigure } =
    useMutation(autoconfigureRecordFn, {
      onSuccess,
      onError,
    });

  const onUpsertRecord = useCallback(
    (data) => upsertRecord({ siteId, ...data }),
    [upsertRecord, siteId]
  );

  const onAutoconfigureRecord = useCallback(
    (data) => autoconfigureRecord({ siteId, ...data }),
    [autoconfigureRecord, siteId]
  );

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      subdomain: initialValues?.name
        ? getRecordNameSubdomain(hostedZoneName, initialValues.name)
        : undefined,
      type: initialValues?.type,
      ttl: initialValues?.ttl || DEFAULT_TTL,
      values: initialValues?.values.join('/n'),
    },
    mode: 'onBlur',
  });

  const [autoconfigure, setAutoconfigure] = useState(
    !!initialValues?.autoconfigured
  );

  const onSubmit = useMemo(
    () =>
      handleSubmit((data) =>
        autoconfigure
          ? onAutoconfigureRecord({
              name: getRecordFromSubdomainAndRoot(
                data.subdomain,
                hostedZoneName
              ),
              type: data.type,
            })
          : onUpsertRecord({
              name: getRecordFromSubdomainAndRoot(
                data.subdomain,
                hostedZoneName
              ),
              type: data.type,
              ttl: +data.ttl,
              values: data.values.split('\n').filter((ln) => !!ln),
            })
      ),
    [
      handleSubmit,
      onUpsertRecord,
      onAutoconfigureRecord,
      hostedZoneName,
      autoconfigure,
    ]
  );

  const type = watch('type');
  const subdomain = watch('subdomain');

  const isAutoconfigurable = useMemo(
    () =>
      isRecordAutoconfigurable(
        {
          type,
          name: getRecordFromSubdomainAndRoot(subdomain, hostedZoneName),
        },
        hostedZoneName
      ),
    [type, subdomain, hostedZoneName]
  );

  useEffect(() => {
    if (!isAutoconfigurable) setAutoconfigure(false);
  }, [isAutoconfigurable]);

  const isLoading = isLoadingUpsert || isLoadingAutoconfigure;

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={6_000}
        onClose={() => setShowError(false)}
      >
        <Alert severity="error">
          Ha ocurrido un error. Por favor revisa la configuración e inténtalo
          más tarde
        </Alert>
      </Snackbar>
      <Box
        component="form"
        onSubmit={onSubmit}
        sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
      >
        <TextField
          label="Record name"
          inputProps={register('subdomain')}
          error={!!errors.subdomain}
          helperText={
            errors.subdomain?.message ||
            'Manten en blanco para crear un record con el dominio raíz'
          }
          placeholder="subdominio"
          InputProps={{
            endAdornment: (
              <Typography variant="body2">
                {formatRecordName(hostedZoneName)}
              </Typography>
            ),
          }}
        />
        <Controller
          control={control}
          name="type"
          rules={{ required: REQUIRED_ERROR }}
          render={({ field }) => (
            <TextField
              label="Tipo"
              error={!!errors.type}
              helperText={errors.type?.message || ' '}
              select
              {...field}
            >
              {recordTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        {isAutoconfigurable && (
          <FormControlLabel
            label="Conectar a Alohome"
            control={
              <Switch
                checked={autoconfigure}
                onChange={(_, v) => setAutoconfigure(v)}
              />
            }
          />
        )}
        {!autoconfigure && (
          <>
            <TextField
              label="TTL"
              inputProps={register('ttl', {
                required: REQUIRED_ERROR,
                pattern: {
                  message: 'Debe ser un número',
                  value: /[0-9]+/,
                },
                min: { message: 'El valor mínimo es 120', value: 120 },
              })}
              error={!!errors.ttl}
              helperText={errors.ttl?.message || ' '}
            />
            <TextField
              label="Valores"
              inputProps={register('values', {
                required: REQUIRED_ERROR,
              })}
              error={!!errors.values}
              helperText={errors.values?.message || ' '}
              multiline
              rows={6}
            />
          </>
        )}
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            onClick={onCancel}
            variant="outlined"
            color="secondary"
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isLoading}
          >
            Guardar
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default RecordEdition;
