import React from 'react'
import PropTypes from 'prop-types'
import { Paper } from '@mui/material'
import styled from 'styled-components'

const Container = styled.div`
  display:flex;
  width: ${props => `calc(calc(20% * ${props.width}) - 24px)`};
  padding: 12px;
`

const Content = styled.div`
  padding: 32px;
  height: 100%;
  display:flex;
  flex-direction: column;
`

const StyledPaper = styled(Paper)`
  width: 100%;
`

const WidtgetContainer = ({ children, width }) => {
  return (
    <Container
      width={width}>
      <StyledPaper
        variant="outlined">
        <Content>
          {
            children
          }
        </Content>
      </StyledPaper>
    </Container>
  )
}

WidtgetContainer.propTypes = {
  width: PropTypes.oneOf([1, 2, 3, 4, 5]).isRequired,
  children: PropTypes.element.isRequired
}

export default WidtgetContainer
