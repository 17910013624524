import React, { useState } from 'react';
import { 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from '@mui/material';
import { FlexContainer, Spacer } from '../../components';
import { getCurrentInputDate } from './utils';

const SoldDateModal = ({ open, onSave, onCancel, showReserved, showSold }) => {
  const [soldDate, setSoldDate] = useState(() => getCurrentInputDate())
  const [reservedDate, setReservedDate] = useState(() => getCurrentInputDate())

  const handleSave = () => {
    setSoldDate(getCurrentInputDate())
    setReservedDate(getCurrentInputDate())
    onSave(reservedDate, soldDate)
  }

  const handleCancel = () => {
    setSoldDate(getCurrentInputDate())
    setReservedDate(getCurrentInputDate())
    onCancel()
  }

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>¿Cuándo ocurrieron estos eventos?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Para mejorar la capacidad de seguimiento del inventario, díganos cuándo ocurrieron estos eventos.
        </DialogContentText>
        <Spacer size={8} />
        <FlexContainer justified>
          {showReserved && (
            <TextField
              autoFocus
              label="Fecha de Reserva"
              type="date"
              value={reservedDate}
              onChange={e => setReservedDate(e.target.value)}
            />
          )}
          {showSold && (
            <TextField
              autoFocus
              label="Fecha de Venta"
              type="date"
              value={soldDate}
              onChange={e => setSoldDate(e.target.value)}
            />
          )}
        </FlexContainer>
        <Spacer size={8} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SoldDateModal
