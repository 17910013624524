import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FlexContainer } from '../'
import { Typography } from '@mui/material'

const DateInput = styled.input`
  max-width: 100px;
  border: none;
  margin:0;
  margin: 0 12px;
  font-family: 'Roboto';
  &::-webkit-calendar-picker-indicator {
   width:16px;
   margin:0;
   padding:0;
  }
  &:focus {
    outline: none;
  }
`

const DateRange = ({ beginDate, endDate, handleBeginDateChange, handlEndDateChange }) => {
  return (
    <FlexContainer>
      <FlexContainer vertical>
        <Typography variant="caption" align="center">Inicio</Typography>
        <DateInput
          type="date"
          value={beginDate.substring(0, 10)}
          onChange={handleBeginDateChange}
        />
      </FlexContainer>
      <Typography variant="overline" align="center">-</Typography>
      <FlexContainer vertical>
        <Typography variant="caption" align="center">Fin</Typography>
        <DateInput
          type="date"
          value={endDate.substring(0, 10)}
          onChange={handlEndDateChange}
        />
      </FlexContainer>
    </FlexContainer>
  )
}

DateRange.propTypes = {
  beginDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  handleBeginDateChange: PropTypes.func.isRequired,
  handlEndDateChange: PropTypes.func.isRequired
}

export default DateRange
