import React from 'react'
import PropTypes from 'prop-types'
import { FlexContainer, CaptionedData, Spacer } from '../'
import { Typography } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import styled from 'styled-components'

const Line = styled.hr`
  height:25%;
  width: 1px;
  color: ${props => props.color};
  margin: 0 48px;
`

const CompareData = ({ dataSize, dataA, dataB, childrenA, childrenB }) => {
  const dataToCompare = [dataA, dataB]
  const theme = useTheme()
  return (
    <FlexContainer>
      {
        dataToCompare.map(({ topCaption, data, bottomCaption }, index) => (
          <React.Fragment key={`${topCaption}-${index}`}>
            <FlexContainer vertical centered>
              <CaptionedData topCaption={topCaption} data={data || ''} dataSize={dataSize} bottomCaption={bottomCaption} />
              {index === 0 ? (
                <>
                  <Spacer size={2} vertical />
                  {childrenA}
                </>
              ) :
                (
                  <>
                    <Spacer size={2} vertical />
                    {childrenB}
                  </>
                )}
            </FlexContainer>
            {index === 0 && (<FlexContainer vertical centered stretched verticalCentered>
              <Line color={theme.palette.grey[300]} />
              <Typography variant="overline" color="textSecondary">VS</Typography>
              <Line color={theme.palette.grey[300]} />
            </FlexContainer>)}
          </React.Fragment>
        ))
      }

    </FlexContainer>
  )
}

CompareData.defaultProps = {
  dataSize: "small"
}

CompareData.propTypes = {
  dataSize: PropTypes.oneOf(["small", "medium", "large"]).isRequired,
  dataA: PropTypes.shape({
    topText: PropTypes.string,
    dataText: PropTypes.string,
    bottomText: PropTypes.string
  }).isRequired,
  dataB: PropTypes.shape({
    topText: PropTypes.string,
    dataText: PropTypes.string,
    bottomText: PropTypes.string
  }).isRequired,
  childrenA: PropTypes.element,
  childrenB: PropTypes.element
}

export default CompareData
