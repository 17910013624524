import styled from 'styled-components';
import { FlexContainer, Spacer } from '../../components';
import { TypologyCard } from './TypologyCard';
import { numberToCurrency } from '../../helpers/utils';
import { ReactComponent as OutOfRangeIcon } from '../../assets/icons/information_icon.svg';
import { Slider, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

const CustomDataContainer = styled(FlexContainer)`
  width: 400px;
`;

const currenyFormatOptions = {
  style: 'currency',
  currency: 'MXN',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
};
const CustomSlider = styled(Slider)`
  .MuiSlider-valueLabelOpen {
    transform: translateY(130%) scale(1);
    &::before {
      top: -30%;
      bottom: unset;
    }
  }
`;

export const ReportRow = ({ data, locale, projectType }) => {
  const [selectedUnit, setSelectedUnit] = useState();
  const customData = useMemo(() => {
    if (projectType === 'typology') {
      return data;
    } else {
      return selectedUnit;
    }
  }, [data, projectType, selectedUnit]);

  const price = useMemo(
    () =>
      projectType === 'typology'
        ? customData?.fields?.price?.MXN
        : customData?.fields?.prices?.MXN,
    [
      customData?.fields?.price?.MXN,
      customData?.fields?.prices?.MXN,
      projectType,
    ]
  );

  const minPrice = useMemo(
    () => customData?.report?.min_price,
    [customData?.report?.min_price]
  );
  const averagePrice = useMemo(
    () => customData?.report?.price,
    [customData?.report?.price]
  );
  const maxPrice = useMemo(
    () => customData?.report?.max_price,
    [customData?.report?.max_price]
  );

  const priceM2 = useMemo(
    () => price / customData?.fields?.interior_m2,
    [customData?.fields?.interior_m2, price]
  );

  const minPriceM2 = useMemo(
    () => minPrice / customData?.fields?.interior_m2,
    [customData?.fields?.interior_m2, minPrice]
  );

  const averagePriceM2 = useMemo(
    () => averagePrice / customData?.fields?.interior_m2,
    [customData?.fields?.interior_m2, averagePrice]
  );

  const maxPriceM2 = useMemo(
    () => maxPrice / customData?.fields?.interior_m2,
    [customData?.fields?.interior_m2, maxPrice]
  );

  const maxSlider = useMemo(() => maxPrice - minPrice, [maxPrice, minPrice]);

  const sliderPriceValue = useMemo(() => price - minPrice, [price, minPrice]);

  const maxSliderM2 = useMemo(
    () => maxPriceM2 - minPriceM2,
    [maxPriceM2, minPriceM2]
  );

  const sliderPriceValueM2 = useMemo(
    () => priceM2 - minPriceM2,
    [priceM2, minPriceM2]
  );

  const percentOverAverage = useMemo(
    () => ((price * 100) / averagePrice - 100).toFixed(2),
    [price, averagePrice]
  );

  const percentOverAverageM2 = useMemo(
    () => ((priceM2 * 100) / averagePriceM2 - 100).toFixed(2),
    [priceM2, averagePriceM2]
  );

  const percentBelowAverage = useMemo(
    () => (100 - (price * 100) / averagePrice).toFixed(2),
    [price, averagePrice]
  );

  const percentBelowAverageM2 = useMemo(
    () => (100 - (priceM2 * 100) / averagePriceM2).toFixed(2),
    [priceM2, averagePriceM2]
  );

  const priceFormated = useMemo(
    () => (+price)?.toLocaleString('es-MX', currenyFormatOptions),
    [price]
  );

  const priceM2Formated = useMemo(
    () => (+priceM2)?.toLocaleString('es-MX', currenyFormatOptions),
    [priceM2]
  );

  const priceIsInRange = useMemo(
    () => price >= minPrice && price <= maxPrice,
    [price, minPrice, maxPrice]
  );

  const priceM2IsInRange = useMemo(
    () => priceM2 >= minPriceM2 && priceM2 <= maxPriceM2,
    [priceM2, minPriceM2, maxPriceM2]
  );

  useEffect(() => {
    setSelectedUnit(projectType === 'unit' && data?.units[0]);
  }, [data, projectType]);

  const handleSelectUnit = (value) => {
    setSelectedUnit(value.target.value);
  };

  return !customData ? null : (
    <>
      <FlexContainer>
        <TypologyCard
          units={data?.units}
          data={customData}
          locale={locale}
          projectType={projectType}
          selectedUnit={selectedUnit}
          handleSelectUnit={handleSelectUnit}
        />
        <Spacer size={8} />
        <CustomDataContainer vertical verticalCenter>
          {(!priceIsInRange || !priceM2IsInRange) && (
            <>
              <FlexContainer>
                <OutOfRangeIcon />
                <Spacer size={1} />
                <Typography color={'#E31B0C'}>
                  Fuera de los precios estimados
                </Typography>
              </FlexContainer>
              <Spacer vertical size={2} />
            </>
          )}
          <FlexContainer vertical centered>
            <Typography variant="body1">
              Estimado de valor total de venta
            </Typography>
          </FlexContainer>
          <FlexContainer justified>
            <FlexContainer vertical>
              <Typography variant="caption">Límite inferior</Typography>
              <Typography variant="caption">
                ${numberToCurrency(roundToThousands(minPrice))}
              </Typography>
            </FlexContainer>
            <FlexContainer vertical centered>
              <Typography variant="body1" color={'var(--color-primary)'}>
                ${numberToCurrency(Math.round(averagePrice))}
              </Typography>
            </FlexContainer>
            <FlexContainer vertical>
              <Typography variant="caption">Límite superior</Typography>
              <Typography variant="caption">
                ${numberToCurrency(roundToThousands(maxPrice))}
              </Typography>
            </FlexContainer>
          </FlexContainer>
          <CustomSlider
            max={maxSlider}
            min={0}
            value={sliderPriceValue}
            valueLabelDisplay="on"
            valueLabelFormat={
              percentOverAverage > 0
                ? `El precio de la unidad ${priceFormated} (+ ${percentOverAverage}%)`
                : `El precio de la unidad ${priceFormated} (- ${percentBelowAverage}%)`
            }
          />
          <Spacer size={4} vertical />
          <FlexContainer vertical centered>
            <Typography variant="body1">Estimado de valor por m²</Typography>
          </FlexContainer>
          <FlexContainer justified>
            <FlexContainer vertical>
              <Typography variant="caption">Límite inferior</Typography>
              <Typography variant="caption">
                ${numberToCurrency(roundToThousands(minPriceM2))}
              </Typography>
            </FlexContainer>
            <FlexContainer vertical centered>
              <Typography variant="body1" color={'var(--color-primary)'}>
                ${numberToCurrency(Math.round(averagePriceM2))}
              </Typography>
            </FlexContainer>
            <FlexContainer vertical>
              <Typography variant="caption">Límite superior</Typography>
              <Typography variant="caption">
                ${numberToCurrency(roundToThousands(maxPriceM2))}
              </Typography>
            </FlexContainer>
          </FlexContainer>
          <CustomSlider
            max={maxSliderM2}
            min={0}
            value={sliderPriceValueM2}
            valueLabelDisplay="on"
            valueLabelFormat={
              percentOverAverageM2 > 0
                ? `El precio por m² de la unidad ${priceM2Formated} (+ ${percentOverAverageM2}%)`
                : `El precio por m² de la unidad ${priceM2Formated} (- ${percentBelowAverageM2}%)`
            }
          />
          <Spacer size={6} vertical />
          <Typography variant="body1" color={'var(--color-text-secondary)'}>
            * Generamos este reporte a través de un proceso automatizado que
            compara las características del inmueble ingresado con millones de
            propiedades y realiza una estimación calibrada utilizando
            información de precios de oferta, redondeando el valor a la unidad
            de millar más cercana.
          </Typography>
        </CustomDataContainer>
      </FlexContainer>
      <Spacer size={6} vertical />
    </>
  );
};

function roundToThousands(num) {
  return Math.round(num / 1_000) * 1_000
}
