import { forwardRef } from 'react';
import styled from 'styled-components';

const SIZES = [480, 640, 768, 1024, 1366, 1600]

const generateSourceSet = (url, sizes = SIZES) => {
  if (!url) return null;
  const index = url.indexOf('?')
  const newUrl = index >= 0 ? url.substring(0, index) : url
  return sizes.map(width => `${newUrl}?s=${width}x1&fit=contain ${width}w`).join(', ')
}

const generateSizes = (sizes = SIZES) => {
  return sizes.map((width, index) => index < SIZES.length - 1 ? `(max-width: ${width}px) ${width}px` : `${width}px`).join(', ')
}

export const StyledImage = styled('img')(
  (props) => (props.cover || props.fullwidth) && 'object-fit: cover;',
  (props) => props.fit && `object-fit: ${props.fit};`,
  (props) => props.position && `object-position: ${props.position};`,
  (props) => props.fullwidth && 'width: 100%;',
);

const Image = forwardRef(({ src, alt, loading, ...props }, ref) => {
  return (
    <StyledImage
      ref={ref}
      src={`${src}?s=330x300`}
      // srcSet={generateSourceSet(src)} 
      // sizes={generateSizes()}
      loading={loading ?? 'lazy'} 
      alt={alt || ''} 
      {...props} 
    />
  )
});

Image.defaultProps = {
  fullwidth: false,
  cover: false,
  alt: '',
};

Image.displayName = 'Image'

export default Image;
