import { Image as ImageIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  styled,
  Typography
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { MaxCharsInput } from "../../../components";
import useAssetsNames from "../../../hooks/useAssetsNames";
import { HoverCardMedia, ImageGallery } from "../../../modules";

const CardButton = styled(CardActionArea)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  background: "rgba(0, 0, 0, 0.04)",
  border: "1px dashed rgba(0, 0, 0, 0.26)",
  borderRadius: 4,
});

const StyledImageIcon = styled(ImageIcon)({
  width: "3rem",
  height: "3rem",
});

const SimpleImage = ({
  pathData = {},
  setPathData,
  maxChars,
  textKey = "alt",
  textFieldLabel = "Texto alternativo",
  onDelete,
}) => {
  const [showGallery, setShowGallery] = useState(false);

  const openGallery = useCallback(() => setShowGallery(true), []);
  const closeGallery = useCallback(() => setShowGallery(false), []);

  const text = pathData[textKey];
  const url = pathData.url;
  const assetId = pathData.assetId;

  const { assetsNames } = useAssetsNames({ ids: assetId ? [assetId] : [] });

  const onTextChange = useCallback(
    (value) => {
      if (!textKey) return;
      setPathData({ ...pathData, [textKey]: value });
    },
    [pathData, setPathData, textKey]
  );

  const onImageSelected = useCallback(
    (assets) => {
      const { assetId, url } = assets[0];
      setPathData({ ...pathData, assetId, url });
      closeGallery();
    },
    [pathData, setPathData, closeGallery]
  );

  return (
    <>
      <ImageGallery
        open={showGallery}
        onClose={closeGallery}
        onSelected={onImageSelected}
      />
      <Card sx={{ mb: 2 }}>
        {url ? (
          <>
            <HoverCardMedia
              src={url}
              alt={text}
              height={140}
              sx={{
                objectFit: "contain",
                backgroundImage:
                  "linear-gradient(180deg,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.4) 100%)",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={openGallery}
                sx={{ textTransform: "none" }}
              >
                Cambiar
              </Button>
            </HoverCardMedia>
            {assetsNames?.[assetId] && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 1,
                  px: 2,
                }}
              >
                <Typography>{assetsNames?.[assetId]}</Typography>
              </Box>
            )}
          </>
        ) : (
          <CardButton onClick={openGallery} sx={{ height: 150 }}>
            <StyledImageIcon color="action" />
            Selecciona o sube una imagen
          </CardButton>
        )}
      </Card>
      <MaxCharsInput
        label={textFieldLabel}
        value={text ?? ""}
        onChange={onTextChange}
        maxChars={maxChars}
        onDelete={onDelete}
      />
    </>
  );
};

export default SimpleImage;
