import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material"
import React from "react"
import styled from "styled-components"
import { FlexContainer } from "../../components"

const RadioGroupLabel = styled(Typography)`
  width: 340px;
`

const RadioOptions = ({ label, name, value, onChange }) => (
  <FlexContainer verticalCentered>
    <RadioGroupLabel>{label}</RadioGroupLabel>
    <FormControl>
      <RadioGroup
        row
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value === "true")}
      >
        <FormControlLabel
          value="true"
          control={<Radio />}
          label="Si"
          labelPlacement="start"
        />
        <FormControlLabel
          value="false"
          control={<Radio />}
          label="No"
          labelPlacement="start"
        />
      </RadioGroup>
    </FormControl>
  </FlexContainer>
)

export default RadioOptions
