import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  styled,
  Typography
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { Fragment } from "react";
import { getAssetUrlFromId, getImageUrlFromId } from "../../helpers/assets";
import { get } from "../../helpers/request";
import useAssetsUpload from "../../hooks/useAssetsUpload";
import useSite from "../../hooks/useSite";
import AssetsUploadInput from "./AssetsUploadInput";
import ImageCard from "./ImageCard";
import VideoCard from "./VideoGallery/VideoCard";

const ASSET_TYPE = {
  image: "image",
  video: "video",
}

const GalleryLayout = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, 333px)",
  justifyContent: "space-between",
  alignItems: "flex-start",
  gap: 32,
});

const getAssetsFn = async ({ queryKey }) =>
    get("/internal/developer/v1/assets", { site_id: queryKey[1] })

/**
 * @param {{
 *  open: boolean,
 *  onClose: () => void,
 *  onSelected: (assets: Array<{ assetId: string, url: string }>) => void,
 * }} param0
 */
const MediaGallery = ({ open, onClose, onSelected }) => {
  const { site } = useSite();
  const { data } = useQuery(["GET_ASSETS", site?.site_id], getAssetsFn, {
    enabled: open,
  });
  const { inputProps, progress } = useAssetsUpload({
    type: "media",
    onUploaded: ({ assets }) =>      
      onSelected(assets.map(({ assetId, url, type }) => ({ assetId, url, type }))),
  });

  const progressPrct = !progress
    ? null
    : Math.round((100 * progress.loaded) / progress.total);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Galería de Imágenes y Videos</DialogTitle>
      <DialogContent>
        <GalleryLayout>
          <div>
            <AssetsUploadInput
              sx={{ height: 194, cursor: "pointer" }}
              uploadLabel="Sube una imagen o video"
              inputProps={{ ...inputProps, multiple: true }}
            />
            {progressPrct && (
              <>
                <Typography sx={{ mt: 1 }}>Subiendo {progressPrct}%</Typography>
                <LinearProgress
                  variant="determinate"
                  color="secondary"
                  value={progressPrct}
                />
              </>
            )}
          </div>
          {data?.assets?.map((asset) => {
            const assetId = asset.asset_id;
            if (asset.type === ASSET_TYPE.video) {
              return <VideoCard
                key={assetId}
                asset={asset}
                onSelected={() => onSelected([{ assetId, url: getAssetUrlFromId(assetId), type: ASSET_TYPE.video }])}
              />
            } else if (asset.type === ASSET_TYPE.image) {
              const isSvg = asset.metadata?.fileName?.endsWith(".svg");
              const url = isSvg
                ? getAssetUrlFromId(asset.asset_id)
                : getImageUrlFromId(asset.asset_id);
              return (
                <ImageCard
                  key={assetId}
                  assetId={assetId}
                  metadata={asset.metadata}
                  url={url}
                  onSelected={() => onSelected([{ assetId, url, type: ASSET_TYPE.image }])}
                />
              );
            }
            return null
          })}
        </GalleryLayout>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MediaGallery;
