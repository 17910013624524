import React from "react"
import { FlexContainer, Spacer } from "../../../components"
import { IconButton, Switch, Typography } from "@mui/material"
import styled from "styled-components"
import DragHandleIcon from '@mui/icons-material/DragHandle'
import DeleteIcon from '@mui/icons-material/Delete'

const Container = styled(FlexContainer)`
  min-height: 90px;
  max-height: 90px;
  border-bottom: 1px solid var(--color-primary-dark-40);
  ${p => (p.disableEditing) && `
    background: var(--color-primary-dark-10);
    color: rgba(0, 0, 0, 0.38);
  `}
`

const SectionCard = ({ text, icon, isEnabled, onEnable, onDelete, disableEditing, disableDrag }) => (
  <Container justified fullWidth disableEditing={disableEditing || disableDrag} disableDrag={disableDrag}>
    <FlexContainer verticalCentered>
      {(disableEditing || disableDrag)
        ? (
          <Spacer size={5} />
        ) : (
          <IconButton aria-label="ordenar" className="draggable">
            <DragHandleIcon />
          </IconButton>
      )}
      <Spacer size={3} />
      {icon && (
        <>
          <img src={icon} alt={text} />
          <Spacer size={2} />
        </>
      )}
      <Typography>{text}</Typography>
    </FlexContainer>
    {!disableEditing && (
      <FlexContainer verticalCentered>
        {onEnable && (
          <Switch checked={isEnabled} onChange={e => onEnable(e.target.checked)} />
        )}
        <Spacer size={3} />
        {onDelete ? (
          <IconButton aria-label="eliminar" onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        ) : (
          <Spacer size={5} />
        )}
      </FlexContainer>
    )}
  </Container>
)

export default SectionCard
