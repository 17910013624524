const initialSettingsState = {
  general: {
    currency: null,
    language: null,
    theme: null
  },
}

const generalReducer = (state = { blockChangingSite: false, }, action) => {
  switch (action.type) {
    case 'set-profile':
      const activeSites = action.data?.sites?.filter(s => s.is_site_active) ?? []
      return { ...state, profile: { ...action.data, sites: activeSites } }
    case 'clear-profile':
      return { ...state, profile: null, site: null, settings: null }
    case 'reload-profile':
      return { ...state, reloadProfile: action.data }
    case 'set-site':
      return state.blockChangingSite ? { ...state, blockedSite: action.data } : { ...state, site: action.data }
    case 'block-changing-site':
      return { ...state, blockChangingSite: action.data, blockedSite: null  }
    case 'clear-site':
      return { ...state, site: null }
    case 'open-plans-modal':
      return { ...state, showPlansModal: true }
    case 'close-plans-modal':
      return { ...state, showPlansModal: false }
    case 'set-currencies':
      return { ...state, currencies: action.currencies }

    case 'set-general-currency': {
      return {
        ...state,
        settings: {
          ...initialSettingsState,
          general: {
            ...initialSettingsState.general,
            currency: action.settings
          }
        }
      }
    }
    case 'set-locale':
      return { ...state, localte: action.locale }
    case 'analytics-links':
      return { ...state, analyticsLinks: action.data }
    default:
      return state
  }
}

export default generalReducer
