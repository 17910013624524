import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import Plan from './Plan'
import styled from 'styled-components';
import { Button, TextField, Typography } from '@mui/material';
import { FlexContainer, RangeSlider, Spacer } from '../../components';
import { ReactSortable } from "react-sortablejs"
import { MAX_PLAN_NAME_LENGTH } from './utils';

const AddButton = styled(Button)`
  background: #4CAF50;
  color: #fff;
  width: fit-content;
  &:hover {
    background: #4CAF50;
    filter: brightness(1.05);
  }
`

const MutedText = styled(Typography)`
  color: #00000080;
`

const FixedPlansQuoteSettings = ({
  plans,
  onAddNewPlan,
  onDeletePlan,
  onConfirmPlan,
  onReorderPlan,
  previewUnit,
  hasPaymentSchemes,
  hasExternalLoan,
  minPreSalePercent,
  onMinPreSalePercentChange,
  planName,
  onPlanNameChange,
}) => (
  <>
    {hasPaymentSchemes ? (
      <>
        {plans.length === 0 && (
          <>
            <MutedText>Para comenzar agrega los datos de tu primer plan </MutedText>
            <Spacer size={2} vertical />
          </>
        )}
        <ReactSortable list={plans} setList={onReorderPlan} handle='.draggable' animation={300}>
          {plans.map((plan, index) => (
            <div key={plan.id}>
              <Plan
                previewUnit={previewUnit}
                plan={plan}
                onDelete={() => onDeletePlan(index)}
                onConfirm={(newPlan) => onConfirmPlan(newPlan, index)}
              />
              <Spacer size={2} vertical />
            </div>
          ))}
        </ReactSortable>
        <AddButton variant="contained" endIcon={<AddIcon />} size="small" onClick={onAddNewPlan}>Agregar Plan</AddButton>
      </>
    ) : (
      <FlexContainer vertical>
        {hasExternalLoan && (
          <>
            <RangeSlider
              title='Porcentaje a cubrir en preventa (%)'
              endSymbol='%'
              description='Cantidad que el cliente debe pagar antes de recibir su unidad (suma de la reservación o apartado en línea, enganche y mensualidades)'
              min={0}
              max={100}
              step={1}
              value={minPreSalePercent}
              onChange={onMinPreSalePercentChange}
            />
            <Spacer vertical size={6} />
          </>
        )}
        <Typography variant="subtitle1">Nombre del Plan ({planName.length}/{MAX_PLAN_NAME_LENGTH})</Typography>
        <Spacer size={1} vertical />
        <Typography variant='caption'>Le ayudará a los clientes cuando realicen cotizaciones en tu showroom digital</Typography>
        <Spacer size={2} vertical />
        <TextField
          variant="standard"
          size="small"
          value={planName}
          onChange={e => onPlanNameChange(e.target.value)}
        />
      </FlexContainer>
    )}
  </>
)

export default FixedPlansQuoteSettings