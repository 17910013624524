export const onInputNumber = ({ target }) => {
  target.value = target.value
    .replace(/[^0-9.]/g, '')
    .replace(/(\..*?)\..*/g, '$1');
};

export const buttonsSx = {
  width: 'fit-content',
  alignSelf: 'center',
  textTransform: 'none',
  display: 'flex',
};

export const filterConditions = [
  {
    label: 'Contiene',
    value: 'contains',
  },
  {
    label: 'Igual a',
    value: 'equals_to',
  },
  {
    label: 'Comienza con',
    value: 'starts_with',
  },
  {
    label: 'Termina con',
    value: 'ends_with',
  },
  {
    label: 'Está vacío',
    value: 'empty',
  },
  {
    label: 'No está vacío',
    value: 'not_empty',
  },
  {
    label: 'Cualquiera de',
    value: 'any_of',
  },
];

export const units_columns = [
  { columnId: 'checkbox', width: 150, resizable: true },
  { columnId: 'unit_id', width: 150, resizable: true },
  { columnId: 'bedrooms', width: 150, resizable: true },
  { columnId: 'bathrooms', width: 150, resizable: true },
  { columnId: 'half_bathrooms', width: 150, resizable: true },
  { columnId: 'parking_lots', width: 150, resizable: true },
  { columnId: 'interior_m2', width: 150, resizable: true },
  { columnId: 'construction_size', width: 150, resizable: true },
];

export const units_header_row = {
  rowId: 'header',
  cells: [
    { type: 'checkbox', checked: false },
    { type: 'header', text: 'Unidad' },
    {
      type: 'header',
      text: 'Habitaciones',
      bulkType: 'number',
      bulk_name: 'bedrooms',
      required: true,
    },
    {
      type: 'header',
      text: 'Baños',
      bulkType: 'number',
      bulk_name: 'bathrooms',
      required: true,
    },
    {
      type: 'header',
      text: 'Medio baño',
      bulkType: 'number',
      bulk_name: 'half_bathrooms',
      required: true,
    },
    {
      type: 'header',
      text: 'Estacionamientos',
      bulkType: 'number',
      bulk_name: 'parking_lots',
      required: true,
    },
    {
      type: 'header',
      text: 'Superficie construida',
      bulkType: 'number',
      bulk_name: 'interior_m2',
      required: true,
    },
    {
      type: 'header',
      text: 'Superficie terreno',
      bulkType: 'number',
      bulk_name: 'construction_size',
      required: true,
    },
  ],
};

export const property_classes = [
  {
    label: 'No aplica',
    value: 0,
  },
  {
    label: 'Mínima',
    value: 1,
  },
  {
    label: 'Económica',
    value: 2,
  },
  {
    label: 'Interés social',
    value: 3,
  },
  {
    label: 'Media',
    value: 4,
  },
  {
    label: 'Semilujo',
    value: 5,
  },
  {
    label: 'Residencial',
    value: 6,
  },
  {
    label: 'Residencial plus',
    value: 7,
  },
  {
    label: 'Residencial plus +',
    value: 7,
  },
  {
    label: 'Única',
    value: 8,
  },
];

export const property_types = [
  {
    label: 'Casa habitación',
    value: 2,
  },
  {
    label: 'Casa en condominio',
    value: 3,
  },
  {
    label: 'Departamento en condominio',
    value: 4,
  },
];

export const bullets = [
  {
    label: 'Estimado de valor total y por m²',
  },
  {
    label: 'Estimado de renta total y por m²',
  },
  {
    label: 'Propiedades comparables',
  },
  {
    label: 'Inmuebles comparables',
  },
  {
    label: 'Evolución de los precios en la colonia',
  },
];
