import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Spacer } from '../../components';
import { PERMISSIONS } from '../../helpers/permissions';
import useProfile from '../../hooks/useProfile';
import useSettings from '../../hooks/useSettings';

const VIEW_TEXTS = {
  tower: "Torre ID",
  phase: "Fase",
  typology: "Tipología",
  departmentNumberId: "Departamento ID",
  status: "Estado",
  deliveryDate: "Fecha de Entrega",
  price: "Precio Actual",
  newPrice: "Nuevo Precio",
  fullUpfrontPaymentPrice: "Precio al Contado",
  lotNumberId: "Lote",
  block: "Manzana",
  class: "Clase",
}

const headCells = [
  { id: 'tower', label: VIEW_TEXTS.tower, allowSort: true },
  { id: 'phase', label: VIEW_TEXTS.phase, allowSort: false },
  { id: 'typology', label: VIEW_TEXTS.typology, allowSort: false },
  { id: 'department_number_id', label: VIEW_TEXTS.departmentNumberId, allowSort: true },
  { id: 'status', label: VIEW_TEXTS.status, allowSort: false },
  { id: 'delivery_date', label: VIEW_TEXTS.deliveryDate, allowSort: false },
  { id: 'price_mx', label: VIEW_TEXTS.price, allowSort: true },
  { id: 'new_price_mx', label: VIEW_TEXTS.newPrice, allowSort: false, extraSpace: true },
  { id: 'price_mx_full_upfront_payment', label: VIEW_TEXTS.fullUpfrontPaymentPrice, allowSort: false, extraSpace: false },
];

/* TODO: Begin - Eliminar este hack que es solo para el demo de 3 Rocas */
const headCells3Rocas = [
  { id: 'tower', label: VIEW_TEXTS.block, allowSort: true },
  { id: 'phase', label: VIEW_TEXTS.phase, allowSort: false },
  { id: 'typology', label: VIEW_TEXTS.class, allowSort: false },
  { id: 'department_number_id', label: VIEW_TEXTS.lotNumberId, allowSort: true },
  { id: 'status', label: VIEW_TEXTS.status, allowSort: false },
  { id: 'delivery_date', label: VIEW_TEXTS.deliveryDate, allowSort: false },
  { id: 'price_mx', label: VIEW_TEXTS.price, allowSort: true },
  { id: 'new_price_mx', label: VIEW_TEXTS.newPrice, allowSort: false, extraSpace: true },
  { id: 'price_mx_full_upfront_payment', label: VIEW_TEXTS.fullUpfrontPaymentPrice, allowSort: false, extraSpace: false },
];
/* TODO: End - Eliminar este hack que es solo para el demo de 3 Rocas */

const VisuallyHidden = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1;
  margin: -1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20;
  width: 1;
`

const EnhancedTableHead = ({ order, orderBy, onRequestSort, onSelectAllClick, numSelected, rowCount, canSelectRow, changePricePermission }) => {
  const settings = useSettings('general', 'currency')
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const handleSelectAll = (event) => {
    if (onSelectAllClick) onSelectAllClick(event.target.checked)
  }

  /** TODO: Begin - Eliminar este hack que es solo para el demo de 3 Rocas */
  const profile = useProfile()
  const cells = profile && profile.site_id === 41 ? headCells3Rocas : headCells

  /** TODO: End - Eliminar este hack que es solo para el demo de 3 Rocas */
  return (
    <TableHead>
      <TableRow>
        {canSelectRow && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={handleSelectAll}
              color="primary"
              inputProps={{ 'aria-label': 'select all units' }}
            />
          </TableCell>
        )}
        {profile && cells.map((headCell) => (
          <Fragment key={headCell.id}>
            {(headCell.id !== 'new_price_mx' || (headCell.id === 'new_price_mx' && changePricePermission === PERMISSIONS.edit)) && (
              <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell.extraSpace && <Spacer size={7} />}
                {headCell.allowSort
                  ? (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      <b>{headCell.label}</b>
                      {orderBy === headCell.id ? (
                        <VisuallyHidden>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </VisuallyHidden>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    <b>{headCell.label}</b>
                  )}
              </TableCell>
            )}
          </Fragment>
        ))}
        {
          settings && settings.dualCurrency.secondary && (
            <TableCell
              key='new_price_us'>
              <b>{VIEW_TEXTS.price} ({settings.dualCurrency.secondary})</b>
            </TableCell>
          )
        }
      </TableRow>
    </TableHead >
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default EnhancedTableHead;
