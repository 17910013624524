import { WarningAmber as WarningAmberIcon } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { getKeysAsPath } from '../../helpers/data';
import CheckItem from './CheckItem';
import Section from './Section';

export const PROJECT_URL_SLUG = 'project';

export const PROJECT_NOM_PATH = 'project';

export const PROJECT_CHECKLIST_IDS = [
  'Art_7-Art_92',
  'Art_5',
  'Art_6-Sec_XVII',
  'Art_4_11',
];

const Project = ({ getSectionData, setSectionData }) => {
  const getValue = useCallback(
    (id) => getSectionData(getKeysAsPath(['checkList', id])),
    [getSectionData]
  );

  const getHandleValueChange = useCallback(
    (id) => (path, value) =>
      setSectionData(getKeysAsPath(['checkList', id, path]), value),
    [setSectionData]
  );

  return (
    <Section title="Información del proyecto" id="project">
      <CheckItem
        value={getValue('Art_7-Art_92')}
        setValuePath={getHandleValueChange('Art_7-Art_92')}
        title="En el caso que el proyecto ejecutivo del inmueble por el cual se realizó el anticipo necesite modificarse, el proveedor debe notificar al consumidor sobre las modificaciones realizadas, debiendo este último autorizar de forma expresa por escrito, continuar con la compra del inmueble o devolución del enganche y en su caso, el pago de una indemnización."
        NOMSection="Art. 7 y 92 fracción II de la LFPC"
      />
      <CheckItem
        value={getValue('Art_5')}
        setValuePath={getHandleValueChange('Art_5')}
        title="Información y publicidad en idioma español"
        NOMSection="Art. 5"
      />
      <CheckItem
        value={getValue('Art_6-Sec_XVII')}
        setValuePath={getHandleValueChange('Art_6-Sec_XVII')}
        title="Información sobre las características del inmueble (extensión del terreno, superficie construida, tipo de estructura, instalaciones, acabados, accesorios, lugar de estacionamiento, áreas de uso común, servicios con que cuenta y estado general físico del inmueble)."
        NOMSection="Art. 6, Sec. XVII"
      />
      <CheckItem
        checked
        title="Visualización de todos los prototipos de viviendas disponibles"
        NOMSection="Art. 5.3 Sec. i"
      />
      <CheckItem
        value={getValue('Art_4_11')}
        setValuePath={getHandleValueChange('Art_4_11')}
        title={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography component="span">
              Prohibición de negativa de trato y de ventas atadas. El proveedor
              no podrá negar al consumidor la venta de un inmueble que tenga
              disponible.
            </Typography>
            <Tooltip title="Asegurate de mantener el inventario actualizado">
              <WarningAmberIcon
                color="error"
                sx={{ ml: 1, verticalAlign: 'middle' }}
              />
            </Tooltip>
          </Box>
        }
        NOMSection="Art. 4.11"
      />
    </Section>
  );
};

export default Project;
