import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

const Flex = styled.div`
  display: flex;
  flex-wrap: ${({ flexWrap }) => flexWrap ? 'wrap' : 'nowrap'};
  ${({ vertical }) => vertical && css`flex-direction: column;`};
  ${({ fullWidth }) => fullWidth && css`width: 100%;`};
  ${({ fullHeight }) => fullHeight && css`height: 100%;`};
  ${({ justified }) => justified && css`justify-content: space-between;`};
  ${({ centered, vertical }) => centered && css`${vertical ? 'align-items' : 'justify-content'}: center;`};
  ${({ verticalCentered, vertical }) => verticalCentered && css`${vertical ? 'justify-content' : 'align-items'}: center;`};
  ${({ stretched }) => stretched && css`align-self: stretch`};
  ${({ started, vertical }) => started && css`${vertical ? 'align-items' : 'justify-content'}: flex-start;`};
  ${({ ended, vertical }) => ended && css`${vertical ? 'align-items' : 'justify-content'}: flex-end;`};
  ${({ topped, vertical }) => topped && css`${vertical ? 'justify-content' : 'align-items'}: flex-start;`};
  ${({ positioned }) => positioned && css`position: relative;`};
  ${({ alignSelfEnd }) => alignSelfEnd && css`align-self: flex-end;`};
`

const FlexContainer = forwardRef(({ ...props }, ref) => (
  <Flex {...props} ref={ref} />
));

FlexContainer.propTypes = {
  vertical: PropTypes.bool,
  fullWidth: PropTypes.bool,
  fullHeight: PropTypes.bool,
  flexWrap: PropTypes.bool,
  justified: PropTypes.bool,
  centered: PropTypes.bool,
  verticalCentered: PropTypes.bool,
  stretched: PropTypes.bool,
  children: PropTypes.node.isRequired,
  started: PropTypes.bool,
  ended: PropTypes.bool,
  positioned: PropTypes.bool,
  topped: PropTypes.bool,
};

FlexContainer.defaultProps = {
  vertical: false,
  fullWidth: false,
  fullHeight: false,
  flexWrap: false,
  justified: false,
  centered: false,
  stretched: false,
  verticalCentered: false,
  started: false,
  ended: false,
  positioned: false,
  topped: false,
};

export default FlexContainer;
