import { Button, IconButton } from '@mui/material'
import React from 'react'
import { FlexContainer, PendingButton, Spacer } from '../../components'
import MonitorIcon from '@mui/icons-material/Monitor';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EditIcon from '@mui/icons-material/Edit';
import NearMeIcon from '@mui/icons-material/NearMe';
import { PREVIEW_SIZE } from './utils';
import styled from 'styled-components';

const PublishButton = styled(Button)`
  color: #fff;
  background: var(--color-secondary-light);
  &:hover {
    background: var(--color-secondary-gradient);
  }
  && .MuiTouchRipple-rippleVisible: {
    background: var(--color-primary-light);
  }
`

const ShowroomEditorMenu = ({
  previewSize,
  onPreviewChange,
  onEdit,
  onSave,
  onPublish,
  isEditing,
  enableSave,
  disableActions,
}) => (
  <FlexContainer>
    <IconButton onClick={onPreviewChange} size='medium'>
      {previewSize === PREVIEW_SIZE.desktop
        ? (
          <MonitorIcon />
        ) : (
          <PhoneIphoneIcon />
      )}
    </IconButton>
    <Spacer size={3} />
    {isEditing ? (
      <PendingButton onClick={onSave} showBullet={enableSave} text="Guardar" />
      ): (
      <Button disabled={disableActions} onClick={onEdit} startIcon={<EditIcon />}>Editar</Button>
    )}
    <Spacer size={3} />
    <PublishButton disabled={disableActions} onClick={onPublish} variant='contained' startIcon={<NearMeIcon />}>Publicar</PublishButton>
  </FlexContainer>
)

export default ShowroomEditorMenu
