import React from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { hexToHsl } from '../../../helpers/colors';
import { get } from '../../../helpers/request';
import themes, { THEME_TYPES } from '../../../helpers/themes';
import useSite from '../../../hooks/useSite';
import ColorSelector from '../themes/ColorSelector';
import CustomColors from '../themes/CustomColors';

const ThemesSelector = ({
  viewCustomColors,
  onCustomize,
  setTheme,
  setThemeConfig,
  toggleThemesEditor,
  viewThemesEditor,
}) => {
  const { site, changeSiteData } = useSite();
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [customColors, setCustomColors] = useState(undefined);

  useEffect(() => {
    setCustomColors(site.draft_theme_custom_colors);
  }, [customColors, setCustomColors, site.draft_theme_custom_colors]);

  const selectedThemeData = useMemo(
    () => THEME_TYPES.find((t) => t.value === selectedTheme),
    [selectedTheme]
  );

  useEffect(() => {
    setSelectedTheme(site?.draft_theme ?? site?.theme);
    setSelectedColor(
      site?.draft_theme_version ?? site?.colors?.value?.replace('option', '')
    );
  }, [
    site?.colors?.value,
    site?.draft_theme,
    site?.draft_theme_custom_colors,
    site?.draft_theme_version,
    site?.theme,
  ]);

  const handleChangeTheme = useCallback(
    (theme, version) => {
      setThemeConfig((prev) => ({ ...prev, theme, version }));
      get(`/internal/developer/v1/themes/${theme}/${version}`, site)
        .then((data) => setTheme(data.content))
        .catch((error) => console.log(error));
    },
    [site, setTheme, setThemeConfig]
  );

  const handleThemeSelect = useCallback(
    (theme) => {
      setSelectedTheme(theme);
      changeSiteData({ ...site, draft_theme: theme });
      handleChangeTheme(theme, selectedColor);
    },
    [changeSiteData, handleChangeTheme, selectedColor, site]
  );

  const handleColorSelect = useCallback(
    (color) => {
      setSelectedColor(color);
      changeSiteData({ ...site, draft_theme_version: color });
      handleChangeTheme(selectedTheme, color);
    },
    [changeSiteData, site, handleChangeTheme, selectedTheme]
  );

  const handleCustomize = useCallback(() => {
    const themeColors =
      themes[selectedTheme.toLowerCase()].colors['option' + selectedColor];
    let hslColors = {};
    Object.entries(themeColors).forEach(([key, value]) => {
      hslColors = {
        ...hslColors,
        [key]: hexToHsl(value),
      };
    });
    changeSiteData({ ...site, draft_theme_custom_colors: hslColors });
    setCustomColors({
      theme: selectedTheme,
      version: selectedColor,
      customColors: hslColors,
    });
    onCustomize();
  }, [selectedTheme, selectedColor, changeSiteData, site, onCustomize]);

  const handleCustomMainColorChange = useCallback(
    (h, s, l, attribute) => {
      let newCustomColors;
      const addFactor = l <= 0.5 ? 1 : -1;
      const main = `hsl(${h}, ${s * 100}%, ${l * 100}%)`;
      const variant1 = `hsl(${h}, ${s * 100}%, ${l * 100 + addFactor * 15}%)`;
      const variant2 = `hsl(${h}, ${s * 100}%, ${l * 100 + addFactor * 30}%)`;

      switch (attribute) {
        case 'primary':
          newCustomColors = {
            ...customColors,
            primary: main,
            primary80: variant1,
            primary60: variant2,
          };
          break;
        case 'secondary':
          newCustomColors = {
            ...customColors,
            secondary: main,
          };
          break;
        case 'neutral80':
          newCustomColors = {
            ...customColors,
            neutral80: main,
            neutral60: variant1,
            neutral20: variant2,
          };
          break;
        default:
      }
      changeSiteData({ ...site, draft_theme_custom_colors: newCustomColors });
      setCustomColors(newCustomColors);
    },
    [changeSiteData, site, customColors]
  );

  const handleCustomVariantColorChange = useCallback(
    (value, attribute) => {
      const newCustomColors = {
        ...customColors,
        [attribute]: value,
      };
      changeSiteData({ ...site, draft_theme_custom_colors: newCustomColors });
      setCustomColors(newCustomColors);
    },
    [changeSiteData, site, customColors]
  );

  const handleDeleteCustomColors = useCallback(() => {
    changeSiteData({ ...site, draft_theme_custom_colors: null });
    setCustomColors(null);
  }, [changeSiteData, site]);

  useEffect(() => {
    setThemeConfig((prev) => ({ ...prev, customColors: customColors }));
  }, [customColors, setThemeConfig]);

  return (
    <>
      {viewCustomColors ? (
        <CustomColors
          colors={customColors}
          onMainChange={handleCustomMainColorChange}
          onVariantChange={handleCustomVariantColorChange}
        />
      ) : (
        <ColorSelector
          toggleThemesEditor={toggleThemesEditor}
          viewThemesEditor={viewThemesEditor}
          onCustomize={handleCustomize}
          selectedTheme={selectedTheme}
          selectedColor={selectedColor}
          selectedThemeData={selectedThemeData}
          onThemeSelect={handleThemeSelect}
          onColorSelect={handleColorSelect}
          customColors={customColors}
          onCustomizeColors={onCustomize}
          onDeleteCustomColors={handleDeleteCustomColors}
        />
      )}
    </>
  );
};

export default ThemesSelector;
