import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { SECTIONS_DATA } from '../../../helpers/constants';
import { sections_data } from '../../../helpers/sections_data';
import { Portal, Typography } from '@mui/material';
import { FlexContainer } from '../../../components';

const Container = styled.div`
  font-family: 'Roboto';
`;

const SectionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 10px;
  width: 230px;
  gap: 16px;
`;

const SectionCard = styled(FlexContainer)`
  padding: 0px;
  gap: 8px;
  width: 100%;
  min-width: 100px;
  height: 100px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
  flex-direction: column;
  cursor: grab;
  user-select: none;
  &:active {
    cursor: grabbing;
    cursor: url(images/grabbing.cur);
  }
`;

const GlobalCardContainer = styled.div`
  position: fixed;
  display: flex;
  z-index: 1000;
  ${(p) => (p.t ? `top: ${p.t};` : '')}
  ${(p) => (p.b ? `bottom: ${p.b};` : '')}
  ${(p) => (p.l ? `left: ${p.l};` : '')}
  ${(p) => (p.r ? `right: ${p.r};` : '')}
`;

const CardComponent = ({ section, onMouseDown }) => (
  <SectionCard centered verticalCentered onMouseDown={(e) => onMouseDown(e, section)} key={section.id}>
    <img draggable={false} src={section.icon} alt={section.label} />
    <Typography variant="caption">{section.label}</Typography>
  </SectionCard>
);

export const SectionsComponent = ({ previewRef, setData, data }) => {
  const [selectedSection, setSelectedSection] = useState(null);
  const site = useSelector((e) => e.site);
  const [sections] = useState(
    Object.entries(SECTIONS_DATA).map(([key, value]) => ({
      ...value,
      id: key,
    })),
  );

  const onMouseDown = (e, section) => {
    document.body.style.cursor = 'grabbing';
    const sectionData = sections_data[section.id];
    if (sectionData.type === 'small-map-section') {
      sectionData.card.logo = { ...sectionData.card.logo, ...data.header.logo };
    }
    previewRef.current.contentWindow.postMessage(
      {
        action: 'start-alohub-drop-zone',
        data: sectionData,
      },
      '*',
    );
    setSelectedSection(section);
    window.addEventListener('mouseup', onMouseUp);
  };

  const onMouseUp = useCallback(
    (e) => {
      document.body.style.cursor = 'default';
      previewRef.current.contentWindow.postMessage(
        {
          action: 'end-alohub-drop-zone',
        },
        '*',
      );
      setSelectedSection(null);
      window.removeEventListener('mouseup', onMouseUp);
    },
    [previewRef],
  );

  useEffect(() => {
    const mouseUpHandler = (event) => {
      if (event.data.action === 'set_data') {
        onMouseUp();
        setData(event.data.data);
      } else if (event.data.action === 'mousemove') {
        const element = document.getElementById('section-card-preview');
        if (element) {
          element.style.top = `${
            event.data.clientY + previewRef.current.offsetTop - 110
          }px`;
          element.style.left = `${
            event.data.clientX + previewRef.current.offsetLeft - 110
          }px`;
        }
      }
    };
    window.addEventListener('message', mouseUpHandler);
    return () => window.removeEventListener('message', mouseUpHandler);
  }, [onMouseUp, setData, previewRef]);

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (selectedSection) {
        const element = document.getElementById('section-card-preview');
        if (element) {
          element.style.top = `${event.clientY - 110}px`;
          element.style.left = `${event.clientX - 110}px`;
        }
      }
    };
    document.addEventListener('mousemove', handleMouseMove);
    return () => document.removeEventListener('mousemove', handleMouseMove);
  }, [selectedSection]);

  return (
    <>
      <Container>
        <Typography>Arrastra y agrega un elemento</Typography>
        <SectionsGrid>
          {sections
            .filter((sec) =>
              site?.project_type === 'APARTMENT'
                ? sec.id !== 'lots-view-section'
                : sec.id !== 'carousel-typologies',
            )
            .map((section) => (
              <CardComponent
                key={section.id}
                section={section}
                onMouseDown={onMouseDown}
              />
            ))}
        </SectionsGrid>
      </Container>
      {selectedSection && (
        <Portal>
          <GlobalCardContainer id="section-card-preview">
            <CardComponent section={selectedSection} onMouseDown={() => {}} />
          </GlobalCardContainer>
        </Portal>
      )}
    </>
  );
};
