import { Button, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { FlexContainer, Spacer } from "../../components";
import { PLANS } from "../../helpers/constants"

const Container = styled(FlexContainer)`
  padding: 16px;
  box-shadow: 0px 0px 0px 1px #e0e0e0;
  border-radius: 4px;
  width: calc(100% - 32px);

  .MuiButton-root {
    text-transform: none;
  }

  @media only screen and (min-width: 1440px) {
    padding: 24px;
    width: calc(100% - 48px);
  }
`;

const Plan = ({
  id,
  name,
  monthly_price,
  anual_price,
  title,
  description,
  cta,
  onSelect,
  planSelect,
  showMonthlyPrice
}) => (<Container fullWidth vertical>
    <FlexContainer justified verticalCentered>
      <FlexContainer verticalCentered>
        <Typography variant="h6">{name}</Typography>
        <Spacer size={1} />
        {id !== PLANS.ENTERPRISE ? (<Typography
          variant="caption"
          color="var(--color-text-disabled)"
        >{`USD $${showMonthlyPrice ? monthly_price : anual_price}/mes`}</Typography>)
          : (<Typography
            variant="caption"
            color="var(--color-text-disabled)"
          >Cotización personalizada</Typography>)}
      </FlexContainer>
      <Button
        variant={cta.variant}
        color="primary"
        disabled={planSelect === id}
        size="small"
        onClick={() => onSelect(id, cta.action)}
      >
        {id === planSelect ? cta.label_select : cta.label}
      </Button>
    </FlexContainer>
    <Spacer vertical size={1} />
    <Typography variant="caption" color="var(--color-text-secondary)">
      <b>{title}</b>
      {description}
    </Typography>
  </Container>);

export default Plan;
