import { MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const Placeholder = styled(Typography)`
  color: #00000061;
`

const StyledSelect = styled(Select)`
  min-width: 120px;
`

const SelectComponent = ({ placeholder, value, onChange, options, defaultValue }) => (
  <StyledSelect
    displayEmpty
    value={value}
    onChange={(e) => onChange(e.target.value)}
    renderValue={
      () => {
        if (value == null || value === '') {
          return <Placeholder>{placeholder}</Placeholder>
        }
        const selectedOption = options.find(o => o.value === value)
        return selectedOption && selectedOption.label
      }
    }
  >
    {options.map(o => (
      <MenuItem value={o.value} key={o.value}>{o.label}</MenuItem>
    ))}
  </StyledSelect>
)

export default SelectComponent
