export function getSitePreviewUrl(site) {
	return site
		? `https://preview-${site?.site_id}-${site.current_version || 1}.preview.alohome.io`
		: null;
}

export const getData = async (site) => {
	const previewUrl = getSitePreviewUrl(site);
	if (!previewUrl) return;
	const res = await fetch(`${previewUrl}/api/data/data`);
	return await res.json();
}

export const getGeneral = async (site) => {
	const previewUrl = getSitePreviewUrl(site);
	if (!previewUrl) return;
	const res = await fetch(`${previewUrl}/api/data/general`);
	return await res.json();
}

export const getInventory = async (site) => {
	const previewUrl = getSitePreviewUrl(site);
	if (!previewUrl) return;
	const res = await fetch(`${previewUrl}/api/data/inventory`);
	return await res.json();
}

export const getAlocodeIconUrl = (alocode, site) => {
	const previewUrl = getSitePreviewUrl(site);
	if (!previewUrl) return;
	// eslint-disable-next-line no-useless-escape
	const alocodeFileName = alocode.replace(/[\[\]:]/g, "");
	return `${previewUrl}/icons/${alocodeFileName}.svg`;
}
