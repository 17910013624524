import React from 'react'
import PropTypes from 'prop-types'
import { FlexContainer } from '../'
import { Typography } from '@mui/material'

const CaptionedData = ({ topCaption, data, dataSize, bottomCaption }) => {
  return (
    <FlexContainer vertical centered>
      <Typography variant="overline" color="textSecondary" align="center">{topCaption}</Typography>
      <Typography variant={dataSize === "small" ? 'h5' : dataSize === "medium" ? 'h4' : 'h3'} align="center">{data}</Typography>
      <Typography variant="overline" color="textSecondary" align="center">{bottomCaption}</Typography>
    </FlexContainer>
  )
}

CaptionedData.defaultProps = {
  topCaption: " ",
  bottomCaption: " ",
  dataSize: "small"
}

CaptionedData.propTypes = {
  topCaption: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.number,PropTypes.string]).isRequired,
  dataSize: PropTypes.oneOf(["small", "medium", "large"]),
  bottomCaption: PropTypes.string
}

export default CaptionedData
