import { Typography, Input } from '@mui/material'
import { FlexContainer, Select, Spacer } from '../../components'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BulkEditContainer from './BulkEditContainer'
import BulkEditPriceTable from './BulkEditPriceTable'
import useCurrencies from '../../hooks/useCurrencies'
import useSettings from '../../hooks/useSettings'

const INCREASE_DECREASE = [{
  label: 'Incrementa',
  value: 0,
}, {
  label: 'Disminuye',
  value: 1,
}]

const INCREMENT_TYPE = [{
  label: 'Porcentaje (%)',
  value: 0,
}, {
  label: 'Monto (MXN)',
  value: 1,
}]

const StyledInput = styled(Input)`
  width: 100px;
`

const StyledTypography = styled(Typography)`
  display: flex;
  align-items: flex-end;
`

const BulkEditPrice = ({ inventoryBulk, onUpdate }) => {
  const [newInventory, setNewInventory] = useState(inventoryBulk)
  const [increaseDecrease, setIncreaseDecrease] = useState(INCREASE_DECREASE[0].value)
  const [incrementType, setIncrementType] = useState(INCREMENT_TYPE[0].value)
  const [amount, setAmount] = useState('')
  const currencies = useCurrencies()
  const settings = useSettings()
  const mainCurrencyCode = settings.dualCurrency.main

  useEffect(() => {
    setNewInventory((inventory) => {
      const realAmount = Number(amount ?? 0)
      const increaseOrDecrease = increaseDecrease === INCREASE_DECREASE[0].value ? 1 : -1
      const updatedInventory = inventory.map(n => {
        const percentageOrAmount = incrementType === INCREMENT_TYPE[0].value ? n[`price_${mainCurrencyCode}`] / 100 : 1
        return {
          ...n,
          new_price: Math.ceil(n[`price_${mainCurrencyCode}`] + percentageOrAmount * realAmount * increaseOrDecrease)
        }
      })
      return updatedInventory
    })
  }, [increaseDecrease, incrementType, amount, mainCurrencyCode])

  const handleUpdateClick = () => onUpdate(newInventory)

  return (
    <>
      {currencies && (<BulkEditContainer title="Edición masiva del precio" onUpdate={handleUpdateClick} showUpdateButton={amount !== ''}>
        <FlexContainer>
          <Select options={INCREASE_DECREASE} onChange={setIncreaseDecrease} value={increaseDecrease} />
          <Spacer size={2} />
          <StyledTypography variant="subtitle1">el precio por</StyledTypography>
          <Spacer size={2} />
          <StyledInput value={amount} onChange={e => setAmount(e.target.value)} placeholder="monto" type="number" />
          <Spacer size={2} />
          <Select options={[INCREMENT_TYPE[0], {
            ...INCREMENT_TYPE[1],
            label: `Monto (${currencies.main.code})`
          }]} value={incrementType} onChange={setIncrementType} />
        </FlexContainer>
        {amount && (
          <>
            <Spacer vertical size={4} />
            <BulkEditPriceTable newInventory={newInventory} />
          </>
        )}
      </BulkEditContainer>)}
    </>
  )
}

export default BulkEditPrice
