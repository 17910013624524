import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SPACE_MULTIPLIER = 8;

const Vertical = styled.div`
  height: ${(p) => p.size * SPACE_MULTIPLIER}px;
  min-height: ${(p) => p.size * SPACE_MULTIPLIER}px;
`;

const Horizontal = styled.div`
  width: ${(p) => p.size * SPACE_MULTIPLIER}px;
  min-width: ${(p) => p.size * SPACE_MULTIPLIER}px;
  display: inline-block;
`;

const Spacer = ({ size, vertical }) => (
  <>{vertical ? <Vertical size={size} /> : <Horizontal size={size} />}</>
);

Spacer.propTypes = {
  size: PropTypes.number.isRequired,
  vertical: PropTypes.bool,
};

Spacer.defaultProps = {
  vertical: false,
};

export default Spacer;
