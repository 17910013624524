/**
 * Filters duplicated entries on array `arr` of values by `getItemValue`
 * function.
 *
 * @example
 *
 * const users = [
 *  { email: 'alejandro@alohome.io', id: 1 },
 *  { email: 'username0@alohome.io', id: 2 },
 *  { email: 'alejandro@alohome.io', id: 3 },
 * ]
 *
 * const result = filterUniqueBy(users, v => v.email);
 *
 * // result = [
 * //   { email: 'alejandro@alohome.io', id: 1 },
 * //   { email: 'username0@alohome.io', id: 2 },
 * // ]
 */
export function filterUniqueBy(arr, getItemValue = (v) => v) {
  return arr.filter(
    (item, index) =>
      arr.findIndex((v) => getItemValue(v) === getItemValue(item)) === index,
  );
}
