import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import BusinessIcon from '@mui/icons-material/Business';
import ComputerIcon from '@mui/icons-material/Computer';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import IntelimetricaIcon  from '../../assets/icons/intelimetrica.svg';
import FlatIcon from '../../assets/icons/flat.svg';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SidebarOption from './SidebarOption';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useMemo } from 'react';
import useSite from '../../hooks/useSite';
import { getAvailableOptions } from '../../helpers/permissions';
import { Segment as SegmentIcon } from '@mui/icons-material';
import AppsIcon from '@mui/icons-material/Apps';
import { useQuery } from '@tanstack/react-query';
import { get } from '../../helpers/request';

const MENU = [
  {
    id: 'project',
    label: 'Proyecto',
    href: '/project',
    icon: BusinessIcon,
    permissionPath: ['project'],
  },
  {
    id: 'showroom',
    label: 'Showroom digital',
    href: '/digital-showroom',
    icon: ComputerIcon,
    permissionPath: ['digital_showroom'],
  },
  {
    id: 'prospects',
    label: 'Prospectos',
    href: '/prospects',
    icon: PersonSearchIcon,
    permissionPath: ['prospects'],
  },
  {
    id: 'quote',
    label: 'Cotizar',
    href: '/quote',
    icon: AttachMoneyIcon,
    permissionPath: ['quote'],
  },
  {
    id: 'sales-team',
    label: 'Equipo de ventas',
    href: '/team',
    icon: PeopleIcon,
    permissionPath: ['team'],
  },
  {
    id: 'marketing',
    label: 'Marketing',
    href: '/marketing',
    icon: BarChartIcon,
    permissionPath: ['marketing'],
  },
  {
    id: 'nom-247',
    label: 'NOM-247',
    href: '/nom247',
    icon: SegmentIcon,
    permissionPath: ['nom247'],
  },
  {
    id: 'configuration',
    label: 'Configuración',
    href: '/config',
    icon: SettingsIcon,
    permissionPath: ['config'],
  },
  {
    label: 'Apps',
    href: '/apps',
    icon: AppsIcon,
    permissionPath: ['integrations', 'administration'],
  },
];

const APPS_MENU = [
  {
    code: 'flat',
    label: 'Flat.mx',
    href: '/flat',
    iconPath: FlatIcon,
    permissionPath: ['integrations', 'flat'],
  },
  {
    code: 'intelimetrica',
    label: 'Intelimétrica',
    href: '/intelimetrica',
    iconPath: IntelimetricaIcon,
    permissionPath: ['integrations', 'intelimetrica'],
  },
];

const Title = styled(ListSubheader)`
  font-size: 12px;
`;

const getActive = ({ queryKey }) =>
  get('/internal/alohub/integrations/active', { site_id: queryKey[1] });

export default function Menu() {
  const location = useLocation();
  const { site } = useSite();
  const siteId = site?.site_id;

  const { data: activeApps } = useQuery(['ACTIVE_APPS', siteId], getActive, {
    staleTime: 360_000,
    keepPreviousData: true,
    enabled: !!siteId,
  });

  const isOptionSelected = useCallback(
    (optionUrl) => {
      const pathnameParts = location.pathname.split('/');
      return pathnameParts[1] === optionUrl.replace('/', '');
    },
    [location.pathname]
  );

  const availableMenu = useMemo(
    () => getAvailableOptions(MENU, site?.permissions),
    [site?.permissions]
  );

  const availableAppsMenu = useMemo(
    () =>
      getAvailableOptions(APPS_MENU, site?.permissions)?.filter(({ code }) =>
        activeApps?.includes(code)
      ),
    [site?.permissions, activeApps]
  );

  return (
    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <List
        component="nav"
        aria-label="routes menu"
        subheader={site && <Title>ALOHUB</Title>}
      >
        {availableMenu.map((menuItem) => (
          <SidebarOption
            key={menuItem.label}
            id={menuItem.id}
            icon={menuItem.icon}
            label={menuItem.label}
            to={menuItem.href}
            selected={isOptionSelected(menuItem.href)}
          />
        ))}
      </List>
      {availableAppsMenu?.length > 0 && (
        <List
          component="nav"
          aria-label="routes menu"
          subheader={site && <Title>APPS</Title>}
        >
          {availableAppsMenu.map((menuItem) => (
            <SidebarOption
              key={menuItem.label}
              icon={menuItem.icon}
              label={menuItem.label}
              to={menuItem.href}
              selected={isOptionSelected(menuItem.href)}
              iconPath={menuItem.iconPath}
            />
          ))}
        </List>
      )}
    </Box>
  );
}
