import { Button, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { FlexContainer, Spacer } from '../../components'

const Container = styled(FlexContainer)`
  margin: 32px 0;
  padding: 16px;
  box-shadow: 0px 0px 0px 1px #E0E0E0;
  border-radius: 16px;
  background: rgba(69, 168, 230, 0.1);
  border: 1px dashed var(--color-primary-light-60);
`

const MessageBox = ({ remainingTrialDays }) => {
  const dispatch = useDispatch()
  const openPlans = useCallback(()=>{
    dispatch({ type: 'open-plans-modal' })
  },[dispatch])

  return(
    <Container>
      <FlexContainer vertical >
        <FlexContainer vertical>
          <Typography variant='h6'> Quedan {remainingTrialDays} días de prueba </Typography>
          <Spacer vertical  size={1} />
          <Typography x={{ padding: "1rem" }} variant='caption' color="disabled">Elige un plan para que tu showroom digital siga activo</Typography>
        </FlexContainer>
        <Spacer vertical size={3} />
        <Button sx={{ width: "fit-content", alignSelf: "center", textTransform: "none" }} variant="contained" color="primary" onClick={openPlans} > Elegir plan </Button>
      </FlexContainer>
    </Container>
  )
}
export default MessageBox