import React, { Fragment, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import styled from 'styled-components'
import { FlexContainer, Spacer } from '../../components'
import { Alert, Button, Chip, Divider, IconButton, Snackbar, TextField, Typography } from '@mui/material'
import useSite from '../../hooks/useSite'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import RefreshIcon from '@mui/icons-material/Refresh'
import CloseIcon from '@mui/icons-material/Close'
import RecordVoice from '../../assets/svgs/record-voice.svg'
import { useCallback } from 'react'
import { useMemo } from 'react'

const UTMs = [{
  name: "Social",
  tag: "paid_social",
  description: "Usa este enlace para medir el tráfico a tu showroom desde FB, IG y otras redes sociales",
  url: "/?utm_source=paid_social&utm_medium=paid_social&utm_campaign=paid_social"
}, {
  name: "Search",
  tag: "paid_search",
  description: "Usa este enlace para medir el tráfico a tu showroom desde Google y otros motores de búsqueda",
  url: "/?utm_source=paid_search&utm_medium=paid_search&utm_campaign=paid_search"
}]

const HeaderContainer = styled(FlexContainer)`
  background: var(--color-secondary-gradient-80);
  border-radius: 4px 4px 0px 0px;
  height: 110px;
  position: relative;
`

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
`

const HeaderText = styled(Typography)`
  color: #F6F7F9;
`

const BodyContainer = styled(FlexContainer)`
  padding: 20px 30px;
`

const DomainContainer = styled(FlexContainer)`
  background: #EEEEEE;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
`

const DomainText = styled.div`
  background: #FFFFFF;
  border-radius: 4px;
  width: 100%;
  padding: 4px 12px;
`

const DividerContent = styled(FlexContainer)`
  gap: 8px;
`

const StyledDivider = styled(Divider)`
  height: 1px;
  width: calc(50% - 74px);
  margin: 0;
`

const UtmTitle = styled(Typography)`
  font-weight: 500;
`

const UtmUrlInput = styled(TextField)`
  width: 415px;
  /* border-bottom: 1px dashed rgba(0, 0, 0, 0.42); */
  div {
    &::before {
      border-bottom: 1px dashed rgba(0, 0, 0, 0.42);
    }
  }
`

const ShowroomButton = styled(Button)`
  width: 300px;
`

const PublishModal = ({ isOpen, onClose }) => {
  const [showCopyMessage, setShowCopyMessage] = useState(false)
  const { site } = useSite()

  const domain = useMemo(() => `https://${site?.site_domain}`, [site?.site_domain])

  const handleOpenDomain = useCallback(() => {
    window.open(domain, "_blank")
  }, [domain])

  const handleCopyUtm = useCallback((utmUrl) => {
    navigator.clipboard.writeText(domain + utmUrl)
    setShowCopyMessage(true)
  }, [domain])

  const closeCopyMessage = useCallback(() => {
    setShowCopyMessage(false)
  }, [])

  return (
    <>
      <Snackbar open={showCopyMessage} autoHideDuration={6000} onClose={closeCopyMessage} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={closeCopyMessage} severity="success" sx={{ width: '100%' }}>
          El enlace se ha copiado correctamente
        </Alert>
      </Snackbar>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isOpen}
        onClose={onClose}
      >
        <HeaderContainer vertical centered verticalCentered>
          <CloseButton onClick={onClose}>
            <CloseIcon  sx={{ color: "#F6F7F9" }} />
          </CloseButton>
          <HeaderText variant='h4'>¡Qué comiencen los resultados!</HeaderText>
          <HeaderText variant='h6'>Estamos publicando tu showroom...</HeaderText>
        </HeaderContainer>
        <DialogContent>
          <BodyContainer vertical>
            <Typography>Este proceso puede tardar hasta 5 minutos. Tu dirección web será:</Typography>
            <Spacer size={2} vertical />
            <FlexContainer>
              <DomainContainer verticalCentered>
                <Spacer size={2} />
                <ArrowBackIcon sx={{ color: "#9E9E9E" }} />
                <Spacer size={2} />
                <RefreshIcon sx={{ color: "#9E9E9E" }} />
                <Spacer size={2} />
                <DomainText>
                  <Typography variant="caption">{domain}</Typography>
                </DomainText>
              </DomainContainer>
              <Spacer size={2} />
              <Button color="primary" variant="contained" size="small" onClick={handleOpenDomain}>visitar</Button>
            </FlexContainer>
            <Spacer size={3} vertical />
            <DividerContent verticalCentered>
              <StyledDivider />
              <Typography color="text.disabled">¿Qué sigue ahora?</Typography>
              <StyledDivider />
            </DividerContent>
            <Spacer size={3} vertical />
            <FlexContainer topped>
              <img src={RecordVoice} alt="UTM" />
              <Spacer size={4} />
              <FlexContainer vertical>
                <Typography>¡Puedes comenzar tus campañas de marketing digital!</Typography>
                <Spacer size={2} vertical />
                {UTMs.map(utm => (
                  <Fragment key={utm.tag}>
                    <FlexContainer verticalCentered>
                      <UtmTitle>{utm.name}</UtmTitle>
                      <Spacer size={2} />
                      <Chip label={utm.tag} />
                    </FlexContainer>
                    <Spacer size={2} vertical />
                    <Typography>{utm.description}</Typography>
                    <Spacer size={2} vertical />
                    <FlexContainer>
                      <UtmUrlInput value={domain + utm.url} variant="standard" disabled />
                      <Spacer size={3} />
                      <Button color="primary" variant="outlined" size="small" onClick={() => handleCopyUtm(utm.url)}>Copiar</Button>
                    </FlexContainer>
                    <Spacer size={4} vertical />
                  </Fragment>
                ))}
                <ShowroomButton variant="contained" color="primary" onClick={onClose}>seguir editando mi showroom</ShowroomButton>
              </FlexContainer>
            </FlexContainer>
          </BodyContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default PublishModal
