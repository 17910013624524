import { Typography } from "@mui/material"
import React from "react"
import { useCallback } from "react"
import { useMemo } from "react"
import styled from "styled-components"
import { FlexContainer, Spacer } from "../../components"

const Container = styled(FlexContainer)`
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  overflow: hidden;
`

const ColorBox = styled.div`
  width: 225px;
  height: 124px;
  background: ${p => p.color};
`

const Customizer = styled.div`
  padding: 12px;
`

const Slider = styled.input`
  appearance: none;
  width: 100%;
  margin-right: 15px;
  height: 8px;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  background-repeat: no-repeat;
`

const LightnessSlider = styled(Slider)`
  background-image: linear-gradient(to left, #fff 0%, ${p => p.color} 50%, #000 100%);
`

const SaturationSlider = styled(Slider)`
  background-image: linear-gradient(90deg, #777777 0%, ${p => p.color} 100%);
`

const HslPicker = ({ h, s, l, onChange }) => {
  const color = useMemo(() => `hsl(${h}, ${s}%, ${l}%)`, [h, l, s])
  const sliderColor = useMemo(() => `hsl(${h}, 100%, 50%)`, [h])

  const handleChange = useCallback((value, attribute) => {
    onChange(h, attribute === "s" ? value : s, attribute === "l" ? value : l)
  }, [h, l, onChange, s])

  return (
    <Container vertical>
      <ColorBox color={color} />
      <Customizer vertical>
        <Typography>Brillo</Typography>
        <Spacer vertical size={1} />
        <LightnessSlider
          type="range"
          min={0}
          max={100}
          value={l}
          color={sliderColor}
          onChange={(e) => handleChange(e.target.value, 'l')}
        />
        <Typography>Saturación</Typography>
        <Spacer vertical size={1} />
        <SaturationSlider
          type="range"
          min={0}
          max={100}
          value={s}
          color={sliderColor}
          onChange={(e) => handleChange(e.target.value, 's')}
        />
      </Customizer>
    </Container>
  )
}

export default HslPicker
