import { Typography } from "@mui/material"
import React from "react"
import { useCallback } from "react"
import { FlexContainer, Spacer } from "../../../components"
import ColorHSLPickerBox from "./ColorHSLPickerBox"
import ColorPickerBox from "./ColorPickerBox"

const CustomColors = ({ colors, onMainChange, onVariantChange }) => {
  
  const handleMainChange = useCallback((color, attribute) => {
    const { h, s, l } = color.hsl
    onMainChange(h, s, l, attribute)
  }, [onMainChange])

  return (
    <FlexContainer vertical>
      <Typography variant="h6">Personaliza los colores de tu showroom</Typography>
      <Spacer size={3} vertical />
      <Typography>Color Primario</Typography>
      <Spacer size={1} vertical />
      <FlexContainer>
        <ColorPickerBox color={colors?.primary} onChange={(color) => handleMainChange(color, "primary")} />
        <Spacer size={2} />
        <ColorHSLPickerBox color={colors?.primary80} onChange={(color) => onVariantChange(color, "primary80")} />
        <Spacer size={2} />
        <ColorHSLPickerBox color={colors?.primary60} onChange={(color) => onVariantChange(color, "primary60")} />
      </FlexContainer>
      <Spacer size={3} vertical />
      <Typography>Color Secundario</Typography>
      <Spacer size={1} vertical />
      <ColorPickerBox color={colors?.secondary} onChange={(color) => handleMainChange(color, "secondary")} />
      <Spacer size={3} vertical />
      <Typography>Color Neutral</Typography>
      <Spacer size={1} vertical />
      <FlexContainer>
        <ColorPickerBox color={colors?.neutral80} onChange={(color) => handleMainChange(color, "neutral80")} />
        <Spacer size={2} />
        <ColorHSLPickerBox color={colors?.neutral60} onChange={(color) => onVariantChange(color, "neutral60")} />
        <Spacer size={2} />
        <ColorHSLPickerBox color={colors?.neutral20} onChange={(color) => onVariantChange(color, "neutral20")} />
      </FlexContainer>
    </FlexContainer>
  )
}

export default CustomColors
