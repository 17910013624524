import { Delete as DeleteIcon } from '@mui/icons-material';
import { Box, Button, Card, IconButton, Typography } from '@mui/material';
import React from 'react';
import HoverCardMedia from '../../../../modules/Cards/HoverCardMedia';

const ProjectCard = ({ name, gallery, onEdit, onDelete }) => {
  return (
    <Card sx={{ maxWidth: 333, width: 1 }}>
      <HoverCardMedia src={gallery[0].url} height={194} alt={name}>
        <Button variant="contained" color="primary" onClick={onEdit}>
          Editar
        </Button>
      </HoverCardMedia>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 1,
          px: 2,
        }}
      >
        <Typography>{name}</Typography>
        {onDelete && (
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </Card>
  );
};

export default ProjectCard;
