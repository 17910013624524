import { FlexContainer, WidgetContainer, WidgetTitle, Spacer, CompareData } from "../../components"
import { useEffect, useState } from "react"
import { get } from '../../helpers/request'
import { useSelector } from "react-redux"
import useScreenWidthBelowEqual from "../../hooks/useScreenWidthBelowEqual"

const TITLE = "Cotizaciones Generadas"
const TOOLTIP = "Cotizaciones totales generadas agrupadas por: las generadas a través de su página web y las generadas por su equipo de ventas"
const VIA_WEBSITE = "VÍA PÁGINA WEB"
const VIA_SALES_TEAM = "VÍA VENTAS"

const QuotesGenerated = () => {
  const [data, setData] = useState()
  const selectedSite = useSelector(state => state.site)
  const isBelowEqual1280 = useScreenWidthBelowEqual(1280)

  useEffect(() => {
    if (selectedSite) {
      get('/internal/developer/v3/dashboard/quotesgenerated', { site_id: selectedSite.site_id })
        .then(result => setData(result))
        .catch(error => {
          console.error(error)
        })
    }
  }, [selectedSite])

  const dataA = {
    data: data && data.via_website,
    bottomCaption: VIA_WEBSITE
  }
  const dataB = {
    data: data && data.via_sales_team,
    bottomCaption: VIA_SALES_TEAM
  }
  return (
    <WidgetContainer width={isBelowEqual1280 ? 5 : 2}>
      <>
        <WidgetTitle title={TITLE} tooltip={TOOLTIP} />
        <Spacer vertical size={3} />
        <FlexContainer centered vertical fullHeight verticalCentered>
          <CompareData dataSize="medium"
            dataA={dataA}
            dataB={dataB} />
        </FlexContainer>
        <Spacer vertical size={2} />
      </>
    </WidgetContainer>
  )
}

export default QuotesGenerated
