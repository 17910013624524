import { Typography } from "@mui/material"
import React from "react"
import styled from "styled-components"
import mediaIcon from "../../assets/svgs/showroom_sections/lots-view.svg"

const Container = styled.button`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  min-width: 250px;
  height: 140px;
  min-height: 140px;
  border: 1px dashed rgba(0, 0, 0, 0.26);
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.04);
  cursor: pointer;
`

const Icon = styled.img`
  width: 50px;
  height: 50px;
`

const NewMediaButton = ({ onClick }) => (
  <Container onClick={onClick}>
    <Icon src={mediaIcon} alt="añadir imagen o video" />
    <Typography>Añade una imagen o video</Typography>
  </Container>
)

export default NewMediaButton
