import { Button, Typography } from "@mui/material"
import React from "react"
import { FlexContainer, Spacer } from "../../components"
import PageLayout from "../../layouts/PageLayout"
import WelcomeImage from "../../assets/images/welcome.png"
import Link from '@mui/material/Link';
import { useCallback } from "react"
import { useNavigate } from "react-router-dom";

const WelcomeView = () => {
  const navigate = useNavigate()

  const goToProfile = useCallback(() => {
    navigate('/profile')
  }, [navigate])

  return (
    <PageLayout>
      <FlexContainer vertical>
        <Spacer vertical size={4} />
        <Typography variant="h2">¡Te damos la bienvenida a la familia alohome!</Typography>
        <Spacer vertical size={4} />
        <FlexContainer topped>
          <FlexContainer vertical>
            <Typography><b>¿Qué es alohome?</b></Typography>
            <Spacer vertical size={2} />
            <Typography>¡Somos la <b>tecnología</b> que está cambiando las ventas inmobiliarias en América Latina!</Typography>
            <Spacer vertical size={3} />
            <Typography>🕤 Ayudamos a acelerar los ciclos comerciales</Typography>
            <Typography>🔥 Aumentamos la conversión de tráfico a cotizaciones</Typography>
            <Typography>🔑 Creamos herramientas que facilitan los cierres con los clientes</Typography>
            <Spacer vertical size={4} />
            <Button color="primary" variant="contained" sx={{ width: 135 }} onClick={goToProfile}>Continuar</Button>
            <Spacer vertical size={5} />
            <Typography>(Si quieres conocernos más, <Link href="https://alohome.io" target="_blank" color="text.primary">puedes dar clic aquí</Link>)</Typography>
          </FlexContainer>
          <img src={WelcomeImage} alt="bienvenido" />
        </FlexContainer>
      </FlexContainer>
    </PageLayout>
  )
}

export default WelcomeView
