import { Grid } from "@mui/material"
import React, { useCallback, useEffect, useState } from 'react'
import styled from "styled-components"
import { findAssetFromAssetId } from "../../helpers/utils"
import AssetCard from './AssetCard'
import EmptyAssetCard from './EmptyAssetCard'

const StyledGridItem = styled(Grid)`
  @media (min-width: 641px) {
    flex-basis: 100%;
    max-width: 100%;
  }
  @media (min-width: 780px) {
    flex-basis: 50%;
    max-width: 50%;
  }
  @media (min-width: 1024px) {
    flex-basis: calc(100% / 3);
    max-width: calc(100% / 3);
  }
`

const insertToArray = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index + 1)
]

const removeFromArray = (arr, index) => [
  ...arr.slice(0, index),
  {},
  ...arr.slice(index + 1)
]

const ASSETS_URL = "https://assets.alohome.io/images"

const AssetGridItemCard = ({
  assetId,
  imageSrc,
  assets,
  setAssets,
  defaultAssetLabel,
  assetType,
  defaultUploadLabel,
  maxImageSizeLabel,
  maxImageSizeErrorLabel,
  uploadingLabel,
  label,
  editLabel,
  saveLabel,
  deleteAssetLabel,
  data,
  updateData,
  metaData,
  tags,
  getArrayData,
  objectKey,
  onDelete }) => {
  const [uploadedAssetId, setUploadedAssetId] = useState(assetId || null)
  const [thumbImage, setThumbImage] = useState(null)

  const handleArrayContentUpdate = useCallback((item) => {
    const getIndex = getArrayData()

    updateData(prev => insertToArray(prev, getIndex, item))
  }, [getArrayData, updateData])

  const handleObjectContentUpdate = useCallback((item) => {
    if(!objectKey) return updateData(item)
    updateData(prev => { return {...prev, [objectKey]: item} })
  }, [objectKey, updateData])
  
  const handleArrayItemDelete = useCallback(() => {
    const getIndex = getArrayData()

    updateData(prev => removeFromArray(prev, getIndex))
    
    if(onDelete) onDelete()
  }, [getArrayData, onDelete, updateData])
  
  const handleObjectItemDelete = useCallback(() => {
    if(!objectKey) return updateData({})
    updateData(prev => { return {...prev, [objectKey]: {type: assetType}} })
  }, [assetType, objectKey, updateData])

  const handleChange = useCallback((item) => {
    if(getArrayData) {
      return handleArrayContentUpdate(item)
    }
    handleObjectContentUpdate(item)
  },[getArrayData, handleArrayContentUpdate, handleObjectContentUpdate])

  const handleUpdate = useCallback((assetId, newData) => {
    const item = findAssetFromAssetId(assetId, data)[0]
    handleChange({...item, metadata: {...item.metadata, name: newData} })
  },[data, handleChange])

  const handleDelete = useCallback((assetType) => {
    if(getArrayData) {
      return handleArrayItemDelete(assetType)
    }
    handleObjectItemDelete(assetType)
  },[getArrayData, handleArrayItemDelete, handleObjectItemDelete ])

  useEffect(() => {
    if(uploadedAssetId) setThumbImage(`${ASSETS_URL}/${uploadedAssetId}`)
  }, [uploadedAssetId])
  
  return (
    <StyledGridItem item xs={2} sm={4} md={4}>
      {(uploadedAssetId) ?
        <AssetCard
          src={imageSrc || thumbImage}
          label={label || defaultAssetLabel}
          height={194}
          sx={{ m: 1, maxWidth: 330, width: 1 }}
          assetId={uploadedAssetId}
          setAssetId={setUploadedAssetId}
          assetType={assetType}
          assets={assets}
          setAssets={setAssets}
          editLabel={editLabel}
          saveLabel={saveLabel}
          deleteAssetLabel={deleteAssetLabel}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
          assetMetadata={metaData}
        />
      :
        <EmptyAssetCard
          uploadLabel={defaultUploadLabel}
          maxImageSizeLabel={maxImageSizeLabel}
          maxImageSizeErrorLabel={maxImageSizeErrorLabel}
          uploadingLabel={uploadingLabel}
          assetLabel={defaultAssetLabel}
          height={178}
          sx={{ m: 1, maxWidth: 330, width: 1 }}
          assetType={assetType}
          setAssets={setAssets}
          setAssetId={setUploadedAssetId}
          handleChange={handleChange}
          tags={tags}
        />
      }
    </StyledGridItem>
  )
}

export default AssetGridItemCard
