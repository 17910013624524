import {
  Add as AddIcon,
  Close as CloseIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import { Button, IconButton, Typography } from '@mui/material';
import React, { useCallback, useState, useMemo, useRef } from 'react';
import { DiscardChangesDialog } from '../../../../modules';
import Images from './Images';
import MainInformation from './MainInformation';
import validateProjectFields from './validateProjectFields';

function getEmptyProject() {
  return {
    name: '',
    gallery: [],
    location: '',
  };
}

const ProjectDetails = ({
  fields: initialFields,
  isEditing,
  onClose,
  onSubmit,
}) => {
  const initialState = useRef(initialFields || getEmptyProject());
  const [fields, setFields] = useState(initialState.current);
  const [openConfirmDiscard, setOpenConfirmDiscard] = useState(false);
  const [errors, setErrors] = useState([]);

  const setProperty = useCallback((property, value) => {
    setFields((prev) => ({ ...prev, [property]: value }));
  }, []);

  const addImage = useCallback(
    ({ url, assetId }) =>
      setFields((prev) => ({
        ...prev,
        gallery: [
          {
            url,
            assetId,
          },
        ],
      })),
    [],
  );

  const deleteImage = useCallback(() => {
    setFields((prev) => {
      return {
        ...prev,
        gallery: [],
      };
    });
  }, []);

  const handleSubmit = useCallback(
    (fields) => {
      const err = validateProjectFields(fields);
      if (err.length === 0) onSubmit(fields);
      else setErrors(err);
    },
    [onSubmit],
  );

  const isDisabled = useMemo(
    () => (validateProjectFields(fields).length === 0 ? false : true),
    [fields],
  );

  const openDialog = useCallback(() => setOpenConfirmDiscard(true), []);
  const closeDialog = useCallback(() => setOpenConfirmDiscard(false), []);

  const changeHasBeenMade = initialState.current !== fields;

  return (
    <>
      <DiscardChangesDialog
        open={openConfirmDiscard}
        onClose={closeDialog}
        onSave={() => handleSubmit(fields)}
        onDiscard={onClose}
      />
      <IconButton
        onClick={changeHasBeenMade ? openDialog : onClose}
        sx={{ ml: 'auto' }}
      >
        <CloseIcon />
      </IconButton>
      <MainInformation
        setProperty={setProperty}
        name={fields.name}
        location={fields.location}
      />
      <Images images={fields.gallery} onAdd={addImage} onDelete={deleteImage} />
      <Button
        variant="contained"
        color="primary"
        disabled={isDisabled}
        startIcon={isEditing ? <SaveIcon /> : <AddIcon />}
        onClick={() => handleSubmit(fields)}
        sx={{ mr: 'auto' }}
      >
        {isEditing ? 'Actualizar proyecto' : 'Agregar proyecto'}
      </Button>
      {errors.map((error) => (
        <Typography key={error} variant="body2" color="error" sx={{ mt: 1 }}>
          {error}
        </Typography>
      ))}
    </>
  );
};

export default ProjectDetails;
