import {
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { useMemo } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { FlexContainer, PendingButton, Spacer } from '../../components';
import { getGeneral } from '../../helpers/draft';
import { post, put } from '../../helpers/request';
import { configTabs } from '../../helpers/tabsData';
import { convertToY, useSharedDocument } from '../../hooks/documentHooks';
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt';
import useSite from '../../hooks/useSite';
import PageLayout from '../../layouts/PageLayout';
import { AdvanceFeatureModal, DiscardChangesDialog } from '../../modules';

const PUT_INTEGRATIONS = '/internal/settings/v1/settings/showroom/integrations';

const DEFAULT_DATA = {
  head: '',
  body: '',
  enabled: false,
};

const CheckLabel = styled(FormControlLabel)`
  margin-left: 0;
`;

const IntegrationsView = () => {
  const dispatch = useDispatch();
  const { site } = useSite();
  const [yDocument, , documentReady] = useSharedDocument(site?.site_id, {
    ws: process.env.REACT_APP_WEBSOCKET,
  });
  const [viewWarningModal, setViewWarningModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [savedData, setSavedData] = useState(DEFAULT_DATA);
  const [formData, setFormData] = useState(DEFAULT_DATA);

  const enableSave = useMemo(
    () => !isEqual(formData, savedData),
    [formData, savedData]
  );
  const [showPrompt, confirmNavigation] = useCallbackPrompt(enableSave);

  useEffect(() => {
    const getIntegrations = async () => {
      if (site?.site_id) {
        const dataMap = yDocument && yDocument?.getMap('general');
        let dataJson = dataMap?.toJSON();
        if (!!dataJson && Object.keys(dataJson).length === 0 && !!dataMap) {
          dataJson = await getGeneral(site);
        }
        if (Object.keys(dataJson?.tags ?? {}).length > 0) {
          const data = {
            head: dataJson?.tags?.head ?? '',
            body: dataJson?.tags?.body ?? '',
            enabled: dataJson?.tags?.enabled,
          };
          setFormData(data);
          setSavedData(data);
        }
        setLoading(false);
      }
    };
    if (documentReady) {
      getIntegrations();
    }
    // eslint-disable-next-line
  }, [site?.site_id, yDocument, documentReady]);

  const openPlans = useCallback(() => {
    dispatch({ type: 'open-plans-modal' });
  }, [dispatch]);

  const resetForm = useCallback(() => setFormData(DEFAULT_DATA), []);

  const handleChange = useCallback((value, attribute) => {
    setFormData((d) => ({
      ...d,
      [attribute]: value,
    }));
  }, []);

  const handleActivationChange = useCallback(
    (e) => {
      if (e.target.checked) {
        setViewWarningModal(true);
      } else {
        resetForm();
      }
    },
    [resetForm]
  );

  const handleCloseWarningModal = useCallback(
    () => setViewWarningModal(false),
    []
  );

  const handleAcceptWarningModal = useCallback(() => {
    setViewWarningModal(false);
    handleChange(true, 'enabled');
  }, [handleChange]);

  const handlePublish = useCallback(async () => {
    if (site?.remainingDays <= 0) {
      openPlans();
    } else if (site?.site_id) {
      setSavedData(formData);
      put(PUT_INTEGRATIONS, formData, site?.site_id);
      const newTags = {
        head: formData.head,
        body: formData.body,
        enabled: formData.enabled,
      };
      const changes = [
        {
          path: 'tags',
          value: newTags,
        },
      ];
      const dataMap = yDocument && yDocument?.getMap('general');

      dataMap.set('tags', convertToY(newTags));

      await post(
        '/internal/developer/v1.1/files',
        {
          changes,
          stage: ['draft', 'production'],
          fileName: 'general',
        },
        site?.site_id
      );
    }
  }, [formData, openPlans, site?.remainingDays, site?.site_id, yDocument]);

  const saveAndLeave = useCallback(() => {
    handlePublish();
    confirmNavigation();
  }, [confirmNavigation, handlePublish]);

  return (
    <>
      <DiscardChangesDialog
        open={showPrompt}
        onSave={saveAndLeave}
        onDiscard={confirmNavigation}
      />
      <AdvanceFeatureModal
        open={viewWarningModal}
        onClose={handleCloseWarningModal}
        onAccept={handleAcceptWarningModal}
      >
        <Typography>
          La integración de aplicaciones de terceros es una funcionalidad que
          solo debe ser usada por personas con conocimientos técnicos.
        </Typography>
        <Spacer vertical size={2} />
        <Typography>
          El mal uso de esta función puede ocasionar fallas en su Showroom
          digital.
        </Typography>
      </AdvanceFeatureModal>
      <PageLayout
        links={configTabs}
        loading={loading}
        menu={
          <PendingButton
            text="Guardar"
            onClick={handlePublish}
            showBullet={enableSave}
          />
        }
      >
        <FlexContainer vertical>
          <Spacer vertical size={2} />
          <Typography variant="h5">
            Integraciones: mide todo lo que sucede en tu showroom
          </Typography>
          <Spacer size={1} vertical />
          <Typography>
            Agregar herramientas de análisis (como{' '}
            <b>Google Analytics, Adwords o el píxel de Facebook</b>) te
            permitirá recolectar información sobre el comportamiento y las
            conversiones de los visitantes en tu showroom digital.
          </Typography>
          <Spacer size={6} vertical />
          <FormGroup aria-label="position" row>
            <CheckLabel
              control={
                <Switch
                  color="primary"
                  checked={formData.enabled}
                  onChange={handleActivationChange}
                />
              }
              label="Activar integraciones"
              labelPlacement="start"
            />
          </FormGroup>
          <Spacer size={6} vertical />
          <TextField
            label="<head/>"
            multiline
            variant="outlined"
            minRows={3}
            maxRows={12}
            inputProps={{ style: { fontFamily: '"Courier New", monospace' } }}
            placeholder={`<!-- Ingresa tu código aquí -->\n<script>\n  alert("Hello");\n</script>`}
            disabled={!formData.enabled}
            value={formData.head}
            onChange={(e) => handleChange(e.target.value, 'head')}
          />
          <Spacer size={6} vertical />
          <TextField
            label="<body/>"
            multiline
            variant="outlined"
            minRows={3}
            maxRows={12}
            inputProps={{ style: { fontFamily: '"Courier New", monospace' } }}
            placeholder={`<!-- Ingresa tu código aquí -->\n<script>\n  alert("Hello");\n</script>`}
            disabled={!formData.enabled}
            value={formData.body}
            onChange={(e) => handleChange(e.target.value, 'body')}
          />
          <Spacer vertical size={4} />
        </FlexContainer>
      </PageLayout>
    </>
  );
};

export default IntegrationsView;
