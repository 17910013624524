import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { get } from '../helpers/request'

const useSettings = (category = "general", subcategory = "currency") => {
  const selectedSite = useSelector(state => state.site)
  const settings = useSelector(state => state.settings && state.settings[category] && state.settings[category][subcategory])
  const dispatch = useDispatch()

  useEffect(() => {
    if (selectedSite) {
      get(`/internal/settings/v1/settings?category=${category}&subcategory=${subcategory}`, { site_id: selectedSite.site_id })
        .then(result => {
          dispatch({ type: `set-${category}-${subcategory}`, settings: result })
        })
        .catch(error => {
          console.error(error)
        })
    }
  }, [category, dispatch, selectedSite, subcategory])

  return settings
}

export default useSettings