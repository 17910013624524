import React, { Fragment, useCallback, useMemo } from 'react';
import { FlexContainer, IconInput, Spacer } from '../../../components';
import { Button, Typography } from '@mui/material';
import WhatsappIcon from '../../../assets/icons/whatsapp.svg';
import PhoneIcon from '../../../assets/icons/phone.svg';
import MailIcon from '../../../assets/icons/mail.svg';
import LocationIcon from '../../../assets/icons/location.svg';
import AddIcon from '@mui/icons-material/Add';
import {
  CONTACT_METHODS_PATH,
  getPathValue,
  modifyPath,
} from '../../../helpers/data';

const ICONS = [
  { value: 'phone', src: PhoneIcon, alt: 'Teléfono' },
  { value: 'whatsapp', src: WhatsappIcon, alt: 'Whatsapp' },
  { value: 'email', src: MailIcon, alt: 'Email' },
  { value: 'address', src: LocationIcon, alt: 'Dirección' },
];

/*
CONFIG.JS DEFINITIONS
phone: {
  content: "55 1234 5678"
},
whatsapp: {
  content: "55 0987 6543"
},
email: {
  content: "contacto@midominio.com"
},
address: {
  content: "Calle y número"
}
*/

const ContactForms = ({ data, setData, pathData, setPathData }) => {
  // @deprecated: pathData. Before, we were using the contact_methods property
  // contained in section's data. Now we use top level contact_methods
  const contactData = useMemo(
    () => getPathValue(data, CONTACT_METHODS_PATH) || pathData || {},
    [data, pathData]
  );

  const setContactData = useCallback(
    (v) => {
      // @deprecated: setPathData. Components should read from top level
      // contact_methods
      setPathData(v);
      setData((prev) => {
        modifyPath(prev, CONTACT_METHODS_PATH, v);
        return { ...prev };
      });
    },
    [setData, setPathData]
  );

  const currentContactForms = useMemo(
    () => Object.keys(contactData ?? {}),
    [contactData]
  );

  const getAvailableIcons = useCallback(
    (currentIcon) => {
      const availableContactForms = ICONS.filter(
        (i) => !currentContactForms.includes(i.value) || i.value === currentIcon
      );
      return availableContactForms;
    },
    [currentContactForms]
  );

  const getInputLabel = useCallback((attribute) => {
    const icon = ICONS.find((i) => i.value === attribute);
    return icon?.alt;
  }, []);

  const handleAdd = useCallback(() => {
    const availableContactForms = ICONS.filter(
      (i) => !currentContactForms.includes(i.value)
    );
    const contactFormToAdd = availableContactForms[0];
    if (contactFormToAdd) {
      setContactData({
        ...contactData,
        [contactFormToAdd.value]: {
          content: '',
        },
      });
    }
  }, [currentContactForms, setContactData, contactData]);

  const handleDelete = useCallback(
    (attribute) => {
      let newValue = { ...contactData };
      delete newValue[attribute];
      setContactData(newValue);
    },
    [setContactData, contactData]
  );

  const handleLabelChange = useCallback(
    (inputValue, attribute) => {
      const newValue = {
        ...contactData,
        [attribute]: {
          ...contactData[attribute],
          content: inputValue,
        },
      };
      setContactData(newValue);
    },
    [setContactData, contactData]
  );

  const handleIconChange = useCallback(
    (icon, attribute) => {
      const currentValue = contactData[attribute];
      let newValue = { ...contactData };
      delete newValue[attribute];
      newValue[icon] = currentValue;
      setContactData(newValue);
    },
    [setContactData, contactData]
  );

  return (
    <FlexContainer vertical>
      <FlexContainer justified verticalCentered>
        <Typography variant="caption" color="text.secondary">
          Formas de contacto
        </Typography>
        {currentContactForms.length < ICONS.length && (
          <Button
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            onClick={handleAdd}
          >
            Añadir
          </Button>
        )}
      </FlexContainer>
      {contactData &&
        Object.entries(contactData)
          .filter(([key]) => getInputLabel(key))
          .sort((a, b) => a[1].order - b[1].order)
          .map(([key, social]) => (
            <Fragment key={key}>
              <Spacer vertical size={2} />
              <IconInput
                icons={getAvailableIcons(key)}
                text={social.content}
                icon={key}
                label={getInputLabel(key)}
                onDelete={() => handleDelete(key)}
                onTextChange={(e) => handleLabelChange(e.target.value, key)}
                onIconChange={(e) => handleIconChange(e.target.value, key)}
              />
            </Fragment>
          ))}
    </FlexContainer>
  );
};

export default ContactForms;
