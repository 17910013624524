import React, { createContext, useCallback, useMemo, useState } from "react";

export const OnboardingContext = createContext(null);

const OnboardingProvider = ({ data, children }) => {
  const [onboardingData, setOnboardingData] = useState({});
  const [currentStep, setCurrentStep] = useState(1);

  const onNextStep = useCallback(() => setCurrentStep((s) => s + 1), []);
  const onPreviousStep = useCallback(() => setCurrentStep((s) => s - 1), []);
  const value = useMemo(
    () => ({
      onboardingData,
      setOnboardingData,
      currentStep,
      onNextStep,
      onPreviousStep,
    }),
    [onboardingData, currentStep, onNextStep, onPreviousStep]
  );

  return (
    <OnboardingContext.Provider value={value}>
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingProvider;
