import { useEffect, useMemo, useState } from "react"
import * as Y from 'yjs'
import { WebsocketProvider } from 'y-websocket'
import { isArray, isObject } from "../utils/validations"

const status = {}

const convertToY = (data, path = '') => {
  if (isArray(data)) {
    const yArray = new Y.Array();
    data.forEach((entry) => yArray.push([convertToY(entry)]));
    return yArray;
  } else if (data instanceof Date) {
    return data.toISOString();
  } else if (isObject(data) && !isYMap(data) && !isYArray(data)) {
    const yMap = new Y.Map();
    Object.entries(data).forEach(([key, value]) =>
      yMap.set(key, convertToY(value, path + ' ' + key))
    );
    return yMap;
  } else {
    return data;
  }
}

const convertToYText = (data) => {
  return isYText(data) ? data : new Y.Text(data.toString() ?? '')
}
const isYMap = (element) => element instanceof Y.Map
const isYArray = (element) => element instanceof Y.Array
const isYText = (element) => element instanceof Y.Text

const useSharedDocument = (id, options) => {
  const [document, setDocument] = useState()
  const [webSocket, setWebSocket] = useState()
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (!id) return
    setReady(false)
    const doc = new Y.Doc()
    let ws = null
    if (options.ws) {
      ws = new WebsocketProvider(options.ws, id, doc)
      ws.on('status', event => {
        status[id] = event.status
      })
      ws.on('sync', () => {
        setReady(true)
      })
    }
    setDocument(doc)
    setWebSocket(ws)
    return () => {
      if (options.ws) ws.destroy()
      doc.destroy()
      setWebSocket(null)
    }
  }, [id, options.ws])

  return [document, webSocket, ready]
}

const useDelayedYText = (object, delay = 1500) => {
  const [forceUpdate, setForceUpdate] = useState({})
  const [data, setData] = useState()

  useEffect(() => {
    const timer = setTimeout(() => setData(object.toString()), delay)
    return () => clearTimeout(timer)
  }, [object, delay, forceUpdate])

  useEffect(() => {
    if (!object) return null
    const observer = () => setForceUpdate({})
    if (typeof object.observe !== 'function') return
    object.observe(observer)
    return () => object.unobserve(observer)
  }, [object])

  return data
}

const getYObjectKeys = (object) => {
  if (!object) return null
  if (isYMap(object)) {
    const result = {}
    for (let key of object.keys()) {
      result[key] = object.get(key)
    }
    return result
  } else if (isYArray(object)) {
    return object ? object.toArray() : null
  } else {
    throw new Error('Not an Y object')
  }
}

const useYObject = (object, deep = false) => {
  const [forceUpdate, setForceUpdate] = useState({})
  const data = useMemo(() => {
    if (!object) return null
    if (isYMap(object)) {
      const result = {}
      for (let key of object.keys()) {
        result[key] = object.get(key)
      }
      return result
    } else if (isYArray(object)) {
      return object ? object.toArray() : null
    } else {
      throw new Error('Not an Y object')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [object, forceUpdate])

  useEffect(() => {
    if (!object) return null
    const observer = () => setForceUpdate({})
    if (typeof object.observe !== 'function') return
    if (deep) {
      object.observeDeep(observer)
      return () => object.unobserveDeep(observer)
    }
    else {
      object.observe(observer)
      return () => object.unobserve(observer)
    }
  }, [object, deep])

  return data
}

export { useSharedDocument, useYObject, useDelayedYText, convertToY, convertToYText, isYArray, isYMap, isYText, getYObjectKeys }