import useMediaQuery from './useMediaQuery'

const useBreakpoints = () => {
  const breakpoints = {
    isXs: useMediaQuery('(max-width: 640px)'),
    isSm: useMediaQuery('(min-width: 641px) and (max-width: 832px)'),
    isMd: useMediaQuery('(min-width: 833px) and (max-width: 1024px)'),
    isLg: useMediaQuery('(min-width: 1025px)'),
    isSmOrLower: useMediaQuery('(max-width: 832px)'),
    active: '',
  };
  if (breakpoints.isXs) breakpoints.active = 'xs'
  if (breakpoints.isSm) breakpoints.active = 'sm'
  if (breakpoints.isMd) breakpoints.active = 'md'
  if (breakpoints.isLg) breakpoints.active = 'lg'
  return breakpoints
};

export default useBreakpoints
