import { Delete as DeleteIcon } from "@mui/icons-material";
import { Box, Button, Card, IconButton, Typography } from "@mui/material";
import React from "react";
import HoverCardMedia from "../../../../modules/Cards/HoverCardMedia";

const TypologyCard = ({ fields, onEdit, onDelete }) => {
  return (
    <Card sx={{ maxWidth: 333, width: 1 }}>
      <HoverCardMedia
        src={fields.images?.[0]?.url}
        height={194}
        alt={fields.name.es}
      >
        <Button variant="contained" color="primary" onClick={onEdit}>
          Editar
        </Button>
      </HoverCardMedia>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          py: 1,
          px: 2,
        }}
      >
        <Typography>{fields.name.es}</Typography>
        {onDelete && (
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </Card>
  );
};

export default TypologyCard;
