import amenitiesIcon from "../assets/svgs/showroom_sections/amenities.svg"
import carouselProjectsIcon from "../assets/svgs/showroom_sections/carousel-projects.svg"
import workProgressIcon from "../assets/svgs/showroom_sections/work-progress.svg"
import carouselTypologiesIcon from "../assets/svgs/showroom_sections/carousel-typologies.svg"
import flexMediaIcon from "../assets/svgs/showroom_sections/flex-media.svg"
import imageBannerIcon from "../assets/svgs/showroom_sections/image-banner.svg"
import lotsViewIcon from "../assets/svgs/showroom_sections/lots-view.svg"
import masonryGalleryIcon from "../assets/svgs/showroom_sections/masonry-gallery.svg"
import smallMapIcon from "../assets/svgs/showroom_sections/small-map.svg"
import splitContactIcon from "../assets/svgs/showroom_sections/split-contact.svg"

export const TRIAL_DAYS = 21

export const PLANS = {
  ENTREPRENEUR: "ENTREPRENEUR",
  GROWTH: "GROWTH",
  ENTERPRISE: "ENTERPRISE",
}

export const SITE_STATUS = {
  trial: "trial",
  paid: "paid",
  expired: "expired",
}

export const INVENTORY_TYPE = {
  unit: 'unit',
  typology: 'typology',
}

export const SECTIONS_DATA = {
  "image-banner-section": {
    icon: imageBannerIcon,
    label: "Banner"
  },
  "flex-media-section": {
    icon: flexMediaIcon,
    label: "Texto/Video"
  },
  "carousel-typologies": {
    icon: carouselTypologiesIcon,
    label: "Tipologías"
  },
  "masonry-gallery-section": {
    icon: masonryGalleryIcon,
    label: "Galería"
  },
  "amenities-section": {
    icon: amenitiesIcon,
    label: "Amenidades"
  },
  "small-map-section": {
    icon: smallMapIcon,
    label: "Ubicación"
  },
  "split-contact-section": {
    icon: splitContactIcon,
    label: "Contacto"
  },
  "carousel-projects": {
    icon: carouselProjectsIcon,
    label: "Desarrollador"
  },
  "transparency-construction-progress-section": {
    icon: workProgressIcon,
    label: "Avance de obra"
  },
  "lots-view-section": {
    icon: lotsViewIcon,
    label: "Lotes"
  },
}

export const ROLES = [{
  id: 'SUPERVISOR',
  label: 'Supervisor',
  permissionPath: ['team', 'supervisor', 'create_supervisor']
}, {
  id: 'SALES',
  label: 'Asesor',
  permissionPath: ['team', 'sales', 'create_sales']
}]

export const SALES_TYPES = [{
  id: 'INTERNAL',
  label: 'Interno',
}, {
  id: 'EXTERNAL',
  label: 'Externo',
}]

export const USER_SITE_STATUS = {
  active: "active",
  pending: "pending",
  deactivated: "deactivated",
}
