import { useCallback, useEffect, useMemo, useState } from "react"
import * as uuid from 'uuid'
import { INVENTORY_TYPE } from "../../helpers/constants"
import { generateRandomId } from "../../helpers/utils"

export const TYPE = {
  flexible: 1,
  fixed: 2,
}

export const DEED_COST_TYPE = {
  percentage: "percentage",
  category: "category",
}

export const DEED_COST_CATEGORIES = [{
  id: "NOTARY_FEES",
  label: "Honorarios Notario",
}, {
  id: "ISAI",
  label: "ISAI",
}, {
  id: "PUBLIC_RECORD",
  label: "Registro público",
}, {
  id: "APPRAISALS",
  label: "Avalúos",
}]

export const EXTERNAL_LOAN_ENTITIES = [{
  id: "BANK_CREDIT",
  label: "Crédito bancario",
}, {
  id: "SOFOMES",
  label: "Sofomes",
}, {
  id: "INFONAVIT",
  label: "Infonavit",
}, {
  id: "COFINAVIT",
  label: "Cofinavit",
}, {
  id: "FOVISSTE",
  label: "Fovisste",
}]

export const MAX_PLAN_NAME_LENGTH = 25
export const MAX_PLAN_DESCRIPTION_LENGTH = 55

export const getDefaultParameters = (type, inventoryType, activePayments) => {
  if (type === TYPE.fixed) {
    return {
      ...(activePayments && { fixedOnlinePayment: '' }),
      expiration_days: 7,
      plans: [],
    }
  }
  return {
    ...(activePayments && { fixedOnlinePayment: '' }),
    ...(inventoryType === INVENTORY_TYPE.typology && { fixedNumberOfMonths: 12 }),
    discounts: {0: 0},
    expiration_days: 7,
    maxDownPaymentPercent: 100,
    minDownPaymentPercent: 0,
    minPreSalePercent: 10,
  }
}

export const getLowPriceTypology = (inventory) => {
  const typologies = inventory?.typology?.items
  if (typologies) {
    const priceObject = typologies[Object.keys(typologies)[0]].price
    const mainCurrency = Object.keys(priceObject)[0]
    const sortedTypologies = Object.entries(typologies)
      .sort(([,a], [,b]) => {
        return a.price[mainCurrency] - b.price[mainCurrency]
      })
    return sortedTypologies[0][0]
  }
}

export const useFlexibleQuotation = ({
  currentMinDownPaymentPercent,
  currentMaxDownPaymentPercent,
  currentMinPreSalePercent,
  currentDiscounts,
  currentFixedNumberOfMonths,
  hasPaymentSchemes,
  hasExternalLoan,
}) => {
  const [minDownPaymentPercent, setMinDownPaymentPercent] = useState(currentMinDownPaymentPercent ?? 0)
  const [maxDownPaymentPercent, setMaxDownPaymentPercent] = useState(currentMaxDownPaymentPercent ?? 100)
  const [minPreSalePercent, setMinPreSalePercent] = useState(currentMinPreSalePercent ?? 10)
  const [hasDiscounts, setHasDiscounts] = useState(currentDiscounts ?? false)
  const [discounts, setDiscounts] = useState(currentDiscounts ?? [])
  const [fixedNumberOfMonths, setFixedNumberOfMonths] = useState({ value: currentFixedNumberOfMonths ?? 12 })

  useEffect(() => {
    if (currentMinDownPaymentPercent) {
      setMinDownPaymentPercent(currentMinDownPaymentPercent)
    }
    if (currentMaxDownPaymentPercent) {
      setMaxDownPaymentPercent(currentMaxDownPaymentPercent)
    }
    if (currentMinPreSalePercent) {
      setMinPreSalePercent(currentMinPreSalePercent)
    }
    if (currentFixedNumberOfMonths) {
      setFixedNumberOfMonths({ value: currentFixedNumberOfMonths })
    }
    if (currentDiscounts) {
      const formattedDiscounts = []
      for (const [key, value] of Object.entries(currentDiscounts)) {
        const downPaymentPercent = Number(key)
        const discountPercent = Number(value)
        if (downPaymentPercent !== 0) {
          const id = uuid.v4()
          formattedDiscounts.push({
            id,
            downPaymentPercent,
            discountPercent,
          })
        }
      }
      setDiscounts(formattedDiscounts)
      if (formattedDiscounts.length >= 1) {
        setHasDiscounts(true)
      }
    }
  }, [currentDiscounts, currentFixedNumberOfMonths, currentMaxDownPaymentPercent, currentMinDownPaymentPercent, currentMinPreSalePercent])

  const onMinDownPaymentPercentChange = useCallback((value) => {
    if (value > maxDownPaymentPercent) {
      setMaxDownPaymentPercent(value)
    }

    if (value > minPreSalePercent) {
      setMinPreSalePercent(value)
    }
    
    setMinDownPaymentPercent(value)
  }, [maxDownPaymentPercent, minPreSalePercent])

  const onMaxDownPaymentPercentChange = useCallback((value) => {
    if (value < minDownPaymentPercent) {
      setMinDownPaymentPercent(value)
    }

    setMaxDownPaymentPercent(value)
  }, [minDownPaymentPercent])

  const onMinPreSalePercentChange = useCallback((value) => {
    if (value < minDownPaymentPercent) {
      setMinDownPaymentPercent(value)
    }

    setMinPreSalePercent(value)
  }, [minDownPaymentPercent])

  const onHasDiscountsChange = useCallback((value) => setHasDiscounts(value), [])
  
  const onAddNewDiscount = useCallback(() => {
    const id = uuid.v4()
    setDiscounts([...discounts, {
      id,
      downPaymentPercent: 0,
      discountPercent: 0,
    }])
  }, [discounts])

  const onDeleteDiscount = useCallback((index) => {
    const newDiscounts = discounts.filter((_, i) => i !== index)
    setDiscounts(newDiscounts)
  }, [discounts])

  const onChangeDiscount = useCallback((value, index, type) => {
    const matchValue = value.replace("%", "")?.match(/^[0-9]*\.?[0-9]*$/)[0] ?? 0
    const realValue = matchValue.includes(".") ? matchValue : Number(matchValue)
    const newDiscounts = discounts.map(
      (discount, i) => i === index ? { ...discount, [type]: realValue } : discount
    )
    setDiscounts(newDiscounts) 
  }, [discounts])

  const onBlurDiscount = useCallback((value, index, type) => {
    const matchValue = value.replace("%", "")?.match(/^[0-9]*\.?[0-9]*$/)[0] ?? 0
    const realValue = Number(matchValue)
    let previousValue = discounts[0]
    const newDiscounts = discounts.map((discount, i) => {
      const currentValue = i === index ? { ...discount, [type]: realValue > 100 ? 100 : realValue } : discount
      let downPaymentPercentError = false
      let discountPercentError = false
      if (i !== 0) {
        if (discount.downPaymentPercent < previousValue.downPaymentPercent) {
          downPaymentPercentError = true
        }
        if (discount.discountPercent < previousValue.discountPercent) {
          discountPercentError = true
        }
        previousValue = discount
      }
      return {
        ...currentValue,
        downPaymentPercentError,
        downPaymentPercentDirty: true,
        discountPercentError,
        discountPercentDirty: true,
      }
    })
    setDiscounts(newDiscounts) 
  }, [discounts])

  const onKeyUpDiscount = useCallback((event, index, type) => {
    const keyCode = event.code

    if (keyCode === 'Backspace') {
      const caretPosition = event.target.selectionStart
      const currentValue = event.target.value
      if (currentValue.includes('%') && currentValue.length === caretPosition) {
        const realValue = (event.target.value.replace("%", "")?.match(/^[0-9]*\.?[0-9]*$/)[0] ?? 0).slice(0, -1)
        const newDiscounts = discounts.map(
          (discount, i) => i === index ? { ...discount, [type]: realValue } : discount
        )
        setDiscounts(newDiscounts) 
      }
    }
  }, [discounts])

  const onFixedNumberOfMonthsChange = useCallback((value) => {
    if (!isNaN(value)) {
      if (value === '') {
        setFixedNumberOfMonths({ value: 0 })
      } else {
        setFixedNumberOfMonths({ value: Number(value) })
      }
    }
  }, [])

  const onFixedNumberOfMonthsError = useCallback((value) => {
    setFixedNumberOfMonths(n => ({ ...n, error: true }))
  }, [])

  return {
    minDownPaymentPercent,
    onMinDownPaymentPercentChange,
    maxDownPaymentPercent,
    onMaxDownPaymentPercentChange,
    minPreSalePercent,
    onMinPreSalePercentChange,
    hasDiscounts,
    onHasDiscountsChange,
    discounts,
    onAddNewDiscount,
    onDeleteDiscount,
    onChangeDiscount,
    onBlurDiscount,
    onKeyUpDiscount,
    fixedNumberOfMonths,
    onFixedNumberOfMonthsChange,
    onFixedNumberOfMonthsError,
  }
}

export const useFixedQuotation = ({
  currentPlans,
  currentMinPreSalePercent,
  hasPaymentSchemes,
  hasExternalLoan,
}) => {
  const [plans, setPlans] = useState(currentPlans ?? [])
  const [minPreSalePercent, setMinPreSalePercent] = useState(currentMinPreSalePercent ?? 10)
  const [planName, setPlanName] = useState('Contado')

  useEffect(() => {
    if (currentPlans) {
      const formattedPlans = currentPlans.map(p => {
        if (!p.id) {
          const id = uuid.v4()
          return {
            ...p,
            id
          }
        }
        return p
      })
      setPlans(formattedPlans)
    }

    if (currentMinPreSalePercent) {
      setMinPreSalePercent(currentMinPreSalePercent)
    }
  }, [currentMinPreSalePercent, currentPlans])

  const onAddNewPlan = useCallback(() => {
    const id = uuid.v4()
    const newPlans = [...plans, {
      id,
      name: 'Nombre del plan',
      description: '*(Opcional) Puedes añadir una descripción de tu plan',
      discount_percent: 0,
      down_payment_percent: 0,
      number_of_months: 0,
      monthly_payment_percent: 0,
    }]
    setPlans(newPlans)
  }, [plans])

  const onDeletePlan = useCallback((index) => {
    const newPlans = plans.filter((_, i) => i !== index)
    setPlans(newPlans)
  }, [plans])

  const onConfirmPlan = useCallback((plan, index) => {
    const newPlan = plans.map(
      (p, i) => i === index ? plan : p
    )
    setPlans(newPlan) 
  }, [plans])

  const onReorderPlan = useCallback((newPlans) => {
    setPlans(newPlans) 
  }, [])

  const onMinPreSalePercentChange = useCallback((value) => {
    setMinPreSalePercent(value)
  }, [])

  const onPlanNameChange = useCallback((value) => {
    if (value.length <= MAX_PLAN_NAME_LENGTH) {
      setPlanName(value)
    }
  }, [])

  return {
    plans,
    onAddNewPlan,
    onDeletePlan,
    onConfirmPlan,
    onReorderPlan,
    fixedMinPreSalePercent: minPreSalePercent,
    onFixedMinPreSalePercentChange: onMinPreSalePercentChange,
    planName,
    onPlanNameChange,
  }
}

const DEFAULT_DEED_COST_CATEGORIES = DEED_COST_CATEGORIES.reduce((prev, curr, i) => {
  let newObject = {
    ...prev,
    [curr.id]: {
      enable: false,
      amount: 0,
    },
  }

  if (i === DEED_COST_CATEGORIES.length - 1) {
    newObject = {
      ...newObject,
      OTHERS: {
        enable: false,
        categories: [],
      }
    }
  }

  return newObject
}, {})

const DEFAULT_EXTERNAL_LOAN_ENTITIES = EXTERNAL_LOAN_ENTITIES.reduce((prev, curr) => ({
  ...prev,
  [curr.id]: false,
}), {})

export const useInitialConfig = (quotation) => {
  const [initialConfig, setInitialConfig] = useState({
    deedCostsType: DEED_COST_TYPE.percentage,
    hasPaymentSchemes: null,
    hasExternalLoan: null,
    deedCostPercent: "",
  })

  useEffect(() => {
    if (quotation && quotation?.parameters) {
      const data = quotation.parameters
      const currentConfig = {
        deedCostsType: data.deedCostsType,
        hasPaymentSchemes: data.hasPaymentSchemes,
        hasExternalLoan: data.hasExternalLoan,
        ...(data.deedCostPercent && { deedCostPercent: data.deedCostPercent }),
        ...(data.externalLoanEntities && { externalLoanEntities: data.externalLoanEntities }),
        ...(data.deedCostCategories && { deedCostCategories: data.deedCostCategories }),
      }

      setInitialConfig(currentConfig)
    }
  }, [quotation])

  const formattedInitialConfig = useMemo(() => {
    let newConfig = { ...initialConfig }

    if (newConfig.deedCostsType === DEED_COST_TYPE.percentage) {
      newConfig = {
        ...newConfig,
        deedCostPercent: Number(newConfig.deedCostPercent)
      }
    }

    if (newConfig.deedCostsType === DEED_COST_TYPE.category) {
      let categories = {}
      Object.entries(newConfig.deedCostCategories).forEach(([key, value]) => {
        if (key === "OTHERS") {
          let customCategories = newConfig.deedCostCategories.OTHERS.categories.map((c) => ({
            ...c,
            category: c.category.trim(),
            amount: Number(c.amount)
          }))

          categories = {
            ...categories,
            [key]: {
              ...value,
              categories: customCategories
            }
          }
        } else {
          categories = {
            ...categories,
            [key]: {
              ...value,
              amount: Number(value.amount),
            }
          }
        }
      })

      newConfig = {
        ...newConfig,
        deedCostCategories: categories
      }
    }

    return newConfig
  }, [initialConfig])

  const enableContinue = useMemo(() => {
    if (initialConfig.hasPaymentSchemes == null || initialConfig.hasExternalLoan == null)
      return false
    
    if (initialConfig.deedCostsType === DEED_COST_TYPE.percentage
      && (Number(initialConfig.deedCostPercent) <= 0
        || Number(initialConfig.deedCostPercent) > 100)
    )
      return false

    if (initialConfig.deedCostsType === DEED_COST_TYPE.category) {
      let found = false
      let validated = true
      Object.entries(initialConfig.deedCostCategories).forEach(([key, value]) => {
        if (key === "OTHERS") {
          if (value.enable) {
            found = true

            if (value.categories.length === 0) {
              validated = false
            } else {
              value.categories.forEach((c) => {
                if (c.category.trim() === "" || Number(c.amount) <= 0) {
                  validated = false
                }
              })
            }
          }
        } else {
          if (value.enable) {
            found = true

            if (Number(value.amount) <= 0) {
              validated = false
            }
          }
        }
      })

      if (!(found && validated)) {
        return false
      }
    }

    if (initialConfig.hasExternalLoan) {
      let found = false
      Object.entries(initialConfig.externalLoanEntities).forEach(([key, value]) => {
        if (value) {
          found = true
        }
      })

      if (!found) {
        return false
      }
    }

    return true
  }, [initialConfig])

  const onInitialConfigChange = useCallback((value, attribute) => {
    setInitialConfig(i => {
      let newInitialConfig = {
        ...i,
        [attribute]: value
      }

      if (attribute === "deedCostsType") {
        if (value === "percentage") {
          delete newInitialConfig.deedCostCategories
          newInitialConfig = {
            ...newInitialConfig,
            deedCostPercent: "",
          }
        } else if (value === "category") {
          delete newInitialConfig.deedCostPercent
          newInitialConfig = {
            ...newInitialConfig,
            deedCostCategories: DEFAULT_DEED_COST_CATEGORIES,
          }
        }
      }

      if (attribute === "hasExternalLoan") {
        if (value) {
          newInitialConfig = {
            ...newInitialConfig,
            externalLoanEntities: DEFAULT_EXTERNAL_LOAN_ENTITIES,
          }
        } else {
          delete newInitialConfig.externalLoanEntities
        }
      }
      
      return newInitialConfig
    })
  }, [])
  
  const onDeedCostPercentBlur = useCallback((value) => {
    let percent = Number(value)
    if (percent < 0) percent = 0
    else if (percent > 100) percent = 100

    setInitialConfig(i => ({
      ...i,
      deedCostPercent: percent
    }))
  }, [])

  const onDeedCostCategoriesChange = useCallback((value, attribute1, attribute2) => {
    const newCategories = { 
      ...initialConfig.deedCostCategories,
      [attribute1]: {
        ...initialConfig.deedCostCategories[attribute1],
        [attribute2]: value,
      }
    }
    setInitialConfig(i => ({
      ...i,
      deedCostCategories: newCategories
    }))
  }, [initialConfig.deedCostCategories])

  const onCustomDeedCostCategoryAdd = useCallback(() => {
    const newCustomCategories = [
      ...initialConfig.deedCostCategories.OTHERS.categories,
      {
        id: generateRandomId(),
        category: "",
        amount: 0,
      }
    ]

    setInitialConfig(i => ({
      ...i,
      deedCostCategories: {
        ...i.deedCostCategories,
        OTHERS: {
          ...i.deedCostCategories.OTHERS,
          categories: newCustomCategories,
        }
      }
    }))
  }, [initialConfig.deedCostCategories?.OTHERS?.categories])

  const onCustomDeedCostCategoryChange = useCallback((value, index, attribute) => {
    const newCustomCategories = initialConfig.deedCostCategories.OTHERS.categories.map((c, i) => {
      if (i === index) {
        return {
          ...c,
          [attribute]: value
        }
      }

      return c
    })

    setInitialConfig(i => ({
      ...i,
      deedCostCategories: {
        ...i.deedCostCategories,
        OTHERS: {
          ...i.deedCostCategories.OTHERS,
          categories: newCustomCategories,
        }
      }
    }))
  }, [initialConfig.deedCostCategories?.OTHERS?.categories])

  const onCustomDeedCostCategoryDelete = useCallback((index) => {
    const newCustomCategories = initialConfig.deedCostCategories.OTHERS.categories.filter((_, i) => (
      index !== i
    ))

    setInitialConfig(i => ({
      ...i,
      deedCostCategories: {
        ...i.deedCostCategories,
        OTHERS: {
          ...i.deedCostCategories.OTHERS,
          categories: newCustomCategories,
        }
      }
    }))
  }, [initialConfig.deedCostCategories?.OTHERS?.categories])

  const onExternalLoanItemsChange = useCallback((value, attribute) => {
    const newEntities = { 
      ...initialConfig.externalLoanEntities,
      [attribute]: value,
    }
    setInitialConfig(i => ({
      ...i,
      externalLoanEntities: newEntities
    }))
  }, [initialConfig.externalLoanEntities])

  return {
    initialConfig,
    formattedInitialConfig,
    enableContinue,
    onInitialConfigChange,
    onDeedCostPercentBlur,
    onDeedCostCategoriesChange,
    onCustomDeedCostCategoryAdd,
    onCustomDeedCostCategoryChange,
    onCustomDeedCostCategoryDelete,
    onExternalLoanItemsChange,
  }
}