import { FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material"
import React from "react"
import { FlexContainer, MaxCharsInput, Spacer } from "../../../components"
import { useCallback } from "react"

const VALUE_ATTRIBUTES = {
  action: "action",
  label: "label",
  href: "href",
}

const ACTIONS = {
  goToSection: {
    key: 'goToSection',
    text: 'Ir a sección',
  },
  openExternalLink: {
    key: 'openExternalLink',
    text: 'Agregar enlace (www.)',
  },
  openChat: {
    key: 'openChat',
    text: 'Abrir chat',
  },
}

const SECTIONS = [{
  name: "Banner de promoción",
  id: "default-image-banner-section",
}, {
  name: "Descripción",
  id: "default-flex-media-section",
}, {
  name: "Tipologías",
  id: "default-carousel-typologies",
}, {
  name: "Galería del proyecto",
  id: "default-masonry-gallery-section",
}, {
  name: "Amenidades",
  id: "default-amenities-section",
}, {
  name: "Ubicación",
  id: "default-small-map-section",
}, {
  name: "Formulario",
  id: "default-split-contact-section",
}, {
  name: "Desarrollador",
  id: "default-carousel-projects",
}]

/*
CONFIG.JS DEFINITIONS

GO TO SECTION
{
  action: "goToSection",
  label: "Quiero una asesor",
  href: "/#contacto"
}

ADD LINK
{
  action: "addLink",
  label: "Quiero una asesor",
  href: "https://alohome.io"
}

OPEN CHAT
{
  action: "openChat",
  label: "Quiero una asesor",
}
*/

const CTAAction = ({ value, onChange, maxChars, label }) => {
  const handleSectionChange = useCallback((inputValue) => {
    const newValue = {
      ...value,
      href: `/#${inputValue}`,
    }
    onChange(newValue)
  }, [onChange, value])

  const handleInputChange = useCallback((inputValue, attribute) => {
    const newValue = {
      ...value,
      [attribute]: inputValue,
    }
    onChange(newValue)
  }, [onChange, value])

  const handleActionChange = useCallback((newAction) => {
    const newValue = {
      label: value?.label,
      action: newAction,
    }
    onChange(newValue)
  }, [onChange, value?.label])

  return (
    <FlexContainer vertical>
      <MaxCharsInput
        label={label}
        maxChars={maxChars}
        value={value?.label ?? ''}
        onChange={(v) => handleInputChange(v, VALUE_ATTRIBUTES.label)}
      />
      <Spacer size={4} vertical />
      <FormControl variant="standard">
        <InputLabel id="action-label">Acción</InputLabel>
        <Select
          labelId="action-label"
          variant="standard"
          value={value?.action ?? ''}
          onChange={(e) => handleActionChange(e.target.value)}
          label="Acción"
        >
          {Object.entries(ACTIONS).map(([key, val]) => (
            <MenuItem key={key} value={key}>{val.text}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {value?.action === ACTIONS.goToSection.key && (
        <>
          <Spacer size={4} vertical />
          <FormControl>
            <FormLabel>¿A qué sección debe ir?</FormLabel>
            <RadioGroup
              value={value?.href?.replace("/#", "") || null}
              onChange={(e) => handleSectionChange(e.target.value)}
            >
              {SECTIONS.map(section => (
                <FormControlLabel key={section.id} value={section.id} control={<Radio size="small" />} label={section.name} />
              ))}
            </RadioGroup>
          </FormControl>
        </>
      )}
      {value?.action === ACTIONS.openExternalLink.key && (
        <>
          <Spacer size={4} vertical />
          <TextField
            variant="standard"
            label="Enlace (www.)"
            value={value?.href ?? ''}
            placeholder="https://www.alohome.io"
            onChange={(e) => handleInputChange(e.target.value, VALUE_ATTRIBUTES.href)}
          />
        </>
      )}
    </FlexContainer>
  )
}

export default CTAAction
