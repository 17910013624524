import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from '../helpers/request'

const useProfile = () => {
  const profile = useSelector(state => state.profile)
  const reloadProfile = useSelector(state => state.reloadProfile)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!profile || reloadProfile) {
      get('/internal/login/v1/profile')
        .then(result => {
          if (result) {
            const user = result.user
            const avatar = user?.custom_data?.avatar ? `${process.env.REACT_APP_IMAGES_URL}/${user?.custom_data?.avatar}?s=144x144&fit=cover` : undefined
            dispatch({ type: 'set-profile', data: {
              ...user,
              avatar,
            }})
          }
        })
        .catch(error => {
          dispatch({ type: 'set-profile', data: { error: error.code } })
        })
      dispatch({type: 'reload-profile', data: false})
    }
  }, [profile, dispatch, reloadProfile])

  return profile
}

export default useProfile
