import { useQuery } from '@tanstack/react-query';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import smartlookClient from 'smartlook-client';
import styled from 'styled-components';
import { FlexContainer } from '../../components';
import { getPathValue } from '../../helpers/data';
import {
  getData,
  getGeneral,
  getInventory,
  getSitePreviewUrl,
} from '../../helpers/draft';
import { deepCopy } from '../../helpers/lodash';
import { trackEvent } from '../../helpers/mixpanel';
import { get } from '../../helpers/request';
import { digitalShowroomTabs } from '../../helpers/tabsData';
import { convertCustomColorsInCss } from '../../helpers/themes';
import { convertToY, useSharedDocument } from '../../hooks/documentHooks';
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt';
import useSite from '../../hooks/useSite';
import PageLayout from '../../layouts/PageLayout';
import { DiscardChangesDialog } from '../../modules';
import BeforePublishModal from './BeforePublishModal';
import PublishModal from './PublishModal';
import SectionsManager from './SectionsManager/SectionsManager';
import ShowroomEditorMenu from './ShowroomEditorMenu';
import EditorSidebar from './Sidebar/EditorSidebar';
import { usePublishDraft } from './usePublishDraft';
import { useSaveDraft } from './useSaveDraft';
import useSections from './useSections';
import { PREVIEW_SIZE } from './utils';

const GET_DEVELOPER_INFO =
  '/internal/settings/v1/settings?category=general&subcategory=developer';

const Preview = styled.iframe`
  border: 0;
  width: ${(p) => (p.size === PREVIEW_SIZE.desktop ? '100%' : '425px')};
  height: calc(100vh - 64px);
`;

const getDevInfoFn = ({ queryKey }) =>
  get(GET_DEVELOPER_INFO, { site_id: queryKey[1] });

const ShowroomEditorView = () => {
  const { search: rawSearch } = useLocation();
  const dispatch = useDispatch();
  const { site, resetSite } = useSite();
  const [currentSiteId, setCurrentSiteId] = useState();
  const previewRef = useRef(null);
  const [previewSize, setPreviewSize] = useState(PREVIEW_SIZE.desktop);
  const [isEditing, setIsEditing] = useState(false);
  const [isInspecting, setIsInspecting] = useState(false);
  const [toolsLoaded, setToolsLoaded] = useState(false);
  const [isBeforePublishOpen, setIsBeforePublishOpen] = useState(false);
  const [isPublishOpen, setIsPublishOpen] = useState(false);
  const [hasDeveloperInfo, setHasDeveloperInfo] = useState(false);
  const [viewThemesEditor, setViewThemesEditor] = useState(false);
  const [data, setData] = useState(null);
  const [savedData, setSavedData] = useState(null);
  const [general, setGeneral] = useState(null);
  const [inventory, setInventory] = useState(null);
  const [savedInventory, setSavedInventory] = useState(null);
  const [savedGeneral, setSavedGeneral] = useState(null);
  const [theme, setTheme] = useState(null);
  const [themeConfig, setThemeConfig] = useState(null);
  const [savedThemeConfig, setSavedThemeConfig] = useState(null);
  const previewOrigin = getSitePreviewUrl(site);
  const previewUrl = useMemo(() => {
    if (!previewOrigin) return;
    let url = previewOrigin;
    const search = new URLSearchParams(rawSearch);
    const goto = search.get('goto');
    if (!data || !goto) return url;
    const sections = getPathValue(data, 'pages.home.sections');
    if (!sections) return url;
    const id = sections.find((section) => section.type === goto)?.id;
    if (!id) return url;
    return `${url}#${id}`;
  }, [previewOrigin, rawSearch, data]);

  const [yDocument, , documentReady] = useSharedDocument(site?.site_id, {
    ws: process.env.REACT_APP_WEBSOCKET,
  });

  const filesList = useMemo(
    () => [
      {
        name: 'data',
        setters: [setData, setSavedData],
        getter: getData,
      },
      {
        name: 'general',
        setters: [setGeneral, setSavedGeneral],
        getter: getGeneral,
      },
      {
        name: 'inventory',
        setters: [setInventory, setSavedInventory],
        getter: getInventory,
      },
    ],
    []
  );

  useEffect(() => {
    const updateFiles = (onlyFirstSetter) => {
      for (let file of filesList) {
        const fileMap = yDocument && yDocument?.getMap(file.name);
        const json = fileMap && fileMap?.toJSON();
        if (!!json && Object.keys(json).length !== 0) {
          const files = {
            data,
            inventory,
            general,
          };
          let index = 0;
          for (const setter of file.setters) {
            if (index > 0 && onlyFirstSetter) {
              continue;
            }
            const newFileContent = deepCopy(json);
            const oldFileContent = deepCopy(files[file.name]);

            if (
              JSON.stringify(newFileContent) !== JSON.stringify(oldFileContent)
            ) {
              setter(deepCopy(json));
              if (file.name === 'general') {
                setThemeConfig({
                  theme: site?.draft_theme,
                  version: site?.draft_theme_version,
                  customColors: site.draft_theme_custom_colors,
                });
                setSavedThemeConfig({
                  theme: site?.draft_theme,
                  version: site?.draft_theme_version,
                  customColors: site.draft_theme_custom_colors,
                });
              }
            }
            index++;
          }
        } else if (
          !!json &&
          Object.keys(json).length === 0 &&
          !!site &&
          !!fileMap
        ) {
          file.getter(site).then((data) => {
            Object.entries(data).forEach(([key, value]) => {
              fileMap.set(key, convertToY(value));
            });
            for (const setter of file.setters) {
              setter((prev) => ({ ...prev, ...deepCopy(json) }));
            }
          });
        }
      }
    };
    if (documentReady) {
      if (currentSiteId !== site?.site_id) {
        setData(null);
        setGeneral(null);
        setInventory(null);
        setCurrentSiteId(site?.site_id);
      } else if (!data) {
        updateFiles();
      }
      yDocument.on('update', (_, provider, doc, transation) => {
        updateFiles(true);
      });
    }
    // eslint-disable-next-line
  }, [
    site?.site_id,
    yDocument,
    documentReady,
    filesList,
    data,
    inventory,
    general,
    currentSiteId,
  ]);

  const openPlans = useCallback(() => {
    dispatch({ type: 'open-plans-modal' });
  }, [dispatch]);

  const onDevInfoLoaded = useCallback((devInfo) => {
    if (Object.keys(devInfo).length > 0) {
      setHasDeveloperInfo(true);
    }
  }, []);

  const handleCloseEdit = useCallback(() => {
    previewRef.current.contentWindow.postMessage(
      {
        action: 'end-alohub-inspection',
      },
      '*'
    );
    setIsEditing(false);
    setIsInspecting(false);
    resetSite();
  }, [resetSite]);

  const deepChildSearch = useCallback(
    (key, localJson) => {
      const fileMap = yDocument && yDocument?.getMap(key);
      const yJson = fileMap && fileMap?.toJSON();
      Object.entries(localJson).forEach(([newKey, newValue]) => {
        if (JSON.stringify(newValue) !== JSON.stringify(yJson[newKey])) {
          fileMap.set(newKey, convertToY(newValue));
        }
      });
      Object.entries(yJson).forEach(([oldKey]) => {
        if (localJson[oldKey] === undefined) {
          fileMap.delete(oldKey);
        }
      });
    },
    [yDocument]
  );

  const { loading: loadingDevInfo } = useQuery(
    ['DEV_INFO', site?.site_id],
    getDevInfoFn,
    {
      enabled: !!site?.site_id && !hasDeveloperInfo,
      onSuccess: onDevInfoLoaded,
    }
  );

  const { saveDraft, isLoading: isSavingDraft } = useSaveDraft({
    site,
    data,
    inventory,
    themeConfig,
    general,
  });

  const { publishDraft, isLoading: isPublishingDraft } = usePublishDraft({
    site,
    data,
    inventory,
    themeConfig,
    general,
    options: { onSuccess: handleCloseEdit },
  });

  useEffect(() => {
    const toolsLoadedHandler = (event) => {
      if (event.data.action === 'tools-loaded') setToolsLoaded(true);
    };
    window.addEventListener('message', toolsLoadedHandler);
    return () => window.removeEventListener('message', toolsLoadedHandler);
  }, []);

  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      if (inventory && Object.keys(data).length > 0) {
        const reloadPayload = { inventory, data, general };
        if (theme) reloadPayload.theme = theme;
        previewRef.current.contentWindow.postMessage(
          {
            action: 'hot-reload-alohub',
            ...reloadPayload,
          },
          '*'
        );
        for (let file of filesList) {
          deepChildSearch(file.name, reloadPayload[file.name]);
        }
      }
    }, 800);
    return () => clearTimeout(timeoutRef);
  }, [
    previewRef,
    inventory,
    data,
    theme,
    general,
    filesList,
    yDocument,
    deepChildSearch,
  ]);

  useEffect(() => {
    if (isEditing && isInspecting) {
      // end-alohub-inspection
      previewRef.current.contentWindow.postMessage(
        {
          action: 'start-alohub-inspection',
        },
        '*'
      );
    } else if (isEditing && !isInspecting) {
      previewRef.current.contentWindow.postMessage(
        {
          action: 'end-alohub-inspection',
        },
        '*'
      );
    }
  }, [isEditing, isInspecting]);

  useEffect(() => {
    if (themeConfig?.customColors) {
      setGeneral((g) => ({
        ...g,
        custom_colors: convertCustomColorsInCss(themeConfig.customColors),
      }));
    }
  }, [themeConfig?.customColors]);

  const togglePreview = useCallback(() => {
    if (previewSize === PREVIEW_SIZE.desktop) {
      setPreviewSize(PREVIEW_SIZE.mobile);
    } else {
      setPreviewSize(PREVIEW_SIZE.desktop);
    }
  }, [previewSize]);

  const handleEdit = useCallback(() => {
    setIsEditing(true);
    setIsInspecting(true);
    smartlookClient.track('showroom-edit', {
      domain: site?.site_domain,
      project_name: site?.site_name,
    });
    trackEvent('showroom-edit', {
      domain: site?.site_domain,
      project_name: site?.site_name,
    });
  }, [site]);

  const syncSavedInfo = useCallback(() => {
    setSavedData(deepCopy(data));
    setSavedInventory(inventory);
    setSavedGeneral(deepCopy(general));
    setSavedThemeConfig(deepCopy(themeConfig));
  }, [data, inventory, general, themeConfig]);

  const handleSave = useCallback(() => {
    syncSavedInfo();
    saveDraft();
    smartlookClient.track('showroom-save', {
      domain: site?.site_domain,
      project_name: site?.site_name,
    });
    trackEvent('showroom-save', {
      domain: site?.site_domain,
      project_name: site?.site_name,
    });
  }, [saveDraft, site?.site_domain, site?.site_name, syncSavedInfo]);

  const handlePublish = useCallback(() => {
    if (site?.remainingDays <= 0) {
      openPlans();
      return;
    }

    if (hasDeveloperInfo) {
      syncSavedInfo();
      setIsPublishOpen(true);
      publishDraft();
    } else {
      setIsBeforePublishOpen(true);
    }
    smartlookClient.track('showroom-publish', {
      is_registed: hasDeveloperInfo,
      domain: site?.site_domain,
      project_name: site?.site_name,
    });
    trackEvent('showroom-publish', {
      is_registed: hasDeveloperInfo,
      domain: site?.site_domain,
      project_name: site?.site_name,
    });
  }, [
    hasDeveloperInfo,
    openPlans,
    publishDraft,
    site?.remainingDays,
    site?.site_domain,
    site?.site_name,
    syncSavedInfo,
  ]);

  const handleCloseBeforePublish = useCallback(() => {
    setIsBeforePublishOpen(false);
  }, []);

  const handleClosePublish = useCallback(() => {
    setIsPublishOpen(false);
  }, []);

  const handleBeforePublishSave = useCallback(() => {
    setHasDeveloperInfo(true);
    setIsBeforePublishOpen(false);
    setIsPublishOpen(true);
    publishDraft();
  }, [publishDraft]);

  const toggleThemesEditor = useCallback(
    () => setViewThemesEditor((t) => !t),
    []
  );

  const {
    viewSectionsManager,
    pageToManage,
    onSectionsReorder,
    toggleSectionsManager,
    onViewSectionsManager,
    onSectionDelete,
    onSectionEnable,
    onSectionsSave,
  } = useSections(data, setData);

  const loading =
    !toolsLoaded ||
    !documentReady ||
    isSavingDraft ||
    isPublishingDraft ||
    loadingDevInfo;

  const [isDialogInEditor, setIsDialogInEditor] = useState(false);

  const enableSave = useMemo(() => {
    const hasDataChanges = !isEqual(savedData, data);
    const hasInventoryChanges = !isEqual(savedInventory, inventory);
    const hasGeneralChanges = !isEqual(savedGeneral, general);
    const hasThemesChanges = !isEqual(savedThemeConfig, themeConfig);
    return (
      (hasDataChanges ||
        hasInventoryChanges ||
        hasGeneralChanges ||
        hasThemesChanges) &&
      !loading
    );
  }, [
    data,
    inventory,
    loading,
    savedData,
    savedInventory,
    savedGeneral,
    general,
    themeConfig,
    savedThemeConfig,
  ]);

  const [showPrompt, confirmNavigation, , openPrompt] =
    useCallbackPrompt(enableSave);

  const saveAndLeave = useCallback(() => {
    handleSave();
    if (site?.remainingDays > 0) {
      confirmNavigation();
    }
  }, [confirmNavigation, handleSave, site?.remainingDays]);

  const handleDiscard = useCallback(() => {
    if (isDialogInEditor) {
      handleCloseEdit();
      setIsDialogInEditor(false);
    }
    const files = {
      data: savedData,
      inventory: savedInventory,
      general: savedGeneral,
    };
    for (let file of filesList) {
      for (const setter of file.setters) {
        let fileToRollback = files[file.name];
        if (fileToRollback) {
          setter(deepCopy(fileToRollback));
        }
      }
    }
    confirmNavigation();
  }, [
    isDialogInEditor,
    handleCloseEdit,
    confirmNavigation,
    filesList,
    savedData,
    savedInventory,
    savedGeneral,
  ]);

  useEffect(() => {
    if (isDialogInEditor && !isSavingDraft && !showPrompt) {
      handleCloseEdit();
      setIsDialogInEditor(false);
    }
  }, [isSavingDraft, isDialogInEditor, showPrompt, handleCloseEdit]);

  return (
    <>
      <DiscardChangesDialog
        open={showPrompt}
        onSave={saveAndLeave}
        onDiscard={handleDiscard}
      />
      <BeforePublishModal
        isOpen={isBeforePublishOpen}
        onClose={handleCloseBeforePublish}
        onSave={handleBeforePublishSave}
        saveDraft={saveDraft}
        data={data}
      />
      <PublishModal isOpen={isPublishOpen} onClose={handleClosePublish} />
      <PageLayout
        links={digitalShowroomTabs}
        permissions={['digital_showroom', 'editor']}
        menu={
          <ShowroomEditorMenu
            previewSize={previewSize}
            onPreviewChange={togglePreview}
            onEdit={handleEdit}
            onSave={handleSave}
            onPublish={handlePublish}
            isEditing={isEditing}
            enableSave={enableSave}
            disableActions={loading}
          />
        }
      >
        <EditorSidebar
          open={isEditing}
          onClose={handleCloseEdit}
          inventory={inventory}
          setInventory={setInventory}
          data={data}
          setData={setData}
          onOrderClick={onViewSectionsManager}
          viewThemesEditor={viewThemesEditor}
          viewSectionsManager={viewSectionsManager}
          previewRef={previewRef}
          setTheme={setTheme}
          setThemeConfig={setThemeConfig}
          themeConfig={themeConfig}
          toggleThemesEditor={toggleThemesEditor}
          onInspectChange={setIsInspecting}
          openPrompt={openPrompt}
          enableSave={enableSave}
          setIsDialogInEditor={setIsDialogInEditor}
        />
        {isEditing && (
          <SectionsManager
            open={viewSectionsManager}
            onClose={toggleSectionsManager}
            page={pageToManage}
            onSave={onSectionsSave}
            onReorder={onSectionsReorder}
            onDelete={onSectionDelete}
            onEnable={onSectionEnable}
          />
        )}
        <FlexContainer fullWidth centered>
          {previewUrl && (
            <Preview
              ref={previewRef}
              src={previewUrl}
              title="editor"
              size={previewSize}
            />
          )}
        </FlexContainer>
      </PageLayout>
    </>
  );
};

export default ShowroomEditorView;
