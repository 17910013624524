import { FlexContainer, WidgetContainer, WidgetTitle, Spacer } from "../../components"
import { Typography, Select, MenuItem } from "@mui/material"
import { BarChart, XAxis, YAxis, Tooltip, Legend, Bar, ResponsiveContainer } from "recharts"
import { numberToCurrency } from "../../helpers/utils"
import { useEffect, useMemo, useState } from 'react'
import { get } from '../../helpers/request'
import PropTypes from 'prop-types'
import useCurrencies from "../../hooks/useCurrencies"
import { useSelector } from "react-redux"
import useScreenWidthBelowEqual from "../../hooks/useScreenWidthBelowEqual"

const TITLE = "Progreso Global del Proyecto"
const TOOLTIP = "Este gráfico muestra el progreso global del proyecto a nivel de ventas realizadas"

const GlobalProgressChart = ({ data, soldMessage, xAxisFormatter, tooltipFormatter, soldBar, reservedBar, availableBar, offlineBar, ticks }) => {
  const chartData = useMemo(() => {
    return [
      {
        ...data,
        name: "Total"
      }
    ]
  }, [data])

  return (
    <>
      <Typography variant="h4">{soldMessage}</Typography>
      <Spacer vertical size={3} />
      <ResponsiveContainer width="100%" height={200}>
        <BarChart layout="vertical" data={chartData}>
          <XAxis type="number" tickFormatter={xAxisFormatter} ticks={ticks || null} />
          <YAxis dataKey="name" type="category" />
          <Tooltip formatter={tooltipFormatter} />
          <Legend />
          <Bar dataKey={soldBar.dataKey} name="Vendido" stackId="a" fill="#db4437" unit={soldBar.unit} />
          <Bar dataKey={reservedBar.dataKey} name="Reservado" stackId="a" fill="#ffb300" unit={reservedBar.unit} />
          <Bar dataKey={availableBar.dataKey} name="Disponible" stackId="a" fill="#1abc9c" unit={availableBar.unit} />
          <Bar dataKey={offlineBar.dataKey} name="No Publicado" stackId="a" fill="#7e7e7e" unit={offlineBar.unit} />
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}

GlobalProgressChart.propTypes = {
  soldMessage: PropTypes.string.isRequired,
  xAxisFormatter: PropTypes.func.isRequired,
  tooltipFormatter: PropTypes.func,
  soldBar: PropTypes.shape({
    dataKey: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired
  }).isRequired,
  reservedBar: PropTypes.shape({
    dataKey: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired
  }).isRequired,
  availableBar: PropTypes.shape({
    dataKey: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired
  }).isRequired,
  ticks: PropTypes.array
}


const GlobalProgress = () => {
  const [data, setData] = useState()
  const [type, setType] = useState("progress")
  const currencies = useCurrencies()
  const selectedSite = useSelector(state => state.site)
  const isBelowEqual1280 = useScreenWidthBelowEqual(1280)

  useEffect(() => {
    if (selectedSite) {
      get('/internal/developer/v3/dashboard/globalprogress', { site_id: selectedSite.site_id })
        .then(result => {
          setData(result)
        })
        .catch(error => {
          console.error(error)
        })
    }
  }, [selectedSite])

  const handleChange = (event) => setType(event.target.value)
  return (
    <>
      {currencies && data && (<WidgetContainer width={isBelowEqual1280 ? 5 : 4}>
        <FlexContainer justified>
          <WidgetTitle title={TITLE} tooltip={TOOLTIP} />
          <Select value={type} autoWidth name="type" onChange={handleChange}>
            <MenuItem value="progress">Progreso</MenuItem>
            <MenuItem value="income">Ingresos ({currencies.main.code} {currencies.main.symbol})</MenuItem>
          </Select>
        </FlexContainer>
        <Spacer vertical size={1} />
        {data && (type === "progress" ?
          <GlobalProgressChart
            data={data}
            soldMessage={`${data.progress_sold_percentage} Vendido`}
            ticks={[data.progress_total * 0.25, data.progress_total * 0.5, data.progress_total * 0.75, data.progress_total]}
            xAxisFormatter={tick => `${(tick / data.progress_total * 100)}%`}
            soldBar={{
              dataKey: "progress_sold",
              unit: ` unidades => ${data.progress_sold_percentage}`
            }}
            reservedBar={{
              dataKey: "progress_reserved",
              unit: ` unidades => ${data.progress_reserved_percentage}`
            }}
            availableBar={{
              dataKey: "progress_available",
              unit: ` unidades => ${data.progress_available_percentage}`
            }}
            offlineBar={{
              dataKey: "progress_unset",
              unit: ` unidades => ${data.progress_unset_percentage}`
            }}
          />
          :
          <GlobalProgressChart
            data={data}
            soldMessage={`Vendido: ${numberToCurrency(data.income_sold, currencies.main.symbol)}`}
            xAxisFormatter={tick => `${numberToCurrency(tick, currencies.main.symbol)}`}
            tooltipFormatter={value => `${numberToCurrency(value, currencies.main.symbol)}`}
            soldBar={{
              dataKey: "income_sold",
              unit: ""
            }}
            reservedBar={{
              dataKey: "income_reserved",
              unit: ""
            }}
            availableBar={{
              dataKey: "income_available",
              unit: ""
            }}
            offlineBar={{
              dataKey: "income_unset",
              unit: ""
            }}
          />
        )}
      </WidgetContainer>)
      }
    </>
  )
}

export default GlobalProgress
