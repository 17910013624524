import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { get } from "../helpers/request";
import useSite from "./useSite";

const getAssetsFn = ({ queryKey }) =>
  get(`/internal/developer/v1/assets?ids=${queryKey.slice(2).join(",")}`, {
    site_id: queryKey[1],
  });

const useAssetsNames = ({ ids = [] }) => {
  const { site } = useSite();
  const { data, isLoading } = useQuery(
    ["ASSETS_IDS", site?.site_id, ...ids],
    getAssetsFn,
    {
      staleTime: Infinity,
      enabled: ids && ids.length > 0,
    }
  );

  const assetsNames = useMemo(() => {
    if (!data) return {};
    const obj = {};
    for (const { asset_id, metadata } of data.assets) {
      obj[asset_id] = metadata?.fileName || metadata?.name;
    }
    return obj;
  }, [data]);

  return {
    assetsNames,
    isLoading,
  };
};

export default useAssetsNames;
