import LocationOnIcon from '@mui/icons-material/LocationOn';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import { useCallback } from 'react';
import styled from 'styled-components';
import { ListItemOption } from '../../components';

const LocationItemOption = styled(ListItemOption)`
  button {
    column-gap: .5rem;
    align-items: flex-start;
    &,
    &:hover {
      border-radius: 0;
    }
  }
  svg {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  & + & {
    border-top: 1px solid #dedede;
  }
`

const LocationOption = ({label, value, onClick}) => {
  const handleClick = useCallback(() => {
    if(onClick) onClick(value)
  }, [onClick, value])

  return (
    <LocationItemOption>
      <Button onClick={handleClick} variant="text">
        <LocationOnIcon />
        <ListItemText primary={label} sx={{ color: 'text.primary' }}/>
      </Button>
    </LocationItemOption>
  )
}

export default LocationOption