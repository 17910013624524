import { useEffect } from 'react'

function useOnKeyDown(key, callback) {
  useEffect(() => {
    function handleKeyDown(e) {
      if (e.key === key) {
        callback && callback()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [callback, key])
}

export default useOnKeyDown
