import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Grid, Typography } from "@mui/material"
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Spacer } from '../../components'
import { isObject } from '../../helpers/utils'
import useOnboardingContext from '../../hooks/useOnboardingContext'
import PublicLayout from '../../layouts/PublicLayout'
import { AssetGridItemCard } from "../../modules"

const Container = styled.div`
  padding: 30px 142px;
`

const AssetsSectionGrid = styled(Grid)`
  margin-block-end: 7.25rem;
`

const uploadAssetsText = {
  title: "Sube las primeras imágenes de tu proyecto",
  text: "Las tomaremos de base para diseñar tu showroom digital.<br />Más adelante podrás cambiarlas o agregar más.",
  assetsSections: {
    projectLogos: "Logotipo del proyecto",
    developerLogos: "Logotipo del desarrollador",
    project: "Imágenes principales del proyecto",
    type: {
      typologies: "Imágenes de las tipologías",
      distribution: "Imagen de la distribución del proyecto",
    },
    misc: "Imágenes generales del proyecto (amenidades, panorámicas, construcción, etc.)",
  },
}

const uploadLabel = {
  image: "Sube una imagen",
  imageMaxSize: "Peso máximo de imagen 2MB",
  imageMaxSizeError: "El peso de la imagen es mayor a 2MB, intenta con otra imagen.",
  video: "Sube un video",
  uploading: "Subiendo",
}

const actionLabels = {
  primary: "Veamos tu showroom",
  secondary: "Atrás",
  editLabel: "Editar",
  saveLabel: "Guardar",
  deleteAssetLabel: "Eliminar",
}

const defaultLabels = {
  projectLogos: {
    color: {
      assetLabel: "A color"
    },
    white: {
      assetLabel: "En color blanco",
    },
  },
  developerLogos: {
    color: {
      assetLabel: "A color"
    },
    white: {
      assetLabel: "En color blanco",
    },
  },
  projectAssets: {
    mainImage: {
      assetLabel: "Imagen principal de tu proyecto"
    },
    secondaryImage: {
      assetLabel: "Imagen secundaria de tu proyecto",
    },
    video: {
      assetLabel: "Video de tu proyecto",
    },
  },
  distributionAssets: {
    assetLabel: "Imagen de la distribución del proyecto"
  },
  typologyAssets: {
    assetLabel: "Imagen de la tipología"
  },
  miscAssets: {
    assetLabel: "Imagen",
  },
}

const initialProjectLogos = {
  color: {
    tags: [ "logo" ]
  },
  white: {},
}

const initialDeveloperLogos = {
  color: {},
  white: {},
}

const initialProjectAssets = {
  mainImage: {},
  secondaryImage: {},
  video: {
    type: "video"
  },
}

const initialDistributionAssets = {}

const initialTypologyAssets = [{},{},{}]

const initialMiscAssets = [{},{},{}]

const PopulateFromArray = (assets, section, minAmount, setAssets, data, updateData) => {
  const [ itemsAmount, setItemsAmount ] = useState(minAmount)
  const [ filledCards, setFilledCards ] = useState(0)
  const getArrayData = useCallback((index) => { return index }, [])
  const onDelete = useCallback(() => {
    if(filledCards >= minAmount) setItemsAmount(prev => prev - 1)
  }, [filledCards, minAmount])

  const itemsList = [...Array(itemsAmount)].map((_, i) =>
    <AssetGridItemCard
      assetId={data[i]?.asset_id}
      imageSrc={data[i]?.imageSrc}
      label={data[i]?.metadata?.fileName}
      defaultUploadLabel={uploadLabel[data[i]?.type === 'video' ? 'video' : 'image']}
      maxImageSizeLabel={data[i]?.type !== 'video' ? uploadLabel.imageMaxSize : null}
      maxImageSizeErrorLabel={data[i]?.type !== 'video' ? uploadLabel.imageMaxSizeError : null}
      uploadingLabel={uploadLabel.uploading}
      defaultAssetLabel={defaultLabels[section].assetLabel}
      assetType={data[i]?.type}
      assets={assets}
      setAssets={setAssets}
      key={data[i]?.asset_id || i}
      editLabel={actionLabels.editLabel}
      saveLabel={actionLabels.saveLabel}
      deleteAssetLabel={actionLabels.deleteAssetLabel}
      data={data}
      updateData={updateData}
      metaData={data[i]?.metadata}
      getArrayData={() => getArrayData(i)}
      onDelete={onDelete}
    />
  )
  
  const cardsNotEmpty = useMemo(() => itemsList.filter(item => item.props.assetId), [itemsList])

  useEffect(() => {
    setFilledCards(cardsNotEmpty.length)
  }, [cardsNotEmpty.length])

  useEffect(() => {
    if(filledCards >= minAmount) return setItemsAmount(filledCards + 1)
  }, [filledCards, minAmount])
  
  return itemsList
}

const populateFromObject = (assets, section, setAssets, data, updateData, childrenAreSectionAssets = true) => {
  if (childrenAreSectionAssets) return Object.entries(data).map(([key,value]) => 
    <AssetGridItemCard
      assetId={value?.asset_id}
      imageSrc={value?.imageSrc}
      label={value?.metadata?.fileName}
      defaultUploadLabel={uploadLabel[value?.type === 'video' ? 'video' : 'image']}
      maxImageSizeLabel={value?.type !== 'video' ? uploadLabel.imageMaxSize : null}
      maxImageSizeErrorLabel={value?.type !== 'video' ? uploadLabel.imageMaxSizeError : null}
      uploadingLabel={uploadLabel.uploading}
      defaultAssetLabel={defaultLabels[section]?.[key]?.assetLabel || defaultLabels[section]?.assetLabel}
      assetType={value?.type}
      assets={assets}
      setAssets={setAssets}
      key={value?.asset_id || key}
      editLabel={actionLabels.editLabel}
      saveLabel={actionLabels.saveLabel}
      deleteAssetLabel={actionLabels.deleteAssetLabel}
      updateData={updateData}
      metaData={value?.metadata}
      tags={value?.tags}
      objectKey={key}
    />
  )
  return (
    <AssetGridItemCard
      assetId={data?.asset_id}
      imageSrc={data?.imageSrc}
      label={data?.metadata?.fileName}
      defaultUploadLabel={uploadLabel[data?.type === 'video' ? 'video' : 'image']}
      maxImageSizeLabel={data?.type !== 'video' ? uploadLabel.imageMaxSize : null}
      maxImageSizeErrorLabel={data?.type !== 'video' ? uploadLabel.imageMaxSizeError : null}
      uploadingLabel={uploadLabel.uploading}
      defaultAssetLabel={defaultLabels[section]?.assetLabel}
      assetType={data?.type}
      assets={assets}
      setAssets={setAssets}
      key={data?.asset_id || section}
      editLabel={actionLabels.editLabel}
      saveLabel={actionLabels.saveLabel}
      deleteAssetLabel={actionLabels.deleteAssetLabel}
      updateData={updateData}
      metaData={data?.metadata}
    />
  )
}

const AssetsSection = ({assets, section, minAmount, setAssets, data, updateData}) => {
  if(!data) return
  return (
    <AssetsSectionGrid container spacing={2}>
      {isObject(data) ?
        populateFromObject(assets, section, setAssets, data, updateData)
        : PopulateFromArray(assets, section, minAmount, setAssets, data, updateData)}
    </AssetsSectionGrid>
)}

const ProjectAssets = () => {
  const { onboardingData, setOnboardingData, onNextStep, onPreviousStep } = useOnboardingContext()
  const [assets, setAssets] = useState([])
  const { title, text, assetsSections } = uploadAssetsText
  const { primary, secondary } = actionLabels
  const propertyType = useMemo(() => onboardingData?.propertyType || 'APARTMENT', [onboardingData?.propertyType])
  const [projectLogos, setProjectLogos] = useState(initialProjectLogos)
  const [developerLogos, setDeveloperLogos] = useState(initialDeveloperLogos)
  const [projectAssets, setProjectAssets] = useState(initialProjectAssets)
  const [projectTypeAssets, setProjectTypeAssets] = useState(propertyType === 'LOT' ? initialDistributionAssets : initialTypologyAssets)  
  const [miscAssets, setMiscAssets] = useState(initialMiscAssets)

  const handleNext = useCallback(() => {
    onNextStep()
    setOnboardingData({
      ...onboardingData,
      assets,
      projectLogos,
      developerLogos,
      projectAssets,
      [propertyType === 'LOT' ? "distributionAssets" : "typologyAssets"]: projectTypeAssets,
      miscAssets,
    })
  }, [assets, developerLogos, miscAssets, onNextStep, onboardingData, projectAssets, projectLogos, projectTypeAssets, propertyType, setOnboardingData])

  return (
    <PublicLayout
      primaryLabel={primary}
      primaryIcon={<ArrowForwardIcon />}
      onPrimaryClick={handleNext}
      secondaryLabel={secondary}
      onSecondaryClick={onPreviousStep}
      currentStep={5}
      numberOfSteps={5}
      showSkipButton
      onSkipClick={handleNext}
    >
      <Container>
        <Typography variant='h2'>{title}</Typography>
        <Spacer vertical size={4} />
        <Typography variant='h5' dangerouslySetInnerHTML={{ __html: text }} mb={8}/>
        <Typography variant='h5' mb={2}>{assetsSections.projectLogos}</Typography>
        <AssetsSection
          initialConfig={projectLogos}
          assets={assets}
          section="projectLogos"
          setAssets={setAssets}
          data={projectLogos}
          updateData={setProjectLogos}
        />
        <Typography variant='h5' mb={2}>{assetsSections.developerLogos}</Typography>
        <AssetsSection
          initialConfig={developerLogos}
          assets={assets}
          section="developerLogos"
          setAssets={setAssets}
          data={developerLogos}
          updateData={setDeveloperLogos}
        />
        <Typography variant='h5' mb={2}>{assetsSections.project}</Typography>
        <AssetsSection
          initialConfig={projectAssets}
          assets={assets}
          section="projectAssets"
          setAssets={setAssets}
          data={projectAssets}
          updateData={setProjectAssets}
        />
        <Typography
          variant='h5'
          mb={2}
        >
          {propertyType === 'LOT' ? assetsSections.type.distribution : assetsSections.type.typologies}
        </Typography>
        {projectTypeAssets && <AssetsSection
          initialConfig={projectTypeAssets}
          assets={assets}
          section={propertyType === 'LOT' ? "distributionAssets" : "typologyAssets"}
          minAmount={propertyType !== 'LOT' ? 3 : null}
          setAssets={setAssets}
          data={projectTypeAssets}
          updateData={setProjectTypeAssets}
          childrenAreSectionAssets={propertyType === 'LOT' ? false : null}
        />}
        <Typography variant='h5' mb={2}>{assetsSections.misc}</Typography>
        <AssetsSection
          initialConfig={miscAssets}
          assets={assets}
          section="miscAssets"
          minAmount={3}
          setAssets={setAssets}
          data={miscAssets}
          updateData={setMiscAssets}
        />
      </Container>
    </PublicLayout>
  )
}

export default ProjectAssets
