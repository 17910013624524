import React from "react"
import styled from "styled-components"
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #131313;
  opacity: 0.6;
  transition: 1s opacity;
  z-index: 3;
`

const Sidebar = styled.div`
  background: #F7F7F7;
  position: fixed;
  right: ${p => p.open ? '0' : '-75vw'};
  top: 0;
  width: 75vw;
  height: 100vh;
  transition: 1s right;
  overflow-y: auto;
  z-index: 4;
`

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 6px;
  right: 6px;
`

const RightSidebar = ({ children, open, onClose }) => (
  <>
    {open && (
      <Overlay open={open} />
    )}
    <Sidebar open={open}>
      <CloseButton aria-label="close" onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      {children}
    </Sidebar>
  </>
)

export default RightSidebar