import { Chip, Menu, MenuItem } from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore';
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

const COLORS = {
  sold: '#F44336',
  reserved: '#e0bd20',
  available: '#4CAF50',
  offline: '#646464',
}

const FONT_COLORS = {
  sold: '#FFFFFF',
  available: '#FFFFFF',
  reserved: '#000000DE',
  offline: '#FFFFFF',
}

const EXPAND_COLORS = {
  sold: '#E31B0C',
  available: '#3B873E',
  reserved: '#b5960e',
  offline: '#4b4b4b',
}

const LABELS = {
  sold: 'VENDIDO',
  available: 'DISPONIBLE',
  reserved: 'RESERVADO',
  offline: 'NO PUBLICADO'
}

const StyledChip = styled(({ color, fontColor, showArrow, ...props }) => <Chip {...props} />)`
  background-color: ${({ color }) => color};
  color: ${({ fontColor }) => fontColor};
  width: ${({ showArrow }) => showArrow ? '140px' : '105px'};
  justify-content: ${({ showArrow }) => showArrow ? 'start' : 'center'};;
  &:hover, &:focus {
    background-color: ${({ color }) => color};
    filter: contrast(125%);
  }
`;

const StyledExpandMore = styled(({ color, ...props }) => <ExpandMore {...props} />)`
  border-radius: 50%;
  background: ${({ color }) => color};
  color: #fff;
`;

const StatusChip = ({ value, onChanged, disabled }) => {
  const [anchor, setAnchor] = useState(null)
  const color = COLORS[value]
  const fontColor = FONT_COLORS[value] || 'white'
  const expandColor = EXPAND_COLORS[value] || '#1f1e1e6b'
  const label = LABELS[value]

  const handleClick = useCallback(event => {
    if (!disabled) {
      setAnchor(event.currentTarget)
    }
  }, [disabled])

  const handleClose = useCallback(() => {
    setAnchor(null)
  }, [])

  const handleSelect = useCallback((status) => {
    if (onChanged) onChanged(status)
    setAnchor(null)
  }, [onChanged])

  return (
    <>
      <StyledChip
        ref={anchor}
        label={label}
        color={color}
        fontColor={fontColor}
        onClick={handleClick}
        icon={!disabled ? <StyledExpandMore color={expandColor} /> : undefined}
        showArrow={!disabled}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={handleClose}
      >
        {Object.keys(COLORS).map(status =>
          <MenuItem key={status} onClick={() => handleSelect(status)}>{LABELS[status]}</MenuItem>
        )}
      </Menu>
    </>
  )
}

export default StatusChip