import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { FlexContainer, Spacer } from '../../components'
import { ROLES, SALES_TYPES } from '../../helpers/constants'
import { getAvailableOptions } from '../../helpers/permissions'
import useSite from '../../hooks/useSite'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const LeftColumn = styled.div`
  max-width: 450px;
`

const Form = styled(FormControl)`
  width: 100%;
`

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  max-width: 213px;
`

const emailHelper = "Esta dirección de correo recibirá una notificación con tu invitación y se añadirá a tu lista de supervisores o asesores dentro de alohome."

const AddUserForm = ({ newUserData, onNewUserDataChange }) => {
  const { site } = useSite()

  const availableRoles = useMemo(() => (
    getAvailableOptions(ROLES, site?.permissions)
  ), [site?.permissions])

  const currentRole = newUserData?.role?.value;
  const currentRoleLabel = useMemo(
    () => availableRoles.find((role) => role.id === currentRole)?.label,
    [availableRoles, currentRole]
  );

  return (
    <LeftColumn>
      <FlexContainer vertical>
        <Spacer size={2} vertical />
        <FlexContainer vertical>
          <TextField
            variant="standard"
            placeholder="micorreo@empresa.com"
            value={newUserData?.email?.value}
            type="email"
            label="Correo electrónico"
            helperText={newUserData?.email?.error ? newUserData?.email?.helperText : emailHelper}
            onChange={(e) => onNewUserDataChange(e.target.value, 'email')}
            error={newUserData?.email?.error}
          />
          <Spacer vertical size={3} />
          <FlexContainer justified>
            <Form variant="standard">
              <InputLabel id="role-label">Tipo de Perfil</InputLabel>
              <Select
                labelId="role-label"
                variant="standard"
                value={newUserData?.role?.value}
                onChange={(e) => onNewUserDataChange(e.target.value, 'role')}
                label="Tipo de Perfil"
                error={newUserData?.role?.error}
              >
                {availableRoles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>{role.label}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{newUserData?.role?.helperText}</FormHelperText>
            </Form>
            <Spacer size={3} />
            <Form variant="standard">
              <InputLabel id="type-label">Tipo de {currentRoleLabel || 'Usuario'}</InputLabel>
              <Select
                labelId="type-label"
                variant="standard"
                value={newUserData?.type?.value}
                onChange={(e) => onNewUserDataChange(e.target.value, 'type')}
                label={`Tipo de ${currentRoleLabel || 'Usuario'}`}
                error={newUserData?.type?.error}
              >
                {SALES_TYPES.map((type) => (
                  <MenuItem key={type.id} value={type.id}>{type.label}</MenuItem>
                ))}
              </Select>
              <FormHelperText error>{newUserData?.type?.helperText}</FormHelperText>
            </Form>
          </FlexContainer>
          <Spacer vertical size={3} />
          <StyledDatePicker
            disablePast
            inputFormat="DD/MM/YYYY"
            label="Valido hasta"
            value={newUserData?.validUntil?.value}
            onChange={(newValue) => onNewUserDataChange(newValue, 'validUntil')}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                helperText={newUserData?.validUntil?.helperText}
                error={params.error || newUserData?.validUntil?.error}
              />
            )}
          />
        </FlexContainer>
      </FlexContainer>
    </LeftColumn>
  )
}

export default AddUserForm
