import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { StatusChip } from '../../components'
import { formatDate } from '../../helpers/utils'
import React from 'react'
import styled from 'styled-components'

const Container = styled(TableContainer)`
  padding: 0 24px;
  height: calc(100vh - 650px);
  overflow-y: auto;
`

const BulkEditStatusTable = ({ newInventory }) => (
  <Container>
    <Table aria-label="bulk edit status" stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell><b>Departamento ID</b></TableCell>
          <TableCell><b>Estado</b></TableCell>
          <TableCell><b>Fecha de Reserva</b></TableCell>
          <TableCell><b>Fecha de Venta</b></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {newInventory.map(unit => (
          <TableRow key={unit.unit_id}>
            <TableCell>{unit.department_number}</TableCell>
            <TableCell><StatusChip value={unit.new_status} disabled /></TableCell>
            <TableCell>{unit.reserved_date ? formatDate(unit.reserved_date) : '-'}</TableCell>
            <TableCell>{unit.sold_date ? formatDate(unit.sold_date) : '-'}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Container>
)

export default BulkEditStatusTable
