/** @type {Partial<import('mui-datatables').MUIDataTableTextLabels>} */
export const localization = {
  toolbar: {
    search: 'Buscar',
    filterTable: 'Filtrar',
  },
  selectedRows: {
    delete: 'Eliminar',
    deleteAria: 'Eliminar filas seleccionadas',
    text: 'filas seleccionadas',
  },
  filter: {
    all: 'Todos',
    title: 'FILTROS',
    reset: 'Reestablecer',
  },
  body: {
    noMatch: '0 resultados',
    toolTip: 'Organizar',
    columnHeaderTooltip: (column) => `Organizar por ${column.label}`,
  },
  pagination: {
    next: 'Siguiente página',
    previous: 'Página anterior',
    rowsPerPage: 'Filas por página:',
    displayRows: 'de',
    jumpToPage: 'Ir a la página:',
  },
};
