import { Button } from "@mui/material"
import React from "react"
import styled, { css } from "styled-components"
import { FlexContainer } from "../../../components"

const Overlay = styled(FlexContainer)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  opacity: ${(p) => p.isDisabled ? "0.4" : "0" };
  transition: ${(p) => p.theme.transitions.create("opacity")};
`

const Container = styled(FlexContainer)`
  position: relative;
  width: 120px;
  height: 80px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12));

  ${p => (p.showButton) && css`
    &:hover button {
      display: block;
    }

    &:hover ${Overlay} {
      opacity: 0.4;
    }
  `}
`

const Color = styled.div`
  width: 100%;
  height: 80px;
  background: ${p => p.color};
`

const SelectButton = styled(Button)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  display: none;
`

const ColorCard = ({ color1, color2, color3, onClick, isDisabled, buttonText, showButton }) => (
  <Container centered verticalCentered showButton={showButton}>
    <Color color={color1} />
    <Color color={color2} />
    <Color color={color3} />
    <Overlay isDisabled={isDisabled} />
    {showButton && (
      <SelectButton variant="contained" color="primary" size="small" onClick={onClick}>{buttonText}</SelectButton>
    )}
  </Container>
)

export default ColorCard