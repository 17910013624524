import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, styled, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import ApartmentImg from "../../assets/images/project-types/apartment.png";
import HouseImg from "../../assets/images/project-types/house.png";
import LotImg from "../../assets/images/project-types/lot.jpeg";
import { Spacer } from "../../components";
import useOnboardingContext from "../../hooks/useOnboardingContext";
import PublicLayout from "../../layouts/PublicLayout";
import { SelectableCard } from "../../modules";

const PROPERTY_TYPES = [
  { value: "APARTMENT", label: "Departamentos", imageSrc: ApartmentImg },
  { value: "LOT", label: "Terrenos/Lotes", imageSrc: LotImg },
  { value: "HOUSE", label: "Casas", imageSrc: HouseImg },
];

const Container = styled("div")`
  padding: 30px 142px;
`;

const ProjectTypeSelection = () => {
  const { onNextStep, onPreviousStep, setOnboardingData, onboardingData } =
    useOnboardingContext();

  const [selectedPropertyType, setSelectedPropertyType] = useState(
    onboardingData.propertyType
  );

  const handleNext = useCallback(() => {
    setOnboardingData((prev) => ({
      ...prev,
      propertyType: selectedPropertyType,
    }));
    onNextStep();
  }, [onNextStep, setOnboardingData, selectedPropertyType]);

  return (
    <PublicLayout
      primaryLabel="Pasemos al diseño"
      primaryIcon={<ArrowForwardIcon />}
      onPrimaryClick={handleNext}
      secondaryLabel="Atrás"
      onSecondaryClick={onPreviousStep}
      showHelpMessage
      currentStep={2}
      numberOfSteps={5}
      disableNextStep={!selectedPropertyType}
    >
      <Container>
        <Typography variant="h2">¿Tu proyecto es de...?</Typography>
        <Spacer vertical size={10} />
        <Box
          sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
        >
          {PROPERTY_TYPES.map(
            ({ value: propertyType, imageSrc, label }) => (
              <SelectableCard
                key={propertyType}
                imageSrc={imageSrc}
                label={label}
                value={propertyType}
                isSelected={selectedPropertyType === propertyType}
                onSelect={setSelectedPropertyType}
                isACardSelected={!!selectedPropertyType}
              />
            )
          )}
        </Box>
      </Container>
    </PublicLayout>
  );
};

export default ProjectTypeSelection;
