import { Add as AddIcon } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { IconInput } from "../../../../components";
import DeleteCharacteristicDialog from "./DeleteCharacteristicDialog";
import Section from "./Section";

const CHARACTERISTIC_MAX_LENGTH = 20;

const Characteristics = ({
  icons,
  characteristics = [],
  setCharacteristicLabel,
  setCharacteristicIcon,
  addCharacteristic,
  removeCharacteristic,
}) => {
  const [deletingCharacteristicIndex, setDeletingCharacteristicIndex] =
    useState();

  return (
    <>
      <Section title="Características de las tipologías">
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, 300px)",
            justifyContent: "space-between",
            gap: 4,
          }}
        >
          {characteristics.map(({ label = { es: "" }, icon = "" }, index) => (
            <IconInput
              key={index}
              label="Característica"
              text={label.es}
              onTextChange={(e) =>
                setCharacteristicLabel(index, {
                  ...label,
                  es: e.target.value.substring(0, CHARACTERISTIC_MAX_LENGTH),
                })
              }
              icon={icon}
              onIconChange={(e) => setCharacteristicIcon(index, e.target.value)}
              onDelete={() => setDeletingCharacteristicIndex(index)}
              maxLength={CHARACTERISTIC_MAX_LENGTH}
              icons={icons}
            />
          ))}
        </Box>
        {characteristics.length < 6 && (
          <Button
            variant="outlined"
            color="primary"
            onClick={addCharacteristic}
            startIcon={<AddIcon />}
            sx={{ my: 2 }}
          >
            Agregar característica
          </Button>
        )}
      </Section>
      {typeof deletingCharacteristicIndex === "number" && (
        <DeleteCharacteristicDialog
          open
          onClose={() => setDeletingCharacteristicIndex(undefined)}
          onDelete={() => {
            setDeletingCharacteristicIndex((index) => {
              removeCharacteristic(index);
              return undefined;
            });
          }}
        />
      )}
    </>
  );
};

export default Characteristics;
