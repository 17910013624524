import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';

const StyledDialogActions = styled(DialogActions)`
  padding: 8px 24px 20px 24px;
`

const SimpleModal = ({ open, onClose, title, message, primaryText, onPrimaryClick, secondaryText, onSecondaryClick }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullWidth
    maxWidth="xs"
  >
    {title && (
      <DialogTitle>
        {title}
      </DialogTitle>
    )}
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {message}
      </DialogContentText>
    </DialogContent>
    <StyledDialogActions>
      {secondaryText && onSecondaryClick && (
        <Button onClick={onSecondaryClick} color="primary">{secondaryText}</Button>
      )}
      {primaryText && onPrimaryClick && (
        <Button onClick={onPrimaryClick} autoFocus color='primary' variant="contained">
          {primaryText}
        </Button>
      )}
    </StyledDialogActions>
  </Dialog>
)

export default SimpleModal
