import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Tooltip } from '@mui/material'
import Info from '@mui/icons-material/InfoOutlined'
import useTheme from '@mui/material/styles/useTheme'

const IconContainer = styled.div`
  margin-left:8px;
`

const StyledInfoIcon = styled(Info)`
  color: ${props => props.color};
`;

const InformationTooltip = ({ tooltip }) => {
  const theme = useTheme()
  return (
    <IconContainer>
      <Tooltip
        title={tooltip}
        placement="right"
        arrow
      >
        <StyledInfoIcon
          size="small"
          color={theme.palette.action.focus} />
      </Tooltip>
    </IconContainer >
  )
}

InformationTooltip.propTypes = {
  tooltip: PropTypes.string.isRequired
}

export default InformationTooltip
