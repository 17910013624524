import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Web as WebIcon
} from '@mui/icons-material';
import { Avatar, Box, Button, Checkbox, Chip, TableCell, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { Spacer } from '../../components';
import { getImageUrlFromId } from '../../helpers/assets';
import { getPermission } from '../../helpers/permissions';
import { formatName } from '../../helpers/utils';
import useSite from '../../hooks/useSite';

const useProspectsColumns = ({ toggleQuotationDetails, rowsExpanded, toggleAllRowsSelection, rowsSelection, toggleSelectRow, leads }) => {
  const { site } = useSite();
  return useMemo(() => {
    const columns = [
      {
        name: 'delete',
        label: 'delete',
        options: {
          customBodyRender: (value, data) => {
            const emailIndex = columns.indexOf(columns.find(({ name }) => name === 'email'));
            const leadEmail = (data.rowData[emailIndex] || '').trim();
            return <Checkbox checked={!!rowsSelection[leadEmail]} onChange={() => toggleSelectRow(leadEmail)} color="primary" />
          },
          customHeadRender: (data) => {
            return (
              <TableCell key={data.index}>
                <Checkbox indeterminate={Object.keys({ ...rowsSelection }).length > 0 && Object.keys({ ...rowsSelection }).length < leads.length} checked={leads.length === Object.keys(rowsSelection).length} onChange={() => toggleAllRowsSelection(Object.keys({ ...rowsSelection }).length)} color="primary" />
              </TableCell>
            )

          }
        },
      },
      {
        name: 'name',
        label: 'Nombre',
        sortname: 'clientName',
        options: {
          customBodyRender: (value, data) => {
            const emailIndex = columns.indexOf(columns.find(({ name }) => name === 'email'));
            const leadEmail = (data.rowData[emailIndex] || '').trim();
            return <>
              {leadEmail.endsWith('alohome.io') &&
                <Tooltip
                  title={'Este prospecto es de prueba, y será eliminado automáticamente en 24 horas'}
                  placement="bottom"
                  arrow
                >
                  <Chip
                    color="error"
                    variant="outlined"
                    label={'Prueba'}
                  />
                </Tooltip>
              }
              <Spacer size={1} />
              {value}
            </>;
          },
        },
      },
      {
        name: 'date',
        label: 'Fecha y Hora',
        sortname: 'createdDate',
      },
      {
        name: 'phone',
        label: 'Teléfono',
        sortname: 'clientPhone',
        options: {
          customBodyRender: (value) => {
            return value && <a href={`tel:${value}`}>{value}</a>;
          },
        },
      },
      {
        name: 'email',
        label: 'Email',
        sortname: 'clientEmail',
        options: {
          customBodyRender: (value) => {
            return value && <a href={`mailto:${value}`}>{value}</a>;
          },
        },
      },
      {
        name: 'quotes',
        label: 'Cotizaciones',
        options: {
          sort: false,
          customBodyRender: (value, { rowIndex }) => {
            if (!value || value.length === 0)
              return (
                <Button fullWidth disabled>
                  No cotizó
                </Button>
              );
            const isExpanded = rowsExpanded.includes(rowIndex);
            return (
              <Button
                fullWidth
                color="primary"
                endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                onClick={() => toggleQuotationDetails(rowIndex)}
              >
                {value.length === 1 ? 'Ver cotización' : 'Ver cotizaciones'}
              </Button>
            );
          },
        },
      },
    ];

    const hasFullView =
      site?.permissions &&
      getPermission(['prospects', 'prospects'], site?.permissions) === 'view';

    if (hasFullView)
      columns.splice(-1, 0, {
        name: 'origin',
        label: 'Origen',
        options: {
          sort: false,
          customBodyRender: (value) => {
            return (
              <Box
                sx={{
                  maxWidth: '300px',
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 1,
                }}
              >
                {value.map((item) => {
                  const isLeadSource = item.type === 'leadSource';
                  const name = isLeadSource
                    ? null
                    : formatName(item.value?.name, item.value?.lastname);
                  return item.type === 'leadSource' ? (
                    <Chip
                      key={item.value}
                      label={item.value}
                      icon={<WebIcon />}
                    />
                  ) : (
                    <Chip
                      key={item.value.userId}
                      label={name}
                      avatar={
                        item.value && (
                          <Avatar
                            alt={name}
                            src={
                              item.value.avatar &&
                              getImageUrlFromId(item.value.avatar)
                            }
                          >
                            {name?.[0]}
                          </Avatar>
                        )
                      }
                    />
                  );
                })}
              </Box>
            );
          },
        },
      });

    return columns;
  }, [toggleQuotationDetails, rowsExpanded, site, toggleAllRowsSelection, rowsSelection, toggleSelectRow, leads]);
};

export default useProspectsColumns;
