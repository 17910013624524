import React from 'react'
import { useMemo } from 'react'
import { useState } from 'react'
import { useCallback } from 'react'
import isEqual from 'react-fast-compare'
import { useDispatch } from 'react-redux'
import { PendingButton } from '../../components'
import { put } from '../../helpers/request'
import { profileTabs } from '../../helpers/tabsData'
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt'
import useProfile from '../../hooks/useProfile'
import PageLayout from '../../layouts/PageLayout'
import { DiscardChangesDialog, UserProfile } from '../../modules'

const DEFAULT_NUMBER = "+52"

const PUT_USER = "/internal/alohub/profile"

const ProfileView = () => {
  const dispatch = useDispatch()
  const profile = useProfile()
  const [profileData, setProfileData] = useState({
    name: { value: profile?.name ?? '' },
    lastname: { value: profile?.lastname ?? '' },
    email: { value: profile?.email ?? '' },
    phone: { value: profile?.custom_data?.phone ?? DEFAULT_NUMBER },
    whatsapp: { value: profile?.custom_data?.whatsapp_phone ?? DEFAULT_NUMBER },
  })

  const savedProfile = useMemo(() => ({
    name: profile?.name ?? '',
    lastname: profile?.lastname ?? '',
    email: profile?.email ?? '',
    phone: profile?.custom_data?.phone,
    whatsapp: profile?.custom_data?.whatsapp_phone,
  }), [profile?.custom_data?.phone, profile?.custom_data?.whatsapp_phone, profile?.email, profile?.lastname, profile?.name])

  const getProfileData = useCallback(() => {
    const phone = profileData?.phone?.value?.length > 3 ? profileData?.phone?.value?.replaceAll(' ', '') : undefined
    const whatsapp = profileData?.whatsapp?.value?.length > 3 ? profileData?.whatsapp?.value?.replaceAll(' ', '') : undefined
    return {
      name: profileData?.name?.value?.trim(),
      lastname: profileData?.lastname?.value?.trim(),
      email: profileData?.email?.value,
      phone,
      whatsapp,
    }
  }, [profileData?.email?.value, profileData?.lastname?.value, profileData?.name?.value, profileData?.phone?.value, profileData?.whatsapp?.value])

  const ValidateForm = useCallback(() => {
    let isValid = true
    if (profileData?.name?.value?.trim() === "") {
      setProfileData(p => ({
        ...p,
        name: {
          ...p.name,
          error: true,
        }
      }))
      isValid = false
    }
    return isValid
  }, [profileData])

  const enableSave = useMemo(() => {
    const data = getProfileData()
    return !isEqual(data, savedProfile)
  }, [getProfileData, savedProfile])

  const [showPrompt, confirmNavigation] = useCallbackPrompt(enableSave)

  const handleSave = useCallback(() => {
    if (ValidateForm()) {
      const data = getProfileData()
      put(PUT_USER, {
        name: data.name,
        lastname: data.lastname,
        phone: data.phone,
        whatsapp_phone: data.whatsapp,
      })
      dispatch({ type: 'reload-profile', data: true })
    }
  }, [ValidateForm, dispatch, getProfileData])

  const onProfileChange = useCallback((value, attribute) => {
    setProfileData((d) => ({
      ...d,
      [attribute]: { value }
    }))
  }, [])

  const saveAndLeave = useCallback(() => {
    handleSave()
    confirmNavigation()
  }, [confirmNavigation, handleSave])

  return (
    <PageLayout
      links={profileTabs}
      menu={<PendingButton
        text="Guardar"
        onClick={handleSave}
        showBullet={enableSave}
      />}
    >
      <DiscardChangesDialog
        open={showPrompt}
        onSave={saveAndLeave}
        onDiscard={confirmNavigation}
      />
      <UserProfile
        avatar={profile?.avatar}
        name={profileData?.name}
        lastName={profileData?.lastname}
        email={profileData?.email}
        phone={profileData?.phone}
        whatsapp={profileData?.whatsapp}
        onProfileChange={onProfileChange}
      />
    </PageLayout>
  )
}

export default ProfileView