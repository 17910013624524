import React, { Fragment, useCallback, useMemo } from "react"
import { FlexContainer, IconInput, Spacer } from "../../../components"
import FacebookIcon from "../../../assets/icons/facebook.svg"
import InstagramIcon from "../../../assets/icons/instagram.svg"
import TwitterIcon from "../../../assets/icons/twitter.svg"
import WhatsappIcon from "../../../assets/icons/whatsapp.svg"
import YoutubeIcon from "../../../assets/icons/youtube.svg"
import AddIcon from '@mui/icons-material/Add'
import { Button, Typography } from "@mui/material"

const ICONS = [
  { value: "Facebook", src: FacebookIcon, alt: "Facebook" },
  { value: "Instagram", src: InstagramIcon, alt: "Instagram" },
  { value: "Twitter", src: TwitterIcon, alt: "Twitter" },
  { value: "WhatsApp", src: WhatsappIcon, alt: "WhatsApp" },
  { value: "YouTube", src: YoutubeIcon, alt: "YouTube" },
]

/*
CONFIG.JS DEFINITIONS
{
  Facebook: {
    order: 1,
    href: "https://www.facebook.com/miproyecto"
  },
  Instagram: {
    order: 2,
    href: "https://www.instagram.com/miproyecto/"
  },
  Twitter: {
    order: 3,
    href: "https://www.twitter.com/miproyecto/"
  },
  WhatsApp: {
    order: 4,
    href: "https://www.whatsapp.com/miproyecto/"
  },
  YouTube: {
    order: 5,
    href: "https://www.youtube.com/channel/miproyecto/videos"
  }
}
*/

const SocialNetworks = ({ pathData = {}, setPathData }) => {
  const currentMedias = useMemo(() => Object.keys(pathData ?? {}), [pathData])

  const getAvailableIcons = useCallback((currentIcon) => {
     const availableMedias = ICONS.filter(
      i => !currentMedias.includes(i.value) || i.value === currentIcon
    )
    return availableMedias
  }, [currentMedias])

  const handleAdd = useCallback(() => {    
    const availableMedias = ICONS.filter(i => !currentMedias.includes(i.value))
    const mediaToAdd = availableMedias[0]
    if (mediaToAdd) {
      setPathData({...pathData, 
        [mediaToAdd.value]: {
          href: '',
          order: currentMedias.length + 1,
        }
      })
    }
  }, [currentMedias, setPathData, pathData])

  const handleDelete = useCallback((attribute) => {
    let newValue = { ...pathData }
    delete newValue[attribute]
    Object.keys(newValue).forEach((key, index) => {
      newValue[key] = {
        ...newValue[key],
        order: index + 1,
      }
    })
    setPathData(newValue)
  }, [setPathData, pathData])

  const handleLabelChange = useCallback((inputValue, attribute) => {
    const newValue = {
      ...pathData,
      [attribute]: {
        ...pathData[attribute],
        href: inputValue
      }
    }
    setPathData(newValue)
  }, [setPathData, pathData])

  const handleIconChange = useCallback((icon, attribute) => {
    const currentValue = pathData[attribute]
    let newValue = { ...pathData }
    delete newValue[attribute]
    newValue[icon] = currentValue
    setPathData(newValue)
  }, [setPathData, pathData])

  return (
    <FlexContainer vertical>
      <FlexContainer justified verticalCentered>
        <Typography variant="caption" color="text.secondary">Redes Sociales</Typography>
        {currentMedias.length < ICONS.length && (
          <Button color="primary" size="small" startIcon={<AddIcon />} onClick={handleAdd}>Añadir</Button>
        )}
      </FlexContainer>
      {Object.entries(pathData).sort((a, b) => a[1].order - b[1].order).map(([key, social]) => (
        <Fragment key={key}>
          <Spacer vertical size={2} />
          <IconInput
            icons={getAvailableIcons(key)}
            text={social.href}
            icon={key}
            label={key}
            onDelete={() => handleDelete(key)}
            onTextChange={(e) => handleLabelChange(e.target.value, key)}
            onIconChange={(e) => handleIconChange(e.target.value, key)}
          />
        </Fragment>
      ))}
    </FlexContainer>
  )
}

export default SocialNetworks
