import { useEffect, useState } from "react"

function useScreenWidthBelowEqual(threshold) {
  const [isBelowEqualThreshold, setIsBelowEqualThreshold] = useState(null)

  useEffect(() => {
    function handleResize() {
      const windowWidth = window.innerWidth
      if (windowWidth <= threshold) {
        setIsBelowEqualThreshold(true)
      } else {
        setIsBelowEqualThreshold(false)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [threshold])

  return isBelowEqualThreshold
}

export default useScreenWidthBelowEqual