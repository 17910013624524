import {
  Alert,
  Button,
  FormControlLabel,
  Snackbar,
  Switch,
  Typography,
} from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import styled from 'styled-components';
import { FlexContainer, PendingButton, Spacer } from '../../components';
import { projectTabs } from '../../helpers/tabsData';
import PageLayout from '../../layouts/PageLayout';
import SectionDescription from './SectionDescription';
import { DEFAULT_SETTINGS, getDate, useWorkProgress } from './utils';
import WorkProgressSettings from './WorkProgressSettings';
import NearMeIcon from '@mui/icons-material/NearMe';
import { post } from '../../helpers/request';
import useSite from '../../hooks/useSite';
import isEqual from 'react-fast-compare';
import Preview from './Preview';
import { PLANS } from '../../helpers/constants';
import { DiscardChangesDialog } from '../../modules';
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import {
  convertToY,
  getYObjectKeys,
  useSharedDocument,
} from '../../hooks/documentHooks';
import { getData, getInventory } from '../../helpers/draft';
import { getKeysAsPath, getPathValue, NOM_PATH } from '../../helpers/data';

const Container = styled(FlexContainer)`
  padding: 32px;
  flex-direction: column;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    color: ${(p) =>
      p.$enabled
        ? p.theme.palette.primary.main
        : p.theme.palette.text.disabled};
  }
`;

const PublishButton = styled(LoadingButton)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  background: theme.palette.secondary.light,
  '&:hover': {
    background: 'var(--color-secondary-gradient)',
  },
}));

const WorkProgressView = () => {
  const dispatch = useDispatch();
  const { site } = useSite();
  const [yDocument, , documentReady] = useSharedDocument(site?.site_id, {
    ws: process.env.REACT_APP_WEBSOCKET,
  });
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [savedData, setSavedData] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [inventory, setInventory] = useState(null);
  const [data, setData] = useState(null);
  const previewRef = useRef(null);
  const [showSuccessSave, setShowSuccessSave] = useState(false);
  const [showSuccessPublish, setShowSuccessPublish] = useState(false);
  const [showError, setShowError] = useState(false);

  const onCloseSuccessSave = useCallback(() => setShowSuccessSave(false), []);
  const onCloseSuccessPublish = useCallback(
    () => setShowSuccessPublish(false),
    []
  );
  const onCloseError = useCallback(() => setShowError(false), []);

  useEffect(() => {
    const getDataFromDb = async () => {
      if (site?.site_id && documentReady) {
        const dataMap = yDocument && yDocument?.getMap('data');
        const inventoryMap = yDocument && yDocument?.getMap('inventory');
        let data = dataMap && dataMap?.toJSON();
        let inventory = inventoryMap && inventoryMap?.toJSON();
        if (!!data && Object.keys(data).length === 0 && !!dataMap) {
          data = await getData(site);
        }
        if (
          !!inventory &&
          Object.keys(inventory).length === 0 &&
          !!inventoryMap
        ) {
          inventory = await getInventory(site);
        }
        setInventory(inventory);
        setData(data);
        const progressData = data.custom_data?.work_progress;
        if (progressData && Object.keys(progressData).length > 0) {
          setEnabled(progressData.enabled);
          const formattedData = {
            ...progressData,
            periods: progressData.periods.map((p) => ({
              ...p,
              date: getDate(p.date),
            })),
          };
          setSavedData(formattedData);
        } else {
          setSavedData(DEFAULT_SETTINGS);
          if (site.plan === PLANS.ENTREPRENEUR || site.plan == null) {
            setEnabled(true);
            setSavedData((s) => ({ ...s, enabled: true }));
          }
        }
        setIsLoadingData(false);
      }
    };
    getDataFromDb();
  }, [site, documentReady, yDocument]);

  const {
    workProgressData,
    isValid,
    onPeriodsReorder,
    ...workProgressSettings
  } = useWorkProgress({
    enabled,
    currentData: savedData,
  });

  useEffect(() => {
    const preview = previewRef?.current;
    const handlePreview = () => {
      if (data && inventory && workProgressData && preview) {
        const newData = {
          ...data,
          pages: {
            home: {
              slug: '/',
              sections: [
                {
                  type: 'transparency-construction-progress-section',
                  id: 'default-transparency-construction-progress-section',
                  hubid: '[transparency-construction-progress-section]',
                  title: '[:building_2:] Avance de obra',
                  content:
                    'Conforme avanza la construcción del proyecto, aquí encontrarás la actualización de cada una etapas.\nTambién tendrás un histórico, en imágenes, de los progresos de la obra.',
                  className:
                    'has-gallery full-height icon-bullets section_with_neutral_background',
                  gallery_title: 'Avance del proyecto',
                },
              ],
            },
          },
          custom_data: {
            ...data.custom_data,
            work_progress: workProgressData,
          },
        };
        preview.contentWindow.postMessage(
          {
            action: 'hot-reload-alohub',
            inventory,
            data: newData,
          },
          '*'
        );
      }
    };
    handlePreview();
    if (preview) {
      preview.addEventListener('load', handlePreview);
    }
    return () => {
      if (preview) {
        preview.removeEventListener('load', handlePreview);
      }
    };
  }, [data, inventory, workProgressData]);

  const openPlans = useCallback(() => {
    dispatch({ type: 'open-plans-modal' });
  }, [dispatch]);

  const togglePreview = useCallback(() => setShowPreview((p) => !p), []);

  const enableSave = useMemo(() => {
    if (isLoadingData) return false;
    const hasChanges = !isEqual(savedData, workProgressData);
    return isValid && hasChanges;
  }, [isValid, isLoadingData, savedData, workProgressData]);

  const [showPrompt, confirmNavigation] = useCallbackPrompt(enableSave);

  const handleSave = useCallback(() => {
    if (site?.remainingDays <= 0) {
      openPlans();
      return;
    }

    onPeriodsReorder();
    setIsSaving(true);
    const fileMap = yDocument && yDocument?.getMap('data');
    const fileMaps = getYObjectKeys(fileMap);
    if (fileMaps.custom_data) {
      fileMaps.custom_data.set('work_progress', convertToY(workProgressData));
    }

    const changes = [
      {
        path: 'custom_data.work_progress',
        value: workProgressData,
      },
    ];

    const hasNOMData = !!getPathValue(data, NOM_PATH);
    if (hasNOMData)
      changes.push({
        path: getKeysAsPath([NOM_PATH, 'updatedAt']),
        value: new Date().toISOString(),
      });

    post(
      '/internal/developer/v1.1/files',
      {
        changes,
        stage: ['draft'],
        fileName: 'data',
      },
      site?.site_id
    )
      .then(() => setShowSuccessSave(true))
      .catch(() => setShowError(true))
      .finally(() => setIsSaving(false));
    setSavedData(workProgressData);
  }, [
    onPeriodsReorder,
    openPlans,
    site?.remainingDays,
    site?.site_id,
    workProgressData,
    yDocument,
    data
  ]);

  const handlePublish = useCallback(() => {
    if (site?.remainingDays <= 0) {
      openPlans();
      return;
    }

    handleSave();
    setIsPublishing(true);

    const changes = [
      {
        path: 'custom_data.work_progress',
        value: workProgressData,
      },
    ];

    const hasNOMData = !!getPathValue(data, NOM_PATH);
    if (hasNOMData)
      changes.push({
        path: getKeysAsPath([NOM_PATH, 'updatedAt']),
        value: new Date().toISOString(),
      });

    post(
      '/internal/developer/v1.1/files',
      {
        changes,
        stage: ['draft', 'production'],
        fileName: 'data',
      },
      site?.site_id
    )
      .then(() => setShowSuccessPublish(true))
      .catch(() => setShowError(true))
      .finally(() => setIsPublishing(false));
  }, [
    handleSave,
    openPlans,
    site?.remainingDays,
    site?.site_id,
    workProgressData,
    data
  ]);

  const saveAndLeave = useCallback(() => {
    handleSave();
    if (site?.remainingDays > 0) {
      confirmNavigation();
    }
  }, [confirmNavigation, handleSave, site?.remainingDays]);

  const isLoading = isLoadingData || isSaving || isPublishing;

  return (
    <>
      <Snackbar
        open={showSuccessSave}
        autoHideDuration={6_000}
        onClose={onCloseSuccessSave}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Alert severity="success" onClose={onCloseSuccessSave}>
          Información guardada
        </Alert>
      </Snackbar>
      <Snackbar
        open={showSuccessPublish}
        autoHideDuration={6_000}
        onClose={onCloseSuccessPublish}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Alert severity="success" onClose={onCloseSuccessPublish}>
          Información guardada y publicada
        </Alert>
      </Snackbar>
      <Snackbar
        open={showError}
        autoHideDuration={6_000}
        onClose={onCloseError}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Alert severity="error" onClose={onCloseError}>
          Oops. Algo ha salido mal. Por favor inténtalo de nuevo.
        </Alert>
      </Snackbar>
      <PageLayout
        loading={isLoadingData}
        links={projectTabs}
        menu={
          <FlexContainer>
            <PendingButton
              text="Guardar"
              onClick={handleSave}
              showBullet={enableSave}
              disabled={isLoading || !enableSave}
              loading={isSaving}
              loadingPosition="start"
            />
            <Spacer size={2} />
            <PublishButton
              variant="contained"
              startIcon={<NearMeIcon />}
              onClick={handlePublish}
              disabled={isLoading}
              loading={isPublishing}
              loadingPosition="start"
            >
              Publicar
            </PublishButton>
          </FlexContainer>
        }
      >
        <DiscardChangesDialog
          open={showPrompt}
          onSave={saveAndLeave}
          onDiscard={confirmNavigation}
        />
        <Preview open={showPreview} onClose={togglePreview} ref={previewRef} />
        <Container>
          <FlexContainer fullWidth justified>
            <FlexContainer verticalCentered>
              <Typography variant="h5">Avance de Obra</Typography>
              {(site?.plan === PLANS.ENTERPRISE ||
                site?.plan === PLANS.GROWTH) && (
                <>
                  <Spacer size={5} />
                  <StyledFormControlLabel
                    $enabled={enabled}
                    control={
                      <Switch
                        color="primary"
                        checked={enabled}
                        onChange={(e) => setEnabled(e.target.checked)}
                      />
                    }
                    label={enabled ? 'Activo' : 'Inactivo'}
                    labelPlacement="start"
                  />
                </>
              )}
            </FlexContainer>
            {enabled && (
              <Button
                color="primary"
                variant="contained"
                onClick={togglePreview}
              >
                vista previa
              </Button>
            )}
          </FlexContainer>
          <Spacer vertical size={4} />
          {enabled ? (
            <WorkProgressSettings {...workProgressSettings} />
          ) : (
            <SectionDescription />
          )}
        </Container>
      </PageLayout>
    </>
  );
};

export default WorkProgressView;
