import { GoogleMap, Marker } from "@react-google-maps/api";

const Map = ({center, selected}) => {
  const mapContainerStyles = { width: '100%', height: '100%', minHeight: '340px' }
  return (
    <GoogleMap
      zoom={selected ? 15 : 10}
      center={selected || center}
      mapContainerStyle={mapContainerStyles}
      mapContainerClassName="map-container"
    >
      {selected && <Marker position={selected} />}
    </GoogleMap>
  );
}

export default Map