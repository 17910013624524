import { Button, Typography } from "@mui/material"
import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { FlexContainer, Spacer } from "../../../components"
import { SECTIONS_DATA } from "../../../helpers/constants"
import { RightSidebar, SimpleModal } from "../../../modules"
import SectionCard from "./SectionCard"
import { ReactSortable } from "react-sortablejs"
import FloatingBanner from "../../../assets/svgs/showroom_sections/floating-banner.svg"
import { OUTSIDE_SECTIONS } from "../useSections"

const Container = styled(FlexContainer)`
  padding: 64px 32px;
`

const SectionsContainer = styled(FlexContainer)`
  background: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 8px 16px;
  height: calc(100vh - 314px);
  overflow-y: auto;
`

const Save = styled(Button)`
  width: 120px;
`

const SectionsManager = ({ open, onClose, page = {}, onSave, onReorder, onDelete, onEnable }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [sectionToDeleteIndex, setSectionToDeleteIndex] = useState(null)

  const handleDelete = useCallback((index) => {
    setSectionToDeleteIndex(index)
    setShowDeleteModal(true)
  }, [])

  const closeDeleteModal = useCallback(() => setShowDeleteModal(false), [])

  const handleDeleteConfirmation = useCallback(() => {
    onDelete(sectionToDeleteIndex)
    setShowDeleteModal(false)
  }, [onDelete, sectionToDeleteIndex])

  return (
    <>
      <SimpleModal
        open={showDeleteModal}
        onClose={closeDeleteModal}
        message={`¿Quieres eliminar la sección "${page?.sections ? SECTIONS_DATA[page?.sections[sectionToDeleteIndex]?.type]?.label : ''}"?`}
        primaryText="Eliminar"
        onPrimaryClick={handleDeleteConfirmation}
        secondaryText="Cancelar"
        onSecondaryClick={closeDeleteModal}
      />
      <RightSidebar open={open} onClose={onClose}>
        <Container vertical>
          <Typography variant="h5">Decide el orden de la información en tu showroom digital </Typography>
          <Spacer vertical size={1} />
          <Typography>Arrasta, elimina, activa o desactiva las secciones disponibles</Typography>
          <Spacer vertical size={4} />
            <SectionsContainer vertical>
              <SectionCard text="Hero" disableEditing />
              <SectionCard
                text="Banner Flotante"
                icon={FloatingBanner}
                disableDrag
                isEnabled={!page?.banner?.disabled ?? true}
                onEnable={(value) => onEnable(value, OUTSIDE_SECTIONS.floatingBanner)}
              />
              <ReactSortable list={page?.sections ?? []} setList={onReorder} handle='.draggable' animation={300} direction="vertical">
                {page.sections?.map((s, i) => (
                  <SectionCard
                    key={s.id}
                    text={SECTIONS_DATA[s.type].label}
                    icon={SECTIONS_DATA[s.type].icon}
                    isEnabled={!s.disabled ?? true}
                    onDelete={() => handleDelete(i)}
                    onEnable={(value) => onEnable(value, i)}
                  />
                ))}
              </ReactSortable>
              <SectionCard text="Footer" disableEditing />
            </SectionsContainer>
          <Spacer vertical size={4} />
          <Save color="primary" variant="contained" onClick={onSave}>Guardar</Save>
        </Container>
      </RightSidebar>
    </>
  )
}

export default SectionsManager
