import { Button } from '@mui/material';
import React, { useState } from 'react';
import ProjectsManagementDrawer from './ProjectsManagementDrawer';

const ProjectsManagement = ({ pathData, setPathData }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setShow(true)}>
        editar proyectos
      </Button>
      <ProjectsManagementDrawer
        projects={pathData}
        setProjects={setPathData}
        open={show}
        onClose={() => setShow(false)}
        PaperProps={{ sx: { maxWidth: 'lg', width: 1, p: 4 } }}
      />
    </>
  );
};

export default ProjectsManagement;
