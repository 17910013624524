import {
  Chip,
  ClickAwayListener,
  NativeSelect,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

const FiltersContainer = styled.div`
  position: relative;
`;

const FiltersModal = styled.div`
  position: absolute;
  top: 80px;
  padding: 8px;
  background: #ffffff;
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
    0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  z-index: 1;
  display: grid;
  grid-template-columns: auto 220px 220px 220px;
  gap: 20px;
`;

const CustomTextField = styled(TextField)`
  > div {
    flex-wrap: wrap;
  }
`;
const initialForm = {
  column: '',
  condition: '',
  value: '',
  values: [],
};

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
}));

export const FilterBar = ({
  children,
  open,
  closeModal,
  columns = [],
  conditions = [],
  setFilters,
}) => {
  const classes = useStyles();
  const [form, setForm] = useState(initialForm);
  const handleForm = ({ target }) => {
    setForm((form) => ({ ...form, [target.name]: target.value }));
  };

  const clearOrCloseFilters = () => {
    if (form.column || form.condition || form.value || form.values.length > 0) {
      setForm(initialForm);
    } else {
      closeModal();
    }
  };

  useEffect(() => {
    if (
      (form.column && form.condition && form.value) ||
      (form.column && form.condition === 'any_of' && form.values.length > 0)
    ) {
      setFilters({
        ...form,
      });
    } else setFilters(null);
  }, [form, setFilters]);

  const handleAddToValues = ({ target, key }) => {
    if (form.condition === 'any_of' && key === 'Enter' && target.value) {
      setForm((form) => ({
        ...form,
        values: [...form.values, target.value],
        value: '',
      }));
    }
  };

  const handleDeleteValue = (item) => () => {
    setForm((form) => {
      const newValues = [...form.values];
      newValues.splice(newValues.indexOf(item), 1);
      return { ...form, values: newValues };
    });
  };

  return (
    <ClickAwayListener onClickAway={closeModal}>
      <FiltersContainer>
        {children}
        {open && (
          <FiltersModal>
            <CloseIcon onClick={clearOrCloseFilters} />
            <NativeSelect
              label="Columna"
              name="column"
              variant="standard"
              value={form.column}
              onChange={handleForm}
              displayEmpty
              InputLabelProps={{
                shrink: true,
              }}
            >
              <option value="">Columna</option>
              {columns.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </NativeSelect>
            <NativeSelect
              label="Condición"
              name="condition"
              variant="standard"
              value={form.condition}
              onChange={handleForm}
              displayEmpty
              InputLabelProps={{
                shrink: true,
              }}
            >
              <option value="">Condición</option>
              {conditions.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </NativeSelect>
            {form.condition !== 'empty' && form.condition !== 'not_empty' && (
              <CustomTextField
                label="Valor"
                name="value"
                variant="standard"
                value={form.value}
                onChange={handleForm}
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyUp={handleAddToValues}
                InputProps={{
                  startAdornment:
                    form.condition !== 'any_of'
                      ? null
                      : form.values.map((item) => (
                          <Chip
                            key={item}
                            tabIndex={-1}
                            label={item}
                            className={classes.chip}
                            onDelete={handleDeleteValue(item)}
                          />
                        )),
                }}
              />
            )}
          </FiltersModal>
        )}
      </FiltersContainer>
    </ClickAwayListener>
  );
};
