import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Portal } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../../components';
import {
  findPathsByHubidSlug,
  getPathValue,
  getRelativePath,
  mergePaths,
  modifyPath,
} from '../../../helpers/data';
import getEditionDefinition, { shouldSyncHubId } from '../showroomDefinition';
import PaletteIcon from '@mui/icons-material/Palette';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { SectionsComponent } from './Sections';
import ThemesSelector from './ThemesSelector';

const FOOTER_ACTIONS = {
  themes: 'themes',
  sections: 'sections',
  order: 'order',
};

const FOOTER_BUTTONS = [
  {
    id: FOOTER_ACTIONS.themes,
    label: 'TEMAS',
    icon: <PaletteIcon />,
    tooltip:
      'Cambia el diseño de tu showroom digital: elige otros colores y otra tipografía.',
  },
  {
    id: FOOTER_ACTIONS.sections,
    label: 'SECCIONES',
    icon: <AppRegistrationIcon />,
    tooltip:
      'Agrega nuevas secciones y muestra aún más tu proyecto. Prueba los diferentes elementos.',
  },
  {
    id: FOOTER_ACTIONS.order,
    label: 'ORDEN',
    icon: <DragHandleIcon />,
    tooltip:
      'Decide el orden de la información y activa o desactiva secciones para mejorar la experiencia de los clientes. ',
  },
];

const Sidebar = styled.div`
  background: #fff;
  position: fixed;
  left: ${(p) => (p.open ? '0' : '-332px')};
  top: 0;
  width: 332px;
  height: calc(100vh - 72px);
  transition: 1s left;
  overflow-y: auto;
  margin-top: 72px;
  z-index: 1;
`;

const BackButton = styled(Button)`
  width: fit-content;
`;

const Container = styled(FlexContainer)`
  padding: 16px;
`;

const Body = styled(FlexContainer)`
  height: calc(100vh - 72px - 67px);
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar-track {
    border-radius: 16px;
    opacity: 0.2;
    background-color: rgba(0, 0, 0, .05);
  }
  &::-webkit-scrollbar {
    width: 12px;
    background-color: rgba(0, 0, 0, .05);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: var(--color-primary);
  }
`;

const Footer = styled(FlexContainer)`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const FooterButton = styled(Button)`
  width: 100%;
  background: var(--color-primary-10);
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 2px solid transparent;
  ${(p) =>
    p.selected
      ? `
    color: var(--color-primary-main);
    border-bottom: 2px solid var(--color-primary-main); 
  `
      : ''}
`;

const Tooltip = styled.div`
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  position: absolute;
  bottom: 85px;
  background: #fff;
  padding: 24px 16px;
  margin: 0 20px;
`;

const EditorSidebar = ({
  open,
  onClose,
  inventory,
  setInventory,
  data,
  setData,
  onOrderClick,
  viewSectionsManager,
  viewThemesEditor,
  previewRef,
  setTheme,
  setThemeConfig,
  themeConfig,
  toggleThemesEditor,
  onInspectChange,
  openPrompt,
  enableSave,
  setIsDialogInEditor,
}) => {
  const [selectedFooterButton, setSelectedFooterButton] = useState(null);
  const [customComponent, setCustomComponent] = useState(null);
  const [selection, setSelection] = useState(null);
  const [tooltipText, setTooltipText] = useState(null);
  const [viewCustomColors, setViewCustomColors] = useState(false);
  const [viewSectionsDragPanel, setViewSectionsDragPanel] = useState(false);

  useEffect(() => {
    const elementSelectionHandler = (event) => {
      const { action, fullPath, hubidPath, hubid } = event.data;
      if (action === 'element-selected')
        setSelection(
          fullPath && hubidPath && hubid
            ? { fullPath, hubidPath, hubid }
            : null,
        );
    };
    window.addEventListener('message', elementSelectionHandler);
    return () => window.removeEventListener('message', elementSelectionHandler);
  }, []);

  useEffect(() => {
    if (!viewThemesEditor && !viewSectionsManager && !viewSectionsDragPanel) {
      setSelectedFooterButton(null);
    }
  }, [viewThemesEditor, viewSectionsManager, viewSectionsDragPanel]);

  const editionDefinition = useMemo(
    () =>
      !selection
        ? null
        : getEditionDefinition(
            selection.hubid,
            getRelativePath(selection.hubidPath, selection.fullPath),
          ),
    [selection],
  );

  const Component = editionDefinition?.Component || customComponent?.Component;
  const componentProps =
    editionDefinition?.componentProps || customComponent?.props;

  const pathData = useMemo(
    () =>
      editionDefinition && selection
        ? getPathValue(
            data,
            mergePaths(selection.hubidPath, editionDefinition.definitionPath),
          )
        : null,
    [data, editionDefinition, selection],
  );

  const sectionData = useMemo(
    () =>
      editionDefinition && selection
        ? getPathValue(data, selection.hubidPath)
        : null,
    [data, editionDefinition, selection],
  );

  const editionPaths = useMemo(() => {
    if (editionDefinition && shouldSyncHubId(editionDefinition.editionId)) {
      return findPathsByHubidSlug(data, editionDefinition.editionId).map(
        (path) => mergePaths(path, editionDefinition.definitionPath),
      );
    } else if (editionDefinition) {
      const path = mergePaths(
        selection?.hubidPath,
        editionDefinition?.definitionPath,
      );
      return [path];
    }
    return null;
  }, [data, editionDefinition, selection?.hubidPath]);

  const setPathData = useCallback(
    (dataPath) => {
      if (!editionPaths) return;
      setData((data) => {
        for (const path of editionPaths) {
          modifyPath(data, path, dataPath);
        }
        return { ...data };
      });
    },
    [setData, editionPaths],
  );

  const setSectionData = useCallback(
    (dataPath) => {
      if (!selection.hubidPath) return;
      setData((data) => {
        modifyPath(data, selection.hubidPath, dataPath);
        return { ...data };
      });
    },
    [setData, selection?.hubidPath],
  );

  const toggleDragPanel = useCallback((flag) =>
    setViewSectionsDragPanel(
      flag !== undefined ? flag : !viewSectionsDragPanel,
    ), [viewSectionsDragPanel]);

  const onSectionsClick = useCallback(() => {
    setCustomComponent({
      Component: SectionsComponent,
      props: { previewRef, setData, data },
    });
    onInspectChange(false)
    setSelection(null);
    toggleDragPanel(true);
  }, [previewRef, setData, data, onInspectChange, toggleDragPanel]);

  const onThemesClick = useCallback(() => {
    setCustomComponent({
      Component: ThemesSelector,
    });
    onInspectChange(false)
    setViewCustomColors(false)
    setSelection(null);
  }, [onInspectChange]);

  const handleFooterClick = useCallback(
    (action) => {
      setSelectedFooterButton(action);
      switch (action) {
        case FOOTER_ACTIONS.themes:
          onThemesClick();
          break;
        case FOOTER_ACTIONS.sections:
          onSectionsClick();
          break;
        case FOOTER_ACTIONS.order:
          onOrderClick();
          break;
        default:
      }
    },
    [onOrderClick, onThemesClick, onSectionsClick],
  );

  const toggleCustomColors = useCallback(() => setViewCustomColors(c => !c), [])

  const handleGoBackClick = useCallback(() => {
    if(!customComponent && enableSave) {
      setIsDialogInEditor(true);
      openPrompt();
    } else if (viewSectionsDragPanel) {
      toggleDragPanel(false)
      setCustomComponent(null)
      onInspectChange(true)
    } else if (viewCustomColors) {
      toggleCustomColors()
    } else if (customComponent) {
      setCustomComponent(null)
      onInspectChange(true)
    } else {
      onClose()
    }
  }, [customComponent, onClose, onInspectChange, toggleCustomColors, toggleDragPanel, viewCustomColors, viewSectionsDragPanel, enableSave, openPrompt, setIsDialogInEditor])

  const backButtonText = useMemo(() => {
    if (customComponent) {
      return "Regresar"
    }
    return "Salir de edición" 
  }, [customComponent])

  return (
    <Portal>
      <Sidebar open={open} id="showroom-sidebar">
        <Body vertical>
          <BackButton
            color="primary"
            onClick={handleGoBackClick}
            startIcon={<ArrowBackIcon />}
          >
            {backButtonText}
          </BackButton>
          <Container vertical>
            {Component && (
              <Component
                {...componentProps}
                inventory={inventory}
                setInventory={setInventory}
                data={data}
                setData={setData}
                pathData={pathData}
                setPathData={setPathData}
                sectionData={sectionData}
                setSectionData={setSectionData}
                setTheme={setTheme}
                setThemeConfig={setThemeConfig}
                themeConfig={themeConfig}
                toggleThemesEditor={toggleThemesEditor}
                viewThemesEditor={viewThemesEditor}
                viewCustomColors={viewCustomColors}
                onCustomize={toggleCustomColors}
              />
            )}
          </Container>
        </Body>
        <Footer>
          {tooltipText && <Tooltip>{tooltipText}</Tooltip>}
          {FOOTER_BUTTONS.map((b) => (
            <FooterButton
              selected={b.id === selectedFooterButton}
              key={b.id}
              onMouseOver={() => setTooltipText(b.tooltip)}
              onMouseLeave={() => setTooltipText(null)}
              onClick={() => handleFooterClick(b.id)}
            >
              {b.icon}
              {b.label}
            </FooterButton>
          ))}
        </Footer>
      </Sidebar>
    </Portal>
  );
};

export default EditorSidebar;
