import { Button, Paper, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AlertMessage, Spacer, Wrapper } from '../../components';
import { PERMISSIONS } from '../../helpers/permissions';
import { get, post } from '../../helpers/request';
import { projectTabs } from '../../helpers/tabsData';
import { convertDateToMMYY, convertMMYYtoFormat } from '../../helpers/utils';
import useSettings from '../../hooks/useSettings';
import PageLayout from '../../layouts/PageLayout';
import { ProjectPreviewModal } from '../../modules';
import InventoryFilter from '../../modules/Inventory/InventoryFilter';
import BulkEditDeliveryDate from './BulkEditDeliveryDate';
import BulkEditPrice from './BulkEditPrice';
import BulkEditSelector from './BulkEditSelector';
import BulkEditStatus from './BulkEditStatus';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import InventoryTable from './InventoryTable';
import NewChangesModal from './NewChangesModal';
import SoldDateModal from './SoldDateModal';
import { getComparator, INVENTORY_SCREEN, stableSort } from './utils';

const getLastUpdateFn = ({ queryKey }) =>
  get('/internal/alohub/inventory/last-change', { site_id: queryKey[1] });

const Container = styled.div`
 position: relative;
 `

const AlertContainer = styled.div`
   position: absolute;
   top: -32px; // padding-top of container (SalesView.js)
   left: calc(50% - 275px); // half of max-width of AlertMessage
`
const VIEW_TEXTS = {
  labelTogglePreview: "Vista Previa",
  unsavedChangesAlert: {
    title: "Tienes cambios sin guardar",
    content: "Has clic en el botón de \"GUARDAR\" para guardar tus cambios",
  },
}

const InventoryView = () => {
  const [inventory, setInventory] = useState([])
  const [filters, setFilters] = useState([])
  const [viewAlert, setViewAlert] = useState(false)
  const [unitsToUpdate, setUnitsToUpdate] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('department_number');
  const [, setError] = useState(false)
  const [isSoldModalOpen, setIsSoldModalOpen] = useState(false);
  const [modalSoldUnit, setModalSoldUnit] = useState({});
  const [isChangesModalOpen, setIsChangesModalOpen] = useState(false);
  const [disabledChangesModalSaveButton, setDisabledChangesModalSaveButton] = useState(false)
  const [inventoryScreen, setInventoryScreen] = useState(INVENTORY_SCREEN.inventory)
  const [bulkEditInventory, setBulkEditInventory] = useState([])
  const [showProjectPreview, setShowProjectPreview] = useState(false)

  const settings = useSettings()
  const mainCurrencyCode = settings?.dualCurrency?.main
  const secondaryCurrencyCode = settings?.dualCurrency?.secondary
  const secondaryCurrencyRate = settings?.dualCurrency?.rate
  const selectedSite = useSelector(state => state.site)
  const showSoldDate = useMemo(() => modalSoldUnit.new_status === 'sold', [modalSoldUnit.new_status])

  const showReservedDate = useMemo(() =>
    modalSoldUnit.new_status === 'reserved'
    || ((modalSoldUnit.status === 'available' || modalSoldUnit.status === 'offline') && modalSoldUnit.new_status === 'sold'
    ), [modalSoldUnit.new_status, modalSoldUnit.status])

  const numSelected = useMemo(() => inventory.filter(i => i.is_selected).length, [inventory])

  const sortedInventory = useMemo(
    () => stableSort(inventory, getComparator(order, orderBy)),
    [inventory, order, orderBy]
  );

  const changeStatusPermission = useMemo(() => PERMISSIONS.edit, [])
  const changeDeliveryDatePermission = useMemo(() => PERMISSIONS.edit, [])
  const changePricePermission = useMemo(() => PERMISSIONS.edit, [])

  const editPermissionIsHidden = useMemo(() =>
    changeStatusPermission === PERMISSIONS.hidden
    && changeDeliveryDatePermission === PERMISSIONS.hidden
    && changePricePermission === PERMISSIONS.hidden
    , [changeDeliveryDatePermission, changePricePermission, changeStatusPermission])

  useEffect(() => {
    const getInventory = async () => {
      try {
        const filtersWithCurrency = filters.indexOf('price_') !== -1 ? `${filters}&price_currency=${mainCurrencyCode}` : filters
        const result = await get('/internal/inventory/v3/inventory?' + filtersWithCurrency, { site_id: selectedSite.site_id })
        setInventory(result.inventory.map(inventoryItem => {
          const price = inventoryItem.prices.find(({ currency }) => currency === mainCurrencyCode)
          const fullUpfrontPaymentPrice = inventoryItem?.fields?.price_full_upfront_payment ? inventoryItem.fields?.price_full_upfront_payment[mainCurrencyCode] : null
          return {
            ...inventoryItem,
            unit_id: inventoryItem.unit_id,
            [`price_${mainCurrencyCode}`]: Math.trunc(price?.price) || 0,
            new_price: Math.trunc(price?.price) || 0,
            [`price_${mainCurrencyCode}_full_upfront_payment`]: Math.trunc(fullUpfrontPaymentPrice) || null,
            new_full_upfront_payment_price: Math.trunc(fullUpfrontPaymentPrice) || null,
            new_status: inventoryItem.status,
            is_selected: false,
            delivery_date: convertDateToMMYY(new Date(inventoryItem.delivery_date)),
            new_delivery_date: convertDateToMMYY(new Date(inventoryItem.delivery_date)),
          }
        }))
      } catch (e) {
        setError(e)
      }
    }
    if (mainCurrencyCode)
      getInventory()
  }, [filters, mainCurrencyCode, selectedSite?.site_id])

  useEffect(() => {
    if (unitsToUpdate.length > 0) {
      setViewAlert(true)
    } else {
      setViewAlert(false)
    }
  }, [unitsToUpdate])

  const toggleProjectPreview = useCallback(() => setShowProjectPreview(p => !p), [])
  const valueNotConsideredEmpty = useCallback((value) => value !== "" && value !== 0, [])

  const updateInventory = useCallback((unitId, updates) => {
    setInventory(units => {
      const newInventory = units.map(i => i.unit_id === unitId ? { ...i, ...updates } : i)
      return newInventory
    })
  }, [])

  const batchUpdateInventory = useCallback((updates) => {
    const newInventory = inventory.map(i => {
      const unit = updates.find(u => u.unit_id === i.unit_id)
      if (unit) {
        return {
          ...i,
          ...unit,
        }
      }
      return i
    })
    setInventory(newInventory)
  }, [inventory])

  const updateUnitsToUpdate = useCallback((unitId, updates) => {
    setUnitsToUpdate(units => {
      let newUnitsToUpdate = []
      const unitToUpdate = units.find(u => u.unit_id === unitId)
      if (unitToUpdate) {
        newUnitsToUpdate = units.map(u => u.unit_id === unitId ? { ...u, ...updates } : u)
      } else {
        newUnitsToUpdate = [...units, { unit_id: unitId, ...updates }]
      }
      return newUnitsToUpdate
    })
  }, [])

  const removeUnitsToUpdate = useCallback((unitId, attributesToRemove) => {
    let newInventory = []
    const unitToUpdate = unitsToUpdate.find(u => u.unit_id === unitId)
    if (unitToUpdate) {
      attributesToRemove.forEach(attribute => {
        delete unitToUpdate[attribute]
      })
      if (Object.keys(unitToUpdate).length === 2) { // unit_id and department_number are the only attributes
        newInventory = unitsToUpdate.filter(u => u.unit_id !== unitId)
      } else {
        newInventory = unitsToUpdate.map(u => u.unit_id === unitId ? unitToUpdate : u)
      }
      setUnitsToUpdate(newInventory)
    }
  }, [unitsToUpdate])

  const handleChangeStatus = useCallback((unitId, status) => {
    const unit = inventory.find(i => i.unit_id === unitId)

    if (unit.status !== status) {
      if (status === 'sold' || ((unit.status === 'available' || unit.status === 'offline') && status === 'reserved')) {
        setIsSoldModalOpen(true)
        setModalSoldUnit({
          department_number: unit.department_number,
          unit_id: unitId,
          status: unit.status,
          new_status: status,
        })
      } else {
        updateInventory(unitId, {
          new_status: status,
          sold_date: undefined,
          ...(status === 'reserved' && { reserved_date: undefined }),
        })
        updateUnitsToUpdate(unitId, {
          status: unit.status,
          new_status: status,
          department_number: unit.department_number,
          sold_date: undefined,
          ...(status === 'reserved' && { reserved_date: undefined }),
        })
      }
    } else {
      updateInventory(unitId, {
        new_status: status,
        sold_date: undefined,
        reserved_date: undefined,
      })
      removeUnitsToUpdate(unitId, ['status', 'new_status', 'sold_date', 'reserved_date'])
    }
  }, [inventory, removeUnitsToUpdate, updateInventory, updateUnitsToUpdate])

  const handleEditDeliveryDate = useCallback((unitId) => {
    updateInventory(unitId, { is_editing_delivery_date: true })
  }, [updateInventory])

  const handleConfirmDeliveryDate = useCallback((unitId) => {
    const updates = { is_editing_delivery_date: false }
    const unit = inventory.find(i => i.unit_id === unitId)
    if (unit.delivery_date !== unit.new_delivery_date) {
      if (unit.new_delivery_date.indexOf("-") === -1) { // Date not completely written
        updateUnitsToUpdate(unitId, {
          delivery_date: unit.delivery_date,
          new_delivery_date: unit.new_delivery_date,
          department_number: unit.department_number,
        })
      } else {
        updates.new_delivery_date = unit.delivery_date
        removeUnitsToUpdate(unitId, ['delivery_date', 'new_delivery_date'])
      }
    } else {
      removeUnitsToUpdate(unitId, ['delivery_date', 'new_delivery_date'])
    }
    updateInventory(unitId, updates)
  }, [inventory, removeUnitsToUpdate, updateInventory, updateUnitsToUpdate])

  const handleChangeDeliveryDate = useCallback((unitId, newDeliveryDate) => {
    updateInventory(unitId, { new_delivery_date: newDeliveryDate })
  }, [updateInventory])

  const handleEditPrice = useCallback((unitId) => {
    updateInventory(unitId, { is_editing_price: true })
  }, [updateInventory])

  const handleConfirmPrice = useCallback((unitId) => {
    updateInventory(unitId, { is_editing_price: false })
    const unit = inventory.find(i => i.unit_id === unitId)
    if (unit[`price_${mainCurrencyCode}`] !== unit.new_price) {
      updateUnitsToUpdate(unitId, {
        [`price_${mainCurrencyCode}`]: unit[`price_${mainCurrencyCode}`],
        new_price: unit.new_price,
        department_number: unit.department_number,
      })
    } else {
      removeUnitsToUpdate(unitId, [`price_${mainCurrencyCode}`, 'new_price'])
    }
  }, [inventory, removeUnitsToUpdate, updateInventory, updateUnitsToUpdate, mainCurrencyCode])

  const handleEditFullUpfrontPaymentPrice = useCallback((unitId) => {
    updateInventory(unitId, { is_editing_full_upfront_payment_price: true })
  }, [updateInventory])

  const handleConfirmFullUpfrontPaymentPrice = useCallback((unitId) => {
    updateInventory(unitId, { is_editing_full_upfront_payment_price: false })
    const unit = inventory.find(i => i.unit_id === unitId)
    if (unit[`price_${mainCurrencyCode}_full_upfront_payment`] !== unit.new_full_upfront_payment_price) {
      updateUnitsToUpdate(unitId, {
        [`price_${mainCurrencyCode}_full_upfront_payment`]: unit[`price_${mainCurrencyCode}_full_upfront_payment`],
        new_full_upfront_payment_price: valueNotConsideredEmpty(unit.new_full_upfront_payment_price) ? unit.new_full_upfront_payment_price : null,
        department_number: unit.department_number,
      })
    } else {
      removeUnitsToUpdate(unitId, [`price_${mainCurrencyCode}_full_upfront_payment`, 'new_full_upfront_payment_price'])
    }
  }, [updateInventory, inventory, mainCurrencyCode, updateUnitsToUpdate, valueNotConsideredEmpty, removeUnitsToUpdate])

  const handleChangePrice = useCallback((unitId, newPrice) => {
    updateInventory(unitId, { new_price: Number(newPrice) })
  }, [updateInventory])

  const handleChangeFullUpfrontPaymentPrice = useCallback((unitId, newPrice) => {
    updateInventory(unitId, { new_full_upfront_payment_price: (!isNaN(newPrice) && newPrice > 0) ? Number(newPrice) : null })
  }, [updateInventory])

  const handleRequestSort = useCallback((event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }, [order, orderBy])

  const handleCloseAlert = useCallback(() => setViewAlert(false), [])

  const handleSave = useCallback(() => {
    setIsChangesModalOpen(true)
  }, [])

  const handleCancelSoldModal = useCallback(() => {
    setIsSoldModalOpen(false)
    setModalSoldUnit({})
  }, [])

  const handleSaveSoldModal = useCallback((newReservedDate, newSoldDate) => {
    const soldDate = new Date(newSoldDate.replaceAll('-', '/'))
    const reservedDate = new Date(newReservedDate.replaceAll('-', '/'))
    const updateObject = {
      new_status: modalSoldUnit.new_status,
      ...(showSoldDate && { sold_date: soldDate }),
      ...(showReservedDate && { reserved_date: reservedDate }),
    }
    updateInventory(modalSoldUnit.unit_id, updateObject)
    updateUnitsToUpdate(modalSoldUnit.unit_id, {
      ...updateObject,
      status: modalSoldUnit.status,
      department_number: modalSoldUnit.department_number,
    })
    setIsSoldModalOpen(false)
    setModalSoldUnit({})
  }, [modalSoldUnit, showReservedDate, showSoldDate, updateInventory, updateUnitsToUpdate])

  const handleCloseChangeModal = useCallback(() => setIsChangesModalOpen(false), [])

  const handleSaveChangeModal = useCallback(async () => {
    try {
      setDisabledChangesModalSaveButton(true)
      const updateArray = unitsToUpdate.map(u => {
        const mm = u.new_delivery_date && Number(convertMMYYtoFormat(u.new_delivery_date, 'MM')) - 1 // in Date() months are from 0-11
        const yyyy = u.new_delivery_date && Number(convertMMYYtoFormat(u.new_delivery_date, 'YYYY'))
        const d = u.new_delivery_date && 2 // To avoid 2026/11/01 (Local Time) gets converted in 2026/10/01 (UTC), day should be at least 2
        return {
          unit_id: u.unit_id,
          ...(u.new_price && { prices: [{ price: u.new_price, currency: mainCurrencyCode }, ...(secondaryCurrencyCode ? [{ price: Math.ceil(+u.new_price / +secondaryCurrencyRate), currency: secondaryCurrencyCode }] : [])] }),
          ...(u.new_status && { status: u.new_status }),
          ...((u.new_full_upfront_payment_price || u.new_full_upfront_payment_price === null) && { price_full_upfront_payment: {[mainCurrencyCode]: valueNotConsideredEmpty(u.new_full_upfront_payment_price) ? u.new_full_upfront_payment_price : null, ...(secondaryCurrencyCode ? {[secondaryCurrencyCode]: valueNotConsideredEmpty(u.new_full_upfront_payment_price) ? Math.ceil(u.new_full_upfront_payment_price / +secondaryCurrencyRate) : null} : {})} }),
          ...(u.new_delivery_date && { delivery_date: new Date(yyyy, mm, d) }),
          ...(u.sold_date && { sold_date: u.sold_date }),
          ...(u.reserved_date && { reserved_date: u.reserved_date }),
        }
      })
      await post('/internal/inventory/v3/inventory/edit', updateArray, selectedSite?.site_id);
      await post('/internal/admin/v3/generate/inventory', {
        site_id: selectedSite?.site_id,
      });

      const newInventory = inventory.map(i => ({
        ...i,
        [`price_${mainCurrencyCode}`]: i.new_price,
        [`price_${mainCurrencyCode}_full_upfront_payment`]: valueNotConsideredEmpty(i.new_full_upfront_payment_price) ? i.new_full_upfront_payment_price : null,
        status: i.new_status,
        delivery_date: i.new_delivery_date,
      }))

      setInventory(newInventory)
      setViewAlert(false)
      setIsChangesModalOpen(false)
      setUnitsToUpdate([])
      setDisabledChangesModalSaveButton(false)
    } catch (e) {
      setError(e)
      setDisabledChangesModalSaveButton(false)
    }
  }, [unitsToUpdate, selectedSite?.site_id, inventory, mainCurrencyCode, secondaryCurrencyCode, secondaryCurrencyRate, valueNotConsideredEmpty])

  const handleChangeCheck = useCallback((unitId, newValue) => {
    updateInventory(unitId, { is_selected: newValue })
  }, [updateInventory])

  const handleSelectAll = useCallback((newValue) => {
    const newInventory = inventory.map(i => ({ ...i, is_selected: newValue }))
    setInventory(newInventory)
  }, [inventory])

  const handleBulkEdit = useCallback(() => {
    setInventoryScreen(INVENTORY_SCREEN.bulkEdit)
    const selectedInventory = inventory.filter(i => i.is_selected)
    setBulkEditInventory(selectedInventory)
  }, [inventory])

  const handleBulkCancel = useCallback(() => {
    setInventoryScreen(INVENTORY_SCREEN.inventory)
    setBulkEditInventory([])
  }, [])

  // TODO: Refactor handleBulkEdit functions into a single common function
  const handleBulkEditPrice = useCallback((unitsToUpdate) => {
    unitsToUpdate.forEach((u) => {
      const unit = {
        unit_id: u.unit_id,
        department_number: u.department_number,
        [`price_${mainCurrencyCode}`]: u[`price_${mainCurrencyCode}`],
        new_price: u.new_price,
      }
      updateUnitsToUpdate(u.unit_id, unit)
    })
    batchUpdateInventory(unitsToUpdate.map(u => ({ ...u, is_selected: false })))
    setInventoryScreen(INVENTORY_SCREEN.inventory)
  }, [batchUpdateInventory, updateUnitsToUpdate, mainCurrencyCode])

  const handleBulkEditDeliveryDate = useCallback((unitsToUpdate) => {
    unitsToUpdate.forEach((u) => {
      const unit = {
        unit_id: u.unit_id,
        department_number: u.department_number,
        delivery_date: u.delivery_date,
        new_delivery_date: u.new_delivery_date
      }
      updateUnitsToUpdate(u.unit_id, unit)
    })
    batchUpdateInventory(unitsToUpdate.map(u => ({ ...u, is_selected: false })))
    setInventoryScreen(INVENTORY_SCREEN.inventory)
  }, [batchUpdateInventory, updateUnitsToUpdate])

  const handleBulkEditStatus = useCallback((unitsToUpdate) => {
    unitsToUpdate.forEach((u) => {
      const unit = {
        unit_id: u.unit_id,
        department_number: u.department_number,
        status: u.status,
        new_status: u.new_status,
        reserved_date: u.reserved_date,
        sold_date: u.sold_date,
      }
      updateUnitsToUpdate(u.unit_id, unit)
    })
    batchUpdateInventory(unitsToUpdate.map(u => ({ ...u, is_selected: false })))
    setInventoryScreen(INVENTORY_SCREEN.inventory)
  }, [batchUpdateInventory, updateUnitsToUpdate])

  const siteId = selectedSite?.site_id;
  const { data: lastUpdate } = useQuery(
    ['LAST_INVENTORY_UPDATE', siteId],
    getLastUpdateFn,
    { enabled: !!siteId }
  );

  const lastUpdateUserName = useMemo(
    () =>
      [lastUpdate?.user?.name, lastUpdate?.user?.lastname]
        .filter(Boolean)
        .join(' '),
    [lastUpdate]
  );
  const lastUpdateDate = useMemo(() => {
    if (!lastUpdate) return null;
    try {
      return new Date(lastUpdate.lastChangeDate);
    } catch {
      return null;
    }
  }, [lastUpdate]);

  return (
    <PageLayout
      links={projectTabs}
      menu={<Button color="primary" variant="outlined" onClick={toggleProjectPreview}>{VIEW_TEXTS.labelTogglePreview}</Button>}
    >
      <ProjectPreviewModal open={showProjectPreview} onClose={toggleProjectPreview} />
      <Wrapper>
        <Container>
          <NewChangesModal
            open={isChangesModalOpen}
            onCancel={handleCloseChangeModal}
            onSave={handleSaveChangeModal}
            unitsToUpdate={unitsToUpdate}
            disableSavedButton={disabledChangesModalSaveButton}
          />
          <SoldDateModal
            open={isSoldModalOpen}
            onCancel={handleCancelSoldModal}
            onSave={handleSaveSoldModal}
            showReserved={showReservedDate}
            showSold={showSoldDate}
          />
          {viewAlert && (
            <AlertContainer>
              <AlertMessage
                severity="warning"
                title={VIEW_TEXTS.unsavedChangesAlert.title}
                message={VIEW_TEXTS.unsavedChangesAlert.content}
                onClose={handleCloseAlert}
              />
            </AlertContainer>
          )}
          <InventoryFilter onFiltersChange={setFilters} role="developer" />
          <Spacer vertical size={1} />
          <Paper>
            {!editPermissionIsHidden
              ? (
                <EnhancedTableToolbar
                  numSelected={numSelected}
                  isSaveDisabled={unitsToUpdate.length === 0}
                  isEditing={inventoryScreen !== INVENTORY_SCREEN.inventory}
                  onSave={handleSave}
                  onBulkEdit={handleBulkEdit}
                  onCancelEdit={handleBulkCancel}
                />
              ) : (
                <Spacer size={2} vertical />
              )
            }
            {inventoryScreen === INVENTORY_SCREEN.inventory && (
              <InventoryTable
                inventory={sortedInventory}
                order={order}
                orderBy={orderBy}
                numSelected={numSelected}
                rowCount={inventory.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAll}
                onStatusChange={handleChangeStatus}
                onPriceChange={handleChangePrice}
                onEditPrice={handleEditPrice}
                onConfirmPrice={handleConfirmPrice}
                onFullUpfrontPaymentPriceChange={handleChangeFullUpfrontPaymentPrice}
                onEditFullUpfrontPaymentPrice={handleEditFullUpfrontPaymentPrice}
                onConfirmFullUpfrontPaymentPrice={handleConfirmFullUpfrontPaymentPrice}
                onCheckChange={handleChangeCheck}
                onEditDeliveryDate={handleEditDeliveryDate}
                onConfirmDeliveryDate={handleConfirmDeliveryDate}
                onDeliveryDateChange={handleChangeDeliveryDate}
                canSelectRow={!editPermissionIsHidden}
                changeStatusPermission={changeStatusPermission}
                changeDeliveryDatePermission={changeDeliveryDatePermission}
                changePricePermission={changePricePermission}
              />
            )}
            {inventoryScreen === INVENTORY_SCREEN.bulkEdit && (
              <BulkEditSelector
                onSelect={setInventoryScreen}
                changeStatusPermission={changeStatusPermission}
                changeDeliveryDatePermission={changeDeliveryDatePermission}
                changePricePermission={changePricePermission}
              />
            )}
            {inventoryScreen === INVENTORY_SCREEN.bulkEditPrice && (
              <BulkEditPrice inventoryBulk={bulkEditInventory} onUpdate={handleBulkEditPrice} />
            )}
            {inventoryScreen === INVENTORY_SCREEN.bulkEditDeliveryDate && (
              <BulkEditDeliveryDate inventoryBulk={bulkEditInventory} onUpdate={handleBulkEditDeliveryDate} />
            )}
            {inventoryScreen === INVENTORY_SCREEN.bulkEditStatus && (
              <BulkEditStatus inventoryBulk={bulkEditInventory} onUpdate={handleBulkEditStatus} />
            )}
          </Paper>
        </Container>
      </Wrapper>
      {lastUpdateUserName && lastUpdateDate && (
        <Typography variant="body1" sx={{ mb: 4 }}>
          Actualizado por {lastUpdateUserName} ({lastUpdate?.user?.email}) el{' '}
          {moment(lastUpdateDate).format('D/M/yyyy - h:mm a')}
        </Typography>
      )}
    </PageLayout>
  )
}

export default InventoryView
