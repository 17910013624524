import { Upload as UploadIcon } from '@mui/icons-material';
import { styled } from '@mui/material';
import React, { useId } from 'react';

const Label = styled('label')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.04)',
  border: '1px dashed rgba(0, 0, 0, 0.26)',
  borderRadius: 4,
});

const StyledUploadIcon = styled(UploadIcon)({
  width: '3rem',
  height: '3rem',
});

const Input = styled('input')({
  display: 'none',
  '&::-webkit-file-upload-button': {
    visibility: 'hidden',
  },
});

function AssetsUploadInput({ uploadLabel, inputProps, sx }) {
  const id = useId();
  const inputId = inputProps?.id || id;
  return (
    <>
      <Label htmlFor={inputId} sx={{ cursor: 'pointer', ...sx }}>
        <StyledUploadIcon color="action" />
        {uploadLabel}
      </Label>
      <Input {...inputProps} id={inputId} />
    </>
  );
}

export default AssetsUploadInput;
