import React from "react"
import styled from "styled-components"
import { FlexContainer } from "../../components"
import defaultLogo from "../../assets/svgs/default-logo.svg"

const ASSETS_URL = "https://assets.alohome.io/assets/"

const ImageContainer = styled(FlexContainer)`
  min-width: 200px;
  max-width: 200px;
`

const Image = styled.img`
  max-width: 180px;
  max-height: 85px;
  object-fit: contain;
`

const ProjectImage = ({ src, alt }) => (
  <ImageContainer centered verticalCentered>
    <Image src={src ? `${ASSETS_URL}${src}` : defaultLogo} alt={alt} />
  </ImageContainer>
)

export default ProjectImage
