import { TextField } from "@mui/material";
import React from "react";
import Section from "./Section";

const DESCRIPTION_MAX_LENGTH = 232;

const Description = ({ description = { es: "" }, setDescription }) => {
  return (
    <Section title="Descripción de la tipología">
      <TextField
        name="description"
        placeholder="Descripción..."
        value={description.es}
        onChange={(e) =>
          setDescription({
            ...description,
            es: e.target.value.substring(0, DESCRIPTION_MAX_LENGTH),
          })
        }
        variant="standard"
        helperText={`${description.es.length}/${DESCRIPTION_MAX_LENGTH}`}
        multiline
        fullWidth
      />
    </Section>
  );
};

export default Description;
