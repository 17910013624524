import { Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import React from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../components';

const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid ${p => p.color};
`

const ArrowDown = styled.div`
  width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid ${p => p.color};
`

const Container = styled(FlexContainer)`
  width: 50px;
  ${({hidden}) => hidden && 'display: none;'}
`

const PriceChangeInfo = ({ basePrice, newPrice }) => {
  const theme = useTheme();
  const percentage = ((newPrice * 100 / basePrice) - 100).toFixed(2)
  return (
    <Container vertical centered hidden={basePrice === null || basePrice === "" || (basePrice === newPrice)}>
      {newPrice > basePrice && (
        <>
          <ArrowUp color={theme.palette.success.main} />
          <Typography style={{ color: theme.palette.success.main }}  variant='caption'>{percentage}%</Typography>
        </>
      )}
      {newPrice < basePrice && (
        <>
          <ArrowDown color={theme.palette.error.main} />
          <Typography color="error" variant='caption'>{percentage}%</Typography>
        </>
      )}
    </Container>
  )
}

export default PriceChangeInfo
