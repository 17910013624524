import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import Section from "./Section";

const TAGS = [
  { value: "best_sellers", label: "El diseño más vendido" },
  { value: "best_view", label: "Con la mejor vista" },
  { value: "wider", label: "El más espacioso" },
];

const Tag = ({ tag, setTag }) => {
  return (
    <Section title="Tags">
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {TAGS.map(({ value, label }) => {
          const isSelected = tag === value;
          return (
            <Chip
              key={value}
              label={label}
              clickable
              color={isSelected ? "primary" : undefined}
              variant="contained"
              onClick={() => (isSelected ? setTag(undefined) : setTag(value))}
            />
          );
        })}
      </Box>
      <Typography variant="body2" sx={{ color: "text.secondary", mt: 4 }}>
        Los tags se mostrarán en el carusel de tipologías en el showroom
      </Typography>
    </Section>
  );
};

export default Tag;
