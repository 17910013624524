import {
  Button,
  Card,
  CardContent,
  Typography
} from "@mui/material";
import React from "react";
import HoverCardMedia from "../Cards/HoverCardMedia";

const ImageCard = ({ assetId, url, metadata, onSelected }) => (
  <Card>
    <HoverCardMedia height={194} src={url} alt="">
      <Button
        variant="contained"
        color="primary"
        onClick={onSelected}
        sx={{ textTransform: "none" }}
      >
        Elegir
      </Button>
    </HoverCardMedia>
    <CardContent>
      <Typography variant="body2">
        {metadata?.name || metadata?.fileName}
      </Typography>
    </CardContent>
  </Card>
);

export default ImageCard;
