import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { get } from '../helpers/request';
import useSettings from './useSettings';
import { useQuery } from '@tanstack/react-query';

const getCurrenciesFn = () => get('/v2/alohome/currencies');

const useCurrencies = () => {
  const { data } = useQuery(['CURRENCIES'], getCurrenciesFn, {
    staleTime: Infinity,
  });
  const currencies = useSelector((state) => state.currencies);
  const dispatch = useDispatch();
  const currencySettings = useSettings('general', 'currency');

  const mainCurrency = currencySettings?.dualCurrency?.main;
  const secondaryCurrency = currencySettings?.dualCurrency?.secondary;

  useEffect(() => {
    if (data && mainCurrency)
      dispatch({
        type: 'set-currencies',
        currencies: {
          main: {
            code: mainCurrency,
            symbol: data[mainCurrency],
          },
          secondary: {
            code: secondaryCurrency,
            symbol: data[secondaryCurrency],
          },
        },
      });
  }, [data, mainCurrency, secondaryCurrency, dispatch]);

  return currencies;
};

export default useCurrencies;
