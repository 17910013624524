import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useSite from '../../hooks/useSite';
import styled from 'styled-components';
import { FlexContainer, Spacer } from '../../components';
import { ProjectImage } from '..';
import { IconButton, Link, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useMemo } from 'react';
import { useCallback } from 'react';

const Container = styled(FlexContainer)`
  position: relative;
  padding: 16px;
`

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 0;
`

const ProjectPreviewModal = ({ open, onClose }) => {
  const { site } = useSite()
  const domain = useMemo(() => `https://${site?.site_domain}`, [site?.site_domain])

  const copyToClipboard = useCallback(() => navigator.clipboard.writeText(domain), [domain])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
    >
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      <DialogContent>
        {site && (
          <Container topped>
            <ProjectImage src={site.site_logo} alt={site.site_name} />
            <Spacer size={3} />
            <FlexContainer vertical>
              <Typography variant="h4">{site.site_name}</Typography>
              <Spacer vertical size={1} />
              {site.location?.address && (
                <Typography variant="subtitle1">
                  {site.location?.address}
                </Typography>
              )}
              <Spacer vertical size={4} />
              <FlexContainer verticalCentered>
                <Link href={domain} target="_blank" underline="always"  color="primary">
                  {domain}
                </Link>
                <Spacer size={2} />
                <IconButton onClick={copyToClipboard}>
                  <ContentCopyIcon color="primary" fontSize="small" />
                </IconButton>
              </FlexContainer>
            </FlexContainer>
          </Container>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ProjectPreviewModal
