import { CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { formatRecordName } from './recordNameUtils';

const COLUMNS = [
  {
    name: 'name',
    label: 'Record Name',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => formatRecordName(value),
    },
  },
  {
    name: 'type',
    label: 'Tipo',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'ttl',
    label: 'TTL',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'values',
    label: 'Valor',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (values, { tableData, rowIndex }) => {
        const { autoconfigured } = tableData[rowIndex];
        return (
          values && (
            <div>
              {autoconfigured ? (
                <Box sx={{ display: 'flex', alingItems: 'center', gap: 1 }}>
                  <CheckCircleOutlineIcon color="success" />
                  <Typography>Contectado a Alohome</Typography>
                </Box>
              ) : (
                values.map((value) => (
                  <Typography key={value}>{value}</Typography>
                ))
              )}
            </div>
          )
        );
      },
    },
  },
];

export default COLUMNS;
