import { TextField, Typography } from '@mui/material'
import React from 'react'
import { Spacer, RangeSlider } from '../../components'
import { INVENTORY_TYPE } from '../../helpers/constants'
import Discounts from './Discounts'

const FlexibleQuoteSettings = ({
  minDownPaymentPercent,
  onMinDownPaymentPercentChange,
  maxDownPaymentPercent,
  onMaxDownPaymentPercentChange,
  minPreSalePercent,
  onMinPreSalePercentChange,
  hasDiscounts,
  discounts,
  onHasDiscountsChange,
  onAddNewDiscount,
  onDeleteDiscount,
  onChangeDiscount,
  onBlurDiscount,
  onKeyUpDiscount,
  fixedNumberOfMonths,
  onFixedNumberOfMonthsChange,
  inventoryType,
  hasPaymentSchemes,
  hasExternalLoan,
}) => (
  <>
    {inventoryType === INVENTORY_TYPE.typology && hasPaymentSchemes && (
      <>
        <Typography variant='subtitle1'>Mensualidades </Typography>
        <Spacer size={1} vertical />
        <Typography>Define la cantidad de mensualidades que tendrán las cotizaciones</Typography>
        <Spacer size={2} vertical />
        <TextField
          variant='standard'
          size="small"
          placeholder='7'
          label='Número de meses'
          value={fixedNumberOfMonths.value}
          onChange={e => onFixedNumberOfMonthsChange(e.target.value)}
          error={fixedNumberOfMonths.error}
          helperText={fixedNumberOfMonths.error && 'Las mensualidades deben de ser mayor a 0'}
        />
        <Spacer size={6} vertical />
      </>
    )}
    {hasPaymentSchemes && (
      <>
        <RangeSlider
          title='Enganche mínimo (%)'
          endSymbol='%'
          description='Porcentaje más bajo que podría elegir un cliente'
          min={0}
          max={100}
          step={1}
          value={minDownPaymentPercent}
          onChange={onMinDownPaymentPercentChange}
        />
        <Spacer size={6} vertical />
        <RangeSlider
          title='Enganche máximo (%)'
          endSymbol='%'
          description='Porcentaje más alto que podría elegir un cliente'
          min={0}
          max={100}
          step={1}
          value={maxDownPaymentPercent}
          onChange={onMaxDownPaymentPercentChange}
        />
        <Spacer size={6} vertical />
      </>
    )}
    {(hasExternalLoan || hasPaymentSchemes) && (
      <RangeSlider
        title='Porcentaje a cubrir en preventa (%)'
        endSymbol='%'
        description='Cantidad que el cliente debe pagar antes de recibir su unidad (suma de la reservación en línea, enganche y mensualidades)'
        min={0}
        max={100}
        step={1}
        value={minPreSalePercent}
        onChange={onMinPreSalePercentChange}
      />
    )}
    {hasPaymentSchemes && (
      <>
        <Spacer size={6} vertical />
        <Discounts
          hasDiscounts={hasDiscounts}
          discounts={discounts}
          onHasDiscountsChange={onHasDiscountsChange}
          onAddNewDiscount={onAddNewDiscount}
          onDeleteDiscount={onDeleteDiscount}
          onChangeDiscount={onChangeDiscount}
          onBlurDiscount={onBlurDiscount}
          onKeyUpDiscount={onKeyUpDiscount}
        />
      </>
    )}
  </>
)

export default  FlexibleQuoteSettings
