import ListSubheader from '@mui/material/ListSubheader';
import styled from 'styled-components';

const ListOptionsSubheader = styled(ListSubheader)`
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  margin: 0 1rem;
  &.MuiListSubheader-root {
    padding-inline: 0;
  }
`
export default ListOptionsSubheader