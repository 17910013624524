import Button from '@mui/material/Button';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { useCallback } from 'react';
import { ListItemOption } from '../../components';
import InitialsAvatar from './InitialsAvatar';

const ProjectOption = ({label, onClick}) => {
  const handleClick = useCallback(() => {
    if(onClick) onClick()
  }, [onClick])

  return (
    <ListItemOption>
      <Button onClick={handleClick} variant="text">
        <ListItemAvatar>
          <InitialsAvatar string={label} />
        </ListItemAvatar>
        <ListItemText primary={label} sx={{ color: 'text.primary' }}/>
      </Button>
    </ListItemOption>
  )
}

export default ProjectOption