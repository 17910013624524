import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Toolbar, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import React from 'react'
import styled from 'styled-components'
import NewChangesTable from './NewChangesTable'

const SaveButton = styled(Button)`
  background: ${red[500]};
  color: #fff;
`

const VIEW_TEXTS = {
  title: "CONFIRMA TUS CAMBIOS",
  description: "Has actualizado las siguientes unidades, ¿estás seguro que deseas guardar los cambios?",
  toolbarTitle: "UNIDADES A ACTUALIZAR",
  buttonCancel: "Cancelar",
  buttonSave: "Guardar Cambios",
}

const NewChangesModal = ({ open, onCancel, onSave, unitsToUpdate, disableSavedButton }) => (
  <Dialog open={open} maxWidth="lg" fullWidth>
    <DialogTitle>{VIEW_TEXTS.title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{VIEW_TEXTS.description}</DialogContentText>
      <Paper elevation={3}>
        <Toolbar>
          <Typography variant="h6">{VIEW_TEXTS.toolbarTitle}</Typography>
        </Toolbar>
        <NewChangesTable unitsToUpdate={unitsToUpdate} />
      </Paper>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} variant="outlined" color="primary">
        {VIEW_TEXTS.buttonCancel}
      </Button>
      <SaveButton onClick={onSave} variant="contained" disabled={disableSavedButton}>
        {VIEW_TEXTS.buttonSave}
      </SaveButton>
    </DialogActions>
  </Dialog>
)

export default NewChangesModal
