import { useEffect, useState, useRef } from 'react'

export function useNearScreen (keepElement = true) {
  const element = useRef(null)
  const [show, setShow] = useState(false)

  /* Lazy Loading */
  useEffect(() => {
    Promise.resolve(
      typeof window.IntersectionObserver !== 'undefined'
        ? window.IntersectionObserver
        : import('intersection-observer')
    ).then(() => {
      const observer = new window.IntersectionObserver((entries) => {
        const { isIntersecting } = entries[0]
        if (isIntersecting) {
          setShow(true)
          if (keepElement) {
            observer.disconnect()
          }
        } else {
          if (!keepElement) {
            setShow(false)
          }
        }
      })
      observer.observe(element.current)
      return () => observer.disconnect()
    })
  }, [element, keepElement])

  return [show, element]
}
