import React from 'react'
import PropTypes from 'prop-types'
import { IMaskInput, IMask } from 'react-imask'
import moment from 'moment'
import { Input } from '@mui/material'

const FormattedInput = React.forwardRef(function FormattedInput(props, ref) {
  const { onChange, ...other } = props
  const momentFormat = 'MM/YY'
  return (
    <IMaskInput
      {...other}
      mask={Date}
      pattern={momentFormat}
      inputRef={ref}
      onAccept={value => onChange({ target: { value } })}
      format={date => moment(date).format(momentFormat)}
      parse={str => moment(str, momentFormat)}
      lazy={false}
      min={new Date(2020, 0, 1)}
      blocks={{
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
          placeholderChar: '-'
        },
        YY: {
          mask: IMask.MaskedRange,
          from: 20,
          to: 99,
          placeholderChar: '-'
        }
      }}

    />
  );
});

const MMYYInput = ({ value, onChange }) => {
  return (
    <Input
      value={value}
      inputComponent={FormattedInput}
      inputProps={{
        onChange: onChange
      }}
    />
  )
}

MMYYInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default MMYYInput