import { FormControl, TextField, Typography, Alert } from "@mui/material";
import { FlexContainer, Spacer } from "../../components";
import { post } from "../../helpers/request";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import PublicLayout from "../../layouts/PublicLayout";
import { Link } from "react-router-dom";
import useOnKeyDown from "../../hooks/useOnKeyDown";

const FormContent = styled.div`
  width: 350px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  field-content {
    display: flex;
    width: 100%;
  }
  a {
    color: var(--color-text-primary);
  }
`;
const StyledAlert = styled(Alert)`
  align-self: stretch;
`;
const LabelField = styled(Typography)`
  padding: 8px 0;
`;

const CustomTitle = styled(Typography)`
  text-align: center;
  max-width: 900px;
`;

export const ForgotPasswordView = () => {
  const [successMessage, setSuccessMessage] = useState(null);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");

  const handleChangeEmail = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    post('/internal/alohub/password-reset/send-email', {
      email,
      resetPasswordHubPath: '/reset-password',
    })
      .then(() => {
        setSuccessMessage(
          'Se ha enviado un correo para restablecer la contraseña',
        );
        setError(null);
      })
      .catch((error) => {
        setError('No se encontró ningún usuario con ese correo electrónico.');
        setSuccessMessage(null);
      });
  }, [email]);

  useOnKeyDown("Enter", handleSubmit)

  return (
    <PublicLayout onPrimaryClick={handleSubmit} primaryLabel={"Enviar"}>
      <FlexContainer centered fullWidth vertical>
        <Spacer vertical size={5} />
        <CustomTitle variant="h2">
          Calma, te ayudamos a reestablecer tu contraseña
        </CustomTitle>
        <Spacer vertical size={10} />
        <FormContent>
          <field-content>
            <FormControl variant="filled" fullWidth>
              <LabelField>¿Cuál es tu correo electrónico?</LabelField>
              <TextField
                placeholder="correo@tuempresa.com"
                id="login-email"
                variant="outlined"
                value={email}
                onChange={handleChangeEmail}
              />
            </FormControl>
          </field-content>

          {(error || successMessage) && (
            <>
              <Spacer vertical size={2} />
              <FlexContainer fullWidth centered vertical>
                <StyledAlert severity={successMessage ? "success" : "error"}>
                  {successMessage ? successMessage : error}
                </StyledAlert>
              </FlexContainer>
            </>
          )}
          <Spacer vertical size={4} />
          <Typography>
            Ya tengo cuenta <Link to="/login">Iniciar sesión</Link>
          </Typography>
        </FormContent>
      </FlexContainer>
    </PublicLayout>
  );
};
