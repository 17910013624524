export const quotationTool = {
  down_payment_label: "¿Cuál podría ser tu pago inicial?",
  down_payment_right_value: "${{quotation.down_payment | currency}}",
  monthly_payment_label: "Tus {{quotation.number_of_months}} mensualidades serían de",
  monthly_payment_right_value: "${{quotation.monthly_payment | currency}}",
  deed_cost_label: "Costos de escrituración totales",
  deed_cost_value: "${{quotation.total_deed_payment | currency}} {{meta.currency}}",
  deed_cost_disclaimer: "El precio puede variar sin previo aviso",
  deed_cost_categories_label: {
    NOTARY_FEES: "Honorarios Notario",
    ISAI: "ISAI",
    PUBLIC_RECORD: "Registro público",
    APPRAISALS: "Avalúos",
  },
  external_loan_entities_label: {
    BANK_CREDIT: "crédito bancario",
    SOFOMES: "Sofomes",
    INFONAVIT: "Infonavit",
    COFINAVIT: "Cofinavit",
    FOVISSTE: "Fovisste",
  },
  interest_rate_label: "Tasa de interés",
  interest_rate_value: "{{quotation.annual_irr}}%",
  cat_label: "CAT",
  cat_value: "{{quotation.total_annual_cost}}%",
  terms_label: "Plazo",
  terms_value: "{{quotation.payments_duration}} meses",
  total_payment_label: "Monto total a pagar",
  total_payment_value: "${{quotation.total_payment | currency}} {{meta.currency}}",
  category_accordion_label: "{{category.label}}",
  category_accordion_value: "${{category.amount | currency}} {{meta.currency}}",
  payment_schemes_disclaimer: "Los valores aquí presentados son una simulación y pueden variar. En las operaciones financiadas, el precio total se calcula en función de los montos variables de conceptos de crédito y gastos notariales estimados. Estos montos pueden cambiar ya que se encuentran fuera del control del desarrollador. Consulta detalles con tu promotor.",
  external_loan_disclaimer: "Aceptamos {{quotation.loan_entities}}. \nSi quieres simular las opciones de crédito y conocer sus costos, puedes ir directamente al simulador hipotecario de la CONDUSEF: https://phpapps.condusef.gob.mx/condusefhipotecario/ \nPara más detalles sobre opciones de financiamiento, contacta nuestro equipo de ventas."
}

export const initialTable = {
  body: [
    {
      label: "Precio de contado de tu propiedad",
      value: "${{quotation.upfront_payment | currency}} {{meta.currency}}",
      show_condition: "quotation.upfront_payment !== 0",
    },
    {
      label: "Valor de tu departamento",
      value: "${{quotation.unit_price | currency}} {{meta.currency}}",
      strikeout_value: true,
      strikeout_condition: "quotation.discount_percent !== 0",
      no_spacing: true,
    },
    {
      label: "{{quotation.discount_percent}}% de descuento",
      value: "${{quotation.final_price | currency}} {{meta.currency}}",
      show_condition: "quotation.discount_percent !== 0",
      no_spacing: true,
    },
    {
      label: "Cifra a cubrir en preventa",
      value: "${{quotation.presell_payment | currency}} {{meta.currency}}",
    },
  ],
}
