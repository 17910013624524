import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, Input, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import React, { Fragment } from "react"
import styled from "styled-components"
import { FlexContainer, Spacer } from "../../components"
import RadioOptions from "./RadioOptions"
import { DEED_COST_CATEGORIES, DEED_COST_TYPE, EXTERNAL_LOAN_ENTITIES } from "./utils"
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const Container = styled(FlexContainer)`
  column-gap: 84px;
  padding: 32px;
  flex-direction: column;
  display: ${p => p.open ? 'flex' : 'none'};
`

const Column = styled(FlexContainer)`
  width: 50%;
  flex-direction: column;
`

const InnerContainer = styled(FlexContainer)`
  gap: 84px;
`

const InitialConfig = ({
  open,
  siteName,
  enableContinue,
  initialConfig,
  onChange,
  onDeedCostPercentBlur,
  onDeedCostCategoriesChange,
  onCustomDeedCostCategoryAdd,
  onCustomDeedCostCategoryChange,
  onCustomDeedCostCategoryDelete,
  onExternalLoanItemsChange,
  onContinue,
}) => (
  <Container open={open}>
    <Column>
      <Typography variant='h5'>Cotizador del proyecto {siteName}</Typography>
      <Spacer vertical size={1} />
      <Typography variant='caption'>En cumplimiento de la NOM247, que indica transparencia en disponibilidad, precios, planes de pago, opciones de financiamiento y costos en los planes a meses, es necesario proporcionar la siguiente información lo más detallada posible:</Typography>
      <Spacer vertical size={3} />
    </Column>
    <InnerContainer>
      <Column>
        <FormControl variant="standard">
          <InputLabel id="deed-costs-label">¿Cuáles son los montos de escrituración?</InputLabel>
          <Select
            labelId="deed-costs-label"
            id="deed-costs"
            value={initialConfig.deedCostsType}
            onChange={(e) => onChange(e.target.value, "deedCostsType")}
            label="¿Cuáles son los montos de escrituración?"
          >
            <MenuItem value={DEED_COST_TYPE.percentage}>Por porcentaje</MenuItem>
            <MenuItem value={DEED_COST_TYPE.category}>Por categoría</MenuItem>
          </Select>
        </FormControl>
      </Column>
      {initialConfig.deedCostsType === DEED_COST_TYPE.percentage && (
        <Column>
          <TextField
            value={initialConfig.deedCostPercent}
            onChange={(e) => onChange(e.target.value, "deedCostPercent")}
            onBlur={(e) => onDeedCostPercentBlur(e.target.value)}
            label="Porcentaje"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: { min: 0, max: 100 }
            }}
            variant="standard"
            type="number"
          />
        </Column>
      )}
    </InnerContainer>
    {initialConfig.deedCostsType === DEED_COST_TYPE.category && (
      <FlexContainer vertical>
        <Spacer vertical size={3} />
        <Typography color="text.secondary">Selecciona la categoría y coloca el monto</Typography>
        <Spacer vertical size={1} />
        {DEED_COST_CATEGORIES.map((c) => (
          <InnerContainer key={c.id} verticalCentered>
            <Column>
              <FormControlLabel
                label={c.label}
                control={
                  <Checkbox
                    checked={initialConfig.deedCostCategories?.[c.id]?.enable}
                    onChange={(e) => onDeedCostCategoriesChange(e.target.checked, c.id, "enable")}
                  />
                }
              />
              <Spacer size={1} vertical />
            </Column>
            <Column>
              {initialConfig.deedCostCategories?.[c.id]?.enable && (
                <FlexContainer vertical>
                  <TextField
                    value={initialConfig.deedCostCategories?.[c.id]?.amount}
                    onChange={(e) => onDeedCostCategoriesChange(e.target.value, c.id, "amount")}
                    type="number"
                    label="Monto"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">MXN</InputAdornment>,
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    variant="standard"
                  />
                  <Spacer size={1} vertical />
                </FlexContainer>
              )}
            </Column>
          </InnerContainer>
        ))}
        <InnerContainer>
          <Column>
            <FormControlLabel
              label="Otros"
              control={
                <Checkbox
                  checked={initialConfig.deedCostCategories?.OTHERS?.enable}
                  onChange={(e) => onDeedCostCategoriesChange(e.target.checked, "OTHERS", "enable")}
                />
              }
            />
          </Column>
          <Column>
            {initialConfig.deedCostCategories?.OTHERS?.enable && (
              <FlexContainer vertical>
                <FlexContainer justified verticalCentered>
                  <Typography variant="caption">Lista</Typography>
                  <Button color="primary" variant="text" size="small" onClick={onCustomDeedCostCategoryAdd} startIcon={<AddIcon />}>Añadir</Button>
                </FlexContainer>
                <Spacer size={2} vertical />
                {initialConfig.deedCostCategories?.OTHERS?.categories?.map((c, i) => (
                  <Fragment key={i.id}>
                    <FormControl variant="standard">
                      <InputLabel htmlFor="custom-category">Categoría</InputLabel>
                      <Input
                        id="custom-category"
                        value={c.category}
                        onChange={(e) => onCustomDeedCostCategoryChange(e.target.value, i, "category")}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton size="small" onClick={() => onCustomDeedCostCategoryDelete(i)}>
                              <DeleteIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <Spacer size={2} vertical />
                    <TextField
                      value={c.amount}
                      onChange={(e) => onCustomDeedCostCategoryChange(e.target.value, i, "amount")}
                      label="Monto"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">MXN</InputAdornment>,
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      variant="standard"
                      type="number"
                    />
                    <Spacer size={2} vertical />
                  </Fragment>
                ))}
              </FlexContainer>
            )}
          </Column>
        </InnerContainer>
      </FlexContainer>
    )}
    <Spacer vertical size={2} />
    <RadioOptions
      label="¿Ofrecen diferentes esquemas de pago a meses?"
      name="payment-schemes"
      value={initialConfig.hasPaymentSchemes}
      onChange={(value) => onChange(value, "hasPaymentSchemes")}
    />
    <Spacer vertical size={2} />
    <RadioOptions
      label="¿Trabajan/aceptan créditos hipotecarios externos?"
      name="external-loan"
      value={initialConfig.hasExternalLoan}
      onChange={(value) => onChange(value, "hasExternalLoan")}
    />
    {initialConfig.hasExternalLoan && (
      <Column>
        <Spacer vertical size={3} />
        <Typography color="text.secondary">¿Con qué entidades trabajan?</Typography>
        <Spacer vertical size={1} />
        <FormGroup>
          {EXTERNAL_LOAN_ENTITIES.map((le) => (
            <FormControlLabel
              key={le.id}
              label={le.label}
              control={
                <Checkbox
                  checked={initialConfig.externalLoanEntities?.[le.id]}
                  onChange={(e) => onExternalLoanItemsChange(e.target.checked, le.id)}
                />
              }
            />
          ))}
        </FormGroup>
      </Column>
    )}
    <Spacer vertical size={3} />
    <Column>
      {initialConfig.hasPaymentSchemes === false && initialConfig.hasExternalLoan === false && (
        <>
          <Typography color="error.light">
            Favor de revisar las opciones anteriores, no mostrar los costos de planes financiados y las opciones de crédito generará un incumplimiento de la NOM247 y el riesgo de inspecciones y multas.
          </Typography>
          <Spacer vertical size={3} />
        </>
      )}
      <FlexContainer centered>
        <Button variant="contained" color="primary" disabled={!enableContinue} onClick={onContinue}>Continuar</Button>
      </FlexContainer>
    </Column>
  </Container>
)

export default InitialConfig
