import { Button } from '@mui/material';
import React from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom'

const ProjectsManagement = ({ pathData, setPathData }) => {
  const navigate = useNavigate()

  const handleClick = useCallback(() => navigate("/project/work-progress"), [navigate])

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleClick}>
        EDITAR AVANCE DE OBRA
      </Button>
    </>
  );
};

export default ProjectsManagement;
