import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, styled, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { Spacer } from "../../components";
import { THEME_TYPES } from "../../helpers/themes";
import useOnboardingContext from "../../hooks/useOnboardingContext";
import PublicLayout from "../../layouts/PublicLayout";
import { SelectableCard } from "../../modules";

const Container = styled("div")`
  padding: 30px 142px;
`;

const ThemeSelection = () => {
  const { onNextStep, onPreviousStep, setOnboardingData, onboardingData } =
    useOnboardingContext();

  const [selectedTheme, setSelectedTheme] = useState(onboardingData.theme);

  const handleNext = useCallback(() => {
    onNextStep();
    setOnboardingData((prev) => ({
      ...prev,
      theme: selectedTheme,
    }));
  }, [onNextStep, setOnboardingData, selectedTheme]);

  return (
    <PublicLayout
      primaryLabel="Pasemos a la dirección"
      primaryIcon={<ArrowForwardIcon />}
      onPrimaryClick={handleNext}
      secondaryLabel="Atrás"
      onSecondaryClick={onPreviousStep}
      showHelpMessage
      currentStep={3}
      numberOfSteps={5}
      disableNextStep={!selectedTheme}
    >
      <Container>
        <Box sx={{ mb: 8 }}>
          <Typography variant="h2">
            ¿Qué diseño te gusta para tu proyecto?
          </Typography>
          <Spacer vertical size={5} />
          <Typography variant="h6">
            Usaremos los colores y tipografía que elijas.
          </Typography>
          <Typography variant="h6">
            (Si no te encantan, podrás cambiarlos fácilmente.)
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
        >
          {THEME_TYPES.map(({ value: theme, imageSrc, label }) => (
            <SelectableCard
              key={theme}
              imageSrc={imageSrc}
              label={label}
              value={theme}
              isSelected={selectedTheme === theme}
              onSelect={setSelectedTheme}
              isACardSelected={!!selectedTheme}
            />
          ))}
        </Box>
      </Container>
    </PublicLayout>
  );
};

export default ThemeSelection;
