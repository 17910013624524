import React from 'react'
import PropTypes from 'prop-types'
import { FlexContainer } from '../../components'
import styled from 'styled-components'

const Container = styled(FlexContainer)`
  min-height: calc(100vh - 110px);
`

const Analytics = ({ src, title }) => {
  return (
    <Container fullHeight>
      <iframe width="100%" src={src} frameBorder="0" style={{ border: 0 }} title={title}></iframe>
    </Container>
  )
}

Analytics.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default Analytics
