import { FormControl, FormHelperText, IconButton, TextField } from "@mui/material"
import React, { useCallback } from "react"
import FlexContainer from "../FlexContainer/FlexContainer"
import DeleteIcon from '@mui/icons-material/Delete'
import styled from "styled-components"

const DeleteButton = styled(IconButton)`
  padding: 2px;
`

const Container = styled(FormControl)`
  width: 100%;
`

const MaxCharsInput = ({ label, value, onChange, onBlur, maxChars, onDelete, multiline, rows }) => {
  const handleChange = useCallback((e) => {
    if (e.target.value.length <= maxChars)
      onChange(e.target.value)
  }, [maxChars, onChange])

  return (
    <Container variant="standard">
      <TextField
        variant="standard"
        label={label}
        value={value}
        multiline={multiline}
        rows={rows}
        onChange={handleChange}
        onBlur={(e) => onBlur?.(e.target.value)}
      />
      {maxChars && (
        <FlexContainer justified verticalCentered>
          <FormHelperText variant="standard">{`${value?.length}/${maxChars}`}</FormHelperText>
          {onDelete && (
            <DeleteButton size="small" onClick={onDelete}>
              <DeleteIcon />
            </DeleteButton>
          )}
        </FlexContainer>
      )}
    </Container>
  )
}

export default MaxCharsInput
