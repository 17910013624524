import React, { useEffect } from "react"
import { DevelopmentsTabs } from "../../helpers/tabsData"
import PageLayout from "../../layouts/PageLayout"
import { useLocation } from "react-router-dom"
import DevelopmentDetails from "./DevelopmentDetails"
import { useNavigate } from "react-router-dom"

const DevelopmentView = () => {
  const { state } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (state == null) {
      navigate('/projects')
    }
  }, [navigate, state])

  if (state == null) return null

  return (
    <PageLayout links={DevelopmentsTabs}>
      <DevelopmentDetails site={state} />
    </PageLayout>
  )
}

export default DevelopmentView
