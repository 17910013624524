import UploadIcon from '@mui/icons-material/Upload';
import { Card, CardActionArea, CardContent, CardMedia, InputBase, InputLabel, LinearProgress, styled, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { trackEvent } from '../../helpers/mixpanel';
import { post } from '../../helpers/request';
import { ASSETS_URL, getExtType, toBase64, validImageFileTypes } from '../../helpers/utils';

const StyledCard = styled(Card)`
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
`

const StyledCardMedia = styled(CardMedia)`
  background: rgba(0, 0, 0, 0.04);
  border: 1px dashed rgba(0, 0, 0, 0.26);
  border-radius: 4px;
  height: ${({height}) => height ? `${height}px` : "178px"};
`;

const StyledInputLabel = styled(InputLabel)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: initial;

  svg {
    width: 3rem;
    height: 3rem;
    margin-block-end: .75rem;
  }
`

const StyledCardContent = styled(CardContent)`
  padding-inline: 0;
`;

const StyledInputFile = styled(InputBase)`
  display: none;
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
`;

const EmptyAssetCard = ({ uploadLabel, maxImageSizeLabel, maxImageSizeErrorLabel, uploadingLabel, assetLabel, height, sx, assetType, setAssets, setAssetId, handleChange, tags = [] }) => {
  const [ progress, setProgress ] = useState(null)
  const [maxImageSizeError, setMaxImageSizeError] = useState(false)

  const handleUploadProgress = useCallback((event, asset_id, type) => {
    const attribute = `${type}Percent`
    const percent = Math.floor((event.loaded / event.total) * 100)

    setProgress(percent)

    setAssets((currentAssets) => currentAssets.map(a => {
      if (a.asset_id === asset_id) {
        const otherPercent = type === 'signed' ? a.clientSignedPercent : a.signedPercent
        const totalPercent = Math.round((otherPercent + percent) / 2)
        return {
          ...a,
          [attribute]: percent,
          percent: totalPercent,
        }
      }
      return a
    }))
  }, [setAssets])

  const filePutSignedUrl = useCallback(async (url, file, asset_id, type) => {
    return new Promise((resolve) => {
      const request = new XMLHttpRequest();
      request.open('PUT', url);
      request.setRequestHeader('Content-Type', file.type);

      request.upload.addEventListener('progress', (e) => handleUploadProgress(e, asset_id, type));
      request.addEventListener('load', resolve);

      request.send(file);
    });
  }, [handleUploadProgress]);

  const uploadMedia = useCallback(
    async(e) => {
      setMaxImageSizeError(false)
      const file = e.target.files[0]
      const currentType = getExtType(file.name)

      if (file != null) {
        // Maximum 2MB
        const maxAllowedSize = 2 * 1024 * 1024;
        if (file.size > maxAllowedSize && currentType === 'image') {
          setMaxImageSizeError(true)
          return
        }

        const base64Url = await toBase64(file)

        if(file.type === "image/svg+xml") return

        const { asset_id, signed_url } = await post(ASSETS_URL, {
          mime_type: file.type,
          tags: [...tags],
          type: currentType,
          metadata: { fileName: file.name }
        })

        await filePutSignedUrl(signed_url, file, asset_id, 'signed')
        
        setAssets((currentAssets) => [asset_id, ...currentAssets])
        
        if(signed_url) setAssetId(asset_id)

        handleChange({
          asset_id,
          type: currentType,
          tags: [...tags],
          url: base64Url ?? asset_id,
          signedPercent: 0,
          clientSignedPercent: 0,
          percent: 0,
          metadata: { fileName: file.name }
        })

        trackEvent('upload-media')
      }
    },
    [filePutSignedUrl, handleChange, setAssetId, setAssets, tags]
  )

  const fileInputProps = useMemo(() => {
    return { accept: (assetType && assetType === "video") ? "video/*" : `${validImageFileTypes.join(',')}` }
  }, [assetType])

  return (
    <StyledCard sx={sx}>
      <CardActionArea>
        <StyledCardMedia component="div" height={height}>
          <StyledInputLabel>
            <StyledInputFile
              type="file"
              onChange={uploadMedia}
              inputProps={fileInputProps}
            />
            <UploadIcon color="action" />
            {uploadLabel && <Typography color="rgba(0, 0, 0, 0.87)" mb=".25rem">{uploadLabel}</Typography>}
            {maxImageSizeLabel && <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">{maxImageSizeLabel}</Typography>}
          </StyledInputLabel>
        </StyledCardMedia>
      </CardActionArea>
        <StyledCardContent>
          {assetLabel && progress === null && !maxImageSizeError && (
            <Typography component="p" variant="body">
              {assetLabel}
            </Typography>
          )}
          {progress !== null && !maxImageSizeError &&
            <>
              <Typography component="p" variant="body1">
                {`${uploadingLabel} ${progress}%`}
              </Typography>
              <LinearProgress variant="determinate" value={progress} />
            </>}
          {maxImageSizeError && (
            <Typography component="p" color="error">
              {maxImageSizeErrorLabel}
            </Typography>
          )}
        </StyledCardContent>
    </StyledCard>
  );
};

export default EmptyAssetCard;
