export const sections_data = {
    "image-banner-section": {
        type: 'image-banner-section',
        id: 'default-image-banner-section',
        hubid: '[image-banner-section]',
        image: {
            url: 'https://assets.alohome.io/images/8c414c30-fe6e-11ec-b9af-f1366b5b7af8',
        },
        title: 'Solo este mes: ¡escrituración sin costo!',
        content:
            'Queremos que estrenes depa. Platica con nuestro equipo y personaliza tu plan de pagos. La escrituación corre por nuestra cuenta.',
        call_to_action: {
            action: 'goToSection',
            label: 'Quiero mi escrituración gratis',
            href: `/#default-split-contact-section`,
        },
    },
    "split-contact-section": {
        type: 'split-contact-section',
        id: 'default-split-contact-section',
        hubid: '[split-contact-section][contact-methods]',
        contact_title: '¡Nos encatará platicar contigo!',
        contact_methods: {
            phone: {
                content: '52 123 456 7890',
            },
            whatsapp: {
                content: '52 123 456 7891',
                message_template: 'Hola, tengo una pregunta sobre {{legal.projectDomain}}',
            },
            email: {
                content: 'contacto@midominio.com',
            },
            address: {
                content: 'Calle y número',
                coordinates: {
                    latitude: 6.177835924602142,
                    longitude: -75.60720258548689,
                },
            },
        },
        contact_form: {
            contact: {
                title: '¿Necesitas más información para tomar una decisión?',
                form: {
                    fields: [
                        {
                            type: 'form-question',
                            label: '¿Cuál es tu nombre?',
                            component: 'Input',
                            control: 'text',
                            id: 'clientName',
                            required: true,
                        },
                        {
                            type: 'form-question',
                            label: '¿Cuáles son tus apellidos?',
                            component: 'Input',
                            control: 'text',
                            id: 'clientLastNames',
                            required: true,
                        },
                        {
                            type: 'form-question',
                            label: '¿Cuál es tu número de celular?',
                            component: 'TelephoneField',
                            id: 'clientPhone',
                            required: true,
                            country: 'mx',
                            preferredCountries: ['mx', 'us'],
                            countryCodeEditable: false,
                            regions: ['america', 'europe'],
                        },
                        {
                            type: 'form-question',
                            label: '¿Cuál es tu correo electrónico?',
                            component: 'Input',
                            control: 'email',
                            id: 'clientEmail',
                            required: true,
                        },
                        {
                            type: 'form-question',
                            label: '¿Prefieres que te llamen o que te escriban por WhatsApp?',
                            component: 'RadioGroup',
                            control: 'Radio',
                            id: 'contactMethod',
                            required: true,
                            options: [
                                {
                                    value: 'Llamada',
                                    name: 'contactMethod',
                                    label: 'Llamada',
                                    variant: 'inline',
                                },
                                {
                                    value: 'WhatsApp',
                                    name: 'contactMethod',
                                    label: 'WhatsApp',
                                    variant: 'inline',
                                },
                            ],
                        },
                        {
                            type: 'call-to-action',
                            label: 'Enviar',
                            component: 'ButtonCTA',
                            control: 'button',
                            turnIntoLoader: true,
                            id: 'sendButton',
                        },
                        {
                            type: 'form-question',
                            label:
                                'Acepto los [términos y condiciones]!(action,open-terms-and-conditions) y la [política de privacidad]!(action,open-privacy-policy).',
                            component: 'Checkbox',
                            control: 'checkbox',
                            variant: 'flex',
                            id: 'clientAgreesToTerms',
                            required: true,
                        },
                    ],
                },
            },
            success: {
                avatar: {
                    image_source: '/images/e4b2e0c0-ff1f-11ec-b9af-f1366b5b7af8',
                },
                title: '¡Recibimos tus datos!',
                content:
                    'Nuestro equipo está preparando toda la información para ayudarte a tomar la mejor decisión: ¡tu futuro!',
                call_to_action: {
                    label: 'Ver tipologías',
                    href: '/#default-carousel-typologies',
                },
            },
            error: {
                avatar: {
                    image_source: '/images/e4b2e0c0-ff1f-11ec-b9af-f1366b5b7af8',
                },
                title: 'Hubor un error.',
                content: 'Lamentablemente hubo un error al enviar los datos.',
            },
        },
    },
    "masonry-gallery-section": {
        type: 'masonry-gallery-section',
        id: 'default-masonry-gallery-section',
        hubid: '[masonry-gallery-section]',
        title: 'Galería del proyecto',
        images: [
            {
                url: 'https://assets.alohome.io/images/f65cc270-fe91-11ec-b9af-f1366b5b7af8',
                caption: 'Seguridad',
                id: '1',
            },
            {
                url: 'https://assets.alohome.io/images/287131b0-fe92-11ec-b9af-f1366b5b7af8',
                caption: 'Áreas verdes',
                id: '2',
            },
            {
                url: 'https://assets.alohome.io/images/68fca1b0-fe92-11ec-b9af-f1366b5b7af8',
                caption: 'Zona de asadores',
                id: '3',
            },
            {
                url: 'https://assets.alohome.io/images/8a77a560-fe92-11ec-b9af-f1366b5b7af8',
                caption: 'Piscina',
                id: '4',
            },
            {
                url: 'https://assets.alohome.io/images/a9d5e390-fe92-11ec-b9af-f1366b5b7af8',
                caption: 'Exterior',
                id: '5',
            },
            {
                url: 'https://assets.alohome.io/images/d855ba60-fe92-11ec-b9af-f1366b5b7af8',
                caption: 'Salon de juegos',
                id: '6',
            },
            {
                url: 'https://assets.alohome.io/images/f0714c40-fe92-11ec-b9af-f1366b5b7af8',
                caption: 'Coworking',
                id: '7',
            },
            {
                url: 'https://assets.alohome.io/images/287131b0-fe92-11ec-b9af-f1366b5b7af8',
                caption: 'Áreas verdes 2',
                id: '8',
            },
        ],
    },
    "carousel-typologies": {
        type: 'carousel-typologies',
        id: 'default-carousel-typologies',
        hubid: '[carousel-typologies]',
        title: 'Apartamentos de 2 y 3 alcobas, desde 57.70 m²',
        typology_data: {
            card_subtitle: 'Desde',
            card_bottom_text: 'Conoce y cotiza',
            data_sheep_title: 'Detalles',
            data_sheep_subtitle_1: 'Precio:',
            data_sheep_subtitle_2: 'Plusvalía anual',
        },
    },
    "amenities-section": {
        type: 'amenities-section',
        id: 'default-amenities-section',
        hubid: '[amenities-section]',
        title: 'Amenidades',
        items: [
            {
                icon: '[:pool:]',
                label: 'Piscina',
                id: '1',
            },
            {
                icon: '[:nature:]',
                label: 'Áreas verdes',
                id: '2',
            },
            {
                icon: '[:ping-pong:]',
                label: 'Salón de juegos',
                id: '3',
            },
            {
                icon: '[:grill_3:]',
                label: 'Zona de asadores',
                id: '4',
            },
            {
                icon: '[:security_camera:]',
                label: 'Seguridad',
                id: '5',
            },
            {
                icon: '[:monitor:]',
                label: 'Coworking',
                id: '6',
            },
            {
                icon: '[:fitness:]',
                label: 'Gimnasio',
                id: '7',
            },
            {
                icon: '[:bike:]',
                label: 'Ciclopista',
                id: '8',
            },
            {
                icon: '[:playground:]',
                label: 'Ludoteca',
                id: '9',
            },
            {
                icon: '[:pet_2:]',
                label: 'Zona pet',
                id: '10',
            },
            {
                icon: '[:film:]',
                label: 'Sala de cine',
                id: '11',
            },
        ],
    },
    "small-map-section": {
        type: 'small-map-section',
        id: 'default-small-map-section',
        hubid: '[small-map-section]',
        title: 'Ubicación',
        location: {
            coordinates: {
                latitude: 21.019985,
                longitude: -89.571812,
            },
            address: 'Berlín 16, col. Juárez, Ciudad de México',
        },
        isBlackAndWhite: true,
        card: {
            hubid: '[project-logo]',
            logo: {
                url: 'https://assets.alohome.io/assets/75694160-fe6f-11ec-b9af-f1366b5b7af8',
            },
            title: 'Ubicación',
        },
        bullets: [
            {
                id: '1',
                text: 'Parque Central (5 min.)',
            },
            {
                id: '2',
                text: 'Universidad La Salle (10 min.)',
            },
            {
                id: '3',
                text: 'Plaza Antara (5 min.)',
            },
            {
                id: '4',
                text: 'Hospital Ángeles (10 min.)',
            },
            {
                id: '5',
                text: 'Hospital San Angel Inn (15 min.)',
            },
            {
                id: '6',
                text: 'Universidad (20 min.)',
            },
        ],
    },
    "flex-media-section": {
        type: 'flex-media-section',
        id: 'default-flex-media-section',
        hubid: '[flex-media-section]',
        title: 'Construye la vida que siempre imaginaste',
        showBullets: true,
        video_source: {
            url: 'https://assets.alohome.io/assets/b718bc00-fe90-11ec-b9af-f1366b5b7af8',
        },
        items: [
            {
                label: 'Departamentos con terraza',
                id: 1,
            },
            {
                label: 'Hasta 75 m²',
                id: 2,
            },
            {
                label: '15 niveles',
                id: 3,
            },
            {
                label: 'Desde $3,000,000 MXN',
                id: 4,
            },
            {
                label: '10% plusvalía anual',
                id: 5,
            },
            {
                label: 'Más de 20 increíbles amenidades',
                id: 6,
            },
        ],
    },
    "carousel-projects": {
        type: 'carousel-projects',
        id: 'default-carousel-projects',
        hubid: '[carousel-projects]',
        title: 'Proyectos',
        project: {
            logo: {
                url: 'https://assets.alohome.io/assets/8a8e35c0-0223-11ed-b9af-f1366b5b7af8',
                alt: '',
            },
            description:
                'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
        },
        projects: {
            'Real Navarra': {
                location: 'Polanco, CDMX',
                href: '',
                gallery: [
                    {
                        url: 'https://assets.alohome.io/images/49eb8f50-0196-11ed-b9af-f1366b5b7af8',
                        alt: '',
                    },
                ],
            },
            'Real Potosí': {
                location: 'Roma Norte, CDMX',
                href: '',
                gallery: [
                    {
                        url: 'https://assets.alohome.io/images/82b47f30-0197-11ed-b9af-f1366b5b7af8',
                        alt: '',
                    },
                ],
            },
            'Real Valladolid': {
                location: 'Colonia Juárez, CDMX',
                href: '',
                gallery: [
                    {
                        url: 'https://assets.alohome.io/images/a634c8c0-0197-11ed-b9af-f1366b5b7af8',
                        alt: '',
                    },
                ],
            },
            'Real Madrid': {
                location: 'Colonia Anáhuac',
                href: '',
                gallery: [
                    {
                        url: 'https://assets.alohome.io/images/b68eba50-0197-11ed-b9af-f1366b5b7af8',
                        alt: '',
                    },
                ],
            },
        },
    },
    "transparency-construction-progress-section": {
        type: "transparency-construction-progress-section",
        id: "default-transparency-construction-progress-section",
        hubid: "[transparency-construction-progress-section]",
        title: "[:building_2:] Avance de obra",
        content: "Conforme avanza la construcción del proyecto, aquí encontrarás la actualización de cada una etapas.\nTambién tendrás un histórico, en imágenes, de los progresos de la obra.",
        className: "has-gallery full-height icon-bullets section_with_neutral_background",
        gallery_title: "Avance del proyecto",
    },
    "lots-view-section": {
        type: 'lots-view-section',
        id: 'default-lots-view-section',
        hubid: '[lots-view-section]',
        image: {
            url: 'https://assets.alohome.io/images/35d40f00-12f9-11ed-b9af-f1366b5b7af8',
        },
        title: '85 lotes desde 75 m²',
    },
}