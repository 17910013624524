import { Typography } from '@mui/material'
import { FlexContainer, Select, Spacer } from '../../components'
import React, { useState } from 'react'
import styled from 'styled-components'
import BulkEditContainer from './BulkEditContainer'
import BulkEditStatusTable from './BulkEditStatusTable'
import SoldDateModal from './SoldDateModal'

const StyledTypography = styled(Typography)`
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
`

const STATUS = [{
  label: 'Vendido',
  value: 'sold',
}, {
  label: 'Disponible',
  value: 'available',
}, {
  label: 'Reservado',
  value: 'reserved',
}, {
  label: 'No publicado',
  value: 'offline',
}]

const BulkEditStatus = ({ inventoryBulk, onUpdate }) => {
  const [newInventory, setNewInventory] = useState(inventoryBulk)
  const [status, setStatus] = useState('')
  const [statusToChange, setStatusToChange] = useState('')
  const [isSoldModalOpen, setIsSoldModalOpen] = useState(false)

  const handleUpdateClick = () => onUpdate(newInventory)

  const handleCancelSoldModal = () => {
    setIsSoldModalOpen(false)
    setStatusToChange('')
  }

  const handleSaveSoldModal = (newReservedDate, newSoldDate) => {
    const soldDate = new Date(newSoldDate.replaceAll('-', '/'))
    const reservedDate = new Date(newReservedDate.replaceAll('-', '/'))

    const updatedInventory = newInventory.map(i => ({
      ...i,
      new_status: statusToChange,
      reserved_date: reservedDate,
      sold_date: statusToChange === 'sold' ? soldDate : undefined,
    }))

    setNewInventory(updatedInventory)
    setStatus(statusToChange)
    setStatusToChange('')
    setIsSoldModalOpen(false)
  }

  const handleStatusChange = (newStatus) => {
    if (newStatus === 'available' || newStatus === "offline") {
      const updatedInventory = newInventory.map(i => ({
        ...i,
        new_status: newStatus,
        sold_date: undefined,
        reserved_date: undefined,
      }))
      setNewInventory(updatedInventory)
      setStatus(newStatus)
    } else {
      setStatusToChange(newStatus)
      setIsSoldModalOpen(true)
    }
  }

  return (
    <>
      <SoldDateModal
        open={isSoldModalOpen}
        onCancel={handleCancelSoldModal}
        onSave={handleSaveSoldModal}
        showReserved={true}
        showSold={statusToChange === 'sold'}
      />
      <BulkEditContainer
        title="Edición masiva del estado"
        onUpdate={handleUpdateClick}
        showUpdateButton={status !== ''}
      >
        <FlexContainer>
          <StyledTypography>Cambiar el estado a</StyledTypography>
          <Spacer size={2} />
          <Select options={STATUS} value={status} onChange={handleStatusChange} placeholder="Estado" />
        </FlexContainer>
        {status && (
          <>
            <Spacer vertical size={4} />
            <BulkEditStatusTable newInventory={newInventory} />
          </>
        )}
      </BulkEditContainer>
    </>
  )
}

export default BulkEditStatus
