import React from 'react'
import PropTypes from 'prop-types'
import { FlexContainer } from '../'
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material'
import styled from 'styled-components'
import { Typography, Tooltip } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'

const StyledArrowUp = styled(ArrowDropUp)`
  color: ${props => props.color};
`
const StyledArrowDown = styled(ArrowDropDown)`
  color: ${props => props.color};
`

const StyledTypography = styled(Typography)`
 color: ${props => props.color};
`

const PercentageIndicator = ({ percentage, tooltip }) => {
  const theme = useTheme()
  const isPositive = percentage >= 0
  return (
    <FlexContainer>
      <Tooltip title={tooltip} placement="right" arrow>
        {
          isPositive
            ? (
              <FlexContainer verticalCentered>
                <StyledArrowUp fontSize="large" color={theme.palette.success.main} />
                <StyledTypography variant="h6" color={theme.palette.success.main}>{percentage}%</StyledTypography>
              </FlexContainer>
            )
            : (
              <FlexContainer verticalCentered>
                <StyledArrowDown fontSize="large" color={theme.palette.error.dark} />
                <StyledTypography variant="h6" color={theme.palette.error.dark}>{percentage}%</StyledTypography>
              </FlexContainer>
            )
        }
      </Tooltip>
    </FlexContainer>
  )
}

PercentageIndicator.propTypes = {
  percentage: PropTypes.number.isRequired,
  tooltip: PropTypes.string
}

export default PercentageIndicator
