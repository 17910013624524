export const PERMISSIONS = {
  view: 'view',
  edit: 'edit',
  hidden: 'hidden',
  limited_view: 'limited_view',
}

const checkObjectPermission = (permissions, permission) => {
  for (const [, value] of Object.entries(permissions)) {
    if (typeof value !== 'string') {
      const isHidden = checkObjectPermission(value, permission)
      if (!isHidden) return false
    } else if (value !== permission) {
      return false
    }
  }
  return true
}

export const getPermission = (permissionToGet, permissions) => {
  const currentPermissionToGet = [...permissionToGet]
  const currentPermission = currentPermissionToGet.shift()
  if (permissions[currentPermission] == null) return PERMISSIONS.hidden
  if (typeof permissions[currentPermission] === 'string') return permissions[currentPermission]

  if (currentPermissionToGet.length === 0) {
    const isHidden = checkObjectPermission(permissions[currentPermission], PERMISSIONS.hidden)
    if (isHidden) return PERMISSIONS.hidden
    return permissions[currentPermission]
  }

  return getPermission(currentPermissionToGet, permissions[currentPermission])
}

export const getAvailableOptions = (items, permissions) => {
  if (permissions) {
    return items.filter(item => {
      if (item.permissionPath) {
        const tabPermission = getPermission(item.permissionPath, permissions)
        return tabPermission !== PERMISSIONS.hidden
      }
      return true
    })
  }
  return []
}
