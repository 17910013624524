import { FlexContainer, Spacer } from '../../components'
import React from 'react'
import styled from 'styled-components'
import { Typography, Button } from '@mui/material'

const Container = styled(FlexContainer)`
  padding: 24px;
`

const Title = styled(Typography)`
  font-weight: 500;
`

const UpdateButton = styled(Button)`
  width: 150px;
  align-self: flex-end;
`

const BulkEditContainer = ({ title, children, onUpdate, showUpdateButton }) => (
  <Container vertical>
    <Title variant="body1">{title}</Title>
    <Spacer vertical size={2} />
    {children}
    {showUpdateButton && (
      <>
        <Spacer vertical size={4} />
        <UpdateButton variant="contained" color="primary" onClick={onUpdate}>Actualizar</UpdateButton>
      </>
    )}
  </Container>
)

export default BulkEditContainer
