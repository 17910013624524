import {
  Email as EmailIcon,
  WhatsApp as WhatsAppIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Collapse,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

const NOTIFICATION_CHANNEL = {
  EMAIL: 'email',
  WHATSAPP: 'whatsapp',
};

const CHANNEL_DATA = {
  [NOTIFICATION_CHANNEL.EMAIL]: {
    placeholder: `john.doe@gmail.com jane-smith@outlook.com`,
    icon: <EmailIcon sx={{ color: 'text.secondary' }} />,
    label: 'Recibir notificaciones por correo electrónico',
    description:
      'Lista los correos electrónicos a los cuales se les enviará una notificación. Presiona enter para agregar una opción.',
    validateFn: (emails) => {
      if (emails.length === 0) return 'Campo requerido.';
      for (const email of emails) {
        // Regex from https://github.com/segmentio/is-email/blob/master/lib/index.js
        if (
          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
            email
          )
        )
          return `${email} no es correo válido`;
      }
      return true;
    },
  },
  [NOTIFICATION_CHANNEL.WHATSAPP]: {
    placeholder: `+52 1 55 1234 5678 +57 310 1234123`,
    icon: <WhatsAppIcon sx={{ color: 'text.secondary' }} />,
    label: 'Recibir notificaciones por Whatsapp',
    description:
      'Lista los números de Whatsapp a los cuales se les enviará una notificación. Presiona enter para agregar una opción.',
    helperText: (
      <Typography variant="caption">
        <strong>NOTA:</strong> El número debe incluir el código del país (p.e.
        +52 para México, +57 para Colombia)
      </Typography>
    ),
    validateFn: (phones) => {
      if (phones.length === 0) return 'Campo requerido.';
      for (const phone of phones) {
        if (!/^\+?[0-9\s]{6,}$/.test(phone))
          return `${phone} no es número de Whatsapp válido.`;
      }
      return true;
    },
  },
};

const NotificationChannelOption = ({
  event,
  channel,
  control,
  error,
  getOptionLabel,
  autocompleteOptions,
}) => {
  const activeKey = `${event}.${channel}.active`;
  const valueKey = `${event}.${channel}.value`;
  const { placeholder, label, description, validateFn, icon, helperText } =
    CHANNEL_DATA[channel];
  return (
    <Controller
      key={channel}
      name={activeKey}
      control={control}
      defaultValue={false}
      render={({ field: { value: active, ...switchProps } }) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 1,
          }}
        >
          <FormControlLabel
            label={
              <Typography
                variant="body1"
                sx={{
                  mr: 'auto',
                  fontWeight: 'normal',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                {icon}
                {label}
              </Typography>
            }
            labelPlacement="start"
            sx={{ ml: 0 }}
            control={<Switch checked={active} {...switchProps} />}
          />
          <Collapse in={active} mountOnEnter unmountOnExit sx={{ width: 1 }}>
            {typeof description === 'string' ? (
              <Typography>{description}</Typography>
            ) : (
              description
            )}
            {active && (
              <Controller
                name={valueKey}
                control={control}
                rules={{
                  required: 'Campo requerido.',
                  validate: validateFn,
                }}
                shouldUnregister
                defaultValue={[]}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Autocomplete
                    multiple
                    freeSolo
                    fullWidth
                    value={value}
                    onBlur={onBlur}
                    onChange={(_, v) => onChange(v)}
                    options={autocompleteOptions}
                    getOptionLabel={getOptionLabel}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={
                          value.length === 0 ? placeholder : undefined
                        }
                        error={!!error}
                        helperText={error || helperText || ' '}
                      />
                    )}
                    sx={{ mt: 1, mb: 2 }}
                  />
                )}
              />
            )}
          </Collapse>
        </Box>
      )}
    />
  );
};

export default NotificationChannelOption;
