import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { IconAvatar, ListItemOption } from '../../components';
import { trackEvent } from '../../helpers/mixpanel';

const LinkContainer = styled.a`
  margin: 0;
  padding: 0;
  background: none;
  border: none;
`;

const SidebarOption = ({ id, icon, label, to, onClick, selected, iconPath }) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    trackEvent(`sidebar-item-${id}`);
    if (to) {
      navigate(to);
    } else if (onClick) {
      onClick();
    }
  }, [navigate, onClick, to, id]);

  return (
    <ListItemOption selected={selected}>
      <LinkContainer onClick={handleClick}>
        <ListItemAvatar>
          {icon && <IconAvatar icon={icon} />}
          {iconPath && <img alt='' src={iconPath} />}
        </ListItemAvatar>
        <ListItemText primary={label} sx={{ color: 'text.primary' }} />
      </LinkContainer>
    </ListItemOption>
  );
};

export default SidebarOption;
