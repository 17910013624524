import React, { useEffect } from 'react'
import { trackEvent } from '../../helpers/mixpanel'
import useBreakpoints from '../../hooks/useBreakpoints'
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt'
import useOnboardingContext from '../../hooks/useOnboardingContext'
import { DiscardChangesDialog } from '../../modules'
import LoadingScreen from './LoadingScreen'
import OnboardingMobile from './OnboardingMobile'
import OnboardingPreview from './OnboardingPreview'
import OnboardingRegistration from './OnboardingRegistration'
import ProjectAddress from './ProjectAddress'
import ProjectAssets from './ProjectAssets'
import ProjectForm from './ProjectForm'
import ProjectTypeSelection from './ProjectTypeSelection'
import ThemeSelection from './ThemeSelection'

const OnboardingSteps = ({ setHideSidebar }) => {
  const { currentStep } = useOnboardingContext()
  const { isSmOrLower } = useBreakpoints()
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(true)

  useEffect(() => {
    if(setHideSidebar) setHideSidebar(true)
  
    return () => {
      if(setHideSidebar) setHideSidebar(false)
    }
  }, [setHideSidebar])

  useEffect(() => {
    trackEvent('onboarding-step', { step: currentStep })
  }, [currentStep])

  if(isSmOrLower) {
    return (<OnboardingMobile />)
  }
  
  return (
    <>
      <DiscardChangesDialog
        open={showPrompt}
        onSave={cancelNavigation}
        onDiscard={confirmNavigation}
        discardText="Salir"
        saveText="Cancelar"
        saveIcon={null}
        text="¡Espera! Si sales puedes perder tus avances, ¿deseas continuar?"
      />
      {currentStep === 1 && <ProjectForm />}
      {currentStep === 2 && <ProjectTypeSelection />}
      {currentStep === 3 && <ThemeSelection />}
      {currentStep === 4 && <ProjectAddress />}
      {currentStep === 5 && <ProjectAssets />}
      {currentStep === 6 && <LoadingScreen />}
      {currentStep === 7 && <OnboardingPreview setHideSidebar={setHideSidebar} onDiscard={confirmNavigation} />}
      {currentStep === 8 && <OnboardingRegistration />}
    </>
  )
}

export default OnboardingSteps
