import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Typography } from "@mui/material";
import React, { Fragment, useCallback } from "react";
import { MaxCharsInput, Spacer } from "../../../components";
import { generateRandomId } from "../../../helpers/utils";
/*
CONFIG.JS DEFINITIONS
[{
    id: "fd23kj54",
    text: "text 1"
}, {
    id: "m43m6252",
    text: "text 2"
}]
*/

const SimpleList = ({
  pathData,
  setPathData,
  maxChars,
  labelKey = "label",
}) => {
  const handleAdd = useCallback(() => {
    const id = generateRandomId();
    setPathData([
      ...pathData,
      {
        id,
        [labelKey]: "",
      },
    ]);
  }, [setPathData, pathData, labelKey]);

  const handleDelete = useCallback(
    (id) => {
      const newValue = pathData?.filter((v) => v.id !== id);
      setPathData(newValue);
    },
    [setPathData, pathData]
  );

  const handleChange = useCallback(
    (inputValue, id) => {
      const newValue = pathData?.map((v) =>
        v.id !== id
          ? v
          : {
              ...v,
              [labelKey]: inputValue,
            }
      );
      setPathData(newValue);
    },
    [setPathData, pathData, labelKey]
  );

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
          bgcolor: 'background.default',
          zIndex: 1,
        }}
      >
        <Typography variant="caption" color="text.secondary">
          Lista
        </Typography>
        <Button
          color="primary"
          size="small"
          startIcon={<AddIcon />}
          onClick={handleAdd}
        >
          Añadir
        </Button>
      </Box>
      {pathData?.map((item, i) => (
        <Fragment key={item.id}>
          <Spacer vertical size={2} />
          <MaxCharsInput
            label={`Texto ${i + 1}`}
            value={item[labelKey]}
            maxChars={maxChars}
            onDelete={
              pathData.length > 1 ? () => handleDelete(item.id) : undefined
            }
            onChange={(v) => handleChange(v, item.id)}
          />
        </Fragment>
      ))}
    </Box>
  );
};

export default SimpleList;
