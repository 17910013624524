import React from 'react';
import styled from 'styled-components';
import IntelimetricaBanner from '../../assets/images/intelimetrica.jpg';
import IntelimetricaCardImage from '../../assets/images/intelimetrica_card_example.png';
import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  FormLabel,
  Grid,
  Link,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import { FlexContainer, Spacer } from '../../components';
import { useState } from 'react';
import useProfile from '../../hooks/useProfile';
import useSite from '../../hooks/useSite';
import { post } from '../../helpers/request';
import { emailRegex } from '../../helpers/regex';
import { MuiTelInput } from 'mui-tel-input';
import { useEffect } from 'react';
import { IntelimetricaLayout } from './Layout';
import { buttonsSx } from './util';

const Banner = styled.img`
  display: block;
  width: 100%;
  object-fit: cover;
  min-height: 300px;
`;

const CustomListItem = styled(ListItem)`
  display: list-item;
`;

const CardImage = styled.img`
  display: block;
  height: 100%;
  object-fit: cover;
`;

const FieldContainer = styled(TextField)`
  width: 292px;
`;

const CustomCheckboxFormLabel = styled(FormLabel)`
  margin-top: auto;
  margin-bottom: auto;
`;

export const AboutIntelimetrica = () => {
  const [reloadLayout, setReloadLayout] = useState(false);
  const [errors, setErrors] = useState({});
  const [formButtonDisabled, setFormButtonDisabled] = useState(false);
  const profile = useProfile();
  const { site } = useSite();
  const [showForm, setShowForm] = useState(false);

  const [form, setForm] = useState({
    name: profile?.name || '',
    lastname: profile?.lastname || '',
    email: profile?.email || '',
    phone_number: profile?.custom_data?.phone || '',
    alohome_terms: false,
    intelimetrica_terms: false,
  });

  const handleForm = (name, value) => {
    setForm((form) => ({ ...form, [name]: value }));
  };

  const validateForm = (data) => {
    if (!data.name) {
      setErrors({ name: 'El nombre es requerido' });
      return false;
    } else if (!data.lastname) {
      setErrors({ lastname: 'El apellido es requerido' });
      return false;
    } else if (!data.email.match(emailRegex)) {
      setErrors({ email: 'El email es incorrecto' });
      return false;
    } else if (!data.alohome_terms) {
      setErrors({ alohome_terms: true });
      return false;
    } else if (!data.intelimetrica_terms) {
      setErrors({ intelimetrica_terms: true });
      return false;
    }
    setErrors({});
    return true;
  };

  const handleSubmitForm = () => {
    post(
      `/internal/alohub/integrations/intelimetrica/config/contact-data`,
      form,
      site.site_id
    ).then(() => {
      setReloadLayout(true);
    });
  };

  useEffect(() => {
    setFormButtonDisabled(!validateForm(form));
  }, [form]);

  return (
    <IntelimetricaLayout
      reloadLayout={reloadLayout}
      setReloadLayout={setReloadLayout}
      site={site}
    >
      <Banner src={IntelimetricaBanner} />
      {!showForm ? (
        <>
          <Spacer size={2} vertical />
          <Typography>
            Intelimétrica es una firma de asesoría estratégica de negocios que
            empodera a empresas del sector hipotecario a acelerar sus procesos
            de transformación digital, con base en la explotación inteligente de
            datos y la aplicación de analítica avanzada
          </Typography>
          <Spacer size={2} vertical />
          <Link href={'intelimetrica.com'} target="_blank">
            intelimetrica.com
          </Link>
          <Spacer size={2} vertical />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    OBTÉN —SIN COSTO— UN REPORTE PERSONALIZADO
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Compara los precios de las unidades de tu proyecto vs. los
                    precios estimados de propiedades similares en la zona
                    (totales y por m2).
                  </Typography>
                  <List
                    sx={{
                      listStyleType: 'disc',
                      listStylePosition: 'inside',
                    }}
                  >
                    <CustomListItem>
                      Sabrás si tus precios están por encima, en promedio o por
                      debajo de la oferta inmobiliaria actual
                    </CustomListItem>
                    <CustomListItem>
                      Si es necesario, podrás reajustar tus precios para
                      hacerlos más competitivos
                    </CustomListItem>
                    <CustomListItem>
                      Podrás analizar si algunas unidades o tipologías están
                      bien valuadas
                    </CustomListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <CardImage src={IntelimetricaCardImage} />
              <Typography>Ejemplo de reporte</Typography>
            </Grid>
          </Grid>
          <Spacer size={4} vertical />
          <Button
            onClick={() => setShowForm(true)}
            sx={{
              ...buttonsSx,
              margin: '0 auto',
            }}
            variant="contained"
            color="primary"
          >
            COMENZAR
          </Button>
          <Spacer size={4} vertical />
        </>
      ) : (
        <Box
          sx={{ alignItems: 'center', width: 'fit-content', margin: '0 auto' }}
          vertical
        >
          <Spacer vertical size={4} />
          <FlexContainer>
            <FieldContainer
              placeholder="Nombre"
              name="name"
              variant="standard"
              value={form.name}
              error={errors.name}
              helperText={errors.name}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => handleForm(target.name, target.value)}
            />
            <Spacer size={5} />
            <FieldContainer
              placeholder="Apellido"
              name="lastname"
              variant="standard"
              value={form.lastname}
              error={errors.lastname}
              helperText={errors.lastname}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => handleForm(target.name, target.value)}
            />
          </FlexContainer>
          <Spacer vertical size={4} />
          <FlexContainer>
            <FieldContainer
              placeholder="Email"
              name="email"
              variant="standard"
              value={form.email}
              error={errors.email}
              helperText={errors.email}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => handleForm(target.name, target.value)}
            />
            <Spacer size={5} />
            <FieldContainer
              placeholder="Número de teléfono"
              name="phone_number"
              variant="standard"
              value={form.phone_number}
              error={errors.phone_number}
              helperText={errors.phone_number}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(value) => handleForm('phone_number', value)}
              as={MuiTelInput}
              onlyCountries={['MX', 'CO', 'BR']}
              forceCallingCode
            />
          </FlexContainer>
          <Spacer size={5} />
          <FormGroup aria-label="position" row>
            <Checkbox
              value={form.alohome_terms}
              name="alohome_terms"
              onChange={({ target }) => handleForm(target.name, target.checked)}
              id="alohome-terms-and-conditions"
            />
            <CustomCheckboxFormLabel htmlFor="alohome-terms-and-conditions">
              He leído y acepto los{' '}
              <Link
                target="_blank"
                href="https://alohome.io/politica-de-privacidad"
              >
                términos y condiciones
              </Link>{' '}
              de uso de Alohome
            </CustomCheckboxFormLabel>
          </FormGroup>
          <FormGroup aria-label="position" row>
            <Checkbox
              value={form.intelimetrica_terms}
              name="intelimetrica_terms"
              onChange={({ target }) => handleForm(target.name, target.checked)}
              id="intelimetrica-terms-and-conditions"
            />
            <CustomCheckboxFormLabel htmlFor="intelimetrica-terms-and-conditions">
              He leído y acepto los{' '}
              <Link
                target="_blank"
                href="https://yals.mx/terminos-y-condiciones"
              >
                términos y condiciones
              </Link>
              y{' '}
              <Link target="_blank" href="https://yals.mx/aviso-de-privacidad">
                el Aviso de privacidad
              </Link>{' '}
              de uso de Intelimétrica
            </CustomCheckboxFormLabel>
          </FormGroup>
          <Spacer size={5} />
          <Button
            disabled={formButtonDisabled}
            onClick={handleSubmitForm}
            sx={buttonsSx}
            variant="contained"
            color="primary"
          >
            COMENZAR
          </Button>
        </Box>
      )}
    </IntelimetricaLayout>
  );
};
