import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Link as MuiLink,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import MUIDataTable from 'mui-datatables';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { FlexContainer, Spacer } from '../../components';
import { deleteReq, get, downloadFile, RESPONSE_TYPE } from '../../helpers/request';
import { localization } from '../../helpers/tables';
import { prospectsTabs } from '../../helpers/tabsData';
import useSite from '../../hooks/useSite';
import PageLayout from '../../layouts/PageLayout';
import QuotationDetail from './QuotationDetail';
import useProspectsColumns from './useProspectsColumns';
import useTableLeads from './useTableLeads';
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import WithPermission from '../../components/WithPermission/WithPermission';

const ITEMS_PER_PAGE_OPTIONS = [5, 10, 15, 20, 50, 100];
const DOWNLOAD_EXCEL_ENDPOINT = "/internal/alohub/leads/download"

const getLeadsFn = ({ queryKey }) => {
  const siteId = queryKey[1];
  const pagination = queryKey[2];
  const search = new URLSearchParams({ ...pagination });
  return get(`/internal/alohub/leads?${search.toString()}`, {
    site_id: siteId,
  });
};

const savedNotificationsConfigFn = ({ queryKey }) => {
  const siteId = queryKey[1];
  if (!siteId) return;
  return get('/internal/alohub/config/notifications', { site_id: siteId });
};

const ProspectsView = () => {
  const { site } = useSite();
  const [isDownloading, setIsDownloading] = useState(false)
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);

  const { data: notificationsConfig, isLoading: isLoadingNotificationsConfig } =
    useQuery(
      ['NOTIFICATIONS_CONFIG', site?.site_id],
      savedNotificationsConfigFn
    );

  const [pagination, setPagination] = useState({
    page: 0,
    itemsPerPage: ITEMS_PER_PAGE_OPTIONS[0],
    sortBy: 'createdDate',
    sortOrder: 'DESC',
  });

  const setPaginationProperty = useCallback((property, value) => {
    setPagination((prev) => ({ ...prev, [property]: value }));
  }, []);

  const { data, isFetching, refetch } = useQuery(
    ['GET_LEADS', site?.site_id, pagination],
    getLeadsFn,
    { keepPreviousData: true }
  );

  const [rowsSelection, setRowsSelection] = useState(() => ({}));
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const leads = useMemo(() => data?.leads || [], [data]);
  const count = useMemo(() => data?.total, [data]);
  const selectedRowsCount = useMemo(
    () => Object.keys(rowsSelection).length,
    [rowsSelection]
  );

  const [rowsExpanded, setRowsExpanded] = useState([]);

  useEffect(() => {
    setRowsExpanded([]);
  }, [site, pagination]);

  useEffect(() => {
    setPagination((prevValue) => ({ ...prevValue, page: 0 }));
  }, [site]);

  useEffect(() => {
    setRowsSelection((prevState) => {
      const rows = { ...prevState };
      Object.keys(rows).forEach((rowName) => {
        if (!leads.some((lead) => lead.clientInfo.clientEmail === rowName)) {
          delete rows[rowName];
        }
      });
      return rows;
    });
  }, [leads]);

  const toggleDeleteDialog = () =>
    setOpenDeleteDialog((currentFlag) => !currentFlag);

  const toggleQuotationDetails = useCallback((rowIndex) => {
    setRowsExpanded((prevState) => {
      const rows = [...prevState];
      const index = prevState.indexOf(rowIndex);
      if (index === -1) rows.push(rowIndex);
      else rows.splice(index, 1);
      return rows;
    });
  }, []);

  const toggleAllRowsSelection = useCallback(
    (selectedRowsCount) => {
      setRowsSelection((prevState) => {
        const rows = { ...prevState };
        leads.forEach((lead) => (rows[lead.clientInfo.clientEmail] = true));
        return selectedRowsCount === leads.length ? {} : rows;
      });
    },
    [leads]
  );

  const toggleSelectRow = useCallback((currentRow) => {
    setRowsSelection((prevState) => {
      const rows = { ...prevState };
      if (rows[currentRow]) {
        delete rows[currentRow];
      } else {
        rows[currentRow] = true;
      }
      return rows;
    });
  }, []);

  const deleteLeads = () => {
    const emails = Object.keys(rowsSelection);
    deleteReq('/internal/developer/v3/leads', { emails }, site?.site_id).then(
      () => refetch()
    );
    toggleDeleteDialog();
  };

  const columns = useProspectsColumns({
    toggleQuotationDetails,
    rowsExpanded,
    toggleAllRowsSelection,
    rowsSelection,
    toggleSelectRow,
    leads,
  });

  const standardizedLeads = useTableLeads({ leads });

  const onTableChange = useCallback(
    (action, tableState) => {
      if (action === 'changePage')
        setPaginationProperty('page', tableState.page);
      else if (action === 'sort') {
        const column = columns.find(
          ({ name }) => name === tableState.sortOrder?.name
        );
        if (column) setPaginationProperty('sortBy', column.sortname);
        setPaginationProperty(
          'sortOrder',
          tableState.sortOrder.direction.toUpperCase()
        );
      }
    },
    [columns, setPaginationProperty]
  );

  const renderExpandableRow = useCallback(
    (rowData, { rowIndex }) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow sx={{ bgcolor: 'grey.100', p: 0 }}>
          <TableCell colSpan={colSpan} sx={{ p: 0 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 4,
                px: 6,
                py: 4,
                maxHeight: 500,
                overflow: 'auto',
              }}
            >
              {leads?.[rowIndex]?.quotes?.map((quote) => (
                <Box key={quote.id} sx={{ width: 1, maxWidth: 942 }}>
                  <QuotationDetail {...quote} />
                </Box>
              ))}
            </Box>
          </TableCell>
        </TableRow>
      );
    },
    [leads]
  );

  const tableOptions = useMemo(
    () => ({
      textLabels: localization,
      serverSide: true,
      onTableChange,
      page: pagination.page,
      rowsPerPage: pagination.itemsPerPage,
      rowsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
      count,
      onChangePage: (value) => setPaginationProperty('page', value),
      onChangeRowsPerPage: (value) =>
        setPaginationProperty('itemsPerPage', value),
      selectableRows: false,
      customToolbar: () => null,
      expandableRows: true,
      expandableRowsHeader: false,
      jumpToPage: true,
      rowsExpanded,
      renderExpandableRow,
      setTableProps: () => {
        return {
          sx: {
            // Avoid displaying expand column space
            '& tr > *.MuiTableCell-paddingCheckbox': { display: 'none' },
          },
        };
      },
    }),
    [
      pagination,
      count,
      onTableChange,
      setPaginationProperty,
      rowsExpanded,
      renderExpandableRow,
    ]
  );

  const handleExcelDownload = useCallback(async (ids) => {
    setIsDownloading(true)

    const currentDate = new Date()
    const fileName = `Prospectos_${currentDate.getDate()}-${currentDate.getMonth() +
      1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`
    const search = new URLSearchParams();
    if (ids)
      search.set("leadIds", ids);
    const res = await get(`${DOWNLOAD_EXCEL_ENDPOINT}?${search.toString()}`, { site_id: site?.site_id }, RESPONSE_TYPE.blob)
    await downloadFile(res, fileName)

    setIsDownloading(false)
    setOpenDownloadDialog(false)
  }, [site?.site_id])

  const handleDownloadAll = useCallback(() => handleExcelDownload(), [handleExcelDownload])

  const handleDownloadSelected = useCallback(() => {
    const selectedIds = leads
      .filter(l => rowsSelection[l.clientInfo.clientEmail])
      .map(l => l.leadId)
      .join()
    handleExcelDownload(selectedIds)
  }, [handleExcelDownload, leads, rowsSelection])

  const handleExcelClick = useCallback(() => {
    if (selectedRowsCount === 0) {
      handleDownloadAll()
    } else {
      setOpenDownloadDialog(true)
    }
  }, [handleDownloadAll, selectedRowsCount])

  const toggleDownloadDialog = useCallback(() => setOpenDownloadDialog(o => !o), [])

  const userShouldUpdateNotificationsConfig = useMemo(() => {
    if (isLoadingNotificationsConfig) return false;
    return !notificationsConfig?.new_lead?.whatsapp?.active;
  }, [isLoadingNotificationsConfig, notificationsConfig]);

  return (
    <PageLayout links={prospectsTabs}>
      <FlexContainer vertical>
        <Spacer vertical size={2} />
        {userShouldUpdateNotificationsConfig && (
          <WithPermission permissionPath={["config", "notifications", "new_lead"]}>
            <Alert severity="info">
              ¡Entérate de todo! Recibe por WhatsApp los datos de cada
              prospecto interesado en tu proyecto.{' '}
              <Link to="/config/notifications">
                <MuiLink>Activa las notificaciones</MuiLink>
              </Link>
            </Alert>
            <Spacer vertical size={4} />
          </WithPermission>
        )}
        <Dialog
          open={openDeleteDialog}
          onClose={toggleDeleteDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle>
            {selectedRowsCount === 1
              ? '¿Quieres eliminar el prospecto seleccionado?'
              : '¿Quieres eliminar los prospectos seleccionados?'}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={toggleDeleteDialog}
              color="primary"
              variant="outlined"
            >
              cancelar
            </Button>
            <Button onClick={deleteLeads} color="primary" variant="contained">
              eliminar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDownloadDialog}
          onClose={toggleDownloadDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle>
            ¿Cómo desea descargar la lista de prospectos?
          </DialogTitle>
          <DialogContent>
            <Typography><b>· Solo los seleccionados:</b> Descarga solo los prospectos seleccionados</Typography>
            <Typography><b>· Todos:</b> Descarga todos los prospectos de la lista</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDownloadDialog} color="primary" variant="text">
              Cancelar
            </Button>
            <Spacer size={1} />
            <Button onClick={handleDownloadAll} color="primary" variant="outlined">
              Todos
            </Button>
            <Spacer size={1} />
            <Button onClick={handleDownloadSelected} color="primary" variant="contained">
              Solo los ({selectedRowsCount}) seleccionados
            </Button>
          </DialogActions>
        </Dialog>
        <Box sx={{ position: 'relative' }}>
          <FlexContainer justified>
            <FlexContainer verticalCentered>
              <Button
                disabled={selectedRowsCount === 0}
                onClick={toggleDeleteDialog}
                variant="contained"
                color="primary"
              >
                eliminar prospecto(s)
              </Button>
              <Spacer size={3} />
              <Typography variant="caption">
                Tienes ({selectedRowsCount}) prospectos seleccionados
              </Typography>
            </FlexContainer>
            <Button
              disabled={isDownloading}
              onClick={handleExcelClick}
              variant="outlined"
              color="primary"
              startIcon={isDownloading ? <CircularProgress size={20} /> : <DownloadIcon />}
            >
              {isDownloading ? "descargando..." : "descargar en excel"} 
            </Button>
          </FlexContainer>
          <Spacer vertical size={2} />
          <LinearProgress
            sx={{ visibility: isFetching ? 'visible' : 'hidden' }}
          />
          <MUIDataTable
            columns={columns}
            data={standardizedLeads}
            options={tableOptions}
            components={{
              TableToolbar: () => null,
              ExpandButton: () => null,
            }}
          />
        </Box>
      </FlexContainer>
    </PageLayout>
  );
};

export default ProspectsView;
