import { Box, Button, Typography } from "@mui/material"
import React, { Fragment, useCallback, useState } from "react"
import { IconInput, Spacer } from "../../../components"
import AddIcon from '@mui/icons-material/Add'
import { generateRandomId } from "../../../helpers/utils"
import { DEFAULT_ICONS } from "../../../components/IconInput/IconInput"
import { getAlocodeIconUrl } from "../../../helpers/draft"
import useSite from "../../../hooks/useSite"

/*
CONFIG.JS DEFINITIONS
[{
  id: "1"
  icon: "[:pool:]",
  label: "Piscina",
}, {
  id: "2"
  icon: "[:nature:]",
  label: "Áreas verdes",
}]
*/

function getIcons(pathData, site) {
  if (!pathData) return DEFAULT_ICONS;
  const icons = [...DEFAULT_ICONS];
  const alocodeIcons = pathData
    .map(({ icon }) => icon)
    .filter((icon) => /^\[:.+:\]$/.test(icon));
  alocodeIcons.forEach((icon) => {
    if (!icons.some(({ value }) => value === icon)) {
      icons.push({ value: icon, src: getAlocodeIconUrl(icon, site) });
    }
  });
  return icons;
}

const IconList = ({ pathData, setPathData, maxChars }) => {
  const { site } = useSite();
  const [icons] = useState(() => getIcons(pathData, site));
  const handleAdd = useCallback(() => {
    const id = generateRandomId()
    setPathData([...pathData, {
      id,
      label: '',
      icon: '[:bed:]',
    }])
  }, [setPathData, pathData])

  const handleDelete = useCallback((id) => {
    const newValue = pathData?.filter(v => v.id !== id)
    setPathData(newValue)
  }, [setPathData, pathData])

  const handleLabelChange = useCallback((inputValue, id) => {
    if (inputValue.length <= maxChars) {
      const newValue = pathData?.map(v => v.id !== id ? v : {
        ...v,
        label: inputValue,
      })
      setPathData(newValue)
    }
  }, [maxChars, setPathData, pathData])

  const handleIconChange = useCallback((icon, id) => {
    const newValue = pathData?.map(v => v.id !== id ? v : {
      ...v,
      icon: icon,
    })
    setPathData(newValue)
  }, [setPathData, pathData])

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
          bgcolor: "background.default",
          zIndex: 1,
        }}
      >
        <Typography variant="caption" color="text.secondary">
          Lista
        </Typography>
        <Button
          color="primary"
          size="small"
          startIcon={<AddIcon />}
          onClick={handleAdd}
        >
          Añadir
        </Button>
      </Box>
      {pathData?.map((item, i) => (
        <Fragment key={item.id}>
          <Spacer vertical size={2} />
          <IconInput
            label={`Item ${i + 1}`}
            text={item.label}
            icon={item.icon}
            maxLength={maxChars}
            onDelete={() => handleDelete(item.id)}
            onIconChange={(e) => handleIconChange(e.target.value, item.id)}
            onTextChange={(e) => handleLabelChange(e.target.value, item.id)}
            icons={icons}
          />
        </Fragment>
      ))}
    </Box>
  );
}

export default IconList
