import { Alert, Button, Chip, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { MuiTelInput } from 'mui-tel-input';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FlexContainer, Spacer, UserPhoto } from '../../components';
import { post } from '../../helpers/request';
import useSite from "../../hooks/useSite"

const ROLES = {
  ADMIN: "Administrador",
  SUPERVISOR: "Supervisor",
  SALES: "Asesor",
}

const passwordResetEmailFn = ({ email }) =>
  post('/internal/alohub/password-reset/send-email', {
    email,
    resetPasswordHubPath: '/reset-password',
  });

const FieldContainer = styled(TextField)`
  width: 250px;
`;

const RoleChip = styled(Chip)`
  background-color: ${p => p.theme.palette.success.light};
  color: #fff;
  font-weight: 500;
`

const UserData = ({ label, data }) => (
  <FieldContainer vertical as={FlexContainer}>
    <Typography variant="caption" color="text.secondary">
      {label}
    </Typography>
    <Spacer size={0.5} vertical />
    <Typography>{data}</Typography>
  </FieldContainer>
);

const UserProfile = ({
  avatar,
  name,
  lastName,
  email,
  phone,
  whatsapp,
  onProfileChange,
}) => {
  const { site } = useSite()
  const [passwordResetStatus, setPasswordResetStatus] = useState(null);
  const [countDownToActiveReset, setCountDownToActiveReset] = useState(null);

  const onSuccess = useCallback(() => setPasswordResetStatus('success'), []);
  const onError = useCallback(() => setPasswordResetStatus('error'), []);

  const { mutate: mutatePasswordResetEmail, isLoading } = useMutation(
    passwordResetEmailFn,
    { onSuccess, onError },
  );

  const handlePasswordReset = useCallback(() => {
    mutatePasswordResetEmail({ email: email.value });
  }, [mutatePasswordResetEmail, email.value]);

  useEffect(() => {
    if (passwordResetStatus !== 'success') return;
    setCountDownToActiveReset(10);
    const intervalRef = setInterval(() => {
      setCountDownToActiveReset((prev) => {
        if (prev <= 1) {
          clearInterval(intervalRef);
          setPasswordResetStatus(null);
          return null;
        }
        return prev - 1;
      });
    }, 1_000);
    return () => clearInterval(intervalRef);
  }, [passwordResetStatus]);

  const disableResetPassword = !!countDownToActiveReset || isLoading;

  return (
    <FlexContainer vertical>
      <Spacer vertical size={5} />
      <FlexContainer>
        <FlexContainer vertical centered>
          <UserPhoto editable name={name} size={144} photoUrl={avatar} />
        </FlexContainer>
        <Spacer size={10} />
        <FlexContainer vertical>
          <FlexContainer>
            <UserData label="Email" data={email.value} />
            <Spacer size={5} />
            {site && site.role_name && (
              <RoleChip label={ROLES[site.role_name]} size="small" />
            )}
          </FlexContainer>
          <Spacer size={4} vertical />
          <FlexContainer>
            <FieldContainer
              label="Nombre"
              variant="standard"
              value={name.value}
              error={name.error}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => onProfileChange(e.target.value, 'name')}
            />
            <Spacer size={5} />
            <FieldContainer
              label="Apellido"
              variant="standard"
              value={lastName.value}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => onProfileChange(e.target.value, 'lastname')}
            />
          </FlexContainer>
          <Spacer size={4} vertical />
          <FlexContainer>
            <FieldContainer
              as={MuiTelInput}
              label="Número de teléfono"
              variant="standard"
              value={phone.value}
              InputLabelProps={{
                shrink: true,
              }}
              onlyCountries={['MX', 'CO', 'BR']}
              forceCallingCode
              onChange={(value) => onProfileChange(value, 'phone')}
            />
            <Spacer size={5} />
            <FieldContainer
              as={MuiTelInput}
              label="Número de Whatsapp"
              variant="standard"
              value={whatsapp.value}
              InputLabelProps={{
                shrink: true,
              }}
              onlyCountries={['MX', 'CO', 'BR']}
              forceCallingCode
              onChange={(value) => onProfileChange(value, 'whatsapp')}
            />
          </FlexContainer>
          <Spacer size={4} vertical />
          <UserData label="Contraseña" data="••••••••••" />
          <Spacer size={2} vertical />
          <Button
            color="primary"
            variant="contained"
            sx={{ alignSelf: 'flex-start' }}
            onClick={handlePasswordReset}
            disabled={disableResetPassword}
          >
            Cambiar contraseña{' '}
            {countDownToActiveReset ? `(${countDownToActiveReset})` : ''}
          </Button>
          {passwordResetStatus === 'success' && (
            <Alert severity="success" sx={{ my: 2, maxWidth: 'sm' }}>
              Te hemos enviado instrucciones a {email.value} para cambiar tu
              contraseña
            </Alert>
          )}
          {passwordResetStatus === 'error' && (
            <Alert severity="error" sx={{ my: 2, maxWidth: 'sm' }}>
              Ups! parece que ha ocurrido un error, por favor vuelve a intentar
              en unos minutos
            </Alert>
          )}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default UserProfile;
