import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FlexContainer, Spacer } from '../../components'
import useCurrencies from '../../hooks/useCurrencies'
import useSettings from '../../hooks/useSettings'
import EnhancedTableHead from './EnhancedTableHead'
import PriceChangeInfo from './PriceChangeInfo'
import UnitRow from './UnitRow'

const Container = styled(TableContainer)`
  padding: 0 24px;
  height: calc(100vh - 370px);
  overflow-y: auto;
`
const StyledTable = styled(Table)`
  width: max-content;
`

const StyledTableCell = styled(TableCell)`
  min-width: '128px';
`

const InventoryTable = ({
  inventory,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  onSelectAllClick,
  onStatusChange,
  onPriceChange,
  onEditPrice,
  onConfirmPrice,
  onFullUpfrontPaymentPriceChange,
  onEditFullUpfrontPaymentPrice,
  onConfirmFullUpfrontPaymentPrice,
  onEditDeliveryDate,
  onConfirmDeliveryDate,
  onDeliveryDateChange,
  onCheckChange,
  canSelectRow,
  changeStatusPermission,
  changeDeliveryDatePermission,
  changePricePermission,
}) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalNewPrice, setTotalNewPrice] = useState(0);
  const settings = useSettings('general', 'currency')
  const currencies = useCurrencies()
  const mainCurrencyCode = settings?.dualCurrency?.main
  useEffect(() => {
    if (mainCurrencyCode) {
      let newTotalPrice = 0
      let newTotalNewPrice = 0
      inventory.forEach(item => {
        newTotalPrice += item[`price_${mainCurrencyCode}`]
        newTotalNewPrice += item.new_price
      })

      setTotalPrice(newTotalPrice)
      setTotalNewPrice(newTotalNewPrice)
    }
  }, [inventory, mainCurrencyCode])

  return (
    <Container>
      {settings && currencies && mainCurrencyCode && (<StyledTable aria-label="customized table" stickyHeader >
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          numSelected={numSelected}
          rowCount={rowCount}
          onSelectAllClick={onSelectAllClick}
          canSelectRow={canSelectRow}
          changePricePermission={changePricePermission}
        />
        <TableBody>
          {inventory.map((unit) => (
            <UnitRow
              key={unit.unit_id}
              unit={unit}
              onStatusChange={onStatusChange}
              onPriceChange={onPriceChange}
              onEditPrice={onEditPrice}
              onConfirmPrice={onConfirmPrice}
              onFullUpfrontPaymentPriceChange={onFullUpfrontPaymentPriceChange}
              onEditFullUpfrontPaymentPrice={onEditFullUpfrontPaymentPrice}
              onConfirmFullUpfrontPaymentPrice={onConfirmFullUpfrontPaymentPrice}
              onEditDeliveryDate={onEditDeliveryDate}
              onConfirmDeliveryDate={onConfirmDeliveryDate}
              onDeliveryDateChange={onDeliveryDateChange}
              onCheckChange={onCheckChange}
              currencies={currencies}
              settings={settings}
              canSelectRow={canSelectRow}
              changeStatusPermission={changeStatusPermission}
              changeDeliveryDatePermission={changeDeliveryDatePermission}
              changePricePermission={changePricePermission}
              mainCurrencyCode={mainCurrencyCode}
            />
          ))}
          <>
            {currencies && (<TableRow>
              <StyledTableCell colSpan={5}></StyledTableCell>
              <StyledTableCell><b>TOTAL</b></StyledTableCell>
              <StyledTableCell><b>{`${currencies.main.symbol}${Math.trunc(totalPrice).toLocaleString()}`}</b></StyledTableCell>
              <StyledTableCell>
                <FlexContainer>
                  <PriceChangeInfo basePrice={totalPrice} newPrice={totalNewPrice} />
                  <Spacer size={1} />
                  <b>{`${currencies.main.symbol}${Math.trunc(totalNewPrice).toLocaleString()}`}</b>
                </FlexContainer>
              </StyledTableCell>
            </TableRow>)}
          </>
        </TableBody>
      </StyledTable>)}
    </Container>
  )
}

export default InventoryTable
