import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { getAssetUrlFromId } from "../../../helpers/assets";
import HoverCardMedia from "../../Cards/HoverCardMedia";

const VideoCard = ({ asset, onSelected }) => {
  const ref = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const onTogglePlay = useCallback(() => {
    setIsPlaying((playing) => {
      if (playing) ref.current.pause();
      else ref.current.play();
      return !playing;
    });
  }, []);

  return (
    <Card key={asset.asset_id}>
      <HoverCardMedia
        height={194}
        component="video"
        src={getAssetUrlFromId(asset.asset_id)}
        ref={ref}
      >
        <Box sx={{ display: "flex", justifyContent: "space-evenly", width: 1 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSelected}
            sx={{ textTransform: "none" }}
          >
            Elegir
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={onTogglePlay}
            sx={{ textTransform: "none" }}
          >
            {isPlaying ? "Pausar" : "Reproducir"}
          </Button>
        </Box>
      </HoverCardMedia>
      <CardContent>
        <Typography variant="body2">
          {asset.metadata?.name || asset.metadata?.fileName}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default VideoCard;
