import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText
} from "@mui/material";
import React from "react";

const DeleteCharacteristicDialog = ({ onDelete, onClose, ...dialogProps }) => {
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      PaperProps={{ sx: { p: 4 } }}
      onClose={onClose}
      {...dialogProps}
    >
      <DialogContent>
        <DialogContentText variant="h6" component="p">
          ¿Quieres eliminar esta característica?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={onDelete}>
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCharacteristicDialog;
