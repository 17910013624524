import { Button, TextField, Typography, Switch, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { FlexContainer, Spacer, RangeSlider } from '../../components'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { MAX_PLAN_DESCRIPTION_LENGTH, MAX_PLAN_NAME_LENGTH } from './utils'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 0px 1px #E0E0E0;
  border-radius: 4px;
  padding: 16px;
`

const OptionButton = styled(Button)`
  background: #4CAF50;
  color: #fff;
  width: fit-content;
  &:hover {
    background: #4CAF50;
    filter: brightness(1.05);
  }
`;

const SwitchContainer = styled(FlexContainer)`
  margin-left: -12px;
`

const EditPlan = ({ plan, onClose, onConfirm }) => {
  const [isConfirmCloseOpen, setIsConfirmCloseOpen] = useState(false)
  const [currentPlan, setCurrentPlan] = useState(plan)

  const handleNameChange = useCallback((value) => {
    if (value.length <= MAX_PLAN_NAME_LENGTH) {
      setCurrentPlan(p => ({
        ...p,
        name: value,
      }))
    }
  }, [])

  const handleDescriptionChange = useCallback((value) => {
    if (value.length <= MAX_PLAN_DESCRIPTION_LENGTH) {
      setCurrentPlan(p => ({
        ...p,
        description: value,
      }))
    }
  }, [])

  const handleSliderChange = useCallback((value, attribute) => {
    setCurrentPlan(p => {
      if (attribute === 'down_payment_percent') {
        if (value + p.monthly_payment_percent > 100) {
          return {
            ...p,
            down_payment_percent: value,
            monthly_payment_percent: 100 - value,
          }
        }
      } else if (attribute === 'monthly_payment_percent') {
        if (value + p.down_payment_percent > 100) {
          return {
            ...p,
            monthly_payment_percent: value,
            down_payment_percent: 100 - value,
          }
        }
      }
      return {
        ...p,
        [attribute]: value
      }
    })
  }, [])

  const handleConfirm = useCallback(() => {
    onConfirm(currentPlan)
    onClose()
    setIsConfirmCloseOpen(false)
  }, [currentPlan, onClose, onConfirm])

  const toggleConfirmCloseModal = useCallback(() => setIsConfirmCloseOpen(!isConfirmCloseOpen), [isConfirmCloseOpen])

  const handleClose = useCallback(() => {
    let isDifferent = false
    for (const [key, value] of Object.entries(currentPlan)) {
      if (value !== plan[key]) {
        isDifferent = true
        break
      }
    }

    if (isDifferent) {
      toggleConfirmCloseModal()
    } else {
      onClose()
    }
  }, [currentPlan, onClose, plan, toggleConfirmCloseModal])

  const handleMonthlySwitchChange = useCallback((value) => {
    setCurrentPlan(p => ({
      ...p,
      number_of_months: value ? -1 : 0
    }))
  }, [])

  return (
    <>
      <Dialog
        open={isConfirmCloseOpen}
        onClose={toggleConfirmCloseModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>¿Quieres salir de este plan sin guardar los cambios?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Puedes regresar más tarde y seguir editándolo
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" variant="outlined">
            Salir
          </Button>
          <Button onClick={handleConfirm} color="primary" variant="contained">
            Guardar Cambios
          </Button>
        </DialogActions>
      </Dialog>
      <Container>
        <Typography>Nombre del Plan ({currentPlan.name.length}/{MAX_PLAN_NAME_LENGTH})</Typography>
        <Spacer size={1} vertical />
        <TextField
          variant="outlined"
          size="small"
          placeholder='Nombre del Plan'
          value={currentPlan.name}
          onChange={e => handleNameChange(e.target.value)}
        />
        <Spacer size={3} vertical />
        <Typography>Descripción del Plan (opcional) ({currentPlan?.description?.length ?? 0}/{MAX_PLAN_DESCRIPTION_LENGTH})</Typography>
        <Spacer size={1} vertical />
        <TextField
          variant="outlined"
          size="small"
          placeholder='Descripción del Plan'
          value={currentPlan.description ?? ''}
          onChange={e => handleDescriptionChange(e.target.value)}
        />
        <Spacer size={3} vertical />
        <RangeSlider
          title='Enganche (%)'
          endSymbol='%'
          min={0}
          max={100}
          step={1}
          value={currentPlan.down_payment_percent}
          onChange={(value) => handleSliderChange(value, 'down_payment_percent')}
        />
        <Spacer size={3} vertical />
        <RangeSlider
          title='Mensualidades'
          description='Número de pagos que se harán antes de la fecha de entrega'
          disabled={currentPlan.number_of_months === -1}
          min={0}
          max={48}
          step={1}
          value={currentPlan.number_of_months}
          onChange={(value) => handleSliderChange(value, 'number_of_months')}
        />
        <SwitchContainer verticalCentered>
          <Switch
            checked={currentPlan.number_of_months === -1 ? true : false}
            onChange={(e) => handleMonthlySwitchChange(e.target.checked)}
            color='primary'
          />
          <Typography variant='caption'>Calcular mensualidades de acuerdo a la fecha de entrega.</Typography>
        </SwitchContainer>
        <Spacer size={3} vertical />
        <RangeSlider
          title='Mensualidades (%)'
          endSymbol='%'
          min={0}
          max={100}
          step={1}
          value={currentPlan.monthly_payment_percent}
          onChange={(value) => handleSliderChange(value, 'monthly_payment_percent')}
        />
        <Spacer size={3} vertical />
        <RangeSlider
          title='Descuento (%)'
          endSymbol='%'
          min={0}
          max={100}
          step={1}
          value={currentPlan.discount_percent}
          onChange={(value) => handleSliderChange(value, 'discount_percent')}
        />
        <Spacer size={3} vertical />
        <FlexContainer>
          <OptionButton variant="contained" size="small" onClick={handleClose}>Salir</OptionButton>
          <Spacer size={2} />
          <OptionButton variant="contained" size="small" onClick={handleConfirm}>Guardar Plan</OptionButton>
        </FlexContainer>
      </Container>
    </>
  )
}

export default EditPlan