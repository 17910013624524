import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { convertMMYYtoFormat } from '../../helpers/utils'
import React from 'react'
import styled from 'styled-components'

const Container = styled(TableContainer)`
  padding: 0 24px;
  height: calc(100vh - 650px);
  overflow-y: auto;
`

const BulkEditDeliveryDateTable = ({ newInventory }) => (
  <Container>
    <Table aria-label="bulk edit delivery date" stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell><b>Departamento ID</b></TableCell>
          <TableCell><b>Fecha de Entrega Anterior</b></TableCell>
          <TableCell><b>Fecha de Entrega Nueva</b></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {newInventory.map(unit => (<TableRow key={unit.unit_id}>
          <TableCell>{unit.department_number}</TableCell>
          <TableCell>{convertMMYYtoFormat(unit.delivery_date, 'MMMM YYYY')}</TableCell>
          <TableCell>{convertMMYYtoFormat(unit.new_delivery_date, 'MMMM YYYY')}</TableCell>
        </TableRow>))}
      </TableBody>
    </Table>
  </Container>
)

export default BulkEditDeliveryDateTable
