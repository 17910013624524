import { Avatar } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import userSvg from '../../assets/svgs/user.svg';
import imageSvg from '../../assets/svgs/image.svg';
import { useRef } from 'react';
import { filePutSignedUrl, put } from '../../helpers/request';
import { useDispatch } from 'react-redux';

const Container = styled.div`
  display: flex;
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--color-primary-dark);
  border-radius: 50%;
  opacity: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
`;

const ImageSvg = styled.img`
  margin: 0 auto;
  display: flex;
  width: 36px;
  height: 36px;
`;

const ChangeImageLabel = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 166%;

  text-align: center;
  letter-spacing: 0.4px;

  color: #ffffff;
  padding: 0 20px;
  margin-top: 5px;
`;

const UserPhoto = ({ photoUrl, name, size, editable = false, sx }) => {
  const dispatch = useDispatch();
  const [isHover, setIsHover] = useState(false);
  const inputRef = useRef();

  const handleChangePhoto = async ({ target }) => {
    const file = target.files[0];
    if (file) {
      const { signed_url } = await put('/internal/alohub/profile/avatar', {
        mime_type: file.type,
      });
      await filePutSignedUrl(signed_url, file);
      dispatch({ type: 'reload-profile', data: true })
    }
  };

  const handleOpenInputSelector = () => {
    inputRef.current.value = '';
    inputRef.current.click();
  };

  return (
    <Container
      onMouseEnter={() => editable && setIsHover(true)}
      onMouseLeave={() => editable && setIsHover(false)}
    >
      <Avatar
        alt={name}
        src={photoUrl ?? userSvg}
        sx={{ width: size, height: size, ...sx }}
      />
      {isHover && (
        <Overlay onClick={handleOpenInputSelector}>
          <ImageSvg src={imageSvg} />
          <ChangeImageLabel>Sube una imagen</ChangeImageLabel>
        </Overlay>
      )}
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        hidden
        onChange={handleChangePhoto}
      />
    </Container>
  );
};

export default UserPhoto;
