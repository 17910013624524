import mixpanel from "mixpanel-browser"

const initializeMixpanel = () => {
  const project_key = process.env.REACT_APP_MIXPANEL_TOKEN
  if (project_key && project_key.length > 0) {
    mixpanel.init(project_key, { debug: process.env.NODE_ENV !== 'production' })
  }
  // TODO: Research if there's a prop to check if mixpanel has been initialized or not
}

const clientIdentify = (email) => {
  try {
    mixpanel.identify(email)
    trackEvent('profile')
  } catch (error) {
    console.error(error)
  }
}

const trackEvent = (event, params) => {
  try {
    mixpanel.track(event, params)
  } catch (error) {
    console.error(error)
  }
}

export { initializeMixpanel, clientIdentify, trackEvent }
