import { WarningAmber as WarningAmberIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControlLabel,
  Link as MUILink,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getKeysAsPath, getPathValue } from '../../helpers/data';
import useDebouncedState from '../../hooks/useDebouncedState';
import useSite from '../../hooks/useSite';
import AttachedFiles from './AttachedFiles';
import CheckItem, { MUST_BE_SHOW_ON } from './CheckItem';
import Section from './Section';

export const LEGAL_URL_SLUG = 'legal';

export const LEGAL_NOM_PATH = 'legal';

export const LEGAL_CHECKLIST_IDS = [
  'Art_73',
  'Art_6-Sec_XXI',
  'Art_73_LFPC-Sec_III',
  'Cap_VIII_LFPC-Sec_V',
  'Art_7',
  'Art_5_5',
  'Sec_5_6_3',
  'Art_4_2-Sec_VII',
  'Art_5_5-Art_9_LFPC',
  'Art_5-Sec_VI',
  'Art_10',
  'Art_4_7',
];

const Legal = ({ data, getSectionData, setSectionData }) => {
  const onRegistryChange = useCallback(
    (v) => setSectionData('adhesionContractsPROFECO.publicRegistry', v),
    [setSectionData]
  );
  const [registry, setRegistry] = useDebouncedState(
    () => getSectionData('adhesionContractsPROFECO.publicRegistry') || '',
    { onSettled: onRegistryChange }
  );
  const handleRegistryChange = useCallback(
    (e) => setRegistry(e.target.value),
    [setRegistry]
  );

  const { site } = useSite();

  const privacyLink = useMemo(() => {
    if (!site) return;
    const privacySlug =
      getPathValue(data, 'pages.TermsAndConditions.slug') ||
      getPathValue(data, 'pages.Terms.slug');
    if (!privacySlug) return;
    return `https://${site.site_domain}${privacySlug}`;
  }, [site, data]);

  const getValue = useCallback(
    (id) => getSectionData(getKeysAsPath(['checkList', id])),
    [getSectionData]
  );

  const getHandleValueChange = useCallback(
    (id) => (path, value) =>
      setSectionData(getKeysAsPath(['checkList', id, path]), value),
    [setSectionData]
  );

  return (
    <Section title="Legal" id="legal">
      <CheckItem
        value={getValue('Art_73')}
        setValuePath={getHandleValueChange('Art_73')}
        title={
          <>
            Contratos de adhesión registrados ante la PROFECO
            <Tooltip title="Para comercilizar tu proyecto, es indispensable tener contratos registrados">
              <WarningAmberIcon
                color="error"
                sx={{ ml: 1, verticalAlign: 'bottom' }}
              />
            </Tooltip>
          </>
        }
        NOMSection="Art. 73"
        mustBeShowOn={MUST_BE_SHOW_ON.PHYSICALLY_AND_DIGITALLY}
      >
        <TextField
          name="PROFECORegistry"
          variant="standard"
          label="Núm. de Registro Público"
          sx={{ display: 'block', mb: 2 }}
          value={registry}
          onChange={handleRegistryChange}
          helperText={
            <MUILink href="https://rpca.profeco.gob.mx/getDoc?p=1787-2022">
              Ver ejemplo
            </MUILink>
          }
        />
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography component="span" sx={{ mr: 2 }}>
            Estatus de los contratos registrados:
          </Typography>
          <RadioGroup
            row
            value={getSectionData('adhesionContractsPROFECO.status') || ''}
            onChange={(v) =>
              setSectionData('adhesionContractsPROFECO.status', v.target.value)
            }
          >
            <FormControlLabel
              value="REVISION"
              label="En revisión"
              control={<Radio />}
            />
            <FormControlLabel
              value="APPROVED"
              label="Aprobado(s)"
              control={<Radio />}
            />
          </RadioGroup>
        </Box>
        <AttachedFiles
          files={getSectionData('adhesionContractsPROFECO.files')}
          setFiles={(v) => setSectionData('adhesionContractsPROFECO.files', v)}
        />
      </CheckItem>
      <CheckItem
        value={getValue('Art_6-Sec_XXI')}
        setValuePath={getHandleValueChange('Art_6-Sec_XXI')}
        title="Carta de derechos del consumidor"
        NOMSection="Art. 6, Sec. XXI"
        mustBeShowOn={MUST_BE_SHOW_ON.PHYSICALLY_AND_DIGITALLY}
      >
        <AttachedFiles
          files={getSectionData('consumerRights.files')}
          setFiles={(v) => setSectionData('consumerRights.files', v)}
        />
      </CheckItem>
      <CheckItem
        value={getValue('Art_73_LFPC-Sec_III')}
        setValuePath={getHandleValueChange('Art_73_LFPC-Sec_III')}
        title="Acreditación de la propiedad"
        NOMSection="Art. 73 de la LFPC, Sec. III"
        mustBeShowOn={MUST_BE_SHOW_ON.PHYSICALLY_AND_DIGITALLY}
      >
        <AttachedFiles
          files={getSectionData('propertyAccreditation.files')}
          setFiles={(v) => setSectionData('propertyAccreditation.files', v)}
        />
      </CheckItem>
      <CheckItem
        value={getValue('Cap_VIII_LFPC-Sec_V')}
        setValuePath={getHandleValueChange('Cap_VIII_LFPC-Sec_V')}
        title="Obligaciones en las entregas"
        NOMSection="Cap. VIII de la LFPC, Sec. V"
        mustBeShowOn={MUST_BE_SHOW_ON.PHYSICALLY_OR_DIGITALLY}
        digitallyChildren={
          <AttachedFiles
            files={getSectionData('deliveryObligations.files')}
            setFiles={(v) => setSectionData('deliveryObligations.files', v)}
          />
        }
      />
      <CheckItem
        value={getValue('Art_7')}
        setValuePath={getHandleValueChange('Art_7')}
        title="Garantías ofrecidas a los compradores del proyecto"
        NOMSection="Art. 7"
        mustBeShowOn={MUST_BE_SHOW_ON.PHYSICALLY_OR_DIGITALLY}
        digitallyChildren={
          <AttachedFiles
            files={getSectionData('buyerWarranties.files')}
            setFiles={(v) => setSectionData('buyerWarranties.files', v)}
          />
        }
      />
      <CheckItem
        value={getValue('Art_5_5')}
        setValuePath={getHandleValueChange('Art_5_5')}
        title="Información de los promotores autorizados para comercializar el proyecto: credencial expedida por el proveedor que incluya nombre completo, logo, cargo, vigencia de ID y número de atención de quejas"
        NOMSection="Art. 5.5"
        mustBeShowOn={MUST_BE_SHOW_ON.PHYSICALLY_AND_DIGITALLY}
      >
        <Button variant="outlined" color="primary" component={Link} to="/team">
          Editar
        </Button>
      </CheckItem>
      <CheckItem
        value={getValue('Sec_5_6_3')}
        setValuePath={getHandleValueChange('Sec_5_6_3')}
        title="Programa de Protección Civil del inmueble"
        NOMSection="Sec 5.6.3"
        mustBeShowOn={MUST_BE_SHOW_ON.PHYSICALLY_OR_DIGITALLY}
        digitallyChildren={
          <AttachedFiles
            files={getSectionData('propertyCivilProtection.files')}
            setFiles={(v) => setSectionData('propertyCivilProtection.files', v)}
          />
        }
      />
      <CheckItem
        value={getValue('Art_4_2-Sec_VII')}
        setValuePath={getHandleValueChange('Art_4_2-Sec_VII')}
        title="Aviso de Privacidad conforme a lo establecido por la LFPDPPP"
        NOMSection="Art. 4.2 Sec. VII"
        mustBeShowOn={MUST_BE_SHOW_ON.DIGITALLY}
      >
        {privacyLink && (
          <Button
            variant="outlined"
            color="primary"
            component="a"
            target="_blank"
            href={privacyLink}
          >
            Ver
          </Button>
        )}
      </CheckItem>
      <CheckItem
        value={getValue('Art_5_5-Art_9_LFPC')}
        setValuePath={getHandleValueChange('Art_5_5-Art_9_LFPC')}
        title="Lo ofrecido por el promotor debe ser cumplido al consumidor, en virtud de la responsabilidad administrativa que tiene el personal contratado o subcontratado por el proveedor de conformidad"
        NOMSection="Art. 5.5 / Art. 9 de la LFPC"
      />
      <CheckItem
        value={getValue('Art_5-Sec_VI')}
        setValuePath={getHandleValueChange('Art_5-Sec_VI')}
        title="Licencias, permisos o autorizaciones del inmueble otorgados por las autoridades competentes"
        NOMSection="Art. 5, Sec. VI"
        mustBeShowOn={MUST_BE_SHOW_ON.PHYSICALLY_OR_DIGITALLY}
        digitallyChildren={
          <AttachedFiles
            files={getSectionData('licensesAndPermits.files')}
            setFiles={(v) => setSectionData('licensesAndPermits.files', v)}
          />
        }
      />
      <CheckItem
        value={getValue('Art_10')}
        setValuePath={getHandleValueChange('Art_10')}
        title="En caso de que el consumidor haya hecho valer la garantía establecida en el artículo 73 QUÁTER de la LFPC y persistan los defectos o fallas imputables al proveedor, éste se verá obligado a otorgarle al consumidor las bonificaciones a que se refiere el artículo 73 del mismo ordenamiento"
        NOMSection="Art. 10"
      />
      <CheckItem
        value={getValue('Art_4_7')}
        setValuePath={getHandleValueChange('Art_4_7')}
        title="El proveedor debe demostrar que cuenta con una política de NO discriminación de conformidad con el artículo 58 de la LFPC y que la misma ha sido notificada a los consumidores"
        NOMSection="Art. 4.7"
        mustBeShowOn={MUST_BE_SHOW_ON.PHYSICALLY}
      />
    </Section>
  );
};

export default Legal;
