import { CONTACT_METHODS_PATH, getPathValue } from './data';

export function getContactMethodsSettingsFromData(data) {
  const contactData = getPathValue(data, CONTACT_METHODS_PATH);
  if (!contactData) return null;
  const contact = {};
  contact.phone = contactData.phone?.content
    ? [contactData.phone.content]
    : null;
  contact.whatsapp = contactData.whatsapp?.content
    ? [contactData.whatsapp.content]
    : null;
  contact.email = contactData.email?.content
    ? [contactData.email.content]
    : null;
  contact.address = contactData.address?.content
    ? [contactData.address.content]
    : null;
  return contact;
}
