import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

const DisableTransparencyDialog = ({ onDisable, onClose, ...dialogProps }) => {
  const [userConfirmation, setUserConfirmation] = useState(false);

  useEffect(() => {
    setUserConfirmation(false);
  }, [dialogProps.open]);

  const onUserConfirmationChange = useCallback(
    (e) => setUserConfirmation(e.target.checked),
    []
  );

  const handleDisable = useCallback(() => {
    onDisable();
    onClose?.();
  }, [onDisable, onClose]);

  return (
    <Dialog {...dialogProps} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>¡Espera! Primero lee esto...</DialogTitle>
      <DialogContent>
        Al desactivar tu página de TRANSPARENCIA dejarías de cumplir con
        diversos lineamientos de la NOM247: existe información que debe estar
        visible de forma obligatoria en tu sitio web (showroom digital).
        <br />
        <br />
        Podrías recibir una multa, siendo 100% responsabilidad del
        desarrollador.
        <FormControlLabel
          label="Leí el aviso y entiendo las consecuencias"
          control={
            <Checkbox
              checked={userConfirmation}
              onChange={onUserConfirmationChange}
            />
          }
          sx={{ display: 'block', my: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!userConfirmation}
          onClick={handleDisable}
        >
          Desactivar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisableTransparencyDialog;
