import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import IntelimetricaLogo from '../../assets/images/intelimetrica-logo.png';
import { get, post } from '../../helpers/request';
import { appsTabs } from '../../helpers/tabsData';
import useSite from '../../hooks/useSite';
import PageLayout from '../../layouts/PageLayout';
import FlatLogo from '../../assets/images/flat_logo.png';
import { getPermission } from '../../helpers/permissions';

const APPS_PER_CATEGORY = {
  data: {
    intelimetrica: {
      title: 'Intelimétrica',
      description:
        'Conoce el precio estimado de las propiedades que están alrededor de tu proyecto inmobiliario',
      logoSrc: IntelimetricaLogo,
      href: '/intelimetrica/report',
      ctaLabel: 'Ver Reporte',
      permissionPath: ['integrations', 'intelimetrica'],
    },
  },
  marketplaces: {
    flat: {
      title: 'Flat.mx',
      description:
        '¡Llega a más clientes! Incrementa, sin costo, la visibilidad de tu proyecto y véndelo más rápido.',
      logoSrc: FlatLogo,
      href: '/flat/welcome',
      ctaLabel: 'Configurar',
      permissionPath: ['integrations', 'flat'],
    },
  },
};

const getActive = ({ queryKey }) =>
  get('/internal/alohub/integrations/active', { site_id: queryKey[1] });

const setAppActive = ({ code, siteId, active }) =>
  post('/internal/alohub/integrations/active', { [code]: active }, siteId);

const AppsView = () => {
  const { category } = useParams();
  const navigate = useNavigate();
  const { site } = useSite();
  const siteId = site?.site_id;
  const [deactivatingApp, setDeactivatingApp] = useState(null);

  const {
    data: activeApps,
    isLoading: isLoadingActiveApps,
    refetch,
  } = useQuery(['ACTIVE_APPS', siteId], getActive, {
    staleTime: 360_000,
    keepPreviousData: true,
    enabled: !!siteId,
  });

  const onSuccess = useCallback(
    (_, { code, active }) => {
      (async () => {
        setDeactivatingApp(null);
        await refetch();
        const href = APPS_PER_CATEGORY[category]?.[code]?.href;
        if (href && active) navigate(href);
      })().catch(console.error);
    },
    [refetch, category, navigate]
  );

  const { mutate: mutateSetAppActive, isLoading: isLoadingSetActive } =
    useMutation(setAppActive, {
      onSuccess,
    });

  const apps = useMemo(() => {
    if (!category || !APPS_PER_CATEGORY[category] || !site?.permissions)
      return [];
    return (
      Object.entries(APPS_PER_CATEGORY[category])
        .filter(
          ([code, data]) =>
            getPermission(data.permissionPath, site?.permissions) !== 'hidden'
        )
        .map(([code, { ...data }]) => ({
          ...data,
          code,
          active: !!activeApps?.includes(code),
        })) || []
    );
  }, [category, activeApps, site?.permissions]);

  const disableActions = isLoadingActiveApps || isLoadingSetActive;

  const onDiscardDeactivation = useCallback(() => setDeactivatingApp(null), []);

  useEffect(() => setDeactivatingApp(null), [category]);

  return (
    <>
      <PageLayout loading={isLoadingActiveApps && !activeApps} links={appsTabs}>
        <Box
          sx={{
            display: 'flex',
            gap: 4,
            maxWidth: 'md',
            width: 1,
            mt: 2,
            '& > *': {
              my: 1,
              maxWidth: 500,
              width: 1,
            },
          }}
        >
          {apps.map(
            ({ code, title, description, logoSrc, active, href, ctaLabel }) => (
              <Card
                key={code}
                sx={{ display: 'flex', alignItems: 'center', p: 1 }}
              >
                {logoSrc && (
                  <CardMedia
                    component="img"
                    src={logoSrc}
                    height={100}
                    sx={{ width: 100, ml: 2 }}
                  />
                )}
                <CardContent>
                  <Typography variant="h5">{title}</Typography>
                  <Typography>{description}</Typography>
                </CardContent>
                <CardActions>
                  {active ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                      }}
                    >
                      <Button
                        component={Link}
                        to={href}
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={() => setDeactivatingApp(code)}
                        disabled={disableActions}
                        sx={{ width: 'max-content' }}
                      >
                        {ctaLabel}
                      </Button>
                      <Button
                        color="error"
                        size="small"
                        onClick={() => setDeactivatingApp(code)}
                        disabled={disableActions}
                      >
                        Desactivar
                      </Button>
                    </Box>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={() =>
                        mutateSetAppActive({ code, siteId, active: true })
                      }
                      disabled={disableActions}
                      sx={{ px: 2 }}
                    >
                      Activar
                    </Button>
                  )}
                </CardActions>
              </Card>
            )
          )}
        </Box>
      </PageLayout>
      <Dialog open={!!deactivatingApp} onClose={onDiscardDeactivation}>
        <DialogTitle>
          La información de tu proyecto dejará de compartirse con{' '}
          {APPS_PER_CATEGORY[category]?.[deactivatingApp]?.title}
        </DialogTitle>
        <DialogContent>
          En caso de que más adelante quieras activar de nuevo este servicio,
          conservaremos la configuración que realizaste en alohome.
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={onDiscardDeactivation}
            disabled={isLoadingSetActive}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              mutateSetAppActive({
                code: deactivatingApp,
                siteId,
                active: false,
              })
            }
            disabled={isLoadingSetActive}
          >
            Entendido
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AppsView;
