import styled from '@emotion/styled';
import {
  alpha,
  Box,
  Button,
  Checkbox,
  FormGroup,
  FormLabel,
  Link,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import FlatBanner from '../../assets/images/flat_banner.png';
import { FlexContainer, Spacer } from '../../components';
import { FlatLayout } from './Layout';
import Info from '@mui/icons-material/InfoOutlined';
import { useEffect, useState } from 'react';
import { post } from '../../helpers/request';
import useSite from '../../hooks/useSite';

const Container = styled.div``;

const Banner = styled.img`
  display: block;
  width: 100%;
  object-fit: cover;
  min-height: 300px;
`;

const StyledInfoIcon = styled(Info)`
  color: ${(props) => props.color};
`;

const SelectContainer = styled(TextField)`
  width: 300px;
`;

const CustomCheckboxFormLabel = styled(FormLabel)`
  margin-top: auto;
  margin-bottom: auto;
`;

const projectLocations = [
  {
    label: 'CDMX',
    value: 'CDMX',
  },
  {
    label: 'EDOMX',
    value: 'EDOMX',
  },
  {
    label: 'Puebla',
    value: 'Puebla',
  },
  {
    label: 'Querétaro',
    value: 'Querétaro',
  },
  {
    label: 'Guadalajara',
    value: 'Guadalajara',
  },
  {
    label: 'Monterrey',
    value: 'Monterrey',
  },
];

const WelcomeToFlatView = () => {
  const [reloadLayout, setReloadLayout] = useState(false);
  const { site } = useSite();
  const [form, setForm] = useState({
    projectLocation: '',
    alohome_terms: false,
    flat_terms: false,
  });

  const [formButtonDisabled, setFormButtonDisabled] = useState(false);

  const handleForm = (name, value) => {
    setForm((form) => ({ ...form, [name]: value }));
  };

  const handleSubmit = () => {
    post(
      `/internal/alohub/integrations/flat/config/welcome-data`,
      form,
      site.site_id
    ).then(() => {
      setReloadLayout(true);
    });
  };

  const validateForm = (data) => {
    if (!data.projectLocation) {
      return false;
    } else if (!data.alohome_terms) {
      return false;
    } else if (!data.flat_terms) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setFormButtonDisabled(!validateForm(form));
  }, [form]);

  return (
    <FlatLayout reloadLayout={reloadLayout} setReloadLayout={setReloadLayout}>
      <Container>
        <Banner src={FlatBanner} />
        <Spacer vertical size={2} />
        <Typography>
          ¡Hola! Somos Flat.mx y trabajamos junto a los portales inmobiliarios
          más importantes para posicionar tu proyecto y aumentar su
          visilibildad.
        </Typography>
        <Spacer vertical size={3} />
        <Typography>
          Contamos con +4000 aliados que se dedicarán a encontrar al comprador
          ideal para que tu inmueble se venda más rápido y al mejor precio.
        </Typography>
        <Spacer vertical size={3} />
        <Typography>
          Hoy en día, tenemos operaciones en CDMX, Estado de México, Monterrey,
          Querétaro, Guadalajara, Puebla... y seguimos trabajando para estar en
          nuevas ciudades. ¡Llega a más clientes ahora mismo!
        </Typography>
        <Spacer vertical size={3} />
        <Box
          sx={{
            py: 2,
            px: 2,
            mb: 4,
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
            borderRadius: 4,
          }}
        >
          <FlexContainer>
            <FlexContainer vertical justified verticalCentered>
              <StyledInfoIcon size="small" color={'info'} />
            </FlexContainer>
            <Spacer size={3} />
            <FlexContainer vertical>
              <Typography>
                Nuestra alianza con Alohome permite difundir tu proyecto y
                llegar a más clientes sin ningún costo; sin embargo, existe una
                comisión del 4% por unidad vendida.
              </Typography>
              <Spacer vertical size={2} />
              <Typography>
                Este porcentaje se determina tras un análisis de las
                características de tu inmueble. Para conocer la comisión exacta,
                por favor, escríbenos a email@flat.mx
              </Typography>
            </FlexContainer>
          </FlexContainer>
        </Box>
        <Spacer vertical size={3} />
        <SelectContainer
          select
          variant="standard"
          name="projectLocation"
          value={form.projectLocation}
          onChange={({ target }) => handleForm(target.name, target.value)}
          label="¿En dónde se ubica tu proyecto?"
        >
          {projectLocations
            .sort((a, b) => a.value.localeCompare(b.value))
            .map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
        </SelectContainer>
        <Spacer vertical size={3} />
        <FormGroup aria-label="position" row>
          <Checkbox
            value={form.alohome_terms}
            name="alohome_terms"
            onChange={({ target }) => handleForm(target.name, target.checked)}
            id="alohome-terms-and-conditions"
          />
          <CustomCheckboxFormLabel htmlFor="alohome-terms-and-conditions">
            He leído y acepto los{' '}
            <Link
              target="_blank"
              href="https://alohome.io/politica-de-privacidad"
            >
              términos y condiciones
            </Link>{' '}
            de uso de Alohome
          </CustomCheckboxFormLabel>
        </FormGroup>
        <FormGroup aria-label="position" row>
          <Checkbox
            value={form.flat_terms}
            name="flat_terms"
            onChange={({ target }) => handleForm(target.name, target.checked)}
            id="flat-terms-and-conditions"
          />
          <CustomCheckboxFormLabel htmlFor="flat-terms-and-conditions">
            He leído y acepto los{' '}
            <Link target="_blank" href="https://www.flat.mx/terminos-y-condiciones">
              términos y condiciones
            </Link>
            y el{' '}
            <Link target="_blank" href="https://www.flat.mx/aviso-de-privacidad">
              Aviso de privacidad
            </Link>{' '}
            de uso de Flat
          </CustomCheckboxFormLabel>
        </FormGroup>
        <Spacer vertical size={3} />
        <Button
          disabled={formButtonDisabled}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          comenzar
        </Button>
        <Spacer vertical size={3} />
      </Container>
    </FlatLayout>
  );
};

export default WelcomeToFlatView;
