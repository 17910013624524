import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { FlexContainer, Spacer } from '../../components';
import useOnboardingContext from '../../hooks/useOnboardingContext';
import PublicLayout from '../../layouts/PublicLayout';
import AutoCompleteMap from '../../modules/AutoCompleteMap/AutoCompleteMap';

const Container = styled(FlexContainer)`
  padding: 30px 142px;
`

const projectAddressText = {
  title: "¿En dónde se ubica tu proyecto?",
  instructions: "Escribe la dirección exacta y asegúrate de que se muestre en el mapa",
}

const actionLabels = {
  primaryLabel: "Vamos al último paso",
  secondaryLabel: "Atrás",
}

const ProjectAddress = () => {
  const { title, instructions } = projectAddressText
  const { onboardingData, setOnboardingData, onNextStep, onPreviousStep } = useOnboardingContext()
  const validAddress = useMemo(() => onboardingData?.location?.lat && onboardingData?.location?.lng, [onboardingData?.location?.lat, onboardingData?.location?.lng])

  const handleNext = useCallback(() => {
    onNextStep()
  }, [onNextStep])

  const handleSelect = useCallback(({ address, lat, lng }) => {
    setOnboardingData({
      ...onboardingData,
      location: {
        address,
        lat,
        lng,
      }
    })
  }, [onboardingData, setOnboardingData])

  return (
    <PublicLayout
      primaryLabel={actionLabels.primaryLabel}
      primaryIcon={<ArrowForwardIcon />}
      onPrimaryClick={handleNext}
      secondaryLabel={actionLabels.secondaryLabel}
      onSecondaryClick={onPreviousStep}
      showHelpMessage
      currentStep={4}
      numberOfSteps={5}
      disableNextStep={!validAddress}
    >
      <Container vertical>
        <Typography variant='h2'>{title}</Typography>
        <Spacer vertical size={8} />
        <Typography variant='h5'>{instructions}</Typography>
        <Spacer vertical size={3} />
        <AutoCompleteMap onSelect={handleSelect} locationData={onboardingData?.location} />
      </Container>
    </PublicLayout>
  )
}

export default ProjectAddress
