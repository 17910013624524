import React, { useCallback } from 'react';
import { Toolbar, Button, Typography } from '@mui/material';
import {
  Download as DownloadIcon,
  Save as SaveIcon,
  Upload as UploadIcon,
} from '@mui/icons-material';
import { FlexContainer, Spacer } from '../../components';
import { Link } from 'react-router-dom';
import WithPermission from '../../components/WithPermission/WithPermission';
import { downloadFile, get, RESPONSE_TYPE } from '../../helpers/request';
import useSite from '../../hooks/useSite';
import { useMutation } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';

const downloadInventoryFn = async (siteId) => {
  const currentDate = new Date();
  const fileName = `Inventario_${currentDate.getDate()}-${
    currentDate.getMonth() + 1
  }-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.xlsx`;
  const res = await get(
    '/internal/alohub/inventory/download',
    { site_id: siteId },
    RESPONSE_TYPE.blob
  );
  await downloadFile(res, fileName);
};

const EnhancedTableToolbar = ({
  numSelected,
  isSaveDisabled,
  isEditing,
  onSave,
  onBulkEdit,
  onCancelEdit,
}) => {
  const { site } = useSite();
  const siteId = site?.site_id;

  const { mutate: downloadInventory, isLoading } =
    useMutation(downloadInventoryFn);

  const onDownload = useCallback(() => {
    if (siteId) downloadInventory(siteId);
  }, [siteId, downloadInventory]);

  return (
    <Toolbar>
      <FlexContainer fullWidth justified verticalCentered>
        <FlexContainer verticalCentered>
          {isEditing ? (
            <Button variant="outlined" color="secondary" onClick={onCancelEdit}>
              Cancelar Edición
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={onBulkEdit}
              disabled={numSelected === 0}
            >
              Edición en masa
            </Button>
          )}
          <Spacer size={2} />
          <Typography variant="caption">
            Tienes ({numSelected}) departamento{numSelected !== 1 ? 's' : ''}{' '}
            seleccionados
          </Typography>
        </FlexContainer>
        <WithPermission permissionPath={['project', 'inventory', 'upload']}>
          <Button
            component={Link}
            to="./upload"
            variant="outlined"
            color="primary"
            startIcon={<UploadIcon />}
          >
            Subir inventario desde Excel
          </Button>
        </WithPermission>
        <LoadingButton
          variant="outlined"
          color="primary"
          onClick={onDownload}
          startIcon={<DownloadIcon />}
          loading={isLoading}
          loadingPosition="start"
        >
          {isLoading ? 'Descargando' : 'Descargar en Excel'}
        </LoadingButton>
        {!isEditing && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={onSave}
            disabled={isSaveDisabled}
          >
            Guardar
          </Button>
        )}
      </FlexContainer>
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
