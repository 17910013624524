import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Card, CardActions, CardContent, CardMedia, IconButton, styled, TextField, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import Image from '../../components/Image/Image';
import { put } from '../../helpers/request';
import { API_UPDATE_ASSET_URL, truncateString } from '../../helpers/utils';

const StyledCard = styled(Card)({
  "&[data-disabled=true]": {
    opacity: 0.5,
  },
});

const IconContainer = styled(CardMedia)`
  background: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  height: ${({height}) => height ? `${height}px` : "178px"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 3rem;
    height: 3rem;
  }
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  column-gap: 1rem;
  justify-content: space-between;
  align-items: center;
`;

const StyledCardActions = styled(CardActions)`
  align-items: center;
  justify-content: center;
  flex-basis: min-content;
  padding: 0;
`;

const StyledImage = styled(Image)`
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  object-fit: cover;
`;

const AssetName = styled(Typography)`
  flex-basis: auto;
  overflow: hidden;
`;

const AssetCard = ({
  disabled,
  src,
  label,
  height,
  sx,
  assetId,
  setAssetId,
  assetType,
  assets,
  setAssets,
  editLabel,
  saveLabel,
  deleteAssetLabel,
  handleUpdate,
  handleDelete,
  assetMetadata }) => {
  const [ textValue, setTextValue ] = useState(label)
  const [ textIsEditable, setTextIsEditable ] = useState(false)

  const handleMetadataUpdate = useCallback(() => {
    handleUpdate(assetId, textValue)
  }, [assetId, handleUpdate, textValue])

  const handleEditText = useCallback(() => {
    setTextIsEditable(true)
  }, [setTextIsEditable])

  const handleApproveText = useCallback(() => {
    setTextIsEditable(false)
    handleMetadataUpdate()
    put(API_UPDATE_ASSET_URL, {
      asset_id: assetId,
      metadata: { ...assetMetadata, name: textValue }
    })
  }, [assetId, assetMetadata, handleMetadataUpdate, textValue])
  
  const onDelete = useCallback(() => {
    setAssetId(null)
    const result = assets.filter(item => item !== assetId);
    setAssets(result)
    handleDelete(assetType)
  }, [assetId, assets, assetType, handleDelete, setAssetId, setAssets])

  return (
    <StyledCard sx={sx} data-disabled={disabled}>
      {assetType !== "video" && 
        <StyledImage height={height} src={src} alt={label} />
      }
      {assetType === "video" &&
      <IconContainer component="div" height={height}>
        <PlayCircleOutlineIcon color="action" />
      </IconContainer>
      }
      <StyledCardContent>
        {textIsEditable ?
          <TextField
            autoFocus
            value={textValue}
            onChange={event => setTextValue(event.target.value)}
          />
          :
          <AssetName component="p" variant="h6">
            {truncateString(textValue, 10)}
          </AssetName>
        }
        <StyledCardActions>
          <IconButton
            aria-label= {!textIsEditable ? editLabel : saveLabel}
            onClick={!textIsEditable ? handleEditText : handleApproveText}
          >
            {!textIsEditable ? <EditIcon color="action" /> : <CheckIcon color="action" />}
          </IconButton>
          <IconButton aria-label={deleteAssetLabel} onClick={onDelete}>
            <DeleteIcon color="action" />
          </IconButton>
        </StyledCardActions>
      </StyledCardContent>
    </StyledCard>
  );
};

export default AssetCard;
