import { Drawer } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import ProjectsList from './ProjectsList/ProjectsList';
import ProjectDetails from './ProjectDetails/ProjectDetails';

const ProjectsManagementDrawer = ({
  projects,
  setProjects,
  ...drawerProps
}) => {
  const [view, setView] = useState('list');
  const [editingProjectId, setEditingProjectId] = useState(null);

  const closeEdit = useCallback(() => {
    setView('list');
    setEditingProjectId(null);
  }, []);

  const onAdd = useCallback(() => {
    setView('detail');
    setEditingProjectId(null);
  }, []);

  const onEdit = useCallback((id) => {
    setView('detail');
    setEditingProjectId(id);
  }, []);

  const updateProject = useCallback(
    (fields) => {
      const oldProjects = projects;
      delete oldProjects[`${editingProjectId}`];
      const nProjects = {
        ...oldProjects,
        [fields.name]: fields,
      };
      setProjects(nProjects);
      closeEdit();
    },
    [projects, setProjects, closeEdit, editingProjectId],
  );

  const createProject = useCallback(
    (fields) => {
      const nProjects = {
        ...projects,
        [fields.name]: fields,
      };
      setProjects(nProjects);
      closeEdit();
    },
    [projects, setProjects, closeEdit],
  );

  const deleteProject = useCallback(
    (id) => {
      const nProjects = {
        ...projects,
      };
      delete nProjects[id];
      setProjects(nProjects);
    },
    [projects, setProjects],
  );

  const isEditing = !!editingProjectId;

  const virtualFields = useMemo(
    () =>
      editingProjectId && {
        ...projects[editingProjectId],
        name: editingProjectId,
      },
    [projects, editingProjectId],
  );

  return (
    <Drawer
      {...drawerProps}
      onClose={view === 'list' ? drawerProps.onClose : undefined}
    >
      {view === 'list' && projects && (
        <ProjectsList
          projects={projects}
          onAdd={onAdd}
          onEdit={onEdit}
          onDelete={deleteProject}
          onClose={drawerProps.onClose}
        />
      )}
      {view === 'detail' && (
        <ProjectDetails
          fields={virtualFields}
          isEditing={isEditing}
          onSubmit={isEditing ? updateProject : createProject}
          onClose={closeEdit}
        />
      )}
    </Drawer>
  );
};

export default ProjectsManagementDrawer;
