import { Box, styled } from '@mui/material';
import CardMedia from './CardMedia';
import React from 'react';
import Image from '../../components/Image/Image';

const ChildrenContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#FFFFFF',
  opacity: 0,
  transition: theme.transitions.create('opacity'),
  '&:hover': { opacity: 0.9 },
}));

const HoverCardMedia = React.forwardRef(
  ({ children, component = Image, ...props }, ref) => {
    return (
      <Box sx={{ position: 'relative' }}>
        <CardMedia component={component} {...props} ref={ref} />
        <ChildrenContainer>{children}</ChildrenContainer>
      </Box>
    );
  },
);

export default HoverCardMedia;
