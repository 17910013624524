import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get } from '../../helpers/request';
import { flatTabs } from '../../helpers/tabsData';
import useSite from '../../hooks/useSite';
import PageLayout from '../../layouts/PageLayout';

export const FlatLayout = ({
  children,
  reloadLayout = true,
  setReloadLayout,
  menu,
  avoidRedirection,
}) => {
  const navigate = useNavigate();
  const { site } = useSite();
  const [loading, setLoading] = useState(true);
  const [tabs, setTabs] = useState(flatTabs);
  useEffect(() => {
    if (site?.site_id) {
      Promise.all([
        get(`/internal/alohub/integrations/flat/config/welcome-data`, {
          site_id: site.site_id,
        }),
        get(`/internal/alohub/integrations/flat/config/completed`, {
          site_id: site.site_id,
        }),
      ]).then(([welcomeData, completed]) => {
        let tabs = flatTabs;
        if (!welcomeData?.alohome_terms) {
          tabs.tabs[0].disabled = false;
          tabs.tabs[1].disabled = true;
          tabs.tabs[2].disabled = true;
          setTabs(tabs);
          navigate(`../${tabs.tabs[0].href}`, { replace: true });
        } else if (!!welcomeData?.alohome_terms && !completed) {
          tabs.tabs[0].disabled = true;
          tabs.tabs[1].disabled = false;
          tabs.tabs[2].disabled = true;
          setTabs(tabs);
          navigate(`../${tabs.tabs[1].href}`, { replace: true });
        } else {
          if (!avoidRedirection) {
            setTabs({ ...tabs, tabs: [{ ...tabs.tabs[1], disabled: false }] });
            navigate(`../${tabs.tabs[1].href}`, { replace: true });
          } else {
            tabs.tabs[0].disabled = true;
            tabs.tabs[1].disabled = true;
            tabs.tabs[2].disabled = false;
            setTabs(tabs);
          }
        }
        setLoading(false);
      });
      if (setReloadLayout) setReloadLayout(false);
    }
  }, [
    site?.site_id,
    navigate,
    reloadLayout,
    setReloadLayout,
    avoidRedirection,
  ]);
  return loading ? null : (
    <PageLayout sticky menu={menu} links={tabs}>
      {children}
    </PageLayout>
  );
};
