import React, { useCallback } from 'react';
import { getKeysAsPath } from '../../helpers/data';
import CheckItem from './CheckItem';
import Section from './Section';

export const ADVERTISING_URL_SLUG = 'advertising';

export const ADVERTISING_NOM_PATH = 'advertising';

export const ADVERTISING_CHECKLIST_IDS = [
  'Art_5-MissleadingContent',
  'Art_5-IdentifyPricesUnits',
  'Art_5-IdentifyPromotionsUnits',
  'Art_5-PromotionEndDate',
  'Art_5-ExaggeratedText',
];

const Advertising = ({ getSectionData, setSectionData }) => {
  const getValue = useCallback(
    (id) => getSectionData(getKeysAsPath(['checkList', id])),
    [getSectionData]
  );

  const getHandleValueChange = useCallback(
    (id) => (path, value) =>
      setSectionData(getKeysAsPath(['checkList', id, path]), value),
    [setSectionData]
  );

  return (
    <Section title="Publicidad" id="advertising">
      <CheckItem
        value={getValue('Art_5-MissleadingContent')}
        setValuePath={getHandleValueChange('Art_5-MissleadingContent')}
        title="Aclarar en los renders qué sí se incluye y qué no (mobiliario, decoración, etc.). La información o publicidad que el proveedor difunda por cualquier medio o forma, debe ser veraz, comprobable, clara y exenta de textos, diálogos, sonidos, imágenes, marcas y otras descripciones que puedan inducir a error o confusión por engañosas o abusivas."
        NOMSection="Art. 5"
      />
      <CheckItem
        value={getValue('Art_5-IdentifyPricesUnits')}
        setValuePath={getHandleValueChange('Art_5-IdentifyPricesUnits')}
        title="Al incluir precios en la publicidad, aclarar a qué unidades corresponden las cifras mostradas"
        NOMSection="Art. 5"
      />
      <CheckItem
        value={getValue('Art_5-IdentifyPromotionsUnits')}
        setValuePath={getHandleValueChange('Art_5-IdentifyPromotionsUnits')}
        title="Al incluir promociones en la publicidad, aclarar a cuántas y cuáles unidades aplican"
        NOMSection="Art. 5"
      />
      <CheckItem
        value={getValue('Art_5-PromotionEndDate')}
        setValuePath={getHandleValueChange('Art_5-PromotionEndDate')}
        title="Aclarar en la publicidad las vigencias de las promociones"
        NOMSection="Art. 5"
      />
      <CheckItem
        value={getValue('Art_5-ExaggeratedText')}
        setValuePath={getHandleValueChange('Art_5-ExaggeratedText')}
        title="Evitar en la publicidad textos exagerados, comparativos o superlativos: “el mejor”, “el único”... (En caso de incluirlos, deberán poder demostrarse.)"
        NOMSection="Art. 5"
      />
    </Section>
  );
};

export default Advertising;
