import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPermission, PERMISSIONS } from '../../helpers/permissions';
import { DevelopmentsTabs } from '../../helpers/tabsData';
import useProfile from '../../hooks/useProfile';
import useSite from '../../hooks/useSite';
import PageLayout from '../../layouts/PageLayout';
import DevelopmentList from './DevelopmentList';

const DevelopmentView = () => {
  const profile = useProfile();
  const navigate = useNavigate();
  const { setSite } = useSite();
  const selectedSite = useSelector((state) => state.site);

  const handleAddProject = useCallback(
    () => {
      navigate('/new-project')
    },
    [navigate],
  );

  const handleGoToShowroom = useCallback(
    (newSite) => {
      setSite(newSite);
      navigate('/digital-showroom/editor');
    },
    [navigate, setSite],
  );

  const handleViewSiteDetails = useCallback(
    (site) => {
      navigate(site.site_id.toString(), {
        state: site,
      });
    },
    [navigate],
  );

  const showEditButton = useMemo(() => {
    if(!selectedSite) return false;

    const permission = getPermission(
      ['digital_showroom', 'editor'],
      selectedSite?.permissions,
    );
    if (permission !== PERMISSIONS.hidden) {
      return true;
    }
    return false;
  }, [selectedSite]);

  return (
    <PageLayout links={DevelopmentsTabs}>
      <DevelopmentList
        showEditButton={showEditButton}
        sites={profile?.sites}
        onEditShowroom={handleGoToShowroom}
        onSiteDetails={handleViewSiteDetails}
        onAddProject={handleAddProject}
      />
    </PageLayout>
  );
};

export default DevelopmentView;
