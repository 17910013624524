import React, { useCallback, useState } from 'react'
import EditPlan from './EditPlan'
import ViewPlan from './ViewPlan'

const Plan = ({ previewUnit, plan, onEdit, onDelete, onConfirm }) => {
  const [isEditing, setIsEditing] = useState(false)

  const handleToggleEditing = useCallback(() => setIsEditing(!isEditing), [isEditing])

  return (
    <>
      {isEditing
        ? (
          <EditPlan
            plan={plan}
            onClose={handleToggleEditing}
            onConfirm={onConfirm}
          />
        ) : (
          <ViewPlan
            previewUnit={previewUnit}
            plan={plan}
            onDelete={onDelete}
            onEdit={handleToggleEditing}
          />
        )
      }
    </>
  )
}

export default Plan
