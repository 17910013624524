import { useMutation } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { getAssetUrlFromId, getImageUrlFromId } from "../helpers/assets";
import { post } from "../helpers/request";
import { getExtType } from "../helpers/utils";
import useSite from "./useSite";

const acceptByType = {
  video: 'video/*',
  image:
    'image/bmp, image/gif, image/jpg, image/jpeg, image/png, image/tif, image/tiff, image/webp',
  media: 'image/bmp, image/gif, image/jpg, image/jpeg, image/png, image/tif, image/tiff, image/webp, video/*'
};

const createSignedUrlFn = async ({ files, siteId, type, tags = [] }) =>
  Promise.all(
    files.map((file) => {
      const fileType = type === "media" ? getExtType(file.name) : type
      return post(
        "/internal/developer/v1/assets",
        { tags, type: fileType, mime_type: file.type, metadata: { fileName: file.name } },
        siteId
      )
    })
  );

const uploadFilesFn = async ({ uploads, onProgress }) => {
  const total = uploads.reduce((acc, { file }) => acc + file.size, 0);
  const loaded = new Array(uploads.length);
  return Promise.all(
    uploads.map(
      ({ file, url }, index) =>
        new Promise((resolve) => {
          const request = new XMLHttpRequest();
          request.open("PUT", url);
          request.setRequestHeader("Content-Type", file.type);

          request.upload.addEventListener("progress", (e) => {
            loaded[index] = e.loaded;
            const totalLoaded = loaded.reduce((prev, acc) => prev + acc, 0);
            onProgress?.({ loaded: totalLoaded, total });
          });
          request.addEventListener("load", () => resolve(request));

          request.send(file);
        })
    )
  );
};

/**
 * Handles assets upload process on `type=file` `input` change. Can specify the
 * `type` of asset, associted `tags` array and a handler for uploaded process
 * completed `onUploaded`.
 *
 * Returns the props for the input element (`inputProps`), `assets` array that
 * is populated when the upload process is completed, and `progress` object
 * containing loaded and total bytes of upload when uploading (null before and
 * after upload).
 * @typedef {{ file: File, assetId: string, name: string, url: string }} Asset
 * @param {{
 * 	type?: "video" | "image" | "media",
 *  tags?: any[],
 * 	onUploaded: function({ assets: Asset[] }): void,
 * }} param0
 * @returns {{
 *  assets: Asset[],
 *  inputProps: any,
 *  progress: { loaded: number, total: number }
 * }}
 */
const useAssetsUpload = ({ type, tags, onUploaded }) => {
  const { site } = useSite();

  const [assets, setAssets] = useState([]);
  const [progress, setProgress] = useState(null);

  const onSucessUploadFiles = useCallback(
    (_, { uploads }) => {
      const nAssets = uploads.map(({ file, assetId }) => {
        const isSvg = file.name.endsWith(".svg");
        const fileType = getExtType(file.name)
        return {
          file,
          assetId,
          name: file.name,
          type: fileType,
          url:
            fileType === 'image' && !isSvg
              ? getImageUrlFromId(assetId)
              : getAssetUrlFromId(assetId),
        };
      });
      setAssets(nAssets);
      setProgress(null);
      onUploaded?.({ assets: nAssets });
    },
    [onUploaded]
  );

  const { mutate: mutateUploadFiles } = useMutation(uploadFilesFn, {
    onSuccess: onSucessUploadFiles,
  });

  const onSuccessSignedUrls = useCallback(
    (data, { files }) => {
      const uploads = files.map((file, index) => ({
        file,
        url: data[index].signed_url,
        assetId: data[index].asset_id,
      }));
      mutateUploadFiles({
        uploads,
        onProgress: setProgress,
      });
    },
    [mutateUploadFiles]
  );

  const { mutate: mutateCreateSignedUrl } = useMutation(createSignedUrlFn, {
    onSuccess: onSuccessSignedUrls,
  });

  const onChange = useCallback(
    (e) => {
      const files = Array.from(e.target.files);
      mutateCreateSignedUrl({ files, siteId: site?.site_id, type, tags });
    },
    [mutateCreateSignedUrl, site?.site_id, type, tags]
  );

  const inputProps = useMemo(
    () => ({
      type: "file",
      accept: acceptByType[type],
      onChange,
    }),
    [type, onChange]
  );

  return {
    inputProps,
    assets,
    progress,
  };
};

export default useAssetsUpload;
