import { NearMe as NearMeIcon, Save as SaveIcon } from '@mui/icons-material';
import {
  Alert,
  Box,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Snackbar,
  styled,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import COFlag from '../../assets/svgs/flags/CO.svg';
import BRFlag from '../../assets/svgs/flags/BR.svg';
import MXFlag from '../../assets/svgs/flags/MX.svg';
import USFlag from '../../assets/svgs/flags/US.svg';
import { get, post, put } from '../../helpers/request';
import { projectTabs } from '../../helpers/tabsData';
import useSite from '../../hooks/useSite';
import PageLayout from '../../layouts/PageLayout';
import { LoadingButton } from '@mui/lab';
import { useMemo } from 'react';
import useSiteActiveStatusAction from '../../hooks/useSiteActiveStatusAction';

const Flag = styled('img')({ width: 24, height: 24 });

const currencyFlag = {
  COP: COFlag,
  BRL: BRFlag,
  MXN: MXFlag,
  USD: USFlag,
};

const CURRENCIES = Object.keys(currencyFlag);

const PublishButton = styled(LoadingButton)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  background: theme.palette.secondary.light,
  '&:hover': {
    background: 'var(--color-secondary-gradient)',
  },
}));

const SETTINGS_CATEGORY = 'general';
const SETTINGS_SUBCATEGORY = 'currency';

const getSettingsFn = ({ queryKey }) =>
  get(
    `/internal/settings/v1/settings?category=${SETTINGS_CATEGORY}&subcategory=${SETTINGS_SUBCATEGORY}`,
    { site_id: queryKey[1] }
  );

const setSettingsFn = ({ siteId, value }) =>
  put(
    `/internal/settings/v1/settings/${SETTINGS_CATEGORY}/${SETTINGS_SUBCATEGORY}`,
    value,
    siteId
  );

const publishFn = async ({ siteId, value }) => {
  await setSettingsFn({ siteId, value });
  return post(
    '/internal/developer/v1.1/files',
    {
      changes: [
        {
          path: 'dual_currency',
          value: value.dualCurrency,
        },
      ],
      stage: ['draft', 'production'],
      fileName: 'inventory',
    },
    siteId
  );
};

const CurrencyView = () => {
  const [showSuccessSave, setShowSuccessSave] = useState(false);
  const [showSuccessPublish, setShowSuccessPublish] = useState(false);
  const [showError, setShowError] = useState(false);

  const onCloseSuccessSave = useCallback(() => setShowSuccessSave(false), []);
  const onCloseSuccessPublish = useCallback(
    () => setShowSuccessPublish(false),
    []
  );
  const onCloseError = useCallback(() => setShowError(false), []);

  const [isActive, setIsActive] = useState(null);
  const [savedConfig, setSavedConfig] = useState(null);
  const {
    control,
    register,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      secondary: 'USD',
      rate: 20,
    },
  });

  const { site } = useSite();
  const siteId = site?.site_id;

  const onLoaded = useCallback(
    (data) => {
      const { main, secondary, rate } = data?.dualCurrency || {};
      if (main) setValue('main', main || '');
      if (secondary) setValue('secondary', secondary || '');
      if (rate) setValue('rate', rate);
      setIsActive(!!secondary);
      setSavedConfig({ main, secondary, rate });
    },
    [setValue]
  );

  const { isLoading: isLoadingSettings, refetch: refetchSettings } = useQuery(
    ['CURRENCY_SETTINGS', siteId],
    getSettingsFn,
    {
      enabled: !!siteId,
      onSuccess: onLoaded,
      refetchOnWindowFocus: false,
    }
  );

  const onSuccessSetSettings = useCallback(() => {
    refetchSettings();
    setShowSuccessSave(true);
  }, [refetchSettings]);

  const { mutate: setSettings, isLoading: isUpdating } = useMutation(
    setSettingsFn,
    { onSuccess: onSuccessSetSettings, onError: () => setShowError(true) }
  );

  const onSuccessPublish = useCallback(() => {
    refetchSettings();
    setShowSuccessPublish(true);
  }, [refetchSettings]);

  const { mutate: mutatePublish, isLoading: isPublishing } = useMutation(
    publishFn,
    {
      onSuccess: onSuccessPublish,
      onError: () => setShowError(true),
    }
  );

  const publish = useSiteActiveStatusAction(mutatePublish);

  const main = watch('main');
  const secondary = watch('secondary');
  const rate = watch('rate');

  useEffect(() => {
    if (main === secondary) setValue('secondary', '');
  }, [main, secondary, setValue]);

  const handleSave = useCallback(
    ({ main, secondary, rate }) =>
      setSettings({
        siteId,
        value: {
          dualCurrency: isActive
            ? { main, secondary, rate: +rate }
            : { main, secondary: null, rate: null },
        },
      }),
    [setSettings, isActive, siteId]
  );

  const handlePublish = useCallback(
    ({ main, secondary, rate }) =>
      publish({
        siteId,
        value: {
          dualCurrency: isActive
            ? { main, secondary, rate: +rate }
            : { main, secondary: null, rate: null },
        },
      }),
    [publish, isActive, siteId]
  );

  const configHasChanged = useMemo(
    () =>
      savedConfig &&
      (!isActive ||
        savedConfig?.main !== main ||
        savedConfig?.secondary !== secondary ||
        +savedConfig?.rate !== +rate),
    [savedConfig, isActive, main, secondary, rate]
  );

  const isLoading = isLoadingSettings || isUpdating || isPublishing;

  return (
    <>
      <Snackbar
        open={showSuccessSave}
        autoHideDuration={6_000}
        onClose={onCloseSuccessSave}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Alert severity="success" onClose={onCloseSuccessSave}>
          Configuración guardada
        </Alert>
      </Snackbar>
      <Snackbar
        open={showSuccessPublish}
        autoHideDuration={6_000}
        onClose={onCloseSuccessPublish}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Alert severity="success" onClose={onCloseSuccessPublish}>
          Configuración guardada y publicada
        </Alert>
      </Snackbar>
      <Snackbar
        open={showError}
        autoHideDuration={6_000}
        onClose={onCloseError}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Alert severity="error" onClose={onCloseError}>
          Oops. Algo ha salido mal. Por favor inténtalo de nuevo.
        </Alert>
      </Snackbar>
      <PageLayout
        links={projectTabs}
        loading={isLoadingSettings}
        menu={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <LoadingButton
              startIcon={<SaveIcon />}
              onClick={() => handleSubmit(handleSave)()}
              disabled={isLoading || !configHasChanged}
              loading={isUpdating}
              loadingPosition="start"
            >
              Guardar
            </LoadingButton>
            <PublishButton
              variant="contained"
              startIcon={<NearMeIcon />}
              onClick={() => handleSubmit(handlePublish)()}
              disabled={isLoading}
              loading={isPublishing}
              loadingPosition="start"
            >
              Publicar
            </PublishButton>
          </Box>
        }
      >
        {!isLoadingSettings && (
          <>
            <Box sx={{ maxWidth: 435, width: '1' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: 500 }}>
                  Cambio de moneda
                </Typography>
                <FormControlLabel
                  labelPlacement="start"
                  label={isActive ? 'Activo' : 'Desactivado'}
                  control={
                    <Switch
                      checked={isActive}
                      onChange={(_, v) => setIsActive(v)}
                    />
                  }
                />
              </Box>
              <Typography>
                {isActive
                  ? 'Establece cuál sería la moneda principal, cuál la secundaria y la tasa de cambio aplicable a las propiedades de tu proyecto.'
                  : 'Si deseas comercializar tu proyecto con una moneda distinta al peso mexicano ($MXN), activa esta opción.'}
              </Typography>
            </Box>
            {isActive && (
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 2fr',
                  gap: 1,
                  mt: 2,
                  maxWidth: 'sm',
                  width: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 1,
                  }}
                >
                  <Controller
                    control={control}
                    name="main"
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        label="Moneda principal"
                        value={value}
                        onChange={onChange}
                        select
                        variant="standard"
                        sx={{ maxWidth: 130 }}
                        fullWidth
                      >
                        {CURRENCIES.map((currency) => (
                          <MenuItem key={currency} value={currency}>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              {currencyFlag[currency] && (
                                <Flag src={currencyFlag[currency]} alt="" />
                              )}
                              <span>{currency}</span>
                            </Box>
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 2,
                  }}
                >
                  <Controller
                    control={control}
                    name="secondary"
                    rules={{ required: 'Campo requerido' }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        label="Moneda secundaria"
                        value={value}
                        onChange={onChange}
                        select
                        variant="standard"
                        sx={{ maxWidth: 130 }}
                        fullWidth
                        error={!!errors.secondary}
                        helperText={errors.secondary?.message || ' '}
                      >
                        {CURRENCIES.filter((c) => c !== main).map(
                          (currency) => (
                            <MenuItem key={currency} value={currency}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                }}
                              >
                                {currencyFlag[currency] && (
                                  <Flag src={currencyFlag[currency]} alt="" />
                                )}
                                <span>{currency}</span>
                              </Box>
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    )}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      gap: 1,
                      width: 1,
                    }}
                  >
                    <TextField
                      label="Define la tasa de cambio"
                      value={1}
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            {secondary}
                          </InputAdornment>
                        ),
                      }}
                      helperText=" "
                      variant="standard"
                    />
                    <Typography
                      component="span"
                      sx={{ fontWeight: 'bold', mb: 3 }}
                    >
                      =
                    </Typography>
                    <TextField
                      variant="standard"
                      inputProps={register('rate', {
                        required: 'Campo requerido',
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          message: 'Número inválido',
                        },
                      })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">{main}</InputAdornment>
                        ),
                      }}
                      error={!!errors.rate}
                      helperText={errors.rate?.message || ' '}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </>
        )}
      </PageLayout>
    </>
  );
};

export default CurrencyView;
