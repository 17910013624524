export const sections = [
  {
    type: 'transparency-and-legal-certainty',
    title: 'Transparencia y certeza legal',
    content:
      'Con el fin de protegerte, este showroom digital se apega a los requisitos de la NOM247 en México: la información aquí mostrada (precios, medidas, características...) deberá ser respetada en todo el proceso de compra de tu nueva propiedad.',
    registration_number: {
      title: 'Número de registro PROFECO',
      badge: 'En proceso de revisión'
    },
    bottom_links: [
      {
        content:
          'Alohome ayuda a los desarrolladores inmobiliarios a cumplir la NOM247. [Conoce más aquí]!(external, https://alohome.io)',
      },
      {
        content:
          'Puedes consultar los contratos de adhesión registrados en PROFECO. [Conoce más aquí]!(external,https://rcal.profeco.gob.mx/Consulta.jsp)',
      },
    ],
    downloadables: [
      {
        title: 'Contrato de adhesión [:cotizacion:]',
        files: 'custom_data.NOM247.legal.adhesionContractsPROFECO.files',
      },
      {
        title: 'Contrato de propiedad [:cotizacion:]',
        files: 'custom_data.NOM247.legal.propertyAccreditation.files',
      },
      {
        title: 'Derechos de consumidores [:cotizacion:]',
        files: 'custom_data.NOM247.legal.consumerRights.files',
      },
    ],
  },
  {
    type: "transparency-construction-progress-section",
    id: "default-transparency-construction-progress-section",
    hubid: "[transparency-construction-progress-section]",
    title: "[:building_2:] Avance de obra",
    content: "Conforme avanza la construcción del proyecto, aquí encontrarás la actualización de cada una etapas.\nTambién tendrás un histórico, en imágenes, de los progresos de la obra.",
    className: "has-gallery full-height icon-bullets section_with_neutral_background",
    gallery_title: "Avance del proyecto",
    operator: "isNull",
    disabled: "custom_data.work_progress.enabled"
  },
  {
    type: 'permits-licenses-section',
    id: 'default-permits-licenses-section',
    hubid: '[permits-licenses-section]',
    title: '[:cotizacion:] Permisos, licencias y garantías',
    content:
      'Por disposición oficial, tienes derecho a consultar de forma física o digital las licencias, permisos, garantías y autorizaciones vigentes que las autoridades brindaron al desarrollador del proyecto.\nEsto con el fin de darte mayor certeza jurídica sobre las condiciones del inmueble y saber cómo actuar en caso de una emergencia o desastre.\n**¿Quieres más información sobre los permisos y licencias?**',
    call_to_action: {
      label: 'Contáctanos',
      /** TODO: on template read dynamically the page slug */
      href: `/transparencia#default-transparency-contact-section`,
    },
    download_cta: 'Descargar [:cotizacion:]',
    see_more_label: 'Ver más [:chevron_down:]',
    see_less_label: 'Ver menos [:chevron_up:]',
  },
  {
    type: 'planes-section',
    id: 'default-planes-section',
    hubid: '[planes-section]',
    title: '[:plane:] Planos',
    content:
      'Como cliente, tienes derecho a conocer, física o digitalmente, los planos estructurales, arquitectónicos y de instalaciones del inmueble.\nEsta información ayuda a hacer más transparente la compra de tu propiedad, conociendo la distribución del espacio, las colindancias, los acabados, servicios y más.\n**¿Quieres más información sobre los planos?**',
    call_to_action: {
      label: 'Contáctanos',
      /** TODO: on template read dynamically the page slug */
      href: `/transparencia#default-transparency-contact-section`,
    },
    download_cta: 'Descargar [:cotizacion:]',
    see_more_label: 'Ver más [:chevron_down:]',
    see_less_label: 'Ver menos [:chevron_up:]',
  },
  {
    type: 'slideshow-section',
    id: 'commercial_team',
    operator: 'isNull',
    disabled: 'custom_data.team[0]',
    slide_type: 'card-cover',
    slide_width: 295,
    items_to_show: 2.5,
    className: 'carousel_with_background',
    classname_carousel: 'badge_slider_container',
    cards: 'custom_data.team',
    title: 'Conoce a nuestro equipo comercial',
    content:
      'Te presentamos a los asesores autorizados para comercializar este proyecto',
    data: {
      item_category_data: 'custom_data.team[card]',
      item_category: 'custom_data.team[card]',
    },
    card_template: {
      template: '1fr',
      desktop_template: '1fr',
      className: 'card card__badge_container',
      grid: [
        {
          type: 'row',
          className: 'card__badge_column',
          columns: [
            {
              type: 'image',
              className: 'card__avatar_image',
              width: '100%',
              height: '100%',
              object_fit: 'cover',
              image_source: '{{item.avatar}}',
            },
            {
              type: 'paragraph',
              className: 'card__content_dark',
              content:
                '##[.title-2] {{item.name}}\n[[.subtitle_2]] {{custom_data.agents_labels[item.type]}}',
            },
          ],
        },
        {
          type: 'paragraph',
          operator: '===',
          condition: '',
          disabled: '{{item.valid_until}}',
          content:
            '##[.helper-text] Válido hasta: {{item.valid_until | date(day:"numeric", month:"numeric", year:"numeric")}}',
        },
        {
          type: "paragraph",
          operator: "!==",
          condition: "",
          disabled: "{{item.valid_until}}",
          content: ""
        },
        {
          type: "row",
          className: "agents-call-to-action-rows",
          columns: [
            {
              type: "call-to-action",
              label: "[:phone:] Llamar",
              containerClassname: "bottom_callback",
              variant: "linkPrimary",
              operator: "===",
              condition: "",
              disabled: "{{item.phone}}",
              href: "tel:{{item.phone}}"
            },
            {
              type: "column",
              className: "horizontal_separator",
              operator: "===",
              condition: "",
              disabled: "{{item.phone}}"
            },
            {
              type: "call-to-action",
              label: "[:mail:] Enviar email",
              containerClassname: "bottom_callback",
              variant: "linkPrimary",
              operator: "===",
              condition: "",
              disabled: "{{item.email}}",
              href: "mailto:{{item.phone}}"
            }
          ]
        },
      ],
    },
    navigation: {
      hide_on_list_edges: true,
      previous: { icon: '[:arrow_left:]', aria_label: 'Paso anterior' },
      next: { icon: '[:arrow_right:]', aria_label: 'Paso siguiente' },
    },
  },
  {
    type: 'physical-documents-section',
    hubid: '[physical-documents-section]',
    title:
      '[:levels:] Visita las instalaciones físicas del proyecto y conoce los permisos, licencias, planos y más',
    disclaimer:
      '*En cumplimiento con la NOM247 los documentos pueden ser presentados en formato físico o digital',
    date_update:
      ' Última actualización, {{ custom_data.NOM247.updatedAt | date(day:"numeric", month:"long", year:"numeric", hour:"numeric", minute: "numeric", hourCycle: "h12", localeMatcher: "lookup") | capitalize}}',
    see_more_label: 'Ver más [:chevron_down:]',
    see_less_label: 'Ver menos [:chevron_up:]',
    check_list: [
      {
        id: 'contractsPROFECO',
        icon: '[:cotizacion:]',
        label: 'Contrato PROFECO',
      },
      {
        id: 'licensesAndPermits',
        icon: '[:verified2:]',
        label: 'Permisos y licencias',
      },
      { id: 'plans', icon: '[:map:]', label: 'Planos técnicos' },
      { id: 'model', icon: '[:home:]', label: 'Maqueta del proyecto' },
      {
        id: 'quote',
        icon: '[:dollar:]',
        label: 'Cotizadores y planes de pago',
      },
      {
        id: 'propertyAccreditation',
        icon: '[:building:]',
        label: 'Acreditación de la propiedad',
      },
      {
        id: 'buyerWarranties',
        icon: '[:museum:]',
        label: 'Garantías',
      },
      {
        id: 'policy',
        icon: '[:users:]',
        label: 'Política de no discriminación',
      },
    ],
    padding_y: '80px 20px',
  },
  {
    type: 'transparency-contact-section',
    id: 'default-transparency-contact-section',
    hubid: '[transparency-contact-section][contact-methods]',
    contact_title: 'Atención, quejas y reclamaciones',
    contact_methods: {
      office_hours: {
        close: 'Cerrado',
        hours: {
          monday: {
            label: 'Lunes',
          },
          tuesday: {
            label: 'Martes',
          },
          wednesday: {
            label: 'Miércoles',
          },
          thursday: {
            label: 'Jueves',
          },
          friday: {
            label: 'Viernes',
          },
          saturday: {
            label: 'Sábado',
          },
          sunday: {
            label: 'Domingo',
          },
        },
      },
      phone: {},
      whatsapp: {
        message_template:
          'Hola, tengo una pregunta sobre {{legal.projectDomain}}',
      },
      email: {},
      address: {},
    },
    contact_form: {
      contact: {
        title:
          'Conoce todo sobre la transparencia y certeza legal del proyecto, ¡contáctanos!',
        form: {
          fields: [
            {
              type: 'form-question',
              label: 'Nombre(s)',
              component: 'Input',
              control: 'text',
              id: 'clientName',
              required: true,
              variant: 'standard',
            },
            {
              type: 'form-question',
              label: 'Apellido(s)',
              component: 'Input',
              control: 'text',
              id: 'clientLastNames',
              required: true,
              variant: 'standard',
            },
            {
              type: 'form-question',
              label: 'Número de celular',
              component: 'Input',
              control: 'tel',
              id: 'clientPhone',
              required: true,
              variant: 'standard',
            },
            {
              type: 'form-question',
              label: 'Email',
              component: 'Input',
              control: 'email',
              id: 'clientEmail',
              required: true,
              variant: 'standard',
            },
            {
              type: 'form-question',
              label: 'Compártenos tus comentarios',
              component: 'Textarea',
              id: 'clientComment',
              required: true,
              variant: 'standard',
            },
            {
              type: 'form-question',
              label: '¿Prefieres que te llamen o que te escriban por WhatsApp?',
              component: 'RadioGroup',
              control: 'RadioButton',
              id: 'contactMethod',
              required: true,
              options: [
                {
                  value: 'Llamada',
                  name: 'contactMethod',
                  label: 'Llamada',
                  variant: 'inline',
                },
                {
                  value: 'WhatsApp',
                  name: 'contactMethod',
                  label: 'WhatsApp',
                  variant: 'inline',
                },
              ],
            },
            {
              type: 'form-question',
              label:
                'Acepto los [términos y condiciones]!(action,open-terms-and-conditions) y la [política de privacidad]!(action,open-privacy-policy).',
              component: 'Checkbox',
              control: 'checkbox',
              variant: 'flex',
              id: 'clientAgreesToTerms',
              required: true,
            },
            {
              type: 'call-to-action',
              label: 'Enviar',
              component: 'ButtonCTA',
              control: 'button',
              turnIntoLoader: true,
              id: 'sendButton',
            },
          ],
        },
      },
      success: {
        avatar: {
          image_source: '/images/e4b2e0c0-ff1f-11ec-b9af-f1366b5b7af8',
        },
        title: '¡Recibimos tus solicitud!',
        content:
          'Nuestro equipo alistará la información legal para compartírtela de forma digital o física en nuestro punto de venta.',
      },
      error: {
        avatar: {
          image_source: '/images/e4b2e0c0-ff1f-11ec-b9af-f1366b5b7af8',
        },
        title: 'Hubor un error.',
        content: 'Lamentablemente hubo un error al enviar los datos.',
      },
    },
  },
];

export const customData = {
  agents_labels: {
    sales_supervisor: 'Supervisor',
    sales_advisor: 'Asesor',
  },
};

export const footer = {
  delete_personal_data: {
    content: 'Quiero eliminar mi información personal',
  },
  contact_title: 'Contáctanos',
};
