import { Save as SaveIcon } from "@mui/icons-material";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText
} from "@mui/material";
import React from "react";

const DiscardChangesDialog = ({
  onDiscard,
  onSave,
  onClose,
  text = "¡Espera! Antes de salir, ¿quieres guardar los cambios más recientes?",
  discardText = "Salir sin guardar",
  saveText = "Guardar y salir",
  saveIcon = <SaveIcon />,
  ...dialogProps
}) => {
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      PaperProps={{ sx: { p: 4 } }}
      onClose={onClose}
      {...dialogProps}
    >
      <DialogContent>
        <DialogContentText variant="h6" component="p">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onDiscard}>
          {discardText}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSave}
          startIcon={saveIcon}
        >
          {saveText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiscardChangesDialog;
