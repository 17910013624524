import { WidgetContainer, WidgetTitle, Spacer, CaptionedData, PercentageIndicator, FlexContainer } from "../../components"
import { useEffect, useState, Fragment } from "react"
import { get } from '../../helpers/request'
import { useSelector } from "react-redux"
import useScreenWidthBelowEqual from "../../hooks/useScreenWidthBelowEqual"

const TITLE = "RESERVAS EN ESTE MES"
const TOOLTIP = "Muestra el total de unidades reservadas desde el día 1 del mes actual hasta la fecha"

const ReservedUnitsThisMonth = () => {
  const [data, setData] = useState()
  const selectedSite = useSelector(state => state.site)
  const isBelowEqual1280 = useScreenWidthBelowEqual(1280)

  useEffect(() => {
    if (selectedSite) {
      get('/internal/developer/v3/dashboard/reservedUnits', { site_id: selectedSite.site_id })
        .then(result => setData(result))
        .catch(error => {
          console.error(error)
        })
    }
  }, [selectedSite])

  return (
    <WidgetContainer width={isBelowEqual1280 ? 5 : 1}>
      {data ? (<Fragment>
        <WidgetTitle title={TITLE} tooltip={TOOLTIP} />
        <PercentageIndicator percentage={data && data.percentage_against_previous_range} tooltip="Comparado con el total de unidades reservadas en el mismo rango de días del mes anterior." />
        <Spacer vertical size={3} />
        <FlexContainer centered vertical fullHeight verticalCentered>
          <CaptionedData data={data && data.reserved_units_current_range} dataSize="large" bottomCaption="Unidades" />
        </FlexContainer>
        <Spacer vertical size={2} />
      </Fragment>)
        : <></>}
    </WidgetContainer>
  )
}

export default ReservedUnitsThisMonth
