import { FlatLayout } from './Layout';
import FlatLogo from '../../assets/images/flat-logo-2.png';
import { Box, Button, Typography } from '@mui/material';
import { Spacer } from '../../components';
import { useLocation } from 'react-router-dom';

const CompletedFlat = () => {
  const { state } = useLocation();
  return (
    <FlatLayout avoidRedirection>
      <Spacer vertical size={8} />
      <Box
        component="img"
        src={FlatLogo}
        alt="flat-logo"
        sx={{ maxWidth: 200, display: 'block' }}
      />
      <Spacer vertical size={3} />
      <Typography variant="h5">
        Hurra! Tu proyecto {state.completed ? 'se actualizara' : 'estará publicado'} en
        2 o 3 días
      </Typography>
      <Spacer vertical size={3} />
      <Typography variant="body1">
        Ahora mismo nuestro equipo está validando la información para que tu
        proyecto pueda estar visible lo antes posible en Flat.mx
      </Typography>
      <Spacer vertical size={3} />
      <Typography variant="body1">
        Por el momento, <b>no es necesario realizar alguna otra acción</b>, te
        informaremos cuando tus unidades ya estén publicadas. ¡Seguimos en
        contacto!
      </Typography>
      <Spacer vertical size={3} />
      <Button
        rel="noreferrer"
        variant="contained"
        color="primary"
        href="https://www.flat.mx"
      >
        VISITAR FLAT.MX
      </Button>
    </FlatLayout>
  );
};

export default CompletedFlat;
