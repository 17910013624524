import { Box, TextField } from "@mui/material";
import React from "react";
import Section from "./Section";

const NAME_MAX_LENGTH = 20;
const ANNUAL_CAPITAL_GAIN_MAX_LENGTH = 3;

const VIEW_TEXTS = {
  title: "Datos de la tipología",
  labelName: "Nombre tipología",
  labelPrice: "Precio de la tipología",
  labelFullUpfrontPaymentPrice: "Precio al contado",
  labelAnnualCapitalGain: "Plusvalía anual",
}

const MainInformation = ({
  currency = "MXN",
  name = { es: "" },
  price = { [currency]: "" },
  fullUpfrontPaymentPrice = { [currency]: "" },
  annualCapitalGain = "",
  setProperty,
}) => (
  <Section title={VIEW_TEXTS.title}>
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, 300px)",
        justifyContent: "space-between",
        gap: 4,
      }}
    >
      <TextField
        name="name"
        label={VIEW_TEXTS.labelName}
        value={name.es}
        onChange={(e) =>
          setProperty("name", {
            ...name,
            es: e.target.value.substring(0, NAME_MAX_LENGTH),
          })
        }
        variant="standard"
        helperText={`${name.es.length}/${NAME_MAX_LENGTH}`}
      />
      <TextField
        name="price"
        label={VIEW_TEXTS.labelPrice}
        value={price[currency]?.toLocaleString()}
        onChange={({ target: { value } }) => {
          if (!value) setProperty("price", { ...price, [currency]: "" });
          const numericValue = parseFloat(value.replace(/\D/g, ""));
          if (!Number.isNaN(numericValue))
            setProperty("price", {
              ...price,
              [currency]: numericValue,
            });
        }}
        variant="standard"
        inputProps={{
          inputMode: "numeric",
        }}
      />
      <TextField
        name="price_full_upfront_payment"
        label={VIEW_TEXTS.labelFullUpfrontPaymentPrice}
        value={fullUpfrontPaymentPrice[currency]?.toLocaleString()}
        onChange={({ target: { value } }) => {
          if (!value) setProperty("price_full_upfront_payment", { ...fullUpfrontPaymentPrice, [currency]: "" });
          const numericValue = parseFloat(value.replace(/\D/g, ""));
          if (!Number.isNaN(numericValue))
            setProperty("price_full_upfront_payment", {
              ...fullUpfrontPaymentPrice,
              [currency]: numericValue,
            });
        }}
        variant="standard"
        inputProps={{
          inputMode: "numeric",
        }}
      />
      <TextField
        name="annualCapitalGain"
        label={VIEW_TEXTS.labelAnnualCapitalGain}
        value={annualCapitalGain}
        onChange={(e) =>
          setProperty(
            "annualCapitalGain",
            e.target.value.substring(0, ANNUAL_CAPITAL_GAIN_MAX_LENGTH)
          )
        }
        variant="standard"
        helperText={`${annualCapitalGain.length}/${ANNUAL_CAPITAL_GAIN_MAX_LENGTH}`}
      />
    </Box>
  </Section>
);

export default MainInformation;
