import { Box, TextField } from '@mui/material';
import React from 'react';
import Section from './Section';

const NAME_MAX_LENGTH = 30;
const LOCATION_MAX_LENGTH = 60;

const MainInformation = ({ name = '', location = '', setProperty }) => (
  <Section title="Información del proyecto">
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 4,
        '& > *': {
          width: 333,
        },
      }}
    >
      <TextField
        name="name"
        label="Nombre del proyecto"
        value={name}
        onChange={(e) =>
          setProperty('name', e.target.value.substring(0, NAME_MAX_LENGTH))
        }
        variant="standard"
        helperText={`${name.length}/${NAME_MAX_LENGTH}`}
      />

      <TextField
        name="name"
        label="Ubicación del proyecto"
        value={location}
        onChange={(e) => setProperty('location', e.target.value)}
        variant="standard"
        helperText={`${location.length}/${LOCATION_MAX_LENGTH}`}
      />
    </Box>
  </Section>
);

export default MainInformation;
