import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  styled,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getAssetUrlFromId } from "../../../helpers/assets";
import { get } from "../../../helpers/request";
import useAssetsUpload from "../../../hooks/useAssetsUpload";
import useSite from "../../../hooks/useSite";
import AssetsUploadInput from "../AssetsUploadInput";
import VideoCard from "./VideoCard";

const GalleryLayout = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, 333px)",
  justifyContent: "space-between",
  alignItems: "flex-start",
  gap: 32,
});

const getAssetsFn = async ({ queryKey }) =>
  get("/internal/developer/v1/assets?type=video", { site_id: queryKey[1] });

/**
 * @param {{
 *  open: boolean,
 *  onClose: () => void,
 *  onSelected: (assets: Array<{ assetId: string, url: string }>) => void,
 * }} param0
 */
const VideoGallery = ({ open, onClose, onSelected }) => {
  const { site } = useSite();
  const { data } = useQuery(["GET_ASSETS", site?.site_id], getAssetsFn, {
    enabled: open,
  });
  const { inputProps, progress } = useAssetsUpload({
    type: "video",
    onUploaded: ({ assets }) =>
      onSelected(assets.map(({ assetId, url }) => ({ assetId, url }))),
  });

  const progressPrct = !progress
    ? null
    : Math.round((100 * progress.loaded) / progress.total);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Galería de Vídeos</DialogTitle>
      <DialogContent>
        <GalleryLayout>
          <div>
            <AssetsUploadInput
              sx={{ height: 194, cursor: "pointer" }}
              uploadLabel="Sube un vídeo"
              inputProps={{ ...inputProps, multiple: true }}
            />
            {progressPrct && (
              <>
                <Typography sx={{ mt: 1 }}>Subiendo {progressPrct}%</Typography>
                <LinearProgress
                  variant="determinate"
                  color="secondary"
                  value={progressPrct}
                />
              </>
            )}
          </div>
          {data?.assets?.map((asset) => (
            <VideoCard
              key={asset.id}
              asset={asset}
              onSelected={() => {
                const assetId = asset.asset_id;
                onSelected([{ assetId, url: getAssetUrlFromId(assetId) }]);
              }}
            />
          ))}
        </GalleryLayout>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VideoGallery;
