import React, { useCallback } from 'react'
import PageLayout from '../../layouts/PageLayout'
import { projectTabs } from '../../helpers/tabsData'
import { FlexContainer, Spacer, Wrapper } from '../../components'
import { Button, Typography } from '@mui/material'
import SalesComparison from './SalesComparison'
import QuotesGenerated from './QuotesGenerated'
import GlobalProgress from './GlobalProgress'
import SalesProgress from './SalesProgress'
import ReservedUnitsThisMonth from './ReservedUnitsThisMonth'
import ProjectPreviewModal from '../../modules/ProjectPreviewModal/ProjectPreviewModal'
import { useState } from 'react'

function PanelView() {
  const [showProjectPreview, setShowProjectPreview] = useState(false)

  const toggleProjectPreview = useCallback(() => setShowProjectPreview(p => !p), [])

  return (
    <>
      <ProjectPreviewModal open={showProjectPreview} onClose={toggleProjectPreview} />
      <PageLayout
        links={projectTabs}
        menu={<Button color="primary" variant="outlined" onClick={toggleProjectPreview}>Vista Previa</Button>}
      >
        <Wrapper>
          <Typography variant="h4">¡Buenos días!</Typography>
          <Spacer size={2} vertical />
          <FlexContainer flexWrap>
            <SalesComparison />
            <QuotesGenerated />
            <GlobalProgress />
            <ReservedUnitsThisMonth />
            <SalesProgress />
          </FlexContainer>
        </Wrapper>
      </PageLayout>
    </>
  )
}

export default PanelView