import { Button } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../../components';
import { post } from '../../helpers/request';
import useSite from '../../hooks/useSite';
import HeaderHub from './HeaderHub';
import Menu from './Menu';
import MessageBox from './MessageBox';
import ProjectSelector from './ProjectSelector';

const Container = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  padding: 0 16px;
  background: white;
  position: relative;
  display: ${({ hideSidebar }) => (hideSidebar ? 'none' : 'flex')};
  flex-direction: column;
`;

const MenuContainer = styled(FlexContainer)`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  height: ${(p) => (p.showMessageBox ? 'calc(100vh - 255px)' : '100vh')};
  position: relative;

  &::-webkit-scrollbar-track {
    border-radius: 16px;
    opacity: 0.2;
    background-color: rgba(0, 0, 0, 0.05);
  }
  &::-webkit-scrollbar {
    width: 12px;
    background-color: rgba(0, 0, 0, .05);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: var(--color-primary);
  }
`;

const createPortalSessionFn = (siteId) =>
  post('/internal/alohub/payment/create-portal-session', undefined, siteId);

const Sidebar = ({ hideSidebar }) => {
  const { site } = useSite();

  const onPortalSessionCreated = useCallback(({ url }) => {
    window.location.replace(url);
  }, []);

  const { mutate: mutateCreatePortalSession } = useMutation(
    createPortalSessionFn,
    { onSuccess: onPortalSessionCreated }
  );

  const createPortalSession = useCallback(() => {
    if (site) mutateCreatePortalSession(site.site_id);
  }, [site, mutateCreatePortalSession]);

  return (
    <Container vertical hideSidebar={hideSidebar}>
      <HeaderHub />
      <MenuContainer vertical showMessageBox={!!site?.isTrial}>
        <ProjectSelector />
        <Menu />
      </MenuContainer>
      <Button onClick={createPortalSession} sx={{ display: 'none' }}>
        Portal
      </Button>
      {site?.isTrial && !!site?.planExpirationDate && (
        <MessageBox remainingTrialDays={site?.remainingDays} />
      )}
    </Container>
  );
};

export default Sidebar;
