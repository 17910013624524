import React, { Fragment } from 'react'
import styled from 'styled-components'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import { FlexContainer, Spacer } from '../../components'
import { Button, IconButton, Switch, TextField, Typography } from '@mui/material'

const MutedText = styled(Typography)`
  color: #00000080;
`

const AddButton = styled(Button)`
  width: fit-content;
`

const Discounts = ({ hasDiscounts, onHasDiscountsChange, discounts, onAddNewDiscount, onDeleteDiscount, onChangeDiscount, onBlurDiscount, onKeyUpDiscount }) => (
  <FlexContainer vertical>
    <FlexContainer justified>
      <Typography variant='subtitle1'>Descuentos</Typography>
      <Switch
        checked={hasDiscounts}
        onChange={(e) => onHasDiscountsChange(e.target.checked)}
        name="hasDiscounts"
        inputProps={{ 'aria-label': 'checkbox' }}
        color="primary"
      />
    </FlexContainer>
    <Typography variant='caption'>Los descuentos son proporcionales al porcentaje de enganche que el cliente elija</Typography>
    {hasDiscounts && (
      <>
        <Spacer size={1} vertical />
        <MutedText variant='caption'>Ejemplo:</MutedText>
        <MutedText variant='caption'>25% de enganche = 1% de descuento</MutedText>
        <MutedText variant='caption'>35% de enganche = 2% de descuento</MutedText>
        <Spacer size={2} vertical />
        {discounts.map((discount, i) => (
          <div key={discount.id} id={discount.id}>
            <FlexContainer fullWidth verticalCentered topped>
              <FlexContainer vertical fullWidth>
                <Typography variant='caption'>Enganche</Typography>
                <TextField
                  variant='standard'
                  size="small"
                  value={`${discount.downPaymentPercent}%`}
                  onChange={(e) => onChangeDiscount(e.target.value, i, 'downPaymentPercent')}
                  onBlur={(e) => onBlurDiscount(e.target.value, i, 'downPaymentPercent')}
                  onKeyUp={(e) => onKeyUpDiscount(e, i, 'downPaymentPercent')}
                  error={discount.downPaymentPercentError}
                  helperText={discount.downPaymentPercentError && 'El enganche debe ser mayor'}
                />
              </FlexContainer>
              <Spacer size={1} />
              <FlexContainer vertical fullWidth>
                <Typography variant='caption'>Descuento</Typography>
                <TextField
                  variant='standard'
                  size="small"
                  value={`${discount.discountPercent}%`}
                  onChange={(e) => onChangeDiscount(e.target.value, i, 'discountPercent')}
                  onBlur={(e) => onBlurDiscount(e.target.value, i, 'discountPercent')}
                  onKeyUp={(e) => onKeyUpDiscount(e, i, 'discountPercent')}
                  error={discount.discountPercentError}
                  helperText={discount.discountPercentError && 'El descuento debe ser mayor'}
                />
              </FlexContainer>
              <FlexContainer vertical>
                <Spacer size={2} vertical />
                <IconButton aria-label="delete" onClick={() => onDeleteDiscount(i)}>
                  <CloseIcon />
                </IconButton>
              </FlexContainer>
            </FlexContainer>
            <Spacer size={2} vertical />
          </div>
        ))}
        <AddButton variant="outlined" color='primary' startIcon={<AddIcon />} onClick={onAddNewDiscount}>Agregar descuento</AddButton>
      </>
    )}
  </FlexContainer>
)

export default Discounts
