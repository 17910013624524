import React, { useCallback, useState } from "react";
import styled from "styled-components";
import PublicLayout from "../../layouts/PublicLayout";
import { FlexContainer, Spacer } from "../../components";
import { Checkbox, FormControl, TextField, Typography } from "@mui/material";
import desktopHubAnimation from "../../assets/animations/desktopHub.json";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/material";
import { post } from "../../helpers/request";

const ConteinerMobile = styled(FlexContainer)`
  padding: 0 20px;
`;
const FormControlCheckbox = styled(FormControl)`
  display: flex;
  flex-direction: row;
  align-items: center;
  a {
    color: var(--color-text-primary);
  }
  p {
    font-size: 0.75rem;
  }
`;
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: desktopHubAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const OnboardingMobile = () => {
  const [email, setEmail] = useState("")
  const [termsAccepted, setTermsAccepted] = useState(false)

  const navigate = useNavigate()

  const handleChangeEmail = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const handleChangeCheck = (event) => {
    setTermsAccepted(event.target.checked);
  };

  const handleSend = useCallback(() => {
    post('/internal/login/v1/onboarding/enter-from-desktop', { email })
      .then(_ => {
        navigate('/login')  //TODO: redirect to success page
      })
      .catch(error => {
        console.error(error)
      })
  }, [email, navigate]);

  return (
    <PublicLayout onPrimaryClick={handleSend} primaryLabel={"enviar"} disableNextStep={!(email && termsAccepted)} >
      <ConteinerMobile vertical>
        <Typography variant="h4">
          Crea tu showroom desde una laptop o computadora de escritorio
        </Typography>
        <Spacer vertical size={3} />
        <Typography>
          Nuestra plataforma está optimizada para que puedas editar cada detalle de tu showroom solo dando clic y arrastrando elementos.
        </Typography>
        <Lottie options={defaultOptions} height={184} width={265} />
        <Typography>
          Compártenos tu email: te enviaremos un link para continuar desde una laptop o computadora de escritorio
        </Typography>
        <Spacer vertical size={1} />
        <TextField
          id="email"
          variant="outlined"
          placeholder="micorreo@dominio.com"
          value={email}
          fullWidth
          onChange={handleChangeEmail}
        />
        <Spacer vertical size={1} />
        <FormControlCheckbox>
          <Checkbox onChange={handleChangeCheck} />
          <Typography>
            Acepto los <Link href={`${process.env.REACT_APP_ALOHOME_URL}/terminos-y-condiciones`} target="_blank">términos y condiciones</Link> y la <Link href={`${process.env.REACT_APP_ALOHOME_URL}/politica-de-privacidad`} target="_blank">política de privacidad.</Link>
          </Typography>
        </FormControlCheckbox>
      </ConteinerMobile>
    </PublicLayout>
  );
};

export default OnboardingMobile;
