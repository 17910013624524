import { useQuery } from '@tanstack/react-query';
import { get } from '../helpers/request';
import useSite from './useSite';

const SETTINGS_CATEGORY = 'general';
const SETTINGS_SUBCATEGORY = 'currency';

const getSettingsFn = ({ queryKey }) =>
  get(
    `/internal/settings/v1/settings?category=${SETTINGS_CATEGORY}&subcategory=${SETTINGS_SUBCATEGORY}`,
    { site_id: queryKey[1] }
  );

const useCurrency = () => {
  const { site } = useSite();
  const siteId = site?.site_id;
  const { data, isLoading } = useQuery(
    ['CURRENCY_SETTINGS', siteId],
    getSettingsFn
  );

  const main = data?.dualCurrency?.main;
  const secondary = data?.dualCurrency?.secondary;
  const rate = data?.dualCurrency?.rate;

  return {
    main,
    secondary,
    rate,
    isLoading,
  };
};

export default useCurrency;
