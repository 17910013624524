import { Box, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import IntelimetricaLogo from '../../assets/images/intelimetrica-color-logo.png';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel/CircularProgressWithLabel';
import { get } from '../../helpers/request';
import useSite from '../../hooks/useSite';

const getProgress = ({ queryKey }) =>
  get('/internal/alohub/integrations/intelimetrica/reports/progress', {
    site_id: queryKey[1],
  });

const GeneratingReportIntelimetrica = () => {
  const { site } = useSite();
  const navigate = useNavigate();

  const onSuccess = useCallback(
    (data) => {
      if (typeof data?.progress !== 'number')
        return navigate('/intelimetrica', { replace: true });
      if (data?.progress === 100)
        navigate('/intelimetrica/report', { replace: true });
    },
    [navigate]
  );

  const { data } = useQuery(['PROGRESS', site?.site_id], getProgress, {
    refetchInterval: 3_000,
    onSuccess,
    enabled: !!site?.site_id,
  });

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Box
        component="img"
        src={IntelimetricaLogo}
        alt="intelimetrica-logo"
        sx={{ maxWidth: 200, display: 'block' }}
      />
      <Typography>
        Comparando propiedades. Analizando precios. Generando reporte.
      </Typography>
      <CircularProgressWithLabel
        value={data?.progress}
        size={50}
        color="primary"
        variant="indeterminate"
      />
    </Box>
  );
};

export default GeneratingReportIntelimetrica;
