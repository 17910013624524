import React from "react";
import CTAAction from "./CTAAction";

const Link = ({ pathData, setPathData, maxChars }) => {
  return (
    <CTAAction
      value={pathData}
      onChange={setPathData}
      maxChars={maxChars}
      label="Texto"
    />
  );
};

export default Link;
