import AddIcon from '@mui/icons-material/Add';
import { Button, styled } from '@mui/material';
import React, { Fragment } from 'react';
import { FlexContainer, Spacer } from '../../components';
import ProjectCard from './ProjectCard';

const AddButton = styled(Button)`
  width: 251px;
`;

const DevelopmentList = ({
  sites,
  onEditShowroom,
  onSiteDetails,
  onAddProject,
  showEditButton,
}) => (
  <FlexContainer vertical>
    <Spacer vertical size={5} />
    <AddButton endIcon={<AddIcon />} variant="contained" color="primary" onClick={onAddProject}>agregar un PROYECTO</AddButton>
    <Spacer vertical size={5} />
    <FlexContainer vertical>
      {sites?.map((site) => (
        <Fragment key={site.site_id}>
          <ProjectCard
            name={site.site_name}
            domain={site.site_domain}
            image={site.site_logo}
            showEditButton={showEditButton}
            onEditShowroom={() => onEditShowroom(site)}
            onSiteDetails={() => onSiteDetails(site)}
          />
          <Spacer vertical size={2} />
        </Fragment>
      ))}
    </FlexContainer>
  </FlexContainer>
);

export default DevelopmentList;
