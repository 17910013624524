import Avatar from '@mui/material/Avatar';

const IconAvatar = ({icon}) => {
  const Icon = icon;
  return (
    <Avatar sx={{ bgcolor: 'transparent' }}>
      <Icon color="action" />
    </Avatar>
  )
}

export default IconAvatar