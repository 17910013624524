import { Add as AddIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';

const CustomActions = ({ disableEdit, onCreate }) => {
  return (
    <Button
      startIcon={<AddIcon />}
      color="primary"
      variant="contained"
      disabled={disableEdit}
      onClick={onCreate}
      sx={{ ml: 1 }}
    >
      Crear
    </Button>
  );
};

export default CustomActions;
