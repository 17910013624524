export const RESPONSE_TYPE = {
  json: "json",
  blob: "blob",
}

const request = (url, options = {}) => {
  return new Promise(async (resolve, reject) => {
    const base = (process.env.NODE_ENV === 'production') ? ((process.env.REACT_APP_API_URL.endsWith('/')) ?
      process.env.REACT_APP_API_URL.slice(0, -1) :
      process.env.REACT_APP_API_URL) : '/api'
    const uri = base + url
    fetch(uri, { credentials: 'include', ...options })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  })
}

const blobRequest = async (url, options = {}) => {
  const result = await request(url, options);
  const blob = await result.blob();
  return blob;
};

const jsonRequest = (url, options = {}) =>
  new Promise((resolve, reject) => {
    request(url, options)
      .then(result => {
        result.json().then(response => {
          if (response.success) resolve(response.data)
          else if (response.code) reject({ code: response.code, error: response.error, data: response.data })
          else reject({ code: 'UNKNOWN_RESPONSE', error: 'Unknown response from the server' })
        }).catch(error => {
          reject({ code: 'INVALID_RESPONSE', error: 'Invalid response from the server' })
        })
      })
      .catch(result => {
        reject({ code: 'INTERNET_FAILURE', error: 'Internet Failure', data: result })
      })
  });

const getRequestFunction = (responseType) => {
  switch (responseType) {
    case RESPONSE_TYPE.blob:
      return blobRequest;
    case RESPONSE_TYPE.json:
    default:
      return jsonRequest;
  }
};

const get = (url, data, responseType = RESPONSE_TYPE.json) => {
  const requestFunction = getRequestFunction(responseType);
  if (data) {
    return requestFunction(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-site-id': data.site_id
      }
    })
  } else return requestFunction(url)
}

const post = (url, data = {}, site_id) => {
  return jsonRequest(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-site-id': site_id
    }
  })
}

const put = (url, data, site_id) => {
  return jsonRequest(url, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-site-id': data.site_id ?? site_id
    }
  })
}

const patch = (url, data, site_id) => {
  return jsonRequest(url, {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-site-id': data.site_id ?? site_id
    }
  })
}

const deleteReq = (url, data, site_id) => {
  return jsonRequest(url, {
    method: 'DELETE',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-site-id': data.site_id ?? site_id
    }
  })
}

export const filePutSignedUrl = async (url, file) => {
  return new Promise((resolve) => {
    const request = new XMLHttpRequest();
    request.open('PUT', url);
    request.setRequestHeader('Content-Type', file.type);

    request.addEventListener('load', () => resolve());

    request.send(file);
  });
};

const downloadFile = async (blobData, filename) => {
  const blob = new Blob([blobData], { type: blobData.type || 'application/octet-stream' });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, filename);
    return;
  }
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', filename);

  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  setTimeout(() => {
    window.URL.revokeObjectURL(blobURL);
  }, 100);
};

export { get, post, put, patch, deleteReq, downloadFile }
