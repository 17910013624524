import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { getPathValue, modifyPath } from "../../../helpers/data";
import Images from "./Images";
import SimpleVideo from "./SimpleVideo";

const Multimedia = ({
  sectionData = {},
  setSectionData,
  videoSectionPath,
  imagesSectionPath,
}) => {
  const [mediaType, setMediaType] = useState(() =>
    !!getPathValue(sectionData, imagesSectionPath) ? "image" : "video"
  );

  const videoPathData = useMemo(
    () => getPathValue(sectionData, videoSectionPath),
    [sectionData, videoSectionPath]
  );

  const setVideoPathData = useCallback(
    (value) => {
      modifyPath(sectionData, videoSectionPath, value);
      modifyPath(sectionData, imagesSectionPath, undefined);
      setSectionData({ ...sectionData });
    },
    [sectionData, setSectionData, videoSectionPath, imagesSectionPath]
  );

  const imagesPathData = useMemo(
    () => getPathValue(sectionData, imagesSectionPath),
    [sectionData, imagesSectionPath]
  );

  const setImagesPathData = useCallback(
    (value) => {
      modifyPath(sectionData, imagesSectionPath, value);
      modifyPath(sectionData, videoSectionPath, undefined);
      setSectionData({ ...sectionData });
    },
    [sectionData, setSectionData, videoSectionPath, imagesSectionPath]
  );

  return (
    <>
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={mediaType}
        onChange={(e) => setMediaType(e.target.value)}
        sx={{ mb: 2 }}
      >
        <ToggleButton value="video" sx={{ textTransform: "none" }}>
          Vídeo
        </ToggleButton>
        <ToggleButton value="image" sx={{ textTransform: "none" }}>
          Imágenes
        </ToggleButton>
      </ToggleButtonGroup>
      {mediaType === "video" && (
        <SimpleVideo pathData={videoPathData} setPathData={setVideoPathData} />
      )}
      {mediaType === "image" && (
        <Images pathData={imagesPathData} setPathData={setImagesPathData} />
      )}
    </>
  );
};

export default Multimedia;
