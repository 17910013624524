import { Card, CardActions, CardContent, IconButton } from "@mui/material"
import React, { useCallback, useState } from "react"
import styled from "styled-components"
import DeleteIcon from '@mui/icons-material/Delete'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { MaxCharsInput } from "../../components"
import PlayIcon from "../../assets/icons/play.svg"
import { useRef } from "react"

const Container = styled(Card)`
  width: 249px;
  height: fit-content;
`

const Body = styled(CardContent)`
  padding-bottom: 0;
`

const Footer = styled(CardActions)`
  display: flex;
  justify-content: space-between;
`

const DragHandle = styled(DragIndicatorIcon)`
  cursor: all-scroll;
`

const MediaContainer = styled.button`
  width: 100%;
  background: none;
  position: relative;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: ${p => p.type === "video" ? "pointer" : "cursor"};
`

const Play = styled.img`
  width: 60px;
  height: 60px;
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`

const Media = styled.img`
  object-fit: cover;
  width: 100%;
  height: 140px;
`

const MediaCard = ({ media, onDelete, onTitleChange }) => {
  const mediaRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const handlePlay = useCallback(() => {
    if (mediaRef && mediaRef.current && media?.type === "video") {
      const video = mediaRef.current
      if (video.paused) {
        video.play()
        setIsPlaying(true)
      } else {
        video.pause()
        setIsPlaying(false)
      }
    }
  }, [media?.type])

  return (
    <Container>
      <MediaContainer onClick={handlePlay} type={media?.type}>
        <Media src={`${media?.url}?s=330x300`} as={media?.type === "image" ? "img" : "video"} ref={mediaRef} />
        {media?.type === "video" && !isPlaying && (
          <Play src={PlayIcon} />
        )}
      </MediaContainer>
      {media?.type === "image" && (
        <Body>
          <MaxCharsInput
            label="Título de la imagen"
            maxChars={25}
            value={media?.title}
            onChange={onTitleChange}
          />
        </Body>
      )}
      <Footer>
        <DragHandle className="drag-handler" />
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Footer>
    </Container>
  )
}

export default MediaCard
