import React, { useState } from "react"
import { ReactSortable } from "react-sortablejs"
import { Button, IconButton, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { FlexContainer, MaxCharsInput, Spacer } from "../../components"
import DeleteIcon from '@mui/icons-material/Delete'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import SaveIcon from '@mui/icons-material/Save'
import styled from "styled-components"
import { SimpleModal } from "../../modules"
import { useCallback } from "react"

const DragHandle = styled(DragHandleIcon)`
  cursor: all-scroll;
`

const Cell = styled(TableCell)`
  padding: 8px;
`

const StyledTableContainer = styled(TableContainer)`
  max-height: 320px;

  &::-webkit-scrollbar-track {
    border-radius: 16px;
    opacity: 0.2;
    background-color: rgba(0, 0, 0, .05);
  }
  &::-webkit-scrollbar {
    width: 12px;
    background-color: rgba(0, 0, 0, .05);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: var(--color-primary);
  }
`

const StagesTable = ({
  stages,
  onStagesAdd,
  onStagesDelete,
  onStageChange,
  onStageReorder,
}) => {
  const [stageToDelete, setStageToDelete] = useState(null)

  const closeDeleteModal = useCallback(() => setStageToDelete(null), [])

  const handleStageDelete = useCallback(() => {
    onStagesDelete(stageToDelete)
    setStageToDelete(null)
  }, [onStagesDelete, stageToDelete])

  return (
    <>
      <SimpleModal
        open={stageToDelete != null}
        onClose={closeDeleteModal}
        message="¿Quieres eliminar esta etapa"
        primaryText="Eliminar"
        onPrimaryClick={handleStageDelete}
        secondaryText="Cancelar"
        onSecondaryClick={closeDeleteModal}
      />
      <FlexContainer vertical>
        <StyledTableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <Cell></Cell>
                <Cell align="center">ETAPA</Cell>
                <Cell align="left">NOMBRE</Cell>
                <Cell></Cell>
                <Cell align="center">COMPLETADO</Cell>
                <Cell></Cell>
              </TableRow>
            </TableHead>
            <ReactSortable list={stages} setList={onStageReorder} handle=".drag-handler" draggable=".draggable" animation={300} direction="vertical" tag={TableBody}>
              {stages.map((stage, i) => (
                <TableRow key={stage.id} className="draggable">
                  <Cell align="center">
                    <DragHandle className="drag-handler" />
                  </Cell>
                  <Cell align="center">{i + 1}</Cell>
                  <Cell align="left">
                    {stage.editing ? (
                      <MaxCharsInput
                        value={stage.name}
                        onChange={(value) => onStageChange(stage.id, value, "name")}
                        maxChars={20}
                      />
                    ) : (
                      <Typography>{stage.name}</Typography>
                    )}
                  </Cell>
                  <Cell>
                    {stage.editing ? (
                      <FlexContainer topped>
                        <IconButton
                          size="small"
                          aria-label="guardar"
                          onClick={() => onStageChange(stage.id, !stage.editing, "editing")}
                          disabled={stage.name.length === 0}
                        >
                          <SaveIcon />
                        </IconButton>
                      </FlexContainer>
                    ) : (
                      <IconButton
                        size="small"
                        aria-label="editar"
                        onClick={() => onStageChange(stage.id, !stage.editing, "editing")}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </Cell>
                  <Cell align="center">
                    <Switch
                      checked={stage.completed}
                      onChange={(e) => onStageChange(stage.id, e.target.checked, "completed")}
                    />
                  </Cell>
                  <Cell align="center">
                    {stages.length > 1 ? (
                      <IconButton
                        size="small"
                        aria-label="eliminar"
                        onClick={() => setStageToDelete(stage.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )  : (
                      <Spacer size={2} />
                    )}
                  </Cell>
                </TableRow>
              ))}
            </ReactSortable>
          </Table>
        </StyledTableContainer>
        <Spacer size={4} vertical />
        <FlexContainer>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onStagesAdd}
            endIcon={<AddIcon />}
            disabled={stages.length >= 9}
          >
            Agregar Etapa
          </Button>
        </FlexContainer>
      </FlexContainer>
    </>
  )
}

export default StagesTable
