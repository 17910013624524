import { Box, Typography } from "@mui/material";
import React from "react";

const Section = ({ title, children }) => {
  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h5" sx={{ mb: 4 }}>{title}</Typography>
      {children}
    </Box>
  );
};

export default Section;
