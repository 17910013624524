export const TABS_KEY = {
  panel: 'panel',
  inventory: 'inventory',
  typologyQuote: 'typology-quote',
  unitQuote: 'unit-quote',
  showroomEditor: 'showroom-editor',
  prospects: 'prospects',
  funnelReport: 'funnel-report',
  featuresReport: 'features-report',
  usersReport: 'users-report',
  audienceReport: 'audience-report',
  behaviorReport: 'behavior-report',
  quoteBuilder: 'quote-builder',
  salesTeam: 'sales-team',
  utmLinks: 'utm-links',
  NOM247: 'NOM-247',
  generalSettings: 'general-settings',
  integrations: 'integrations',
  customCss: 'customCss',
  hostedZone: 'hostedZone',
  profile: 'profile',
  projects: 'projects',
  about: 'about',
  config: 'config',
  workProgress: 'work-progress',
  welcome: 'welcome',
  notifications: 'notifications',
}

export const projectTabs = {
  urlLevel: 2,
  tabs: [{
    id: TABS_KEY.panel,
    label: 'Panel',
    href: 'panel',
    permissionPath: ['project', 'panel'],
  }, {
    id: TABS_KEY.inventory,
    label: 'Inventario',
    href: 'inventory',
    permissionPath: ['project', 'inventory'],
  }, {
    id: TABS_KEY.typologyQuote,
    label: 'Cotizador',
    href: 'typology-quote',
    permissionPath: ['project', 'typology_quote_editor'],
  }, {
    id: TABS_KEY.unitQuote,
    label: 'Cotizador',
    href: 'unit-quote',
    permissionPath: ['project', 'quote_editor'],
  }, {
    id: TABS_KEY.workProgress,
    label: 'Avance de Obra',
    href: 'work-progress',
    permissionPath: ['project', 'work_progress'],
  }, {
    id: TABS_KEY.workProgress,
    label: 'Cambio de moneda',
    href: 'dual-currency',
    permissionPath: ['project', 'dual_currency'],
  }]
}

export const digitalShowroomTabs = {
  urlLevel: 2,
  tabs: [{
    id: TABS_KEY.showroomEditor,
    label: 'Editor',
    href: 'editor',
    permissionPath: ['digital_showroom', 'editor'],
  }]
}

export const prospectsTabs = {
  urlLevel: 2,
  tabs: [{
    id: TABS_KEY.prospects,
    label: 'Prospectos',
    href: 'prospects',
    permissionPath: ['prospects', 'prospects'],
  }, {
    id: TABS_KEY.funnelReport,
    label: 'Embudo',
    href: 'funnel',
    permissionPath: ['prospects', 'funnel'],
  }, {
    id: TABS_KEY.featuresReport,
    label: 'Funcionalidades',
    href: 'features',
    permissionPath: ['prospects', 'features'],
  }, {
    id: TABS_KEY.usersReport,
    label: 'Usuarios',
    href: 'users',
    permissionPath: ['prospects', 'users'],
  }, {
    id: TABS_KEY.audienceReport,
    label: 'Audiencia',
    href: 'audience',
    permissionPath: ['prospects', 'audience'],
  }, {
    id: TABS_KEY.behaviorReport,
    label: 'Comportamiento',
    href: 'behavior',
    permissionPath: ['prospects', 'behavior'],
  }]
}

export const quoteTabs = {
  urlLevel: 2,
  tabs: [{
    id: TABS_KEY.quoteBuilder,
    label: 'Cotizar',
    href: 'builder',
    permissionPath: ['quote', 'quote_builder'],
  }]
}

export const salesTeamTabs = {
  urlLevel: 2,
  tabs: [{
    id: TABS_KEY.salesTeam,
    label: 'Supervisores',
    href: 'supervisor',
    permissionPath: ['team', 'supervisor'],
  }, {
    id: TABS_KEY.salesTeam,
    label: 'Asesores',
    href: 'sales',
    permissionPath: ['team', 'sales'],
  }]
}

export const marketingTabs = {
  urlLevel: 2,
  tabs: [{
    id: TABS_KEY.salesTeam,
    label: 'Enlaces UTM',
    href: 'utm-links',
  }]
}

export const configTabs = {
  urlLevel: 2,
  tabs: [{
    id: TABS_KEY.notifications,
    label: 'Notificaciones',
    href: 'notifications',
    permissionPath: ['config', 'notifications'],
  }, {
    id: TABS_KEY.generalSettings,
    label: 'General',
    href: 'general',
    permissionPath: ['config', 'general'],
  }, {
    id: TABS_KEY.integrations,
    label: 'Integraciones',
    href: 'integrations',
    permissionPath: ['config', 'integrations'],
  }, {
    id: TABS_KEY.customCss,
    label: 'Hojas de estilo (CSS)',
    href: 'css',
    permissionPath: ['config', 'css'],
  }, {
    id: TABS_KEY.hostedZone,
    label: 'Zona Alojada',
    href: 'hosted-zone',
    permissionPath: ['config', 'hosted_zone'],
  }]
}

export const profileTabs = {
  urlLevel: 1,
  tabs: [{
    id: TABS_KEY.profile,
    label: 'Mi Perfil',
    href: 'profile',
  }]
}

export const DevelopmentsTabs = {
  urlLevel: 1,
  tabs: [{
    id: TABS_KEY.projects,
    label: 'Administrador de Proyectos',
    href: 'projects',
  }]
}

export const appsTabs = {
  urlLevel: 2,
  tabs: [
    {
      label: 'MARKETPLACES',
      href: 'marketplaces',
    },
    {
      label: 'ANALÍTICOS',
      href: 'data',
    }
  ]
}

export const intelimetricaTabs = {
  urlLevel: 2,
  tabs: [
    {
      id: TABS_KEY.about,
      label: 'REPORTE',
      href: 'report',
      disabled: true
    },
    {
      id: TABS_KEY.about,
      label: 'ACERCA DE',
      href: 'about',
      disabled: true
    },
    {
      id: TABS_KEY.config,
      label: 'configuración',
      href: 'config',
      disabled: true
    }
  ]
}

export const flatTabs = {
  urlLevel: 2,
  tabs: [
    {
      id: TABS_KEY.welcome,
      label: 'Bienvenida',
      href: 'welcome',
    },
    {
      id: TABS_KEY.config,
      label: 'configuración',
      href: 'config',
      disabled: true,
    },
    {
      id: TABS_KEY.config,
      label: 'COMPLETADO',
      href: 'completed',
      disabled: true,
    },
  ]
}