import {
  FormControlLabel,
  styled,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback } from 'react';
import { getKeysAsPath, modifyPath } from '../../../helpers/data';
import useDebouncedState from '../../../hooks/useDebouncedState';
import CheckItem, { MUST_BE_SHOW_ON } from '../CheckItem';
import Section from '../Section';
import BusinessHoursForm from './BusinessHoursForm';
import ContactMethodsForm from './ContactMethodsForm';

const FormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  maxWidth: 450,
  width: '100%',
}));

export const CUSTOMER_SUPPORT_URL_SLUG = 'customer-support';

export const CUSTOMER_SUPPORT_NOM_PATH = 'customerSupport';
export const SUPPORT_CONTACT_METHODS_PATH = 'contactMethods';
export const SUPPORT_PHYSICAL_SUPPORT_PATH = 'physicalSupport';
export const SUPPORT_BUSINESS_HOURS_PATH = 'businessHours';

export const CUSTOMER_SUPPORT_CHECKLIST_IDS = [
  'Art_5-Sec_I',
  'Art_4-Sec_III-Art_5',
  'Art_4_2-Sec_III',
  'Art_4-Sec_III',
  'Art_4_1',
];

const getDefaultNamesState = () => ({ businessName: '', commercialName: '' });

const getDefaultContactMethodsState = () => ({
  complains: {
    email: '',
    phone: '',
    whatsapp: '',
  },
  useSameAsComplainsForSupport: true,
});

const getDefaultPhysicalSupport = () => ({
  complains: { address: '' },
  useSameAsComplainsForSupport: true,
});

const getDefaultBusinessHours = () => ({
  complains: {},
  useSameAsComplainsForSupport: true,
});

const CustomerSupport = ({ getSectionData, setSectionData }) => {
  const onNamesChange = useCallback(
    (v) => setSectionData('names', v),
    [setSectionData]
  );
  const [names, setNames] = useDebouncedState(
    () => getSectionData('names') || getDefaultNamesState(),
    { onSettled: onNamesChange }
  );
  const setNamesProperty = useCallback(
    (prop, value) => {
      setNames((prev) => ({ ...prev, [prop]: value }));
    },
    [setNames]
  );

  const onContactMethodsChange = useCallback(
    (v) => setSectionData(SUPPORT_CONTACT_METHODS_PATH, v),
    [setSectionData]
  );
  const [contactMethods, setContactMethods] = useDebouncedState(
    () => getSectionData(SUPPORT_CONTACT_METHODS_PATH) || getDefaultContactMethodsState(),
    {
      onSettled: onContactMethodsChange,
    }
  );
  const setContactMethodsPath = useCallback(
    (path, value) =>
      setContactMethods((prev) => {
        modifyPath(prev, path, value, { recursive: true });
        return { ...prev };
      }),
    [setContactMethods]
  );
  const sameForSupportContactMethods =
    contactMethods.useSameAsComplainsForSupport;

  const onPhysicalSupportChange = useCallback(
    (v) => setSectionData(SUPPORT_PHYSICAL_SUPPORT_PATH, v),
    [setSectionData]
  );
  const [physicalSupport, setPhysicalSupport] = useDebouncedState(
    () => getSectionData(SUPPORT_PHYSICAL_SUPPORT_PATH) || getDefaultPhysicalSupport(),
    { onSettled: onPhysicalSupportChange }
  );
  const setPhysicalSupportPath = useCallback(
    (path, value) =>
      setPhysicalSupport((prev) => {
        modifyPath(prev, path, value, { recursive: true });
        return { ...prev };
      }),
    [setPhysicalSupport]
  );
  const sameForSupportPhysicalSupport =
    physicalSupport.useSameAsComplainsForSupport;

  const onBusinessHoursChange = useCallback(
    (v) => setSectionData(SUPPORT_BUSINESS_HOURS_PATH, v),
    [setSectionData]
  );
  const [businessHours, setBusinessHours] = useDebouncedState(
    () => getSectionData(SUPPORT_BUSINESS_HOURS_PATH) || getDefaultBusinessHours(),
    { onSettled: onBusinessHoursChange }
  );
  const setBusinessHoursPath = useCallback(
    (path, value) =>
      setBusinessHours((prev) => {
        modifyPath(prev, path, value, { recursive: true });
        return { ...prev };
      }),
    [setBusinessHours]
  );
  const sameForSupportBusinessHours =
    businessHours.useSameAsComplainsForSupport;

  const getValue = useCallback(
    (id) => getSectionData(getKeysAsPath(['checkList', id])),
    [getSectionData]
  );

  const getHandleValueChange = useCallback(
    (id) => (path, value) =>
      setSectionData(getKeysAsPath(['checkList', id, path]), value),
    [setSectionData]
  );

  return (
    <Section title="Atención" id="customer-support">
      <CheckItem
        value={getValue('Art_5-Sec_I')}
        setValuePath={getHandleValueChange('Art_5-Sec_I')}
        title="Nombre comercial y razón social"
        NOMSection="Art. 5 Sec. I"
        mustBeShowOn={MUST_BE_SHOW_ON.DIGITALLY}
      >
        <FormContainer>
          <TextField
            variant="standard"
            name="commercialName"
            label="Nombre comercial"
            value={names.commercialName}
            onChange={(e) => setNamesProperty('commercialName', e.target.value)}
          />
          <TextField
            variant="standard"
            name="businessName"
            label="Razón social"
            value={names.businessName}
            onChange={(e) => setNamesProperty('businessName', e.target.value)}
          />
        </FormContainer>
      </CheckItem>
      <CheckItem
        value={getValue('Art_4-Sec_III-Art_5')}
        setValuePath={getHandleValueChange('Art_4-Sec_III-Art_5')}
        title="Horarios de atención a quejas y reclamaciones / Horarios de atención al consumidor"
        NOMSection="Art. 4 Sec. III / Art. 5"
        mustBeShowOn={MUST_BE_SHOW_ON.DIGITALLY}
      >
        <FormContainer>
          <Typography sx={{ my: 1 }}>
            Horario de atención a quejas y reclamaciones
          </Typography>
          <BusinessHoursForm
            businessHours={businessHours.complains}
            setBusinessHours={(v) => setBusinessHoursPath('complains', v)}
          />
          <FormControlLabel
            label="Usar la misma información para atención al cliente"
            labelPlacement="start"
            sx={{ alignSelf: 'flex-start' }}
            control={
              <Switch
                checked={sameForSupportBusinessHours}
                onChange={(e) =>
                  setBusinessHoursPath(
                    'useSameAsComplainsForSupport',
                    e.target.checked
                  )
                }
                sx={{ ml: 1 }}
              />
            }
          />
          {!sameForSupportBusinessHours && (
            <>
              <Typography sx={{ my: 1 }}>
                Horario de atención al consumidor
              </Typography>
              <BusinessHoursForm
                businessHours={businessHours.support}
                setBusinessHours={(v) => setBusinessHoursPath('support', v)}
              />
            </>
          )}
        </FormContainer>
      </CheckItem>
      <CheckItem
        value={getValue('Art_4_2-Sec_III')}
        setValuePath={getHandleValueChange('Art_4_2-Sec_III')}
        title="Dirección física para quejas y reclamaciones / Dirección física para atención al consumidor"
        NOMSection="Art. 4.2 Sec. III"
        mustBeShowOn={MUST_BE_SHOW_ON.DIGITALLY}
      >
        <FormContainer>
          <Typography sx={{ my: 1 }}>
            Dirección para atención a quejas y reclamaciones
          </Typography>
          <TextField
            variant="standard"
            name="address"
            label="Dirección"
            value={physicalSupport.complains?.address}
            onChange={(e) =>
              setPhysicalSupportPath('complains.address', e.target.value)
            }
          />
          <FormControlLabel
            label="Usar la misma información para atención al cliente"
            labelPlacement="start"
            sx={{ alignSelf: 'flex-start' }}
            control={
              <Switch
                checked={sameForSupportPhysicalSupport}
                onChange={(e) =>
                  setPhysicalSupportPath(
                    'useSameAsComplainsForSupport',
                    e.target.checked
                  )
                }
                sx={{ ml: 1 }}
              />
            }
          />
          {!sameForSupportPhysicalSupport && (
            <TextField
              variant="standard"
              name="address"
              label="Dirección"
              value={physicalSupport.support?.address || ''}
              onChange={(e) =>
                setPhysicalSupportPath('support.address', e.target.value)
              }
            />
          )}
        </FormContainer>
      </CheckItem>
      <CheckItem
        value={getValue('Art_4-Sec_III')}
        setValuePath={getHandleValueChange('Art_4-Sec_III')}
        title="Teléfono y dirección electrónica para quejas y reclamaciónes / Teléfono y dirección electrónica para atención al consumidor"
        NOMSection="Art. 4 Sec. III"
        mustBeShowOn={MUST_BE_SHOW_ON.DIGITALLY}
      >
        <Typography sx={{ mb: 3 }}>
          Información para atención a quejas y reclamaciones
        </Typography>
        <FormContainer>
          <ContactMethodsForm
            email={contactMethods.complains?.email}
            phone={contactMethods.complains?.phone}
            whatsapp={contactMethods.complains?.whatsapp}
            onEmailChange={(v) => setContactMethodsPath('complains.email', v)}
            onPhoneChange={(v) => setContactMethodsPath('complains.phone', v)}
            onWhatsappChange={(v) =>
              setContactMethodsPath('complains.whatsapp', v)
            }
          />
          <FormControlLabel
            label="Usar la misma información para atención al cliente"
            labelPlacement="start"
            sx={{ alignSelf: 'flex-start' }}
            control={
              <Switch
                checked={sameForSupportContactMethods}
                onChange={(e) =>
                  setContactMethodsPath(
                    'useSameAsComplainsForSupport',
                    e.target.checked
                  )
                }
                sx={{ ml: 0.5 }}
              />
            }
          />
          {!sameForSupportContactMethods && (
            <ContactMethodsForm
              email={contactMethods.support?.email || ''}
              phone={contactMethods.support?.phone || ''}
              whatsapp={contactMethods.support?.whatsapp || ''}
              onEmailChange={(v) => setContactMethodsPath('support.email', v)}
              onPhoneChange={(v) => setContactMethodsPath('support.phone', v)}
              onWhatsappChange={(v) =>
                setContactMethodsPath('support.whatsapp', v)
              }
            />
          )}
        </FormContainer>
      </CheckItem>
      <CheckItem
        value={getValue('Art_4_1')}
        setValuePath={getHandleValueChange('Art_4_1')}
        title="El proveedor debe contar con canales y mecanismos de atención al consumidor, como formularios en sitios web, debiendo estar estos habilitados por lo menos en días y horas hábiles, en los que se recibirán comentarios, quejas y sugerencias."
        NOMSection="Art. 4.1"
      />
    </Section>
  );
};

export default CustomerSupport;
