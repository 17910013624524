import { Button, Link, Typography } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import alohomeLogo from '../assets/svgs/alohome.svg'
import { FlexContainer } from '../components'
import StepCounter from '../components/StepCounter/StepCounter'

const BACKGROUND = "#F6F7F9"

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: ${BACKGROUND};
  width: 100%;
`

const Header = styled.header`
  width: calc(100% - 48px);
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  position: fixed;
  background: ${BACKGROUND};
  z-index: 1;
  @media only screen and (max-width: 641px) {
    justify-content: center;
  }
`

const Footer = styled.footer`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  gap: 16px;
  background: linear-gradient(180deg, rgba(246, 247, 249, 0) 0%, ${BACKGROUND} 49.77%);
  @media only screen and (max-width: 641px) {
    height: 0;
  }
`

const Body = styled.div`
  margin-top: 90px;
  margin-bottom: 100px; 
`

const Logo = styled.img`
  width: 200px;
`

const HelpMessage = styled(FlexContainer)`
  position: absolute;
  right: 24px;
`

const SkipContainer = styled(FlexContainer)`
  position: absolute;
  right: 24px;
`

const actionLabels = {
  skipLabel: "Saltar este paso",
}

const PublicLayout = ({
  children,
  onPrimaryClick,
  primaryLabel,
  primaryIcon,
  onSecondaryClick,
  secondaryLabel,
  footerMessage,
  showHelpMessage,
  currentStep,
  numberOfSteps,
  disableNextStep,
  showSkipButton,
  onSkipClick
}) => (
  <Container>
    <Header>
      <Logo src={alohomeLogo} alt="Alohome logo" />
      {currentStep && numberOfSteps && (
        <StepCounter currentStep={currentStep} numberOfSteps={numberOfSteps} />
      )}
    </Header>
    <Body>
      {children}
    </Body>
    <Footer>
      {footerMessage && (
        <Typography>{footerMessage}</Typography>
      )}
      {secondaryLabel && onSecondaryClick && (
        <Button onClick={onSecondaryClick} color="primary">{secondaryLabel}</Button>
      )}
      {primaryLabel && onPrimaryClick && (
        <Button disabled={disableNextStep} endIcon={primaryIcon} onClick={onPrimaryClick} variant="contained" color="primary">{primaryLabel}</Button>
      )}
      {showHelpMessage && (
        <HelpMessage vertical ended>
          <Typography>¿Necesitas ayuda con tu showroom?</Typography>
          <Link href={`mailto:${process.env.REACT_APP_SUPPORT_MAIL}`} target="_blank">Platica con nuestro equipo</Link>
        </HelpMessage>
      )}
      {showSkipButton && (
        <SkipContainer vertical ended>
          <Button onClick={onSkipClick} color="primary">{actionLabels.skipLabel}</Button>
        </SkipContainer>
      )}
    </Footer>
  </Container>
)

export default PublicLayout
