import {
  CONTACT_METHODS_PATH,
  getKeysAsPath,
  getPathValue,
  modifyPath,
  NOM_PATH,
  TRANSPARENCY_PAGE_PATH,
} from '../../../helpers/data';
import { DAYS_KEYS } from '../CustomerSupport/BusinessHoursForm';
import {
  CUSTOMER_SUPPORT_NOM_PATH,
  SUPPORT_BUSINESS_HOURS_PATH,
  SUPPORT_CONTACT_METHODS_PATH,
  SUPPORT_PHYSICAL_SUPPORT_PATH,
} from '../CustomerSupport/CustomerSupport';
import { customData, footer, sections } from './staticData';

export default function getDataChanges({ data, disabled }) {
  const pageSlugPath = getKeysAsPath([TRANSPARENCY_PAGE_PATH, 'slug']);
  const transparencyPath = getPathValue(data, pageSlugPath) || '/transparencia';

  const activeChanges = [
    {
      path: getKeysAsPath([TRANSPARENCY_PAGE_PATH, 'sections']),
      value: sections,
    },
    {
      path: pageSlugPath,
      value: transparencyPath,
    },
  ];

  const disableChanges = [
    {
      path: TRANSPARENCY_PAGE_PATH,
      value: undefined,
    },
  ];

  const changes = [
    {
      path: NOM_PATH,
      value: getPathValue(data, NOM_PATH),
    },
    {
      path: getKeysAsPath([NOM_PATH, 'updatedAt']),
      value: new Date().toISOString(),
    },
    ...(disabled ? disableChanges : activeChanges),
  ];

  for (const key in customData) {
    changes.push({
      path: getKeysAsPath(['custom_data', key]),
      value: customData[key],
    });
  }

  for (const key in footer) {
    changes.push({
      path: getKeysAsPath(['footer', key]),
      value: footer[key],
    });
  }

  const header = getPathValue(data, 'header');
  if (header?.type === 'underline-header' && Array.isArray(header.items)) {
    const { items } = header;
    const transparencyItemIndex = items.findIndex((item) =>
      item?.href?.startsWith?.(transparencyPath)
    );

    const nItems = [...items];
    if (transparencyItemIndex >= 0) nItems.splice(transparencyItemIndex, 1);
    if (!disabled)
      nItems.splice(nItems.length - 1, 0, {
        label: 'Transparencia',
        href: transparencyPath,
        action: 'goToSection',
      });
    changes.push({
      path: 'header.items',
      value: nItems,
    });
  } else if (!disabled) {
    changes.push({
      path: getKeysAsPath([TRANSPARENCY_PAGE_PATH, 'menu_item']),
      value: 'TRANSPARENCIA',
    });
  }

  modifyDataContactMethods(data);

  changes.push({
    path: CONTACT_METHODS_PATH,
    value: getPathValue(data, CONTACT_METHODS_PATH),
  });

  return changes;
}

function modifyDataContactMethods(data) {
  const { support: CMSupport } = getNOMSupportContactMethods(data);
  const { support: physicalSupport } = getNOMSupportPhysicalSupport(data);
  const { support: businessHoursSupport } = getNOMSupportBusinessHours(data);

  if (CMSupport) {
    const { whatsapp, phone, email } = CMSupport;

    if (whatsapp)
      modifyPath(
        data,
        getKeysAsPath([CONTACT_METHODS_PATH, 'whatsapp', 'content']),
        whatsapp
      );
    else
      modifyPath(
        data,
        getKeysAsPath([CONTACT_METHODS_PATH, 'whatsapp']),
        undefined
      );

    if (email)
      modifyPath(
        data,
        getKeysAsPath([CONTACT_METHODS_PATH, 'email', 'content']),
        email
      );
    else
      modifyPath(
        data,
        getKeysAsPath([CONTACT_METHODS_PATH, 'email']),
        undefined
      );

    if (phone)
      modifyPath(
        data,
        getKeysAsPath([CONTACT_METHODS_PATH, 'phone', 'content']),
        phone
      );
    else
      modifyPath(
        data,
        getKeysAsPath([CONTACT_METHODS_PATH, 'phone']),
        undefined
      );
  }

  if (physicalSupport) {
    const { address } = physicalSupport;
    if (address)
      modifyPath(
        data,
        getKeysAsPath([CONTACT_METHODS_PATH, 'address', 'content']),
        address
      );
    else
      modifyPath(
        data,
        getKeysAsPath([CONTACT_METHODS_PATH, 'address']),
        undefined
      );
  }

  if (businessHoursSupport) {
    modifyPath(
      data,
      getKeysAsPath([CONTACT_METHODS_PATH, 'office_hours', 'close']),
      'Cerrado'
    );
    modifyPath(
      data,
      getKeysAsPath([CONTACT_METHODS_PATH, 'office_hours', 'label']),
      'Horarios de atención:'
    );
    for (const { label, key } of DAYS_KEYS) {
      const dayBusinessHours = getPathValue(businessHoursSupport, key);
      if (dayBusinessHours?.from && dayBusinessHours.to)
        modifyPath(
          data,
          getKeysAsPath([CONTACT_METHODS_PATH, 'office_hours', 'hours', key]),
          {
            label,
            hour: { from: dayBusinessHours.from, to: dayBusinessHours.to },
          }
        );
      else
        modifyPath(
          data,
          getKeysAsPath([CONTACT_METHODS_PATH, 'office_hours', 'hours', key]),
          { label }
        );
    }
  } else
    modifyPath(
      data,
      getKeysAsPath([CONTACT_METHODS_PATH, 'office_hours']),
      undefined
    );
}

export function getNOMSupportContactMethods(data) {
  const contactMethods = getPathValue(
    data,
    getKeysAsPath([
      NOM_PATH,
      CUSTOMER_SUPPORT_NOM_PATH,
      SUPPORT_CONTACT_METHODS_PATH,
    ])
  );

  return {
    complains: contactMethods?.complains,
    support: contactMethods?.useSameAsComplainsForSupport
      ? contactMethods?.complains
      : contactMethods?.support,
  };
}

function getNOMSupportPhysicalSupport(data) {
  const physicalSupport = getPathValue(
    data,
    getKeysAsPath([
      NOM_PATH,
      CUSTOMER_SUPPORT_NOM_PATH,
      SUPPORT_PHYSICAL_SUPPORT_PATH,
    ])
  );

  return {
    complains: physicalSupport?.complains,
    support: physicalSupport?.useSameAsComplainsForSupport
      ? physicalSupport?.complains
      : physicalSupport?.support,
  };
}

function getNOMSupportBusinessHours(data) {
  const isActive = getPathValue(
    data,
    getKeysAsPath([
      NOM_PATH,
      CUSTOMER_SUPPORT_NOM_PATH,
      'checkList',
      'Art_4-Sec_III-Art_5',
      'value',
    ])
  );

  if (!isActive) return { complains: null, support: null };

  const businessHours = getPathValue(
    data,
    getKeysAsPath([
      NOM_PATH,
      CUSTOMER_SUPPORT_NOM_PATH,
      SUPPORT_BUSINESS_HOURS_PATH,
    ])
  );

  return {
    complains: businessHours?.complains,
    support: businessHours?.useSameAsComplainsForSupport
      ? businessHours?.complains
      : businessHours?.support,
  };
}
