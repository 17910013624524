import { CircularProgress } from "@mui/material"
import React from "react"
import { FlexContainer, Spacer } from "../../components"

const PageLoader = () => (
  <FlexContainer fullWidth vertical centered verticalCentered>
    <Spacer size={8} vertical />
    <CircularProgress />
  </FlexContainer>
)

export default PageLoader
