import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Undo as UndoIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import useAssetsUpload from '../../hooks/useAssetsUpload';

const AttachedFiles = ({ files: filesProp, setFiles }) => {
  const files = useMemo(() => filesProp || [], [filesProp]);
  const inputRef = useRef(null);
  const [editingAssetId, setEditingAssetId] = useState(null);
  const editingRef = useRef(null);

  const onUploaded = useCallback(
    ({ assets }) => {
      const nFiles = [...files];
      nFiles.push(
        ...assets.map(({ assetId, url, name }) => ({
          assetId,
          url,
          name: name.replace(/\..+$/, ''),
        }))
      );
      setFiles(nFiles);
      if (inputRef.current) inputRef.current.value = '';
    },
    [files, setFiles]
  );

  const onUndo = useCallback(() => setEditingAssetId(null), []);

  const onDeleted = useCallback(
    (index) => {
      const nFiles = [...files];
      nFiles.splice(index, 1);
      setFiles(nFiles);
    },
    [files, setFiles]
  );

  const onNameChange = useCallback(
    (index) => {
      if (!editingRef.current) return;
      const { value } = editingRef.current;
      const nFiles = [...files];
      nFiles[index].name = value;
      setFiles(nFiles);
      setEditingAssetId(null);
    },
    [files, setFiles]
  );

  const { progress, inputProps } = useAssetsUpload({
    type: 'pdf',
    tags: ['NOM-247'],
    onUploaded,
  });

  const handleButtonClick = useCallback(() => {
    inputRef.current?.click();
  }, []);

  const value = useMemo(() => {
    if (!progress) return;
    return Math.round((100 * progress.loaded) / progress.total);
  }, [progress]);

  return (
    <>
      <input
        {...inputProps}
        ref={inputRef}
        accept="*"
        multiple
        style={{ display: 'none' }}
      />
      <Button
        variant="outlined"
        color="primary"
        onClick={handleButtonClick}
        sx={{ mb: 1 }}
      >
        Subir archivo(s)
      </Button>
      <Box sx={{ maxWidth: 336, width: 1 }}>
        {value && (
          <>
            <Typography>Subiendo {value}%</Typography>
            <LinearProgress variant="determinate" value={value} />
          </>
        )}
        {files.map(({ assetId, name }, index) => (
          <Box
            key={assetId}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <IconButton size="small" onClick={() => onDeleted(index)}>
              <DeleteIcon />
            </IconButton>
            {editingAssetId === assetId ? (
              <>
                <IconButton size="small" onClick={onUndo}>
                  <UndoIcon />
                </IconButton>
                <IconButton size="small" onClick={() => onNameChange(index)}>
                  <SaveIcon />
                </IconButton>
                <TextField
                  inputRef={editingRef}
                  size="small"
                  variant="standard"
                  defaultValue={name}
                />
              </>
            ) : (
              <>
                <IconButton
                  size="small"
                  onClick={() => setEditingAssetId(assetId)}
                >
                  <EditIcon />
                </IconButton>
                <Typography component="span">{name}</Typography>
              </>
            )}
          </Box>
        ))}
      </Box>
    </>
  );
};

export default AttachedFiles;
