import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SITE_STATUS, TRIAL_DAYS } from "../../helpers/constants";
import { post, put } from "../../helpers/request";
import { THEME_TYPES } from "../../helpers/themes";
import { addDaysToDate } from "../../helpers/utils";
import useOnboardingContext from '../../hooks/useOnboardingContext';
import useProfile from '../../hooks/useProfile';
import useSite from '../../hooks/useSite';
import PublicLayout from '../../layouts/PublicLayout';
import { PUT_QUOTATION } from "./helpers";

const IframeContainer = styled.section`
  max-width: 1156px;
  margin-inline: auto;
  padding-inline: 2rem;
  min-height: calc(100vh - 90px - 100px);
  display: flex;
  flex-grow: 1;
`

const IFrame = styled.iframe`
  width: 100%;
  height: auto;
  border: none;
  background-color: gray;
`;

const actionLabels = {
  primary: "Terminar de editarlo",
  footerMessage: "¡Tu showroom está casi listo para ser publicado!",
}

const PREVIEW_URL = {
  subdomainBase: "https://preview-temporal",
  mainDomain: "preview.alohome.io"
}

const OnboardingPreview = ({ onDiscard }) => {
  const dispatch = useDispatch();
  const { onboardingData, onNextStep } = useOnboardingContext()
  const profile = useProfile()
  const [ newSiteId, setNewSiteId ] = useState(null)
  const tempSite = useMemo(() => `${PREVIEW_URL.subdomainBase}-${onboardingData?.tempSiteId}.${PREVIEW_URL.mainDomain}`, [onboardingData?.tempSiteId])
  const navigate = useNavigate();
  const { setSite } = useSite();

  const addProjectToProfile = useCallback(() => {
    post('/internal/login/v1/onboarding/projects',
      {
        project_name: onboardingData.projectName,
        project_domain: onboardingData.subdomain + ".alohome.io",
        assets: onboardingData.assets,
        temporal_site_id: onboardingData.tempSiteId,
        property_type: onboardingData.propertyType,
      })
      .then((data) => {
        dispatch({ type: 'reload-profile', data: true })
        setNewSiteId(data.site_id)
      })
      .catch(error => {
        console.log(error)
      })
  }, [dispatch, onboardingData.assets, onboardingData.projectName, onboardingData.propertyType, onboardingData.subdomain, onboardingData.tempSiteId])

  const handleNext = useCallback(() => {
    if(profile && !profile.error) return addProjectToProfile()
    return onNextStep()
  }, [addProjectToProfile, onNextStep, profile])

  const reloadNewSite = useCallback(async() => {
    const siteId = newSiteId
    setNewSiteId(null)
    const themeColors = THEME_TYPES.find((item) => item.value === onboardingData.theme)
    const newSite = profile.sites.find((site) => site.site_id === siteId)
    const today = new Date()

    await put('/internal/settings/v1/settings/showroom/config', {
      project_type: onboardingData.propertyType,
      theme: onboardingData.theme,
      theme_version: "1",
      draft_theme: onboardingData.theme,
      draft_theme_version: "1",
      units_qty: onboardingData.unitsQty,
      location: onboardingData.location,
      site_status: SITE_STATUS.trial,
      trial_end_date: addDaysToDate(today, TRIAL_DAYS),
      colors: themeColors.colors[0],
    }, siteId)
    await put(PUT_QUOTATION, {
      activePayments: false,
      has_online_payments: false,
      quotation_type: "flexible",
    }, siteId)

    setSite(newSite);
    navigate('/digital-showroom/editor', {replace: true});
    onDiscard()
  }, [onDiscard, navigate, newSiteId, onboardingData.location, onboardingData.propertyType, onboardingData.theme, onboardingData.unitsQty, profile.sites, setSite])

  useEffect(() => {
    if(newSiteId && profile.sites.some((site) => site.site_id === newSiteId)) {
      reloadNewSite()
    }
  }, [newSiteId, profile.sites, reloadNewSite])

  return (
    <PublicLayout
      footerMessage={actionLabels.footerMessage}
      primaryLabel={actionLabels.primary}
      onPrimaryClick={handleNext}
    >
      <IframeContainer>
        <IFrame
          src={tempSite}
          title="preview"
        />
      </IframeContainer>
    </PublicLayout>
  )
}

export default OnboardingPreview
