import { Typography } from '@mui/material'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: conic-gradient(var(--color-secondary-light) 0% ${p => p.percent}%, transparent ${p => p.percent}%);
  display: flex;
  justify-content: center;
  align-items: center;
`

const InnerContainer = styled.div`
  width: 53px;
  height: 53px;
  border-radius: 50%;
  background: #F6F7F9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`

const StepCounter = ({ currentStep, numberOfSteps }) => {
  const percent = useMemo(() => currentStep / numberOfSteps * 100, [currentStep, numberOfSteps])
  return (
    <Container percent={percent}>
      <InnerContainer>
        <Typography variant="body2">{`${currentStep}/${numberOfSteps}`}</Typography>
      </InnerContainer>
    </Container>
  )
}

export default StepCounter
