import React from "react"
import styled from "styled-components"
import { ChromePicker } from 'react-color'
import { useState } from "react"
import { useCallback } from "react"
import useHandleOutsideClick from "../../../hooks/useHandleOutsideClick"
import { useRef } from "react"
import { useEffect } from "react"

const Container = styled.div`
  position: relative;
  width: fit-content;
`

const Box = styled.button`
  background: ${p => p.color};
  border-radius: 4px;
  width: 110px;
  height: 70px;
  border: 0;
  cursor: pointer;
  border: 1px solid #eeeeee;
`

const PickerContainer = styled.div`
  position: fixed;
  left: ${p => p.left}px;
  top: ${p => p.top}px;
  z-index: 1;
`

const ColorPickerBox = ({ color, onChange }) => {
  const [viewPicker, setViewPicker] = useState(false)
  const [pickerPosition, setPickerPosition] = useState({ top: 0, left: 0 })
  const pickerRef = useHandleOutsideClick(() => setViewPicker(false))
  const boxRef = useRef(null)

  useEffect(() => {
    if (boxRef?.current) {
      const viewportOffset = boxRef.current.getBoundingClientRect();

      setPickerPosition({
        top: viewportOffset.top,
        left: viewportOffset.right + 10,
      })
    }
  }, [boxRef])

  const togglePicker = useCallback(() => setViewPicker(p => !p), [])

  return (
    <Container>
      <Box color={color} onClick={togglePicker} ref={boxRef} />
      {viewPicker && (
        <PickerContainer ref={pickerRef} top={pickerPosition.top} left={pickerPosition.left}>
          <ChromePicker color={color} onChange={onChange} disableAlpha />
        </PickerContainer>
      )}
    </Container>
  )
}

export default ColorPickerBox
