import { Box, CircularProgress } from '@mui/material';
import React, { Suspense } from 'react';
import { projectTabs } from '../../../helpers/tabsData';
import PageLayout from '../../../layouts/PageLayout';

const InventoryUploadViewLazy = React.lazy(() =>
  import('./InventoryUploadView')
);

const InventoryUploadView = () => {
  return (
    <PageLayout links={projectTabs}>
      <Suspense
        fallback={
          <Box sx={{ width: 1, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress color="primary" />
          </Box>
        }
      >
        <InventoryUploadViewLazy />
      </Suspense>
    </PageLayout>
  );
};

export default InventoryUploadView;
