import { Box, Drawer } from "@mui/material";
import React, { useCallback, useState } from "react";
import { v4 } from "uuid";
import TypologiesList from "./TypologiesList/TypologiesList";
import TypologyDetails from "./TypologyDetails/TypologyDetails";

const TypologiesManagementDrawer = ({
  typologies,
  setTypologies,
  ...drawerProps
}) => {
  const [view, setView] = useState("list");
  const [editingTypologyId, setEditingTypologyId] = useState(null);

  const closeEdit = useCallback(() => {
    setView("list");
    setEditingTypologyId(null);
  }, []);

  const onAdd = useCallback(() => {
    setView("detail");
    setEditingTypologyId(null);
  }, []);

  const onEdit = useCallback((id) => {
    setView("detail");
    setEditingTypologyId(id);
  }, []);

  const updateTypology = useCallback(
    (fields) => {
      const nTypologies = {
        ...typologies,
        [editingTypologyId]: fields,
      };
      setTypologies(nTypologies);
      closeEdit();
    },
    [typologies, setTypologies, closeEdit, editingTypologyId]
  );

  const createTypology = useCallback(
    (fields) => {
      const id = v4();
      const nTypologies = {
        ...typologies,
        [id]: fields,
      };
      setTypologies(nTypologies);
      closeEdit();
    },
    [typologies, setTypologies, closeEdit]
  );

  const deleteTypology = useCallback(
    (id) => {
      const nTypologies = {
        ...typologies,
      };
      delete nTypologies[id];
      setTypologies(nTypologies);
    },
    [typologies, setTypologies]
  );

  const isEditing = !!editingTypologyId;

  return (
    <Drawer
      {...drawerProps}
      onClose={view === "list" ? drawerProps.onClose : undefined}
    >
      <Box sx={{ display: "flex", flexDirection: "column", p: 3 }}>
        {view === "list" && typologies && (
          <TypologiesList
            typologies={typologies}
            onAdd={onAdd}
            onEdit={onEdit}
            onDelete={deleteTypology}
            onClose={drawerProps.onClose}
          />
        )}
        {view === "detail" && (
          <TypologyDetails
            fields={typologies[editingTypologyId]}
            isEditing={isEditing}
            onSubmit={isEditing ? updateTypology : createTypology}
            onClose={closeEdit}
          />
        )}
      </Box>
    </Drawer>
  );
};

export default TypologiesManagementDrawer;
