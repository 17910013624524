import { Save as SaveIcon } from '@mui/icons-material';
import {
  alpha,
  Box,
  Button,
  Grid,
  List,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useLoadScript } from '@react-google-maps/api';
import { ReactGrid } from '@silevis/reactgrid';
import '@silevis/reactgrid/styles.css';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import scstyled from 'styled-components';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import { Map, Spacer } from '../../components';
import LocationOption from '../../components/PlacesAutocomplete/LocationOption';
import { get, patch, post } from '../../helpers/request';
import useLocale from '../../hooks/useLocale';
import useProfile from '../../hooks/useProfile';
import useSite from '../../hooks/useSite';
import { BulkEditor } from './BulkEditor';
import { FilterBar } from './CustomFilterBar';
import { IntelimetricaLayout } from './Layout';
import {
  buttonsSx,
  filterConditions,
  property_classes,
  property_types,
  units_columns,
  units_header_row,
} from './util';

const mapLibraries = ['places'];

const FieldContainer = scstyled(TextField)`
  width: 100%;
`;

const SelectContainer = scstyled(Select)`
  width: 100%;
`;

const MapContainer = scstyled.div`
  max-height: 336px;
`;

const LocationList = scstyled(List)`
  max-width: 28rem;
  max-height: 240px;
  overflow-y: auto;
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  background-color: #fff;
  border: 1px solid #dedede;
  border-top: none;
  border-radius: 4px;
`;

const ButtonsContainer = styled('div')(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  width: '100%',
  minHeight: '106px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: `linear-gradient(${alpha(
    theme.palette.background.default,
    0.4
  )}, ${theme.palette.background.default} 40%)`,
}));

const ReactGridContainer = scstyled.div`
  max-height: 70vh;
  max-width: 100%;
  overflow: auto;
  .rg-cell[data-cell-rowidx='0'] {
    background-color: var(--color-primary-10) !important;
    color: var(--color-primary) !important;
  }
  .rg-cell {
    text-align: center;
  }
  .rg-checkbox-cell > label > span {
    border: 2px solid var(--color-text-secondary) !important;
    background-color: transparent !important;
  }
  .reactgrid-content
    .rg-pane
    .rg-cell.rg-checkbox-cell
    label
    input:checked
    ~ span {
    background-color: var(--color-primary) !important;
  }
  .rg-checkbox-cell > label > span::after {
    border-width: 0 2px 2px 0 !important;
  }
`;

const ConfigIntelimetricaLoaded = () => {
  const [openBulkEditor, setOpenBulkEditor] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [rowHeaders, setRowHeaders] = useState(units_header_row);
  const [unitsColumns, setUnitsColumns] = useState(units_columns);
  const [projectType, setProjectType] = useState('');
  const [rows, setRows] = useState([]);
  const saveReferenceRows = useRef(null);
  const locale = useLocale();

  const checkedRows = useMemo(() => {
    const checkRows = rows.filter((row) => row.cells[0].checked);
    return checkRows;
  }, [rows]);

  const autocomplete = usePlacesAutocomplete();
  const [formButtonDisabled, setFormButtonDisabled] = useState(false);
  const profile = useProfile();
  const { site } = useSite();
  const [filterColumns, setFilterColumns] = useState([]);
  const [filters, setFilters] = useState(null);
  const navigate = useNavigate();

  const [form, setForm] = useState({
    federal_entity: '',
    municipality: '',
    property_class: '',
    property_type: '',
    location: undefined,
    suburb: '',
    street: '',
    exterior_number: '',
    zip_code: '',
  });

  const isHouse = useMemo(
    () => form.property_type === 2 || form.property_type === 3,
    [form.property_type]
  );

  const applyChanges = (changes, prev) => {
    let newRowHeader = rowHeaders;
    changes.forEach((change) => {
      const rowName = change.rowId;
      const fieldName = change.columnId;
      const columnIndex = unitsColumns.indexOf(
        unitsColumns.find((c) => c.columnId === fieldName)
      );
      const rowIndex = rows.indexOf(rows.find((c) => c.rowId === rowName));
      if (change.rowId === 'header' && fieldName === 'checkbox') {
        newRowHeader.cells[columnIndex] = change.newCell;
        prev = prev.map((r) => {
          r.cells[columnIndex] = change.newCell;
          return { ...r };
        });
      } else {
        prev[rowIndex].cells[columnIndex] = change.newCell;
      }
    });
    setRowHeaders(newRowHeader);
    return [...prev];
  };

  const handleGridChanges = (changes) => {
    setRows((prev) => applyChanges(changes, prev));
  };

  const handleForm = (name, value) => {
    setForm((form) => ({ ...form, [name]: value }));
  };

  const center = useMemo(
    () => ({
      lat: 24.036590303251916,
      lng: -102.44471879657337,
    }),
    []
  );

  const onSelect = useCallback(
    ({ lat, lng, address }) =>
      setForm((form) => ({
        ...form,
        location: {
          lat,
          lng,
          address,
        },
      })),
    []
  );

  const handleSelect = async (address) => {
    autocomplete.setValue(address, false);
    autocomplete.clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    onSelect({ address, lat, lng });
  };

  const validateForm = (data, rows) => {
    let validRows = true;
    for (let row of rows) {
      for (let cell of row.cells) {
        const value = cell.type === 'number' ? cell.value : cell.text;
        if (
          (value === undefined ||
            value === null ||
            value === '' ||
            isNaN(value)) &&
          cell.type !== 'checkbox' &&
          !cell.nonEditable
        ) {
          validRows = false;
          break;
        }
      }
    }
    if (
      !data.location ||
      data.property_class === null ||
      data.property_class === undefined ||
      data.property_class === '' ||
      !data.property_type ||
      !validRows
    ) {
      return false;
    }
    return true;
  };

  const onSaveBulk = (_, form, checked) => {
    const newCheckedRows = checkedRows;
    const formArray = Object.entries(form);
    newCheckedRows.forEach((_, index) => {
      for (let item of formArray) {
        const itemName = item[0];
        const newValue = item[1];
        const column = rowHeaders.cells.find((c) => c.bulk_name === itemName);
        const columnIndex = rowHeaders.cells.indexOf(column);
        if (checked[itemName]) {
          const type = column.bulkType;
          newCheckedRows[index].cells[columnIndex][
            type === 'number' ? 'value' : 'text'
          ] = type === 'number' ? Number(newValue) : newValue;
        }
      }
    });
    const newRows = [];
    for (let row of rows) {
      const newRow = newCheckedRows.find((nr) => nr.rowId === row.rowId);
      if (!!newRow) {
        newRows.push(newRow);
      } else {
        newRows.push(row);
      }
    }
    setRows(newRows);
    setOpenBulkEditor(false);
  };

  const saveAndContinueLater = () => {
    const inventory = [];
    for (let row of rows) {
      const newRow = row.data;
      const cells = row.cells;
      for (let col of unitsColumns) {
        const columnId = col.columnId;
        const colIndex = unitsColumns.indexOf(col);
        const cell = cells[colIndex];
        const cellType = cell.type;
        newRow.fields[columnId] =
          cellType === 'number' ? cell.value : cell.text;
      }
      inventory.push(newRow);
    }
    return Promise.all([
      post(
        `/internal/alohub/integrations/intelimetrica/config/project-data`,
        {
          property_class: form.property_class,
          property_type: form.property_type || undefined,
          lat: form?.location?.lat,
          lng: form?.location?.lng,
          address: form?.location?.address,
          country: 'MX',
        },
        site.site_id
      ),
      patch(
        '/internal/alohub/integrations/intelimetrica/inventory',
        {
          items: [...inventory],
        },
        site.site_id
      ),
    ]).then(() =>
      setRows((prev) => {
        const nRows = [...prev];
        saveReferenceRows.current = nRows;
        return nRows;
      })
    );
  };

  useEffect(() => {
    const validation = validateForm(form, rows);
    setFormButtonDisabled(!validation);
  }, [form, rows]);

  useEffect(() => {
    setForm((form) => ({ ...form, municipality: '' }));
  }, [form.federal_entity]);

  useEffect(() => {
    if (site?.site_id && autocomplete.ready) {
      get(`/internal/alohub/integrations/intelimetrica/config/project-data`, {
        site_id: site.site_id,
      }).then((result) => {
        setForm((form) => ({
          ...form,
          property_class:
            result.property_class !== undefined ? result.property_class : '',
          property_type:
            result.property_type !== undefined ? result.property_type : '',
          location: !result.address
            ? undefined
            : {
                lat: result?.lat || '',
                lng: result?.lng || '',
                address: result?.address || '',
              },
        }));
        if (result.address) {
          autocomplete.setValue(result.address, false);
        }
      });
    }
    // eslint-disable-next-line
  }, [site?.site_id, autocomplete.setValue, autocomplete.ready]);
  useEffect(() => {
    const customUnitColumns = units_columns;
    const customRowHeaders = units_header_row;
    setUnitsColumns(
      !isHouse
        ? customUnitColumns?.filter(
            ({ columnId }) => columnId !== 'construction_size'
          )
        : customUnitColumns
    );
    setRowHeaders({
      ...units_header_row,
      cells: !isHouse
        ? customRowHeaders.cells.filter(
            ({ bulk_name }) => bulk_name !== 'construction_size'
          )
        : customRowHeaders.cells,
    });
  }, [isHouse]);

  useEffect(() => {
    if (site?.site_id) {
      Promise.all([
        get('/internal/alohub/inventory?type=typology', {
          site_id: site?.site_id,
        }),
        get('/internal/alohub/inventory?type=unit', {
          site_id: site?.site_id,
        }),
      ]).then(([typologies, units]) => {
        const inventory = [...typologies, ...units];
        const projectType = typologies.length > 0 ? 'typology' : 'unit';

        const array = [];
        const firstItem = inventory[0];
        if (firstItem) {
          setFilterColumns(
            Object.entries(firstItem.fields)
              .filter(([key, value]) => typeof value !== 'object')
              .map(([key]) => ({ label: key, value: key }))
          );
        }
        for (let item of inventory) {
          let cells = [
            { type: 'checkbox', checked: false },
            {
              type: 'text',
              text:
                projectType === 'typology'
                  ? item?.fields?.name[locale?.substring(0, 2) || 'es']
                  : item?.id,
              nonEditable: true,
            },
            {
              type: 'number',
              value: Number(item?.fields?.bedrooms),
              placeholder: '-',
              required: true,
            },
            {
              type: 'number',
              value: Number(item?.fields?.bathrooms),
              placeholder: '-',
              required: true,
            },
            {
              type: 'number',
              value: Number(item?.fields?.half_bathrooms),
              placeholder: '-',
              required: true,
            },
            {
              type: 'number',
              value: Number(item?.fields?.parking_lots),
              placeholder: '-',
              required: true,
            },
            {
              type: 'number',
              value: Number(item?.fields?.interior_m2),
              placeholder: '-',
              required: true,
            },
            {
              type: 'number',
              name: 'construction_size',
              value: Number(item?.fields?.construction_size),
              placeholder: '-',
              required: true,
            },
          ];
          if (!isHouse) {
            cells = cells.filter(({ name }) => name !== 'construction_size');
          }
          array.push({
            rowId: item.inventory_id,
            data: item,
            cells,
          });
        }
        saveReferenceRows.current = array;
        setRows(array);
        setProjectType(projectType);
      });
    }
  }, [site?.site_id, locale, isHouse]);

  const generateReport = () => {
    saveAndContinueLater().then(() =>
      post(
        `/internal/alohub/integrations/intelimetrica/generate-reports?type=${projectType}`,
        {},
        site.site_id
      ).then(() => {
        navigate(`../generating-report`, { replace: true });
      })
    );
  };

  const filteredRows = useMemo(() => {
    let customRows = rows;
    if (filters) {
      switch (filters.condition) {
        case 'contains':
          customRows = customRows.filter((row) =>
            `${row.data.fields[filters.column]}`.includes(filters.value)
          );
          break;
        case 'equals_to':
          customRows = customRows.filter(
            (row) => `${row.data.fields[filters.column]}` === filters.value
          );
          break;
        case 'starts_with':
          customRows = customRows.filter((row) =>
            `${row.data.fields[filters.column]}`.startsWith(filters.value)
          );
          break;
        case 'ends_with':
          customRows = customRows.filter((row) =>
            `${row.data.fields[filters.column]}`.endsWith(filters.value)
          );
          break;
        case 'empty':
          customRows = customRows.filter(
            (row) => !`${row.data.fields[filters.column]}`
          );
          break;
        case 'not_empty':
          customRows = customRows.filter(
            (row) => !!`${row.data.fields[filters.column]}`
          );
          break;
        case 'any_of':
          customRows = customRows.filter((row) =>
            filters.values.includes(`${row.data.fields[filters.column]}`)
          );
          break;
        default:
          break;
      }
    }
    return customRows;
  }, [rows, filters]);

  const canSave = !!saveReferenceRows && saveReferenceRows.current !== rows;

  return (
    <IntelimetricaLayout
      avoidReportRedirect
      site={site}
      layoutMenu={
        <Button
          sx={buttonsSx}
          startIcon={<SaveIcon />}
          disabled={!canSave}
          onClick={saveAndContinueLater}
        >
          GUARDAR
        </Button>
      }
    >
      <Box sx={{ position: 'relative' }}>
        <Spacer size={4} vertical />
        <Typography variant="h5">
          ¡Hagamos el reporte más preciso y personalizado!
        </Typography>
        <Spacer size={4} vertical />
        <Typography>
          {profile.name}, necesitamos un poco de tu ayuda.
        </Typography>
        <Spacer size={4} vertical />
        <Typography>
          Con los datos que compartas podremos analizar aún más las propiedades
          que están alrededor de tu proyecto inmobiliario e incrementar la
          precisión de los precios estimados.
        </Typography>
        <Spacer size={4} vertical />
        <Box
          sx={{
            py: 1,
            px: 4,
            mb: 4,
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
            borderRadius: 4,
          }}
        >
          <Typography
            sx={{
              textTransform: 'uppercase',
              color: 'primary.main',
              fontWeight: 500,
            }}
          >
            DATOS GLOBALES
          </Typography>
        </Box>
        <Typography>*Campos obligatorios</Typography>
        <Spacer size={4} vertical />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FieldContainer
              label="*Dirección"
              name="name"
              variant="standard"
              value={autocomplete.value}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!autocomplete.ready}
              onChange={(e) => autocomplete.setValue(e.target.value)}
            />
            <LocationList hidden={!autocomplete?.suggestions?.status}>
              {autocomplete?.suggestions?.status === 'OK' &&
                autocomplete?.suggestions?.data?.map(
                  ({ place_id, description }) => (
                    <LocationOption
                      key={place_id}
                      value={description}
                      label={description}
                      onClick={handleSelect}
                      sx={{ color: 'text.primary' }}
                    />
                  )
                )}
            </LocationList>
            <Spacer size={6} vertical />
            <SelectContainer
              variant="standard"
              name="property_class"
              value={form.property_class}
              onChange={({ target }) => handleForm(target.name, target.value)}
              displayEmpty
            >
              <MenuItem value="">Clase del inmueble</MenuItem>
              {property_classes.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </SelectContainer>
            <Spacer size={6} vertical />
            <SelectContainer
              variant="standard"
              name="property_type"
              value={form.property_type}
              onChange={({ target }) => handleForm(target.name, target.value)}
              displayEmpty
            >
              <MenuItem value="">*Tipo de inmueble</MenuItem>
              {property_types.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </SelectContainer>
          </Grid>
          <Grid item xs={6}>
            <MapContainer>
              <Map selected={form.location} center={center} />
            </MapContainer>
          </Grid>
        </Grid>
        <Spacer size={4} vertical />
        <Box
          sx={{
            py: 1,
            px: 4,
            mb: 4,
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
            borderRadius: 4,
          }}
        >
          <Typography
            sx={{
              textTransform: 'uppercase',
              color: 'primary.main',
              fontWeight: 500,
            }}
          >
            Unidades
          </Typography>
        </Box>
        <Button
          sx={buttonsSx}
          onClick={() => setOpenBulkEditor(true)}
          disabled={checkedRows.length <= 0}
          variant="contained"
          color="primary"
        >
          EDITAR SELECCIONADOS
        </Button>
        <Spacer size={2} vertical />
        <FilterBar
          closeModal={() => setOpenFilters(false)}
          open={openFilters}
          setFilters={setFilters}
          conditions={filterConditions}
          columns={filterColumns}
        >
          <Button onClick={() => setOpenFilters(true)} color="primary">
            <FilterIcon />
            <Spacer size={1} />
            FILTROS
          </Button>
        </FilterBar>
        <Spacer size={2} vertical />
        <ReactGridContainer>
          <ReactGrid
            rows={[rowHeaders, ...filteredRows]}
            columns={unitsColumns}
            onCellsChanged={handleGridChanges}
            stickyLeftColumns={2}
            stickyTopRows={1}
          />
        </ReactGridContainer>
        <ButtonsContainer>
          <Button
            sx={buttonsSx}
            variant="contained"
            color="primary"
            disabled={formButtonDisabled}
            onClick={generateReport}
          >
            GENERAR REPORTE
          </Button>
        </ButtonsContainer>
        <BulkEditor
          columns={rowHeaders?.cells || []}
          isOpen={openBulkEditor}
          onClose={() => setOpenBulkEditor(false)}
          onSave={onSaveBulk}
        />
      </Box>
    </IntelimetricaLayout>
  );
};

export const ConfigIntelimetrica = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: mapLibraries,
  });
  return isLoaded && !loadError ? (
    <ConfigIntelimetricaLoaded {...props} />
  ) : null;
};
