import { Delete as DeleteIcon } from "@mui/icons-material";
import {
  FormControl,
  FormGroup,
  FormHelperText,
  IconButton,
  MenuItem,
  styled,
  TextField
} from "@mui/material";
import React from "react";
import AirPlaneIcon from "../../assets/icons/airplane.svg";
import AmazonRoomIcon from "../../assets/icons/amazon_room.svg";
import AquariumIcon from "../../assets/icons/aquarium.svg";
import ArqueologicaIcon from "../../assets/icons/arqueologica.svg";
import BalconyIcon from "../../assets/icons/balcony.svg";
import BarIcon from "../../assets/icons/bar.svg";
import BedIcon from "../../assets/icons/bed.svg";
import BikeIcon from "../../assets/icons/bike.svg";
import BocceIcon from "../../assets/icons/bocce.svg";
import BuildingIcon from "../../assets/icons/building.svg";
import BusIcon from "../../assets/icons/bus.svg";
import BusinessIcon from "../../assets/icons/business.svg";
import CableCarIcon from "../../assets/icons/cable_car.svg";
import PoolChairIcon from "../../assets/icons/camastro.svg";
import ClimbingIcon from "../../assets/icons/climbing.svg";
import ClosetIcon from "../../assets/icons/closet.svg";
import CodeIcon from "../../assets/icons/code.svg";
import CoffeeIcon from "../../assets/icons/coffee.svg";
import ConciergeIcon from "../../assets/icons/concierge.svg";
import CourtIcon from "../../assets/icons/court.svg";
import DeskIcon from "../../assets/icons/desk.svg";
import DiningRoomIcon from "../../assets/icons/diningroom.svg";
import DoubleHeightIcon from "../../assets/icons/doble_altura.svg";
import DowntownIcon from "../../assets/icons/downtown.svg";
import EyeIcon from "../../assets/icons/eye.svg";
import FarmIcon from "../../assets/icons/farm_2.svg";
import FilmIcon from "../../assets/icons/film.svg";
import FirePitIcon from "../../assets/icons/fire_pit.svg";
import FitnessIcon from "../../assets/icons/fitness.svg";
import FoodIcon from "../../assets/icons/food.svg";
import FountainIcon from "../../assets/icons/fuente.svg";
import GolfIcon from "../../assets/icons/golf.svg";
import VineyardIcon from "../../assets/icons/grapes.svg";
import GrillIcon from "../../assets/icons/grill_3.svg";
import HorseIcon from "../../assets/icons/horse.svg";
import HospitalIcon from "../../assets/icons/hospital.svg";
import JacuzziIcon from "../../assets/icons/jacuzzi_2.svg";
import JoggingIcon from "../../assets/icons/jogging.svg";
import KeyIcon from "../../assets/icons/key.svg";
import KitchenIcon from "../../assets/icons/kitchen.svg";
import LakeIcon from "../../assets/icons/lago_2.svg";
import Laundry2Icon from "../../assets/icons/laundry_2.svg";
import LevelsIcon from "../../assets/icons/levels.svg";
import LienzoIcon from "../../assets/icons/lienzo.svg";
import PierIcon from "../../assets/icons/malecon.svg";
import MarinaIcon from "../../assets/icons/marina.svg";
import MuseumIcon from "../../assets/icons/museum_2.svg";
import NatureIcon from "../../assets/icons/nature.svg";
import ParkingIcon from "../../assets/icons/parking.svg";
import PeopleIcon from "../../assets/icons/people_2.svg";
import ZooIcon from "../../assets/icons/pet.svg";
import PetIcon from "../../assets/icons/pet_2.svg";
import PhoneIcon from "../../assets/icons/phone.svg";
import PingPongIcon from "../../assets/icons/ping-pong.svg";
import PlaneIcon from "../../assets/icons/plane.svg";
import PlaygroundIcon from "../../assets/icons/playground.svg";
import SaunaIcon from "../../assets/icons/sauna_2.svg";
import SchoolIcon from "../../assets/icons/school.svg";
import SecurityCameraIcon from "../../assets/icons/security_camera.svg";
import ShieldIcon from "../../assets/icons/shield.svg";
import ShoppingIcon from "../../assets/icons/shopping_bag.svg";
import ShowerIcon from "../../assets/icons/shower.svg";
import ShowerHeadIcon from "../../assets/icons/shower_2.svg";
import SportsIcon from "../../assets/icons/sports.svg";
import SportsBarIcon from "../../assets/icons/sport_bar.svg";
import TerraceIcon from "../../assets/icons/terraza.svg";
import ThemeParkIcon from "../../assets/icons/theme_park.svg";
import ToolIcon from "../../assets/icons/tool.svg";
import BullIcon from "../../assets/icons/toro.svg";
import TricycleIcon from "../../assets/icons/tricycle.svg";
import BeachIcon from "../../assets/icons/umbrella.svg";
import VideoGamesIcon from "../../assets/icons/video_games.svg";
import VolumeIcon from "../../assets/icons/volume.svg";
import WaterIcon from "../../assets/icons/water.svg";
import WorkIcon from "../../assets/icons/work.svg";
import YogaIcon from "../../assets/icons/yoga_2.svg";

import FlexContainer from "../FlexContainer/FlexContainer";

export const DEFAULT_ICONS = [
  { value: "[:bed:]", src: BedIcon, alt: "habitación" },
  { value: "[:closet:]", src: ClosetIcon, alt: "closet" },
  { value: "[:shower:]", src: ShowerIcon, alt: "baño" },
  { value: "[:desk:]", src: DeskIcon, alt: "estudio" },
  { value: "[:parking:]", src: ParkingIcon, alt: "parqueadero" },
  { value: "[:levels:]", src: LevelsIcon, alt: "niveles" },
  { value: "[:plane:]", src: PlaneIcon, alt: "plano" },
  { value: "[:balcony:]", src: BalconyIcon, alt: "balcón" },
  { value: "[:terraza:]", src: TerraceIcon, alt: "terraza" },
  { value: "[:bar:]", src: BarIcon, alt: "bar" },
  { value: "[:eye:]", src: EyeIcon, alt: "vista" },
  { value: "[:work:]", src: WorkIcon, alt: "trabajo" },
  { value: "[:water:]", src: WaterIcon, alt: "agua" },
  { value: "[:laundry_2:]", src: Laundry2Icon, alt: "laundry" },
  { value: "[:amazon_room:]", src: AmazonRoomIcon, alt: "closet" },
  { value: "[:airplane:]", src: AirPlaneIcon, alt: "aeropuerto" },
  { value: "[:aquarium:]", src: AquariumIcon, alt: "acuario" },
  { value: "[:arqueologica:]", src: ArqueologicaIcon, alt: "zona arqueológica" },
  { value: "[:umbrella:]", src: BeachIcon, alt: "playa" },
  { value: "[:bike:]", src: BikeIcon, alt: "bicicleta" },
  { value: "[:bocce:]", src: BocceIcon, alt: "bocce" },
  { value: "[:building:]", src: BuildingIcon, alt: "edificio" },
  { value: "[:bus:]", src: BusIcon, alt: "bus" },
  { value: "[:business:]", src: BusinessIcon, alt: "trabajo" },
  { value: "[:cable_car:]", src: CableCarIcon, alt: "teleférico" },
  { value: "[:camastro:]", src: PoolChairIcon, alt: "asoleadero" },
  { value: "[:climbing:]", src: ClimbingIcon, alt: "escalar" },
  { value: "[:code:]", src: CodeIcon, alt: "code" },
  { value: "[:coffee:]", src: CoffeeIcon, alt: "café" },
  { value: "[:concierge:]", src: ConciergeIcon, alt: "concierge" },
  { value: "[:court:]", src: CourtIcon, alt: "court" },
  { value: "[:diningroom:]", src: DiningRoomIcon, alt: "diningroom" },
  { value: "[:doble_altura:]", src: DoubleHeightIcon, alt: "doble altura" },
  { value: "[:downtown:]", src: DowntownIcon, alt: "downtown" },
  { value: "[:farm_2:]", src: FarmIcon, alt: "granja" },
  { value: "[:film:]", src: FilmIcon, alt: "film" },
  { value: "[:fire_pit:]", src: FirePitIcon, alt: "fogata" },
  { value: "[:fitness:]", src: FitnessIcon, alt: "fitness" },
  { value: "[:food:]", src: FoodIcon, alt: "comida" },
  { value: "[:fuente:]", src: FountainIcon, alt: "fuente" },
  { value: "[:golf:]", src: GolfIcon, alt: "golf" },
  { value: "[:grill_3:]", src: GrillIcon, alt: "asadero" },
  { value: "[:horse:]", src: HorseIcon, alt: "caballo" },
  { value: "[:hospital:]", src: HospitalIcon, alt: "hospital" },
  { value: "[:jacuzzi_2:]", src: JacuzziIcon, alt: "jacuzzi" },
  { value: "[:jogging:]", src: JoggingIcon, alt: "jogging" },
  { value: "[:key:]", src: KeyIcon, alt: "llave" },
  { value: "[:kitchen:]", src: KitchenIcon, alt: "cocina" },
  { value: "[:lago_2:]", src: LakeIcon, alt: "lago" },
  { value: "[:lienzo:]", src: LienzoIcon, alt: "lienzo" },
  { value: "[:malecon:]", src: PierIcon, alt: "malecón" },
  { value: "[:marina:]", src: MarinaIcon, alt: "marina" },
  { value: "[:museum_2:]", src: MuseumIcon, alt: "museo" },
  { value: "[:nature:]", src: NatureIcon, alt: "naturaleza" },
  { value: "[:people_2:]", src: PeopleIcon, alt: "persona" },
  { value: "[:pet_2:]", src: PetIcon, alt: "mascota" },
  { value: "[:phone:]", src: PhoneIcon, alt: "teléfono" },
  { value: "[:ping-pong:]", src: PingPongIcon, alt: "ping-pong" },
  { value: "[:playground:]", src: PlaygroundIcon, alt: "área de juegos" },
  { value: "[:sauna_2:]", src: SaunaIcon, alt: "sauna" },
  { value: "[:school:]", src: SchoolIcon, alt: "escuela" },
  { value: "[:security_camera:]", src: SecurityCameraIcon, alt: "cámaras de seguridad" },
  { value: "[:shield:]", src: ShieldIcon, alt: "seguridad" },
  { value: "[:shopping_bag:]", src: ShoppingIcon, alt: "centro comercial" },
  { value: "[:shower_2:]", src: ShowerHeadIcon, alt: "regaderas" },
  { value: "[:sport_bar:]", src: SportsBarIcon, alt: "sport bar" },
  { value: "[:sports:]", src: SportsIcon, alt: "sports" },
  { value: "[:theme_park:]", src: ThemeParkIcon, alt: "theme park" },
  { value: "[:tool:]", src: ToolIcon, alt: "tool" },
  { value: "[:toro:]", src: BullIcon, alt: "toro" },
  { value: "[:tricycle:]", src: TricycleIcon, alt: "triciclo" },
  { value: "[:video_games:]", src: VideoGamesIcon, alt: "videojuegos" },
  { value: "[:grapes:]", src: VineyardIcon, alt: "viñedo" },
  { value: "[:volume:]", src: VolumeIcon, alt: "volumen" },
  { value: "[:yoga_2:]", src: YogaIcon, alt: "yoga" },
  { value: "[:pet:]", src: ZooIcon, alt: "zoológico" },
];

const IconPreview = styled("img")({
  display: "block",
  width: 23,
  height: 23,
});

const DeleteButton = styled(IconButton)`
  padding: 2px;
`

const IconInput = ({
  error,
  label,
  helperText,
  text,
  icon,
  onTextChange,
  onIconChange,
  onDelete,
  maxLength,
  icons = DEFAULT_ICONS,
}) => (
  <FormControl error={error}>
    <FormGroup row>
      <TextField
        error={error}
        variant="standard"
        value={icon}
        onChange={onIconChange}
        label=" "
        select
      >
        {icons.map(({ value, src, alt }) => (
          <MenuItem key={value} value={value}>
            {<IconPreview src={src} alt={alt} />}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        error={error}
        variant="standard"
        value={text}
        onChange={onTextChange}
        label={label}
        sx={{ flex: 1 }} 
      />
    </FormGroup>
    <FlexContainer justified verticalCentered>
      {helperText && <FormHelperText variant="standard">{helperText}</FormHelperText>}
      {!helperText && maxLength && (
        <FormHelperText variant="standard">{`${text?.length || 0}/${maxLength}`}</FormHelperText>
      )}
      {!helperText && !maxLength && onDelete && <div />}
      {onDelete && (
        <DeleteButton size="small" onClick={onDelete}>
          <DeleteIcon />
        </DeleteButton>
      )}
    </FlexContainer>
  </FormControl>
);

export default IconInput;
