import {
  OutlinedInput,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FlexContainer, Spacer } from "../../components";
import { post } from "../../helpers/request";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import PublicLayout from "../../layouts/PublicLayout";
import { Link, useSearchParams } from "react-router-dom";
import useOnKeyDown from "../../hooks/useOnKeyDown";
import { clientIdentify, trackEvent } from "../../helpers/mixpanel";

const FormContent = styled.div`
  max-width: 700px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  field-content {
    display: flex;
    width: 100%;

    @media only screen and (max-width: 832px) {
      flex-direction: column;
      padding: 16px;
    }
  }
  a {
    color: var(--color-text-primary);
  }
`;
const StyledAlert = styled(Alert)`
  align-self: stretch;
`;
const LabelField = styled(Typography)`
  padding: 8px 0;
`;

const LoginView = () => {
  const [searchParams] = useSearchParams();
  const successMessage = searchParams.get("successMessage");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const handleChangePassword = useCallback((event) => {
    setPassword(event.target.value);
  }, []);

  const handleChangeEmail = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const handleShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleLogin = useCallback(() => {
    post("/internal/login/v1/login", { email, password })
      .then((result) => {
        if (result.mfaToken) {
          navigate("/verify", {
            state: {
              mfaToken: result.mfaToken,
              email,
              password,
            },
          });
        } else {
          dispatch({ type: "set-profile", data: result.user });
          clientIdentify(email)
          trackEvent('login')
          setError(false);
        }
      })
      .catch((error) => {
        if (error.data && error.data.deactivated) {
          setError(
            "Su cuenta está desactivada, por favor contáctese a " + process.env.REACT_APP_SUPPORT_MAIL
          );
        } else {
          setError("Usuario o contraseña incorrectas");
        }
      });
  }, [email, password, navigate, dispatch]);

  useOnKeyDown("Enter", handleLogin)

  return (
    <PublicLayout onPrimaryClick={handleLogin} primaryLabel={"iniciar sesión"}>
      <FlexContainer centered fullWidth vertical>
        <Spacer vertical size={5} />
        <Typography variant="h2">Iniciar sesión</Typography>
        <Spacer vertical size={10} />
        <FormContent>
          <field-content>
            <FormControl variant="filled" fullWidth>
              <LabelField>¿Cuál es tu correo electrónico?</LabelField>
              <TextField
                id="login-email"
                variant="outlined"
                value={email}
                fullWidth
                onChange={handleChangeEmail}
              />
            </FormControl>
            <Spacer horizontal size={2} />
            <FormControl fullWidth>
              <LabelField>Contraseña</LabelField>
              <OutlinedInput
                id="login-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handleChangePassword}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <LabelField>
                <Link to="/forgot-password">No recuerdo mi contraseña</Link>
              </LabelField>
            </FormControl>
          </field-content>

          {(error || successMessage) && (
            <>
              <Spacer vertical size={2} />
              <FlexContainer fullWidth centered vertical>
                <StyledAlert severity={successMessage ? "success" : "error"}>
                  {successMessage ? successMessage : error}
                </StyledAlert>
              </FlexContainer>
            </>
          )}
          <Spacer vertical size={4} />
          <Typography>
            ¿Aún no tienes una cuenta? <Link to="/register">Registrar</Link>
          </Typography>
        </FormContent>
      </FlexContainer>
    </PublicLayout>
  );
};

export default LoginView;
