import { alpha, Box, Typography } from '@mui/material';
import React from 'react';

const Section = ({ title, children, id }) => {
  return (
    <section id={id}>
      <Box
        sx={{
          py: 1,
          px: 4,
          mb: 4,
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
          borderRadius: 4,
        }}
      >
        <Typography
          sx={{
            textTransform: 'uppercase',
            color: 'primary.main',
            fontWeight: 500,
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          letterSpacing: 1,
          mb: 1,
        }}
      >
        <Typography
          component="span"
          variant="overline"
          sx={{ textTransform: 'uppercase', color: 'text.secondary' }}
        >
          ¿Cuentas con esta información?
        </Typography>
        <Typography
          component="span"
          variant="overline"
          sx={{ textTransform: 'uppercase', color: 'text.secondary' }}
        >
          Sección de la NOM-247
        </Typography>
      </Box>
      <Box sx={{ '& > *': { mb: 4 } }}>{children}</Box>
    </section>
  );
};

export default Section;
