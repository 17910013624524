import React from 'react'
import styled from 'styled-components';
import { Paper, Typography } from '@mui/material'
import { FlexContainer, Spacer } from '../../components'

const Container = styled(Paper)`
  width: 250px;
  height: 250px;
  cursor: pointer;
  padding: 60px 24px 24px 24px;
  &:hover {
    border-color: #3F51B5;
  }
`

const Title = styled(Typography)`
  font-weight: 500;
`

const ChangeCard = ({ icon: Icon, title, description, changeLabel, onClick }) => (
  <Container variant="outlined" onClick={onClick}>
    <FlexContainer vertical centered>
      <FlexContainer vertical fullWidth centered>
        <Icon color="primary" fontSize="large" />
        <Spacer vertical size={2} />
        <Title variant="body1" color="primary">{title}</Title>
      </FlexContainer>
      <Spacer vertical size={2} />
      <Typography variant="caption">{description}</Typography>
      <Spacer vertical size={2} />
    </FlexContainer>
  </Container>
)

export default ChangeCard
