import { Alert, Box, Button, Chip, IconButton, Link, Typography } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import styled from 'styled-components';
import { FlexContainer, Spacer, UserPhoto } from '../../components';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { SALES_TYPES, USER_SITE_STATUS } from '../../helpers/constants';

export const ALERT_TYPE = {
  add: "add",
  delete: "delete",
}

const StyledGrid = styled(DataGrid)`
  border: 0;

  .MuiDataGrid-cell {
    border: 0;
  }

  .MuiDataGrid-footerContainer {
    border: 0;
  }

  .MuiDataGrid-columnHeaders {
    border: 0;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
  }
`

const getColumns = (onEdit, onDelete) => ([
  {
    field: 'user',
    headerName: '',
    renderCell: (params) => (
      <UserPhoto
        name={params.row.name}
        photoUrl={params.row.avatarId ? `${process.env.REACT_APP_IMAGES_URL}/${params.row.avatarId}?s=144x144&fit=cover` : null}
        sx={{ opacity: params.row.status === USER_SITE_STATUS.deactivated ? .5 : 1 }}
      />
    ),
    sortable: false,
    disableClickEventBubbling: true,
    disableColumnFilter: true,
    disableColumnMenu: true,
    disableColumnSelector: true,
    width: 60,
  },
  {
    field: 'name',
    headerName: 'Nombre',
    renderCell: (params) => (
      <Typography variant="body2" sx={{ opacity: params.row.status === USER_SITE_STATUS.deactivated ? .5 : 1 }}>
        {`${params.row.name ?? ''} ${params.row.lastname ?? ''}`}
      </Typography>
    ),
    flex: 1,
    disableColumnSelector: true,
    disableColumnMenu: true,
  },
  {
    field: 'email',
    headerName: 'Correo electrónico',
    renderCell: (params) => (
      <Link
        href={`mailto:${params.row.email}`}
        sx={{ opacity: params.row.status === USER_SITE_STATUS.deactivated ? .5 : 1 }}
      >
        {params.row.email}
      </Link>
    ),
    flex: 1,
    disableColumnSelector: true,
    disableColumnMenu: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: (params) => (
      <>
        {params.row.status === USER_SITE_STATUS.deactivated && (
          <Chip icon={<CloseIcon />} label="Inactivo" variant="filled" size="small" />
        )}
        {params.row.status === USER_SITE_STATUS.active && (
          <Chip icon={<CheckIcon />} label="Activo" variant="outlined" size="small" color="success" />
        )}
        {params.row.status === USER_SITE_STATUS.pending && (
          <Chip label="Por aceptar invitación" variant="outlined" size="small" />
        )}
      </>
    ),
    disableColumnSelector: true,
    disableColumnMenu: true,
    width: 160,
  },
  {
    field: 'type',
    headerName: 'Tipo de supervisor',
    renderCell: (params) => {
      const type = SALES_TYPES.find(t => t.id === params.row.type)
      return (
        <>
          {type && (
            <Typography variant="body2" sx={{ opacity: params.row.status === USER_SITE_STATUS.deactivated ? .5 : 1 }}>
              {type.label}
            </Typography>
          )}
        </>
      )
    },
    disableColumnSelector: true,
    disableColumnMenu: true,
    width: 180,
  },
  {
    field: 'view',
    headerName: '',
    renderCell: (params) => (
      <strong>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => onEdit(params.row)}
        >
          Ver Perfil
        </Button>
      </strong>
    ),
    sortable: false,
    disableColumnFilter: true,
    disableColumnMenu: true,
    disableColumnSelector: true,
    disableClickEventBubbling: true,
    width: 100,
  },
  {
    field: 'delete',
    headerName: '',
    renderCell: (params) => (
      <strong>
        <IconButton
          onClick={() => onDelete(params.row)}
        >
          <DeleteIcon />
        </IconButton>
      </strong>
    ),
    sortable: false,
    disableColumnFilter: true,
    disableColumnMenu: true,
    disableColumnSelector: true,
    disableClickEventBubbling: true,
    width: 50,
  },
]);

const TeamList = ({ users, onEdit, onDelete, loading, alertData, onAlertClose }) => {
  const [pageSize, setPageSize] = useState(5)
  const columns = useMemo(() => getColumns(onEdit, onDelete), [onDelete, onEdit])

  return (
    <FlexContainer vertical>
      <Spacer vertical size={2} />
      {alertData?.type === ALERT_TYPE.add && (
        <>
          <Alert severity="success" onClose={onAlertClose}>
            {alertData?.email} se <b>agregó</b> con éxito como <b>{alertData?.role}</b>
          </Alert>
          <Spacer vertical size={2} />
        </>
      )}
      {alertData?.type === ALERT_TYPE.delete && (
        <>
          <Alert severity="error" onClose={onAlertClose}>
            {alertData?.email} se <b>eliminó</b> con éxito como <b>{alertData?.role}</b>
          </Alert>
          <Spacer vertical size={2} />
        </>
      )}
      <Box sx={{ height: 400, width: 1, maxWidth: 'xl', mx: 'auto' }}>
        <StyledGrid
          loading={loading}
          rows={users}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 15, 20]}
          isRowSelectable={() => false}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          localeText={{
            noRowsLabel: 'No hay usuarios agregados',
            footerTotalVisibleRows: (visibleCount, totalCount) => `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
          }}
          getRowId={(row) =>  row.userId}
        />
      </Box>
    </FlexContainer>
  )
}

export default TeamList
