import {
  Email as EmailIcon,
  Message as MessageIcon,
  Phone as PhoneIcon,
} from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import React from 'react';

const ContactMethodsForm = ({
  email,
  phone,
  whatsapp,
  onEmailChange,
  onPhoneChange,
  onWhatsappChange,
}) => {
  return (
    <>
      <TextField
        variant="standard"
        name="email"
        label="Correo electrónico"
        value={email}
        onChange={(e) => onEmailChange(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        variant="standard"
        name="phone"
        label="Celular"
        value={phone}
        onChange={(e) => onPhoneChange(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PhoneIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        variant="standard"
        name="whatsapp"
        label="Whatsapp"
        value={whatsapp}
        onChange={(e) => onWhatsappChange(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MessageIcon />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default ContactMethodsForm;
