import {
  Alert,
  Box,
  FormControlLabel,
  Snackbar,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import {
  getKeysAsPath,
  getPathValue,
  modifyPath,
  NOM_PATH,
} from '../../helpers/data';
import { getData } from '../../helpers/draft';
import { post, put } from '../../helpers/request';
import { getContactMethodsSettingsFromData } from '../../helpers/settings';
import { convertToY, useSharedDocument } from '../../hooks/documentHooks';
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt';
import useSite from '../../hooks/useSite';
import useSiteActiveStatusAction from '../../hooks/useSiteActiveStatusAction';
import PageLayout from '../../layouts/PageLayout';
import { DiscardChangesDialog } from '../../modules';
import { changesReplacements } from '../../utils/deepMerge';
import Advertising, { ADVERTISING_NOM_PATH } from './Advertising';
import CustomerSupport, {
  CUSTOMER_SUPPORT_NOM_PATH,
} from './CustomerSupport/CustomerSupport';
import DisableTransparencyDialog from './DisableTransparencyDialog';
import Legal, { LEGAL_NOM_PATH } from './Legal';
import PaymentMethodsAndCosts, {
  PM_AND_COSTS_NOM_PATH,
} from './PaymentMethodsAndCosts';
import Plans, { PLANS_NOM_PATH } from './Plans';
import ProgressStatus from './ProgressStatus';
import Project, { PROJECT_NOM_PATH } from './Project';
import getDataChanges, {
  getNOMSupportContactMethods,
} from './SaveActions/getDataChanges';
import SaveActions from './SaveActions/SaveActions';
import useProgress from './useProgress';
import useTabs from './useTabs';

const DISABLED_NOM_PATH = 'disabled';

const NOM247View = () => {
  const { site } = useSite();

  const [data, setData] = useState(null);

  const [yDocument, , documentReady] = useSharedDocument(site?.site_id, {
    ws: process.env.REACT_APP_WEBSOCKET,
  });

  const [referenceData, setReferenceData] = useState(null);

  const [showSuccessSave, setShowSuccessSave] = useState(false);
  const [showSuccessPublish, setShowSuccessPublish] = useState(false);
  const [showError, setShowError] = useState(false);

  const onCloseSuccessSave = useCallback(() => setShowSuccessSave(false), []);
  const onCloseSuccessPublish = useCallback(
    () => setShowSuccessPublish(false),
    []
  );
  const onCloseError = useCallback(() => setShowError(false), []);

  const hasChanges = useMemo(() => {
    return JSON.stringify(data) !== JSON.stringify(referenceData);
  }, [data, referenceData]);

  const getDataFn = async () => {
    const dataMap = yDocument && yDocument?.getMap('data');
    let data = dataMap && dataMap?.toJSON();
    if (!!data && Object.keys(data).length === 0 && !!dataMap) {
      data = await getData(site);
    }
    return data;
  };

  const { isLoading: isLoadingData, refetch: refetchData } = useQuery(
    ['DATA', site],
    getDataFn,
    {
      onSuccess: (data) => {
        setData(JSON.parse(JSON.stringify(data)));
        setReferenceData(JSON.parse(JSON.stringify(data)));
      },
      enabled: !data && !!documentReady,
    }
  );

  const onSuccessSave = useCallback(() => {
    refetchData();
    setShowSuccessSave(true);
  }, [refetchData]);

  const onSuccessPublish = useCallback(() => {
    refetchData();
    setShowSuccessPublish(true);
  }, [refetchData]);

  const getNOMPathSetter = useCallback(
    (basePath) => (path, value) => {
      setData((prev) => {
        if (!prev) return prev;
        modifyPath(prev, getKeysAsPath([NOM_PATH, basePath, path]), value, {
          recursive: true,
        });
        return { ...prev };
      });
    },
    []
  );

  const getNOMPathGetter = useCallback(
    (basePath) => (path) =>
      getPathValue(data, getKeysAsPath([NOM_PATH, basePath, path])),
    [data]
  );

  const setLegalPathData = useMemo(
    () => getNOMPathSetter(LEGAL_NOM_PATH),
    [getNOMPathSetter]
  );
  const getLegalPathData = useMemo(
    () => getNOMPathGetter(LEGAL_NOM_PATH),
    [getNOMPathGetter]
  );

  const setPmAndCostsPathData = useMemo(
    () => getNOMPathSetter(PM_AND_COSTS_NOM_PATH),
    [getNOMPathSetter]
  );
  const getPmAndCostsPathData = useMemo(
    () => getNOMPathGetter(PM_AND_COSTS_NOM_PATH),
    [getNOMPathGetter]
  );

  const setCustomerSupportPathData = useMemo(
    () => getNOMPathSetter(CUSTOMER_SUPPORT_NOM_PATH),
    [getNOMPathSetter]
  );
  const getCustomerSupportPathData = useMemo(
    () => getNOMPathGetter(CUSTOMER_SUPPORT_NOM_PATH),
    [getNOMPathGetter]
  );

  const setProjectPathData = useMemo(
    () => getNOMPathSetter(PROJECT_NOM_PATH),
    [getNOMPathSetter]
  );
  const getProjectPathData = useMemo(
    () => getNOMPathGetter(PROJECT_NOM_PATH),
    [getNOMPathGetter]
  );

  const setPlansPathData = useMemo(
    () => getNOMPathSetter(PLANS_NOM_PATH),
    [getNOMPathSetter]
  );
  const getPlansPathData = useMemo(
    () => getNOMPathGetter(PLANS_NOM_PATH),
    [getNOMPathGetter]
  );

  const setAdvertisingPathData = useMemo(
    () => getNOMPathSetter(ADVERTISING_NOM_PATH),
    [getNOMPathSetter]
  );
  const getAdvertisingPathData = useMemo(
    () => getNOMPathGetter(ADVERTISING_NOM_PATH),
    [getNOMPathGetter]
  );

  const getNOMPathData = useCallback(
    (section) => getPathValue(data, getKeysAsPath([NOM_PATH, section])),
    [data]
  );

  const setNOMPathData = useCallback((path, value) => {
    setData((prev) => {
      if (!prev) return prev;
      modifyPath(prev, getKeysAsPath([NOM_PATH, path]), value, {
        recursive: true,
      });
      return { ...prev };
    });
  }, []);

  const [openDisableDialog, setOpenDisableDialog] = useState(false);
  const { progress, sectionsProgress } = useProgress({ getNOMPathData });
  const tabs = useTabs({ sectionsProgress });

  const disabled = getNOMPathData(DISABLED_NOM_PATH) || false;
  const onDisable = useCallback(
    () => setNOMPathData(DISABLED_NOM_PATH, true),
    [setNOMPathData]
  );
  const onEnable = useCallback(
    () => setNOMPathData(DISABLED_NOM_PATH, false),
    [setNOMPathData]
  );
  const onToggleDisabled = useCallback(
    (e) => (e.target.checked ? onEnable() : setOpenDisableDialog(true)),
    [onEnable]
  );

  const saveFn = async ({ data, siteId, disabled }) => {
    if (siteId) {
      const dataChanges = getDataChanges({ data, disabled });
      const dataMap = yDocument && yDocument?.getMap('data');
      var dataJson = dataMap?.toJSON();

      const newData = await changesReplacements(dataChanges, dataJson);

      Object.entries(newData).forEach(([key, value]) => {
        dataMap.set(key, convertToY(value));
      });

      return post(
        '/internal/developer/v1.1/files',
        {
          changes: getDataChanges({ data, disabled }),
          stage: ['draft'],
          fileName: 'data',
        },
        siteId
      );
    }
  };

  const { mutate: save, isLoading: isSaving } = useMutation(saveFn, {
    onSuccess: onSuccessSave,
    onError: () => setShowError(true),
  });

  const handleSave = useCallback(
    () => save({ data, siteId: site.site_id, disabled }),
    [save, data, site, disabled]
  );

  const publishFn = async ({ data, siteId, disabled }) => {
    if (!siteId) return;
    const contact = getContactMethodsSettingsFromData(data);
    const { complains } = getNOMSupportContactMethods(data);

    return Promise.all([
      post(
        '/internal/developer/v1.1/files',
        {
          changes: getDataChanges({ data, disabled }),
          stage: ['draft', 'production'],
          fileName: 'data',
        },
        siteId
      ),
      !disabled &&
        complains &&
        put(
          '/internal/settings/v1/settings/showroom/customerSupport',
          { complains },
          siteId
        ),
      !disabled &&
        contact &&
        put('/internal/settings/v1/settings/showroom/contact', contact, siteId),
    ]);
  };

  const { mutate: mutatePublish, isLoading: isPublishing } = useMutation(
    publishFn,
    {
      onSuccess: onSuccessPublish,
      onError: () => setShowError(true),
    }
  );
  const publish = useSiteActiveStatusAction(mutatePublish);

  const handlePublish = useCallback(
    () => publish({ data, siteId: site.site_id, disabled }),
    [publish, data, site, disabled]
  );

  const [showPrompt, confirmNavigation] = useCallbackPrompt(false);

  const dialogOnSave = () => {
    handleSave();
    confirmNavigation();
  };

  const dialogOnDiscard = () => {
    confirmNavigation();
  };

  const loading = isLoadingData || isSaving || isPublishing;

  return (
    <>
      <Snackbar
        open={showSuccessSave}
        autoHideDuration={6_000}
        onClose={onCloseSuccessSave}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Alert severity="success" onClose={onCloseSuccessSave}>
          Información guardada
        </Alert>
      </Snackbar>
      <Snackbar
        open={showSuccessPublish}
        autoHideDuration={6_000}
        onClose={onCloseSuccessPublish}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Alert severity="success" onClose={onCloseSuccessPublish}>
          Información guardada y publicada
        </Alert>
      </Snackbar>
      <Snackbar
        open={showError}
        autoHideDuration={6_000}
        onClose={onCloseError}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Alert severity="error" onClose={onCloseError}>
          Oops. Algo ha salido mal. Por favor inténtalo de nuevo.
        </Alert>
      </Snackbar>
      <DiscardChangesDialog
        open={showPrompt}
        onSave={dialogOnSave}
        onDiscard={dialogOnDiscard}
      />
      <DisableTransparencyDialog
        open={openDisableDialog}
        onClose={() => setOpenDisableDialog(false)}
        onDisable={onDisable}
      />
      <PageLayout
        sticky
        loading={isLoadingData}
        scrollableTabs
        scrollYOffset={-160}
        links={tabs}
        menu={
          <SaveActions
            hasChanges={hasChanges}
            onSave={handleSave}
            onPublish={handlePublish}
            loading={loading}
            isSaving={isSaving}
            isPublishing={isPublishing}
          />
        }
      >
        {data && (
          <>
            <ProgressStatus
              progress={progress}
              getNOMPathData={getNOMPathData}
              sx={{
                justifyContent: 'center',
                position: 'sticky',
                top: 64,
                zIndex: 'appBar',
                py: 1,
              }}
            />
            <Typography variant="h4" sx={{ mt: 8, mb: 6, textAlign: 'center' }}>
              ¡La NOM 247 en tus manos!
            </Typography>
            <Typography>
              En septiembre de 2022 la{' '}
              <strong>Norma Oficial Mexicana 247-SE-2021 (NOM 247)</strong>{' '}
              entró en vigor para mejorar la experiencia de compra y las
              prácticas comerciales en el sector inmobiliario.
              <br />
              <br />
              En <strong>alohome</strong> estamos comprometidos a ayudarte a
              cumplir con todos los lineamientos para evitar multas y
              penalizaciones en tu proyecto. ¡Comencemos!
            </Typography>
            <Tooltip title="El contenido que a continuación compartirás se mostrará en tu showroom digital (la mayoría, en el apartado de TRANSPARENCIA). Tienes el control para desactivar esta información si así lo decides.">
              <FormControlLabel
                label="Mostrar/Desactivar página de transparencia:"
                labelPlacement="start"
                control={
                  <Switch checked={!disabled} onChange={onToggleDisabled} />
                }
                sx={{ my: 2 }}
              />
            </Tooltip>
            <Alert severity="error" sx={{ mb: 4 }}>
              Este listado es una guía para cumplir con la NOM247; sin embargo,
              marcar con una ✔ cada uno de los lineamientos no garantiza la
              aprobación de PROFECO. El desarrollador es responsable de contar
              con toda esta información para una auditoría.
            </Alert>
            <Box sx={{ counterReset: 'checklist' }}>
              <Legal
                data={data}
                getSectionData={getLegalPathData}
                setSectionData={setLegalPathData}
              />
              <PaymentMethodsAndCosts
                data={data}
                getSectionData={getPmAndCostsPathData}
                setSectionData={setPmAndCostsPathData}
              />
              <CustomerSupport
                getSectionData={getCustomerSupportPathData}
                setSectionData={setCustomerSupportPathData}
              />
              <Project
                getSectionData={getProjectPathData}
                setSectionData={setProjectPathData}
              />
              <Plans
                getSectionData={getPlansPathData}
                setSectionData={setPlansPathData}
              />
              <Advertising
                getSectionData={getAdvertisingPathData}
                setSectionData={setAdvertisingPathData}
              />
            </Box>
          </>
        )}
      </PageLayout>
    </>
  );
};

export default NOM247View;
