import { useLoadScript } from "@react-google-maps/api";
import { useMemo } from "react";
import styled from 'styled-components';
import { FlexContainer, Map, Spacer, PlacesAutocomplete } from '../../components';

const mapLibraries = ['places']

const Container = styled(FlexContainer)`
  column-gap: 3rem;
`
const Column = styled(FlexContainer)`
  flex: 1 1;
`
const AutoCompleteMap = ({ locationData, onSelect }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: mapLibraries,
  });

  const center = useMemo(() => ({ lat: 24.036590303251916, lng: -102.44471879657337 }), []);

  if (loadError) {
    return <div>Error al cargar el mapa.</div>
  }
  if (!isLoaded) return <div>Cargando...</div>;
  return (
    <Container justified>
      <Column vertical>
        <PlacesAutocomplete
          inputLabel="Dirección de la ubicación"
          inputPlaceholder="Calle, número y ciudad"
          onSelect={onSelect}
        />
      </Column>
      <Spacer vertical size={5} />
      <Column vertical>
        <Map 
          center={center}
          selected={locationData}
        />
      </Column>
    </Container>
  )
}

export default AutoCompleteMap