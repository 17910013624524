import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { FlexContainer, Spacer } from '../../components'
import { Divider, IconButton, Link, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import styled from 'styled-components'
import crownIcon from '../../assets/svgs/crown.svg'
import Plan from './Plan'
import { PLANS } from '../../helpers/constants'
import smartlookClient from 'smartlook-client'
import useSite from '../../hooks/useSite'
import { TwoOptionsSwitch } from '../../components'
import { useMutation } from '@tanstack/react-query'
import { post } from '../../helpers/request'
import { trackEvent } from '../../helpers/mixpanel'

const ACTIONS = {
  choose: "choose",
  demo: "demo",
}

const PLANS_DATA = {
  basic: [{
    id: PLANS.ENTREPRENEUR,
    name: 'Emprendedor',
    monthly_price: '300',
    anual_price: '260',
    description: '• Dominio básico • Plantillas editables • Base de datos (leads) • Analítica básica',
    cta: {
      label: 'Elegir plan',
      action: ACTIONS.choose,
      variant: 'outlined',
      label_select: 'Plan actual'
    }
  }],
  attendance: [{
    id: PLANS.GROWTH,
    name: 'Crecimiento +',
    monthly_price: '620',
    anual_price: '550',
    title: '• Alohome partnership: ',
    description: 'writing, design & dev. • Diseño exclusivo • Cotización de unidades específicas • Analítica avanzada',
    cta: {
      label: 'Agenda una demo',
      action: ACTIONS.demo,
      variant: 'contained'
    }
  }, {
    id: PLANS.ENTERPRISE,
    name: 'Enterprise',
    monthly_price: 'xxx',
    anual_price: 'xxx',
    title: '• Alohome partnership: ',
    description: ' writing, design & dev. • Pagos online • Conexión CRM / ERP • Soporte por tickets • Cotizaciones interactivas',
    cta: {
      label: 'Agenda una demo',
      action: ACTIONS.demo,
      variant: 'contained'
    }
  }]
}

const TITLE = {
  trialEnded: "Tus 21 días de prueba terminaron",
  standard: "Planes diseñados para cada necesidad",
}

const LeftPanel = styled(FlexContainer)`
  width: 300px;
`

const RightPanel = styled(FlexContainer)`
  width: 100%;
`
const DividerContent = styled(FlexContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const StyledDivider = styled(Divider)`
  height: 1px;
  width: 50%;
  margin: 0;
`

const CrownIcon = styled.img`
  width: 130px;
`

const createPaymentSessionFn = ({
  plan,
  billingPeriod,
  successPath,
  cancelPath,
  siteId,
}) =>
  post('/internal/alohub/payment/create-checkout-session', {
    plan,
    billingPeriod,
    successPath,
    cancelPath,
  }, siteId);

const PlansModal = () => {
  const { site } = useSite()
  const [selectedPlan, setSelectedPlan] = useState()
  const dispatch = useDispatch()
  const isOpen = useSelector(state => state.showPlansModal) ?? false
  const [isMonthlySelected, setIsMonthlySelected] = useState(true)

  const onPaymentSessionCreated = useCallback(({ url }) => {
    window.location.replace(url);
  }, []);

  const { mutate: mutateCreatePaymentSession } = useMutation(
    createPaymentSessionFn,
    { onSuccess: onPaymentSessionCreated },
  );

  const handleClose = useCallback(() => {
    dispatch({ type: 'close-plans-modal' })
  }, [dispatch])

  const handleSelect = useCallback((planId, planAction) => {
    if (planAction === ACTIONS.demo) {
      window.open(process.env.REACT_APP_CALENDLY_URL, "_blank")
    } else {
      const search = new URLSearchParams({
        plan: planId,
      });
      mutateCreatePaymentSession({
        plan: planId,
        billingPeriod: isMonthlySelected ? 'MONTH' : 'YEAR',
        successPath: `/payment-success?${search.toString()}`,
        cancelPath: `/payment-failure?${search.toString()}`,
        siteId: site.site_id,
      });
    }
    setSelectedPlan(planId)
    trackEvent('plan-selected', { plan_id: planId, billing_period: isMonthlySelected ? 'MONTH' : 'YEAR', project_name: site?.site_name })
  }, [mutateCreatePaymentSession, isMonthlySelected, site]);

  useEffect(() => {
    smartlookClient.track('choose-plan', { site_domain: site?.site_domain, site_name: site?.site_name, remainingTrialDays: site?.remainingDays })
    trackEvent('choose-plan', { site_domain: site?.site_domain, site_name: site?.site_name, remainingTrialDays: site?.remainingDays })
  }, [site])

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle>
        <FlexContainer fullWidth justified>
          <Typography variant='h4'>
            {site?.isTrial ? TITLE.trialEnded : TITLE.standard}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </FlexContainer>
      </DialogTitle>
      <DialogContent>
        <FlexContainer>
          <LeftPanel vertical centered>
            <Spacer vertical size={4} />
            <CrownIcon src={crownIcon} alt='crown' />
            <Spacer vertical size={4} />
            <Typography variant='body2' align='center' color="var(--color-text-secondary)" >Conoce los beneficios de cada plan y elige el mejor para tu proyecto</Typography>
            <Spacer vertical size={4} />
            <Link href={`${process.env.REACT_APP_ALOHOME_URL}/precios`} color="primary.dark" target="_blank">Ver beneficios de cada plan</Link>
          </LeftPanel>
          <Spacer size={3} />
          <RightPanel vertical>
            <FlexContainer verticalCentered>
              <Typography variant="h5">Pago</Typography>
              <Spacer size={1}/>
              <TwoOptionsSwitch
                isFirstOptionSelected={isMonthlySelected}
                setIsFirstOptionSelected={setIsMonthlySelected}
                firstOptionLabel="MENSUAL"
                secondOptionLabel="ANUAL" />
            </FlexContainer>
            <Spacer vertical size={2} />
            {PLANS_DATA.basic.map((plan, i) => (
              <Fragment key={plan.id}>
                <Plan {...plan} onSelect={handleSelect} planSelect={selectedPlan} showMonthlyPrice={isMonthlySelected} />
                {PLANS_DATA.basic.length !== i + 1 && (
                  <Spacer vertical size={3} />
                )}
              </Fragment>
            ))}
            <Spacer vertical size={2} />
            <DividerContent>
              <Typography variant='body1' align='left' color="var(--color-text-disabled)" >Asistencia de nuestro equipo </Typography>
              <StyledDivider variant='middle' />
            </DividerContent>
            <Spacer vertical size={2} />
            {PLANS_DATA.attendance.map((plan, i) => (
              <Fragment key={plan.id}>
                <Plan {...plan} onSelect={handleSelect} showMonthlyPrice={isMonthlySelected} />
                {PLANS_DATA.attendance.length !== i + 1 && (
                  <Spacer vertical size={3} />
                )}
              </Fragment>
            ))}
          </RightPanel>
        </FlexContainer>
      </DialogContent>
    </Dialog>
  );
}

export default PlansModal
