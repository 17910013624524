import { Button, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"
import { styled } from "@mui/system"
import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { FlexContainer } from "../../components"
import alohomeLogo from '../../assets/svgs/alohome.svg'

const PLANS = {
  'entrepreneur': {
    name: 'Emprendedor',
    monthlyFee: '300',
    benefits: [
      { text: "Dominio básico (proyecto.alohome.io)" },
      { text: "Plantillas editables" },
      { text: "Cotizador interactivo de tipologías" },
      { text: "Formulario e integración con WhatsApp" },
      { text: "Y mucho más...", noIcon: true }
    ]
  },
  'growth': {
    name: 'Crecimiento +',
    monthlyFee: '620',
    benefits: [
      { text: "Dominio personalizado" },
      { text: "Showroom multi-page con diseño exclusivo" },
      { text: "Mapa interactivo (2D) del proyecto" },
      { text: "75 cotizaciones interactivas / mes" },
    ]
  },
  'enterprise': {
    name: 'Enterprise',
    benefits: [
      { text: "TODOS LOS BENEFICIOS DE CRECIMIENTO+" },
      { text: "Reservaciones y pagos online" },
      { text: "Conexión CRM y ERP" },
      { text: "Cotizaciones interactivas ilimitadas" },
    ]
  },
}

const StyledLogo = styled('img')`
height: 20px;
align-self: flex-start;
`

const Logo = () => {
  return (
    <StyledLogo src={alohomeLogo} alt="Alohome logo" />    
  )
}

const Container = styled(FlexContainer)`
  padding: 112px 32px;
  justify-content: center;
  box-sizing: border-box;
  @media (max-width: 828px) {
    padding: 64px 32px;
  }
`

const Panel = styled(FlexContainer)`
width: 700px;  
flex-direction: column;
@media (max-width: 828px) {
  width: calc(100% - 64px);
}
`

const StyledButtons = styled(FlexContainer)`
  width: 100%;
  gap: 32px;
  align-items: center;
  justify-content: center;
  @media (max-width: 828px) {
    flex-direction: column;
  }
`

const Title = styled(Typography)`
  display: flex;
  align-items: center;

  img {
    margin-left: 16px;
  }
`

const StyledCustomButton = styled(Button)`
  width: fit-content; 
  align-self: center; 
  text-transform: uppercase;
  @media (max-width: 828px) {
    width: 100%;
  }
`

const CustomButton = ({ children, ...props }) => {
  return (
    <StyledCustomButton 
      color="primary"
      {...props}
    >
      {children}
    </StyledCustomButton>
  )
}

const Item = ({ text, icon }) => {
  return (
    <ListItem disablePadding sx={{ minHeight: 48 }}>
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  )
}

const Buttons = () => {
  const navigate = useNavigate()

  const goToHub = useCallback(() => {
    navigate('/')
  }, [navigate])

  const goToAlohome = useCallback(() => {
    window.location.href = 'https://alohome.io'
  }, [])

  return (
    <StyledButtons>
      <CustomButton variant="contained" onClick={goToHub}>
        Ir a mi cuenta de alohub
      </CustomButton>
      <CustomButton variant="outlined" onClick={goToAlohome}>
        Ir a página principal
      </CustomButton>
    </StyledButtons>
  )
}

export { PLANS, Logo, Container, Panel, Buttons, Title, CustomButton, Item }