import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Divider, Paper, Portal } from '@mui/material';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import alohomeIcon from '../../assets/svgs/alohome.svg';
import { trackEvent } from '../../helpers/mixpanel';
import { post } from '../../helpers/request';
import useHandleOutsideClick from '../../hooks/useHandleOutsideClick';
import useProfile from '../../hooks/useProfile';
import SidebarOption from './SidebarOption';

const AlohomeIcon = styled.img`
  width: 130px;
`
const Header = styled.div`
  padding: 1rem;
  padding-right: 0;
  display: flex;
  column-gap: 2rem;
  justify-content: space-between;
  header-colum {
    display: flex;
    cursor: pointer;
    justify-content: end;
    column-gap: 0.5rem;
    align-items: center;
  }
`
const StylePaper = styled(Paper)`
  border-radius: 16px;
  position: absolute;
  width: 180px;
  top: 70px;
  z-index: 2;
  left: 130px;
  a {
    flex-direction: row-reverse;
    .MuiTypography-root {
      text-align: end;
    }
    .MuiListItemAvatar-root {
      min-width: auto;
    }  
  }
  & > li a:hover {
    border-radius: 0px;
  }

  & > li:first-child a:hover {
    border-radius: 16px 16px 0px 0px;
  }

  logout-option {
    .MuiSvgIcon-root, 
    .MuiTypography-root {
      color: #F50057;
    }
    a:hover {
      background: rgba(245, 0, 87, 0.08);
      border-radius: 0px 0px 16px 16px;
    }
  }
`

const OPTIONS = {
  perfil: {
    label: "Perfil",
    href: "/profile",    
    icon: PersonIcon,
  }
};

const HeaderHub = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const profile = useProfile()
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open])

  const menuRef = useHandleOutsideClick(() => setOpen(false))

  const handleLogout = useCallback(async () => {
    try {
      await post('/internal/login/v1/logout')
      dispatch({ type: 'clear-profile' })
      dispatch({ type: 'clear-site' })
      trackEvent('logout')
      navigate('/login')
    } catch (error) {
      console.error(error)
    }
  }, [dispatch, navigate])

  return (
    <Header >
      <AlohomeIcon src={alohomeIcon} alt='alohome' />
      <header-colum>
        <Avatar alt={profile.name} src={profile.avatar} onClick={toggleOpen} />  
        {open ? <ArrowDropUpIcon onClick={toggleOpen}/> :<ArrowDropDownIcon onClick={toggleOpen} /> }
      </header-colum>
      {open && 
        <Portal>
          <StylePaper elevation={2} onClick={toggleOpen} ref={menuRef}>
            {Object.keys(OPTIONS).map((item) => {
              const option = OPTIONS[item];
              return (
                <SidebarOption
                  key={option.label}
                  icon={option.icon}
                  label={option.label}
                  to={option.href}                  
                />
              )
            })}
              <Divider />
              <logout-option>
                <SidebarOption
                    icon={LogoutIcon}
                    label={'Salir'}
                    onClick={handleLogout}
                  />
              </logout-option>
          </StylePaper>
        </Portal>
      }
    </Header>
  )
}

export default HeaderHub