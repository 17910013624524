
const isFunction = (element) => typeof element === 'function';
const isArray = (element) => Array.isArray(element);
const isObject = (element) => typeof element === 'object' && element !== null && !isArray(element);
const isFileImage = (file) => file && file['type'].split('/')[0] === 'image'
const isExtImage = (filename) => filename.match(/.(jpg|jpeg|png|gif|tif|tiff|bmp|webp)$/i)
const isExtVideo = (filename) => filename.match(/.(webm|mkv|flv|ogg|avi|mov|wmv|mp4|mpeg|m4v)$/i)

const toBase64 = file => new Promise((resolve, reject) => {
    const isImage = isFileImage(file)
    if (isImage) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
    } else {
        resolve(null)
    }
})

const checkIfArrayIsUnique = (array) => {
    return array.length === new Set(array).size;
}

export { isFunction, isArray, isObject, toBase64, isExtImage, isExtVideo, checkIfArrayIsUnique }