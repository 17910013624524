import { useCallback, useMemo } from 'react';
import { getKeysAsPath } from '../../helpers/data';
import {
  ADVERTISING_CHECKLIST_IDS,
  ADVERTISING_NOM_PATH,
  ADVERTISING_URL_SLUG,
} from './Advertising';
import {
  CUSTOMER_SUPPORT_CHECKLIST_IDS,
  CUSTOMER_SUPPORT_NOM_PATH,
  CUSTOMER_SUPPORT_URL_SLUG,
} from './CustomerSupport/CustomerSupport';
import { LEGAL_CHECKLIST_IDS, LEGAL_NOM_PATH, LEGAL_URL_SLUG } from './Legal';
import {
  PM_AND_COSTS_CHECKLIST_IDS,
  PM_AND_COSTS_NOM_PATH,
  PM_AND_COSTS_URL_SLUG,
} from './PaymentMethodsAndCosts';
import { PLANS_CHECKLIST_IDS, PLANS_NOM_PATH, PLANS_URL_SLUG } from './Plans';
import {
  PROJECT_CHECKLIST_IDS,
  PROJECT_NOM_PATH,
  PROJECT_URL_SLUG,
} from './Project';

const PROGRESS_PATHS_AND_IDS = [
  {
    id: LEGAL_URL_SLUG,
    nomPath: LEGAL_NOM_PATH,
    checkListIds: LEGAL_CHECKLIST_IDS,
  },
  {
    id: PM_AND_COSTS_URL_SLUG,
    nomPath: PM_AND_COSTS_NOM_PATH,
    checkListIds: PM_AND_COSTS_CHECKLIST_IDS,
  },
  {
    id: CUSTOMER_SUPPORT_URL_SLUG,
    nomPath: CUSTOMER_SUPPORT_NOM_PATH,
    checkListIds: CUSTOMER_SUPPORT_CHECKLIST_IDS,
  },
  {
    id: PROJECT_URL_SLUG,
    nomPath: PROJECT_NOM_PATH,
    checkListIds: PROJECT_CHECKLIST_IDS,
  },
  {
    id: PLANS_URL_SLUG,
    nomPath: PLANS_NOM_PATH,
    checkListIds: PLANS_CHECKLIST_IDS,
  },
  {
    id: ADVERTISING_URL_SLUG,
    nomPath: ADVERTISING_NOM_PATH,
    checkListIds: ADVERTISING_CHECKLIST_IDS,
  },
];

const useProgress = ({ getNOMPathData }) => {
  const getSectionProgress = useCallback(
    (nomPath, checkListIds) => {
      const values = checkListIds.map(
        (id) => getNOMPathData(getKeysAsPath([nomPath, 'checkList', id]))?.value
      );
      const completed = values.filter((v) => v === true).length;
      const total = values.length;
      return {
        completed,
        total,
        progress: Math.round((100 * completed) / total),
      };
    },
    [getNOMPathData]
  );

  const sectionsProgress = useMemo(() => {
    const result = {};
    PROGRESS_PATHS_AND_IDS.forEach(({ nomPath, checkListIds, id }) => {
      result[id] = getSectionProgress(nomPath, checkListIds);
    });
    return result;
  }, [getSectionProgress]);

  const progress = useMemo(() => {
    const total = Object.values(sectionsProgress).reduce(
      (acc, item) => acc + item.total,
      0
    );
    const completed = Object.values(sectionsProgress).reduce(
      (acc, item) => acc + item.completed,
      0
    );
    return Math.round((100 * completed) / total);
  }, [sectionsProgress]);

  return {
    progress,
    sectionsProgress,
  };
};

export default useProgress;
