import React, { useCallback, useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { FlexContainer, PendingButton, Spacer } from '../../components';
import PageLayout from '../../layouts/PageLayout';
import { projectTabs } from '../../helpers/tabsData';
import { Button } from '@mui/material';
import { get, post, put } from '../../helpers/request';
import { currencyToNumber } from '../../helpers/utils';
import {
  getDefaultParameters,
  getLowPriceTypology,
  TYPE,
  useFixedQuotation,
  useFlexibleQuotation,
  useInitialConfig,
} from './utils';
import useSite from '../../hooks/useSite';
import { getData, getInventory, getSitePreviewUrl } from '../../helpers/draft';
import { useMemo } from 'react';
import { INVENTORY_TYPE } from '../../helpers/constants';
import {
  DiscardChangesDialog,
  ProjectPreviewModal,
  SimpleModal,
} from '../../modules';
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt';
import { useDispatch } from 'react-redux';
import QuoteConfig from './QuoteConfig';
import InitialConfig from './InitialConfig';
import { initialTable, quotationTool } from './defaultData';

const VIEW = {
  initialConfig: 'initialConfig',
  quoteConfig: 'quoteConfig',
};

const GET_PAYMENTS =
  '/internal/settings/v1/settings?category=general&subcategory=payments';
const GET_QUOTATION =
  '/internal/settings/v1/settings?category=general&subcategory=quotation';
const GET_CURRENCY =
  '/internal/settings/v1/settings?category=general&subcategory=currency';
const PUT_PAYMENTS = '/internal/settings/v1/settings/general/payments';
const PUT_QUOTATION = '/internal/settings/v1/settings/general/quotation';
const GET_AVAILABLE_INVENTORY =
  '/internal/inventory/v3/inventory?status=available';
const GENERATE_QUOTATION = '/internal/admin/v3/generate/quotation';

const MAX_ONLINE_PAYMENT = 20000;

const QuoteEditor = ({ inventoryType }) => {
  const dispatch = useDispatch();
  const { site } = useSite();
  const previewRef = useRef(null);
  const [view, setView] = useState(VIEW.initialConfig);
  const [type, setType] = useState(null);
  const [fixedOnlinePayment, setFixedOnlinePayment] = useState({ value: '' });
  const [expirationDays, setExpirationDays] = useState({ value: 7 });
  const [isPublishMessageOpen, setIsPublishMessageOpen] = useState(false);
  const [payments, setPayments] = useState(null);
  const [quotation, setQuotation] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [activePayments, setActivePayments] = useState(false);
  const [loading, setLoading] = useState(true);
  const [previewUnit, setPreviewUnit] = useState(null);
  const [enableSave, setEnableSave] = useState(false);
  const [showPrompt, confirmNavigation] = useCallbackPrompt(enableSave);
  const [quoteUrl, setQuoteUrl] = useState('');
  const [showProjectPreview, setShowProjectPreview] = useState(false);

  const {
    initialConfig,
    formattedInitialConfig,
    enableContinue,
    onInitialConfigChange,
    onDeedCostPercentBlur,
    onDeedCostCategoriesChange,
    onCustomDeedCostCategoryAdd,
    onCustomDeedCostCategoryChange,
    onCustomDeedCostCategoryDelete,
    onExternalLoanItemsChange,
  } = useInitialConfig(quotation);

  const toggleProjectPreview = useCallback(
    () => setShowProjectPreview((p) => !p),
    []
  );

  const toggleView = useCallback(() => {
    if (view === VIEW.initialConfig) {
      setView(VIEW.quoteConfig);
    } else {
      setView(VIEW.initialConfig);
    }
  }, [view]);

  const openPlans = useCallback(() => {
    dispatch({ type: 'open-plans-modal' });
  }, [dispatch]);

  const previewUrl = useMemo(() => {
    const prevUrl = getSitePreviewUrl(site);
    if (inventoryType === INVENTORY_TYPE.unit) {
      return `${prevUrl}/${quoteUrl}/${previewUnit?.unit_id}?preview=1`;
    }
    return `${prevUrl}/cotiza/${previewUnit}?preview=1`;
  }, [inventoryType, previewUnit, quoteUrl, site]);

  useEffect(() => {
    const getQuoteData = async () => {
      if (site?.site_id) {
        const getInventoryFunction = () => {
          if (inventoryType === INVENTORY_TYPE.unit) {
            return get(GET_AVAILABLE_INVENTORY, { site_id: site.site_id });
          } else {
            return getInventory(site);
          }
        };
        setLoading(true);
        const responses = await Promise.all([
          get(GET_PAYMENTS, { site_id: site.site_id }),
          get(GET_QUOTATION, { site_id: site.site_id }),
          get(GET_CURRENCY, { site_id: site.site_id }),
          getInventoryFunction(),
          getData(site),
        ]);
        const [
          paymentResponse,
          quotationResponse,
          currencyResponse,
          inventoryResponse,
          dataResponse,
        ] = responses;
        setPayments(paymentResponse);
        setQuotation(quotationResponse);
        setCurrency(currencyResponse);
        setType(TYPE[quotationResponse?.quotation_type]);
        setActivePayments(
          quotationResponse?.has_online_payments ??
            quotationResponse?.activePayments
        );
        setFixedOnlinePayment({
          value: quotationResponse?.parameters?.fixedOnlinePayment ?? '',
        });
        setExpirationDays({
          value: quotationResponse?.parameters?.expiration_days ?? 7,
        });

        if (dataResponse) {
          const quoteSlug = dataResponse.pages?.QuotingPage?.slug;
          if (quoteSlug) {
            const slugParts = quoteSlug.split('/');
            setQuoteUrl(slugParts[1]);
          }
        }

        if (
          quotationResponse?.parameters?.deedCostsType &&
          quotationResponse?.parameters?.hasPaymentSchemes != null &&
          quotationResponse?.parameters?.hasExternalLoan != null
        ) {
          setView(VIEW.quoteConfig);
        }

        if (inventoryType === INVENTORY_TYPE.unit) {
          const mainCurrency = currencyResponse?.dualCurrency?.main;
          const priceAttribute = `price_${mainCurrency}`;
          const sortedInventory = inventoryResponse?.inventory?.sort((a, b) => {
            if (a[priceAttribute] === b[priceAttribute]) {
              return a.unit_id.localeCompare(b.unit_id);
            }
            return a[priceAttribute] - b[priceAttribute];
          });
          setPreviewUnit(sortedInventory[0]);
        } else {
          const typologyId = getLowPriceTypology(inventoryResponse);
          setPreviewUnit(typologyId);
        }

        setLoading(false);
      }
    };
    getQuoteData().catch(console.error);
  }, [inventoryType, site, site?.site_id]);

  // TODO: Erase when all sites are migrated to nextjs
  useEffect(() => {
    const preview = previewRef?.current;
    const handlePreviewLoad = () => {
      preview.contentWindow.postMessage(
        {
          action: 'quotation-preview',
          quotePreview: true,
        },
        '*'
      );
    };
    if (preview) {
      preview.addEventListener('load', handlePreviewLoad);
    }
    return () => {
      if (preview) {
        preview.removeEventListener('load', handlePreviewLoad);
      }
    };
  }, []);

  const handleFixedOnlinePaymentChange = useCallback((value) => {
    const rawValue = currencyToNumber(value);
    setFixedOnlinePayment({ value: rawValue });
  }, []);

  const handleFixedOnlinePaymentBlur = useCallback((value) => {
    const rawValue = currencyToNumber(value);
    if (rawValue > MAX_ONLINE_PAYMENT) {
      setFixedOnlinePayment({ value: MAX_ONLINE_PAYMENT });
    }
  }, []);

  const handleExpirationDaysChange = useCallback((value) => {
    if (!isNaN(value)) {
      if (value === '') {
        setExpirationDays({ value: 0 });
      } else {
        setExpirationDays({ value: Number(value) });
      }
    }
  }, []);

  const {
    minDownPaymentPercent,
    onMinDownPaymentPercentChange,
    maxDownPaymentPercent,
    onMaxDownPaymentPercentChange,
    minPreSalePercent,
    onMinPreSalePercentChange,
    hasDiscounts,
    onHasDiscountsChange,
    discounts,
    onAddNewDiscount,
    onDeleteDiscount,
    onChangeDiscount,
    onBlurDiscount,
    onKeyUpDiscount,
    fixedNumberOfMonths,
    onFixedNumberOfMonthsChange,
    onFixedNumberOfMonthsError,
  } = useFlexibleQuotation({
    currentMinDownPaymentPercent: quotation?.parameters?.minDownPaymentPercent,
    currentMaxDownPaymentPercent: quotation?.parameters?.maxDownPaymentPercent,
    currentMinPreSalePercent: quotation?.parameters?.minPreSalePercent,
    currentDiscounts: quotation?.parameters?.discounts,
    currentFixedNumberOfMonths: quotation?.parameters?.fixedNumberOfMonths,
    hasPaymentSchemes: quotation?.parameters?.hasPaymentSchemes,
    hasExternalLoan: quotation?.parameters?.hasExternalLoan,
  });

  const {
    plans,
    onAddNewPlan,
    onDeletePlan,
    onConfirmPlan,
    onReorderPlan,
    fixedMinPreSalePercent,
    onFixedMinPreSalePercentChange,
    planName,
    onPlanNameChange,
  } = useFixedQuotation({
    currentPlans: quotation?.parameters?.plans,
    currentMinPreSalePercent: quotation?.parameters?.fixedMinPreSalePercent,
    hasPaymentSchemes: quotation?.parameters?.hasPaymentSchemes,
    hasExternalLoan: quotation?.parameters?.hasExternalLoan,
  });

  const getParameters = useCallback(() => {
    const hasPaymentSchemes = formattedInitialConfig?.hasPaymentSchemes;
    const hasExternalLoan = formattedInitialConfig?.hasExternalLoan;

    if (type === TYPE.fixed) {
      const realFixedMinPreSalePercent =
        hasPaymentSchemes === false && hasExternalLoan === false
          ? 100
          : fixedMinPreSalePercent;
      const parameters = {
        ...formattedInitialConfig,
        ...(activePayments && { fixedOnlinePayment: fixedOnlinePayment.value }),
        expiration_days: expirationDays.value,
        ...(!hasPaymentSchemes && {
          fixedMinPreSalePercent: realFixedMinPreSalePercent,
        }),
        ...(!hasPaymentSchemes && { planName }),
        ...(hasPaymentSchemes && { plans }),
      };
      return parameters;
    } else if (type === TYPE.flexible) {
      const realMinPreSalePercent =
        hasPaymentSchemes === false && hasExternalLoan === false
          ? 100
          : minPreSalePercent;
      let discountsObject = { 0: 0 };

      if (hasDiscounts && hasPaymentSchemes) {
        const orderedDiscounts = discounts.sort((a, b) => a - b);
        orderedDiscounts.forEach((d) => {
          if (!(d.downPaymentPercentError || d.discountPercentError)) {
            discountsObject = {
              ...discountsObject,
              [d.downPaymentPercent]: d.discountPercent,
            };
          }
        });
      }
      const parameters = {
        ...formattedInitialConfig,
        ...(activePayments && { fixedOnlinePayment: fixedOnlinePayment.value }),
        ...(inventoryType === INVENTORY_TYPE.typology &&
          hasPaymentSchemes && {
            fixedNumberOfMonths: fixedNumberOfMonths.value,
          }),
        expiration_days: expirationDays.value,
        ...(hasPaymentSchemes && { minDownPaymentPercent }),
        ...(hasPaymentSchemes && { maxDownPaymentPercent }),
        minPreSalePercent: realMinPreSalePercent,
        discounts: discountsObject,
      };
      return parameters;
    }
  }, [
    activePayments,
    discounts,
    expirationDays.value,
    fixedMinPreSalePercent,
    fixedNumberOfMonths.value,
    fixedOnlinePayment.value,
    formattedInitialConfig,
    hasDiscounts,
    inventoryType,
    maxDownPaymentPercent,
    minDownPaymentPercent,
    minPreSalePercent,
    planName,
    plans,
    type,
  ]);

  const savedParameters = useMemo(() => {
    if (quotation?.parameters) return quotation?.parameters;
    return getDefaultParameters(type, inventoryType, activePayments);
  }, [activePayments, inventoryType, quotation?.parameters, type]);

  const verifyEnableSave = useCallback(
    (parameters) => {
      const hasPendingChanges = !isEqual(parameters, savedParameters);
      const enabled = enableContinue && hasPendingChanges;
      setEnableSave(enabled);
    },
    [enableContinue, savedParameters]
  );

  useEffect(() => {
    const parameters = getParameters();
    verifyEnableSave(parameters);

    if (previewRef?.current) {
      previewRef.current.contentWindow.postMessage(
        {
          action: 'hot-reload-quotation-alohub',
          quotation: parameters,
        },
        '*'
      );
    }
  }, [getParameters, verifyEnableSave, view]);

  const togglePublishMessage = useCallback(
    () => setIsPublishMessageOpen(!isPublishMessageOpen),
    [isPublishMessageOpen]
  );

  const handlePublish = useCallback(async () => {
    if (site?.remainingDays <= 0) {
      openPlans();
      return;
    }

    if (
      activePayments &&
      (!fixedOnlinePayment.value || fixedOnlinePayment.value === 0)
    ) {
      setFixedOnlinePayment((f) => ({
        ...f,
        error: true,
        helperText: 'El apartado online debe de ser mayor a $0',
      }));
      return;
    }
    if (!expirationDays.value || expirationDays.value === 0) {
      setExpirationDays((f) => ({ ...f, error: true }));
      return;
    }

    if (type === TYPE.flexible && hasDiscounts) {
      if (
        inventoryType === INVENTORY_TYPE.typology &&
        (!fixedNumberOfMonths.value || fixedNumberOfMonths.value === 0)
      ) {
        onFixedNumberOfMonthsError();
        return;
      }

      if (hasDiscounts) {
        let hasErrors = false;
        discounts.forEach((d) => {
          if (d.downPaymentPercentError || d.discountPercentError) {
            const element = document.getElementById(d.id);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
            hasErrors = true;
          }
        });
        if (hasErrors) return;
      }
    }

    togglePublishMessage();

    if (activePayments) {
      const currentCurrency = currency?.dualCurrency.main;
      const hasFixedAmount = payments?.amount?.fixedAmount?.find(
        (fa) => fa.currency === currentCurrency
      );

      let fixedAmount = [];
      if (hasFixedAmount) {
        fixedAmount = payments?.amount?.fixedAmount.map((fa) =>
          fa.currency === currentCurrency
            ? { ...fa, price: fixedOnlinePayment.value }
            : fa
        );
      } else {
        fixedAmount = [
          { currency: currentCurrency, price: fixedOnlinePayment.value },
          ...(payments?.amount?.fixedAmount ?? []),
        ];
      }

      const currentPayments = {
        ...payments,
        amount: {
          ...payments.amount,
          type: 'fixed',
          fixedAmount,
        },
      };

      await put(PUT_PAYMENTS, currentPayments, site?.site_id);
    }

    const parameters = getParameters();
    const quotationData = {
      ...quotation,
      parameters,
    };
    setQuotation(quotationData);
    await put(PUT_QUOTATION, quotationData, site?.site_id);
    post(GENERATE_QUOTATION, {}, site?.site_id);

    if (inventoryType === INVENTORY_TYPE.typology) {
      post(
        '/internal/developer/v1.1/files',
        {
          changes: [
            {
              path: 'quotation.quotation_tool',
              value: quotationTool,
            },
            {
              path: 'quotation.initial_table',
              value: initialTable,
            },
          ],
          stage: ['draft', 'production'],
          fileName: 'data',
        },
        site?.site_id
      );
    }
  }, [
    activePayments,
    currency?.dualCurrency.main,
    discounts,
    expirationDays.value,
    fixedNumberOfMonths.value,
    fixedOnlinePayment.value,
    getParameters,
    hasDiscounts,
    inventoryType,
    onFixedNumberOfMonthsError,
    openPlans,
    payments,
    quotation,
    site?.remainingDays,
    site?.site_id,
    togglePublishMessage,
    type,
  ]);

  const saveAndLeave = useCallback(() => {
    handlePublish();
    if (site?.remainingDays > 0) {
      confirmNavigation();
    }
  }, [confirmNavigation, handlePublish, site?.remainingDays]);

  return (
    <PageLayout
      links={projectTabs}
      loading={loading}
      menu={
        <FlexContainer>
          <PendingButton
            text="Guardar"
            onClick={handlePublish}
            showBullet={enableSave}
          />
          {inventoryType === INVENTORY_TYPE.unit && (
            <>
              <Spacer size={1} />
              <Button
                color="primary"
                variant="outlined"
                onClick={toggleProjectPreview}
              >
                Vista Previa
              </Button>
            </>
          )}
        </FlexContainer>
      }
    >
      <ProjectPreviewModal
        open={showProjectPreview}
        onClose={toggleProjectPreview}
      />
      <DiscardChangesDialog
        open={showPrompt}
        onSave={saveAndLeave}
        onDiscard={confirmNavigation}
      />
      <SimpleModal
        open={isPublishMessageOpen}
        onClose={togglePublishMessage}
        title="¡Plan publicado!"
        message="En unos minutos se reflejará en tu showroom digital."
        primaryText="Aceptar"
        onPrimaryClick={togglePublishMessage}
      />
      <InitialConfig
        open={view === VIEW.initialConfig}
        initialConfig={initialConfig}
        enableContinue={enableContinue}
        onChange={onInitialConfigChange}
        onDeedCostPercentBlur={onDeedCostPercentBlur}
        onDeedCostCategoriesChange={onDeedCostCategoriesChange}
        onCustomDeedCostCategoryAdd={onCustomDeedCostCategoryAdd}
        onCustomDeedCostCategoryChange={onCustomDeedCostCategoryChange}
        onCustomDeedCostCategoryDelete={onCustomDeedCostCategoryDelete}
        onExternalLoanItemsChange={onExternalLoanItemsChange}
        onContinue={toggleView}
      />
      <QuoteConfig
        open={view === VIEW.quoteConfig}
        siteName={site?.site_name}
        activePayments={activePayments}
        type={type}
        fixedOnlinePayment={fixedOnlinePayment}
        expirationDays={expirationDays}
        onFixedOnlinePaymentChange={handleFixedOnlinePaymentChange}
        onFixedOnlinePaymentBlur={handleFixedOnlinePaymentBlur}
        onExpirationDaysChange={handleExpirationDaysChange}
        minDownPaymentPercent={minDownPaymentPercent}
        onMinDownPaymentPercentChange={onMinDownPaymentPercentChange}
        maxDownPaymentPercent={maxDownPaymentPercent}
        onMaxDownPaymentPercentChange={onMaxDownPaymentPercentChange}
        minPreSalePercent={minPreSalePercent}
        onMinPreSalePercentChange={onMinPreSalePercentChange}
        hasDiscounts={hasDiscounts}
        discounts={discounts}
        onHasDiscountsChange={onHasDiscountsChange}
        onAddNewDiscount={onAddNewDiscount}
        onDeleteDiscount={onDeleteDiscount}
        onChangeDiscount={onChangeDiscount}
        onBlurDiscount={onBlurDiscount}
        onKeyUpDiscount={onKeyUpDiscount}
        fixedNumberOfMonths={fixedNumberOfMonths}
        onFixedNumberOfMonthsChange={onFixedNumberOfMonthsChange}
        inventoryType={inventoryType}
        plans={plans}
        previewUnit={previewUnit}
        onAddNewPlan={onAddNewPlan}
        onDeletePlan={onDeletePlan}
        onConfirmPlan={onConfirmPlan}
        onReorderPlan={onReorderPlan}
        previewUrl={previewUrl}
        previewRef={previewRef}
        onGoBack={toggleView}
        hasPaymentSchemes={formattedInitialConfig?.hasPaymentSchemes}
        hasExternalLoan={formattedInitialConfig?.hasExternalLoan}
        fixedMinPreSalePercent={fixedMinPreSalePercent}
        onFixedMinPreSalePercentChange={onFixedMinPreSalePercentChange}
        planName={planName}
        onPlanNameChange={onPlanNameChange}
      />
    </PageLayout>
  );
};

export default QuoteEditor;
