import { Slider, TextField } from "@mui/material"
import React from "react"
import styled from "styled-components"
import { FlexContainer, Spacer } from "../../components"
import { numberToPercent } from "../../helpers/utils"

const StyledSlider = styled(Slider)`
  height: 10px;
  .MuiSlider-thumb {
    color: #F5F5F5;
  }
  .MuiSlider-rail {
    background: #E4E4E4;
    opacity: 1;
  }
  .MuiSlider-mark {
    display: none;
  }
  .MuiSlider-markLabel {
    bottom: 30px;
    top: initial;
    &:first-of-type {
      transform: translateX(0);
    }
    &:last-of-type {
      transform: translateX(-100%);
    }
  }
`

const MARKS = [{
  value: 0,
  label: '0%',
}, {
  value: 100,
  label: '100%',
}]

const PercentEditor = ({ value, onChange, onBlur, onKeyDown, onSliderChange }) => (
  <FlexContainer vertical>
    <TextField
      value={numberToPercent(value)}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={(e) => onKeyDown(e.code)}
      onBlur={(e) => onBlur(e.target.value)}
      variant="standard"
      label="Porcentaje"
    />
    <Spacer size={6} vertical />
    <StyledSlider
      value={value}
      onChange={(e) => onSliderChange(e.target.value)}
      color="primary"
      min={0}
      max={100}
      step={1}
      marks={MARKS}
    />
  </FlexContainer>
)

export default PercentEditor
