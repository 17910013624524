import React from 'react'
import Analytics from '../../modules/Analytics/Analytics'
import MissingAnalytics from '../../modules/Analytics/MissingAnalytics'
import PropTypes from 'prop-types'
import PageLayout from '../../layouts/PageLayout'
import { prospectsTabs } from '../../helpers/tabsData'

const AnalyticView = ({ title, url }) => {
  return (
    <>
      <PageLayout links={prospectsTabs}>
        {
          url
            ? <Analytics src={url} title={title} />
            : <MissingAnalytics />
        }
      </PageLayout>
    </>
  )
}

AnalyticView.propTypes = {
  url: PropTypes.string
}

export default AnalyticView