import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import smartlookClient from 'smartlook-client';
import ProductFruits from 'react-product-fruits';
import "./App.css";
import ReactQueryClientProvider from "./contexts/ReactQueryClientProvider";
import useProfile from "./hooks/useProfile";
import { ForgotPasswordView, ResetPasswordView } from "./views";
import LoginView from "./views/Auth/LoginView";
import VerifyView from "./views/Auth/VerifyView";
import HubView from "./views/HubView";
import OnboardingView from "./views/Onboarding/OnboardingView";
import PaymentSuccess from "./views/PaymentResult/PaymentSuccess";
import PaymentFailure from "./views/PaymentResult/PaymentFailure";
import AcceptInvitationView from "./views/AcceptInvitation/AcceptInvitationView";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/es';
import { clientIdentify, initializeMixpanel } from "./helpers/mixpanel";

function App() {
  const profile = useProfile();

  useEffect(() => {
    const project_key = process.env.REACT_APP_SMARTLOOK_PROJECT_KEY
    if (project_key && project_key.length > 0) smartlookClient.init(project_key)
  }, [])

  useEffect(() => {
    initializeMixpanel()
  }, [])

  useEffect(() => {
    if (profile?.user_id) {
      smartlookClient.identify(profile.user_id, profile)
    }
  }, [profile])

  useEffect(() => {
    if (profile?.email) clientIdentify(profile.email)
  }, [profile?.email])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <ReactQueryClientProvider>
        {profile && (
          <>
            {profile.error ? (
              <Routes>
                <Route path="/login" element={<LoginView />} />
                <Route path="/verify" element={<VerifyView />} />
                <Route path="/forgot-password" element={<ForgotPasswordView />} />
                <Route path="/reset-password" element={<ResetPasswordView />} />
                <Route path="/accept-invitations" element={<AcceptInvitationView />} />
                <Route path="/register" element={<OnboardingView />} />
                <Route path="/payment-success" element={<PaymentSuccess />} />
                <Route path="/payment-failure" element={<PaymentFailure />} />
                <Route path="*" element={<Navigate to="/login" replace />} />
              </Routes>
            ) : (
              <>
                <Routes>
                  <Route path="/payment-success" element={<PaymentSuccess />} />
                  <Route path="/payment-failure" element={<PaymentFailure />} />
                  <Route path="/reset-password" element={<ResetPasswordView />} />
                  <Route path="*" element={<HubView />} />
                </Routes>
                {process.env.REACT_APP_PRODUCTFRUITS_PROJECT_CODE && process.env.REACT_APP_PRODUCTFRUITS_PROJECT_CODE.length > 0 &&
                  <ProductFruits 
                    projectCode={process.env.REACT_APP_PRODUCTFRUITS_PROJECT_CODE}
                    language="es"
                    username={profile.email}
                    email={profile.email}
                    firstname={profile.name}
                    lastname={profile.lastname}
                  />
                }
              </>
            )}
          </>
        )}
      </ReactQueryClientProvider>
    </LocalizationProvider>
  );
}

export default App;
