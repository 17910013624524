import { Check as CheckIcon, Edit as EditIcon } from '@mui/icons-material';
import {
  Checkbox,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { withStyles } from '@mui/styles';
import React, { memo, useCallback } from 'react';
import styled from 'styled-components';
import { FlexContainer, Spacer, StatusChip } from '../../components';
import { PERMISSIONS } from '../../helpers/permissions';
import { convertMMYYtoFormat } from '../../helpers/utils';
import { useNearScreen } from '../../hooks/useNearScreen';
import MMYYInput from './MMYYInput';
import PriceChangeInfo from './PriceChangeInfo';

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 65,
    '&:hover': {
      backgroundColor: theme?.palette?.action?.hover,
    },
    '&:hover button': {
      visibility: 'initial',
    },
  },
}))(TableRow);

const IconButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: ${(p) => p.color};
`;

const EditButton = styled(IconButton)`
  visibility: hidden;
`;

const StyledTableCell = styled(TableCell)`
  min-width: ${(props) => (props.width ? `${props.width}px` : '128px')};
`;
const INPUT_LABELS = {
  null: 'Sin Configurar',
};

const UnitRow = ({
  unit,
  onStatusChange,
  onPriceChange,
  onEditPrice,
  onConfirmPrice,
  onFullUpfrontPaymentPriceChange,
  onEditFullUpfrontPaymentPrice,
  onConfirmFullUpfrontPaymentPrice,
  onEditDeliveryDate,
  onConfirmDeliveryDate,
  onDeliveryDateChange,
  onCheckChange,
  currencies,
  settings,
  canSelectRow,
  changeStatusPermission,
  changeDeliveryDatePermission,
  changePricePermission,
  mainCurrencyCode,
}) => {
  const [show, element] = useNearScreen(false);
  const theme = useTheme();
  const valueNotEmpty = useCallback((value) => value !== '' && value !== 0, []);

  const handleStatusChange = useCallback(
    (status) => {
      if (onStatusChange && unit.new_status !== status)
        onStatusChange(unit.unit_id, status);
    },
    [onStatusChange, unit]
  );

  const handlePriceChange = useCallback(
    (event) => {
      if (onPriceChange) onPriceChange(unit.unit_id, event.target.value);
    },
    [onPriceChange, unit]
  );

  const handleFullUpfrontPaymentPriceChange = useCallback(
    (event) => {
      if (onFullUpfrontPaymentPriceChange)
        onFullUpfrontPaymentPriceChange(unit.unit_id, event.target.value);
    },
    [onFullUpfrontPaymentPriceChange, unit]
  );

  const handleEdit = useCallback(() => {
    if (onEditPrice) onEditPrice(unit.unit_id);
  }, [onEditPrice, unit]);

  const handleConfirm = useCallback(() => {
    if (onConfirmPrice) onConfirmPrice(unit.unit_id);
  }, [onConfirmPrice, unit]);

  const handleEditFullUpfrontPaymentPrice = useCallback(() => {
    if (onEditFullUpfrontPaymentPrice)
      onEditFullUpfrontPaymentPrice(unit.unit_id);
  }, [onEditFullUpfrontPaymentPrice, unit]);

  const handleConfirmFullUpfrontPaymentPrice = useCallback(() => {
    if (onConfirmFullUpfrontPaymentPrice)
      onConfirmFullUpfrontPaymentPrice(unit.unit_id);
  }, [onConfirmFullUpfrontPaymentPrice, unit]);

  const handleEditDeliveryDate = useCallback(() => {
    if (onEditDeliveryDate) onEditDeliveryDate(unit.unit_id);
  }, [onEditDeliveryDate, unit]);

  const handleConfirmDeliveryDate = useCallback(() => {
    if (onConfirmDeliveryDate) onConfirmDeliveryDate(unit.unit_id);
  }, [onConfirmDeliveryDate, unit]);

  const handleDeliveryDateChange = useCallback(
    (event) => {
      if (onDeliveryDateChange)
        onDeliveryDateChange(unit.unit_id, event.target.value);
    },
    [onDeliveryDateChange, unit]
  );

  const handleCheckChange = useCallback(
    (event) => {
      if (onCheckChange) onCheckChange(unit.unit_id, event.target.checked);
    },
    [onCheckChange, unit]
  );

  const convertPriceToSecondaryCurrency = useCallback((price, rate) => {
    return Math.ceil(price / rate / 100) * 100;
  }, []);

  return (
    <StyledTableRow key={unit.unit_id} ref={element}>
      {show && (
        <>
          {canSelectRow && (
            <StyledTableCell padding="checkbox" width={64}>
              <Checkbox
                checked={unit.is_selected}
                onChange={handleCheckChange}
                color="primary"
              />
            </StyledTableCell>
          )}
          <StyledTableCell component="th" scope="row">
            {unit.tower}
          </StyledTableCell>
          <StyledTableCell>{unit.phase}</StyledTableCell>
          <StyledTableCell>{unit.typology}</StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {unit.department_number}
          </StyledTableCell>
          <StyledTableCell>
            <StatusChip
              value={unit.new_status}
              onChanged={handleStatusChange}
              disabled={changeStatusPermission !== PERMISSIONS.edit}
            />
          </StyledTableCell>
          <StyledTableCell style={{ minWidth: 200, width: 200 }}>
            {changeDeliveryDatePermission === PERMISSIONS.edit ? (
              <FlexContainer verticalCentered>
                <Spacer size={1} />
                {unit.is_editing_delivery_date ? (
                  <>
                    <MMYYInput
                      value={unit.new_delivery_date}
                      onChange={handleDeliveryDateChange}
                    />
                    <IconButton
                      onClick={handleConfirmDeliveryDate}
                      color={theme.palette.success.main}
                    >
                      <CheckIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <TextField
                      value={convertMMYYtoFormat(
                        unit.new_delivery_date,
                        'MMMM YYYY'
                      )}
                      disabled
                    />
                    <EditButton
                      onClick={handleEditDeliveryDate}
                      color={theme.palette.action.active}
                    >
                      <EditIcon />
                    </EditButton>
                  </>
                )}
              </FlexContainer>
            ) : (
              <>{convertMMYYtoFormat(unit.new_delivery_date, 'MMMM YYYY')}</>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {currencies.main.symbol}
            {unit[`price_${mainCurrencyCode}`]?.toLocaleString()}
          </StyledTableCell>
          {changePricePermission === PERMISSIONS.edit && (
            <StyledTableCell>
              <FlexContainer verticalCentered>
                <PriceChangeInfo
                  basePrice={unit[`price_${mainCurrencyCode}`]}
                  newPrice={unit.new_price}
                />
                <Spacer size={1} />
                {unit.is_editing_price ? (
                  <>
                    <TextField
                      value={unit.new_price}
                      onChange={(e) =>
                        !isNaN(+e.target.value) && handlePriceChange(e)
                      }
                      onBlur={handleConfirm}
                      type="text"
                    />
                    <IconButton
                      onClick={handleConfirm}
                      color={theme.palette.success.main}
                    >
                      <CheckIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <TextField
                      value={`${
                        currencies.main.symbol
                      }${unit.new_price.toLocaleString()}`}
                      disabled
                    />
                    <EditButton
                      onClick={handleEdit}
                      color={theme.palette.action.active}
                    >
                      <EditIcon />
                    </EditButton>
                  </>
                )}
              </FlexContainer>
            </StyledTableCell>
          )}
          {changePricePermission === PERMISSIONS.edit && (
            <StyledTableCell>
              <FlexContainer verticalCentered>
                {valueNotEmpty(
                  unit[`price_${mainCurrencyCode}_full_upfront_payment`]
                ) && (
                  <PriceChangeInfo
                    basePrice={
                      unit[`price_${mainCurrencyCode}_full_upfront_payment`]
                    }
                    newPrice={unit.new_full_upfront_payment_price}
                  />
                )}
                <Spacer size={1} />
                {unit.is_editing_full_upfront_payment_price ? (
                  <>
                    <TextField
                      value={unit.new_full_upfront_payment_price}
                      onChange={(e) =>
                        !isNaN(+e.target.value) &&
                        handleFullUpfrontPaymentPriceChange(e)
                      }
                      onBlur={handleConfirmFullUpfrontPaymentPrice}
                      type="text"
                    />
                    <IconButton
                      onClick={handleConfirmFullUpfrontPaymentPrice}
                      color={theme.palette.success.main}
                    >
                      <CheckIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Typography
                      style={{ color: theme.palette.success.main }}
                      color="primary"
                    >
                      {unit?.new_full_upfront_payment_price !== null
                        ? `${
                            currencies.main.symbol
                          }${unit?.new_full_upfront_payment_price?.toLocaleString()}`
                        : INPUT_LABELS.null}
                    </Typography>
                    <EditButton
                      onClick={handleEditFullUpfrontPaymentPrice}
                      color={theme.palette.action.active}
                    >
                      <EditIcon />
                    </EditButton>
                  </>
                )}
              </FlexContainer>
            </StyledTableCell>
          )}
          {settings && settings.dualCurrency.secondary && (
            <StyledTableCell>
              {currencies.secondary.symbol}
              {convertPriceToSecondaryCurrency(
                unit[`price_${mainCurrencyCode}`],
                settings.dualCurrency.rate
              ).toLocaleString()}
            </StyledTableCell>
          )}
        </>
      )}
    </StyledTableRow>
  );
};

export default memo(UnitRow);
