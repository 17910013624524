import { Add as AddIcon } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { generateRandomId } from "../../../helpers/utils";
import SimpleImage from "./SimpleImage";

const Images = ({
  pathData = [{ id: generateRandomId() }],
  setPathData,
  maxChars = 75,
  textKey,
  textFieldLabel,
  maxCount,
}) => {
  const setItemPathData = useCallback(
    (index) => (itemPathData) => {
      pathData[index] = itemPathData;
      setPathData([...pathData]);
    },
    [pathData, setPathData]
  );

  const addImage = useCallback(() => {
    const id = generateRandomId();
    pathData.push({ id });
    setPathData([...pathData]);
  }, [pathData, setPathData]);

  const onDelete = useCallback(
    (index) => () => {
      pathData.splice(index, 1);
      setPathData([...pathData]);
    },
    [pathData, setPathData]
  );

  const disableAdd = typeof maxCount === "number" && pathData.length >= maxCount;
  const showDelete = pathData.length > 1;

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
          bgcolor: 'background.default',
          zIndex: 1,
          mb: 2,
        }}
      >
        <Typography variant="caption" color="text.secondary">
          Imágenes
        </Typography>
        <Button
          color="primary"
          size="small"
          startIcon={<AddIcon />}
          onClick={addImage}
          disabled={disableAdd}
        >
          Añadir
        </Button>
      </Box>
      <Box sx={{ display: 'grid', gap: 4 }}>
        {pathData.map((itemPathData, index) => (
          <div key={itemPathData.id}>
            <SimpleImage
              pathData={itemPathData}
              setPathData={setItemPathData(index)}
              maxChars={maxChars}
              textKey={textKey}
              textFieldLabel={textFieldLabel}
              onDelete={showDelete ? onDelete(index) : undefined}
            />
          </div>
        ))}
      </Box>
    </Box>
  );
};

export default Images;
