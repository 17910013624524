import { getPermission, PERMISSIONS } from "./permissions";

export const redirectPrioritization = [
  {
    permissionPath: ['digital_showroom', 'editor'],
    path: '/digital-showroom/editor',
  },
  {
    permissionPath: ['project', 'panel'],
    path: '/project/panel',
  },
  {
    permissionPath: ['prospects', 'prospects'],
    path: '/prospects/prospects',
  },
];

export const redirectProject = [
  {
    permissionPath: ['project', 'typology_quote_editor'],
    path: 'typology-quote',
  },
  {
    permissionPath: ['project', 'panel'],
    path: 'panel',
  },
];

export const redirectTeam = [
  {
    permissionPath: ['team', 'supervisor'],
    path: 'supervisor',
  },
  {
    permissionPath: ['team', 'sales'],
    path: 'sales',
  },
];

export const getRedirectUrl = (selectedSite, redirectArray) => {
  let toRedirect = '';
  if (selectedSite?.permissions) {
    for (let r of redirectArray) {
      const permission = getPermission(
        r.permissionPath,
        selectedSite?.permissions,
      );
      if (permission !== PERMISSIONS.hidden) {
        toRedirect = r.path;
        break;
      }
    }
  }
  return toRedirect;
}