import { Box, Tab, Tabs, styled } from '@mui/material'
import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { resolvePath, useLocation, useNavigate } from 'react-router-dom'
import { FlexContainer, Spacer } from '../components'
import { getAvailableOptions } from '../helpers/permissions'
import { TABS_KEY } from '../helpers/tabsData'
import { getPermission, PERMISSIONS } from '../helpers/permissions'
import useSite from '../hooks/useSite'
import { PageLoader } from '../modules'
import useItemsActiveHref from '../hooks/useItemsActiveHref'
import { trackEvent } from '../helpers/mixpanel'

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  padding-right: 16px;
  overflow: auto;
  ${({ sticky }) => sticky && `position: relative;`}
`

const TabsContainer = styled(FlexContainer)`
  ${({ sticky }) => sticky && `position: sticky; top: 0;`}
  ${({ theme }) => `background-color: ${theme.palette.background.default};`}
  ${({ theme }) => `z-index: ${theme.zIndex.appBar};`}
`

const StyledTab = styled(Tab)`
  padding: 0 10px;
  min-height: 48px;
  ${p => p.$isDisabled && `color: #00000042;`}
`

const PageLayout = ({ children, menu, links, loading, permissions, sticky, scrollableTabs, scrollYOffset }) => {
  const containerRef = useRef(null);
  const navigate = useNavigate()
  const location = useLocation()
  const { site } = useSite()
  const analyticsLinks = useSelector(state => state.analyticsLinks) ?? {}
  const [blockView, setBlockView] = useState(true)

  useEffect(() => {
    if (permissions && site?.permissions) {
      const permission = getPermission(permissions, site.permissions)
      if (permission === PERMISSIONS.hidden) setBlockView(true)
      else setBlockView(false)
    } else {
      setBlockView(false)
    }
  }, [permissions, site?.permissions])

  const handleTabChange = useCallback((_, newValue) => {
    if (scrollableTabs) {
      const element = document.getElementById(newValue)
      if (element) {
        const y = element.getBoundingClientRect().top + (scrollYOffset ?? 0);
        containerRef.current.scrollBy({ top: y, behavior: "smooth" })
      }
    } else {
      navigate(resolvePath(`../${newValue}`, location.pathname), { replace: true })
    }
    trackEvent(`tab-${newValue}`)
  }, [navigate, scrollYOffset, scrollableTabs, location.pathname])

  const availableTabs = useMemo(() => {
    if (!links) return []

    const filteredTabs = links.tabs.filter(l => {
      switch (l.id) {
        case TABS_KEY.funnelReport:
          if (!analyticsLinks.funnel) return false
          break
        case TABS_KEY.featuresReport:
          if (!analyticsLinks.features) return false
          break
        case TABS_KEY.usersReport:
          if (!analyticsLinks.users) return false
          break
        case TABS_KEY.audienceReport:
          if (!analyticsLinks.audience) return false
          break
        case TABS_KEY.behaviorReport:
          if (!analyticsLinks.behavior) return false
          break
        default:
      }
      return true
    })
    return getAvailableOptions(filteredTabs, site?.permissions)
  }, [analyticsLinks.audience, analyticsLinks.behavior, analyticsLinks.features, analyticsLinks.funnel, analyticsLinks.users, links, site?.permissions])

  const activeHref = useItemsActiveHref(availableTabs, containerRef.current);

  const currentUrl = useMemo(() => {
    if (!links) return ""

    if (scrollableTabs) {
      if (activeHref)
        return activeHref
      return availableTabs[0]?.href;
    }
    const pathname = location.pathname
    const pathnameParts = pathname.split('/')
    return pathnameParts[links.urlLevel]
  }, [activeHref, availableTabs, links, location.pathname, scrollableTabs])

  if (blockView) return null

  return (
    <Container sticky={sticky} ref={containerRef}>
      <TabsContainer vertical sticky={sticky}>
        <Spacer vertical size={1} />
        <FlexContainer fullWidth justified verticalCentered>
          {availableTabs.length === 0 ? (
            <div />
          ) : (
            <Tabs
              value={currentUrl}
              onChange={handleTabChange}
              aria-label="digital showroom tabs"
              variant="scrollable"
              scrollButtons="auto"
            >
              {availableTabs.map((link) => (
                <StyledTab
                  disabled={link.disabled}
                  key={link.href}
                  label={link.label}
                  value={link.href}
                  icon={link.icon}
                  iconPosition="end"
                />
              ))}
            </Tabs>
          )}
          {menu}
        </FlexContainer>
        <Spacer vertical size={1} />
      </TabsContainer>
      {loading ? <PageLoader /> : children}
    </Container>
  )
}

export default PageLayout
