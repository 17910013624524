import { Button } from "@mui/material";
import React, { useCallback, useState } from "react";
import TypologiesManagementDrawer from "./TypologiesManagementDrawer";

const TypologiesManagement = ({ inventory, setInventory }) => {
  const typologies = inventory?.typology?.items;

  const setTypologies = useCallback(
    (items) => {
      setInventory((prev) => ({ ...prev, typology: { items } }));
    },
    [setInventory]
  );

  const [show, setShow] = useState(false);
  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setShow(true)}>
        administrar tipologías
      </Button>
      <TypologiesManagementDrawer
        typologies={typologies}
        setTypologies={setTypologies}
        open={show}
        onClose={() => setShow(false)}
        PaperProps={{ sx: { maxWidth: "lg", width: 1 } }}
      />
    </>
  );
};

export default TypologiesManagement;
