export const generalFieldsRows = [
  [
    {
      type: 'full-width-text-field',
      label: 'Título*',
      field_name: 'title',
    },
  ],
  [
    {
      type: 'outlined-multiline-text-field',
      label: 'Descripción*',
      field_name: 'description',
    },
  ],
  [
    {
      type: 'text-field',
      label: 'Nombre del desarrollo*',
      field_name: 'development_name',
    },
    {
      type: 'selector',
      label: 'Tipo de proyecto*',
      field_name: 'project_type',
      options: [
        {
          value: 'department',
          label: 'Departamento',
        },
        {
          value: 'house',
          label: 'Casa',
        },
      ],
    },
  ],
  [
    {
      type: 'text-field',
      label: 'Año de construcción*',
      field_name: 'construction_year',
    },
    {
      type: 'text-field',
      label: 'Pisos en el edificio (opcional)',
      field_name: 'floors',
    },
  ],
];

export const locationFieldsRows = [
  [
    {
      type: 'text-field-autocomplete',
      label: 'Calle*',
      field_name: 'street',
    },
    {
      type: 'text-field',
      label: 'Núm. exterior*',
      field_name: 'exterior_num',
    },
  ],
  [
    {
      type: 'text-field',
      label: 'Nombre del Barrio/Zona*',
      field_name: 'area',
    },
    {
      type: 'text-field',
      label: 'Código postal*',
      field_name: 'zip_code',
    },
  ],
  [
    {
      type: 'selector',
      label: 'Estado*',
      field_name: 'state',
      options: [
        {
          value: 'Aguascalientes',
          label: 'Aguascalientes',
        },
        {
          value: 'Baja California',
          label: 'Baja California',
        },
        {
          value: 'Baja California Sur',
          label: 'Baja California Sur',
        },
        {
          value: 'Campeche',
          label: 'Campeche',
        },
        {
          value: 'Coahuila de Zaragoza',
          label: 'Coahuila de Zaragoza',
        },
        {
          value: 'Colima',
          label: 'Colima',
        },
        {
          value: 'Chiapas',
          label: 'Chiapas',
        },
        {
          value: 'Chihuahua',
          label: 'Chihuahua',
        },
        {
          value: 'Ciudad de México',
          label: 'Ciudad de México',
        },
        {
          value: 'Durango',
          label: 'Durango',
        },
        {
          value: 'Guanajuato',
          label: 'Guanajuato',
        },
        {
          value: 'Guerrero',
          label: 'Guerrero',
        },
        {
          value: 'Hidalgo',
          label: 'Hidalgo',
        },
        {
          value: 'Jalisco',
          label: 'Jalisco',
        },
        {
          value: 'México',
          label: 'México',
        },
        {
          value: 'Michoacán de Ocampo',
          label: 'Michoacán de Ocampo',
        },
        {
          value: 'Morelos',
          label: 'Morelos',
        },
        {
          value: 'Nayarit',
          label: 'Nayarit',
        },
        {
          value: 'Nuevo León',
          label: 'Nuevo León',
        },
        {
          value: 'Oaxaca',
          label: 'Oaxaca',
        },
        {
          value: 'Puebla',
          label: 'Puebla',
        },
        {
          value: 'Querétaro',
          label: 'Querétaro',
        },
        {
          value: 'Quintana Roo',
          label: 'Quintana Roo',
        },
        {
          value: 'San Luis Potosí',
          label: 'San Luis Potosí',
        },
        {
          value: 'Sinaloa',
          label: 'Sinaloa',
        },
        {
          value: 'Sonora',
          label: 'Sonora',
        },
        {
          value: 'Tabasco',
          label: 'Tabasco',
        },
        {
          value: 'Tamaulipas',
          label: 'Tamaulipas',
        },
        {
          value: 'Tlaxcala',
          label: 'Tlaxcala',
        },
        {
          value: 'Veracruz de Ignacio de la Llave',
          label: 'Veracruz de Ignacio de la Llave',
        },
        {
          value: 'Yucatán',
          label: 'Yucatán',
        },
        {
          value: 'Zacatecas',
          label: 'Zacatecas',
        },
      ],
    },
    {
      type: 'selector',
      label: 'Ciudad*',
      field_name: 'city',
      options: [
        {
          value: 'Aguascalientes',
          label: 'Aguascalientes',
          condition: ['state', '=', ['Aguascalientes']],
        },
        {
          value: 'San Francisco de los Romo',
          label: 'San Francisco de los Romo',
          condition: ['state', '=', ['Aguascalientes']],
        },
        {
          value: 'Rincón de Romos',
          label: 'Rincón de Romos',
          condition: ['state', '=', ['Aguascalientes']],
        },
        {
          value: 'Cosío',
          label: 'Cosío',
          condition: ['state', '=', ['Aguascalientes']],
        },
        {
          value: 'Tepezalá',
          label: 'Tepezalá',
          condition: ['state', '=', ['Aguascalientes']],
        },
        {
          value: 'Pabellón de Arteaga',
          label: 'Pabellón de Arteaga',
          condition: ['state', '=', ['Aguascalientes']],
        },
        {
          value: 'Asientos',
          label: 'Asientos',
          condition: ['state', '=', ['Aguascalientes']],
        },
        {
          value: 'Calvillo',
          label: 'Calvillo',
          condition: ['state', '=', ['Aguascalientes']],
        },
        {
          value: 'Jesús María',
          label: 'Jesús María',
          condition: ['state', '=', ['Aguascalientes']],
        },
        {
          value: 'Mexicali',
          label: 'Mexicali',
          condition: ['state', '=', ['Baja California']],
        },
        {
          value: 'Tecate',
          label: 'Tecate',
          condition: ['state', '=', ['Baja California']],
        },
        {
          value: 'San Felipe',
          label: 'San Felipe',
          condition: ['state', '=', ['Baja California']],
        },
        {
          value: 'Tijuana',
          label: 'Tijuana',
          condition: ['state', '=', ['Baja California']],
        },
        {
          value: 'Playas de Rosarito',
          label: 'Playas de Rosarito',
          condition: ['state', '=', ['Baja California']],
        },
        {
          value: 'Rodolfo Sánchez Taboada (Maneadero)',
          label: 'Rodolfo Sánchez Taboada (Maneadero)',
          condition: ['state', '=', ['Baja California']],
        },
        {
          value: 'Ensenada',
          label: 'Ensenada',
          condition: ['state', '=', ['Baja California']],
        },
        {
          value: 'La Paz',
          label: 'La Paz',
          condition: ['state', '=', ['Baja California Sur']],
        },
        {
          value: 'Todos Santos',
          label: 'Todos Santos',
          condition: ['state', '=', ['Baja California Sur']],
        },
        {
          value: 'San José del Cabo',
          label: 'San José del Cabo',
          condition: ['state', '=', ['Baja California Sur']],
        },
        {
          value: 'Cabo San Lucas',
          label: 'Cabo San Lucas',
          condition: ['state', '=', ['Baja California Sur']],
        },
        {
          value: 'Ciudad Constitución',
          label: 'Ciudad Constitución',
          condition: ['state', '=', ['Baja California Sur']],
        },
        {
          value: 'Puerto Adolfo López Mateos',
          label: 'Puerto Adolfo López Mateos',
          condition: ['state', '=', ['Baja California Sur']],
        },
        {
          value: 'Loreto',
          label: 'Loreto',
          condition: ['state', '=', ['Baja California Sur', 'Zacatecas']],
        },
        {
          value: 'Heroica Mulegé',
          label: 'Heroica Mulegé',
          condition: ['state', '=', ['Baja California Sur']],
        },
        {
          value: 'Santa Rosalía',
          label: 'Santa Rosalía',
          condition: ['state', '=', ['Baja California Sur']],
        },
        {
          value: 'San Ignacio',
          label: 'San Ignacio',
          condition: ['state', '=', ['Baja California Sur', 'Sinaloa']],
        },
        {
          value: 'Villa Alberto Andrés Alvarado Arámburo',
          label: 'Villa Alberto Andrés Alvarado Arámburo',
          condition: ['state', '=', ['Baja California Sur']],
        },
        {
          value: 'Guerrero Negro',
          label: 'Guerrero Negro',
          condition: ['state', '=', ['Baja California Sur']],
        },
        {
          value: 'San Francisco de Campeche',
          label: 'San Francisco de Campeche',
          condition: ['state', '=', ['Campeche']],
        },
        {
          value: 'Ciudad del Carmen',
          label: 'Ciudad del Carmen',
          condition: ['state', '=', ['Campeche']],
        },
        {
          value: 'Sabancuy',
          label: 'Sabancuy',
          condition: ['state', '=', ['Campeche']],
        },
        {
          value: 'Candelaria',
          label: 'Candelaria',
          condition: ['state', '=', ['Campeche']],
        },
        {
          value: 'Escárcega',
          label: 'Escárcega',
          condition: ['state', '=', ['Campeche']],
        },
        {
          value: 'Champotón',
          label: 'Champotón',
          condition: ['state', '=', ['Campeche']],
        },
        {
          value: 'Hopelchén',
          label: 'Hopelchén',
          condition: ['state', '=', ['Campeche']],
        },
        {
          value: 'Tenabo',
          label: 'Tenabo',
          condition: ['state', '=', ['Campeche']],
        },
        {
          value: 'Hecelchakán',
          label: 'Hecelchakán',
          condition: ['state', '=', ['Campeche']],
        },
        {
          value: 'Pomuch',
          label: 'Pomuch',
          condition: ['state', '=', ['Campeche']],
        },
        {
          value: 'Calkini',
          label: 'Calkini',
          condition: ['state', '=', ['Campeche']],
        },
        {
          value: 'Bécal',
          label: 'Bécal',
          condition: ['state', '=', ['Campeche']],
        },
        {
          value: 'Saltillo',
          label: 'Saltillo',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'Arteaga',
          label: 'Arteaga',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'San Buenaventura',
          label: 'San Buenaventura',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'Frontera',
          label: 'Frontera',
          condition: ['state', '=', ['Coahuila de Zaragoza', 'Tabasco']],
        },
        {
          value: 'Monclova',
          label: 'Monclova',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'Castaños',
          label: 'Castaños',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'Ramos Arizpe',
          label: 'Ramos Arizpe',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'Piedras Negras',
          label: 'Piedras Negras',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'Nava',
          label: 'Nava',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'Ciudad Acuña',
          label: 'Ciudad Acuña',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'Ciudad Melchor Múzquiz',
          label: 'Ciudad Melchor Múzquiz',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'Zaragoza',
          label: 'Zaragoza',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'Morelos',
          label: 'Morelos',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'Allende',
          label: 'Allende',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'Sabinas',
          label: 'Sabinas',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'Nueva Rosita',
          label: 'Nueva Rosita',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'Torreón',
          label: 'Torreón',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'Matamoros',
          label: 'Matamoros',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'Viesca',
          label: 'Viesca',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'Nadadores',
          label: 'Nadadores',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'Cuatro Ciénegas de Carranza',
          label: 'Cuatro Ciénegas de Carranza',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'San Pedro',
          label: 'San Pedro',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'Francisco I. Madero (Chávez)',
          label: 'Francisco I. Madero (Chávez)',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'Parras de la Fuente',
          label: 'Parras de la Fuente',
          condition: ['state', '=', ['Coahuila de Zaragoza']],
        },
        {
          value: 'Colima',
          label: 'Colima',
          condition: ['state', '=', ['Colima']],
        },
        {
          value: 'Tecomán',
          label: 'Tecomán',
          condition: ['state', '=', ['Colima']],
        },
        {
          value: 'Manzanillo',
          label: 'Manzanillo',
          condition: ['state', '=', ['Colima']],
        },
        {
          value: 'Ciudad de Armería',
          label: 'Ciudad de Armería',
          condition: ['state', '=', ['Colima']],
        },
        {
          value: 'Ciudad de Villa de Álvarez',
          label: 'Ciudad de Villa de Álvarez',
          condition: ['state', '=', ['Colima']],
        },
        {
          value: 'Tuxtla Gutiérrez',
          label: 'Tuxtla Gutiérrez',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Ocozocoautla de Espinosa',
          label: 'Ocozocoautla de Espinosa',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Chiapa de Corzo',
          label: 'Chiapa de Corzo',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'San Cristóbal de las Casas',
          label: 'San Cristóbal de las Casas',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Acala',
          label: 'Acala',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Reforma',
          label: 'Reforma',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Pichucalco',
          label: 'Pichucalco',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Ocosingo',
          label: 'Ocosingo',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Palenque',
          label: 'Palenque',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Comitán de Domínguez',
          label: 'Comitán de Domínguez',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Las Margaritas',
          label: 'Las Margaritas',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Venustiano Carranza',
          label: 'Venustiano Carranza',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Las Rosas',
          label: 'Las Rosas',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Cintalapa de Figueroa',
          label: 'Cintalapa de Figueroa',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Jiquipilas',
          label: 'Jiquipilas',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Arriaga',
          label: 'Arriaga',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Villaflores',
          label: 'Villaflores',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Tonalá',
          label: 'Tonalá',
          condition: ['state', '=', ['Chiapas', 'Jalisco']],
        },
        {
          value: 'Pijijiapan',
          label: 'Pijijiapan',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Mapastepec',
          label: 'Mapastepec',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Huixtla',
          label: 'Huixtla',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Tapachula de Córdova y Ordóñez',
          label: 'Tapachula de Córdova y Ordóñez',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Puerto Madero (San Benito)',
          label: 'Puerto Madero (San Benito)',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Cacahoatán',
          label: 'Cacahoatán',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Motozintla de Mendoza',
          label: 'Motozintla de Mendoza',
          condition: ['state', '=', ['Chiapas']],
        },
        {
          value: 'Chihuahua',
          label: 'Chihuahua',
          condition: ['state', '=', ['Chihuahua']],
        },
        {
          value: 'Cuauhtémoc',
          label: 'Cuauhtémoc',
          condition: ['state', '=', ['Chihuahua']],
        },
        {
          value: 'Colonia Anáhuac',
          label: 'Colonia Anáhuac',
          condition: ['state', '=', ['Chihuahua']],
        },
        {
          value: 'Bachíniva',
          label: 'Bachíniva',
          condition: ['state', '=', ['Chihuahua']],
        },
        {
          value: 'Nuevo Casas Grandes',
          label: 'Nuevo Casas Grandes',
          condition: ['state', '=', ['Chihuahua']],
        },
        {
          value: 'Madera',
          label: 'Madera',
          condition: ['state', '=', ['Chihuahua']],
        },
        {
          value: 'Juárez',
          label: 'Juárez',
          condition: ['state', '=', ['Chihuahua']],
        },
        {
          value: 'Manuel Ojinaga',
          label: 'Manuel Ojinaga',
          condition: ['state', '=', ['Chihuahua']],
        },
        {
          value: 'Juan Aldama',
          label: 'Juan Aldama',
          condition: ['state', '=', ['Chihuahua', 'Zacatecas']],
        },
        {
          value: 'Delicias',
          label: 'Delicias',
          condition: ['state', '=', ['Chihuahua']],
        },
        {
          value: 'Saucillo',
          label: 'Saucillo',
          condition: ['state', '=', ['Chihuahua']],
        },
        {
          value: 'Santa Rosalía de Camargo',
          label: 'Santa Rosalía de Camargo',
          condition: ['state', '=', ['Chihuahua']],
        },
        {
          value: 'Hidalgo del Parral',
          label: 'Hidalgo del Parral',
          condition: ['state', '=', ['Chihuahua']],
        },
        {
          value: 'José Mariano Jiménez',
          label: 'José Mariano Jiménez',
          condition: ['state', '=', ['Chihuahua']],
        },
        {
          value: 'Ciudad de México',
          label: 'Ciudad de México',
          condition: ['state', '=', ['Ciudad de México']],
        },
        {
          value: 'Victoria de Durango',
          label: 'Victoria de Durango',
          condition: ['state', '=', ['Durango']],
        },
        {
          value: 'Canatlán',
          label: 'Canatlán',
          condition: ['state', '=', ['Durango']],
        },
        {
          value: 'San Juan del Río del Centauro del Norte',
          label: 'San Juan del Río del Centauro del Norte',
          condition: ['state', '=', ['Durango']],
        },
        {
          value: 'Santiago Papasquiaro',
          label: 'Santiago Papasquiaro',
          condition: ['state', '=', ['Durango']],
        },
        {
          value: 'Peñón Blanco',
          label: 'Peñón Blanco',
          condition: ['state', '=', ['Durango']],
        },
        {
          value: 'Francisco I. Madero',
          label: 'Francisco I. Madero',
          condition: ['state', '=', ['Durango']],
        },
        {
          value: 'Nombre de Dios',
          label: 'Nombre de Dios',
          condition: ['state', '=', ['Durango']],
        },
        {
          value: 'Vicente Guerrero',
          label: 'Vicente Guerrero',
          condition: ['state', '=', ['Durango']],
        },
        {
          value: 'El Salto',
          label: 'El Salto',
          condition: ['state', '=', ['Durango', 'Jalisco']],
        },
        {
          value: 'Gómez Palacio',
          label: 'Gómez Palacio',
          condition: ['state', '=', ['Durango']],
        },
        {
          value: 'Ciudad Lerdo',
          label: 'Ciudad Lerdo',
          condition: ['state', '=', ['Durango']],
        },
        {
          value: 'Santa María del Oro',
          label: 'Santa María del Oro',
          condition: ['state', '=', ['Durango']],
        },
        {
          value: 'Guanajuato',
          label: 'Guanajuato',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Silao',
          label: 'Silao',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Romita',
          label: 'Romita',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Marfil',
          label: 'Marfil',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'San Francisco del Rincón',
          label: 'San Francisco del Rincón',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Purísima de Bustos',
          label: 'Purísima de Bustos',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Ciudad Manuel Doblado',
          label: 'Ciudad Manuel Doblado',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Irapuato',
          label: 'Irapuato',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Salamanca',
          label: 'Salamanca',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Pénjamo',
          label: 'Pénjamo',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Cuerámaro',
          label: 'Cuerámaro',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Abasolo',
          label: 'Abasolo',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Huanímaro',
          label: 'Huanímaro',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'León de los Aldama',
          label: 'León de los Aldama',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'San Miguel de Allende',
          label: 'San Miguel de Allende',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Dolores Hidalgo Cuna de la Independencia Nacional',
          label: 'Dolores Hidalgo Cuna de la Independencia Nacional',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'San Diego de la Unión',
          label: 'San Diego de la Unión',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'San Luis de la Paz',
          label: 'San Luis de la Paz',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Doctor Mora',
          label: 'Doctor Mora',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'San José Iturbide',
          label: 'San José Iturbide',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Celaya',
          label: 'Celaya',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Rincón de Tamayo',
          label: 'Rincón de Tamayo',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Apaseo el Grande',
          label: 'Apaseo el Grande',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Comonfort',
          label: 'Comonfort',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Empalme Escobedo',
          label: 'Empalme Escobedo',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Santa Cruz Juventino Rosas',
          label: 'Santa Cruz Juventino Rosas',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Villagrán',
          label: 'Villagrán',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Cortazar',
          label: 'Cortazar',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Valle de Santiago',
          label: 'Valle de Santiago',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Jaral del Progreso',
          label: 'Jaral del Progreso',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Apaseo el Alto',
          label: 'Apaseo el Alto',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Jerécuaro',
          label: 'Jerécuaro',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Acámbaro',
          label: 'Acámbaro',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Tarandacuao',
          label: 'Tarandacuao',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Moroleón',
          label: 'Moroleón',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Salvatierra',
          label: 'Salvatierra',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Yuriria',
          label: 'Yuriria',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Santiago Maravatío',
          label: 'Santiago Maravatío',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Uriangato',
          label: 'Uriangato',
          condition: ['state', '=', ['Guanajuato']],
        },
        {
          value: 'Chilpancingo de los Bravo',
          label: 'Chilpancingo de los Bravo',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Ocotito',
          label: 'Ocotito',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Tixtla de Guerrero',
          label: 'Tixtla de Guerrero',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Ayutla de los Libres',
          label: 'Ayutla de los Libres',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Acapulco de Juárez',
          label: 'Acapulco de Juárez',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Tierra Colorada',
          label: 'Tierra Colorada',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'San Marcos',
          label: 'San Marcos',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Iguala de la Independencia',
          label: 'Iguala de la Independencia',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Huitzuco',
          label: 'Huitzuco',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Zumpango del Río',
          label: 'Zumpango del Río',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Taxco de Alarcón',
          label: 'Taxco de Alarcón',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Buenavista de Cuéllar',
          label: 'Buenavista de Cuéllar',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Teloloapan',
          label: 'Teloloapan',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Arcelia',
          label: 'Arcelia',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Ciudad Apaxtla de Castrejón',
          label: 'Ciudad Apaxtla de Castrejón',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Tlapehuala',
          label: 'Tlapehuala',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Cutzamala de Pinzón',
          label: 'Cutzamala de Pinzón',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Ciudad Altamirano',
          label: 'Ciudad Altamirano',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Coyuca de Catalán',
          label: 'Coyuca de Catalán',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'La Unión',
          label: 'La Unión',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Petatlán',
          label: 'Petatlán',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Zihuatanejo',
          label: 'Zihuatanejo',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Técpan de Galeana',
          label: 'Técpan de Galeana',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'San Luis San Pedro',
          label: 'San Luis San Pedro',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'San Luis de la Loma',
          label: 'San Luis de la Loma',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Atoyac de Álvarez',
          label: 'Atoyac de Álvarez',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'San Jerónimo de Juárez',
          label: 'San Jerónimo de Juárez',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Coyuca de Benítez',
          label: 'Coyuca de Benítez',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Olinalá',
          label: 'Olinalá',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Chilapa de Álvarez',
          label: 'Chilapa de Álvarez',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Huamuxtitlán',
          label: 'Huamuxtitlán',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Tlapa de Comonfort',
          label: 'Tlapa de Comonfort',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'San Luis Acatlán',
          label: 'San Luis Acatlán',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Cruz Grande',
          label: 'Cruz Grande',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Copala',
          label: 'Copala',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Azoyú',
          label: 'Azoyú',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Marquelia',
          label: 'Marquelia',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Cuajinicuilapa',
          label: 'Cuajinicuilapa',
          condition: ['state', '=', ['Guerrero']],
        },
        {
          value: 'Pachuca de Soto',
          label: 'Pachuca de Soto',
          condition: ['state', '=', ['Hidalgo']],
        },
        {
          value: 'Ixmiquilpan',
          label: 'Ixmiquilpan',
          condition: ['state', '=', ['Hidalgo']],
        },
        {
          value: 'Zimapán',
          label: 'Zimapán',
          condition: ['state', '=', ['Hidalgo']],
        },
        {
          value: 'Actopan',
          label: 'Actopan',
          condition: ['state', '=', ['Hidalgo']],
        },
        {
          value: 'Tula de Allende',
          label: 'Tula de Allende',
          condition: ['state', '=', ['Hidalgo']],
        },
        {
          value: 'San Miguel Vindho',
          label: 'San Miguel Vindho',
          condition: ['state', '=', ['Hidalgo']],
        },
        {
          value: 'Tepeji del Río de Ocampo',
          label: 'Tepeji del Río de Ocampo',
          condition: ['state', '=', ['Hidalgo']],
        },
        {
          value: 'Tlaxcoapan',
          label: 'Tlaxcoapan',
          condition: ['state', '=', ['Hidalgo']],
        },
        {
          value: 'Huejutla de Reyes',
          label: 'Huejutla de Reyes',
          condition: ['state', '=', ['Hidalgo']],
        },
        {
          value: 'Tulancingo',
          label: 'Tulancingo',
          condition: ['state', '=', ['Hidalgo']],
        },
        {
          value: 'Santiago Tulantepec',
          label: 'Santiago Tulantepec',
          condition: ['state', '=', ['Hidalgo']],
        },
        {
          value: 'Tizayuca',
          label: 'Tizayuca',
          condition: ['state', '=', ['Hidalgo']],
        },
        {
          value: 'Apan',
          label: 'Apan',
          condition: ['state', '=', ['Hidalgo']],
        },
        {
          value: 'Tepeapulco',
          label: 'Tepeapulco',
          condition: ['state', '=', ['Hidalgo']],
        },
        {
          value: 'Fray Bernardino de Sahagún (Ciudad Sahagún)',
          label: 'Fray Bernardino de Sahagún (Ciudad Sahagún)',
          condition: ['state', '=', ['Hidalgo']],
        },
        {
          value: 'Guadalajara',
          label: 'Guadalajara',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Zapopan',
          label: 'Zapopan',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Tala',
          label: 'Tala',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Tlaquepaque',
          label: 'Tlaquepaque',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Tlajomulco de Zúñiga',
          label: 'Tlajomulco de Zúñiga',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Las Pintitas',
          label: 'Las Pintitas',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'San José el Verde (El Verde)',
          label: 'San José el Verde (El Verde)',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'El Quince (San José el Quince)',
          label: 'El Quince (San José el Quince)',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Acatlán de Juárez',
          label: 'Acatlán de Juárez',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Villa Corona',
          label: 'Villa Corona',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Zacoalco de Torres',
          label: 'Zacoalco de Torres',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Jocotepec',
          label: 'Jocotepec',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Chapala',
          label: 'Chapala',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Ajijic',
          label: 'Ajijic',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Poncitlán',
          label: 'Poncitlán',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Huejuquilla el Alto',
          label: 'Huejuquilla el Alto',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Colotlán',
          label: 'Colotlán',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Tequila',
          label: 'Tequila',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Magdalena',
          label: 'Magdalena',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Etzatlán',
          label: 'Etzatlán',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Ameca',
          label: 'Ameca',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Ahualulco de Mercado',
          label: 'Ahualulco de Mercado',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'San Juan de los Lagos',
          label: 'San Juan de los Lagos',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Jalostotitlán',
          label: 'Jalostotitlán',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'San Miguel el Alto',
          label: 'San Miguel el Alto',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'San Julián',
          label: 'San Julián',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Arandas',
          label: 'Arandas',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'San Ignacio Cerro Gordo',
          label: 'San Ignacio Cerro Gordo',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Teocaltiche',
          label: 'Teocaltiche',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Villa Hidalgo',
          label: 'Villa Hidalgo',
          condition: ['state', '=', ['Jalisco', 'Zacatecas']],
        },
        {
          value: 'Yahualica de González Gallo',
          label: 'Yahualica de González Gallo',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Valle de Guadalupe',
          label: 'Valle de Guadalupe',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Lagos de Moreno',
          label: 'Lagos de Moreno',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Unión de San Antonio',
          label: 'Unión de San Antonio',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'San Diego de Alejandría',
          label: 'San Diego de Alejandría',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Tepatitlán de Morelos',
          label: 'Tepatitlán de Morelos',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Tototlán',
          label: 'Tototlán',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Atotonilco el Alto',
          label: 'Atotonilco el Alto',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Ocotlán',
          label: 'Ocotlán',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Jamay',
          label: 'Jamay',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'La Barca',
          label: 'La Barca',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Talpa de Allende',
          label: 'Talpa de Allende',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Puerto Vallarta',
          label: 'Puerto Vallarta',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Cocula',
          label: 'Cocula',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'El Grullo',
          label: 'El Grullo',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Villa Purificación',
          label: 'Villa Purificación',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Autlán de Navarro',
          label: 'Autlán de Navarro',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'La Resolana',
          label: 'La Resolana',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Cihuatlán',
          label: 'Cihuatlán',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Ciudad Guzmán',
          label: 'Ciudad Guzmán',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Sayula',
          label: 'Sayula',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Zapotiltic',
          label: 'Zapotiltic',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Tamazula de Gordiano',
          label: 'Tamazula de Gordiano',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Tuxpan',
          label: 'Tuxpan',
          condition: [
            'state',
            '=',
            ['Jalisco', 'Michoacán de Ocampo', 'Nayarit'],
          ],
        },
        {
          value: 'Tecalitlán',
          label: 'Tecalitlán',
          condition: ['state', '=', ['Jalisco']],
        },
        {
          value: 'Toluca de Lerdo',
          label: 'Toluca de Lerdo',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Almoloya de Juárez',
          label: 'Almoloya de Juárez',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Tejupilco de Hidalgo',
          label: 'Tejupilco de Hidalgo',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Amatepec',
          label: 'Amatepec',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Xonacatlán',
          label: 'Xonacatlán',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'San Mateo Atenco',
          label: 'San Mateo Atenco',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Metepec',
          label: 'Metepec',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Capulhuac',
          label: 'Capulhuac',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Ocoyoacac',
          label: 'Ocoyoacac',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Huixquilucan de Degollado',
          label: 'Huixquilucan de Degollado',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Ciudad Adolfo López Mateos',
          label: 'Ciudad Adolfo López Mateos',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Naucalpan de Juárez',
          label: 'Naucalpan de Juárez',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Tlalnepantla de Baz',
          label: 'Tlalnepantla de Baz',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Ciudad Nicolás Romero',
          label: 'Ciudad Nicolás Romero',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Tepotzotlán',
          label: 'Tepotzotlán',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Cuautitlán Izcalli',
          label: 'Cuautitlán Izcalli',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Cuautitlán',
          label: 'Cuautitlán',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Melchor Ocampo',
          label: 'Melchor Ocampo',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Tultitlán de Mariano Escobedo',
          label: 'Tultitlán de Mariano Escobedo',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Santa Maria Tultepec',
          label: 'Santa Maria Tultepec',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Ecatepec de Morelos',
          label: 'Ecatepec de Morelos',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Zumpango',
          label: 'Zumpango',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Tequixquiac',
          label: 'Tequixquiac',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Coacalco de Berriozabal',
          label: 'Coacalco de Berriozabal',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Tecámac de Felipe Villanueva',
          label: 'Tecámac de Felipe Villanueva',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Texcoco de Mora',
          label: 'Texcoco de Mora',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Chiconcuac',
          label: 'Chiconcuac',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Chimalhuacán',
          label: 'Chimalhuacán',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Chicoloapan de Juárez',
          label: 'Chicoloapan de Juárez',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Los Reyes Acaquilpan (La Paz)',
          label: 'Los Reyes Acaquilpan (La Paz)',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Ixtapaluca',
          label: 'Ixtapaluca',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Chalco de Díaz Covarrubias',
          label: 'Chalco de Díaz Covarrubias',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Valle de Chalco Solidaridad',
          label: 'Valle de Chalco Solidaridad',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Juchitepec de Mariano Rivapalacio',
          label: 'Juchitepec de Mariano Rivapalacio',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Ciudad Nezahualcóyotl',
          label: 'Ciudad Nezahualcóyotl',
          condition: ['state', '=', ['México']],
        },
        {
          value: 'Morelia',
          label: 'Morelia',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Puruándiro',
          label: 'Puruándiro',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Zacapu',
          label: 'Zacapu',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Cuitzeo del Porvenir',
          label: 'Cuitzeo del Porvenir',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Zinapécuaro de Figueroa',
          label: 'Zinapécuaro de Figueroa',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Sahuayo de Morelos',
          label: 'Sahuayo de Morelos',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Yurécuaro',
          label: 'Yurécuaro',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'La Piedad de Cabadas',
          label: 'La Piedad de Cabadas',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Jiquilpan de Juárez',
          label: 'Jiquilpan de Juárez',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Zamora de Hidalgo',
          label: 'Zamora de Hidalgo',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Tangancícuaro de Arista',
          label: 'Tangancícuaro de Arista',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Jacona de Plancarte',
          label: 'Jacona de Plancarte',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Cotija de la Paz',
          label: 'Cotija de la Paz',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Uruapan',
          label: 'Uruapan',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Paracho de Verduzco',
          label: 'Paracho de Verduzco',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Los Reyes de Salgado',
          label: 'Los Reyes de Salgado',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Apatzingán de la Constitución',
          label: 'Apatzingán de la Constitución',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Ciudad Lázaro Cárdenas',
          label: 'Ciudad Lázaro Cárdenas',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Las Guacamayas',
          label: 'Las Guacamayas',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Ciudad Hidalgo',
          label: 'Ciudad Hidalgo',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Maravatío de Ocampo',
          label: 'Maravatío de Ocampo',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Heróica Zitácuaro',
          label: 'Heróica Zitácuaro',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Pátzcuaro',
          label: 'Pátzcuaro',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Tacámbaro de Codallos',
          label: 'Tacámbaro de Codallos',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Nueva Italia de Ruiz',
          label: 'Nueva Italia de Ruiz',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Huetamo de Núñez',
          label: 'Huetamo de Núñez',
          condition: ['state', '=', ['Michoacán de Ocampo']],
        },
        {
          value: 'Cuernavaca',
          label: 'Cuernavaca',
          condition: ['state', '=', ['Morelos']],
        },
        {
          value: 'Jiutepec',
          label: 'Jiutepec',
          condition: ['state', '=', ['Morelos']],
        },
        {
          value: 'Temixco',
          label: 'Temixco',
          condition: ['state', '=', ['Morelos']],
        },
        {
          value: 'Puente de Ixtla',
          label: 'Puente de Ixtla',
          condition: ['state', '=', ['Morelos']],
        },
        {
          value: 'Yautepec de Zaragoza',
          label: 'Yautepec de Zaragoza',
          condition: ['state', '=', ['Morelos']],
        },
        {
          value: 'Cuautla',
          label: 'Cuautla',
          condition: ['state', '=', ['Morelos']],
        },
        {
          value: 'Santa Rosa Treinta',
          label: 'Santa Rosa Treinta',
          condition: ['state', '=', ['Morelos']],
        },
        {
          value: 'Zacatepec de Hidalgo',
          label: 'Zacatepec de Hidalgo',
          condition: ['state', '=', ['Morelos']],
        },
        {
          value: 'Galeana',
          label: 'Galeana',
          condition: ['state', '=', ['Morelos']],
        },
        {
          value: 'Jojutla',
          label: 'Jojutla',
          condition: ['state', '=', ['Morelos']],
        },
        {
          value: 'Tlaquiltenango',
          label: 'Tlaquiltenango',
          condition: ['state', '=', ['Morelos']],
        },
        {
          value: 'Tepic',
          label: 'Tepic',
          condition: ['state', '=', ['Nayarit']],
        },
        {
          value: 'Santiago Ixcuintla',
          label: 'Santiago Ixcuintla',
          condition: ['state', '=', ['Nayarit']],
        },
        {
          value: 'Acaponeta',
          label: 'Acaponeta',
          condition: ['state', '=', ['Nayarit']],
        },
        {
          value: 'Tecuala',
          label: 'Tecuala',
          condition: ['state', '=', ['Nayarit']],
        },
        {
          value: 'Francisco I. Madero (Puga)',
          label: 'Francisco I. Madero (Puga)',
          condition: ['state', '=', ['Nayarit']],
        },
        {
          value: 'Villa Hidalgo (El Nuevo)',
          label: 'Villa Hidalgo (El Nuevo)',
          condition: ['state', '=', ['Nayarit']],
        },
        {
          value: 'Ruíz',
          label: 'Ruíz',
          condition: ['state', '=', ['Nayarit']],
        },
        {
          value: 'Compostela',
          label: 'Compostela',
          condition: ['state', '=', ['Nayarit']],
        },
        {
          value: 'Las Varas',
          label: 'Las Varas',
          condition: ['state', '=', ['Nayarit']],
        },
        {
          value: 'La Peñita de Jaltemba',
          label: 'La Peñita de Jaltemba',
          condition: ['state', '=', ['Nayarit']],
        },
        {
          value: 'Bucerías',
          label: 'Bucerías',
          condition: ['state', '=', ['Nayarit']],
        },
        {
          value: 'San Blas',
          label: 'San Blas',
          condition: ['state', '=', ['Nayarit', 'Sinaloa']],
        },
        {
          value: 'Xalisco',
          label: 'Xalisco',
          condition: ['state', '=', ['Nayarit']],
        },
        {
          value: 'San Pedro Lagunillas',
          label: 'San Pedro Lagunillas',
          condition: ['state', '=', ['Nayarit']],
        },
        {
          value: 'Jala',
          label: 'Jala',
          condition: ['state', '=', ['Nayarit']],
        },
        {
          value: 'Ahuacatlán',
          label: 'Ahuacatlán',
          condition: ['state', '=', ['Nayarit']],
        },
        {
          value: 'Ixtlán del Río',
          label: 'Ixtlán del Río',
          condition: ['state', '=', ['Nayarit']],
        },
        {
          value: 'Monterrey',
          label: 'Monterrey',
          condition: ['state', '=', ['Nuevo León']],
        },
        {
          value: 'Anáhuac',
          label: 'Anáhuac',
          condition: ['state', '=', ['Nuevo León']],
        },
        {
          value: 'Ciudad Sabinas Hidalgo',
          label: 'Ciudad Sabinas Hidalgo',
          condition: ['state', '=', ['Nuevo León']],
        },
        {
          value: 'Ciénega de Flores',
          label: 'Ciénega de Flores',
          condition: ['state', '=', ['Nuevo León']],
        },
        {
          value: 'García',
          label: 'García',
          condition: ['state', '=', ['Nuevo León']],
        },
        {
          value: 'Ciudad General Escobedo',
          label: 'Ciudad General Escobedo',
          condition: ['state', '=', ['Nuevo León']],
        },
        {
          value: 'Ciudad Santa Catarina',
          label: 'Ciudad Santa Catarina',
          condition: ['state', '=', ['Nuevo León']],
        },
        {
          value: 'San Pedro Garza García',
          label: 'San Pedro Garza García',
          condition: ['state', '=', ['Nuevo León']],
        },
        {
          value: 'San Nicolás de los Garza',
          label: 'San Nicolás de los Garza',
          condition: ['state', '=', ['Nuevo León']],
        },
        {
          value: 'Ciudad Apodaca',
          label: 'Ciudad Apodaca',
          condition: ['state', '=', ['Nuevo León']],
        },
        {
          value: 'Guadalupe',
          label: 'Guadalupe',
          condition: ['state', '=', ['Nuevo León', 'Zacatecas']],
        },
        {
          value: 'Ciudad Benito Juárez',
          label: 'Ciudad Benito Juárez',
          condition: ['state', '=', ['Nuevo León']],
        },
        {
          value: 'Santiago',
          label: 'Santiago',
          condition: ['state', '=', ['Nuevo León']],
        },
        {
          value: 'El cercado',
          label: 'El cercado',
          condition: ['state', '=', ['Nuevo León']],
        },
        {
          value: 'Cadereyta Jiménez',
          label: 'Cadereyta Jiménez',
          condition: ['state', '=', ['Nuevo León']],
        },
        {
          value: 'Montemorelos',
          label: 'Montemorelos',
          condition: ['state', '=', ['Nuevo León']],
        },
        {
          value: 'Linares',
          label: 'Linares',
          condition: ['state', '=', ['Nuevo León']],
        },
        {
          value: 'Hualahuises',
          label: 'Hualahuises',
          condition: ['state', '=', ['Nuevo León']],
        },
        {
          value: 'Doctor Arroyo',
          label: 'Doctor Arroyo',
          condition: ['state', '=', ['Nuevo León']],
        },
        {
          value: 'Oaxaca de Juárez',
          label: 'Oaxaca de Juárez',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'San Francisco Telixtlahuaca',
          label: 'San Francisco Telixtlahuaca',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'San Pablo Huitzo',
          label: 'San Pablo Huitzo',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Santiago Suchilquitongo',
          label: 'Santiago Suchilquitongo',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'San Juan Bautista Tuxtepec',
          label: 'San Juan Bautista Tuxtepec',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Loma Bonita',
          label: 'Loma Bonita',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Cosolapa',
          label: 'Cosolapa',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Vicente Camalote',
          label: 'Vicente Camalote',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'San Felipe Jalapa de Díaz',
          label: 'San Felipe Jalapa de Díaz',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'San Juan Bautista Valle Nacional',
          label: 'San Juan Bautista Valle Nacional',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Teotitlán de Flores Magón',
          label: 'Teotitlán de Flores Magón',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'San Juan Bautista Cuicatlán',
          label: 'San Juan Bautista Cuicatlán',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Natividad',
          label: 'Natividad',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Heroica Ciudad de Huajuapan de León',
          label: 'Heroica Ciudad de Huajuapan de León',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Mariscala de Juárez',
          label: 'Mariscala de Juárez',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Villa de Tamazulápam del Progreso',
          label: 'Villa de Tamazulápam del Progreso',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Asunción Nochixtlán',
          label: 'Asunción Nochixtlán',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Santiago Juxtlahuaca',
          label: 'Santiago Juxtlahuaca',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'San Sebastián Tecomaxtlahuaca',
          label: 'San Sebastián Tecomaxtlahuaca',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Heroica Ciudad de Tlaxiaco',
          label: 'Heroica Ciudad de Tlaxiaco',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Juchitán (Juchitán de Zaragoza)',
          label: 'Juchitán (Juchitán de Zaragoza)',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Ciudad Ixtepec',
          label: 'Ciudad Ixtepec',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Unión Hidalgo',
          label: 'Unión Hidalgo',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'San Francisco Ixhuatán',
          label: 'San Francisco Ixhuatán',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'San Pedro Tapanatepec',
          label: 'San Pedro Tapanatepec',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Chahuites',
          label: 'Chahuites',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Matías Romero Avendaño',
          label: 'Matías Romero Avendaño',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Lagunas',
          label: 'Lagunas',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Tlacolula de Matamoros',
          label: 'Tlacolula de Matamoros',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'San Pablo Villa de Mitla',
          label: 'San Pablo Villa de Mitla',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'San Pedro Totolápam',
          label: 'San Pedro Totolápam',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'El Camarón',
          label: 'El Camarón',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Salina Cruz',
          label: 'Salina Cruz',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Santo Domingo Tehuantepec',
          label: 'Santo Domingo Tehuantepec',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'San Blas Atempa',
          label: 'San Blas Atempa',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Miahuatlán de Porfirio Díaz',
          label: 'Miahuatlán de Porfirio Díaz',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'San Pedro Pochutla',
          label: 'San Pedro Pochutla',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Santa María Huatulco',
          label: 'Santa María Huatulco',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Bahias de Huatulco',
          label: 'Bahias de Huatulco',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Putla Villa de Guerrero',
          label: 'Putla Villa de Guerrero',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'San Miguel el Grande',
          label: 'San Miguel el Grande',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Santa Cruz Itundujia',
          label: 'Santa Cruz Itundujia',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Zimatlán de Álvarez',
          label: 'Zimatlán de Álvarez',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Santa Lucía del Camino',
          label: 'Santa Lucía del Camino',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'San Antonio de la Cal',
          label: 'San Antonio de la Cal',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Villa de Zaachila',
          label: 'Villa de Zaachila',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'El Rosario',
          label: 'El Rosario',
          condition: ['state', '=', ['Oaxaca', 'Sinaloa']],
        },
        {
          value: 'Cuilápam de Guerrero',
          label: 'Cuilápam de Guerrero',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Villa Sola de Vega',
          label: 'Villa Sola de Vega',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Heroica Ciudad de Ejutla de Crespo',
          label: 'Heroica Ciudad de Ejutla de Crespo',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Ocotlán de Morelos',
          label: 'Ocotlán de Morelos',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Santiago Pinotepa Nacional',
          label: 'Santiago Pinotepa Nacional',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'San Juan Cacahuatepec',
          label: 'San Juan Cacahuatepec',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'San Juan Bautista Lo de Soto',
          label: 'San Juan Bautista Lo de Soto',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Santiago Jamiltepec',
          label: 'Santiago Jamiltepec',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Río Grande o Piedra Parada',
          label: 'Río Grande o Piedra Parada',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Puerto Escondido',
          label: 'Puerto Escondido',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'San Pedro Mixtepec Distrito 22',
          label: 'San Pedro Mixtepec Distrito 22',
          condition: ['state', '=', ['Oaxaca']],
        },
        {
          value: 'Heroica Puebla de Zaragoza',
          label: 'Heroica Puebla de Zaragoza',
          condition: ['state', '=', ['Puebla']],
        },
        {
          value: 'Cuautlancingo',
          label: 'Cuautlancingo',
          condition: ['state', '=', ['Puebla']],
        },
        {
          value: 'San Pedro Cholula',
          label: 'San Pedro Cholula',
          condition: ['state', '=', ['Puebla']],
        },
        {
          value: 'San Andrés Cholula',
          label: 'San Andrés Cholula',
          condition: ['state', '=', ['Puebla']],
        },
        {
          value: 'Amozoc',
          label: 'Amozoc',
          condition: ['state', '=', ['Puebla']],
        },
        {
          value: 'Xicotepec',
          label: 'Xicotepec',
          condition: ['state', '=', ['Puebla']],
        },
        {
          value: 'Huauchinango',
          label: 'Huauchinango',
          condition: ['state', '=', ['Puebla']],
        },
        {
          value: 'Zacatlán',
          label: 'Zacatlán',
          condition: ['state', '=', ['Puebla']],
        },
        {
          value: 'Teziutlán',
          label: 'Teziutlán',
          condition: ['state', '=', ['Puebla']],
        },
        {
          value: 'San Martín Texmelucan de Labastida',
          label: 'San Martín Texmelucan de Labastida',
          condition: ['state', '=', ['Puebla']],
        },
        {
          value: 'Atlixco',
          label: 'Atlixco',
          condition: ['state', '=', ['Puebla']],
        },
        {
          value: 'Izúcar de Matamoros',
          label: 'Izúcar de Matamoros',
          condition: ['state', '=', ['Puebla']],
        },
        {
          value: 'Acatlán de Osorio',
          label: 'Acatlán de Osorio',
          condition: ['state', '=', ['Puebla']],
        },
        {
          value: 'Tepeaca',
          label: 'Tepeaca',
          condition: ['state', '=', ['Puebla']],
        },
        {
          value: 'Tecamachalco',
          label: 'Tecamachalco',
          condition: ['state', '=', ['Puebla']],
        },
        {
          value: 'Ciudad Serdán',
          label: 'Ciudad Serdán',
          condition: ['state', '=', ['Puebla']],
        },
        {
          value: 'Tehuacán',
          label: 'Tehuacán',
          condition: ['state', '=', ['Puebla']],
        },
        {
          value: 'Santiago de Querétaro',
          label: 'Santiago de Querétaro',
          condition: ['state', '=', ['Querétaro']],
        },
        {
          value: 'San Juan del Rio',
          label: 'San Juan del Rio',
          condition: ['state', '=', ['Querétaro']],
        },
        {
          value: 'El Pueblito',
          label: 'El Pueblito',
          condition: ['state', '=', ['Querétaro']],
        },
        {
          value: 'Chetumal',
          label: 'Chetumal',
          condition: ['state', '=', ['Quintana Roo']],
        },
        {
          value: 'Felipe Carrillo Puerto',
          label: 'Felipe Carrillo Puerto',
          condition: ['state', '=', ['Quintana Roo']],
        },
        {
          value: 'Kantunilkín',
          label: 'Kantunilkín',
          condition: ['state', '=', ['Quintana Roo']],
        },
        {
          value: 'Isla Mujeres',
          label: 'Isla Mujeres',
          condition: ['state', '=', ['Quintana Roo']],
        },
        {
          value: 'Cancún',
          label: 'Cancún',
          condition: ['state', '=', ['Quintana Roo']],
        },
        {
          value: 'Cozumel',
          label: 'Cozumel',
          condition: ['state', '=', ['Quintana Roo']],
        },
        {
          value: 'Playa del Carmen',
          label: 'Playa del Carmen',
          condition: ['state', '=', ['Quintana Roo']],
        },
        {
          value: 'Bacalar',
          label: 'Bacalar',
          condition: ['state', '=', ['Quintana Roo']],
        },
        {
          value: 'San Luis Potosí',
          label: 'San Luis Potosí',
          condition: ['state', '=', ['San Luis Potosí']],
        },
        {
          value: 'Soledad de Graciano Sánchez',
          label: 'Soledad de Graciano Sánchez',
          condition: ['state', '=', ['San Luis Potosí']],
        },
        {
          value: 'Cedral',
          label: 'Cedral',
          condition: ['state', '=', ['San Luis Potosí']],
        },
        {
          value: 'Charcas',
          label: 'Charcas',
          condition: ['state', '=', ['San Luis Potosí']],
        },
        {
          value: 'Salinas de Hidalgo',
          label: 'Salinas de Hidalgo',
          condition: ['state', '=', ['San Luis Potosí']],
        },
        {
          value: 'Matehuala',
          label: 'Matehuala',
          condition: ['state', '=', ['San Luis Potosí']],
        },
        {
          value: 'Ciudad Valles',
          label: 'Ciudad Valles',
          condition: ['state', '=', ['San Luis Potosí']],
        },
        {
          value: 'Ébano',
          label: 'Ébano',
          condition: ['state', '=', ['San Luis Potosí']],
        },
        {
          value: 'Tamuín',
          label: 'Tamuín',
          condition: ['state', '=', ['San Luis Potosí']],
        },
        {
          value: 'El Naranjo',
          label: 'El Naranjo',
          condition: ['state', '=', ['San Luis Potosí']],
        },
        {
          value: 'Ciudad del Maíz',
          label: 'Ciudad del Maíz',
          condition: ['state', '=', ['San Luis Potosí']],
        },
        {
          value: 'Cárdenas',
          label: 'Cárdenas',
          condition: ['state', '=', ['San Luis Potosí', 'Tabasco']],
        },
        {
          value: 'Cerritos',
          label: 'Cerritos',
          condition: ['state', '=', ['San Luis Potosí']],
        },
        {
          value: 'Villa de Reyes',
          label: 'Villa de Reyes',
          condition: ['state', '=', ['San Luis Potosí']],
        },
        {
          value: 'Santa María del Río',
          label: 'Santa María del Río',
          condition: ['state', '=', ['San Luis Potosí']],
        },
        {
          value: 'Tierra Nueva',
          label: 'Tierra Nueva',
          condition: ['state', '=', ['San Luis Potosí']],
        },
        {
          value: 'Rioverde',
          label: 'Rioverde',
          condition: ['state', '=', ['San Luis Potosí']],
        },
        {
          value: 'Fracción el Refugio',
          label: 'Fracción el Refugio',
          condition: ['state', '=', ['San Luis Potosí']],
        },
        {
          value: 'Tamasopo',
          label: 'Tamasopo',
          condition: ['state', '=', ['San Luis Potosí']],
        },
        {
          value: 'Tamazunchale',
          label: 'Tamazunchale',
          condition: ['state', '=', ['San Luis Potosí']],
        },
        {
          value: 'Culiacán Rosales',
          label: 'Culiacán Rosales',
          condition: ['state', '=', ['Sinaloa']],
        },
        {
          value: 'Navolato',
          label: 'Navolato',
          condition: ['state', '=', ['Sinaloa']],
        },
        {
          value: 'Licenciado Benito Juárez (Campo Gobierno)',
          label: 'Licenciado Benito Juárez (Campo Gobierno)',
          condition: ['state', '=', ['Sinaloa']],
        },
        {
          value: 'Quilá',
          label: 'Quilá',
          condition: ['state', '=', ['Sinaloa']],
        },
        {
          value: 'Cosalá',
          label: 'Cosalá',
          condition: ['state', '=', ['Sinaloa']],
        },
        {
          value: 'Mocorito',
          label: 'Mocorito',
          condition: ['state', '=', ['Sinaloa']],
        },
        {
          value: 'Guasave',
          label: 'Guasave',
          condition: ['state', '=', ['Sinaloa']],
        },
        {
          value: 'Los Mochis',
          label: 'Los Mochis',
          condition: ['state', '=', ['Sinaloa']],
        },
        {
          value: 'Ahome',
          label: 'Ahome',
          condition: ['state', '=', ['Sinaloa']],
        },
        {
          value: 'Higuera de Zaragoza',
          label: 'Higuera de Zaragoza',
          condition: ['state', '=', ['Sinaloa']],
        },
        {
          value: 'Topolobampo',
          label: 'Topolobampo',
          condition: ['state', '=', ['Sinaloa']],
        },
        {
          value: 'Guamúchil',
          label: 'Guamúchil',
          condition: ['state', '=', ['Sinaloa']],
        },
        {
          value: 'Angostura',
          label: 'Angostura',
          condition: ['state', '=', ['Sinaloa']],
        },
        {
          value: 'Choix',
          label: 'Choix',
          condition: ['state', '=', ['Sinaloa']],
        },
        {
          value: 'Sinaloa de Leyva',
          label: 'Sinaloa de Leyva',
          condition: ['state', '=', ['Sinaloa']],
        },
        {
          value: 'Estación Naranjo',
          label: 'Estación Naranjo',
          condition: ['state', '=', ['Sinaloa']],
        },
        {
          value: 'Mazatlán',
          label: 'Mazatlán',
          condition: ['state', '=', ['Sinaloa']],
        },
        {
          value: 'Villa Unión',
          label: 'Villa Unión',
          condition: ['state', '=', ['Sinaloa']],
        },
        {
          value: 'Escuinapa de Hidalgo',
          label: 'Escuinapa de Hidalgo',
          condition: ['state', '=', ['Sinaloa']],
        },
        {
          value: 'La Cruz',
          label: 'La Cruz',
          condition: ['state', '=', ['Sinaloa']],
        },
        {
          value: 'Hermosillo',
          label: 'Hermosillo',
          condition: ['state', '=', ['Sonora']],
        },
        {
          value: 'San Luis Río Colorado',
          label: 'San Luis Río Colorado',
          condition: ['state', '=', ['Sonora']],
        },
        {
          value: 'Puerto Peñasco',
          label: 'Puerto Peñasco',
          condition: ['state', '=', ['Sonora']],
        },
        {
          value: 'Sonoyta',
          label: 'Sonoyta',
          condition: ['state', '=', ['Sonora']],
        },
        {
          value: 'Heroica Caborca',
          label: 'Heroica Caborca',
          condition: ['state', '=', ['Sonora']],
        },
        {
          value: 'Heroica Nogales',
          label: 'Heroica Nogales',
          condition: ['state', '=', ['Sonora']],
        },
        {
          value: 'Magdalena de Kino',
          label: 'Magdalena de Kino',
          condition: ['state', '=', ['Sonora']],
        },
        {
          value: 'Agua Prieta',
          label: 'Agua Prieta',
          condition: ['state', '=', ['Sonora']],
        },
        {
          value: 'Heroica Ciudad de Cananea',
          label: 'Heroica Ciudad de Cananea',
          condition: ['state', '=', ['Sonora']],
        },
        {
          value: 'Ciudad Obregón',
          label: 'Ciudad Obregón',
          condition: ['state', '=', ['Sonora']],
        },
        {
          value: 'Empalme',
          label: 'Empalme',
          condition: ['state', '=', ['Sonora']],
        },
        {
          value: 'Heroica Guaymas',
          label: 'Heroica Guaymas',
          condition: ['state', '=', ['Sonora']],
        },
        {
          value: 'Navojoa',
          label: 'Navojoa',
          condition: ['state', '=', ['Sonora']],
        },
        {
          value: 'Huatabampo',
          label: 'Huatabampo',
          condition: ['state', '=', ['Sonora']],
        },
        {
          value: 'Villahermosa',
          label: 'Villahermosa',
          condition: ['state', '=', ['Tabasco']],
        },
        {
          value: 'Jalpa de Méndez',
          label: 'Jalpa de Méndez',
          condition: ['state', '=', ['Tabasco']],
        },
        {
          value: 'Comalcalco',
          label: 'Comalcalco',
          condition: ['state', '=', ['Tabasco']],
        },
        {
          value: 'Huimanguillo',
          label: 'Huimanguillo',
          condition: ['state', '=', ['Tabasco']],
        },
        {
          value: 'Paraíso',
          label: 'Paraíso',
          condition: ['state', '=', ['Tabasco']],
        },
        {
          value: 'Cunduacán',
          label: 'Cunduacán',
          condition: ['state', '=', ['Tabasco']],
        },
        {
          value: 'Macuspana',
          label: 'Macuspana',
          condition: ['state', '=', ['Tabasco']],
        },
        {
          value: 'Teapa',
          label: 'Teapa',
          condition: ['state', '=', ['Tabasco']],
        },
        {
          value: 'Tenosique de Pino Suárez',
          label: 'Tenosique de Pino Suárez',
          condition: ['state', '=', ['Tabasco']],
        },
        {
          value: 'Emiliano Zapata',
          label: 'Emiliano Zapata',
          condition: ['state', '=', ['Tabasco']],
        },
        {
          value: 'Ciudad Victoria',
          label: 'Ciudad Victoria',
          condition: ['state', '=', ['Tamaulipas']],
        },
        {
          value: 'Heroica Matamoros',
          label: 'Heroica Matamoros',
          condition: ['state', '=', ['Tamaulipas']],
        },
        {
          value: 'Valle Hermoso',
          label: 'Valle Hermoso',
          condition: ['state', '=', ['Tamaulipas']],
        },
        {
          value: 'San Fernando',
          label: 'San Fernando',
          condition: ['state', '=', ['Tamaulipas']],
        },
        {
          value: 'Soto la Marina',
          label: 'Soto la Marina',
          condition: ['state', '=', ['Tamaulipas']],
        },
        {
          value: 'Ciudad Tula',
          label: 'Ciudad Tula',
          condition: ['state', '=', ['Tamaulipas']],
        },
        {
          value: 'Jaumave',
          label: 'Jaumave',
          condition: ['state', '=', ['Tamaulipas']],
        },
        {
          value: 'Nuevo Laredo',
          label: 'Nuevo Laredo',
          condition: ['state', '=', ['Tamaulipas']],
        },
        {
          value: 'Ciudad Miguel Alemán',
          label: 'Ciudad Miguel Alemán',
          condition: ['state', '=', ['Tamaulipas']],
        },
        {
          value: 'Nueva Ciudad Guerrero',
          label: 'Nueva Ciudad Guerrero',
          condition: ['state', '=', ['Tamaulipas']],
        },
        {
          value: 'Ciudad Gustavo Díaz Ordaz',
          label: 'Ciudad Gustavo Díaz Ordaz',
          condition: ['state', '=', ['Tamaulipas']],
        },
        {
          value: 'Ciudad Camargo',
          label: 'Ciudad Camargo',
          condition: ['state', '=', ['Tamaulipas']],
        },
        {
          value: 'Reynosa',
          label: 'Reynosa',
          condition: ['state', '=', ['Tamaulipas']],
        },
        {
          value: 'Ciudad Río Bravo',
          label: 'Ciudad Río Bravo',
          condition: ['state', '=', ['Tamaulipas']],
        },
        {
          value: 'Tampico',
          label: 'Tampico',
          condition: ['state', '=', ['Tamaulipas']],
        },
        {
          value: 'Ciudad Madero',
          label: 'Ciudad Madero',
          condition: ['state', '=', ['Tamaulipas']],
        },
        {
          value: 'Altamira',
          label: 'Altamira',
          condition: ['state', '=', ['Tamaulipas']],
        },
        {
          value: 'González',
          label: 'González',
          condition: ['state', '=', ['Tamaulipas']],
        },
        {
          value: 'Estación Manuel (Úrsulo Galván)',
          label: 'Estación Manuel (Úrsulo Galván)',
          condition: ['state', '=', ['Tamaulipas']],
        },
        {
          value: 'Xicoténcatl',
          label: 'Xicoténcatl',
          condition: ['state', '=', ['Tamaulipas']],
        },
        {
          value: 'Ciudad Mante',
          label: 'Ciudad Mante',
          condition: ['state', '=', ['Tamaulipas']],
        },
        {
          value: 'Tlaxcala de Xicohténcatl',
          label: 'Tlaxcala de Xicohténcatl',
          condition: ['state', '=', ['Tlaxcala']],
        },
        {
          value: 'Calpulalpan',
          label: 'Calpulalpan',
          condition: ['state', '=', ['Tlaxcala']],
        },
        {
          value: 'Apizaco',
          label: 'Apizaco',
          condition: ['state', '=', ['Tlaxcala']],
        },
        {
          value: 'Huamantla',
          label: 'Huamantla',
          condition: ['state', '=', ['Tlaxcala']],
        },
        {
          value: 'Chiautempan',
          label: 'Chiautempan',
          condition: ['state', '=', ['Tlaxcala']],
        },
        {
          value: 'Villa Vicente Guerrero',
          label: 'Villa Vicente Guerrero',
          condition: ['state', '=', ['Tlaxcala']],
        },
        {
          value: 'Xalapa-Enríquez',
          label: 'Xalapa-Enríquez',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Perote',
          label: 'Perote',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Banderilla',
          label: 'Banderilla',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Coatepec',
          label: 'Coatepec',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Paso de Ovejas',
          label: 'Paso de Ovejas',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'José Cardel',
          label: 'José Cardel',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Veracruz',
          label: 'Veracruz',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Tampico Alto',
          label: 'Tampico Alto',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Tempoal de Sánchez',
          label: 'Tempoal de Sánchez',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Tantoyuca',
          label: 'Tantoyuca',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Platón Sánchez',
          label: 'Platón Sánchez',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Naranjos',
          label: 'Naranjos',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'El Higo',
          label: 'El Higo',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Cerro Azul',
          label: 'Cerro Azul',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Huayacocotla',
          label: 'Huayacocotla',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Álamo',
          label: 'Álamo',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Túxpam de Rodríguez Cano',
          label: 'Túxpam de Rodríguez Cano',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Tihuatlán',
          label: 'Tihuatlán',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Cazones de Herrera',
          label: 'Cazones de Herrera',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Coatzintla',
          label: 'Coatzintla',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Poza Rica de Hidalgo',
          label: 'Poza Rica de Hidalgo',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Papantla de Olarte',
          label: 'Papantla de Olarte',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Gutiérrez Zamora',
          label: 'Gutiérrez Zamora',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Martínez de la Torre',
          label: 'Martínez de la Torre',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'San Rafael',
          label: 'San Rafael',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Tlapacoyan',
          label: 'Tlapacoyan',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Altotonga',
          label: 'Altotonga',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Yecuatla',
          label: 'Yecuatla',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Pánuco',
          label: 'Pánuco',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Huatusco de Chicuellar',
          label: 'Huatusco de Chicuellar',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Soledad de Doblado',
          label: 'Soledad de Doblado',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Boca del Río',
          label: 'Boca del Río',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Orizaba',
          label: 'Orizaba',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Ixtaczoquitlán',
          label: 'Ixtaczoquitlán',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Fortín de las Flores',
          label: 'Fortín de las Flores',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Córdoba',
          label: 'Córdoba',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Nogales',
          label: 'Nogales',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Río Blanco',
          label: 'Río Blanco',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Huiloapan de Cuauhtémoc',
          label: 'Huiloapan de Cuauhtémoc',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Cuitláhuac',
          label: 'Cuitláhuac',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Paraje Nuevo',
          label: 'Paraje Nuevo',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Atoyac',
          label: 'Atoyac',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'General Miguel Alemán (Potrero Nuevo)',
          label: 'General Miguel Alemán (Potrero Nuevo)',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Paso del Macho',
          label: 'Paso del Macho',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Tezonapa',
          label: 'Tezonapa',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Tierra Blanca',
          label: 'Tierra Blanca',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Alvarado',
          label: 'Alvarado',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Lerdo de Tejada',
          label: 'Lerdo de Tejada',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Tres Valles',
          label: 'Tres Valles',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Carlos A. Carrillo',
          label: 'Carlos A. Carrillo',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Cosamaloapan',
          label: 'Cosamaloapan',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Tlacojalpan',
          label: 'Tlacojalpan',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Playa Vicente',
          label: 'Playa Vicente',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Isla',
          label: 'Isla',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Juan Rodríguez Clara',
          label: 'Juan Rodríguez Clara',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'San Andrés Tuxtla',
          label: 'San Andrés Tuxtla',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Sihuapan',
          label: 'Sihuapan',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Santiago Tuxtla',
          label: 'Santiago Tuxtla',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Ángel R. Cabada',
          label: 'Ángel R. Cabada',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Juan Díaz Covarrubias',
          label: 'Juan Díaz Covarrubias',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Catemaco',
          label: 'Catemaco',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Acayucan',
          label: 'Acayucan',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Jáltipan de Morelos',
          label: 'Jáltipan de Morelos',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Cosoleacaque',
          label: 'Cosoleacaque',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Cuichapa',
          label: 'Cuichapa',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Coatzacoalcos',
          label: 'Coatzacoalcos',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Agua Dulce',
          label: 'Agua Dulce',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Minatitlán',
          label: 'Minatitlán',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Las Choapas',
          label: 'Las Choapas',
          condition: ['state', '=', ['Veracruz de Ignacio de la Llave']],
        },
        {
          value: 'Mérida',
          label: 'Mérida',
          condition: ['state', '=', ['Yucatán']],
        },
        {
          value: 'Kanasín',
          label: 'Kanasín',
          condition: ['state', '=', ['Yucatán']],
        },
        {
          value: 'Motul de Carrillo Puerto',
          label: 'Motul de Carrillo Puerto',
          condition: ['state', '=', ['Yucatán']],
        },
        {
          value: 'Tizimín',
          label: 'Tizimín',
          condition: ['state', '=', ['Yucatán']],
        },
        {
          value: 'Valladolid',
          label: 'Valladolid',
          condition: ['state', '=', ['Yucatán']],
        },
        {
          value: 'Ticul',
          label: 'Ticul',
          condition: ['state', '=', ['Yucatán']],
        },
        {
          value: 'Zacatecas',
          label: 'Zacatecas',
          condition: ['state', '=', ['Zacatecas']],
        },
        {
          value: 'Río Grande',
          label: 'Río Grande',
          condition: ['state', '=', ['Zacatecas']],
        },
        {
          value: 'Villa de Cos',
          label: 'Villa de Cos',
          condition: ['state', '=', ['Zacatecas']],
        },
        {
          value: 'Víctor Rosales',
          label: 'Víctor Rosales',
          condition: ['state', '=', ['Zacatecas']],
        },
        {
          value: 'Ciudad Cuauhtémoc',
          label: 'Ciudad Cuauhtémoc',
          condition: ['state', '=', ['Zacatecas']],
        },
        {
          value: 'Ojocaliente',
          label: 'Ojocaliente',
          condition: ['state', '=', ['Zacatecas']],
        },
        {
          value: 'Luis Moya',
          label: 'Luis Moya',
          condition: ['state', '=', ['Zacatecas']],
        },
        {
          value: 'Fresnillo',
          label: 'Fresnillo',
          condition: ['state', '=', ['Zacatecas']],
        },
        {
          value: 'Sombrerete',
          label: 'Sombrerete',
          condition: ['state', '=', ['Zacatecas']],
        },
        {
          value: 'Valparaíso',
          label: 'Valparaíso',
          condition: ['state', '=', ['Zacatecas']],
        },
        {
          value: 'Jerez de García Salinas',
          label: 'Jerez de García Salinas',
          condition: ['state', '=', ['Zacatecas']],
        },
        {
          value: 'Villanueva',
          label: 'Villanueva',
          condition: ['state', '=', ['Zacatecas']],
        },
        {
          value: 'Jalpa',
          label: 'Jalpa',
          condition: ['state', '=', ['Zacatecas']],
        },
        {
          value: 'Nochistlán de Mejía',
          label: 'Nochistlán de Mejía',
          condition: ['state', '=', ['Zacatecas']],
        },
        {
          value: 'Moyahua de Estrada',
          label: 'Moyahua de Estrada',
          condition: ['state', '=', ['Zacatecas']],
        },
      ],
    },
  ],
  [
    {
      type: 'selector',
      label: 'Municipio*',
      field_name: 'municipality',
      options: [
        {
          value: 'Aguascalientes',
          label: 'Aguascalientes',
          condition: ['city', '=', ['Aguascalientes']],
        },
        {
          value: 'San Francisco de los Romo',
          label: 'San Francisco de los Romo',
          condition: ['city', '=', ['San Francisco de los Romo']],
        },
        {
          value: 'Rincón de Romos',
          label: 'Rincón de Romos',
          condition: ['city', '=', ['Rincón de Romos']],
        },
        {
          value: 'Cosío',
          label: 'Cosío',
          condition: ['city', '=', ['Cosío']],
        },
        {
          value: 'Tepezalá',
          label: 'Tepezalá',
          condition: ['city', '=', ['Tepezalá']],
        },
        {
          value: 'Pabellón de Arteaga',
          label: 'Pabellón de Arteaga',
          condition: ['city', '=', ['Pabellón de Arteaga']],
        },
        {
          value: 'Asientos',
          label: 'Asientos',
          condition: ['city', '=', ['Asientos']],
        },
        {
          value: 'Calvillo',
          label: 'Calvillo',
          condition: ['city', '=', ['Calvillo']],
        },
        {
          value: 'Jesús María',
          label: 'Jesús María',
          condition: ['city', '=', ['Jesús María']],
        },
        {
          value: 'Mexicali',
          label: 'Mexicali',
          condition: ['city', '=', ['Mexicali']],
        },
        {
          value: 'Tecate',
          label: 'Tecate',
          condition: ['city', '=', ['Tecate']],
        },
        {
          value: 'San Felipe',
          label: 'San Felipe',
          condition: ['city', '=', ['San Felipe']],
        },
        {
          value: 'Tijuana',
          label: 'Tijuana',
          condition: ['city', '=', ['Tijuana']],
        },
        {
          value: 'Playas de Rosarito',
          label: 'Playas de Rosarito',
          condition: ['city', '=', ['Playas de Rosarito']],
        },
        {
          value: 'Ensenada',
          label: 'Ensenada',
          condition: [
            'city',
            '=',
            ['Rodolfo Sánchez Taboada (Maneadero)', 'Ensenada'],
          ],
        },
        {
          value: 'La Paz',
          label: 'La Paz',
          condition: [
            'city',
            '=',
            ['La Paz', 'Todos Santos', 'Los Reyes Acaquilpan (La Paz)'],
          ],
        },
        {
          value: 'Los Cabos',
          label: 'Los Cabos',
          condition: ['city', '=', ['San José del Cabo', 'Cabo San Lucas']],
        },
        {
          value: 'Comondú',
          label: 'Comondú',
          condition: [
            'city',
            '=',
            ['Ciudad Constitución', 'Puerto Adolfo López Mateos'],
          ],
        },
        {
          value: 'Loreto',
          label: 'Loreto',
          condition: ['city', '=', ['Loreto']],
        },
        {
          value: 'Mulegé',
          label: 'Mulegé',
          condition: [
            'city',
            '=',
            [
              'Heroica Mulegé',
              'Santa Rosalía',
              'San Ignacio',
              'Villa Alberto Andrés Alvarado Arámburo',
              'Guerrero Negro',
            ],
          ],
        },
        {
          value: 'Campeche',
          label: 'Campeche',
          condition: ['city', '=', ['San Francisco de Campeche']],
        },
        {
          value: 'Carmen',
          label: 'Carmen',
          condition: ['city', '=', ['Ciudad del Carmen', 'Sabancuy']],
        },
        {
          value: 'Candelaria',
          label: 'Candelaria',
          condition: ['city', '=', ['Candelaria']],
        },
        {
          value: 'Escárcega',
          label: 'Escárcega',
          condition: ['city', '=', ['Escárcega']],
        },
        {
          value: 'Champotón',
          label: 'Champotón',
          condition: ['city', '=', ['Champotón']],
        },
        {
          value: 'Hopelchén',
          label: 'Hopelchén',
          condition: ['city', '=', ['Hopelchén']],
        },
        {
          value: 'Tenabo',
          label: 'Tenabo',
          condition: ['city', '=', ['Tenabo']],
        },
        {
          value: 'Hecelchakán',
          label: 'Hecelchakán',
          condition: ['city', '=', ['Hecelchakán', 'Pomuch']],
        },
        {
          value: 'Calkiní',
          label: 'Calkiní',
          condition: ['city', '=', ['Calkini', 'Bécal']],
        },
        {
          value: 'Saltillo',
          label: 'Saltillo',
          condition: ['city', '=', ['Saltillo']],
        },
        {
          value: 'Arteaga',
          label: 'Arteaga',
          condition: ['city', '=', ['Arteaga']],
        },
        {
          value: 'San Buenaventura',
          label: 'San Buenaventura',
          condition: ['city', '=', ['San Buenaventura']],
        },
        {
          value: 'Frontera',
          label: 'Frontera',
          condition: ['city', '=', ['Frontera']],
        },
        {
          value: 'Monclova',
          label: 'Monclova',
          condition: ['city', '=', ['Monclova']],
        },
        {
          value: 'Castaños',
          label: 'Castaños',
          condition: ['city', '=', ['Castaños']],
        },
        {
          value: 'Ramos Arizpe',
          label: 'Ramos Arizpe',
          condition: ['city', '=', ['Ramos Arizpe']],
        },
        {
          value: 'Piedras Negras',
          label: 'Piedras Negras',
          condition: ['city', '=', ['Piedras Negras']],
        },
        {
          value: 'Nava',
          label: 'Nava',
          condition: ['city', '=', ['Nava']],
        },
        {
          value: 'Acuña',
          label: 'Acuña',
          condition: ['city', '=', ['Ciudad Acuña']],
        },
        {
          value: 'Múzquiz',
          label: 'Múzquiz',
          condition: ['city', '=', ['Ciudad Melchor Múzquiz']],
        },
        {
          value: 'Zaragoza',
          label: 'Zaragoza',
          condition: ['city', '=', ['Zaragoza']],
        },
        {
          value: 'Morelos',
          label: 'Morelos',
          condition: ['city', '=', ['Morelos']],
        },
        {
          value: 'Allende',
          label: 'Allende',
          condition: ['city', '=', ['Allende']],
        },
        {
          value: 'Sabinas',
          label: 'Sabinas',
          condition: ['city', '=', ['Sabinas']],
        },
        {
          value: 'San Juan de Sabinas',
          label: 'San Juan de Sabinas',
          condition: ['city', '=', ['Nueva Rosita']],
        },
        {
          value: 'Torreón',
          label: 'Torreón',
          condition: ['city', '=', ['Torreón']],
        },
        {
          value: 'Matamoros',
          label: 'Matamoros',
          condition: ['city', '=', ['Matamoros', 'Heroica Matamoros']],
        },
        {
          value: 'Viesca',
          label: 'Viesca',
          condition: ['city', '=', ['Viesca']],
        },
        {
          value: 'Nadadores',
          label: 'Nadadores',
          condition: ['city', '=', ['Nadadores']],
        },
        {
          value: 'Cuatro Ciénegas',
          label: 'Cuatro Ciénegas',
          condition: ['city', '=', ['Cuatro Ciénegas de Carranza']],
        },
        {
          value: 'San Pedro',
          label: 'San Pedro',
          condition: ['city', '=', ['San Pedro']],
        },
        {
          value: 'Francisco I. Madero',
          label: 'Francisco I. Madero',
          condition: ['city', '=', ['Francisco I. Madero (Chávez)']],
        },
        {
          value: 'Parras',
          label: 'Parras',
          condition: ['city', '=', ['Parras de la Fuente']],
        },
        {
          value: 'Colima',
          label: 'Colima',
          condition: ['city', '=', ['Colima']],
        },
        {
          value: 'Tecomán',
          label: 'Tecomán',
          condition: ['city', '=', ['Tecomán']],
        },
        {
          value: 'Manzanillo',
          label: 'Manzanillo',
          condition: ['city', '=', ['Manzanillo']],
        },
        {
          value: 'Armería',
          label: 'Armería',
          condition: ['city', '=', ['Ciudad de Armería']],
        },
        {
          value: 'Villa de Álvarez',
          label: 'Villa de Álvarez',
          condition: ['city', '=', ['Ciudad de Villa de Álvarez']],
        },
        {
          value: 'Tuxtla Gutiérrez',
          label: 'Tuxtla Gutiérrez',
          condition: ['city', '=', ['Tuxtla Gutiérrez']],
        },
        {
          value: 'Ocozocoautla de Espinosa',
          label: 'Ocozocoautla de Espinosa',
          condition: ['city', '=', ['Ocozocoautla de Espinosa']],
        },
        {
          value: 'Chiapa de Corzo',
          label: 'Chiapa de Corzo',
          condition: ['city', '=', ['Chiapa de Corzo']],
        },
        {
          value: 'San Cristóbal de las Casas',
          label: 'San Cristóbal de las Casas',
          condition: ['city', '=', ['San Cristóbal de las Casas']],
        },
        {
          value: 'Acala',
          label: 'Acala',
          condition: ['city', '=', ['Acala']],
        },
        {
          value: 'Reforma',
          label: 'Reforma',
          condition: ['city', '=', ['Reforma']],
        },
        {
          value: 'Pichucalco',
          label: 'Pichucalco',
          condition: ['city', '=', ['Pichucalco']],
        },
        {
          value: 'Ocosingo',
          label: 'Ocosingo',
          condition: ['city', '=', ['Ocosingo']],
        },
        {
          value: 'Palenque',
          label: 'Palenque',
          condition: ['city', '=', ['Palenque']],
        },
        {
          value: 'Comitán de Domínguez',
          label: 'Comitán de Domínguez',
          condition: ['city', '=', ['Comitán de Domínguez']],
        },
        {
          value: 'Las Margaritas',
          label: 'Las Margaritas',
          condition: ['city', '=', ['Las Margaritas']],
        },
        {
          value: 'Venustiano Carranza',
          label: 'Venustiano Carranza',
          condition: ['city', '=', ['Venustiano Carranza', 'Ciudad de México']],
        },
        {
          value: 'Las Rosas',
          label: 'Las Rosas',
          condition: ['city', '=', ['Las Rosas']],
        },
        {
          value: 'Cintalapa de Figueroa',
          label: 'Cintalapa de Figueroa',
          condition: ['city', '=', ['Cintalapa de Figueroa']],
        },
        {
          value: 'Jiquipilas',
          label: 'Jiquipilas',
          condition: ['city', '=', ['Jiquipilas']],
        },
        {
          value: 'Arriaga',
          label: 'Arriaga',
          condition: ['city', '=', ['Arriaga']],
        },
        {
          value: 'Villaflores',
          label: 'Villaflores',
          condition: ['city', '=', ['Villaflores']],
        },
        {
          value: 'Tonalá',
          label: 'Tonalá',
          condition: ['city', '=', ['Tonalá']],
        },
        {
          value: 'Pijijiapan',
          label: 'Pijijiapan',
          condition: ['city', '=', ['Pijijiapan']],
        },
        {
          value: 'Mapastepec',
          label: 'Mapastepec',
          condition: ['city', '=', ['Mapastepec']],
        },
        {
          value: 'Huixtla',
          label: 'Huixtla',
          condition: ['city', '=', ['Huixtla']],
        },
        {
          value: 'Tapachula',
          label: 'Tapachula',
          condition: [
            'city',
            '=',
            ['Tapachula de Córdova y Ordóñez', 'Puerto Madero (San Benito)'],
          ],
        },
        {
          value: 'Cacahoatán',
          label: 'Cacahoatán',
          condition: ['city', '=', ['Cacahoatán']],
        },
        {
          value: 'Motozintla',
          label: 'Motozintla',
          condition: ['city', '=', ['Motozintla de Mendoza']],
        },
        {
          value: 'Chihuahua',
          label: 'Chihuahua',
          condition: ['city', '=', ['Chihuahua']],
        },
        {
          value: 'Cuauhtémoc',
          label: 'Cuauhtémoc',
          condition: [
            'city',
            '=',
            [
              'Cuauhtémoc',
              'Colonia Anáhuac',
              'Ciudad de México',
              'Ciudad Cuauhtémoc',
            ],
          ],
        },
        {
          value: 'Bachíniva',
          label: 'Bachíniva',
          condition: ['city', '=', ['Bachíniva']],
        },
        {
          value: 'Nuevo Casas Grandes',
          label: 'Nuevo Casas Grandes',
          condition: ['city', '=', ['Nuevo Casas Grandes']],
        },
        {
          value: 'Madera',
          label: 'Madera',
          condition: ['city', '=', ['Madera']],
        },
        {
          value: 'Juárez',
          label: 'Juárez',
          condition: ['city', '=', ['Juárez', 'Ciudad Benito Juárez']],
        },
        {
          value: 'Ojinaga',
          label: 'Ojinaga',
          condition: ['city', '=', ['Manuel Ojinaga']],
        },
        {
          value: 'Aldama',
          label: 'Aldama',
          condition: ['city', '=', ['Juan Aldama']],
        },
        {
          value: 'Delicias',
          label: 'Delicias',
          condition: ['city', '=', ['Delicias']],
        },
        {
          value: 'Saucillo',
          label: 'Saucillo',
          condition: ['city', '=', ['Saucillo']],
        },
        {
          value: 'Camargo',
          label: 'Camargo',
          condition: [
            'city',
            '=',
            ['Santa Rosalía de Camargo', 'Ciudad Camargo'],
          ],
        },
        {
          value: 'Hidalgo del Parral',
          label: 'Hidalgo del Parral',
          condition: ['city', '=', ['Hidalgo del Parral']],
        },
        {
          value: 'Jiménez',
          label: 'Jiménez',
          condition: ['city', '=', ['José Mariano Jiménez']],
        },
        {
          value: 'Álvaro Obregón',
          label: 'Álvaro Obregón',
          condition: ['city', '=', ['Ciudad de México']],
        },
        {
          value: 'Azcapotzalco',
          label: 'Azcapotzalco',
          condition: ['city', '=', ['Ciudad de México']],
        },
        {
          value: 'Benito Juárez',
          label: 'Benito Juárez',
          condition: [
            'city',
            '=',
            ['Ciudad de México', 'San Jerónimo de Juárez', 'Cancún'],
          ],
        },
        {
          value: 'Coyoacán',
          label: 'Coyoacán',
          condition: ['city', '=', ['Ciudad de México']],
        },
        {
          value: 'Cuajimalpa de Morelos',
          label: 'Cuajimalpa de Morelos',
          condition: ['city', '=', ['Ciudad de México']],
        },
        {
          value: 'Gustavo A. Madero',
          label: 'Gustavo A. Madero',
          condition: ['city', '=', ['Ciudad de México']],
        },
        {
          value: 'Iztacalco',
          label: 'Iztacalco',
          condition: ['city', '=', ['Ciudad de México']],
        },
        {
          value: 'Iztapalapa',
          label: 'Iztapalapa',
          condition: ['city', '=', ['Ciudad de México']],
        },
        {
          value: 'La Magdalena Contreras',
          label: 'La Magdalena Contreras',
          condition: ['city', '=', ['Ciudad de México']],
        },
        {
          value: 'Miguel Hidalgo',
          label: 'Miguel Hidalgo',
          condition: ['city', '=', ['Ciudad de México']],
        },
        {
          value: 'Milpa Alta',
          label: 'Milpa Alta',
          condition: ['city', '=', ['Ciudad de México']],
        },
        {
          value: 'Tláhuac',
          label: 'Tláhuac',
          condition: ['city', '=', ['Ciudad de México']],
        },
        {
          value: 'Tlalpan',
          label: 'Tlalpan',
          condition: ['city', '=', ['Ciudad de México']],
        },
        {
          value: 'Xochimilco',
          label: 'Xochimilco',
          condition: ['city', '=', ['Ciudad de México']],
        },
        {
          value: 'Durango',
          label: 'Durango',
          condition: ['city', '=', ['Victoria de Durango']],
        },
        {
          value: 'Canatlán',
          label: 'Canatlán',
          condition: ['city', '=', ['Canatlán']],
        },
        {
          value: 'San Juan del Río',
          label: 'San Juan del Río',
          condition: [
            'city',
            '=',
            ['San Juan del Río del Centauro del Norte', 'San Juan del Rio'],
          ],
        },
        {
          value: 'Santiago Papasquiaro',
          label: 'Santiago Papasquiaro',
          condition: ['city', '=', ['Santiago Papasquiaro']],
        },
        {
          value: 'Peñón Blanco',
          label: 'Peñón Blanco',
          condition: ['city', '=', ['Peñón Blanco']],
        },
        {
          value: 'Pánuco de Coronado',
          label: 'Pánuco de Coronado',
          condition: ['city', '=', ['Francisco I. Madero']],
        },
        {
          value: 'Nombre de Dios',
          label: 'Nombre de Dios',
          condition: ['city', '=', ['Nombre de Dios']],
        },
        {
          value: 'Vicente Guerrero',
          label: 'Vicente Guerrero',
          condition: ['city', '=', ['Vicente Guerrero']],
        },
        {
          value: 'Pueblo Nuevo',
          label: 'Pueblo Nuevo',
          condition: ['city', '=', ['El Salto']],
        },
        {
          value: 'Gómez Palacio',
          label: 'Gómez Palacio',
          condition: ['city', '=', ['Gómez Palacio']],
        },
        {
          value: 'Lerdo',
          label: 'Lerdo',
          condition: ['city', '=', ['Ciudad Lerdo']],
        },
        {
          value: 'El Oro',
          label: 'El Oro',
          condition: ['city', '=', ['Santa María del Oro']],
        },
        {
          value: 'Guanajuato',
          label: 'Guanajuato',
          condition: ['city', '=', ['Guanajuato', 'Marfil']],
        },
        {
          value: 'Silao de la Victoria',
          label: 'Silao de la Victoria',
          condition: ['city', '=', ['Silao']],
        },
        {
          value: 'Romita',
          label: 'Romita',
          condition: ['city', '=', ['Romita']],
        },
        {
          value: 'San Francisco del Rincón',
          label: 'San Francisco del Rincón',
          condition: ['city', '=', ['San Francisco del Rincón']],
        },
        {
          value: 'Purísima del Rincón',
          label: 'Purísima del Rincón',
          condition: ['city', '=', ['Purísima de Bustos']],
        },
        {
          value: 'Manuel Doblado',
          label: 'Manuel Doblado',
          condition: ['city', '=', ['Ciudad Manuel Doblado']],
        },
        {
          value: 'Irapuato',
          label: 'Irapuato',
          condition: ['city', '=', ['Irapuato']],
        },
        {
          value: 'Salamanca',
          label: 'Salamanca',
          condition: ['city', '=', ['Salamanca']],
        },
        {
          value: 'Pénjamo',
          label: 'Pénjamo',
          condition: ['city', '=', ['Pénjamo']],
        },
        {
          value: 'Cuerámaro',
          label: 'Cuerámaro',
          condition: ['city', '=', ['Cuerámaro']],
        },
        {
          value: 'Abasolo',
          label: 'Abasolo',
          condition: ['city', '=', ['Abasolo']],
        },
        {
          value: 'Huanímaro',
          label: 'Huanímaro',
          condition: ['city', '=', ['Huanímaro']],
        },
        {
          value: 'León',
          label: 'León',
          condition: ['city', '=', ['León de los Aldama']],
        },
        {
          value: 'San Miguel de Allende',
          label: 'San Miguel de Allende',
          condition: ['city', '=', ['San Miguel de Allende']],
        },
        {
          value: 'Dolores Hidalgo Cuna de la Independencia Nacional',
          label: 'Dolores Hidalgo Cuna de la Independencia Nacional',
          condition: [
            'city',
            '=',
            ['Dolores Hidalgo Cuna de la Independencia Nacional'],
          ],
        },
        {
          value: 'San Diego de la Unión',
          label: 'San Diego de la Unión',
          condition: ['city', '=', ['San Diego de la Unión']],
        },
        {
          value: 'San Luis de la Paz',
          label: 'San Luis de la Paz',
          condition: ['city', '=', ['San Luis de la Paz']],
        },
        {
          value: 'Doctor Mora',
          label: 'Doctor Mora',
          condition: ['city', '=', ['Doctor Mora']],
        },
        {
          value: 'San José Iturbide',
          label: 'San José Iturbide',
          condition: ['city', '=', ['San José Iturbide']],
        },
        {
          value: 'Celaya',
          label: 'Celaya',
          condition: ['city', '=', ['Celaya', 'Rincón de Tamayo']],
        },
        {
          value: 'Apaseo el Grande',
          label: 'Apaseo el Grande',
          condition: ['city', '=', ['Apaseo el Grande']],
        },
        {
          value: 'Comonfort',
          label: 'Comonfort',
          condition: ['city', '=', ['Comonfort', 'Empalme Escobedo']],
        },
        {
          value: 'Santa Cruz de Juventino Rosas',
          label: 'Santa Cruz de Juventino Rosas',
          condition: ['city', '=', ['Santa Cruz Juventino Rosas']],
        },
        {
          value: 'Villagrán',
          label: 'Villagrán',
          condition: ['city', '=', ['Villagrán']],
        },
        {
          value: 'Cortazar',
          label: 'Cortazar',
          condition: ['city', '=', ['Cortazar']],
        },
        {
          value: 'Valle de Santiago',
          label: 'Valle de Santiago',
          condition: ['city', '=', ['Valle de Santiago']],
        },
        {
          value: 'Jaral del Progreso',
          label: 'Jaral del Progreso',
          condition: ['city', '=', ['Jaral del Progreso']],
        },
        {
          value: 'Apaseo el Alto',
          label: 'Apaseo el Alto',
          condition: ['city', '=', ['Apaseo el Alto']],
        },
        {
          value: 'Jerécuaro',
          label: 'Jerécuaro',
          condition: ['city', '=', ['Jerécuaro']],
        },
        {
          value: 'Acámbaro',
          label: 'Acámbaro',
          condition: ['city', '=', ['Acámbaro']],
        },
        {
          value: 'Tarandacuao',
          label: 'Tarandacuao',
          condition: ['city', '=', ['Tarandacuao']],
        },
        {
          value: 'Moroleón',
          label: 'Moroleón',
          condition: ['city', '=', ['Moroleón']],
        },
        {
          value: 'Salvatierra',
          label: 'Salvatierra',
          condition: ['city', '=', ['Salvatierra']],
        },
        {
          value: 'Yuriria',
          label: 'Yuriria',
          condition: ['city', '=', ['Yuriria']],
        },
        {
          value: 'Santiago Maravatío',
          label: 'Santiago Maravatío',
          condition: ['city', '=', ['Santiago Maravatío']],
        },
        {
          value: 'Uriangato',
          label: 'Uriangato',
          condition: ['city', '=', ['Uriangato']],
        },
        {
          value: 'Chilpancingo de los Bravo',
          label: 'Chilpancingo de los Bravo',
          condition: ['city', '=', ['Chilpancingo de los Bravo', 'Ocotito']],
        },
        {
          value: 'Tixtla de Guerrero',
          label: 'Tixtla de Guerrero',
          condition: ['city', '=', ['Tixtla de Guerrero']],
        },
        {
          value: 'Ayutla de los Libres',
          label: 'Ayutla de los Libres',
          condition: ['city', '=', ['Ayutla de los Libres']],
        },
        {
          value: 'Acapulco de Juárez',
          label: 'Acapulco de Juárez',
          condition: ['city', '=', ['Acapulco de Juárez']],
        },
        {
          value: 'Juan R. Escudero',
          label: 'Juan R. Escudero',
          condition: ['city', '=', ['Tierra Colorada']],
        },
        {
          value: 'San Marcos',
          label: 'San Marcos',
          condition: ['city', '=', ['San Marcos']],
        },
        {
          value: 'Iguala de la Independencia',
          label: 'Iguala de la Independencia',
          condition: ['city', '=', ['Iguala de la Independencia']],
        },
        {
          value: 'Huitzuco de los Figueroa',
          label: 'Huitzuco de los Figueroa',
          condition: ['city', '=', ['Huitzuco']],
        },
        {
          value: 'Eduardo Neri',
          label: 'Eduardo Neri',
          condition: ['city', '=', ['Zumpango del Río']],
        },
        {
          value: 'Taxco de Alarcón',
          label: 'Taxco de Alarcón',
          condition: ['city', '=', ['Taxco de Alarcón']],
        },
        {
          value: 'Buenavista de Cuéllar',
          label: 'Buenavista de Cuéllar',
          condition: ['city', '=', ['Buenavista de Cuéllar']],
        },
        {
          value: 'Teloloapan',
          label: 'Teloloapan',
          condition: ['city', '=', ['Teloloapan']],
        },
        {
          value: 'Arcelia',
          label: 'Arcelia',
          condition: ['city', '=', ['Arcelia']],
        },
        {
          value: 'Apaxtla',
          label: 'Apaxtla',
          condition: ['city', '=', ['Ciudad Apaxtla de Castrejón']],
        },
        {
          value: 'Tlapehuala',
          label: 'Tlapehuala',
          condition: ['city', '=', ['Tlapehuala']],
        },
        {
          value: 'Cutzamala de Pinzón',
          label: 'Cutzamala de Pinzón',
          condition: ['city', '=', ['Cutzamala de Pinzón']],
        },
        {
          value: 'Pungarabato',
          label: 'Pungarabato',
          condition: ['city', '=', ['Ciudad Altamirano']],
        },
        {
          value: 'Coyuca de Catalán',
          label: 'Coyuca de Catalán',
          condition: ['city', '=', ['Coyuca de Catalán']],
        },
        {
          value: 'La Unión de Isidoro Montes de Oca',
          label: 'La Unión de Isidoro Montes de Oca',
          condition: ['city', '=', ['La Unión']],
        },
        {
          value: 'Petatlán',
          label: 'Petatlán',
          condition: ['city', '=', ['Petatlán']],
        },
        {
          value: 'Zihuatanejo de Azueta',
          label: 'Zihuatanejo de Azueta',
          condition: ['city', '=', ['Zihuatanejo']],
        },
        {
          value: 'Técpan de Galeana',
          label: 'Técpan de Galeana',
          condition: [
            'city',
            '=',
            ['Técpan de Galeana', 'San Luis San Pedro', 'San Luis de la Loma'],
          ],
        },
        {
          value: 'Atoyac de Álvarez',
          label: 'Atoyac de Álvarez',
          condition: ['city', '=', ['Atoyac de Álvarez']],
        },
        {
          value: 'Coyuca de Benítez',
          label: 'Coyuca de Benítez',
          condition: ['city', '=', ['Coyuca de Benítez']],
        },
        {
          value: 'Olinalá',
          label: 'Olinalá',
          condition: ['city', '=', ['Olinalá']],
        },
        {
          value: 'Chilapa de Álvarez',
          label: 'Chilapa de Álvarez',
          condition: ['city', '=', ['Chilapa de Álvarez']],
        },
        {
          value: 'Huamuxtitlán',
          label: 'Huamuxtitlán',
          condition: ['city', '=', ['Huamuxtitlán']],
        },
        {
          value: 'Tlapa de Comonfort',
          label: 'Tlapa de Comonfort',
          condition: ['city', '=', ['Tlapa de Comonfort']],
        },
        {
          value: 'San Luis Acatlán',
          label: 'San Luis Acatlán',
          condition: ['city', '=', ['San Luis Acatlán']],
        },
        {
          value: 'Florencio Villarreal',
          label: 'Florencio Villarreal',
          condition: ['city', '=', ['Cruz Grande']],
        },
        {
          value: 'Copala',
          label: 'Copala',
          condition: ['city', '=', ['Copala']],
        },
        {
          value: 'Azoyú',
          label: 'Azoyú',
          condition: ['city', '=', ['Azoyú']],
        },
        {
          value: 'Marquelia',
          label: 'Marquelia',
          condition: ['city', '=', ['Marquelia']],
        },
        {
          value: 'Cuajinicuilapa',
          label: 'Cuajinicuilapa',
          condition: ['city', '=', ['Cuajinicuilapa']],
        },
        {
          value: 'Pachuca de Soto',
          label: 'Pachuca de Soto',
          condition: ['city', '=', ['Pachuca de Soto']],
        },
        {
          value: 'Ixmiquilpan',
          label: 'Ixmiquilpan',
          condition: ['city', '=', ['Ixmiquilpan']],
        },
        {
          value: 'Zimapán',
          label: 'Zimapán',
          condition: ['city', '=', ['Zimapán']],
        },
        {
          value: 'Actopan',
          label: 'Actopan',
          condition: ['city', '=', ['Actopan']],
        },
        {
          value: 'Tula de Allende',
          label: 'Tula de Allende',
          condition: ['city', '=', ['Tula de Allende', 'San Miguel Vindho']],
        },
        {
          value: 'Tepeji del Río de Ocampo',
          label: 'Tepeji del Río de Ocampo',
          condition: ['city', '=', ['Tepeji del Río de Ocampo']],
        },
        {
          value: 'Tlaxcoapan',
          label: 'Tlaxcoapan',
          condition: ['city', '=', ['Tlaxcoapan']],
        },
        {
          value: 'Huejutla de Reyes',
          label: 'Huejutla de Reyes',
          condition: ['city', '=', ['Huejutla de Reyes']],
        },
        {
          value: 'Tulancingo de Bravo',
          label: 'Tulancingo de Bravo',
          condition: ['city', '=', ['Tulancingo']],
        },
        {
          value: 'Santiago Tulantepec de Lugo Guerrero',
          label: 'Santiago Tulantepec de Lugo Guerrero',
          condition: ['city', '=', ['Santiago Tulantepec']],
        },
        {
          value: 'Tizayuca',
          label: 'Tizayuca',
          condition: ['city', '=', ['Tizayuca']],
        },
        {
          value: 'Apan',
          label: 'Apan',
          condition: ['city', '=', ['Apan']],
        },
        {
          value: 'Tepeapulco',
          label: 'Tepeapulco',
          condition: [
            'city',
            '=',
            ['Tepeapulco', 'Fray Bernardino de Sahagún (Ciudad Sahagún)'],
          ],
        },
        {
          value: 'Guadalajara',
          label: 'Guadalajara',
          condition: ['city', '=', ['Guadalajara']],
        },
        {
          value: 'Zapopan',
          label: 'Zapopan',
          condition: ['city', '=', ['Zapopan']],
        },
        {
          value: 'Tala',
          label: 'Tala',
          condition: ['city', '=', ['Tala']],
        },
        {
          value: 'San Pedro Tlaquepaque',
          label: 'San Pedro Tlaquepaque',
          condition: ['city', '=', ['Tlaquepaque']],
        },
        {
          value: 'Tlajomulco de Zúñiga',
          label: 'Tlajomulco de Zúñiga',
          condition: ['city', '=', ['Tlajomulco de Zúñiga']],
        },
        {
          value: 'El Salto',
          label: 'El Salto',
          condition: [
            'city',
            '=',
            [
              'El Salto',
              'Las Pintitas',
              'San José el Verde (El Verde)',
              'El Quince (San José el Quince)',
            ],
          ],
        },
        {
          value: 'Acatlán de Juárez',
          label: 'Acatlán de Juárez',
          condition: ['city', '=', ['Acatlán de Juárez']],
        },
        {
          value: 'Villa Corona',
          label: 'Villa Corona',
          condition: ['city', '=', ['Villa Corona']],
        },
        {
          value: 'Zacoalco de Torres',
          label: 'Zacoalco de Torres',
          condition: ['city', '=', ['Zacoalco de Torres']],
        },
        {
          value: 'Jocotepec',
          label: 'Jocotepec',
          condition: ['city', '=', ['Jocotepec']],
        },
        {
          value: 'Chapala',
          label: 'Chapala',
          condition: ['city', '=', ['Chapala', 'Ajijic']],
        },
        {
          value: 'Poncitlán',
          label: 'Poncitlán',
          condition: ['city', '=', ['Poncitlán']],
        },
        {
          value: 'Huejuquilla el Alto',
          label: 'Huejuquilla el Alto',
          condition: ['city', '=', ['Huejuquilla el Alto']],
        },
        {
          value: 'Colotlán',
          label: 'Colotlán',
          condition: ['city', '=', ['Colotlán']],
        },
        {
          value: 'Tequila',
          label: 'Tequila',
          condition: ['city', '=', ['Tequila']],
        },
        {
          value: 'Magdalena',
          label: 'Magdalena',
          condition: ['city', '=', ['Magdalena', 'Magdalena de Kino']],
        },
        {
          value: 'Etzatlán',
          label: 'Etzatlán',
          condition: ['city', '=', ['Etzatlán']],
        },
        {
          value: 'Ameca',
          label: 'Ameca',
          condition: ['city', '=', ['Ameca']],
        },
        {
          value: 'Ahualulco de Mercado',
          label: 'Ahualulco de Mercado',
          condition: ['city', '=', ['Ahualulco de Mercado']],
        },
        {
          value: 'San Juan de los Lagos',
          label: 'San Juan de los Lagos',
          condition: ['city', '=', ['San Juan de los Lagos']],
        },
        {
          value: 'Jalostotitlán',
          label: 'Jalostotitlán',
          condition: ['city', '=', ['Jalostotitlán']],
        },
        {
          value: 'San Miguel el Alto',
          label: 'San Miguel el Alto',
          condition: ['city', '=', ['San Miguel el Alto']],
        },
        {
          value: 'San Julián',
          label: 'San Julián',
          condition: ['city', '=', ['San Julián']],
        },
        {
          value: 'Arandas',
          label: 'Arandas',
          condition: ['city', '=', ['Arandas']],
        },
        {
          value: 'San Ignacio Cerro Gordo',
          label: 'San Ignacio Cerro Gordo',
          condition: ['city', '=', ['San Ignacio Cerro Gordo']],
        },
        {
          value: 'Teocaltiche',
          label: 'Teocaltiche',
          condition: ['city', '=', ['Teocaltiche']],
        },
        {
          value: 'Villa Hidalgo',
          label: 'Villa Hidalgo',
          condition: ['city', '=', ['Villa Hidalgo']],
        },
        {
          value: 'Yahualica de González Gallo',
          label: 'Yahualica de González Gallo',
          condition: ['city', '=', ['Yahualica de González Gallo']],
        },
        {
          value: 'Valle de Guadalupe',
          label: 'Valle de Guadalupe',
          condition: ['city', '=', ['Valle de Guadalupe']],
        },
        {
          value: 'Lagos de Moreno',
          label: 'Lagos de Moreno',
          condition: ['city', '=', ['Lagos de Moreno']],
        },
        {
          value: 'Unión de San Antonio',
          label: 'Unión de San Antonio',
          condition: ['city', '=', ['Unión de San Antonio']],
        },
        {
          value: 'San Diego de Alejandría',
          label: 'San Diego de Alejandría',
          condition: ['city', '=', ['San Diego de Alejandría']],
        },
        {
          value: 'Tepatitlán de Morelos',
          label: 'Tepatitlán de Morelos',
          condition: ['city', '=', ['Tepatitlán de Morelos']],
        },
        {
          value: 'Tototlán',
          label: 'Tototlán',
          condition: ['city', '=', ['Tototlán']],
        },
        {
          value: 'Atotonilco el Alto',
          label: 'Atotonilco el Alto',
          condition: ['city', '=', ['Atotonilco el Alto']],
        },
        {
          value: 'Ocotlán',
          label: 'Ocotlán',
          condition: ['city', '=', ['Ocotlán']],
        },
        {
          value: 'Jamay',
          label: 'Jamay',
          condition: ['city', '=', ['Jamay']],
        },
        {
          value: 'La Barca',
          label: 'La Barca',
          condition: ['city', '=', ['La Barca']],
        },
        {
          value: 'Talpa de Allende',
          label: 'Talpa de Allende',
          condition: ['city', '=', ['Talpa de Allende']],
        },
        {
          value: 'Puerto Vallarta',
          label: 'Puerto Vallarta',
          condition: ['city', '=', ['Puerto Vallarta']],
        },
        {
          value: 'Cocula',
          label: 'Cocula',
          condition: ['city', '=', ['Cocula']],
        },
        {
          value: 'El Grullo',
          label: 'El Grullo',
          condition: ['city', '=', ['El Grullo']],
        },
        {
          value: 'Villa Purificación',
          label: 'Villa Purificación',
          condition: ['city', '=', ['Villa Purificación']],
        },
        {
          value: 'Autlán de Navarro',
          label: 'Autlán de Navarro',
          condition: ['city', '=', ['Autlán de Navarro']],
        },
        {
          value: 'Casimiro Castillo',
          label: 'Casimiro Castillo',
          condition: ['city', '=', ['La Resolana']],
        },
        {
          value: 'Cihuatlán',
          label: 'Cihuatlán',
          condition: ['city', '=', ['Cihuatlán']],
        },
        {
          value: 'Zapotlán el Grande',
          label: 'Zapotlán el Grande',
          condition: ['city', '=', ['Ciudad Guzmán']],
        },
        {
          value: 'Sayula',
          label: 'Sayula',
          condition: ['city', '=', ['Sayula']],
        },
        {
          value: 'Zapotiltic',
          label: 'Zapotiltic',
          condition: ['city', '=', ['Zapotiltic']],
        },
        {
          value: 'Tamazula de Gordiano',
          label: 'Tamazula de Gordiano',
          condition: ['city', '=', ['Tamazula de Gordiano']],
        },
        {
          value: 'Tuxpan',
          label: 'Tuxpan',
          condition: ['city', '=', ['Tuxpan', 'Túxpam de Rodríguez Cano']],
        },
        {
          value: 'Tecalitlán',
          label: 'Tecalitlán',
          condition: ['city', '=', ['Tecalitlán']],
        },
        {
          value: 'Toluca',
          label: 'Toluca',
          condition: ['city', '=', ['Toluca de Lerdo']],
        },
        {
          value: 'Almoloya de Juárez',
          label: 'Almoloya de Juárez',
          condition: ['city', '=', ['Almoloya de Juárez']],
        },
        {
          value: 'Tejupilco',
          label: 'Tejupilco',
          condition: ['city', '=', ['Tejupilco de Hidalgo']],
        },
        {
          value: 'Amatepec',
          label: 'Amatepec',
          condition: ['city', '=', ['Amatepec']],
        },
        {
          value: 'Xonacatlán',
          label: 'Xonacatlán',
          condition: ['city', '=', ['Xonacatlán']],
        },
        {
          value: 'San Mateo Atenco',
          label: 'San Mateo Atenco',
          condition: ['city', '=', ['San Mateo Atenco']],
        },
        {
          value: 'Metepec',
          label: 'Metepec',
          condition: ['city', '=', ['Metepec']],
        },
        {
          value: 'Capulhuac',
          label: 'Capulhuac',
          condition: ['city', '=', ['Capulhuac']],
        },
        {
          value: 'Ocoyoacac',
          label: 'Ocoyoacac',
          condition: ['city', '=', ['Ocoyoacac']],
        },
        {
          value: 'Huixquilucan',
          label: 'Huixquilucan',
          condition: ['city', '=', ['Huixquilucan de Degollado']],
        },
        {
          value: 'Atizapán de Zaragoza',
          label: 'Atizapán de Zaragoza',
          condition: ['city', '=', ['Ciudad Adolfo López Mateos']],
        },
        {
          value: 'Naucalpan de Juárez',
          label: 'Naucalpan de Juárez',
          condition: ['city', '=', ['Naucalpan de Juárez']],
        },
        {
          value: 'Tlalnepantla de Baz',
          label: 'Tlalnepantla de Baz',
          condition: ['city', '=', ['Tlalnepantla de Baz']],
        },
        {
          value: 'Nicolás Romero',
          label: 'Nicolás Romero',
          condition: ['city', '=', ['Ciudad Nicolás Romero']],
        },
        {
          value: 'Tepotzotlán',
          label: 'Tepotzotlán',
          condition: ['city', '=', ['Tepotzotlán']],
        },
        {
          value: 'Cuautitlán Izcalli',
          label: 'Cuautitlán Izcalli',
          condition: ['city', '=', ['Cuautitlán Izcalli']],
        },
        {
          value: 'Cuautitlán',
          label: 'Cuautitlán',
          condition: ['city', '=', ['Cuautitlán']],
        },
        {
          value: 'Melchor Ocampo',
          label: 'Melchor Ocampo',
          condition: ['city', '=', ['Melchor Ocampo']],
        },
        {
          value: 'Tultitlán',
          label: 'Tultitlán',
          condition: ['city', '=', ['Tultitlán de Mariano Escobedo']],
        },
        {
          value: 'Tultepec',
          label: 'Tultepec',
          condition: ['city', '=', ['Santa Maria Tultepec']],
        },
        {
          value: 'Ecatepec de Morelos',
          label: 'Ecatepec de Morelos',
          condition: ['city', '=', ['Ecatepec de Morelos']],
        },
        {
          value: 'Zumpango',
          label: 'Zumpango',
          condition: ['city', '=', ['Zumpango']],
        },
        {
          value: 'Tequixquiac',
          label: 'Tequixquiac',
          condition: ['city', '=', ['Tequixquiac']],
        },
        {
          value: 'Coacalco de Berriozábal',
          label: 'Coacalco de Berriozábal',
          condition: ['city', '=', ['Coacalco de Berriozabal']],
        },
        {
          value: 'Tecámac',
          label: 'Tecámac',
          condition: ['city', '=', ['Tecámac de Felipe Villanueva']],
        },
        {
          value: 'Texcoco',
          label: 'Texcoco',
          condition: ['city', '=', ['Texcoco de Mora']],
        },
        {
          value: 'Chiconcuac',
          label: 'Chiconcuac',
          condition: ['city', '=', ['Chiconcuac']],
        },
        {
          value: 'Chimalhuacán',
          label: 'Chimalhuacán',
          condition: ['city', '=', ['Chimalhuacán']],
        },
        {
          value: 'Chicoloapan',
          label: 'Chicoloapan',
          condition: ['city', '=', ['Chicoloapan de Juárez']],
        },
        {
          value: 'Ixtapaluca',
          label: 'Ixtapaluca',
          condition: ['city', '=', ['Ixtapaluca']],
        },
        {
          value: 'Chalco',
          label: 'Chalco',
          condition: ['city', '=', ['Chalco de Díaz Covarrubias']],
        },
        {
          value: 'Valle de Chalco Solidaridad',
          label: 'Valle de Chalco Solidaridad',
          condition: ['city', '=', ['Valle de Chalco Solidaridad']],
        },
        {
          value: 'Juchitepec',
          label: 'Juchitepec',
          condition: ['city', '=', ['Juchitepec de Mariano Rivapalacio']],
        },
        {
          value: 'Nezahualcóyotl',
          label: 'Nezahualcóyotl',
          condition: ['city', '=', ['Ciudad Nezahualcóyotl']],
        },
        {
          value: 'Morelia',
          label: 'Morelia',
          condition: ['city', '=', ['Morelia']],
        },
        {
          value: 'Puruándiro',
          label: 'Puruándiro',
          condition: ['city', '=', ['Puruándiro']],
        },
        {
          value: 'Zacapu',
          label: 'Zacapu',
          condition: ['city', '=', ['Zacapu']],
        },
        {
          value: 'Cuitzeo',
          label: 'Cuitzeo',
          condition: ['city', '=', ['Cuitzeo del Porvenir']],
        },
        {
          value: 'Zinapécuaro',
          label: 'Zinapécuaro',
          condition: ['city', '=', ['Zinapécuaro de Figueroa']],
        },
        {
          value: 'Sahuayo',
          label: 'Sahuayo',
          condition: ['city', '=', ['Sahuayo de Morelos']],
        },
        {
          value: 'Yurécuaro',
          label: 'Yurécuaro',
          condition: ['city', '=', ['Yurécuaro']],
        },
        {
          value: 'La Piedad',
          label: 'La Piedad',
          condition: ['city', '=', ['La Piedad de Cabadas']],
        },
        {
          value: 'Jiquilpan',
          label: 'Jiquilpan',
          condition: ['city', '=', ['Jiquilpan de Juárez']],
        },
        {
          value: 'Zamora',
          label: 'Zamora',
          condition: ['city', '=', ['Zamora de Hidalgo']],
        },
        {
          value: 'Tangancícuaro',
          label: 'Tangancícuaro',
          condition: ['city', '=', ['Tangancícuaro de Arista']],
        },
        {
          value: 'Jacona',
          label: 'Jacona',
          condition: ['city', '=', ['Jacona de Plancarte']],
        },
        {
          value: 'Cotija',
          label: 'Cotija',
          condition: ['city', '=', ['Cotija de la Paz']],
        },
        {
          value: 'Uruapan',
          label: 'Uruapan',
          condition: ['city', '=', ['Uruapan']],
        },
        {
          value: 'Paracho',
          label: 'Paracho',
          condition: ['city', '=', ['Paracho de Verduzco']],
        },
        {
          value: 'Los Reyes',
          label: 'Los Reyes',
          condition: ['city', '=', ['Los Reyes de Salgado']],
        },
        {
          value: 'Apatzingán',
          label: 'Apatzingán',
          condition: ['city', '=', ['Apatzingán de la Constitución']],
        },
        {
          value: 'Lázaro Cárdenas',
          label: 'Lázaro Cárdenas',
          condition: [
            'city',
            '=',
            ['Ciudad Lázaro Cárdenas', 'Las Guacamayas', 'Kantunilkín'],
          ],
        },
        {
          value: 'Hidalgo',
          label: 'Hidalgo',
          condition: ['city', '=', ['Ciudad Hidalgo']],
        },
        {
          value: 'Maravatío',
          label: 'Maravatío',
          condition: ['city', '=', ['Maravatío de Ocampo']],
        },
        {
          value: 'Zitácuaro',
          label: 'Zitácuaro',
          condition: ['city', '=', ['Heróica Zitácuaro']],
        },
        {
          value: 'Pátzcuaro',
          label: 'Pátzcuaro',
          condition: ['city', '=', ['Pátzcuaro']],
        },
        {
          value: 'Tacámbaro',
          label: 'Tacámbaro',
          condition: ['city', '=', ['Tacámbaro de Codallos']],
        },
        {
          value: 'Múgica',
          label: 'Múgica',
          condition: ['city', '=', ['Nueva Italia de Ruiz']],
        },
        {
          value: 'Huetamo',
          label: 'Huetamo',
          condition: ['city', '=', ['Huetamo de Núñez']],
        },
        {
          value: 'Cuernavaca',
          label: 'Cuernavaca',
          condition: ['city', '=', ['Cuernavaca']],
        },
        {
          value: 'Jiutepec',
          label: 'Jiutepec',
          condition: ['city', '=', ['Jiutepec']],
        },
        {
          value: 'Temixco',
          label: 'Temixco',
          condition: ['city', '=', ['Temixco']],
        },
        {
          value: 'Puente de Ixtla',
          label: 'Puente de Ixtla',
          condition: ['city', '=', ['Puente de Ixtla']],
        },
        {
          value: 'Yautepec',
          label: 'Yautepec',
          condition: ['city', '=', ['Yautepec de Zaragoza']],
        },
        {
          value: 'Cuautla',
          label: 'Cuautla',
          condition: ['city', '=', ['Cuautla']],
        },
        {
          value: 'Tlaltizapán de Zapata',
          label: 'Tlaltizapán de Zapata',
          condition: ['city', '=', ['Santa Rosa Treinta']],
        },
        {
          value: 'Zacatepec',
          label: 'Zacatepec',
          condition: ['city', '=', ['Zacatepec de Hidalgo', 'Galeana']],
        },
        {
          value: 'Jojutla',
          label: 'Jojutla',
          condition: ['city', '=', ['Jojutla']],
        },
        {
          value: 'Tlaquiltenango',
          label: 'Tlaquiltenango',
          condition: ['city', '=', ['Tlaquiltenango']],
        },
        {
          value: 'Tepic',
          label: 'Tepic',
          condition: ['city', '=', ['Tepic', 'Francisco I. Madero (Puga)']],
        },
        {
          value: 'Santiago Ixcuintla',
          label: 'Santiago Ixcuintla',
          condition: [
            'city',
            '=',
            ['Santiago Ixcuintla', 'Villa Hidalgo (El Nuevo)'],
          ],
        },
        {
          value: 'Acaponeta',
          label: 'Acaponeta',
          condition: ['city', '=', ['Acaponeta']],
        },
        {
          value: 'Tecuala',
          label: 'Tecuala',
          condition: ['city', '=', ['Tecuala']],
        },
        {
          value: 'Ruíz',
          label: 'Ruíz',
          condition: ['city', '=', ['Ruíz']],
        },
        {
          value: 'Compostela',
          label: 'Compostela',
          condition: [
            'city',
            '=',
            ['Compostela', 'Las Varas', 'La Peñita de Jaltemba'],
          ],
        },
        {
          value: 'Bahía de Banderas',
          label: 'Bahía de Banderas',
          condition: ['city', '=', ['Bucerías']],
        },
        {
          value: 'San Blas',
          label: 'San Blas',
          condition: ['city', '=', ['San Blas']],
        },
        {
          value: 'Xalisco',
          label: 'Xalisco',
          condition: ['city', '=', ['Xalisco']],
        },
        {
          value: 'San Pedro Lagunillas',
          label: 'San Pedro Lagunillas',
          condition: ['city', '=', ['San Pedro Lagunillas']],
        },
        {
          value: 'Jala',
          label: 'Jala',
          condition: ['city', '=', ['Jala']],
        },
        {
          value: 'Ahuacatlán',
          label: 'Ahuacatlán',
          condition: ['city', '=', ['Ahuacatlán']],
        },
        {
          value: 'Ixtlán del Río',
          label: 'Ixtlán del Río',
          condition: ['city', '=', ['Ixtlán del Río']],
        },
        {
          value: 'Monterrey',
          label: 'Monterrey',
          condition: ['city', '=', ['Monterrey']],
        },
        {
          value: 'Anáhuac',
          label: 'Anáhuac',
          condition: ['city', '=', ['Anáhuac']],
        },
        {
          value: 'Sabinas Hidalgo',
          label: 'Sabinas Hidalgo',
          condition: ['city', '=', ['Ciudad Sabinas Hidalgo']],
        },
        {
          value: 'Ciénega de Flores',
          label: 'Ciénega de Flores',
          condition: ['city', '=', ['Ciénega de Flores']],
        },
        {
          value: 'García',
          label: 'García',
          condition: ['city', '=', ['García']],
        },
        {
          value: 'General Escobedo',
          label: 'General Escobedo',
          condition: ['city', '=', ['Ciudad General Escobedo']],
        },
        {
          value: 'Santa Catarina',
          label: 'Santa Catarina',
          condition: ['city', '=', ['Ciudad Santa Catarina']],
        },
        {
          value: 'San Pedro Garza García',
          label: 'San Pedro Garza García',
          condition: ['city', '=', ['San Pedro Garza García']],
        },
        {
          value: 'San Nicolás de los Garza',
          label: 'San Nicolás de los Garza',
          condition: ['city', '=', ['San Nicolás de los Garza']],
        },
        {
          value: 'Apodaca',
          label: 'Apodaca',
          condition: ['city', '=', ['Ciudad Apodaca']],
        },
        {
          value: 'Guadalupe',
          label: 'Guadalupe',
          condition: ['city', '=', ['Guadalupe']],
        },
        {
          value: 'Santiago',
          label: 'Santiago',
          condition: ['city', '=', ['Santiago', 'El cercado']],
        },
        {
          value: 'Cadereyta Jiménez',
          label: 'Cadereyta Jiménez',
          condition: ['city', '=', ['Cadereyta Jiménez']],
        },
        {
          value: 'Montemorelos',
          label: 'Montemorelos',
          condition: ['city', '=', ['Montemorelos']],
        },
        {
          value: 'Linares',
          label: 'Linares',
          condition: ['city', '=', ['Linares']],
        },
        {
          value: 'Hualahuises',
          label: 'Hualahuises',
          condition: ['city', '=', ['Hualahuises']],
        },
        {
          value: 'Doctor Arroyo',
          label: 'Doctor Arroyo',
          condition: ['city', '=', ['Doctor Arroyo']],
        },
        {
          value: 'Oaxaca de Juárez',
          label: 'Oaxaca de Juárez',
          condition: ['city', '=', ['Oaxaca de Juárez']],
        },
        {
          value: 'San Francisco Telixtlahuaca',
          label: 'San Francisco Telixtlahuaca',
          condition: ['city', '=', ['San Francisco Telixtlahuaca']],
        },
        {
          value: 'San Pablo Huitzo',
          label: 'San Pablo Huitzo',
          condition: ['city', '=', ['San Pablo Huitzo']],
        },
        {
          value: 'Santiago Suchilquitongo',
          label: 'Santiago Suchilquitongo',
          condition: ['city', '=', ['Santiago Suchilquitongo']],
        },
        {
          value: 'San Juan Bautista Tuxtepec',
          label: 'San Juan Bautista Tuxtepec',
          condition: ['city', '=', ['San Juan Bautista Tuxtepec']],
        },
        {
          value: 'Loma Bonita',
          label: 'Loma Bonita',
          condition: ['city', '=', ['Loma Bonita']],
        },
        {
          value: 'Cosolapa',
          label: 'Cosolapa',
          condition: ['city', '=', ['Cosolapa']],
        },
        {
          value: 'Acatlán de Pérez Figueroa',
          label: 'Acatlán de Pérez Figueroa',
          condition: ['city', '=', ['Vicente Camalote']],
        },
        {
          value: 'San Felipe Jalapa de Díaz',
          label: 'San Felipe Jalapa de Díaz',
          condition: ['city', '=', ['San Felipe Jalapa de Díaz']],
        },
        {
          value: 'San Juan Bautista Valle Nacional',
          label: 'San Juan Bautista Valle Nacional',
          condition: ['city', '=', ['San Juan Bautista Valle Nacional']],
        },
        {
          value: 'Teotitlán de Flores Magón',
          label: 'Teotitlán de Flores Magón',
          condition: ['city', '=', ['Teotitlán de Flores Magón']],
        },
        {
          value: 'San Juan Bautista Cuicatlán',
          label: 'San Juan Bautista Cuicatlán',
          condition: ['city', '=', ['San Juan Bautista Cuicatlán']],
        },
        {
          value: 'Natividad',
          label: 'Natividad',
          condition: ['city', '=', ['Natividad']],
        },
        {
          value: 'Heroica Ciudad de Huajuapan de León',
          label: 'Heroica Ciudad de Huajuapan de León',
          condition: ['city', '=', ['Heroica Ciudad de Huajuapan de León']],
        },
        {
          value: 'Mariscala de Juárez',
          label: 'Mariscala de Juárez',
          condition: ['city', '=', ['Mariscala de Juárez']],
        },
        {
          value: 'Villa de Tamazulápam del Progreso',
          label: 'Villa de Tamazulápam del Progreso',
          condition: ['city', '=', ['Villa de Tamazulápam del Progreso']],
        },
        {
          value: 'Asunción Nochixtlán',
          label: 'Asunción Nochixtlán',
          condition: ['city', '=', ['Asunción Nochixtlán']],
        },
        {
          value: 'Santiago Juxtlahuaca',
          label: 'Santiago Juxtlahuaca',
          condition: ['city', '=', ['Santiago Juxtlahuaca']],
        },
        {
          value: 'San Sebastián Tecomaxtlahuaca',
          label: 'San Sebastián Tecomaxtlahuaca',
          condition: ['city', '=', ['San Sebastián Tecomaxtlahuaca']],
        },
        {
          value: 'Heroica Ciudad de Tlaxiaco',
          label: 'Heroica Ciudad de Tlaxiaco',
          condition: ['city', '=', ['Heroica Ciudad de Tlaxiaco']],
        },
        {
          value: 'Juchitán de Zaragoza',
          label: 'Juchitán de Zaragoza',
          condition: ['city', '=', ['Juchitán (Juchitán de Zaragoza)']],
        },
        {
          value: 'Ciudad Ixtepec',
          label: 'Ciudad Ixtepec',
          condition: ['city', '=', ['Ciudad Ixtepec']],
        },
        {
          value: 'Unión Hidalgo',
          label: 'Unión Hidalgo',
          condition: ['city', '=', ['Unión Hidalgo']],
        },
        {
          value: 'San Francisco Ixhuatán',
          label: 'San Francisco Ixhuatán',
          condition: ['city', '=', ['San Francisco Ixhuatán']],
        },
        {
          value: 'San Pedro Tapanatepec',
          label: 'San Pedro Tapanatepec',
          condition: ['city', '=', ['San Pedro Tapanatepec']],
        },
        {
          value: 'Chahuites',
          label: 'Chahuites',
          condition: ['city', '=', ['Chahuites']],
        },
        {
          value: 'Matías Romero Avendaño',
          label: 'Matías Romero Avendaño',
          condition: ['city', '=', ['Matías Romero Avendaño']],
        },
        {
          value: 'El Barrio de la Soledad',
          label: 'El Barrio de la Soledad',
          condition: ['city', '=', ['Lagunas']],
        },
        {
          value: 'Tlacolula de Matamoros',
          label: 'Tlacolula de Matamoros',
          condition: ['city', '=', ['Tlacolula de Matamoros']],
        },
        {
          value: 'San Pablo Villa de Mitla',
          label: 'San Pablo Villa de Mitla',
          condition: ['city', '=', ['San Pablo Villa de Mitla']],
        },
        {
          value: 'San Pedro Totolápam',
          label: 'San Pedro Totolápam',
          condition: ['city', '=', ['San Pedro Totolápam']],
        },
        {
          value: 'Nejapa de Madero',
          label: 'Nejapa de Madero',
          condition: ['city', '=', ['El Camarón']],
        },
        {
          value: 'Salina Cruz',
          label: 'Salina Cruz',
          condition: ['city', '=', ['Salina Cruz']],
        },
        {
          value: 'Santo Domingo Tehuantepec',
          label: 'Santo Domingo Tehuantepec',
          condition: ['city', '=', ['Santo Domingo Tehuantepec']],
        },
        {
          value: 'Heroica Villa de San Blas Atempa',
          label: 'Heroica Villa de San Blas Atempa',
          condition: ['city', '=', ['San Blas Atempa']],
        },
        {
          value: 'Miahuatlán de Porfirio Díaz',
          label: 'Miahuatlán de Porfirio Díaz',
          condition: ['city', '=', ['Miahuatlán de Porfirio Díaz']],
        },
        {
          value: 'San Pedro Pochutla',
          label: 'San Pedro Pochutla',
          condition: ['city', '=', ['San Pedro Pochutla']],
        },
        {
          value: 'Santa María Huatulco',
          label: 'Santa María Huatulco',
          condition: [
            'city',
            '=',
            ['Santa María Huatulco', 'Bahias de Huatulco'],
          ],
        },
        {
          value: 'Putla Villa de Guerrero',
          label: 'Putla Villa de Guerrero',
          condition: ['city', '=', ['Putla Villa de Guerrero']],
        },
        {
          value: 'San Miguel el Grande',
          label: 'San Miguel el Grande',
          condition: ['city', '=', ['San Miguel el Grande']],
        },
        {
          value: 'Santa Cruz Itundujia',
          label: 'Santa Cruz Itundujia',
          condition: ['city', '=', ['Santa Cruz Itundujia']],
        },
        {
          value: 'Zimatlán de Álvarez',
          label: 'Zimatlán de Álvarez',
          condition: ['city', '=', ['Zimatlán de Álvarez']],
        },
        {
          value: 'Santa Lucía del Camino',
          label: 'Santa Lucía del Camino',
          condition: ['city', '=', ['Santa Lucía del Camino']],
        },
        {
          value: 'San Antonio de la Cal',
          label: 'San Antonio de la Cal',
          condition: ['city', '=', ['San Antonio de la Cal']],
        },
        {
          value: 'Villa de Zaachila',
          label: 'Villa de Zaachila',
          condition: ['city', '=', ['Villa de Zaachila']],
        },
        {
          value: 'San Sebastián Tutla',
          label: 'San Sebastián Tutla',
          condition: ['city', '=', ['El Rosario']],
        },
        {
          value: 'Cuilápam de Guerrero',
          label: 'Cuilápam de Guerrero',
          condition: ['city', '=', ['Cuilápam de Guerrero']],
        },
        {
          value: 'Villa Sola de Vega',
          label: 'Villa Sola de Vega',
          condition: ['city', '=', ['Villa Sola de Vega']],
        },
        {
          value: 'Heroica Ciudad de Ejutla de Crespo',
          label: 'Heroica Ciudad de Ejutla de Crespo',
          condition: ['city', '=', ['Heroica Ciudad de Ejutla de Crespo']],
        },
        {
          value: 'Ocotlán de Morelos',
          label: 'Ocotlán de Morelos',
          condition: ['city', '=', ['Ocotlán de Morelos']],
        },
        {
          value: 'Santiago Pinotepa Nacional',
          label: 'Santiago Pinotepa Nacional',
          condition: ['city', '=', ['Santiago Pinotepa Nacional']],
        },
        {
          value: 'San Juan Cacahuatepec',
          label: 'San Juan Cacahuatepec',
          condition: ['city', '=', ['San Juan Cacahuatepec']],
        },
        {
          value: 'San Juan Bautista Lo de Soto',
          label: 'San Juan Bautista Lo de Soto',
          condition: ['city', '=', ['San Juan Bautista Lo de Soto']],
        },
        {
          value: 'Santiago Jamiltepec',
          label: 'Santiago Jamiltepec',
          condition: ['city', '=', ['Santiago Jamiltepec']],
        },
        {
          value: 'Villa de Tututepec',
          label: 'Villa de Tututepec',
          condition: ['city', '=', ['Río Grande o Piedra Parada']],
        },
        {
          value: 'San Pedro Mixtepec -Dto. 22 -',
          label: 'San Pedro Mixtepec -Dto. 22 -',
          condition: [
            'city',
            '=',
            ['Puerto Escondido', 'San Pedro Mixtepec Distrito 22'],
          ],
        },
        {
          value: 'Puebla',
          label: 'Puebla',
          condition: ['city', '=', ['Heroica Puebla de Zaragoza']],
        },
        {
          value: 'Cuautlancingo',
          label: 'Cuautlancingo',
          condition: ['city', '=', ['Cuautlancingo']],
        },
        {
          value: 'San Pedro Cholula',
          label: 'San Pedro Cholula',
          condition: ['city', '=', ['San Pedro Cholula']],
        },
        {
          value: 'San Andrés Cholula',
          label: 'San Andrés Cholula',
          condition: ['city', '=', ['San Andrés Cholula']],
        },
        {
          value: 'Amozoc',
          label: 'Amozoc',
          condition: ['city', '=', ['Amozoc']],
        },
        {
          value: 'Xicotepec',
          label: 'Xicotepec',
          condition: ['city', '=', ['Xicotepec']],
        },
        {
          value: 'Huauchinango',
          label: 'Huauchinango',
          condition: ['city', '=', ['Huauchinango']],
        },
        {
          value: 'Zacatlán',
          label: 'Zacatlán',
          condition: ['city', '=', ['Zacatlán']],
        },
        {
          value: 'Teziutlán',
          label: 'Teziutlán',
          condition: ['city', '=', ['Teziutlán']],
        },
        {
          value: 'San Martín Texmelucan',
          label: 'San Martín Texmelucan',
          condition: ['city', '=', ['San Martín Texmelucan de Labastida']],
        },
        {
          value: 'Atlixco',
          label: 'Atlixco',
          condition: ['city', '=', ['Atlixco']],
        },
        {
          value: 'Izúcar de Matamoros',
          label: 'Izúcar de Matamoros',
          condition: ['city', '=', ['Izúcar de Matamoros']],
        },
        {
          value: 'Acatlán',
          label: 'Acatlán',
          condition: ['city', '=', ['Acatlán de Osorio']],
        },
        {
          value: 'Tepeaca',
          label: 'Tepeaca',
          condition: ['city', '=', ['Tepeaca']],
        },
        {
          value: 'Tecamachalco',
          label: 'Tecamachalco',
          condition: ['city', '=', ['Tecamachalco']],
        },
        {
          value: 'Chalchicomula de Sesma',
          label: 'Chalchicomula de Sesma',
          condition: ['city', '=', ['Ciudad Serdán']],
        },
        {
          value: 'Tehuacán',
          label: 'Tehuacán',
          condition: ['city', '=', ['Tehuacán']],
        },
        {
          value: 'Querétaro',
          label: 'Querétaro',
          condition: ['city', '=', ['Santiago de Querétaro']],
        },
        {
          value: 'Corregidora',
          label: 'Corregidora',
          condition: ['city', '=', ['El Pueblito']],
        },
        {
          value: 'Othón P. Blanco',
          label: 'Othón P. Blanco',
          condition: ['city', '=', ['Chetumal']],
        },
        {
          value: 'Felipe Carrillo Puerto',
          label: 'Felipe Carrillo Puerto',
          condition: ['city', '=', ['Felipe Carrillo Puerto']],
        },
        {
          value: 'Isla Mujeres',
          label: 'Isla Mujeres',
          condition: ['city', '=', ['Isla Mujeres']],
        },
        {
          value: 'Cozumel',
          label: 'Cozumel',
          condition: ['city', '=', ['Cozumel']],
        },
        {
          value: 'Solidaridad',
          label: 'Solidaridad',
          condition: ['city', '=', ['Playa del Carmen']],
        },
        {
          value: 'Bacalar',
          label: 'Bacalar',
          condition: ['city', '=', ['Bacalar']],
        },
        {
          value: 'San Luis Potosí',
          label: 'San Luis Potosí',
          condition: ['city', '=', ['San Luis Potosí']],
        },
        {
          value: 'Soledad de Graciano Sánchez',
          label: 'Soledad de Graciano Sánchez',
          condition: ['city', '=', ['Soledad de Graciano Sánchez']],
        },
        {
          value: 'Cedral',
          label: 'Cedral',
          condition: ['city', '=', ['Cedral']],
        },
        {
          value: 'Charcas',
          label: 'Charcas',
          condition: ['city', '=', ['Charcas']],
        },
        {
          value: 'Salinas',
          label: 'Salinas',
          condition: ['city', '=', ['Salinas de Hidalgo']],
        },
        {
          value: 'Matehuala',
          label: 'Matehuala',
          condition: ['city', '=', ['Matehuala']],
        },
        {
          value: 'Ciudad Valles',
          label: 'Ciudad Valles',
          condition: ['city', '=', ['Ciudad Valles']],
        },
        {
          value: 'Ebano',
          label: 'Ebano',
          condition: ['city', '=', ['Ébano']],
        },
        {
          value: 'Tamuín',
          label: 'Tamuín',
          condition: ['city', '=', ['Tamuín']],
        },
        {
          value: 'El Naranjo',
          label: 'El Naranjo',
          condition: ['city', '=', ['El Naranjo']],
        },
        {
          value: 'Ciudad del Maíz',
          label: 'Ciudad del Maíz',
          condition: ['city', '=', ['Ciudad del Maíz']],
        },
        {
          value: 'Cárdenas',
          label: 'Cárdenas',
          condition: ['city', '=', ['Cárdenas']],
        },
        {
          value: 'Cerritos',
          label: 'Cerritos',
          condition: ['city', '=', ['Cerritos']],
        },
        {
          value: 'Villa de Reyes',
          label: 'Villa de Reyes',
          condition: ['city', '=', ['Villa de Reyes']],
        },
        {
          value: 'Santa María del Río',
          label: 'Santa María del Río',
          condition: ['city', '=', ['Santa María del Río']],
        },
        {
          value: 'Tierra Nueva',
          label: 'Tierra Nueva',
          condition: ['city', '=', ['Tierra Nueva']],
        },
        {
          value: 'Rioverde',
          label: 'Rioverde',
          condition: ['city', '=', ['Rioverde']],
        },
        {
          value: 'Ciudad Fernández',
          label: 'Ciudad Fernández',
          condition: ['city', '=', ['Fracción el Refugio']],
        },
        {
          value: 'Tamasopo',
          label: 'Tamasopo',
          condition: ['city', '=', ['Tamasopo']],
        },
        {
          value: 'Tamazunchale',
          label: 'Tamazunchale',
          condition: ['city', '=', ['Tamazunchale']],
        },
        {
          value: 'Culiacán',
          label: 'Culiacán',
          condition: ['city', '=', ['Culiacán Rosales', 'Quilá']],
        },
        {
          value: 'Navolato',
          label: 'Navolato',
          condition: [
            'city',
            '=',
            ['Navolato', 'Licenciado Benito Juárez (Campo Gobierno)'],
          ],
        },
        {
          value: 'Cosalá',
          label: 'Cosalá',
          condition: ['city', '=', ['Cosalá']],
        },
        {
          value: 'Mocorito',
          label: 'Mocorito',
          condition: ['city', '=', ['Mocorito']],
        },
        {
          value: 'Guasave',
          label: 'Guasave',
          condition: ['city', '=', ['Guasave']],
        },
        {
          value: 'Ahome',
          label: 'Ahome',
          condition: [
            'city',
            '=',
            ['Los Mochis', 'Ahome', 'Higuera de Zaragoza', 'Topolobampo'],
          ],
        },
        {
          value: 'Salvador Alvarado',
          label: 'Salvador Alvarado',
          condition: ['city', '=', ['Guamúchil']],
        },
        {
          value: 'Angostura',
          label: 'Angostura',
          condition: ['city', '=', ['Angostura']],
        },
        {
          value: 'Choix',
          label: 'Choix',
          condition: ['city', '=', ['Choix']],
        },
        {
          value: 'El Fuerte',
          label: 'El Fuerte',
          condition: ['city', '=', ['San Blas']],
        },
        {
          value: 'Sinaloa',
          label: 'Sinaloa',
          condition: ['city', '=', ['Sinaloa de Leyva', 'Estación Naranjo']],
        },
        {
          value: 'Mazatlán',
          label: 'Mazatlán',
          condition: ['city', '=', ['Mazatlán', 'Villa Unión']],
        },
        {
          value: 'Escuinapa',
          label: 'Escuinapa',
          condition: ['city', '=', ['Escuinapa de Hidalgo']],
        },
        {
          value: 'Elota',
          label: 'Elota',
          condition: ['city', '=', ['La Cruz']],
        },
        {
          value: 'Rosario',
          label: 'Rosario',
          condition: ['city', '=', ['El Rosario']],
        },
        {
          value: 'San Ignacio',
          label: 'San Ignacio',
          condition: ['city', '=', ['San Ignacio']],
        },
        {
          value: 'Hermosillo',
          label: 'Hermosillo',
          condition: ['city', '=', ['Hermosillo']],
        },
        {
          value: 'San Luis Río Colorado',
          label: 'San Luis Río Colorado',
          condition: ['city', '=', ['San Luis Río Colorado']],
        },
        {
          value: 'Puerto Peñasco',
          label: 'Puerto Peñasco',
          condition: ['city', '=', ['Puerto Peñasco']],
        },
        {
          value: 'General Plutarco Elías Calles',
          label: 'General Plutarco Elías Calles',
          condition: ['city', '=', ['Sonoyta']],
        },
        {
          value: 'Caborca',
          label: 'Caborca',
          condition: ['city', '=', ['Heroica Caborca']],
        },
        {
          value: 'Nogales',
          label: 'Nogales',
          condition: ['city', '=', ['Heroica Nogales', 'Nogales']],
        },
        {
          value: 'Agua Prieta',
          label: 'Agua Prieta',
          condition: ['city', '=', ['Agua Prieta']],
        },
        {
          value: 'Cananea',
          label: 'Cananea',
          condition: ['city', '=', ['Heroica Ciudad de Cananea']],
        },
        {
          value: 'Cajeme',
          label: 'Cajeme',
          condition: ['city', '=', ['Ciudad Obregón']],
        },
        {
          value: 'Empalme',
          label: 'Empalme',
          condition: ['city', '=', ['Empalme']],
        },
        {
          value: 'Guaymas',
          label: 'Guaymas',
          condition: ['city', '=', ['Heroica Guaymas']],
        },
        {
          value: 'Navojoa',
          label: 'Navojoa',
          condition: ['city', '=', ['Navojoa']],
        },
        {
          value: 'Huatabampo',
          label: 'Huatabampo',
          condition: ['city', '=', ['Huatabampo']],
        },
        {
          value: 'Centro',
          label: 'Centro',
          condition: ['city', '=', ['Villahermosa']],
        },
        {
          value: 'Jalpa de Méndez',
          label: 'Jalpa de Méndez',
          condition: ['city', '=', ['Jalpa de Méndez']],
        },
        {
          value: 'Comalcalco',
          label: 'Comalcalco',
          condition: ['city', '=', ['Comalcalco']],
        },
        {
          value: 'Huimanguillo',
          label: 'Huimanguillo',
          condition: ['city', '=', ['Huimanguillo']],
        },
        {
          value: 'Paraíso',
          label: 'Paraíso',
          condition: ['city', '=', ['Paraíso']],
        },
        {
          value: 'Cunduacán',
          label: 'Cunduacán',
          condition: ['city', '=', ['Cunduacán']],
        },
        {
          value: 'Macuspana',
          label: 'Macuspana',
          condition: ['city', '=', ['Macuspana']],
        },
        {
          value: 'Centla',
          label: 'Centla',
          condition: ['city', '=', ['Frontera']],
        },
        {
          value: 'Teapa',
          label: 'Teapa',
          condition: ['city', '=', ['Teapa']],
        },
        {
          value: 'Tenosique',
          label: 'Tenosique',
          condition: ['city', '=', ['Tenosique de Pino Suárez']],
        },
        {
          value: 'Emiliano Zapata',
          label: 'Emiliano Zapata',
          condition: ['city', '=', ['Emiliano Zapata']],
        },
        {
          value: 'Victoria',
          label: 'Victoria',
          condition: ['city', '=', ['Ciudad Victoria']],
        },
        {
          value: 'Valle Hermoso',
          label: 'Valle Hermoso',
          condition: ['city', '=', ['Valle Hermoso']],
        },
        {
          value: 'San Fernando',
          label: 'San Fernando',
          condition: ['city', '=', ['San Fernando']],
        },
        {
          value: 'Soto la Marina',
          label: 'Soto la Marina',
          condition: ['city', '=', ['Soto la Marina']],
        },
        {
          value: 'Tula',
          label: 'Tula',
          condition: ['city', '=', ['Ciudad Tula']],
        },
        {
          value: 'Jaumave',
          label: 'Jaumave',
          condition: ['city', '=', ['Jaumave']],
        },
        {
          value: 'Nuevo Laredo',
          label: 'Nuevo Laredo',
          condition: ['city', '=', ['Nuevo Laredo']],
        },
        {
          value: 'Miguel Alemán',
          label: 'Miguel Alemán',
          condition: ['city', '=', ['Ciudad Miguel Alemán']],
        },
        {
          value: 'Guerrero',
          label: 'Guerrero',
          condition: ['city', '=', ['Nueva Ciudad Guerrero']],
        },
        {
          value: 'Gustavo Díaz Ordaz',
          label: 'Gustavo Díaz Ordaz',
          condition: ['city', '=', ['Ciudad Gustavo Díaz Ordaz']],
        },
        {
          value: 'Reynosa',
          label: 'Reynosa',
          condition: ['city', '=', ['Reynosa']],
        },
        {
          value: 'Río Bravo',
          label: 'Río Bravo',
          condition: ['city', '=', ['Ciudad Río Bravo']],
        },
        {
          value: 'Tampico',
          label: 'Tampico',
          condition: ['city', '=', ['Tampico']],
        },
        {
          value: 'Ciudad Madero',
          label: 'Ciudad Madero',
          condition: ['city', '=', ['Ciudad Madero']],
        },
        {
          value: 'Altamira',
          label: 'Altamira',
          condition: ['city', '=', ['Altamira']],
        },
        {
          value: 'González',
          label: 'González',
          condition: [
            'city',
            '=',
            ['González', 'Estación Manuel (Úrsulo Galván)'],
          ],
        },
        {
          value: 'Xicoténcatl',
          label: 'Xicoténcatl',
          condition: ['city', '=', ['Xicoténcatl']],
        },
        {
          value: 'El Mante',
          label: 'El Mante',
          condition: ['city', '=', ['Ciudad Mante']],
        },
        {
          value: 'Tlaxcala',
          label: 'Tlaxcala',
          condition: ['city', '=', ['Tlaxcala de Xicohténcatl']],
        },
        {
          value: 'Calpulalpan',
          label: 'Calpulalpan',
          condition: ['city', '=', ['Calpulalpan']],
        },
        {
          value: 'Apizaco',
          label: 'Apizaco',
          condition: ['city', '=', ['Apizaco']],
        },
        {
          value: 'Huamantla',
          label: 'Huamantla',
          condition: ['city', '=', ['Huamantla']],
        },
        {
          value: 'Chiautempan',
          label: 'Chiautempan',
          condition: ['city', '=', ['Chiautempan']],
        },
        {
          value: 'San Pablo del Monte',
          label: 'San Pablo del Monte',
          condition: ['city', '=', ['Villa Vicente Guerrero']],
        },
        {
          value: 'Xalapa',
          label: 'Xalapa',
          condition: ['city', '=', ['Xalapa-Enríquez']],
        },
        {
          value: 'Perote',
          label: 'Perote',
          condition: ['city', '=', ['Perote']],
        },
        {
          value: 'Banderilla',
          label: 'Banderilla',
          condition: ['city', '=', ['Banderilla']],
        },
        {
          value: 'Coatepec',
          label: 'Coatepec',
          condition: ['city', '=', ['Coatepec']],
        },
        {
          value: 'Paso de Ovejas',
          label: 'Paso de Ovejas',
          condition: ['city', '=', ['Paso de Ovejas']],
        },
        {
          value: 'La Antigua',
          label: 'La Antigua',
          condition: ['city', '=', ['José Cardel']],
        },
        {
          value: 'Veracruz',
          label: 'Veracruz',
          condition: ['city', '=', ['Veracruz']],
        },
        {
          value: 'Tampico Alto',
          label: 'Tampico Alto',
          condition: ['city', '=', ['Tampico Alto']],
        },
        {
          value: 'Tempoal',
          label: 'Tempoal',
          condition: ['city', '=', ['Tempoal de Sánchez']],
        },
        {
          value: 'Tantoyuca',
          label: 'Tantoyuca',
          condition: ['city', '=', ['Tantoyuca']],
        },
        {
          value: 'Platón Sánchez',
          label: 'Platón Sánchez',
          condition: ['city', '=', ['Platón Sánchez']],
        },
        {
          value: 'Naranjos Amatlán',
          label: 'Naranjos Amatlán',
          condition: ['city', '=', ['Naranjos']],
        },
        {
          value: 'El Higo',
          label: 'El Higo',
          condition: ['city', '=', ['El Higo']],
        },
        {
          value: 'Cerro Azul',
          label: 'Cerro Azul',
          condition: ['city', '=', ['Cerro Azul']],
        },
        {
          value: 'Huayacocotla',
          label: 'Huayacocotla',
          condition: ['city', '=', ['Huayacocotla']],
        },
        {
          value: 'Álamo Temapache',
          label: 'Álamo Temapache',
          condition: ['city', '=', ['Álamo']],
        },
        {
          value: 'Tihuatlán',
          label: 'Tihuatlán',
          condition: ['city', '=', ['Tihuatlán']],
        },
        {
          value: 'Cazones de Herrera',
          label: 'Cazones de Herrera',
          condition: ['city', '=', ['Cazones de Herrera']],
        },
        {
          value: 'Coatzintla',
          label: 'Coatzintla',
          condition: ['city', '=', ['Coatzintla']],
        },
        {
          value: 'Poza Rica de Hidalgo',
          label: 'Poza Rica de Hidalgo',
          condition: ['city', '=', ['Poza Rica de Hidalgo']],
        },
        {
          value: 'Papantla',
          label: 'Papantla',
          condition: ['city', '=', ['Papantla de Olarte']],
        },
        {
          value: 'Gutiérrez Zamora',
          label: 'Gutiérrez Zamora',
          condition: ['city', '=', ['Gutiérrez Zamora']],
        },
        {
          value: 'Martínez de la Torre',
          label: 'Martínez de la Torre',
          condition: ['city', '=', ['Martínez de la Torre']],
        },
        {
          value: 'San Rafael',
          label: 'San Rafael',
          condition: ['city', '=', ['San Rafael']],
        },
        {
          value: 'Tlapacoyan',
          label: 'Tlapacoyan',
          condition: ['city', '=', ['Tlapacoyan']],
        },
        {
          value: 'Altotonga',
          label: 'Altotonga',
          condition: ['city', '=', ['Altotonga']],
        },
        {
          value: 'Yecuatla',
          label: 'Yecuatla',
          condition: ['city', '=', ['Yecuatla']],
        },
        {
          value: 'Pánuco',
          label: 'Pánuco',
          condition: ['city', '=', ['Pánuco']],
        },
        {
          value: 'Huatusco',
          label: 'Huatusco',
          condition: ['city', '=', ['Huatusco de Chicuellar']],
        },
        {
          value: 'Soledad de Doblado',
          label: 'Soledad de Doblado',
          condition: ['city', '=', ['Soledad de Doblado']],
        },
        {
          value: 'Boca del Río',
          label: 'Boca del Río',
          condition: ['city', '=', ['Boca del Río']],
        },
        {
          value: 'Orizaba',
          label: 'Orizaba',
          condition: ['city', '=', ['Orizaba']],
        },
        {
          value: 'Ixtaczoquitlán',
          label: 'Ixtaczoquitlán',
          condition: ['city', '=', ['Ixtaczoquitlán']],
        },
        {
          value: 'Fortín',
          label: 'Fortín',
          condition: ['city', '=', ['Fortín de las Flores']],
        },
        {
          value: 'Córdoba',
          label: 'Córdoba',
          condition: ['city', '=', ['Córdoba']],
        },
        {
          value: 'Río Blanco',
          label: 'Río Blanco',
          condition: ['city', '=', ['Río Blanco']],
        },
        {
          value: 'Huiloapan de Cuauhtémoc',
          label: 'Huiloapan de Cuauhtémoc',
          condition: ['city', '=', ['Huiloapan de Cuauhtémoc']],
        },
        {
          value: 'Cuitláhuac',
          label: 'Cuitláhuac',
          condition: ['city', '=', ['Cuitláhuac']],
        },
        {
          value: 'Amatlán de los Reyes',
          label: 'Amatlán de los Reyes',
          condition: ['city', '=', ['Paraje Nuevo']],
        },
        {
          value: 'Atoyac',
          label: 'Atoyac',
          condition: [
            'city',
            '=',
            ['Atoyac', 'General Miguel Alemán (Potrero Nuevo)'],
          ],
        },
        {
          value: 'Paso del Macho',
          label: 'Paso del Macho',
          condition: ['city', '=', ['Paso del Macho']],
        },
        {
          value: 'Tezonapa',
          label: 'Tezonapa',
          condition: ['city', '=', ['Tezonapa']],
        },
        {
          value: 'Tierra Blanca',
          label: 'Tierra Blanca',
          condition: ['city', '=', ['Tierra Blanca']],
        },
        {
          value: 'Alvarado',
          label: 'Alvarado',
          condition: ['city', '=', ['Alvarado']],
        },
        {
          value: 'Lerdo de Tejada',
          label: 'Lerdo de Tejada',
          condition: ['city', '=', ['Lerdo de Tejada']],
        },
        {
          value: 'Tres Valles',
          label: 'Tres Valles',
          condition: ['city', '=', ['Tres Valles']],
        },
        {
          value: 'Carlos A. Carrillo',
          label: 'Carlos A. Carrillo',
          condition: ['city', '=', ['Carlos A. Carrillo']],
        },
        {
          value: 'Cosamaloapan de Carpio',
          label: 'Cosamaloapan de Carpio',
          condition: ['city', '=', ['Cosamaloapan']],
        },
        {
          value: 'Tlacojalpan',
          label: 'Tlacojalpan',
          condition: ['city', '=', ['Tlacojalpan']],
        },
        {
          value: 'Playa Vicente',
          label: 'Playa Vicente',
          condition: ['city', '=', ['Playa Vicente']],
        },
        {
          value: 'Isla',
          label: 'Isla',
          condition: ['city', '=', ['Isla']],
        },
        {
          value: 'Juan Rodríguez Clara',
          label: 'Juan Rodríguez Clara',
          condition: ['city', '=', ['Juan Rodríguez Clara']],
        },
        {
          value: 'San Andrés Tuxtla',
          label: 'San Andrés Tuxtla',
          condition: ['city', '=', ['San Andrés Tuxtla', 'Sihuapan']],
        },
        {
          value: 'Santiago Tuxtla',
          label: 'Santiago Tuxtla',
          condition: ['city', '=', ['Santiago Tuxtla']],
        },
        {
          value: 'Angel R. Cabada',
          label: 'Angel R. Cabada',
          condition: ['city', '=', ['Ángel R. Cabada']],
        },
        {
          value: 'Hueyapan de Ocampo',
          label: 'Hueyapan de Ocampo',
          condition: ['city', '=', ['Juan Díaz Covarrubias']],
        },
        {
          value: 'Catemaco',
          label: 'Catemaco',
          condition: ['city', '=', ['Catemaco']],
        },
        {
          value: 'Acayucan',
          label: 'Acayucan',
          condition: ['city', '=', ['Acayucan']],
        },
        {
          value: 'Jáltipan',
          label: 'Jáltipan',
          condition: ['city', '=', ['Jáltipan de Morelos']],
        },
        {
          value: 'Cosoleacaque',
          label: 'Cosoleacaque',
          condition: ['city', '=', ['Cosoleacaque']],
        },
        {
          value: 'Moloacán',
          label: 'Moloacán',
          condition: ['city', '=', ['Cuichapa']],
        },
        {
          value: 'Coatzacoalcos',
          label: 'Coatzacoalcos',
          condition: ['city', '=', ['Coatzacoalcos']],
        },
        {
          value: 'Agua Dulce',
          label: 'Agua Dulce',
          condition: ['city', '=', ['Agua Dulce']],
        },
        {
          value: 'Minatitlán',
          label: 'Minatitlán',
          condition: ['city', '=', ['Minatitlán']],
        },
        {
          value: 'Las Choapas',
          label: 'Las Choapas',
          condition: ['city', '=', ['Las Choapas']],
        },
        {
          value: 'Mérida',
          label: 'Mérida',
          condition: ['city', '=', ['Mérida']],
        },
        {
          value: 'Kanasín',
          label: 'Kanasín',
          condition: ['city', '=', ['Kanasín']],
        },
        {
          value: 'Motul',
          label: 'Motul',
          condition: ['city', '=', ['Motul de Carrillo Puerto']],
        },
        {
          value: 'Tizimín',
          label: 'Tizimín',
          condition: ['city', '=', ['Tizimín']],
        },
        {
          value: 'Valladolid',
          label: 'Valladolid',
          condition: ['city', '=', ['Valladolid']],
        },
        {
          value: 'Ticul',
          label: 'Ticul',
          condition: ['city', '=', ['Ticul']],
        },
        {
          value: 'Zacatecas',
          label: 'Zacatecas',
          condition: ['city', '=', ['Zacatecas']],
        },
        {
          value: 'Juan Aldama',
          label: 'Juan Aldama',
          condition: ['city', '=', ['Juan Aldama']],
        },
        {
          value: 'Río Grande',
          label: 'Río Grande',
          condition: ['city', '=', ['Río Grande']],
        },
        {
          value: 'Villa de Cos',
          label: 'Villa de Cos',
          condition: ['city', '=', ['Villa de Cos']],
        },
        {
          value: 'Calera',
          label: 'Calera',
          condition: ['city', '=', ['Víctor Rosales']],
        },
        {
          value: 'Ojocaliente',
          label: 'Ojocaliente',
          condition: ['city', '=', ['Ojocaliente']],
        },
        {
          value: 'Luis Moya',
          label: 'Luis Moya',
          condition: ['city', '=', ['Luis Moya']],
        },
        {
          value: 'Fresnillo',
          label: 'Fresnillo',
          condition: ['city', '=', ['Fresnillo']],
        },
        {
          value: 'Sombrerete',
          label: 'Sombrerete',
          condition: ['city', '=', ['Sombrerete']],
        },
        {
          value: 'Valparaíso',
          label: 'Valparaíso',
          condition: ['city', '=', ['Valparaíso']],
        },
        {
          value: 'Jerez',
          label: 'Jerez',
          condition: ['city', '=', ['Jerez de García Salinas']],
        },
        {
          value: 'Villanueva',
          label: 'Villanueva',
          condition: ['city', '=', ['Villanueva']],
        },
        {
          value: 'Jalpa',
          label: 'Jalpa',
          condition: ['city', '=', ['Jalpa']],
        },
        {
          value: 'Nochistlán de Mejía',
          label: 'Nochistlán de Mejía',
          condition: ['city', '=', ['Nochistlán de Mejía']],
        },
        {
          value: 'Moyahua de Estrada',
          label: 'Moyahua de Estrada',
          condition: ['city', '=', ['Moyahua de Estrada']],
        },
      ],
    },
  ],
];

export const financingFieldsRow = [
  [
    {
      type: 'radio-button',
      label: '¿Trabajan/aceptan créditos hipotecarios externos?*',
      field_name: 'external_mortgage_loans',
      options: [
        {
          value: 'yes',
          label: 'SI',
        },
        {
          value: 'no',
          label: 'NO',
        },
      ],
    },
  ],
  [
    {
      type: 'checkbox-multiple',
      label: '¿Con qué entidades trabaja?*',
      field_name: 'entities',
      multiple: true,
      checkmarks: true,
      options: [
        {
          value: 'Crédito bancario',
          label: 'Crédito bancario',
        },
        {
          value: 'Sofomes',
          label: 'Sofomes',
        },
        {
          value: 'Infonavit',
          label: 'Infonavit',
        },
        {
          value: 'Cofinavit',
          label: 'Cofinavit',
        },
        {
          value: 'Fovisste',
          label: 'Fovisste',
        },
      ],
    },
  ],
];

export const visualMaterialFieldsRow = [
  [
    {
      type: 'text-field',
      label: 'URL de recorridos 3D (opcional)',
      field_name: 'tours_3d_url',
    },
    {
      type: 'text-field',
      label: 'URL de brochure del proyecto (opcional)',
      field_name: 'project_brochure_url',
    },
  ],
];

export const contactFieldsRow = [
  [
    {
      type: 'text-field',
      label: 'Dirección*',
      field_name: 'address',
    },
    {
      type: 'text-field',
      label: 'Correo electrónico*',
      field_name: 'email',
    },
  ],
  [
    {
      type: 'text-field',
      label: 'Celular*',
      field_name: 'phone',
    },
    {
      type: 'text-field',
      label: 'Whatsapp*',
      field_name: 'whatsapp',
    },
  ],
];

export const visitingHoursFieldsRow = [
  [
    {
      type: 'radio-button',
      label:
        '¿Las visitas en el desarrollo serán guiadas por personal del desarrollador?*',
      field_name: 'tours_guided_by_developer_staff',
      options: [
        {
          value: 'yes',
          label: 'SI',
        },
        {
          value: 'no',
          label: 'NO',
        },
      ],
    },
  ],
];
export const permissionsFieldsRow = [
  [
    {
      type: 'radio-button',
      label:
        '¿Podemos publicar en portales inmobiliarios externos a Flat? Ejemplo: I24, Casa y Terrenos, etc.*',
      field_name: 'publish_on_external_portals',
      options: [
        {
          value: 'yes',
          label: 'SI',
        },
        {
          value: 'no',
          label: 'NO',
        },
      ],
    },
  ],
];
