export const currencyToNumber = (string) => {
  return Number(String(string).replace(/[A-Za-z!@#$%^&*(),]/g, ''))
}

export const numberToCurrency = (number, currencySymbol = "", currencyCode = "") => {
  if (number === null || number === undefined || number === '') return ''
  if (currencySymbol === "" && currencyCode === "") {
    return new Intl.NumberFormat('en').format(number)
  }
  return (`${currencyCode} ${currencySymbol}${new Intl.NumberFormat('en').format(number)}`)
}

export const numberToPercent = (number) => {
  if (number === null || number === undefined || number === '') return ''
  return (`${number}%`)
}

export const isArray = (element) => Array.isArray(element);
export const isObject = (element) => typeof element === 'object' && element !== null && !isArray(element);

export const ASSETS_URL = `/internal/login/v1/onboarding/assets`

export const API_UPDATE_ASSET_URL = `/internal/login/v1/onboarding/assets`

export const isFileImage = (file) => file && file['type'].split('/')[0] === 'image'
export const isExtImage = (filename) => filename.match(/.(jpg|jpeg|png|gif|tif|tiff|bmp|webp)$/i)
export const isExtVideo = (filename) => filename.match(/.(webm|mkv|flv|ogg|avi|mov|wmv|mp4|mpeg|m4v)$/i)

export const toBase64 = file => new Promise((resolve, reject) => {
  const isImage = isFileImage(file)
  if (isImage) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  } else {
    resolve(null)
  }
})

export const validImageFileTypes = [
  "image/bmp",
  "image/gif",
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/tif",
  "image/tiff",
  "image/webp",
];

export const getExtType = (fileName) => {
  if (isExtImage(fileName)) return 'image'
  if (isExtVideo(fileName)) return 'video'
  return 'file'
}

export const truncateString = (str, len) => {
  if (str.length > len) {
     if (len <= 3) {
        return str.slice(0, len - 3) + "...";
     }
     else {
        return str.slice(0, len) + "...";
     };
  }
  else {
     return str;
  };
};

// ASSETS
export const getItemAssetsCount = (data) => {
  if(isArray(data)) return data.length
  if(isObject(data)) return Object.keys(data).length
}

export const removeItemsWithoutAsset = (list) => list.filter((item) => item.asset_id)

export const findAssetFromAssetId = (assetId, list) => list.filter((item) => item.asset_id === assetId)

const insertToArrayStart = (templateArray, newItems, index) => [
  ...newItems,
  ...templateArray.slice(index)
]

export const mergeGalleries = (templateGallery, onboardingGallery) => {
  const templateGalleryLength = getItemAssetsCount(templateGallery)
  const onboardingGalleryLength = getItemAssetsCount(onboardingGallery)

  if(onboardingGalleryLength >= templateGalleryLength) return onboardingGallery
  return insertToArrayStart(templateGallery, onboardingGallery, onboardingGalleryLength)
}

export const filterByKeyValue = (list, key, value) => {
  return list.filter((item) => item[key] === value)
}

export const getAssetIds = (data) => {
  return removeItemsWithoutAsset(data).map((item) => item.asset_id)
}

export const formatAsImgSrc = (array, baseUrl) => {
  return array.map((item) => {
    return item !== null ? `${baseUrl}/images/${item}` : null
  })
}

export const formatAsAssetIdKeyValue = (array, baseUrl) => {
  return array.map((item, index) => {
    return (item !== null && item !== {}) ? {
      id: `${index + 1}`,
      assetId: item.asset_id,
      url: `${baseUrl}/images/${item.asset_id}`
    } : {}
  })
}

export const formatAsGallerySlide = (data, baseUrl) => {
  return data.map((item, index) => item.asset_id ? ({
    url: `${baseUrl}/images/${item.asset_id}`,
    caption: item.metadata?.name || '',
    id: `${index}`,
  }) : null)
}

export const getGalleryItems = (templateList, list, path, callback, baseUrl) => {
  const filteredList = removeItemsWithoutAsset(list)
  const formattedGallery = filteredList && callback(filteredList, baseUrl)

  if(filteredList.length > 0) return {
    path: path,
    value: mergeGalleries(templateList, formattedGallery)
  }
  return null
}

export const formatArrayAssetIds = (data, baseUrl) => {
  const listIds = getAssetIds(data)

  return formatAsImgSrc(listIds, baseUrl)
}

export const generateRandomId = () => {
  if (typeof window !== 'undefined' && window.crypto) {
    const arr = window.crypto.getRandomValues(new Uint8Array(10))
    return Array.from(arr, number => number.toString(16).padStart(2, '0')).join('')
  }
  return Math.random().toString(36).replace(/\./g, '')
}

export const addDaysToDate = (date, days) => {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export const getDaysBetweenDates = (date1, date2) => {
  const differenceInTime = date2.getTime() - date1.getTime()
  return differenceInTime / (1000 * 3600 * 24)
}

// OLD BACKOFFICE FUNCTIONS - BEGIN

export const MONTHS = {
  0: 'Enero',
  1: 'Febrero',
  2: 'Marzo',
  3: 'Abril',
  4: 'Mayo',
  5: 'Junio',
  6: 'Julio',
  7: 'Agosto',
  8: 'Septiembre',
  9: 'Octubre',
  10: 'Noviembre',
  11: 'Diciembre',
}

export const forMap = (from, to, callback) => {
  const result = []
  for (let i = from; i <= to; i++) {
    result.push(callback(i))
  }
  return result
}


export const fromSnakeCaseToTitleCase = (snakeText) => {
  return snakeText
    .split("_")
    .map(word => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const formatDate = (date, lang = 'es') => {
  const day = date.getDate().toString();
  const month = (date.getMonth() + 1).toString();
  const year = date.getFullYear().toString();

  const formattedDay = `${day.length === 1 ? '0' : ''}${day}`;
  const formattedMonth = `${month.length === 1 ? '0' : ''}${month}`;

  switch (lang) {
    case 'en':
      return `${formattedMonth}/${formattedDay}/${year}`;
    case 'es':
    default:
      return `${formattedDay}/${formattedMonth}/${year}`;
  }
};

export const convertDateToMMYY = (date) => {
  const monthNumber = date.getMonth() + 1 // in Date() months are from 0-11
  const month = (monthNumber < 10) ? `0${monthNumber}` : `${monthNumber}`
  const year =  String(date.getFullYear()).slice(-2)
  return `${month}/${year}`
}

export const convertMMYYtoFormat = (MMYYDate, format) => {

  const MM = MMYYDate.substring(0, 2)
  const YY = MMYYDate.substring(3, 5)
  const MMMM = MONTHS[Number(MM) - 1]
  const YYYY = `20${YY}`

  switch (format) {
    case 'MM':
      return MM
    case 'YY':
      return YY
    case 'MMMM':
      return MMMM
    case 'YYYY':
      return YYYY
    case 'MMMM YYYY':
      return `${MMMM} ${YYYY}`
    case 'MM/YY':
      return `${MM}/${YY}`
    default:
      return MMYYDate
  }
}

export const getMonthsBetweenDates = (d1, d2) => {
  if (d1 == null) return 0
  const date1 = new Date(d1)
  const date2 = d2 ? new Date(d2) : new Date()
  let months;
  months = (date2.getFullYear() - date1.getFullYear()) * 12;
  months -= date1.getMonth();
  months += date2.getMonth();
  return months <= 0 ? 0 : months;
}

export const formatName = (name, lastname) => {
  if (name && lastname) return `${name} ${lastname[0].toUpperCase()}.`;
  return name || lastname;
};

// OLD BACKOFFICE FUNCTIONS - END
