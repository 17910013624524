import { Button, Typography } from "@mui/material"
import React from "react"
import { FlexContainer, Spacer } from "../../components"
import PageLayout from "../../layouts/PageLayout"
import { useCallback } from "react"
import { useNavigate } from "react-router-dom"

const NoSitesView = () => {
  const navigate = useNavigate()

  const goToNewProject = useCallback(() => {
    navigate('/new-project')
  }, [navigate])

  return (
    <PageLayout>
      <FlexContainer vertical>
        <Spacer vertical size={4} />
        <Typography variant="h2">¡Aquí es donde comienza la magia!</Typography>
        <Spacer vertical size={5} />
        <Typography>Hola, que alegría nos da verte en nuestra suite digital. <b>¿Te gustaría crear un showroom digital de tu proyecto?</b></Typography>
        <Spacer vertical size={2} />
        <Typography>Hazlo fácil y rápido. Publícalo en minutos y administra 100% online cada detalle: prospectos (leads), inventario, ventas y más.</Typography>
        <Spacer vertical size={2} />
        <Typography>¡Genera resultados a partir de hoy!</Typography>
        <Spacer vertical size={5} />
        <Button color="primary" variant="contained" sx={{ width: 350 }} onClick={goToNewProject}>Comienza aquí un showroom digital</Button>
      </FlexContainer>
    </PageLayout>
  )
}

export default NoSitesView
