import { FormControl, MenuItem, Select, Switch, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import React, { useMemo } from 'react'
import { useCallback } from 'react'
import styled from 'styled-components'
import { FlexContainer, Spacer, UserPhoto } from '../../components'
import { ROLES, SALES_TYPES, USER_SITE_STATUS } from '../../helpers/constants'
import { getAvailableOptions } from '../../helpers/permissions'
import useSite from '../../hooks/useSite'

const Column = styled(FlexContainer)`
  max-width: 250px;
  width: 100%;
`

const UserData = ({ label, data }) => (
  <FlexContainer vertical>
    <Typography variant="caption" color="text.secondary">{label}</Typography>
    <Spacer size={0.5} vertical />
    <Typography>{(data == null || data?.trim()?.length === 0) ? '-' : data}</Typography>
    <Spacer size={4} vertical />
  </FlexContainer>
)

const TeamEdit = ({ user, onEdit }) => {
  const { site } = useSite()

  const availableRoles = useMemo(() => (
    getAvailableOptions(ROLES, site?.permissions)
  ), [site?.permissions])

  const handleActiveChange = useCallback((e) => {
    if (e.target.checked) {
      onEdit(USER_SITE_STATUS.active, 'status')
    } else {
      onEdit(USER_SITE_STATUS.deactivated, 'status')
    }
  }, [onEdit])

  const photoUrl = useMemo(
    () =>
      user?.avatarId
        ? `${process.env.REACT_APP_IMAGES_URL}/${user.avatarId}?s=144x144&fit=cover`
        : undefined,
    [user?.avatarId]
  );

  return (
    <FlexContainer vertical>
      <Spacer vertical size={4} />
      <FlexContainer>
        <UserPhoto
          name={user.name}
          size={144}
          photoUrl={photoUrl}
        />
        <Spacer size={5} />
        <Column vertical>
          <UserData label="Nombre Completo" data={`${user.name ?? ''} ${user.lastname ?? ''}`} />
          <UserData label="Número de teléfono" data={user.phone} />
          <FormControl variant="standard" fullWidth>
            <Typography variant="caption" color="text.secondary">Tipo de Perfil</Typography>
            <Spacer size={0.5} vertical />
            <Select
              variant="standard"
              value={user?.role}
              onChange={(e) => onEdit(e.target.value, 'role')}
              label="Tipo de Perfil"
            >
              {availableRoles.map((role) => (
                <MenuItem key={role.id} value={role.id}>{role.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Spacer size={4} vertical />
          <FlexContainer vertical>
            <Typography variant="caption" color="text.secondary">Válido hasta</Typography>
            <Spacer size={0.5} vertical />
            <DatePicker
              disablePast
              inputFormat="DD/MM/YYYY"
              value={user?.validUntil}
              onChange={(newValue) => onEdit(newValue, 'validUntil')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  helperText={user?.validUntil == null ? "El campo es obligatorio" : ""}
                  error={params.error || user?.validUntil == null}
                />
              )}
            />
          </FlexContainer>
        </Column>
        <Spacer size={5} />
        <Column vertical>
          <UserData label="Email" data={user.email} />
          <UserData label="Número de Whatsapp" data={user.whatsappPhone} />
          <FormControl variant="standard" fullWidth>
            <Typography variant="caption" color="text.secondary">Tipo de Supervisor</Typography>
            <Spacer size={0.5} vertical />
            <Select
              variant="standard"
              value={user?.type}
              onChange={(e) => onEdit(e.target.value, 'type')}
              label="Tipo de Supervisor"
            >
              {SALES_TYPES.map((type) => (
                <MenuItem key={type.id} value={type.id}>{type.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Column>
        <Spacer size={5} />
        <Column vertical>
          <FlexContainer vertical>
            <Typography variant="caption" color="text.secondary">Activo / Inactivo</Typography>
            {user.status === USER_SITE_STATUS.pending ? (
              <>
                <Spacer size={0.5} vertical />
                <Typography>Por aceptar invitación</Typography>
              </>
            ) : (
              <Switch checked={user.status === USER_SITE_STATUS.active} onChange={handleActiveChange} />
            )}
          </FlexContainer>
        </Column>
      </FlexContainer>
    </FlexContainer>
  )
}

export default TeamEdit
