import {
  Add as AddIcon,
  Close as CloseIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import { Button, IconButton, Typography } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import { DEFAULT_ICONS } from '../../../../components/IconInput/IconInput';
import { getAlocodeIconUrl } from '../../../../helpers/draft';
import { generateRandomId } from '../../../../helpers/utils';
import useCurrency from '../../../../hooks/useCurrency';
import useSite from '../../../../hooks/useSite';
import { DiscardChangesDialog } from '../../../../modules';
import Characteristics from './Characteristics';
import Description from './Description';
import Images from './Images';
import MainInformation from './MainInformation';
import Tag from './Tag';
import validateTypologyFields from './validateTypologyFields';

function getEmptyTypology() {
  return {
    characteristics: [],
    images: [],
  };
}

function getIcons(characteristics, site) {
  if (!characteristics) return DEFAULT_ICONS;
  const icons = [...DEFAULT_ICONS];
  const alocodeIcons = characteristics
  .map(({ icon }) => icon)
  .filter((icon) => /^\[:.+:\]$/.test(icon));
  alocodeIcons.forEach((icon) => {
    if (!icons.some(({ value }) => value === icon)) {
      icons.push({ value: icon, src: getAlocodeIconUrl(icon, site) });
    }
  });
  return icons;
}

const VIEW_TEXTS = {
  labelUpdate: "Actualizar tipología",
  labelAdd: "Agregar tipología",
}

const TypologyDetails = ({
  fields: initialFields,
  isEditing,
  onClose: onDiscard,
  onSubmit,
}) => {
  const { site } = useSite();
  const { main: currency, isLoading: isLoadingCurrency } = useCurrency();
  const initialState = useRef(initialFields || getEmptyTypology());
  const [icons] = useState(() => getIcons(initialState.current.characteristics, site));
  const [fields, setFields] = useState(initialState.current);
  const [openConfirmDiscard, setOpenConfirmDiscard] = useState(false);
  const [errors, setErrors] = useState([]);

  const setProperty = useCallback((property, value) => {
    setFields((prev) => ({ ...prev, [property]: value }));
  }, []);

  const addCharacteristic = useCallback(
    () =>
      setFields((prev) => ({
        ...prev,
        characteristics: [...prev.characteristics, { id: generateRandomId() }],
      })),
    [],
  );

  const removeCharacteristic = useCallback(
    (index) =>
      setFields((prev) => {
        const characteristics = [...prev.characteristics];
        characteristics.splice(index, 1);
        return {
          ...prev,
          characteristics,
        };
      }),
    [],
  );

  const setCharacteristicLabel = useCallback(
    (index, value) =>
      setFields((prev) => {
        const characteristics = [...prev.characteristics];
        const characteristic = characteristics[index];
        characteristics[index] = {
          ...characteristic,
          label: value,
        };
        return {
          ...prev,
          characteristics,
        };
      }),
    [],
  );

  const setCharacteristicIcon = useCallback(
    (index, value) =>
      setFields((prev) => {
        const characteristics = [...prev.characteristics];
        const characteristic = characteristics[index];
        characteristics[index] = {
          ...characteristic,
          icon: value,
        };
        return {
          ...prev,
          characteristics,
        };
      }),
    [],
  );

  const setDescription = useCallback(
    (description) =>
      setFields((prev) => ({
        ...prev,
        description,
      })),
    [],
  );

  const addImage = useCallback(
    ({ url, assetId }) =>
      setFields((prev) => {
        const images = [...prev.images];
        images.push({ url, assetId, id: generateRandomId() });
        return {
          ...prev,
          images,
        };
      }),
    [],
  );

  const deleteImage = useCallback((index) => {
    setFields((prev) => {
      const images = [...prev.images];
      images.splice(index, 1);
      return {
        ...prev,
        images,
      };
    });
  }, []);

  const setTag = useCallback(
    (tag) => setFields((prev) => ({ ...prev, tag })),
    [],
  );

  const handleSubmit = useCallback(
    (fields) => {
      const err = validateTypologyFields(fields, currency);
      if (err.length === 0) onSubmit(fields);
      else setErrors(err);
    },
    [onSubmit, currency],
  );

  const openDialog = useCallback(() => setOpenConfirmDiscard(true), []);
  const closeDialog = useCallback(() => setOpenConfirmDiscard(false), []);

  const changeHasBeenMade = initialState.current !== fields;

  if (isLoadingCurrency) return null;

  return (
    <>
      <DiscardChangesDialog
        open={openConfirmDiscard}
        onClose={closeDialog}
        onSave={() => handleSubmit(fields)}
        onDiscard={onDiscard}
      />
      <IconButton
        onClick={changeHasBeenMade ? openDialog : onDiscard}
        sx={{ ml: 'auto' }}
      >
        <CloseIcon />
      </IconButton>
      <MainInformation
        currency={currency}
        setProperty={setProperty}
        name={fields.name}
        price={fields.price}
        fullUpfrontPaymentPrice={fields.price_full_upfront_payment}
        annualCapitalGain={fields.annualCapitalGain}
      />
      <Characteristics
        icons={icons}
        characteristics={fields.characteristics}
        addCharacteristic={addCharacteristic}
        removeCharacteristic={removeCharacteristic}
        setCharacteristicLabel={setCharacteristicLabel}
        setCharacteristicIcon={setCharacteristicIcon}
      />
      <Description
        description={fields.description}
        setDescription={setDescription}
      />
      <Images images={fields.images} onAdd={addImage} onDelete={deleteImage} />
      <Tag tag={fields.tag} setTag={setTag} />
      <Button
        variant="contained"
        color="primary"
        startIcon={isEditing ? <SaveIcon /> : <AddIcon />}
        onClick={() => handleSubmit(fields)}
        sx={{ mr: 'auto' }}
      >
        {isEditing ? VIEW_TEXTS.labelUpdate : VIEW_TEXTS.labelAdd}
      </Button>
      {errors.map((error) => (
        <Typography key={error} variant="body2" color="error" sx={{ mt: 1 }}>
          {error}
        </Typography>
      ))}
    </>
  );
};

export default TypologyDetails;
