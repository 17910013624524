import { useEffect, useRef, useState } from 'react';

const useDebouncedState = (initialState, { onSettled, wait = 300 }) => {
  const [state, setState] = useState(initialState);
  const firstRender = useRef(true);
  const onSettledRef = useRef(onSettled);
  onSettledRef.current = onSettled;

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    const timeoutRef = setTimeout(() => {
      onSettledRef.current?.(state);
    }, wait);
    return () => clearTimeout(timeoutRef);
  }, [onSettled, wait, state]);

  return [state, setState];
};

export default useDebouncedState;
